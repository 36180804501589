import React from 'react';
import PropTypes from 'prop-types';
import { Popover, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import './SortSelectPopover.scss';

function SortSelectPopover(props) {
  const { anchorEl, options, orderBy, order, onClose, onOrderChange, onOrderByChange } = props;

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      className="sort-select-popover"
      classes={{ paper: 'popover-paper' }}
    >
      <div className="body">
        {options?.length > 0 && (
          <>
            <RadioGroup
              aria-label="orderBy"
              name="orderBy"
              value={orderBy}
              onChange={onOrderByChange}
              className="radio-group"
            >
              {options.map((option) => (
                <FormControlLabel
                  className="radio-label"
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio
                      className="radio-button"
                      icon={<span className="uncheck" />}
                      checkedIcon={<span className="icon-check-solid" />}
                    />
                  }
                  label={option.name}
                />
              ))}
            </RadioGroup>
            <hr className="divider" />
          </>
        )}

        <RadioGroup
          aria-label="order"
          name="order"
          value={order}
          onChange={onOrderChange}
          className="radio-group"
        >
          <FormControlLabel
            value="asc"
            className="radio-label"
            control={
              <Radio
                className="radio-button"
                icon={<span className="uncheck" />}
                checkedIcon={<span className="icon-check-solid" />}
              />
            }
            label="Ascending"
          />
          <FormControlLabel
            value="desc"
            className="radio-label"
            control={
              <Radio
                className="radio-button"
                icon={<span className="uncheck" />}
                checkedIcon={<span className="icon-check-solid" />}
              />
            }
            label="Descending"
          />
        </RadioGroup>
      </div>
    </Popover>
  );
}

SortSelectPopover.propTypes = {
  anchorEl: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Array),
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onClose: PropTypes.func,
  onOrderByChange: PropTypes.func,
  onOrderChange: PropTypes.func,
};

SortSelectPopover.defaultProps = {
  anchorEl: null,
  onClose: () => {},
  onOrderByChange: () => {},
  onOrderChange: () => {},
};

export default React.memo(SortSelectPopover);
