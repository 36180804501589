// Create Material Handler component
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import { useMountEffect } from '../../../commons/CustomHooks';
import { addMaterial } from '../../../resources/ResourceServices';
import { ResourceType } from '../../../app/appConstants';

const CreateMaterialHandler = (props) => {
  const { resourceId, cmsDirectoryId, setCurrentPage, spaceId, firstBlockId, onTrigger } = props;

  useMountEffect(() => {
    // has resourceId, but not materialId
    const material = {
      resourceId: resourceId,
      fileName: 'Untitled',
      fileDescription: '',
      resourceType: ResourceType.image,
    };
    addMaterial(spaceId, cmsDirectoryId, material)
      .then((result) => {
        // console.log('### addMaterial result', result);
        if (result?.materialId) {
          // console.log('### 3 addMaterial');
          setCurrentPage((prevState) => {
            if (!prevState) return prevState;
            const newState = _cloneDeep(prevState);
            const foundBlock = newState.blocks.find((r) => r.id === firstBlockId);
            if (!foundBlock?.items?.length) {
              return prevState;
            }
            foundBlock.items[0].backgroundImageUrlMaterialId = result?.materialId;
            foundBlock.items[0].backgroundImageUrlResourceId = resourceId;
            return newState;
          });
        }
      })
      .catch((error) => {
        console.log('### error', error);
      });

    if (onTrigger) onTrigger();
  });

  return null;
};

CreateMaterialHandler.propTypes = {
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cmsDirectoryId: PropTypes.string,
  setCurrentPage: PropTypes.func,
  spaceId: PropTypes.string,
  firstBlockId: PropTypes.string,
  onTrigger: PropTypes.func,
};

export default CreateMaterialHandler;
