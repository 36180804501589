import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import OfficialButton from '../../../components/OfficialButtons';
import {
  applyCMSChangesToSpaces,
  getCreatedFromTemplateSpacesCount,
  getSpaceChangesCount,
} from '../../../homepage/spaceService';
import queryCache, { CacheKeys } from '../../../app/queryCache';
import { sendNotification, sleep } from '../../../commons/utils';
import ApplyChangesDialog from '../../designer/ApplyChangesDialog';
import i18n from '../../../i18n';
import { useAppContext } from '../../../components/AppProvider';

function PublishChangesButton(props) {
  const { templateRefId, spaceId } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const { portalHubConnection } = useAppContext();

  const derivedSpacesCountQuery = useQuery({
    queryKey: [CacheKeys.getCreatedFromTemplateSpacesCount, templateRefId],
    queryFn: async () => {
      const resp = await getCreatedFromTemplateSpacesCount(templateRefId);
      return resp;
    },

    retry: 0,
  });

  const getSpaceChangesCountQuery = useQuery({
    queryKey: [CacheKeys.getSpaceChangesCount, spaceId],
    queryFn: async () => {
      const resp = await getSpaceChangesCount(spaceId);
      return resp;
    },

    retry: 3,
    refetchOnMount: 'always',
  });

  const refetchSpaceChangesCount = getSpaceChangesCountQuery.refetch;

  useEffect(() => {
    const onSpaceChanged = (message) => {
      console.log('### 51 SpaceChanged listener: ', message);
      if (message?.spaceId !== spaceId) return;
      refetchSpaceChangesCount();
    };

    const onSpacePublished = (message) => {
      console.log('### 81 SpacePublished listener: ', message);
      if (message?.spaceId !== spaceId) return;
      queryCache.setQueryData([CacheKeys.getSpaceChangesCount, spaceId], 0);
      queryCache.removeQueries({
        queryKey: [CacheKeys.getCreatedFromTemplateSpaces, templateRefId],
        exact: false,
      });
    };
    if (portalHubConnection) {
      portalHubConnection.on('SpaceChanged', onSpaceChanged);
      portalHubConnection.on('SpacePublished', onSpacePublished);
    }

    return () => {
      if (portalHubConnection) {
        portalHubConnection.off('SpaceChanged', onSpaceChanged);
        portalHubConnection.off('SpacePublished', onSpacePublished);
      }
    };
  }, [spaceId, portalHubConnection, refetchSpaceChangesCount, templateRefId]);

  const spaceChangesCount = getSpaceChangesCountQuery?.data;
  const derivedSpacesCount = derivedSpacesCountQuery?.data;
  const isDisabledPublishBtn =
    !derivedSpacesCount?.numberOfShouldUpdateSpaces || !spaceChangesCount || !portalHubConnection;

  async function handlePublishButtonClick() {
    try {
      if (spaceChangesCount > 0 && derivedSpacesCount?.numberOfShouldUpdateSpaces > 0) {
        const { isConfirmed, spaceIds, isOverridden } = await ApplyChangesDialog.show(
          templateRefId,
          spaceId
        );
        if (!isConfirmed) {
          // clicking CANCEL will navigate them back to the CMS so they can continue update the CMS design
          return;
        }
        if (spaceIds.length > 0) {
          setIsLoading(true);
          // apply changes to selected spaces
          await applyCMSChangesToSpaces(templateRefId, spaceIds, isOverridden);
          sendNotification(
            i18n.t(
              'Selected spaces are updated with the new design. Please note that it may take some time for all changes to be fully reflected.'
            ),
            {
              type: 'success',
            }
          );
          await sleep(1);
          setIsLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
      sendNotification(e.message, { type: 'error' });
    }
  }

  let tooltipPublishBtn = '';
  if (!derivedSpacesCount?.numberOfOpenSpaces) {
    tooltipPublishBtn = i18n.t('This template has no open spaces to publish changes to.');
  } else if (
    !spaceChangesCount ||
    (derivedSpacesCount?.numberOfOpenSpaces > 0 &&
      derivedSpacesCount?.numberOfShouldUpdateSpaces === 0)
  ) {
    tooltipPublishBtn = i18n.t('No changes available to publish.');
  }
  return (
    <OfficialButton
      label={i18n.t('Publish')}
      variant="regular-green"
      onClick={handlePublishButtonClick}
      disabled={isDisabledPublishBtn}
      toolTipMessage={tooltipPublishBtn}
      placement="bottom"
      isProcessing={isLoading}
    />
  );
}

PublishChangesButton.propTypes = {
  templateRefId: PropTypes.string,
  spaceId: PropTypes.string,
};

export default PublishChangesButton;
