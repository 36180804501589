import React from 'react';
import ResourceFormDialog from './ResourceFormDialog';
import { GLOBAL_RESOURCE_TYPE } from './utils';
import { updateResource } from '../commons/CommonServices';
import { sendNotification } from '../commons/utils';
import { createGlobalResourcesDirectory, updateDirectory } from './globalResourcesService';
import i18n from '../i18n';

type FormData = {
  id?: number;
  name?: string;
  type?: (typeof GLOBAL_RESOURCE_TYPE)[keyof typeof GLOBAL_RESOURCE_TYPE];
  resourceId?: number;
};

type GlobalResourcesFormDialogProps = {
  selectedFolderId?: number;
  selectedFile?: FormData;
  isEditing?: boolean;
  onClose?: (result: boolean) => void;
};

function GlobalResourcesFormDialog(props: GlobalResourcesFormDialogProps) {
  const { selectedFolderId, selectedFile, isEditing, onClose } = props;
  const [openResourceForm, setOpenResourceForm] = React.useState<boolean>(true);
  const [formData, setFormData] = React.useState<FormData | undefined>(selectedFile);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

  function handleCloseResourceForm(result: boolean) {
    setOpenResourceForm(false);
    setFormData(undefined);
    if (onClose) {
      onClose(result);
    }
  }

  const handleFolderOrResourceNameChange = (name: string, value: string) => {
    const newData = { ...formData, [name]: value };
    setFormData(newData);
  };

  async function handleAddOrUpdateResourceButtonClick() {
    try {
      setIsProcessing(true);

      if (formData?.resourceId) {
        const updatedResource = {
          fileName: formData.name,
          fileDescription: '',
        };
        await updateResource(formData.resourceId, updatedResource);
        sendNotification(i18n.t('Updated successfully'), { type: 'success' });
      } else if (formData?.id && isEditing) {
        await updateDirectory(formData.id, { name: formData.name });
        sendNotification(i18n.t('Updated successfully'), { type: 'success' });
      } else {
        const newFolder = {
          name: formData?.name,
          description: '',
          parentId: selectedFolderId,
        };
        await createGlobalResourcesDirectory(newFolder);
        sendNotification(i18n.t('Created successfully'), { type: 'success' });
      }
      setIsProcessing(false);
      handleCloseResourceForm(true);
    } catch (error) {
      let message;
      if (error instanceof Error) {
        message = error.message;
      } else {
        message = String(error);
      }
      if (message) {
        sendNotification(message, { type: 'error' });
      }

      setIsProcessing(false);
    }
  }

  function renderResourceForm() {
    if (!openResourceForm) return null;
    return (
      <ResourceFormDialog
        onClose={() => handleCloseResourceForm(false)}
        selectedResource={formData}
        isProcessing={isProcessing}
        onChange={handleFolderOrResourceNameChange}
        onSubmit={handleAddOrUpdateResourceButtonClick}
      />
    );
  }

  return renderResourceForm();
}

export default React.memo(GlobalResourcesFormDialog);
