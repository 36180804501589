import React from 'react';
import { Column } from '@material-table/core';
import './style.scss';
import { ISpaceUser } from '../../types/User';
import SpaceUserAvatar from '../components/SpaceUserAvatar';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import { SpaceUserDetailsTab } from '../SpaceUserDetailsModal';
import Table from '../components/Table';
import { EngagementDetails, EngagementUser } from '../types/engagement';
import { Ids } from '../../commons/pendoTaggings';

function convertToUserInfo(userInfo: EngagementUser): ISpaceUser {
  return {
    id: userInfo.userInfoId,
    userInfoId: userInfo.userInfoId,
    firstName: userInfo.firstName,
    orgRole: userInfo.orgRole,
    lastName: userInfo.lastName,
    email: userInfo.email,
    fullName: userInfo.fullName,
    isOrganizationUser: userInfo.isOrganizationUser,
    role: userInfo.role,
    isVisitor: userInfo.isVisitor,
  };
}

function EngagementDetailsTable({ engagementDetails }: { engagementDetails: EngagementDetails[] }) {
  const { openSpaceUserModal, visibleUsers } = useSpaceAnalyticsContext();

  const [engagementUsers, setEngagementUsers] = React.useState<EngagementUser[]>([]);

  React.useEffect(() => {
    if (visibleUsers?.length > 0 && engagementDetails?.length > 0) {
      const usersDetails: EngagementUser[] = visibleUsers
        .map((user) => {
          return {
            userInfoId: user.userInfoId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            fullName: user.fullName,
            isOrganizationUser: user.isOrganizationUser,
            role: user.role,
            orgRole: user.orgRole,
            totalActions:
              engagementDetails.find((detail) => detail.userInfoId === user.userInfoId)
                ?.totalActions || 0,
            isVisitor: user.isVisitor,
          } as EngagementUser;
        })
        .sort((a, b) => b.totalActions - a.totalActions);
      setEngagementUsers(usersDetails);
    }
  }, [visibleUsers, engagementDetails]);

  function handleOnClickLink(engagementSummary: EngagementUser) {
    const userInfo = convertToUserInfo(engagementSummary);
    openSpaceUserModal(userInfo, SpaceUserDetailsTab.ENGAGEMENT);
  }

  function renderTable() {
    const columns: Column<EngagementUser>[] = [
      {
        title: 'Name',
        field: 'firstName',
        cellStyle: {
          width: '30%',
        },
        render: (rowData: EngagementUser) => {
          const userInfo: ISpaceUser = {
            id: rowData.userInfoId,
            userInfoId: rowData.userInfoId,
            firstName: rowData.firstName,
            lastName: rowData.lastName,
            orgRole: rowData.orgRole,
            email: rowData.email,
            fullName: rowData.fullName,
            isOrganizationUser: rowData.isOrganizationUser,
            role: rowData.role,
            isVisitor: rowData.isVisitor,
          };
          return <SpaceUserAvatar userInfo={userInfo} />;
        },
      },
      {
        title: 'Actions',
        field: 'totalActions',
        cellStyle: {
          width: '20%',
        },
        defaultSort: 'desc',
        render: (rowData: EngagementUser) => {
          return (
            <span
              className="btn-link"
              onClick={() => handleOnClickLink(rowData)}
              data-id={Ids.engagementParticipantActions}
            >
              {rowData.totalActions}
            </span>
          );
        },
      },
    ];

    console.log('engagementUsers', engagementUsers);

    return (
      <Table
        columns={columns}
        data={engagementUsers}
        options={{
          search: false,
          toolbar: false,
        }}
      />
    );
  }

  return renderTable();
}
export default React.memo(EngagementDetailsTable);
