import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { sendNotification } from '../../commons/utils';
import ConfirmService from '../../components/ConfirmService';
import { Item } from '../../components/Material/ActionMenu';
import i18n from '../../i18n';
import { removeRuntimeDirectory, removeDirectory } from '../../resources/ResourceServices';

const paperProps = {
  style: {
    minWidth: 200,
  },
};

const FolderActionMenu = (props) => {
  const {
    spaceId,
    folder,
    onClose,
    anchorEl,
    onOpenForm,
    isViewOnly,
    isShowEditButton,
    isShowDeleteButton,
    isPortal,
    onShareLinkFolderClick,
    handleDownloadFolder,
    handleMoveResources,
  } = props;

  const isBlockedByFirewall = !!sessionStorage.getItem('isBlockedByFirewall');
  const { id } = folder;
  if (!anchorEl) return false;

  async function handleDeleteButtonClick() {
    if (!folder) return false;
    const isConfirmed = await ConfirmService.show(
      `${i18n.t('DELETE RESOURCE')}`,
      <>
        <b>This can’t be undone</b>
        <br />
        <span>Are you sure you want to delete this resource?</span>
      </>,
      i18n.t('Yes'),
      i18n.t('Cancel')
    );
    if (!isConfirmed) {
      onClose();
      return false;
    }

    try {
      if (isPortal) {
        await removeDirectory(spaceId, id);
      } else {
        await removeRuntimeDirectory(spaceId, id);
      }
      onClose();
      sendNotification(i18n.t('Deleted successfully'), { type: 'success' });
      return true;
    } catch (error) {
      onClose();
      sendNotification(error.message, { type: 'error' });
    }

    return false;
  }

  async function handleShareLinkButtonClick() {
    if (onShareLinkFolderClick) {
      onShareLinkFolderClick(id);
    }
    onClose();
  }

  async function handleDownloadButtonEvent() {
    if (!folder) return;
    if (handleDownloadFolder) {
      await handleDownloadFolder(isPortal, spaceId, folder.id, folder);
    }
    onClose();
  }

  function handleEditButtonClick() {
    onOpenForm('folder', folder.id);
    onClose(false);
  }

  function handleMoveFolder() {
    handleMoveResources(folder);
    onClose(false);
  }

  return (
    <Menu
      className="add-material-action-menu"
      PaperProps={paperProps}
      anchorEl={anchorEl}
      open
      keepMounted={false}
      onClose={onClose}
      classes={{ paper: 'select-menu' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {((handleShareLinkButtonClick && !isViewOnly) || isBlockedByFirewall) &&
        !folder.isExternal && (
          <Item onClick={handleShareLinkButtonClick}>
            <section className="displayFlex row-direction alignItemsCenter gap8">
              <LinkIcon fontSize="small" />
              <span>{i18n.t('Copy link')}</span>
            </section>
          </Item>
        )}

      {handleDownloadFolder && (
        <Item onClick={handleDownloadButtonEvent}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-download displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Download')}</span>
          </section>
        </Item>
      )}

      {handleMoveResources && (
        <Item onClick={handleMoveFolder}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-move displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Move')}</span>
          </section>
        </Item>
      )}

      {isShowEditButton && (
        <Item onClick={handleEditButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-update displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Rename')}</span>
          </section>
        </Item>
      )}

      {isShowDeleteButton && (
        <Item onClick={handleDeleteButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-delete displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Delete')}</span>
          </section>
        </Item>
      )}
    </Menu>
  );
};

FolderActionMenu.propTypes = {
  spaceId: PropTypes.string,
  folder: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  anchorEl: PropTypes.instanceOf(Object),
  onOpenForm: PropTypes.func,
  isPortal: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  isShowEditButton: PropTypes.bool,
  isShowDeleteButton: PropTypes.bool,
  onShareLinkFolderClick: PropTypes.func,
  handleDownloadFolder: PropTypes.func,
  handleMoveResources: PropTypes.func,
};

FolderActionMenu.defaultProps = {
  isViewOnly: false,
  isPortal: false,
};

export default FolderActionMenu;
