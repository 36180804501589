import React from 'react';
import OfficialButtons from '../../components/OfficialButtons';
import i18n from '../../i18n';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';

interface ShareSpaceButtonProps {
  dataId: string;
}

interface ExtendedNewWindow extends Window {
  data?: ShareSpaceButtonMetric;
}

type ShareSpaceButtonMetric = {
  openSharePopup: boolean;
};

const ShareSpaceButton: React.FC<ShareSpaceButtonProps> = (props) => {
  const { dataId } = props;
  const { space } = useSpaceAnalyticsContext();
  const spaceId = space?.id;

  async function handleShareButtonClick() {
    const newWindow: ExtendedNewWindow | null = window.open(`/s/${spaceId}`, '_blank');
    if (newWindow) {
      newWindow.data = { openSharePopup: true };
    }
  }

  return (
    <OfficialButtons
      label={i18n.t('SHARE SPACE')}
      dataId={dataId}
      icon={<span className="icon-share"></span>}
      onClick={handleShareButtonClick}
      variant="rectangle-secondary"
    />
  );
};

export default ShareSpaceButton;
