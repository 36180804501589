import React, { useState } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import {
  Box,
  MenuItem,
  Select,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  FormControl,
} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import GroupIcon from '@mui/icons-material/Group';
import i18n from '../../i18n';
import ConfirmService from '../../components/ConfirmService';
import { MenuEnhancedProps } from '../../components/SelectEnhanced';
import './ShareSettings.scss';
import LightTooltip from '../../components/LightTooltip';
import { PrivacySpace } from '../../app/appConstants';

const getSpaceTypeOptions = memoizeOne((currentPrivacy, isEdit) => {
  const isPrivacy = currentPrivacy === PrivacySpace.PRIVATE;
  const isRestricted = currentPrivacy === PrivacySpace.RESTRICTED;

  const options = [
    {
      title: 'Public access',
      value: PrivacySpace.PUBLIC,
      message: 'Accessible to anyone with the link.',
      icon: <PublicIcon />,
      disabled: (isPrivacy || isRestricted) && isEdit,
    },
    {
      title: 'Restricted access',
      value: PrivacySpace.RESTRICTED,
      message: 'Requires registration (name and email) to access with the link.',
      icon: <GroupIcon />,
      disabled: isPrivacy && isEdit,
    },
    {
      title: 'Private access',
      value: PrivacySpace.PRIVATE,
      message: 'Only invited users can enter. Two-Factor Authentication is required.',
      icon: <span className="icon-lock "></span>,
      disabled: false,
    },
  ];
  console.log('options: ', options);
  return options;
});

const ShareSettings = (props) => {
  const { spacePrivacy, isEdit, onChange, isTemplate, className, isMobile } = props;

  const [privacy, setPrivacy] = useState(spacePrivacy);

  const spaceTypeOptions = getSpaceTypeOptions(spacePrivacy, isEdit);
  const selectedPrivacyLevel = spaceTypeOptions.find((item) => item.value === privacy);
  const disableSelect = isEdit && spacePrivacy === PrivacySpace.PUBLIC;

  const handleChangePrivacy = async (event) => {
    const newValue = event.target.value;

    if (spacePrivacy === newValue) {
      return;
    }
    if (!isEdit) {
      onChange('spacePrivacy', newValue);
      setPrivacy(newValue);
      return;
    }

    const disabled = spaceTypeOptions.find((item) => item.value === newValue).disabled;
    if (disabled) {
      return;
    }
    let message;
    let title;

    if (!isTemplate && spacePrivacy === PrivacySpace.PUBLIC && newValue === PrivacySpace.PRIVATE) {
      title = i18n.t('You are switching to Private Space. ');
      message = i18n.t(
        'This means that only users added in this space are granted access. Space analytics data will be reset.'
      );
    } else if (
      !isTemplate &&
      spacePrivacy === PrivacySpace.PUBLIC &&
      newValue === PrivacySpace.RESTRICTED
    ) {
      title = i18n.t('You are switching to Private Space.');
      message = i18n.t(
        'This means that anyone can enter your space and see your data. Space analytics data will be reset.'
      );
    } else if (spacePrivacy === PrivacySpace.RESTRICTED && newValue === PrivacySpace.PRIVATE) {
      title = i18n.t('You are switching to Private Space.');
      message = i18n.t('This means that only users added in this space are granted access.');
    }
    const hasConfirmed = await ConfirmService.show(
      'CHANGE SPACE PRIVACY',
      <>
        <b>{title}</b>
        <br />
        <span>{message}</span>
        <br />
        <span>{i18n.t('Do you want to continue')}?</span>
      </>,
      'yes',
      'Cancel'
    );
    if (hasConfirmed) {
      onChange('spacePrivacy', newValue);
      setPrivacy(newValue);
    }
  };

  return (
    <Box className="box-width-76 theme-setting-box mb-8">
      <LightTooltip
        title={i18n.t(
          `Changing the share settings is not possible. To modify them, please duplicate the ${isTemplate ? 'template' : 'space'}.`
        )}
        disableHoverListener={!disableSelect}
        disableFocusListener={!disableSelect}
      >
        <div className="share-settings-field">
          <FormControl fullWidth>
            <label className="share-settings-field-label">{i18n.t('Share settings')}</label>
            <Select
              value={privacy}
              disabled={disableSelect}
              onChange={handleChangePrivacy}
              variant="outlined"
              className={`select-accept ${disableSelect ? 'disabled' : ''}`}
              MenuProps={{
                ...MenuEnhancedProps,
                classes: { paper: `select-accept-menu ${className}` },
              }}
              onFocus={props.onFocus}
              startAdornment={
                <InputAdornment position="start">{selectedPrivacyLevel?.icon}</InputAdornment>
              }
              renderValue={() => selectedPrivacyLevel?.title}
            >
              {spaceTypeOptions.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  <LightTooltip
                    title={i18n.t(
                      'Lowering the privacy level is not possible. To change to a lower privacy level, please duplicate the space.'
                    )}
                    disableHoverListener={!item.disabled}
                    disableFocusListener={!item.disabled}
                    placement={isMobile ? 'top' : 'right'}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={`share-settings-field-item ${item.disabled ? 'disabled' : ''}`}>
                      <ListItemIcon className="share-settings-field-icon">{item.icon}</ListItemIcon>
                      <ListItemText>
                        <div className="share-settings-field-content">
                          <div>{item.title}</div>
                          <div className="text-gray">{item.message}</div>
                        </div>
                      </ListItemText>
                    </div>
                  </LightTooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </LightTooltip>
    </Box>
  );
};

ShareSettings.propTypes = {
  spacePrivacy: PropTypes.string,
  isEdit: PropTypes.bool,
  onChange: PropTypes.func,
  isMobile: PropTypes.bool,
  onFocus: PropTypes.func,
  isTemplate: PropTypes.bool,
  className: PropTypes.string,
};

ShareSettings.defaultProps = {
  disabledSpaceSetup: false,
};

export default ShareSettings;
