import { ResourceType } from '../../app/appConstants';
import { MaterialBasicInfo } from '../types/resources';

export const filterAvailableResources = (
  allSpaceResources: MaterialBasicInfo[] | undefined,
  cmsMaterialIds: number[]
) => {
  if (!allSpaceResources) {
    return undefined;
  }
  return allSpaceResources.filter(
    (x) =>
      !x.isCmsFile ||
      (cmsMaterialIds.includes(x.materialId) && x.resourceType !== ResourceType.image)
  );
};
