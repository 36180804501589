import { memo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { addComment } from './CommentService';
import { CommentStatus } from '../../app/appConstants';

const AddCommentHandler = (props) => {
  const { comment, setComment, channelId } = props;
  const refId = useRef();

  const sendNewComment = useCallback(async () => {
    refId.current = true;
    const resp = await addComment(channelId, comment);
    const status = resp.id ? CommentStatus.SENT : CommentStatus.FAILED;
    refId.current = false;

    setComment({ status, id: resp.id, refId: comment.refId });
  }, [channelId, comment, setComment]);

  useEffect(() => {
    if (!refId.current) {
      sendNewComment();
    }
  }, [sendNewComment]);

  return null;
};

AddCommentHandler.propTypes = {
  comment: PropTypes.instanceOf(Object),
  setComment: PropTypes.func,
  channelId: PropTypes.number,
};

export default memo(AddCommentHandler);
