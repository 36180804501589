import React from 'react';
import { Column } from '@material-table/core';
import { ISpaceUser } from '../../types/User';
import SpaceUserAvatar from '../components/SpaceUserAvatar';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import Table from '../components/Table';
import StatusAssessmentTag from '../../assessments/components/StatusAssessmentTag';
import LightTooltip from '../../components/LightTooltip';
import i18n from '../../i18n';
import { formatDateTime, parseDateTimeStringFromServer } from '../../commons/DateTimeUtils';
import { IUserAssessment } from '../AssessmentTable/types';

type AssessmentUser = IUserAssessment & ISpaceUser;

type Iprops = {
  data: IUserAssessment[];
};

function UsersDetailsByAssessmentTable(props: Iprops) {
  const { data } = props;
  const { visibleUsers } = useSpaceAnalyticsContext();

  const [assessmentUsers, setAssessmentUsers] = React.useState<AssessmentUser[]>([]);

  React.useEffect(() => {
    if (visibleUsers?.length > 0 && data?.length > 0) {
      const usersDetails: AssessmentUser[] = [];
      data.forEach((assessment) => {
        const user: ISpaceUser | undefined = visibleUsers.find(
          (visibleUser) => visibleUser.id === assessment.userInfoId
        );
        if (user) {
          const convertUser = {
            userInfoId: user.userInfoId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            fullName: user.fullName,
            isOrganizationUser: user.isOrganizationUser,
            role: user.role,
            orgRole: user.orgRole,
            isVisitor: user.isVisitor,
          };
          usersDetails.push({
            ...convertUser,
            ...assessment,
          });
        }
      });
      setAssessmentUsers(usersDetails);
    }
  }, [visibleUsers, data]);

  function renderTable() {
    const columns: Column<AssessmentUser>[] = [
      {
        title: 'Name',
        field: 'firstName',
        cellStyle: {
          width: '40%',
        },
        render: (rowData: AssessmentUser) => {
          return <SpaceUserAvatar userInfo={rowData} />;
        },
      },
      {
        title: 'Status',
        field: 'isPassed',
        cellStyle: {
          width: '20%',
        },
        render: (rowData: AssessmentUser) => {
          return <StatusAssessmentTag assessment={rowData} />;
        },
      },
      {
        title: 'Score',
        field: 'percentageScore',
        cellStyle: {
          width: '20%',
        },
        defaultSort: 'desc',
        render(rowData: AssessmentUser) {
          if (rowData.percentageScore === null) {
            return (
              <LightTooltip title={i18n.t("The user hasn't taken the assessment yet")}>
                <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
              </LightTooltip>
            );
          }
          return <span>{Math.round(rowData.percentageScore * 100)}%</span>;
        },
      },
      {
        title: 'Submitted date',
        field: 'completedOn',
        cellStyle: {
          width: '20%',
        },
        render: (rowData: AssessmentUser) => {
          return rowData ? (
            formatDateTime(
              parseDateTimeStringFromServer(rowData.completedOn)?.local(),
              'MMM DD, yyyy'
            )
          ) : (
            <LightTooltip title={i18n.t('The user hasn’t joined the space yet.')}>
              <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
            </LightTooltip>
          );
        },
      },
    ];

    return (
      <Table
        columns={columns}
        data={assessmentUsers}
        options={{
          search: false,
          toolbar: false,
        }}
      />
    );
  }

  return renderTable();
}
export default React.memo(UsersDetailsByAssessmentTable);
