import _debounce from 'lodash/debounce';
import Api from '../commons/api';
import { getResourceApi } from '../commons/utils';
import { FetchResourcesPayload, ResourceResponse } from './types';

let resourceIds: number[] = [];
let resolves: Array<(data: ResourceResponse[]) => void> = [];

const debouncedFetchResources = _debounce(
  async () => {
    const copyOfResourceIds = [...resourceIds];
    const copyOfResolves = [...resolves];
    resolves = [];
    resourceIds = [];
    console.log('fetch resourceIds', copyOfResourceIds);
    let resources: ResourceResponse[];
    try {
      resources = await Api.post(`${getResourceApi()}/api/resources/srcs`, {
        resourceIds: copyOfResourceIds,
      });
    } catch (error) {
      resources = [];
      if (error instanceof Error) console.error(`Error when fetching resources: ${error.message}`);
    }
    copyOfResolves.forEach((resolve) => {
      resolve(resources);
    });
  },
  250,
  { maxWait: 3000 }
);

async function accumulateAndFetchResources(ids: number[]): Promise<ResourceResponse[]> {
  return new Promise((resolve) => {
    ids.forEach((id) => {
      if (!id) {
        return;
      }
      if (resourceIds.indexOf(id) < 0) {
        resourceIds.push(id);
      }
    });
    resolves.push(resolve);
    debouncedFetchResources();
    if (resourceIds.length > 50) {
      debouncedFetchResources.flush();
    }
  });
}

export async function fetchResourcesByIdsInPortal(
  ids: number[],
  includesResourceInteractions: boolean,
  includeRecordedFiles: boolean
): Promise<ResourceResponse[]> {
  if (ids.length === 0) {
    return [];
  }
  if (includesResourceInteractions || includeRecordedFiles) {
    const body: FetchResourcesPayload = { resourceIds: ids };
    if (includesResourceInteractions) {
      body.includesResourceInteractions = includesResourceInteractions;
    }

    if (includeRecordedFiles) {
      body.includeRecordedFiles = includeRecordedFiles;
    }
    const resources = await Api.post(`${getResourceApi()}/api/resources/srcs`, body);
    return resources;
  } else {
    const resources = await accumulateAndFetchResources(ids);
    return resources.filter((resource: ResourceResponse) => {
      return ids.indexOf(resource.resourceId) >= 0;
    });
  }
}
