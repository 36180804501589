import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchResultUserSpaceAssessment, startSpaceAssessment } from '../services';
import { CacheKeys } from '../../app/queryCache';

function useSpaceAssessmentQuery(assessmentId, type, userAssessmentId) {
  const userAssessmentQuery = useQuery({
    queryKey: [CacheKeys.fetchSpaceAssessment, userAssessmentId, assessmentId, type],
    queryFn: async () => {
      if (type === 'result') {
        const resp = await fetchResultUserSpaceAssessment(userAssessmentId);
        return resp;
      }
      const resp = await startSpaceAssessment(assessmentId);
      return resp;
    },
    enabled: !!assessmentId,
    refetchOnMount: true,
  });

  const assessment = useMemo(() => {
    if (!userAssessmentQuery.data) {
      return null;
    }

    if (type === 'result') {
      return {
        ...userAssessmentQuery.data,
        questions: userAssessmentQuery.data.questions.map((question) => {
          return {
            ...question,
            options: question.options.map((option) => {
              return {
                ...option,
                checked: question.answer?.data?.some((id) => id === option.id),
              };
            }),
          };
        }),
      };
    }
    return userAssessmentQuery.data;
  }, [userAssessmentQuery.data, type]);

  return {
    isLoading: userAssessmentQuery.isLoading,
    data: assessment,
    refetch: userAssessmentQuery.refetch,
  };
}

export default useSpaceAssessmentQuery;
