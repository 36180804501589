import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import i18n from '../i18n';
// import withRouter from '../commons/withRouter';
import OfficialButton from './OfficialButtons';
// import { LogLevels } from '../app/AppInsights';

function handleClickBackToHome() {
  location.href = '/';
}
export class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: null };
  }

  // componentDidMount() {
  //   const { history } = this.props;
  //   if (history) {
  //     history.listen(() => {
  //       if (this.state.hasError) {
  //         this.setState({
  //           hasError: false,
  //           message: null,
  //         });
  //       }
  //     });
  //   }
  // }

  componentDidCatch(error) {
    const { ctx } = this.props;
    try {
      Object.assign(error, { severityLevel: 4 });
      console.error(error, { ctx });
    } catch (e) {
      console.error(e, { ctx });
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    const message =
      error.displayableError === true ? error.message : i18n.t('Sorry, something went wrong.');
    return { hasError: true, message };
  }

  // reset() {
  //   this.setState({ hasError: false });
  // }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   console.log('ERROR', error, errorInfo);
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const actions =
        this.props.actions === null ? (
          <OfficialButton
            variant="regular-primary"
            label={i18n.t('Back to Home')}
            onClick={handleClickBackToHome}
            fullWidth
          />
        ) : (
          this.props.actions
        );
      return (
        <ErrorMessage
          style={{ position: 'absolute' }}
          renderActions={() => actions}
          message={this.state.message || i18n.t('error_message')}
        />
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  ctx: PropTypes.string,
  actions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.instanceOf(Array)]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
  // history: PropTypes.instanceOf(Object),
};

ErrorBoundary.defaultProps = {
  actions: null,
  children: null,
};

export default ErrorBoundary;
