import React, {
  // createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import NoDaTaBox from '../../components/NoDataBox';
import i18n from '../../i18n';
import './GlobalResourcesOneDriveTable.scss';
import { getOneDriveResources, searchOneDriveItem } from './onedriveService';
import queryCache, { CacheKeys } from '../../app/queryCache';
import { formatOneDriveResources, mapOneDriveItemToResource } from './utils';
import {
  GLOBAL_PROVIDER_TYPE,
  GLOBAL_RESOURCES_INFO,
  GLOBAL_RESOURCE_TYPE,
} from '../../globalResources/utils';
import { getAcceptResourceTypes } from '../../commons/ResourceUtils';
import { dismissNotification, sendCustomNotification, sendNotification } from '../../commons/utils';
import { useIntegrationContext } from '../IntegrationContextProvider';
import { SERVICE_PROVIDER } from '../integrationConstants';
// import WarningMessage from '../../components/WarningMessage';
import ExternalStorageNoConnectionBox from '../components/ExternalStorageNoConnectionBox';
import OneDriveConnectButton from './OneDriveConnectButton';
import GlobalResourcesLoading from '../../globalResources/GlobalResourcesLoading';
import ResourcesDisplayView from '../../globalResources/ResourcesDisplayView/ResourcesDisplayView';
import { useFeatureFlagsContext } from '../../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../../app/appConstants';
import ResourcesViewControls from '../../components/ResourcesView/ResourcesViewControls';
import { useResourcesViewContext } from '../../components/ResourcesView/ResourcesViewContextProvider';
import { useGlobalResourcesContext } from '../../globalResources/context/GlobalResourcesContextProvider';
import { useBreadcrumbContext } from '../../globalResources/context/BreadcrumbsContextProvider';
import ApiError from '../../commons/ApiError';
import WarningMessage from '../../components/WarningMessage';

const getFormatData = memoizeOne((accept, items) => {
  let formattedData = null;
  if (!items?.length) {
    return [];
  }
  if (accept) {
    const { acceptResourceTypes } = getAcceptResourceTypes(accept);
    formattedData = formatOneDriveResources(items, acceptResourceTypes);
  } else {
    formattedData = formatOneDriveResources(items);
  }
  return formattedData;
});
const pageSize = 50;

const GlobalResourcesOneDriveTable = forwardRef((props, ref) => {
  const {
    isGlobalResourcesEnabled,
    isOneDriveEnabled,
    multiple,
    isUserView,
    previewItemInPopup,
    selectType,
    otherTypeDisabled,
  } = props;

  const { resources, setResources } = useGlobalResourcesContext();

  const {
    currentBreadcrumbItem: selectedFolder,
    goToBreadcrumbItem,
    goToRootItem,
    updateBreadcrumbItem,
    clearBreadcrumbsData,
  } = useBreadcrumbContext();

  const { order, orderBy, searchText, setSearchText, setSortData } = useResourcesViewContext();

  // const [resources, setResources] = useState([]);

  const [isSearching, setIsSearching] = useState(false);

  const directoryId = !selectedFolder || selectedFolder.name === '_root' ? null : selectedFolder.id;

  const { removeConnection, getProviderData } = useIntegrationContext();

  const providerData = getProviderData(SERVICE_PROVIDER.ONEDRIVE);
  const enabled = isGlobalResourcesEnabled && isOneDriveEnabled;
  // const tableRef = createRef();
  const connectButtonRef = useRef();

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledMaterialSlider] = checkEnabledFeatures([FeatureFlagsType.MATERIAL_SLIDER]);

  useImperativeHandle(ref, () => ({
    navigate() {},
  }));

  const directoryInfoQuery = useQuery({
    queryKey: [
      CacheKeys.getOneDriveResources,
      'directory-info',
      selectedFolder?.driveId,
      directoryId,
    ],
    enabled: false,
  });

  useEffect(() => {
    if (!directoryInfoQuery.data?.id) {
      return;
    }
    updateBreadcrumbItem(directoryInfoQuery.data);
  }, [directoryInfoQuery.data, updateBreadcrumbItem]);

  async function handleReconnectOneDrive() {
    dismissNotification('onedrive_auth_error_alert');
    await removeConnection(SERVICE_PROVIDER.ONEDRIVE);
    if (connectButtonRef.current?.connectOneDrive) {
      connectButtonRef.current?.connectOneDrive();
    }
  }

  const fetchData = async ({ pageParam }) => {
    try {
      // const refreshPathData = !historyData;
      const response = await getOneDriveResources(
        selectedFolder?.driveId,
        directoryId,
        !isUserView,
        pageSize,
        pageParam,
        orderBy,
        order
      );

      // if (response) {
      //   if (!selectedFolder) {
      //     const queryKey = [CacheKeys.getOneDriveResources, response.driveId, response.id];
      //     queryCache.setQueryData(queryKey, (oldData) => {
      //       if (oldData) return oldData;
      //       const newData = { pages: [response.children], pageParams: [0] };
      //       return newData;
      //     });
      //   }
      // }
      console.log('### getOneDriveResources data response: ', response);
      queryCache.setQueryData(
        [CacheKeys.getOneDriveResources, 'directory-info', selectedFolder?.driveId, directoryId],
        {
          name: response?.name,
          parentId: response?.parentId,
          id: response?.id,
          driveId: response?.driveId,
        }
      );
      if (!response?.children || !response.children.items?.length) {
        return { items: [] };
      }
      if (response?.id && !response.children.items[0].parentId) {
        response.children.items.forEach((item) => {
          Object.assign(item, { parentId: response.id });
        });
      }
      return response.children;
    } catch (error) {
      console.log(error);
      clearBreadcrumbsData();
      if (error instanceof ApiError) {
        if (error.error?.code === '7401') {
          sendCustomNotification(
            <WarningMessage
              title="Whoops! OneDrive has lost connection with SP_CE"
              description="Please reconnect your OneDrive"
              buttonLabel="Reconnect"
              onClickButton={handleReconnectOneDrive}
            />,
            { type: 'error', duration: 20000 },
            'onedrive_auth_error_alert'
          );
        } else {
          sendNotification(
            i18n.t('Please refresh your browser and try again or contact us at support@spce.com'),
            { type: 'error' },
            i18n.t('Uh-oh! Something went wrong.')
          );
        }
      }
    }
    return { items: [] };
  };
  const {
    data,
    isLoading: isLoadingResources,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [
      CacheKeys.getOneDriveResources,
      // selectedFolder?.driveId,
      directoryId,
      pageSize,
      orderBy,
      order,
    ],
    queryFn: fetchData,
    getNextPageParam: (lastPage) => {
      return lastPage.skipToken;
    },
    enabled: isOneDriveEnabled,
  });

  useEffect(() => {
    if (data) {
      console.log('### getOneDriveResources data', data);

      const resourcesData = data.pages.reduce((m, { items }) => {
        return m.concat(items);
      }, []);

      const formattedData = getFormatData(props.accept, resourcesData);
      setResources(formattedData);

      if (isEnabledMaterialSlider) {
        const viewableMaterials = formattedData
          ?.filter((m) => m.type !== GLOBAL_RESOURCE_TYPE.FOLDER)
          ?.map((m) => mapOneDriveItemToResource(m));
        sessionStorage.setItem(
          'selectedFolderMaterials',
          JSON.stringify({
            spaceFolderId: -1,
            materials: viewableMaterials,
          })
        );
      }
    }
  }, [data, isEnabledMaterialSlider, props.accept, setResources]);

  // useUnmountEffect(() => {
  //   queryCache.removeQueries([CacheKeys.getOneDriveResources], { exact: false });
  //   sessionStorage.removeItem('gr-currentOneDriveFolderId');
  // });

  function handleOnLoadMore() {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }

  async function searchResources(value) {
    const searchValue = value.toLowerCase().trim();
    goToRootItem();
    if (searchValue) {
      setSearchText(searchValue);
    }
    let results = [];

    if (searchValue?.length) {
      setIsSearching(true);
      const dataSearched = await searchOneDriveItem(searchValue);
      results = getFormatData(props.accept, dataSearched);
      setIsSearching(false);
      console.log('results: ', results);
    } else {
      const resourcesData = data.pages.reduce((m, { items }) => {
        return m.concat(items);
      }, []);
      results = getFormatData(props.accept, resourcesData);
    }

    setResources(results);
  }

  const handleOnClickRow = useCallback(
    (rowData) => {
      if (rowData.type === GLOBAL_RESOURCE_TYPE.FOLDER) {
        setSearchText('');
        const folder = {
          id: rowData.id,
          name: rowData.name,
          parentId: rowData.parentId,
          driveId: rowData.driveId,
          provider: GLOBAL_PROVIDER_TYPE.ONE_DRIVE,
        };
        goToBreadcrumbItem(folder);
      }
    },
    [setSearchText, goToBreadcrumbItem]
  );

  const handleResourceDoubleClicked = useCallback(
    (resource) => {
      if (resource.isFolder) {
        handleOnClickRow(resource);
      } else if (previewItemInPopup) {
        previewItemInPopup(mapOneDriveItemToResource(resource));
      }
    },
    [handleOnClickRow, previewItemInPopup]
  );

  if (!isGlobalResourcesEnabled) {
    return (
      <div className="global-resources-table global-resources-table-no-data">
        <NoDaTaBox
          icon={<span className="icon-resources" />}
          title={i18n.t('Enable Global Resources')}
          description={i18n.t(
            'Use global resources for your organization to simply organizing all content in one place'
          )}
        />
      </div>
    );
  }

  async function handleOnConnectSuccess() {
    await refetch();
  }

  function renderHeader() {
    if (!enabled) {
      return null;
    }
    const info = GLOBAL_RESOURCES_INFO.OneDrive;
    return (
      <div className="header">
        <section className="info-section">
          <span className="text">{info.readonly}</span>
        </section>
        <div className="actions-section">
          <ResourcesViewControls
            searchPlaceholder={i18n.t('Search file name')}
            searchText={searchText}
            onSearchChange={searchResources}
          />
        </div>
      </div>
    );
  }

  function renderNotFound() {
    if (searchText?.length) {
      return (
        <NoDaTaBox
          icon={<span className="icon-resources" />}
          title={i18n.t('No files or folders found')}
          description={i18n.t('Choose another keyword and try again.')}
        />
      );
    }

    return (
      <NoDaTaBox
        icon={<span className="icon-resources" />}
        title={i18n.t('No files or folders to display')}
      />
    );
  }

  function renderConnectButton() {
    console.log('renderConnectionButton');
    return (
      <ExternalStorageNoConnectionBox
        disabled={isOneDriveEnabled}
        serviceProvider={SERVICE_PROVIDER.ONEDRIVE}
        connectButton={
          <OneDriveConnectButton
            serviceProvider={providerData}
            ref={connectButtonRef}
            onConnectSuccess={handleOnConnectSuccess}
          />
        }
      />
    );
  }
  console.log('isOneDriveEnabled: ', isOneDriveEnabled);

  function renderData() {
    if (!isOneDriveEnabled) {
      return <div style={{ height: '27%' }} />; // a trick to push the connect button to the center
    }
    // if (!isGlobalResourcesEnabled || !isOneDriveEnabled) return null;
    const isLoading = isLoadingResources || resources === null;
    if (isLoading || isSearching) {
      return <GlobalResourcesLoading />;
    }

    const noData = !isLoading && !resources?.length;
    if (noData) {
      return (
        <div className="global-resources-table global-resources-table-no-data">
          {renderNotFound()}
        </div>
      );
    }

    return (
      <ResourcesDisplayView
        key={'onedrive-table'}
        resources={resources}
        isLoading={isLoading}
        hasMore={hasNextPage && !searchText?.length}
        onLoadMore={handleOnLoadMore}
        // className={`${noData ? 'global-resources-table-no-data' : ''}`}
        onClickRow={handleOnClickRow}
        onDoubleClickRow={handleResourceDoubleClicked}
        // onSelectionChange={handleSelectionChanged}
        multiple={multiple}
        selectType={selectType}
        otherTypeDisabled={otherTypeDisabled}
        order={order}
        orderBy={orderBy}
        setSortData={setSortData}
        disableSortingColumns={['itemsCount', 'lastModified']}
        isPortal={props.isPortal}
      />
    );
  }

  return (
    <div className="global-resources-one-drive-table">
      {renderHeader()}
      {renderData()}
      {renderConnectButton()}
      {/** Need to always keep this connect button, the re-connect flow use the connectButtonRef
       * Please check re-connect flow if you want to change UI above such as show or hide this button
       */}
    </div>
  );
});

GlobalResourcesOneDriveTable.propTypes = {
  isGlobalResourcesEnabled: PropTypes.bool.isRequired,
  isOneDriveEnabled: PropTypes.bool.isRequired,
  isUserView: PropTypes.bool,
  previewItemInPopup: PropTypes.func,
  multiple: PropTypes.bool,
  selectType: PropTypes.string,
  otherTypeDisabled: PropTypes.bool,
  accept: PropTypes.string,
  isPortal: PropTypes.bool,
};

GlobalResourcesOneDriveTable.defaultProps = {
  isUserView: false,
  multiple: false,
  onSelectionChange: null,
  isPortal: false,
};

GlobalResourcesOneDriveTable.displayName = 'GlobalResourcesOneDriveTable';

export default GlobalResourcesOneDriveTable;
