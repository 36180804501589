import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useMountEffect from '../commons/useMountEffect';
import useUnmountEffect from '../commons/useUnmountEffect';
import ButtonEnhanced from './ButtonEnhanced';
import i18n from '../i18n';
import { uuidv4 } from '../commons/utils';
import RefreshExternalResourceButton from './RefreshExternalResourceButton';

const MentimeterPlayer = (props) => {
  const [viewResult, setViewResult] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [key, setKey] = useState(uuidv4());
  const [src, resultSrc] = props.src.split('**');
  const ref = React.useRef(false);

  const handleOnReceived = (event) => {
    if (event.origin !== 'https://www.menti.com' && event.origin !== 'https://www.mentimeter.com') {
      return;
    }
    if (event.data?.pcmPixelPostMessageEvent?.ev === 'SubscribedButtonClick') {
      if (ref.current === false) {
        ref.current = true;
        setTimeout(() => {
          ref.current = false;
          setViewResult(true);
        }, 6000);
      } else {
        ref.current = false;
        setViewResult(true);
      }
    }
    if (event.data?.pcmPixelPostMessageEvent?.ev === 'PageView') {
      setProcessing(false);
    }
  };

  useMountEffect(() => {
    if (resultSrc) {
      window.addEventListener('message', handleOnReceived);
    }
  });

  useUnmountEffect(() => {
    if (resultSrc) {
      window.removeEventListener('message', handleOnReceived, false);
    }
  });

  function handleOnClick() {
    setProcessing(true);
    setViewResult(!viewResult);
  }

  function reload() {
    setKey(uuidv4());
  }

  function renderButtons() {
    if (!resultSrc) {
      return null;
    }
    if (processing) {
      return <ButtonEnhanced disabled>{i18n.t('Processing...')}</ButtonEnhanced>;
    }
    if (viewResult) {
      return <ButtonEnhanced onClick={handleOnClick}>{i18n.t('Back')}</ButtonEnhanced>;
    }
    return null;
  }

  return (
    <>
      <div
        className="iframe-player-container"
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        {props.externalTool && <RefreshExternalResourceButton onClick={reload} />}
        <iframe
          title="resource-external-url"
          key={key}
          src={
            viewResult
              ? resultSrc.replace(
                  'https://www.mentimeter.com/s/',
                  'https://www.mentimeter.com/embed/'
                )
              : src
          }
          id="iframe"
          width="100%"
          height={props.height || '100%'}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          style={{ backgroundColor: '#fff' }}
        ></iframe>
        <div className="menimteter-button">{renderButtons()}</div>
      </div>
    </>
  );
};

MentimeterPlayer.propTypes = {
  src: PropTypes.string,
  height: PropTypes.string,
  externalTool: PropTypes.bool,
};

export default MentimeterPlayer;
