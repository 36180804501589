import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

export const MenuEnhancedProps = {
  classes: { paper: 'select-menu' },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const SelectEnhanced = (props) => {
  const { label, required, error, options, defaultValue, displayEmpty, ...newProps } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormControl required={required} error={!!error} fullWidth {...newProps}>
      {label && <InputLabel id="label">{label}</InputLabel>}
      <Select
        onChange={props.onChange}
        defaultValue={defaultValue}
        labelId="label"
        displayEmpty={displayEmpty}
        name={props.name}
        MenuProps={MenuEnhancedProps}
      >
        {props.placeholder && <MenuItem value="">{props.placeholder}</MenuItem>}
        {options.map(({ id, name }) => (
          <MenuItem value={id} key={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

SelectEnhanced.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  displayEmpty: PropTypes.bool,
  name: PropTypes.string,
};

SelectEnhanced.defaultProps = {
  required: true,
  error: null,
  name: null,
  displayEmpty: false,
};

export default SelectEnhanced;
