const TagsTextColor = {
  black: '#000000',
  white: '#FFFFFF',
};

export const TagColors = {
  11: {
    groupName: 'Success1',
    color: TagsTextColor.black,
    background: '#1FDA8B',
  },
  12: {
    groupName: 'Success2',
    color: TagsTextColor.black,
    background: '#48E5A4',
  },
  13: {
    groupName: 'Success3',
    color: TagsTextColor.black,
    background: '#87EAC1',
  },
  14: {
    groupName: 'Success4',
    color: TagsTextColor.black,
    background: '#B8F5DB',
  },
  15: {
    groupName: 'Success5',
    color: TagsTextColor.black,
    background: '#D7FFEF',
  },
  21: {
    groupName: 'Info1',
    color: TagsTextColor.white,
    background: '#4783FF',
  },
  22: {
    groupName: 'Info2',
    color: TagsTextColor.black,
    background: '#76A2FE',
  },
  23: {
    groupName: 'Info3',
    color: TagsTextColor.black,
    background: '#A8C5FF',
  },
  24: {
    groupName: 'Info4',
    color: TagsTextColor.black,
    background: '#CCDDFF',
  },
  31: {
    groupName: 'Warning1',
    color: TagsTextColor.white,
    background: '#C363E9',
  },
  32: {
    groupName: 'Warning2',
    color: TagsTextColor.black,
    background: '#D381F5',
  },
  33: {
    groupName: 'Warning3',
    color: TagsTextColor.black,
    background: '#E3B8F5',
  },
  34: {
    groupName: 'Warning4',
    color: TagsTextColor.black,
    background: '#EED2F9',
  },
  41: {
    groupName: 'Error1',
    color: TagsTextColor.white,
    background: '#F44A4A',
  },
  42: {
    groupName: 'Error2',
    color: TagsTextColor.black,
    background: '#FF7F7F',
  },
  43: {
    groupName: 'Error3',
    color: TagsTextColor.black,
    background: '#FAADAD',
  },
  44: {
    groupName: 'Error4',
    color: TagsTextColor.black,
    background: '#FCCFCF',
  },
  51: {
    groupName: 'Grey1',
    color: TagsTextColor.white,
    background: '#666666',
  },
  52: {
    groupName: 'Grey2',
    color: TagsTextColor.white,
    background: '#999999',
  },
  53: {
    groupName: 'Grey3',
    color: '#585a5b',
    background: '#CCCCCC',
  },
  54: {
    groupName: 'Grey4',
    color: '#585a5b',
    background: '#DBE1E3',
  },
};
