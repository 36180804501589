import React, { useCallback } from 'react';
import { ISpaceUser } from '../../types/User';
import Loading from '../../components/Loading';
import { formatDateTime } from '../../commons/DateTimeUtils';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import Table from '../components/Table';
import { ENGAGEMENT_EVENT_TYPES, PANEL_OPENED_EVENTS } from '../constants';
import Chart, { ChartDefinition } from '../components/Chart';
import useSpaceAnalyticsQuery from '../hooks/useSpaceAnalyticsQuery';
import { CacheKeys } from '../../app/queryCache';
import { EngagementUserInfo } from '../types/engagement';
import { fetchEngagementUserSummary } from '../services/SpaceAnalyticsService';
import { PrivacySpace } from '../../app/appConstants';

type EngagementByUserProps = {
  userInfo: ISpaceUser;
};

function EngagementByUser(props: EngagementByUserProps) {
  const { userInfo } = props;
  const { space, getLastThirtyDays } = useSpaceAnalyticsContext();
  const spaceId = space?.id;

  const { data: userSummary, isLoading: isFetchingSummary } = useSpaceAnalyticsQuery({
    params: {
      userInfoId: userInfo.id,
      startDate: getLastThirtyDays(),
    },
    fetcher: fetchEngagementUserSummary,
    queryKey: CacheKeys.spaceAnalytics_FetchSpaceEngagementSummaryByUser,
    select: (resp) => resp.value,
    enabled: !!spaceId && !!userInfo.id,
  });

  const userActions = userSummary?.actionList || [];
  const totalActions = userSummary?.totalActions || 0;

  function renderMessageType(rowData: EngagementUserInfo) {
    const { eventType, data: messageData } = rowData;
    if (!eventType && !ENGAGEMENT_EVENT_TYPES[eventType]) {
      return '';
    }
    if (
      messageData &&
      PANEL_OPENED_EVENTS?.[messageData] &&
      ENGAGEMENT_EVENT_TYPES[eventType] === ENGAGEMENT_EVENT_TYPES.PANEL_OPENED
    ) {
      return PANEL_OPENED_EVENTS[messageData];
    }

    return ENGAGEMENT_EVENT_TYPES?.[eventType];
  }

  const chartData = React.useMemo(() => userSummary?.data || [], [userSummary?.data]);

  const defineAxis = useCallback(() => {
    if (!userSummary?.data || userSummary?.data?.length === 0) {
      return { left: undefined, right: undefined };
    }
    const flatData = userSummary.data.map((x) => x.data);
    const maxCount = Math.max(...flatData.map((x: { actions: number }) => Math.max(x.actions)));
    return {
      left: {
        maxValue: maxCount,
        isDuration: false,
      },
    };
  }, [userSummary?.data]);

  const chartDefinitions = React.useMemo<ChartDefinition[]>(
    () => [
      {
        type: 'line',
        label: 'Actions',
        data: chartData?.map((x: { data: { actions: number } }) => x.data.actions),
        color: '#4783ff',
        axisBinding: 'left',
      },
    ],
    [chartData]
  );

  const toolTipLabels = React.useMemo<string[]>(
    () => chartData.map((x: { label: string }) => x.label) || [],
    [chartData]
  );

  const renderChart = () => {
    const axis = defineAxis();

    return (
      <Chart
        chartDefinitions={chartDefinitions}
        leftAxesDefinition={axis.left}
        tooltipLabels={toolTipLabels}
        chartLabels={userSummary?.labels || []}
      />
    );
  };

  function renderTable() {
    return (
      <Table
        columns={[
          {
            title: 'Date visit',
            field: 'eventOccurred',
            defaultSort: 'desc',
            render: (rowData: EngagementUserInfo) => {
              return formatDateTime(rowData.eventOccurred, 'MMM DD, yyyy hh:mm A');
            },
          },
          {
            title: 'Actions',
            field: 'eventType',
            render: (rowData: EngagementUserInfo) => {
              return renderMessageType(rowData);
            },
          },
        ]}
        data={userActions}
      />
    );
  }

  function renderContent() {
    if (isFetchingSummary) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }
    return (
      <>
        <p className="short-description">
          {space?.spacePrivacy === PrivacySpace.PUBLIC ? 'The user' : userInfo.fullName} has{' '}
          <b>{totalActions}</b> {totalActions <= 1 ? 'action' : ' actions'}.
        </p>
        {renderChart()}
        {renderTable()}
      </>
    );
  }

  return <div className="engagement-by-user-container">{renderContent()}</div>;
}

export default EngagementByUser;
