import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/system';
import { SelectedFileType, SelectedFolderType } from './context/Interfaces';
import { defaultTheme } from '../theme';
import GlobalResourcesFormDialog from './GlobalResourcesFormDialog';

let containerElement: Element | null = null;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let rootEl: any = null;

function useGlobalResourcesForm() {
  let resolver: (value: boolean) => void;

  React.useEffect(() => {
    return () => {
      if (containerElement && rootEl) {
        rootEl.unmount();
        containerElement = null;
      }
    };
  }, []);

  function closeGlobalResourcesForm(result: boolean) {
    if (containerElement) {
      rootEl?.unmount();
      containerElement = null;
      if (resolver) {
        resolver(result);
      }
    }
  }

  async function showGlobalResourcesForm(
    selectedFolder?: SelectedFolderType | undefined,
    selectedFile?: SelectedFileType,
    isEditing?: boolean
  ) {
    const promise = new Promise((resolve) => {
      resolver = resolve;
      if (!containerElement) {
        containerElement = document.createElement('div');
        rootEl = createRoot(containerElement);
        rootEl.render(
          <ThemeProvider theme={defaultTheme}>
            <GlobalResourcesFormDialog
              onClose={closeGlobalResourcesForm}
              selectedFolderId={selectedFolder?.id}
              selectedFile={selectedFile}
              isEditing={isEditing}
            />
          </ThemeProvider>
        );
      }
    });
    return promise;
  }

  return { showGlobalResourcesForm };
}

export default useGlobalResourcesForm;
