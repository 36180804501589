import React, { useCallback } from 'react';
import linkifyHtml from 'linkify-html';
import { useCommentContext } from './context/CommentContextProvider';
import ActionMenu from './ActionMenu';
import { IComment, ICommentParticipant } from '../../types/comments';
import CommentActionMenu from './CommentActionMenu';
import UserAvatar from '../../components/UserAvatar';
import ParticipantsInteractionsPopover from './ParticipantsInteractions';
import { formatDate, formatTimestamp } from '../../commons/DateTimeUtils';
import spaceUser from '../spaceUser';
import { CommentStatus, CommentState } from '../../app/appConstants';
import CommentText from './CommentText';
import i18n from '../../i18n';
import useLongPress from '../../commons/useLongPress';
import { getDisplayText } from '../../commons/utils';

type Props = {
  participantId?: number;
  children?: React.ReactNode;
  spaceId: string;
  parentId?: number;
  liked?: boolean;
  participants?: ICommentParticipant[];
  isViewOnlyMode?: boolean;
  channelType?: number;
  hasResource?: boolean;
  isMobile?: boolean;
  latestSentComment?: boolean;
  handleOnClickDelete: (comment?: IComment) => void;
  handleOnClickEdit: (comment?: IComment) => void;
  handleOnClickReply: (comment?: IComment, parentCommentId?: number) => void;
  handleOnClickLike: (comment?: IComment) => void;
};
const CommentBody = ({
  participantId,
  children,
  spaceId,
  participants,
  isViewOnlyMode,
  liked,
  parentId,
  channelType,
  hasResource,
  isMobile,
  latestSentComment,
  handleOnClickDelete,
  handleOnClickEdit,
  handleOnClickReply,
  handleOnClickLike,
}: Props) => {
  const { comment, setMenuAnchorEl, menuAnchorEl, retryAddComment, resource } = useCommentContext();
  const isHost = spaceUser.isHost();
  const isMyself = comment?.participantId === participantId;
  const senderName = comment?.participant?.displayName;
  const isDeleted = comment?.state === CommentState.Deleted;
  const hasParentComment = !!comment?.parentComment && !isDeleted;
  const { displayText } = getDisplayText(comment?.text);

  const handleOnClickMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (setMenuAnchorEl) {
      setMenuAnchorEl(event.currentTarget);
    }
  };

  const handleCloseMenu = () => {
    if (setMenuAnchorEl) {
      setMenuAnchorEl(null);
    }
  };

  const onClickReply = useCallback(
    (data: IComment, replyCommentId: number) => {
      const newComment = { ...data };
      if (data.materialId && !data.resource) {
        newComment.resource = resource;
      }
      handleOnClickReply(newComment, replyCommentId);
    },
    [handleOnClickReply, resource]
  );

  const onLongPress = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isMobile) {
      const castedEvent = {
        ...event,
        currentTarget: event.target as unknown as HTMLButtonElement,
      };
      handleOnClickMore(castedEvent);
    }
  };

  const eventLongPress = useLongPress(onLongPress, null, {
    shouldPreventDefault: false,
    delay: 500,
  });

  const renderCommentStatus = () => {
    if (comment?.status === CommentStatus.SENDING) {
      return <div className="status">Sending...</div>;
    } else if (comment?.status === CommentStatus.FAILED) {
      const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        if (retryAddComment) {
          retryAddComment();
        }
      };
      return (
        <div className="status failed">
          {i18n.t('Failed to send')}.
          <span className="retry-button" onClick={onClick}>
            {i18n.t('Retry')}
          </span>
        </div>
      );
    } else if (latestSentComment && isMyself && !comment?.commentInteractions?.length) {
      return <div className="status">{i18n.t('Sent')}</div>;
    } else {
      return null;
    }
  };

  const renderReplyComment = () => {
    if (!comment?.parentComment || isDeleted) {
      return null;
    }
    let timeSent = formatTimestamp(comment?.parentComment?.created);
    const dateSent = formatDate(comment?.parentComment?.created);
    const isToDay = formatDate(new Date()) === dateSent;
    const isMessageDeleted = comment?.parentComment?.state === CommentState.Deleted;
    timeSent = isToDay ? timeSent : `${dateSent} ${timeSent}`;
    const { displayText: parentCommentText } = getDisplayText(comment?.parentComment?.text);

    const getParticipantName = (id: number) => {
      if (!id) {
        return '';
      }
      if (id === participantId) {
        return 'You';
      }
      const participant = participants?.find((p: ICommentParticipant) => p.userInfoId === id);
      return participant?.fullName || '';
    };

    return (
      <div className="response-comment-info">
        <p className="parent-sender">
          <span className="icon-share"></span>
          {`${getParticipantName(comment?.parentComment?.participantId)} • `} {timeSent}
        </p>
        <div className={`parent-message ${isMessageDeleted ? 'deleted' : ''}`}>
          <CommentText
            resource={!isMessageDeleted ? comment.parentComment.resource : null}
            text={parentCommentText}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="comment-body">
      {comment?.startTime && (
        <span className={`sender-name ${!isMyself ? 'align-left' : 'align-right'}`}>
          {!isMyself && `${senderName} • `} {comment?.created ? comment?.startTime : ''}
        </span>
      )}
      <div className={`comment ${!isMyself ? 'align-left' : 'align-right'}`}>
        <div className="comment-content">
          <div className={`message ${!isMyself ? 'align-left' : 'align-right'}`}>
            <div className={`parent-comment ${hasParentComment ? 'parent' : ''}`}>
              {renderReplyComment()}
              <div className="message-action">
                {!isDeleted && (
                  <div className={`action-container ${menuAnchorEl ? 'active' : ''}`}>
                    {!isMobile && (
                      <CommentActionMenu
                        onClickMore={handleOnClickMore}
                        handleOnClickReply={onClickReply}
                        handleOnClickLike={handleOnClickLike}
                        parentId={parentId}
                        channelType={channelType}
                        isMyself={isMyself}
                        liked={liked}
                        comment={comment}
                        isHost={isHost}
                      />
                    )}
                    {menuAnchorEl && (
                      <ActionMenu
                        isHost={isHost}
                        isMyself={isMyself}
                        liked={liked}
                        spaceId={spaceId}
                        isMobile={isMobile}
                        handleCloseMenu={handleCloseMenu}
                        handleOnClickDelete={handleOnClickDelete}
                        handleOnClickEdit={handleOnClickEdit}
                        handleOnClickReply={handleOnClickReply}
                        handleOnClickLike={handleOnClickLike}
                      />
                    )}
                  </div>
                )}

                <div
                  className={`body ${isMyself ? 'myself' : ''} ${hasResource ? 'resource' : ''} `}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...eventLongPress}
                >
                  {comment?.text && (
                    <div className="comment-text">
                      <p className="text">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: linkifyHtml(displayText, { target: '_blank' }),
                          }}
                        />
                      </p>
                    </div>
                  )}
                  <div>{children}</div>
                  {renderCommentStatus()}
                </div>
              </div>
            </div>
          </div>
          <div className="comment-item-footer">
            {!isDeleted && !isViewOnlyMode && !!comment?.commentInteractions?.length && (
              <ParticipantsInteractionsPopover
                liked={liked}
                handleOnClickLike={handleOnClickLike}
                comment={comment}
                participants={participants}
              />
            )}
          </div>
        </div>
        {!isMyself && (
          <div className="avatar-container">
            {comment?.startTime && (
              <UserAvatar
                fullName={comment?.participant?.displayName}
                email={comment?.participant?.email}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentBody;
