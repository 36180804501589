import React from 'react';
import { useTheme } from '@emotion/react';

import PropTypes from 'prop-types';
import Loading from './Loading';
import './LoadingMessage.scss';

const LoadingMessage = ({ children, color }) => {
  const theme = useTheme();
  return (
    <div className="loading-message">
      <Loading color={color || theme.palette.primary.main} />
      {children}
    </div>
  );
};

LoadingMessage.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

LoadingMessage.defaultProps = {
  color: '#fff',
  children: null,
};

export default LoadingMessage;
