import React from 'react';
import ButtonEnhanced from '../../components/ButtonEnhanced';
import i18n from '../../i18n';
import { useGlobalResourcesContext } from '../context/GlobalResourcesContextProvider';
import { getSelectedResourceIds } from './utils';
import useDownloadResource from '../../resources/Download/useDownloadResource';

function DownloadButton() {
  const { selectedResources } = useGlobalResourcesContext();

  const { isLoading, handleClickDownloadBulkResourcesGlobal } = useDownloadResource();

  function handleOnClickDownload() {
    const { resourceIds, directoryIds } = getSelectedResourceIds(selectedResources);
    handleClickDownloadBulkResourcesGlobal(directoryIds, resourceIds);
  }

  return (
    <ButtonEnhanced
      onClick={handleOnClickDownload}
      className="button"
      disabled={isLoading}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:next-line
      endIcon={
        isLoading ? <span className="icon icon-spinner" /> : <span className="icon icon-download" />
      }
    >
      {i18n.t('DownLoad')}
    </ButtonEnhanced>
  );
}

export default React.memo(DownloadButton);
