import React from 'react';
import ButtonEnhanced from '../../components/ButtonEnhanced';
import i18n from '../../i18n';
import './style.scss';
import DownloadButton from './DownloadButton';
import MoveButton from './MoveButton';
import DeleteButton from './DeleteButton';
import SelectAllButton from './SelectAllButton';
import { IFolder, RowData } from '../context/Interfaces';

type ActionButtonsProps = {
  resources: Array<RowData>;
  onClickCancel: () => void;
  onMoveSuccess: () => void;
  onDeleteSuccess: () => void;
  selectedFolder: IFolder | null;
};

function ActionButtons(props: ActionButtonsProps) {
  const { resources, onClickCancel, onMoveSuccess, onDeleteSuccess, selectedFolder } = props;
  return (
    <div className="gr-action-buttons-container">
      <SelectAllButton resources={resources} />
      <div className="right-buttons">
        <DownloadButton />
        <MoveButton onMoveSuccess={onMoveSuccess} selectedFolder={selectedFolder} />
        <DeleteButton onDeleteSuccess={onDeleteSuccess} selectedFolder={selectedFolder} />
        <ButtonEnhanced className="button" onClick={onClickCancel}>
          {i18n.t('Cancel')}
        </ButtonEnhanced>
      </div>
    </div>
  );
}

export default React.memo(ActionButtons);
