import React, { useState } from 'react';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import './style.scss';
import { ISpaceUser } from '../../types/User';
import Tabs from '../../components/Tabs';
import i18n from '../../i18n';
import SpaceUserAvatar from '../components/SpaceUserAvatar';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import ChatsByUser from './ChatsByUser';
import EngagementByUser from './EngagementUser';
import ParticipantTrafficByUser from './ParticipantTrafficByUser';
import ResourcesByUser from './ResourcesByUser';
import { SPACE_FEATURES } from '../../homepage/getSpaceFeatures';
import AssessmentsByUser from './AssessmentsByUser';

export const SpaceUserDetailsTab = {
  ENGAGEMENT: 0,
  TRAFFIC: 1,
  RESOURCES: 2,
  CHATS: 3,
  VIDEOMESSAGES: 4,
  ASSESSMENTS: 4,
};

type SpaceUserDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  userInfo: ISpaceUser;
  defaultTab: (typeof SpaceUserDetailsTab)[keyof typeof SpaceUserDetailsTab];
};

function getTabs(activeTab: number, isChatEnabled: boolean, isEnabledAssessments: boolean) {
  const tabs = [
    { id: 0, active: activeTab === 0, label: i18n.t('Engagement') },
    { id: 1, active: activeTab === 1, label: i18n.t('Traffic') },
    { id: 2, active: activeTab === 2, label: i18n.t('Resources') },
    { id: 3, active: activeTab === 3, label: isChatEnabled ? i18n.t('Chats') : null },
    { id: 4, active: activeTab === 4, label: isEnabledAssessments ? i18n.t('Assessments') : null },
  ];

  return tabs;
}

function SpaceUserDetailsModal(props: SpaceUserDetailsModalProps) {
  const { users, space, isEnabledAssessments } = useSpaceAnalyticsContext();
  const { open, onClose, userInfo, defaultTab } = props;

  const isChatEnabled =
    !!space?.features &&
    space?.features?.findIndex(
      (x: { id: string; enabled: boolean }) => x.id === SPACE_FEATURES.CHAT && x.enabled
    ) > -1;

  const [activeTab, setActiveTab] = useState<number>(defaultTab);
  const tabs = getTabs(activeTab, isChatEnabled, isEnabledAssessments);

  function handleOnChangeTab(index: number) {
    setActiveTab(index);
  }

  function renderHeader() {
    let isOnline = false;
    let lastActive = null;
    const foundUserInfo = users.find((x) => x.userInfoId === userInfo.userInfoId);
    if (foundUserInfo) {
      isOnline = foundUserInfo.isOnline || false;
      lastActive = foundUserInfo.lastActive;
    }
    if (!lastActive) {
      lastActive = userInfo.lastActive;
    }
    userInfo.isOnline = isOnline;
    userInfo.lastActive = lastActive;

    return (
      <div className="header">
        <SpaceUserAvatar userInfo={userInfo} showActiveStatus />
        <Tabs tabs={tabs} onChange={handleOnChangeTab} variant="tabs-level-2" />
      </div>
    );
  }

  function renderActiveTab() {
    if (activeTab === SpaceUserDetailsTab.ENGAGEMENT) {
      return <EngagementByUser userInfo={userInfo} />;
    }

    if (activeTab === SpaceUserDetailsTab.TRAFFIC) {
      return <ParticipantTrafficByUser userInfo={userInfo} />;
    }

    if (activeTab === SpaceUserDetailsTab.RESOURCES) {
      return <ResourcesByUser userInfo={userInfo} />;
    }

    if (activeTab === SpaceUserDetailsTab.CHATS) {
      return <ChatsByUser userInfo={userInfo} />;
    }

    if (activeTab === SpaceUserDetailsTab.ASSESSMENTS) {
      return <AssessmentsByUser userInfo={userInfo} />;
    }

    return null;
  }

  if (!open) {
    return null;
  }

  return (
    <ResponsiveDialog
      modal
      maxWidth="lg"
      className="white confirm-dialog space-users-details-modal"
      onClose={onClose}
    >
      {renderHeader()}
      <div className="tab-content">{renderActiveTab()}</div>
    </ResponsiveDialog>
  );
}

export default React.memo(SpaceUserDetailsModal);
