import React, { MouseEvent, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from '../i18n';
import './VideoMessage.scss';
import { createResourceInteraction } from '../commons/CommonServices';
import VideoMessageDialog from './VideoMessageDialog';
import Loading from '../components/Loading';
import { ResourceInteractionType } from '../app/appConstants';
import { newGuid } from '../commons/utils';
import { useSpaceContext } from '../spaces/SpaceContext';
import { useResourceInteractionContext } from '../resources/ResourceInteractionContextProvider';

const VaamThumbnail = React.lazy(() => import('./VaamThumbnail'));

interface VideoMessageProps {
  vaamId: string;
  title: string;
  description: string;
  createdByUserInfoId: number;
  createdDate: string;
  resourceId: string;
  commentId: number;
  channelId: number;
  spaceName: string;
  materialId: number;
  spaceId: string;
}

const VideoMessage: React.FC<VideoMessageProps> = ({
  spaceId,
  vaamId,
  title,
  description,
  createdByUserInfoId,
  createdDate,
  commentId,
  resourceId,
  channelId,
  spaceName,
  materialId,
}: VideoMessageProps) => {
  const { theme } = useSpaceContext();
  const { resourceInteractionSessionId, setResourceInteractionSessionId } =
    useResourceInteractionContext();
  const location = useLocation();
  const navigate = useNavigate();

  const createResourceViewedInteraction = (totalViewedSeconds: number) => {
    createResourceInteraction(resourceId, {
      refId: resourceInteractionSessionId,
      materialId: resourceId,
      interactionType: ResourceInteractionType.Viewed,
      totalSeconds: totalViewedSeconds,
      IsVideoMessageViewed: true,
      ChannelId: channelId,
      commentId,
    });
  };

  const createResourceClickedInteraction = () => {
    createResourceInteraction(resourceId, {
      refId: resourceInteractionSessionId,
      interactionType: ResourceInteractionType.Click,
      commentId,
      CommentDescription: description,
      CommentTitle: title,
      IsVideoMessageViewed: true,
      ChannelId: channelId,
      SpaceName: spaceName,
      ResourceCreatedByUserInfoId: createdByUserInfoId,
      materialId,
      data: JSON.stringify({
        resourceType: 'VaamRecordingVideo',
      }),
    });
  };

  const onClickPlayer = async (event: MouseEvent<HTMLDivElement>) => {
    setResourceInteractionSessionId(newGuid());
    event.preventDefault();
    event.stopPropagation();

    await VideoMessageDialog.show(
      spaceId,
      vaamId,
      title,
      description,
      createdByUserInfoId,
      createdDate,
      theme?.logoImageSrc,
      navigate,
      location,
      null,
      createResourceViewedInteraction,
      createResourceClickedInteraction
    );
  };

  return (
    <div className="video-message">
      <div className="video-message-content" onClick={onClickPlayer}>
        <div className="player">
          <Suspense fallback={<Loading />}>
            <VaamThumbnail vaamId={vaamId} />
          </Suspense>
        </div>
        {title || description ? (
          <div className="info">
            {title && <div className="title">{i18n.t(title)}</div>}
            {description && <div className="description">{i18n.t(description)}</div>}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default VideoMessage;
