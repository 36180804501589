/* eslint no-await-in-loop: 0 */
import Api from '../commons/api';
import { clearCache, CacheKeys } from '../app/queryCache';
import { getUserTimeZone } from '../commons/DateTimeUtils';
import { getCoreApi } from '../commons/utils';
// import { fetchSpaceFolders } from '../resources/ResourceServices';

export function fetchSpace(id) {
  console.log('spaceId', id);
  return new Promise((resolve) => {
    resolve({
      id: id,
      name: "Toan's galaxy",
      description: 'description',
    });
  });
}

export async function createSpaceTemplate(spaceId) {
  const rs = await Api.post(`${getCoreApi()}/space-templates`, { spaceId });
  clearCache([CacheKeys.getSpaceTemplatesOfFeedbacksTemplates], false);
  return rs;
}

export function fetchSpaceTemplates(keywords, sortBy, limit = 10, offset = 0, tagIds = null) {
  let url = `/space-templates?limit=${limit}&offset=${offset}`;
  if (keywords) {
    url += `&keywords=${keywords}`;
  }
  if (sortBy) {
    url += `&order=${sortBy}`;
  }
  if (tagIds?.length > 0) {
    tagIds.forEach((u) => {
      url = `${url}&tagIds=${u}`;
    });
  }
  return Api.get(`${getCoreApi()}${url}`);
}

export async function deleteSpaceTemplate(id) {
  const rs = await Api.delete(`${getCoreApi()}/space-templates/${id}`);
  clearCache([CacheKeys.getSpaceTemplatesOfFeedbacksTemplates], false);
  return rs;
}

const defaultcloneSpaceOptions = {
  copyMaterials: false, // copy meetings' materials
  copySpaceMaterials: false, // copy space's materials
  isTemplate: false,
  name: null,
  copyParticipants: false,
};

export async function cloneSpace(spaceId, data, options = {}) {
  const { isTemplate, copySpaceMaterials } = {
    ...defaultcloneSpaceOptions,
    ...options,
  };
  const resp = await Api.post(`${getCoreApi()}/spaces/${spaceId}/clone`, {
    isTemplate,
    copyMaterials: copySpaceMaterials,
    ...data,
    tz: getUserTimeZone(),
  });
  const newSpaceId = resp?.id;

  return newSpaceId;
}

// export async function includeResourcesWhenCloning(selectedId) {
//   const resp = await fetchSpaceFolders(selectedId);
//   // const hasResources = directories.find((d) => d.spaceResources.length > 0) !== undefined;
//   // Whenever space has folder even it's not have any materials
//   const hasResources = !!resp.spaceDirectories.length;
//   return hasResources;
// }

export async function transferOwnerShip(spaceId, data) {
  return Api.post(`${getCoreApi()}/spaces/${spaceId}/transfer`, data);
}

export async function transferOwnershipSpaceTemplate(id, data) {
  return Api.post(`${getCoreApi()}/space-templates/${id}/transfer`, data);
}

export function requestSpaceDetails(spaceId) {
  return Api.get(`${getCoreApi()}/spaces/${spaceId}/details`);
}

export function getSpaceUsers(spaceId) {
  return Api.get(`${getCoreApi()}/spaces/${spaceId}/participants`);
}

export async function shareSpace(spaceId, sharedCode, isClickButton = false) {
  return Api.post(`${getCoreApi()}/spaces/${spaceId}/share`, {
    code: sharedCode,
    isClickButton,
  });
}

export function getCreatedFromTemplateSpacesCount(spaceTemplateId) {
  // only count Open spaces
  return Api.get(`${getCoreApi()}/space-templates/${spaceTemplateId}/derived-spaces/count`);
}

export function getCreatedFromTemplateSpaces(
  spaceTemplateId,
  // limit = 10,
  // offset = 0,
  // keywords = '',
  // sortBy = '',
  logId = 0
) {
  let url = `/space-templates/${spaceTemplateId}/derived-spaces`;
  // let url = `/api/space-templates/${spaceTemplateId}/derived-spaces?limit=${limit}&offset=${offset}`;
  // if (keywords) {
  //   url += `&keywords=${keywords}`;
  // }
  // if (sortBy) {
  //   url += `&order=${sortBy}`;
  // }
  if (logId > 0) {
    // url += `&logId=${logId}`;
    url += `?logId=${logId}`;
  }
  return Api.get(`${getCoreApi()}${url}`);
}

export function applyCMSChangesToSpaces(spaceTemplateId, spaceIds, isOverridden = false) {
  const url = `/space-templates/${spaceTemplateId}/derived-spaces/apply-changes`;
  const data = { spaceIds, isOverridden };
  return Api.post(`${getCoreApi()}${url}`, data);
}

export function fetchShareSpaceCode(spaceId, currentShareCode) {
  const baseUrl = '/spaces/';
  let url = `${baseUrl}${spaceId}/share/code`;
  if (currentShareCode) {
    url += `?code=${currentShareCode}`;
  }
  return Api.get(`${getCoreApi()}${url}`).then((resp) => resp);
}

export function saveCMSChangesLog(spaceId, changes) {
  console.log('saveCMSChangesLog', spaceId, changes);
  const url = `/spaces/${spaceId}/cms-changes`;
  // const data = { modifiedBlocks, hasOrderChanged };
  return Api.post(`${getCoreApi()}${url}`, changes);
}

export function getSpaceChangesCount(spaceId) {
  return Api.get(`${getCoreApi()}/spaces/${spaceId}/changes/count`).then((resp) => resp);
}
