import i18n from '../../i18n';
import useURLSpace from '../../homepage/useURLSpace';
import spaceUser from '../../spaces/spaceUser';
import { shareMaterialTrack } from '../CommonServices';
import { copyTextToClipboard, sendNotification } from '../utils';

const useShareLink = (space) => {
  const { getShareLinkSpace } = useURLSpace(space);
  const spaceId = space?.id;

  async function handleShareLinkFileClick(id) {
    const shareLink = getShareLinkSpace({ srId: id });
    if (shareLink) {
      await copyTextToClipboard(shareLink);
      sendNotification(i18n.t('Link copied!'));
      console.log('shareLink', shareLink);
      // send request to track resource interaction
      const sharedCode = spaceUser.getShareSpaceCode();
      shareMaterialTrack(spaceId, id, {
        code: sharedCode,
        isClickButton: true,
      });
    }
  }

  async function handleShareLinkFolderClick(id) {
    const shareLink = getShareLinkSpace({ drId: id });
    if (shareLink) {
      await copyTextToClipboard(shareLink);
      sendNotification(i18n.t('Link copied!'));
    }
  }

  return { handleShareLinkFileClick, handleShareLinkFolderClick };
};

export default useShareLink;
