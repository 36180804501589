import { format, startOfDay, subDays } from 'date-fns';

export const calculateStartDate = (filterValue: string) => {
  let startDate;
  switch (filterValue) {
    case 'Today':
      startDate = startOfDay(new Date());
      break;
    case 'Yesterday':
      startDate = startOfDay(subDays(new Date(), 1));
      break;
    case 'Last 7 days':
      startDate = startOfDay(subDays(new Date(), 7));
      break;
    case 'Last 30 days':
      startDate = startOfDay(subDays(new Date(), 30));
      break;
    case 'Last 90 days':
      startDate = startOfDay(subDays(new Date(), 90));
      break;
    case 'Last 180 days':
      startDate = startOfDay(subDays(new Date(), 180));
      break;
    default:
      startDate = startOfDay(subDays(new Date(), 30));
  }
  return format(startDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
};
