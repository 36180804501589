import React from 'react';
import PropTypes from 'prop-types';

const OfficeAppViewer = (props) => {
  const src = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(props.src)}`;
  return (
    <iframe
      title="Office App Viewer"
      src={src}
      width="100%"
      height="100%"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
      style={{ backgroundColor: '#fff' }}
    ></iframe>
  );
};

OfficeAppViewer.propTypes = {
  src: PropTypes.string,
};

export default OfficeAppViewer;
