/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TablePagination, {
  LabelDisplayedRowsArgs,
  TablePaginationProps,
} from '@mui/material/TablePagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import SteppedPagination from './SteppedPagination';
import './TablePagination.scss';

type ActionsComponentProps = TablePaginationActionsProps & {
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
};

type PatchedPaginationProps = TablePaginationProps & {
  ActionsComponent: React.ComponentType<ActionsComponentProps>;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const PatchedPagination: React.FC<PatchedPaginationProps> = (props) => {
  const { ActionsComponent, onPageChange, onRowsPerPageChange, ...newProps } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(event);
  };

  const labelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) => {
    const { from, to, count } = paginationInfo;
    return (
      <>
        <span>{`Showing ${from}–${to} of`}</span> {count} results
      </>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderActionsComponent = (subprops: any) => {
    return (
      <SteppedPagination
        {...subprops}
        onChangePage={onPageChange}
        showFirstLastPageButtons={false}
        classes={{ root: 'select-result-show' }}
      />
    );
  };
  return (
    <TablePagination
      component="div"
      {...newProps}
      onPageChange={onPageChange}
      onRowsPerPageChange={handleChange}
      labelRowsPerPage={''}
      labelDisplayedRows={labelDisplayedRows}
      ActionsComponent={renderActionsComponent}
      SelectProps={{
        MenuProps: {
          classes: { paper: 'select-menu select-menu-top' },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        },
      }}
    />
  );
};

export default PatchedPagination;
