import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

import './ShareSpace3rdParties.scss';
import { CacheKeys } from '../app/queryCache';
import { fetchShareSpaceMessageTemplate } from '../spaces/SpaceUsers/spaceUsersServices';
import spaceUser from '../spaces/spaceUser';
import { Ids } from '../commons/pendoTaggings';
import useSpaceUsersQuery from '../spaces/SpaceUsers/useSpaceUsersQuery';
import { PrivacySpace } from '../app/appConstants';

const ShareSpace3rdParties = (props) => {
  const { spaceId, shareableLink, isRestrictedSpace, spacePrivacy, spaceName, lastEdited } = props;

  const [defaultMessage, setDefaultMessage] = React.useState(null);
  const [participants, setParticipants] = React.useState(null);
  const isPublicSpace = spacePrivacy === PrivacySpace.PUBLIC;
  const currentUserInfoId = spaceUser.getUserInfoId();

  const fetchMessageTemplateQuery = useQuery({
    queryKey: [CacheKeys.fetchShareSpaceMessageTemplate, isRestrictedSpace, spacePrivacy],
    queryFn: async () => {
      const resp = await fetchShareSpaceMessageTemplate(spaceId, isRestrictedSpace, spacePrivacy);
      return resp;
    },

    retry: 1,
    retryDelay: () => 5000,
  });

  const getSpaceUsersQuery = useSpaceUsersQuery(spaceId, false, spaceUser.isHost(), false);

  React.useEffect(() => {
    if (fetchMessageTemplateQuery.data) {
      setDefaultMessage(fetchMessageTemplateQuery.data || '');
    }
  }, [fetchMessageTemplateQuery.data]);

  React.useEffect(() => {
    if (getSpaceUsersQuery.data?.length > 0) {
      setParticipants(
        getSpaceUsersQuery.data.filter(
          (item) => item.userInfoId !== currentUserInfoId && !(item.isExternal && isPublicSpace)
        )
      );
    }
  }, [getSpaceUsersQuery.data, currentUserInfoId, isPublicSpace]);

  function getDefaultMessageWithData() {
    if (!defaultMessage) {
      return '';
    }
    let message = defaultMessage.replaceAll('{{SPACE_URL}}', shareableLink);
    message = message.replaceAll('{{SPACE_NAME}}', spaceName);
    return message;
  }

  function getBodyContent() {
    const message = getDefaultMessageWithData();
    return encodeURIComponent(message);
  }

  function getEmails() {
    if (participants?.length) {
      return participants.map((p) => p.email).join(',');
    } else {
      return '';
    }
  }

  const onClick = (type) => {
    const emails = getEmails();
    const subject = `Welcome to our ${spaceName}`;
    const bodyMessage = getBodyContent();

    let shareLink = shareableLink;

    if (lastEdited) {
      shareLink = `${shareableLink}?latest=${lastEdited}`;
      shareLink = encodeURIComponent(shareLink);
    }

    let url = '';
    switch (type) {
      case 'outlook':
        // temporary fix for outlook (replace spaces (%20) by half-spaces (%C2%A0))
        url = `https://outlook.office.com/mail/deeplink/compose?subject=${subject.replaceAll(
          ' ',
          '%C2%A0'
        )}&body=${bodyMessage.replaceAll('%20', '%C2%A0')}&to=${emails}`;

        break;
      case 'gmail':
        url = `https://mail.google.com/mail/u/0/?hl=en&tf=cm&fs=1?&to=${emails}&su=${subject}&body=${bodyMessage}`;

        break;
      default:
        url = `https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`;

        break;
    }

    window.open(url, '_blank');
  };

  return (
    <div className="share-3rd-parties">
      <div className="row">
        <div data-id={Ids.shareSpaceOutlook} onClick={() => onClick('outlook')}>
          <span className="icon icon-outlook"></span>
          <span className="app-text">Outlook</span>
        </div>
        <div data-id={Ids.shareSpaceGmail} onClick={() => onClick('gmail')}>
          <span className="icon icon-gmail">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
          </span>
          <span className="app-text">Gmail</span>
        </div>
        <div data-id={Ids.shareSpaceLinkedin} onClick={() => onClick('linkedin')}>
          <span className="icon icon-linkedin">
            <span className="path1"></span>
            <span className="path2"></span>
          </span>
          <span className="app-text">LinkedIn</span>
        </div>
      </div>
    </div>
  );
};

ShareSpace3rdParties.propTypes = {
  spaceId: PropTypes.string,
  shareableLink: PropTypes.string,
  isRestrictedSpace: PropTypes.bool,
  spacePrivacy: PropTypes.string,
  spaceName: PropTypes.string,
  lastEdited: PropTypes.string,
};

export default ShareSpace3rdParties;
