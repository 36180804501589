import { Dialog, Slide } from '@mui/material';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

export const Transition = forwardRef((props, ref) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="left" ref={ref} {...props} timeout={{ enter: 500, exit: 250 }} />;
});

const AssessmentDialog = ({ children, open, onClose }) => {
  return (
    <Dialog
      className="assessment-dialog"
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      {children}
    </Dialog>
  );
};

AssessmentDialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AssessmentDialog;
