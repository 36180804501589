import React from 'react';
import PropTypes from 'prop-types';
import './ResourcesGridView.scss';
import ResourceItem from '../ResourcesDisplayView/ResourceItem';
import { ViewModeType } from '../../../resources/resourceConstants';
import ExternalResourceItem from '../ResourcesDisplayView/ExternalResourceItem';

const ResourcesGridView = (props) => {
  const {
    resources,
    isMobile,
    // handleSelectAllClick,
    selected,
    // order,
    // orderBy,
    // handleSort,
    canDragDropFile,
    selectedFile,
    newFiles,
    onClick,
    onClickCheckbox,
    onClickMoreButton,
    onDriveInfoUpdated,
    // loadMoreButton,
  } = props;

  const renderItem = (resource) => {
    const isItemSelected = resource.id
      ? selected.includes(resource.id)
      : selected.includes(resource.externalId);
    const canDragDrop = canDragDropFile ? canDragDropFile(resource) : false;
    const selectedFileId = selectedFile?.id > 0 && selectedFile?.id === resource.id;

    if (resource.isFolder && !!resource.externalId) {
      return (
        <ExternalResourceItem
          viewMode={ViewModeType.GRID}
          isMobile={isMobile}
          key={resource.id > 0 ? resource.id : resource.externalId}
          resource={resource}
          newFiles={newFiles}
          selected={isItemSelected}
          showActionMenu={props.showActionMenu}
          disabledMoreButton={props.disabledMoreButton}
          onClickItem={onClick}
          onClickCheckbox={onClickCheckbox}
          onClickMoreButton={onClickMoreButton}
          processingResources={props.processingResources}
          newResourceId={props.newResourceId}
          selectedFileId={selectedFileId}
          isViewOnly={props.isViewOnly}
          dragObject={canDragDrop ? resource : null}
          isWebinar={props.isWebinar}
          isPortal={props.isPortal}
          onDriveInfoUpdated={onDriveInfoUpdated}
        />
      );
    }

    return (
      <ResourceItem
        viewMode={ViewModeType.GRID}
        isMobile={isMobile}
        key={resource.id > 0 ? resource.id : resource.externalId}
        resource={resource}
        newFiles={newFiles}
        selected={isItemSelected}
        showActionMenu={props.showActionMenu}
        disabledMoreButton={props.disabledMoreButton}
        onClickItem={onClick}
        onClickCheckbox={onClickCheckbox}
        onClickMoreButton={onClickMoreButton}
        processingResources={props.processingResources}
        newResourceId={props.newResourceId}
        selectedFileId={selectedFileId}
        isViewOnly={props.isViewOnly}
        dragObject={canDragDrop ? resource : null}
        isWebinar={props.isWebinar}
        isPortal={props.isPortal}
      />
    );
  };
  return (
    <div className="resources-grid-view-container">
      <div className="grid">{resources.map((resource) => renderItem(resource))}</div>
    </div>
  );
};

ResourcesGridView.propTypes = {
  resources: PropTypes.instanceOf(Array),
  isMobile: PropTypes.bool,
  // handleSelectAllClick: PropTypes.func,
  selected: PropTypes.instanceOf(Object),
  // order: PropTypes.string,
  // orderBy: PropTypes.string,
  // handleSort: PropTypes.func,
  canDragDropFile: PropTypes.func,
  selectedFile: PropTypes.instanceOf(Object),
  newFiles: PropTypes.instanceOf(Array),
  // loadMoreButton: PropTypes.instanceOf(Object),
  isWebinar: PropTypes.bool,
  showActionMenu: PropTypes.bool,
  disabledMoreButton: PropTypes.bool,
  processingResources: PropTypes.instanceOf(Array),
  newResourceId: PropTypes.number,
  isViewOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onClickCheckbox: PropTypes.func,
  onClickMoreButton: PropTypes.func,
  isPortal: PropTypes.bool,
  onDriveInfoUpdated: PropTypes.func,
};

ResourcesGridView.defaultProps = {
  isPortal: false,
};

export default ResourcesGridView;
