import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@emotion/react';

import { getTheme } from '../theme';
import queryCache from '../app/queryCache';
import ResponsiveDialog from '../components/ResponsiveDialog';
import ConfirmService from '../components/ConfirmService';
import { sendNotification } from '../commons/utils';
import Logo from './../images/spce-logo.png';
import VaamParticipantTabPanel, { SharingTypes } from './VaamParticipantTabPanel';
import i18n from '../i18n';
import './ShareVaamDialog.scss';
import { deleteVaam, finalizeVaam } from './VaamServices';
import SuccessMessageDialog from './SuccessMessageDialog';
import { ChannelType } from '../app/appConstants';
import OfficialButton from '../components/OfficialButtons';
import useUnmountEffect from '../commons/useUnmountEffect';
import useVaamThumbnailUrlQuery from './useVaamThumbnailUrlQuery';
import { Ids } from '../commons/pendoTaggings';
import MaterialsContextProvider, {
  useMaterialsContext,
} from '../spaces/Materials/MaterialsContextProvider';
import AppProvider, { useAppContext } from '../components/AppProvider';
import IntegrationRuntimeContextProvider from '../spaces/integrations/IntegrationRuntimeContextProvider';
import MultipleSelectResourcesContextProvider from '../components/Material/MultipleSelectResourcesContextProvider';
import LoadingMessage from '../components/LoadingMessage';
import Loading from '../components/Loading';

const VaamPlayer = React.lazy(() => import('./VaamPlayer'));

let resolve;
let containerElement;
let rootEl;

const ShareVaamDialog = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [file, setFile] = useState({ title: '', description: '' });
  const [notifyUserDetails, setNotifyUserDetails] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  // const [participantsCount, setParticipantsCount] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [notifyByEmail, setNotifyByEmail] = useState(false);
  const [isPublicSharing, setIsPublicSharing] = useState(false);
  const [isShareInResource, setIsShareInResource] = useState(false);
  const [sharedFolderId, setSharedFolderId] = useState(null);
  const { thumbnailUrl } = useVaamThumbnailUrlQuery(props.vaamId, 5);
  const { getMaterialsQuery, flatDirectories } = useMaterialsContext();
  const [selectedFolder, setSelectedFolder] = React.useState(null);
  const { isTablet } = useAppContext();

  const isPrivateChannel = props.targetChannel?.channelType === ChannelType.P2P;

  useUnmountEffect(() => {
    console.log('componentWillUnmount ShareVaamDialog');
    resolve = null;
    containerElement = null;
  });

  useEffect(() => {
    const rootFolder = flatDirectories?.find((item) => item.name === 'Extra');
    if (!rootFolder) return;

    setSelectedFolder(rootFolder);
  }, [flatDirectories, setSelectedFolder]);

  function handleClose() {
    setIsOpen(false);
    ShareVaamDialog.destroy();
  }

  function handleFileOnChange(event) {
    let value = event.target.innerHTML;
    const name = event.target.getAttribute('name');
    const maxLength = Number(event.target.getAttribute('maxLength'));
    // key code 8 is backspace
    if (value.length === maxLength && event.keyCode !== 8) {
      event.preventDefault();
      event.stopPropagation();
    }
    value = value.substring(0, maxLength);

    setFile({ ...file, [name]: value });
  }

  function handleOnSelectParticipants(userInfoDetails) {
    setNotifyUserDetails(userInfoDetails);
    if (userInfoDetails.length > 0) {
      setHasError(false);
    }
  }

  function handleOnChangeNotifyByEmail(checked) {
    setNotifyByEmail(checked);
  }

  function handleOnChangeSharingType(value) {
    setIsPublicSharing(value === SharingTypes.PUBLIC);
    setIsShareInResource(value === SharingTypes.RESOURCE_PANEL);
    setSharedFolderId(selectedFolder?.id);
  }
  function handleSelectShareFolder(folderId) {
    setSharedFolderId(folderId);
  }

  async function showSuccessMessage() {
    const message = 'Your video message has been added to the space.';
    // if (notifyByEmail) {
    //   message =
    //     'Your video message has been posted in the chat and participants are notified about it via email.';
    // }
    const options = {
      message: message,
      showGoToChat: props.isVaamFromChat,
    };
    const { showChat } = await SuccessMessageDialog.show(options);
    if (showChat) {
      props.openChatSidePanel();
      props.goToChat();
    }
  }

  async function handleShare() {
    const defaultFolderId = sharedFolderId ?? selectedFolder?.id;

    let ToFolderId = isShareInResource ? defaultFolderId : null;
    if (!props.enabledChat) ToFolderId = defaultFolderId;

    if (props.enabledChat && !isPublicSharing && notifyUserDetails?.length === 0 && !ToFolderId) {
      setHasError(true);
      return;
    }
    setIsProcessing(true);

    const payload = {
      title: file.title,
      description: file.description,
      notifyParticipantSettings: {
        participants: notifyUserDetails,
      },
      isShowroom: !props.enabledChat,
      notifyByEmail: notifyByEmail,
      isPublicSharing: isPublicSharing,
      isShareVideoMessageInResourcePanel: isShareInResource,
      thumbnailUrl,
      ToFolderId,
    };

    const channelId = isPrivateChannel ? props.targetChannel?.id : null;

    try {
      await finalizeVaam(props.spaceId, props.resourceId, channelId, payload);
      await getMaterialsQuery.refetch();
      setIsProcessing(false);
    } catch (e) {
      sendNotification(e.message, { type: 'error' });
    } finally {
      handleClose();
      showSuccessMessage();
    }
  }

  async function handleDelete() {
    const isConfirmed = await ConfirmService.show(
      `${i18n.t('delete video')}`,
      <>
        <b>{i18n.t("This can't be undone")}</b>
        <br />
        <span>{i18n.t('Are you sure you want to delete this video message?')}</span>
      </>,
      'Ok',
      'Cancel'
    );

    if (!isConfirmed) {
      return null;
    }

    try {
      await deleteVaam(props.spaceId, props.resourceId);
      handleClose();
    } catch (e) {
      sendNotification(e.message, { type: 'error' });
    }

    return null;
  }

  function getParticipantsCount(newCount) {
    // setParticipantsCount(newCount);
    if (newCount > 0) {
      setHasError(false);
    }
  }

  if (!isOpen) {
    return null;
  }
  if (getMaterialsQuery.isLoading) {
    return (
      <div className="loading-container">
        <LoadingMessage>{i18n.t('Loading...')}</LoadingMessage>
      </div>
    );
  }

  return (
    <ResponsiveDialog
      modal
      maxWidth="lg"
      className="white confirm-dialog share-video-dialog"
      onClose={handleDelete}
    >
      <div className="content">
        <Box className="left">
          {isTablet ? (
            <div className="header">
              <div className="title">{i18n.t('SHARE VIDEO MESSAGE')}</div>
            </div>
          ) : (
            <div className="header">
              {props.logoUrl ? (
                <img src={props.logoUrl} alt="Logo" className="logo" />
              ) : (
                <img src={Logo} alt="SPCE logo" className="logo" />
              )}
            </div>
          )}

          <div className="form">
            <div
              contentEditable
              className="textfield title"
              name="title"
              maxLength={50}
              data-placeholder={i18n.t('add title')}
              onKeyDown={handleFileOnChange}
            />
            <div
              contentEditable
              className="textfield description"
              name="description"
              maxLength={120}
              data-placeholder={i18n.t('Add message (Maximum 120 characters)')}
              onKeyDown={handleFileOnChange}
            />
          </div>

          <div className="player">
            <Suspense fallback={<Loading />}>
              <VaamPlayer vaamId={props.vaamId} />
            </Suspense>
          </div>
        </Box>
        <Box className="right">
          {!isTablet && <div className="title">{i18n.t('SHARE VIDEO MESSAGE')}</div>}
          <VaamParticipantTabPanel
            spaceId={props.spaceId}
            onSelectParticipants={handleOnSelectParticipants}
            channel={props.targetChannel}
            getParticipantsCount={getParticipantsCount}
            enabledChat={props.enabledChat}
            isOnError={hasError}
            onChangeNotifyByEmail={handleOnChangeNotifyByEmail}
            onChangeSharingType={handleOnChangeSharingType}
            handleSharingClick={handleSelectShareFolder}
            isPublicSpace={props.isPublicSpace}
          />
          <div className="footer">
            <div className="action-buttons">
              <OfficialButton
                onClick={handleDelete}
                label={i18n.t('Delete Message')}
                variant="regular-secondary"
                dataId={Ids.vaamDeleteMsgBtnDialogContent}
              />
              <OfficialButton
                onClick={handleShare}
                isProcessing={isProcessing}
                label={i18n.t('Share')}
                variant="regular-primary"
                dataId={Ids.vaamShareBtnDialogContent}
              />
            </div>
          </div>
        </Box>
      </div>
    </ResponsiveDialog>
  );
};

ShareVaamDialog.propTypes = {
  vaamId: PropTypes.string,
  spaceId: PropTypes.string,
  resourceId: PropTypes.number,
  openChatSidePanel: PropTypes.func,
  targetChannel: PropTypes.instanceOf(Object),
  goToChat: PropTypes.func,
  isVaamFromChat: PropTypes.bool,
  enabledChat: PropTypes.bool,
  logoUrl: PropTypes.string,
  isPublicSpace: PropTypes.bool,
};

ShareVaamDialog.show = (
  vaamId,
  spaceId,
  resourceId,
  openChatSidePanel,
  targetChannel,
  goToChat,
  isVaamFromChat,
  enabledChat,
  logoUrl,
  isPublicSpace
) => {
  containerElement = document.createElement('div');
  rootEl = createRoot(containerElement);

  rootEl.render(
    <QueryClientProvider client={queryCache}>
      <ThemeProvider theme={getTheme()}>
        <AppProvider isPortal isSpace>
          <IntegrationRuntimeContextProvider>
            <MaterialsContextProvider spaceId={spaceId} isPortal>
              <MultipleSelectResourcesContextProvider>
                <ShareVaamDialog
                  vaamId={vaamId}
                  spaceId={spaceId}
                  resourceId={resourceId}
                  openChatSidePanel={openChatSidePanel}
                  targetChannel={targetChannel}
                  goToChat={goToChat}
                  isVaamFromChat={isVaamFromChat}
                  enabledChat={enabledChat}
                  logoUrl={logoUrl}
                  isPublicSpace={isPublicSpace}
                />
              </MultipleSelectResourcesContextProvider>
            </MaterialsContextProvider>
          </IntegrationRuntimeContextProvider>
        </AppProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
  return new Promise((res) => {
    resolve = res;
  });
};
ShareVaamDialog.destroy = (retVal = false) => {
  if (containerElement && containerElement.parentNode) {
    setTimeout(() => {
      rootEl.unmount();
      containerElement = null;
    }, 0);
  }
  if (resolve) resolve(retVal);
};

export default ShareVaamDialog;
