import React, { forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import i18n from '../../i18n';
import './FolderForm.scss';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import useMountEffect from '../../commons/useMountEffect';
import OfficialButton from '../../components/OfficialButtons';

const FolderForm = forwardRef((props, ref) => {
  const { data, processing, onSave, parentFolderId } = props;
  const [folder, setFolder] = useState(data);
  const [hasChangesForm, setHasChangesForm] = useState(false);
  const textInputRef = useRef(null);

  useMountEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.focus();
    }
  });

  function handleFolderInputChange(event) {
    const { name, value } = event.target;
    const newFolder = { ...folder, [name]: value };
    setHasChangesForm(true);
    setFolder(newFolder);
  }

  function handleOnSave() {
    const newFolder = { ...folder };
    if (onSave) {
      if (parentFolderId) {
        newFolder.parentFolderId = parentFolderId;
      }
      const result = onSave(newFolder);
      setHasChangesForm(false);
      return result;
    }
    return false;
  }

  async function handleCloseForm() {
    let isCloseForm = true;
    if (hasChangesForm) {
      const { isSaved, discard } = await UnsavedChangesDialog.show(
        i18n.t('UNSAVED CHANGES'),
        <>
          {i18n.t(`You have made changes.`)}
          <br />
          {i18n.t(`Would you like to save?`)}
        </>
      );
      isCloseForm = discard;
      if (isSaved) {
        const result = await handleOnSave();
        if (result) {
          isCloseForm = true;
        }
      }
    }
    return isCloseForm;
  }
  React.useImperativeHandle(ref, () => ({
    handleCloseForm,
  }));

  // const titleButtonCreate = titleButton || i18n.t('add to space');

  return (
    <Grid className="runtime-materials material-form" container spacing={0}>
      <section className="folder-form-items styled-scrollbar">
        <section className="folder-form-item disableHovering">
          <section className="middle-section column-direction">
            <div className="folder-form-control">
              <label className={`folder-form-control-label`}>{i18n.t('Folder name')}</label>
              <TextField
                inputRef={textInputRef}
                autoComplete="off"
                fullWidth
                className="folder-form-control-input"
                variant="outlined"
                placeholder=""
                defaultValue={folder?.name}
                name="name"
                onChange={handleFolderInputChange}
              />
            </div>
          </section>
        </section>
      </section>
      <div className="buttons">
        <OfficialButton
          fullWidth
          variant="regular-green"
          label={folder?.id > 0 ? i18n.t('save') : i18n.t('create')}
          onClick={handleOnSave}
          disabled={!folder?.name}
          isProcessing={processing}
        />
      </div>
    </Grid>
  );
});

FolderForm.propTypes = {
  // titleButton: PropTypes.string,
  processing: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  onSave: PropTypes.func,
  parentFolderId: PropTypes.number,
};

FolderForm.defaultProps = {
  processing: false,
  parentFolderId: null,
};

FolderForm.displayName = 'FolderForm';

export default FolderForm;
