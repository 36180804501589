import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material';
import i18n from '../../i18n';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import OfficialButton from '../../components/OfficialButtons';

const FileSettingsForm = forwardRef((props, ref) => {
  const { processing, onSave, file } = props;
  const [isDownloadable, setIsDownloadable] = useState(file?.isDownloadable);
  const [hasChangesForm, setHasChangesForm] = useState(false);

  // console.log('### FileSettingsForm', disabled, folder, file, defaultAccessLevel);

  function handleChangeDownloadable(event) {
    setIsDownloadable(event.target.checked);
    setHasChangesForm(true);
  }

  function handleOnSave() {
    if (file) {
      const newFile = {
        ...file,
        isDownloadable,
      };
      return onSave([newFile]);
    }
    return false;
  }

  async function handleCloseForm() {
    let isCloseForm = true;
    if (hasChangesForm) {
      const { isSaved, discard } = await UnsavedChangesDialog.show(
        i18n.t('UNSAVED CHANGES'),
        <>
          {i18n.t(`You have made changes.`)}
          <br />
          {i18n.t(`Would you like to save?`)}
        </>
      );
      isCloseForm = discard;
      if (isSaved) {
        const result = await handleOnSave();
        return result;
      }
    }

    return isCloseForm;
  }

  React.useImperativeHandle(ref, () => ({
    handleCloseForm,
  }));

  if (!file) {
    return null;
  }

  return (
    <>
      <section className="cms-widget-items styled-scrollbar">
        <section className="disableHovering">
          <FormControlLabel
            className="file-downloadable-checkbox"
            control={<Checkbox checked={isDownloadable} onChange={handleChangeDownloadable} />}
            label="This resource can be downloaded"
          />
        </section>
      </section>
      {onSave && (
        <div className="buttons">
          <OfficialButton
            isProcessing={processing}
            variant="regular-green"
            fullWidth
            label={i18n.t('save')}
            onClick={handleOnSave}
          />
        </div>
      )}
    </>
  );
});

FileSettingsForm.propTypes = {
  file: PropTypes.instanceOf(Object),
  processing: PropTypes.bool,
  onSave: PropTypes.func,
};

FileSettingsForm.defaultProps = {};

export default FileSettingsForm;
