import React, { Suspense, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import NotificationItem from './NotificationItem';
import NoDataBox from '../NoDataBox';

import Loading from '../Loading';
import useOnScreen from '../../commons/useOnScreen';

const noDatas = {
  notifications: {
    icon: <span className="icon-notify" />,
    title: 'You have no new notifications',
    description: 'You are all caught up',
  },
  messages: {
    icon: <span className="icon-chat" />,
    title: '',
    description: 'Notifications about new chat messages in your spaces will show up here',
  },
};

const ListItems = (props) => {
  const { type, list, onClickNotification, notificationsQuery, currentUserInfo } = props;
  const waypointRef = useRef(null);
  const { fetchNextPage, hasNextPage } = notificationsQuery;

  const onViewingMonitorChanged = useCallback(
    (isOnscreen) => {
      console.log('isOnscreen', isOnscreen);
      if (isOnscreen) {
        fetchNextPage({ cancelRefetch: false });
      }
    },
    [fetchNextPage]
  );

  useOnScreen(waypointRef, onViewingMonitorChanged, 500, 1);

  if (!list?.length) {
    const { icon, title, description } = noDatas[type];
    return <NoDataBox icon={icon} title={title} description={description} />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <div className="content">
        <div className="notify-list">
          {list?.map((item) => (
            <NotificationItem
              currentUserInfo={currentUserInfo}
              item={item}
              key={item?.id}
              onClick={onClickNotification}
            />
          ))}
          {hasNextPage && (
            <>
              <div style={{ height: 1, width: '100%' }} ref={waypointRef} />
              <div className="loading-box">
                <Loading />
              </div>
            </>
          )}
        </div>
      </div>
    </Suspense>
  );
};

ListItems.propTypes = {
  list: PropTypes.instanceOf(Array),
  type: PropTypes.string,
  onClickNotification: PropTypes.func,
  currentUserInfo: PropTypes.instanceOf(Object),
  notificationsQuery: PropTypes.instanceOf(Object),
};

export default ListItems;
