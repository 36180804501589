/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';

import { downloadBulkResources, downloadDirectory } from '../ResourceServices';
import {
  downloadBulk,
  downloadGlobalDirectory,
} from '../../globalResources/globalResourcesService';
import { sendNotification } from '../../commons/utils';
import i18n from '../../i18n';
import eventBus, { EVENT_BUS } from '../../commons/EventBus';

const useDownloadResource = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDownloadResources = useCallback(async (requestCallback: any) => {
    try {
      const resp = await requestCallback();
      if (resp) {
        eventBus.publish(EVENT_BUS.DownloadResourcesEvent, resp.requestId);
      }
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
      sendNotification(i18n.t('Sorry, something went wrong. Please try again!'), { type: 'error' });
    }
  }, []);

  // Download Directory in space
  const handleClickDownloadDirectory = useCallback(
    async (isPortal: boolean, spaceId: string, directoryId: number | string, data: object) => {
      if (!directoryId) {
        return;
      }
      setIsLoading(true);
      await handleDownloadResources(() => downloadDirectory(isPortal, spaceId, directoryId, data));
    },
    [handleDownloadResources]
  );

  // Download Bulk Resources in space
  const handleClickDownloadBulkResources = useCallback(
    async (isPortal: boolean, spaceId: string, payload: object) => {
      if (!payload) {
        return;
      }
      setIsLoading(true);
      await handleDownloadResources(() => downloadBulkResources(isPortal, spaceId, payload));
    },
    [handleDownloadResources]
  );

  // Download Global Directory
  const handleClickDownloadGlobalDirectory = useCallback(
    async (directoryId: number) => {
      if (!directoryId) {
        return;
      }
      setIsLoading(true);
      await handleDownloadResources(() => downloadGlobalDirectory(directoryId));
    },
    [handleDownloadResources]
  );

  // Download Bulk Resources in Global
  const handleClickDownloadBulkResourcesGlobal = useCallback(
    async (
      directoryIds: (string | number | undefined)[],
      resourceIds: (string | number | undefined)[]
    ) => {
      if (!directoryIds?.length && !resourceIds?.length) {
        return;
      }
      setIsLoading(true);
      await handleDownloadResources(() => downloadBulk(directoryIds, resourceIds));
    },
    [handleDownloadResources]
  );

  return {
    isLoading,
    handleClickDownloadDirectory,
    handleClickDownloadBulkResources,
    handleClickDownloadGlobalDirectory,
    handleClickDownloadBulkResourcesGlobal,
  };
};

export default useDownloadResource;
