import React from 'react';
import PropTypes from 'prop-types';
import { isUrlsEqual } from '../../commons/utils';
import { useMountEffect } from '../../commons/CustomHooks';

const DescriptionPreview = React.memo(
  ({
    description,
    descriptionLinks,
    className,
    textAlignClass,
    descriptionPaddingLRClass,
    ellipsisClass,
    descriptionStyle,
    isPortal,
    handleNavigation,
    handleResourceInteraction,
  }) => {
    const descriptionRef = React.useRef(null);
    const linkElements = React.useRef(null);

    const handleOnClick = (linkElement) => {
      // console.log('### 105 DescriptionPreview onClick', linkElement);
      const url = linkElement.href;
      const text = linkElement.innerText;

      const descriptionLink = descriptionLinks.find((item) => {
        return item.text === text && isUrlsEqual(item.url, url);
      });
      // console.log('### 105 descriptionLink', descriptionLink);
      if (descriptionLink) {
        const linkResourceId = descriptionLink.resourceId;
        const linkMaterialId = descriptionLink.materialId;
        if (handleResourceInteraction) {
          handleResourceInteraction(linkResourceId, linkMaterialId);
        }

        const link = descriptionLink.url;
        if (handleNavigation) {
          handleNavigation(link);
        }
      }
    };

    useMountEffect(() => {
      // console.log(
      //   '### 105 DescriptionPreview useMountEffect',
      //   linkElements.current,
      //   descriptionRef.current,
      //   isPortal
      // );
      if (!isPortal && descriptionRef.current) {
        const selector = `.${className} a`;
        linkElements.current = descriptionRef.current.querySelectorAll(selector);
        // console.log('### 105 DescriptionPreview links', linkElements.current);
        linkElements.current.forEach((linkElement) => {
          linkElement.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            handleOnClick(linkElement);
            return false;
          });
        });
      }
    });

    React.useEffect(() => {
      return () => {
        // console.log('### 105 DescriptionPreview unMount', linkElements.current);
        if (linkElements.current) {
          linkElements.current.forEach((linkElement) => {
            linkElement.removeEventListener('click', (event) => {
              event.preventDefault();
              event.stopPropagation();
              handleOnClick(linkElement);
            });
          });
        }
      };
    });

    function getExtraClassNames() {
      let extraClassNames = '';
      if (textAlignClass) {
        extraClassNames += ` ${textAlignClass}`;
      }
      if (descriptionPaddingLRClass) {
        extraClassNames += ` ${descriptionPaddingLRClass}`;
      }
      if (ellipsisClass) {
        extraClassNames += ` ${ellipsisClass}`;
      }
      return extraClassNames;
    }

    return (
      <span
        ref={descriptionRef}
        className={`${className} cms-description-links${getExtraClassNames()}`}
        style={descriptionStyle}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    );
  }
);

DescriptionPreview.propTypes = {
  description: PropTypes.string,
  descriptionLinks: PropTypes.arrayOf(
    PropTypes.shape({
      resourceId: PropTypes.number,
      materialId: PropTypes.number,
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  textAlignClass: PropTypes.string,
  descriptionPaddingLRClass: PropTypes.string,
  ellipsisClass: PropTypes.string,
  descriptionStyle: PropTypes.instanceOf(Object),
  isPortal: PropTypes.bool.isRequired,
  handleNavigation: PropTypes.func,
  handleResourceInteraction: PropTypes.func,
};

export default DescriptionPreview;
