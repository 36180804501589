export const labelSetting = {
  Push: {
    switch: 'App',
    className: 'notification',
    name: 'AppYou',
    event: 'for_you_notification',
  },
  Email: {
    switch: 'Email',
    select: 'Notify me',
    className: 'email',
    name: 'EmailYou',
    event: 'for_you_email',
  },
};

export const labelSettingParticipants = {
  Push: {
    switch: 'App notification',
    name: 'AppParticipant',
    event: 'for_participant_notification',
  },
  Email: {
    switch: 'Email',
    select: 'Notify users',
    className: 'email',
    name: 'EmailParticipant',
    event: 'for_participant_email',
  },
};

export const descriptionRuleSetting = {
  you: {
    EVENT_PARTICIPANT_ASSIGNED_HOST: 'Notify me when I am added as a host in a space.',
    EVENT_VIDEO_MESSAGE_VIEWED: 'Notify me when users watch video messages in my spaces.',
    EVENT_PARTICIPANT_JOINED_SPACE_FIRST_TIME:
      'Notify me when users access my spaces for the first time. ',
    EVENT_RESOURCE_FOLDER_ADDED: 'Notify me when resources are added or updated in my spaces.',
    EVENT_CHAT_ADDED: 'Notify me when chat messages are posted in my spaces.',
  },
  participant: {
    EVENT_PARTICIPANT_ASSIGNED_HOST:
      'Notifications to users when you are assigned host in a space.',
    EVENT_VIDEO_MESSAGE_VIEWED:
      'Notifications to users when users watch a video message for the first time.',
    EVENT_PARTICIPANT_JOINED_SPACE_FIRST_TIME:
      'Notifications to users when users enter the space for the first time.',
    EVENT_RESOURCE_FOLDER_ADDED: 'Notify users when resources are added or updated in my spaces.',
    EVENT_CHAT_ADDED: 'Notify users when chat messages are posted in my spaces.',
  },
};

export const ruleFields = {
  EVENT_PARTICIPANT_JOINED_SPACE_FIRST_TIME: {
    id: 'JoinsFirstTime',
    name: 'first-time-visitor',
    frequency: 'Daily',
  },
  EVENT_RESOURCE_FOLDER_ADDED: {
    id: 'ResourceAdded',
    name: 'resources',
    frequency: 'Daily',
  },
  EVENT_CHAT_ADDED: {
    id: 'ChatAdded',
    name: 'chat',
    frequency: 'Instant',
  },

  EVENT_PARTICIPANT_ASSIGNED_HOST: {
    id: 'ParticipantAssignedHost',
    name: 'participant',
    frequency: 'Daily',
  },
  EVENT_VIDEO_MESSAGE_VIEWED: {
    id: 'VideoMessageViewed',
    name: 'first-watch_-video',
    frequency: 'Daily',
  },
};

export const notificationsContext = {
  Participants: 'Participants',
  Resources: 'Resources',
  Chats: 'Chats',
  Todos: 'Todos',
};

export const notificationTitles = {
  ParticipantInteraction: {
    title: 'Participants’ interaction',
    icon: 'icon-participant-interaction',
  },
  Participants: {
    title: 'Participants',
    icon: 'icon-users',
  },
  Resources: {
    title: 'Resources',
    icon: 'icon-resources',
  },
  Chats: {
    title: 'Chat',
    icon: 'icon-chat',
  },
  Todos: {
    title: 'Todos',
    icon: 'icon-todo2',
  },
};
