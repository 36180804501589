import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

const EnhancedTableHeader = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    hasSelectedItems,
    isSelectAll,
    onSelectAllClick,
    className,
    columns,
    showCheckBox,
    sortOptions,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const isSelectable = columns.includes('select');
  const sortableColumns = sortOptions.map((option) => option.value);

  const renderCell = (headCell) => {
    if (!columns.includes(headCell.id)) {
      return null;
    }
    const sortability = sortableColumns.includes(headCell.id);
    return (
      <TableCell
        key={headCell.id}
        align={headCell.align ?? 'left'}
        padding={headCell.disablePadding ? 'none' : 'normal'}
        sortDirection={orderBy === headCell.id ? order : false}
        className={`header-cell ${headCell.disableHovering ? 'disableHovering' : ''} ${
          headCell.className
        }`}
        // colSpan={headCell.colSpan ?? 1}
        style={{ width: headCell.width ?? 'auto' }}
      >
        {!sortability ? (
          headCell.label
        ) : (
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
          </TableSortLabel>
        )}
      </TableCell>
    );
  };

  return (
    <TableHead className={`table-head ${className}`}>
      <TableRow>
        {isSelectable && (
          <TableCell className="header-cell" style={{ width: '40px' }}>
            {showCheckBox && (
              <Checkbox
                indeterminate={!isSelectAll && hasSelectedItems}
                checked={isSelectAll}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all items' }}
                color="primary"
              />
            )}
          </TableCell>
        )}
        {headCells.map((headCell) => renderCell(headCell))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      align: PropTypes.string,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
      width: PropTypes.string,
      colSpan: PropTypes.number,
    })
  ).isRequired,
  onSelectAllClick: PropTypes.func,
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.string),
  sortOptions: PropTypes.instanceOf(Array),
  showCheckBox: PropTypes.bool,
  hasSelectedItems: PropTypes.bool,
  isSelectAll: PropTypes.bool,
};

EnhancedTableHeader.defaultProps = {
  headCells: [],
  onSelectAllClick: () => {},
  showCheckBox: true,
};

export default EnhancedTableHeader;
