import React from 'react';
import moment from 'moment';
import { ISpaceUser } from '../../types/User';
import Loading from '../../components/Loading';
import { formatDateTime, parseDateTimeStringFromServer } from '../../commons/DateTimeUtils';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import i18n from '../../i18n';
import LightTooltip from '../../components/LightTooltip';
import useChatsByUserQuery, { IChat } from '../queries/useChatsByUserQuery';
import Table from '../components/Table';
import Icons from '../../components/Icons';

const emptyState = {
  icon: <Icons className="icon-chat-new" />,
};

type ChatsByUserProps = {
  userInfo: ISpaceUser;
};

function ChatsByUser(props: ChatsByUserProps) {
  const { userInfo } = props;
  const { space, reportEndDate } = useSpaceAnalyticsContext();
  const spaceId = space?.id;
  const endDate = moment(reportEndDate).toDate().toISOString();

  const { chats, isFetching } = useChatsByUserQuery(
    spaceId,
    userInfo.id,
    endDate,
    !!spaceId && !!userInfo.id
  );
  console.log('chats', chats);

  function renderTable() {
    return (
      <Table
        columns={[
          {
            title: 'Date',
            field: 'createdDate',
            cellStyle: {
              width: '10%',
            },
            defaultSort: 'desc',
            render: (rowData: IChat) => {
              return rowData ? (
                formatDateTime(
                  parseDateTimeStringFromServer(rowData.createdDate)?.local(),
                  'MMM DD, yyyy hh:mm A'
                )
              ) : (
                <LightTooltip title={i18n.t('This to-do has no due date.')}>
                  <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
                </LightTooltip>
              );
            },
          },
        ]}
        data={chats}
        emptyState={emptyState}
      />
    );
  }

  if (isFetching) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <div className="tab-content-container meetings-by-user-container">
      <p className="short-description">
        {userInfo.fullName} sent <b>{chats.length} </b> {chats.length <= 1 ? 'message' : 'messages'}{' '}
        in public chat.
      </p>
      {renderTable()}
    </div>
  );
}

export default ChatsByUser;
