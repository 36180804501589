import React, { useState, useEffect, useCallback, useRef, Suspense } from 'react';
import Moveable from 'react-moveable';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Logo from './../images/spce-logo.png';
import './VaamRecordingBox.scss';
import SwitchEnhanced from '../components/SwitchEnhanced';
import i18n from '../i18n';
import { addVaam, deleteVaam } from './VaamServices';
import { ResourceType, Provider, SidebarType } from './../app/appConstants';
import { sendNotification } from '../commons/utils';
import { useSpaceContext } from '../spaces/SpaceContext';
import ShareVaamDialog from './ShareVaamDialog';
import OfficialButton from '../components/OfficialButtons';
import { Ids } from '../commons/pendoTaggings';
import ConfirmService from '../components/ConfirmService';
import BlockUI from '../components/BlockUI';

// const VaamRecordingBox = React.lazy(() => import('../vaam/VaamRecordingBox'));
// use React.lazy to load vaam sdk only when it is needed
const Recorder = React.lazy(() => import('./VaamRecorder'));

const VaamRecordingBox = ({ onClose, spaceId }) => {
  const {
    setShowVaamRecorder,
    setSidebar,
    goToChat,
    vaamTargetChannel,
    isVaamFromChat,
    theme,
    isPublicSpace,
    getFeatureSpace,
  } = useSpaceContext();
  const features = getFeatureSpace();
  const chatEnabled = features?.Chat;

  const [isRecordingFinished, setIsRecordingFinished] = useState(false);
  const [isRecordingProcessing, setIsRecordingProcessing] = useState(false);
  const [isRecordScreen, setIsRecordScreen] = useState(false);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [vaamCapturedId, setVaamCapturedId] = useState(null);
  const [vaamContainerBox, setVaamContainerBox] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const recorderEl = React.useRef(null);
  const vaamBox = React.useRef();
  const [accumulatedTransform, setAccumulatedTransform] = useState('');
  const [dragStarted, setDragStarted] = useState(false);

  const handleOnClickStartRecording = useCallback((sender) => {
    const recordingBtn = sender;
    recordingBtn.textContent = 'Starting...';
    recordingBtn.style.backgroundColor = '#ECEEEF';
    recordingBtn.style.color = 'rgba(0, 0, 0, 0.4)';
    recordingBtn.style.cursor = 'not-allowed';
    recordingBtn.setAttribute('disabled', 'true');
    recordingBtn.removeEventListener('click', handleOnClickStartRecording);
  }, []);

  useEffect(() => {
    const unhandledRejectionHandler = () => {
      const popupContainer = document.getElementsByClassName('recorder');
      if (popupContainer && popupContainer.length > 0) {
        const recordingBtn = popupContainer[0].querySelector('[class*=PreRecordingControls]');
        if (recordingBtn) {
          recordingBtn.textContent = 'Start recording';
          recordingBtn.removeAttribute('disabled');
          recordingBtn.style.cursor = 'pointer';
          recordingBtn.style.color = '#181818';
          recordingBtn.style.backgroundColor = '#1fda8b';
          recordingBtn.addEventListener('click', () => handleOnClickStartRecording(recordingBtn));
        }
      }
    };

    window.addEventListener('unhandledrejection', unhandledRejectionHandler);

    return () => {
      window.removeEventListener('unhandledrejection', unhandledRejectionHandler);
    };
  }, [handleOnClickStartRecording]);

  const onDragStart = (e) => {
    setDragStarted(true);
    setAccumulatedTransform(e.target.parentNode.parentNode.parentNode.style.transform || '');
  };
  const onClickClose = useCallback(
    (finishButton) => {
      let finishBtn = finishButton;
      if (!finishBtn) {
        const popupContainer = document.querySelector('[class=vaam-recording-box]');
        if (popupContainer) {
          const vaamControlButtons = popupContainer.querySelectorAll(
            '[class*=ControlButtons__InlineButton]'
          );
          if (vaamControlButtons && vaamControlButtons.length > 1) {
            finishBtn = vaamControlButtons[1];
          }
        }
      }
      if (finishBtn) {
        finishBtn.click();
      }
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    setVaamContainerBox(vaamBox);
  }, []);

  useEffect(() => {
    const handleShowLoadingState = () => {
      setIsRecordingFinished(true);

      const videoContainer = document.querySelector('[class*=CamView]');
      videoContainer.style.display = 'none';

      if (!isRecordingFinished) {
        const loadingDiv = document.createElement('div');
        loadingDiv.className = 'loading';
        const icon = document.createElement('i');
        icon.className = 'icon-spinner';
        const text = document.createElement('p');
        text.innerHTML = 'Creating video...';

        loadingDiv.appendChild(icon);
        loadingDiv.appendChild(text);

        // replace video element with loading
        videoContainer.parentNode.replaceChild(loadingDiv, videoContainer);
      }
    };

    const [target] = document.getElementsByClassName('recorder');
    const observer = new MutationObserver((mutationRecords) => {
      const recorders = document.getElementsByClassName('recorder');
      if (recorders.length === 0) {
        return;
      }
      const recorder = recorders[0];
      console.log('Number of mutations : ', mutationRecords);
      for (let i = 0, len = mutationRecords.length; i < len; i += 1) {
        // console.log('@@@target : ', mutationRecords[i].target);
        // console.log('@@@type : ', mutationRecords[i].type);
        // console.log('@@@attributeName : ', mutationRecords[i].attributeName);
        // console.log('@@@calss : ', mutationRecords[i].className);
        // console.log('@@@oldValue : ', mutationRecords[i].oldValue);
        // console.log('@@@@@@@@@@@@@@@@@@@@ :');
        // console.log('@@@isProcessing :', isRecordingProcessing);
        const closeBtns = mutationRecords[i].target.querySelectorAll(
          '[class*=ControlButtons__InlineButton]'
        );
        const timerBox = mutationRecords[i].target.querySelectorAll(
          '[class*=ControlButtons___StyledBox]'
        );

        if (closeBtns && closeBtns[0]) {
          closeBtns[0].classList.add('set-hidden');
          closeBtns[0].removeEventListener('click', () => onClickClose(closeBtns[1]));
          closeBtns[0].addEventListener('click', () => onClickClose(closeBtns[1]));
        }

        if (closeBtns && closeBtns[1]) {
          const iconElement = document.createElement('b');
          iconElement.textContent = 'STOP';
          closeBtns[1].firstChild.classList.add('set-hidden');
          closeBtns[1].insertBefore(iconElement, closeBtns[1].firstChild);
          closeBtns[1].classList.add('stop-button');
          closeBtns[1].removeEventListener('click', handleShowLoadingState);
          closeBtns[1].addEventListener('click', handleShowLoadingState);
        }

        if (closeBtns && closeBtns[2]) {
          closeBtns[2].classList.add('set-hidden');
        }

        const recordingBtn = recorder.querySelector('[class*=PreRecordingControls]');
        const vaamContainers = recorder.getElementsByClassName('vaam-input');
        if (!vaamContainers[0]) {
          return;
        }
        // console.log('vaamContainers', vaamContainers);
        if (recordingBtn) {
          if (recordingBtn.getAttribute('data-id')) {
            return;
          }
          vaamContainers[0].style.height = '430px';
          setIsRecordingStarted(false);
          recordingBtn.setAttribute('data-id', Ids.vaamStartRecording);

          if (!isRecordScreen) {
            recordingBtn.addEventListener('click', () => handleOnClickStartRecording(recordingBtn));
          }
        } else {
          const video = recorder.getElementsByClassName('vjs-tech');
          if (isRecordingFinished || (video && video[0])) {
            vaamContainers[0].style.height = '188px';
          } else {
            vaamContainers[0].style.height = '325px';
            setIsRecordingStarted(true);
          }
        }

        if (timerBox && timerBox[0]) {
          setIsRecordingProcessing(true);
          const iconElement = document.createElement('div');
          timerBox[0].classList.add('set-top');
          timerBox[0].classList.add('timer-box');

          iconElement.classList.add('green-dot');

          timerBox[0].insertBefore(iconElement, timerBox[0].firstChild);
        } else {
          if (isRecordingFinished) {
            setIsRecordingProcessing(false);
          }
        }
      }
    });

    observer.observe(target, {
      attributes: true,
      subtree: true,
      childList: true,
      attributeOldValue: true,
      // attributeFilter: ["type='button'"],
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [handleOnClickStartRecording, isRecordScreen, isRecordingFinished, onClickClose]);

  async function onFinishedRecording(id, waitingToUpload) {
    const vaamContainers = document.getElementsByClassName('vaam-input');
    if (vaamContainers[0]) {
      vaamContainers[0].style.height = '188px';
    }

    if (!waitingToUpload) {
      setIsRecordingFinished(true);
      setVaamCapturedId(id);

      try {
        const data = {
          fileName: '',
          fileDescription: '',
          type: ResourceType.vaam,
          src: '',
          length: 0,
          externalDocumentSettings: {
            documentId: id,
            provider: Provider.Vaam,
          },
          notifyParticipantSettings: {
            participants: [],
          },
        };

        const resp = await addVaam(spaceId, data);
        setResourceId(resp);
      } catch (e) {
        sendNotification(e.message, { type: 'error' });
      }
    }
  }

  async function onClickRestartButton() {
    setIsRecordingFinished(false);
    setIsRecordingStarted(false);
    setResourceId(null);
    try {
      await deleteVaam(spaceId, resourceId);
    } catch (e) {
      sendNotification(e.message, { type: 'error' });
    }
  }
  const confirmationDialog = useRef();

  useEffect(() => {
    confirmationDialog.current = window.confirm;
    window.confirm = () => {
      return true;
    };
    return () => {
      window.confirm = confirmationDialog.current;
    };
  }, []);

  async function onClickRetryButton() {
    const btn = document.querySelector('[class*=Player__ButtonLabel]');
    if (btn) {
      const isConfirmed = await ConfirmService.show(
        i18n.t('RETRY VIDEO'),
        <>
          <span>{i18n.t('This will delete your current recording. Are you sure?')}</span>
          <br />
        </>,
        i18n.t('Yes'),
        i18n.t('Cancel')
      );
      if (isConfirmed) {
        btn.click();
      }
    }
  }

  function handleRecordScreen(event) {
    setIsRecordScreen(event.target.checked);
  }

  function openChatSidePanel() {
    setSidebar(SidebarType.Chat);
  }

  function onShowSharePopup() {
    setShowVaamRecorder(false);

    ShareVaamDialog.show(
      vaamCapturedId,
      spaceId,
      resourceId,
      openChatSidePanel,
      vaamTargetChannel,
      goToChat,
      isVaamFromChat,
      chatEnabled,
      theme.logoImageSrc,
      isPublicSpace
    );
  }
  const isVaamTestEnv = process.env.REACT_APP_ENV !== 'prod';
  return (
    <div className={`vaam-recording-box ${isRecordingProcessing ? ' unset-bg' : ''} `}>
      <Moveable
        target={vaamContainerBox}
        draggable
        origin={false}
        edgeDraggable={false}
        className="moveable-line"
        onDrag={(e) => {
          if (dragStarted) {
            const newTransform = accumulatedTransform + e.transform;
            e.target.parentNode.parentNode.parentNode.style.transform = newTransform;
          }
        }}
        onDragStart={onDragStart}
        onDragEnd={() => setDragStarted(false)}
        // boundary={{ left: 0, top: 0, right: window.innerWidth, bottom: window.innerHeight }}
      />
      <div className="body">
        <div
          className={`header active-recording ${
            isRecordingProcessing ? 'set-visibility-hidden' : ''
          }`}
        >
          {theme.logoImageSrc ? (
            <img src={theme.logoImageSrc} alt="Logo" className="logo" />
          ) : (
            <img src={Logo} alt="SPCE logo" className="logo" />
          )}

          {!isRecordingStarted && (
            <SwitchEnhanced
              className="record-screen-switch"
              checked={isRecordScreen}
              label={i18n.t('Share my screen')}
              onChange={handleRecordScreen}
            />
          )}

          <IconButton aria-label="close" className="move-area-button" color="primary" ref={vaamBox}>
            <i className="icon-move-area" />
          </IconButton>

          <IconButton
            aria-label="close"
            className="close-button"
            color="primary"
            onClick={() => onClickClose()}
          >
            <CloseIcon className="close-icon" />
          </IconButton>
        </div>
        <div className="content">
          <div
            className={`recorder${isRecordingFinished ? ' done-recording' : ''}${
              isRecordingStarted ? ' recording-started' : ''
            } `}
            ref={recorderEl}
          >
            <Suspense
              fallback={
                <BlockUI display backgroundColor="#eee" iconColor="#222" backgroundOpacity={1} />
              }
            >
              <Recorder
                apiKey={process.env.REACT_APP_VAAM_KEY}
                title="VamRecorder"
                startInactive={false}
                testEnv={isVaamTestEnv}
                handleRecordingRestarted={onClickRestartButton}
                handleRecordingFinish={onFinishedRecording}
                recordScreen={isRecordScreen}
              />
            </Suspense>
            {isRecordingFinished && (
              <div className="action-buttons">
                {resourceId && (
                  <>
                    <OfficialButton
                      label={i18n.t('Retry')}
                      onClick={onClickRetryButton}
                      variant="regular-secondary"
                      dataId={Ids.vaamRetryBtn}
                    />
                    <OfficialButton
                      label={i18n.t('Next')}
                      onClick={onShowSharePopup}
                      variant="regular-primary"
                      dataId={Ids.vaamShareBtn}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

VaamRecordingBox.propTypes = {
  onClose: PropTypes.func,
  spaceId: PropTypes.string,
};

export default VaamRecordingBox;
