import { InputLabel } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import LightTooltip from '../LightTooltip';

export const DefaultUploaderTooltip = (
  <div className="file-input-info">
    {'Supported file types:'}
    <ul className="file-types-list">
      <li>
        <b>{'Image:'}</b> {'JPG, JPEG, PNG, APNG, GIF, SVG, WEBP, TIF, TIFF.'}
      </li>
      <li>
        <b>{'Video & audio:'}</b> {'MP4, AVI, MOV, MPG, MPEG, WEBM, MP3, WAV.'}
      </li>
      <li>
        <b>{'Document:'}</b> {'DOC, DOCX, PDF, PPT, PPTX, KEY.'}
      </li>
      <li>
        <b>{'Spreadsheet:'}</b> {'XLSX, XLSM, XLSB, XLS.'}
      </li>
      <li>
        <b>{'3D:'}</b> {'DWG, STP, IGS, JT, STEP, SAT, STL.'}
      </li>
      <li>
        <b>{'Fonts:'}</b> {'TTF, OTF, WOFF, WOFF2.'}
      </li>
      <li>
        <b>{'Other:'}</b> {'ZIP, EPS, AI, INDD, PSD, DCM.'}
      </li>
    </ul>
  </div>
);

function UploaderLabel(props) {
  const { label, tooltip, element, htmlFor, tooltipClasses } = props;

  return (
    <div className="dnd-file-uploader-control-label-wrapper">
      {label && (
        <InputLabel htmlFor={htmlFor} className="dnd-file-uploader-control-label">
          {label}
        </InputLabel>
      )}
      {tooltip && (
        <LightTooltip title={tooltip} placement="bottom-start" classes={tooltipClasses}>
          <i className="icon-info" />
        </LightTooltip>
      )}
      {element}
    </div>
  );
}

UploaderLabel.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  htmlFor: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tooltipClasses: PropTypes.object,
};

UploaderLabel.defaultProps = {
  label: i18n.t('Add folder'),
  // tooltip: DefaultUploaderTooltip,
  element: null,
  htmlFor: 'upload-btns',
  tooltipClasses: { tooltip: 'file-input-tooltip' },
};

export default UploaderLabel;
