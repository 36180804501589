import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import useWaypoint from '../commons/useWaypoint';
import BlockUI from './BlockUI';
import eventBus, { EVENT_BUS } from '../commons/EventBus';
import { useMaterialsContext } from '../spaces/Materials/MaterialsContextProvider';
import spaceUser from '../spaces/spaceUser';

const TCOCalculationViewer = (props) => {
  const { key, title, src } = props;
  const [loaded, setLoaded] = useState(false);
  const { visible, onEnter } = useWaypoint();
  const ref = React.createRef();
  const spaceId = spaceUser.getSpaceId();

  const { viewMaterial } = useMaterialsContext();

  const sendMessage = useCallback(() => {
    ref.current?.contentWindow.postMessage({ message: 'resetForm' }, '*');
  }, [ref]);

  const handleMaterialAdded = useCallback(
    (detail) => {
      // Handle open TCO report
      if (
        detail.materialType === 'TcoCalculations' &&
        sessionStorage.getItem('tcoReportSaving') === 'true'
      ) {
        viewMaterial({
          id: detail.id,
          spaceId: spaceId,
          type: detail.resource.type,
        });
        sendMessage();
      }
    },
    [spaceId, viewMaterial, sendMessage]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const unsubscribes = [];

    unsubscribes.push(eventBus.subscribe(EVENT_BUS.MaterialAdded, handleMaterialAdded));

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [handleMaterialAdded]);

  return (
    <Waypoint onEnter={onEnter} fireOnRapidScroll={false} debug={false} bottomOffset={'-100px'}>
      <div
        className="iframe-player-container tco"
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        {visible && (
          <>
            {!loaded && (
              <BlockUI display backgroundColor="#fff" iconColor="#222" backgroundOpacity={1} />
            )}
            <iframe
              ref={ref}
              key={key}
              src={src}
              title={title}
              id="iframe"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              onLoad={() => setTimeout(() => setLoaded(true), 2000)}
              style={loaded ? { backgroundColor: '#fff' } : { backgroundColor: '#fff', height: 0 }}
            ></iframe>
          </>
        )}
      </div>
    </Waypoint>
  );
};

TCOCalculationViewer.propTypes = {
  key: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
};

export default TCOCalculationViewer;
