import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _, { forEach } from 'lodash';
import Popover from '@mui/material/Popover';
import FavoriteIcon from '@mui/icons-material/Favorite';
import UserAvatar from '../../components/UserAvatar';
import './ParticipantsInteractions.scss';
import i18n from '../../i18n';
import spaceUser from '../spaceUser';

const getInfoParticipant = (participants, interactions) => {
  if (!participants?.length) {
    return [];
  }
  const participantsId = _.mapValues(_.keyBy(participants, 'userInfoId'));
  const foundParticipants = [];
  let foundParticipant = null;
  forEach(interactions, (item) => {
    foundParticipant = participantsId[item.participantId];
    console.log('participantsId', item.participantId, participantsId);
    if (foundParticipant) {
      foundParticipants.push({
        fullName: foundParticipant.fullName,
        email: foundParticipant.email,
      });
    }
  });
  return foundParticipants;
};

function ParticipantsInteractionsPopover(props) {
  const { participants, handleOnClickLike, comment, liked } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentUser = spaceUser.getUserInfo();
  const allUsers = useMemo(() => [currentUser, ...participants], [currentUser, participants]);
  console.log('currentUser: ', currentUser);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  console.log('open', open);

  function renderContent() {
    if (!comment?.commentInteractions.length) {
      return '';
    }

    const interactedParticipants = getInfoParticipant(allUsers, comment?.commentInteractions);

    return (
      <div className="interaction-popover">
        <div className="title">{i18n.t('Liked by')}</div>{' '}
        {interactedParticipants.map(
          (item, index) =>
            item.fullName && (
              <div className="participant-item" key={index}>
                <UserAvatar fullName={item.fullName} email={item.email} />
                <span>{item.fullName}</span>
              </div>
            )
        )}
      </div>
    );
  }

  function renderHeartIcon() {
    const interactedParticipants = getInfoParticipant(allUsers, comment?.commentInteractions);

    if (interactedParticipants?.length === 0) {
      return null;
    }

    return (
      <div className={`liked-message ${liked ? 'liked' : ''} `}>
        <span
          aria-owns={open ? 'interaction-popover' : undefined}
          aria-haspopup="true"
          className="like-icon likes row-direction alignItemsCenter gap4"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          onClick={() => handleOnClickLike(comment)}
        >
          <FavoriteIcon style={{ color: 'red', fontSize: 16 }} />
          <span className="like-count">{interactedParticipants.length}</span>
        </span>
      </div>
    );
  }

  return (
    <>
      {renderHeartIcon()}
      {open && (
        <Popover
          id="interaction-popover"
          className="interaction-popover"
          classes={{
            paper: 'interaction-popover-paper',
          }}
          open
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {renderContent()}
        </Popover>
      )}
    </>
  );
}

ParticipantsInteractionsPopover.propTypes = {
  participants: PropTypes.instanceOf(Array),
  comment: PropTypes.instanceOf(Object),
  handleOnClickLike: PropTypes.func,
  liked: PropTypes.bool,
};

export default ParticipantsInteractionsPopover;
