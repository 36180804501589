import React, { useState, useRef, forwardRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
// import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './ParticipantList.scss';
import { Alert, Card, CardContent } from '@mui/material';
import { addOrUpdateSpaceUser, addSpaceUsers } from './spaceUsersServices';
import Chat from '../Comments/Chat';
import LoadingMessage from '../../components/LoadingMessage';
import spaceUser from '../spaceUser';
import { isOrgRole, sendNotification } from '../../commons/utils';
import {
  FeatureFlagsType,
  OrganizationUserRole,
  ParticipantRole,
  SidebarType,
} from '../../app/appConstants';

import ConfirmService from '../../components/ConfirmService';
import { useSpaceUsersContext } from './SpaceUsersContextProvider';
import { useSpaceContext } from '../SpaceContext';
import i18n from '../../i18n';
import Tabs, { useTabs } from '../../components/Tabs';
import RequestAccessList from './RequestAccessList';
import useUnmountEffect from '../../commons/useUnmountEffect';
import OfficialButton from '../../components/OfficialButtons';
import queryCache, { CacheKeys } from '../../app/queryCache';
import { useFeatureFlagsContext } from '../../commons/FeatureFlags/FeatureFlagsContextProvider';
import ParticipantsTable from './ParticipantsTable';
// import { useRuntimeContext } from '../RuntimeContext';
import LightTooltip from '../../components/LightTooltip';
import ParticipantInfoForm from './ParticipantInfoForm';
import NoDaTaBox from '../../components/NoDataBox';
import useOrganizationsUsersQuery from '../../users/useOrganizationsUsersQuery';
import useSpaceUsersQuery from './useSpaceUsersQuery';
import AddPartnerUsersDialog from '../../components/ParticipantSelect/AddPartnerUsersDialog';

const MODE = {
  LIST: 'LIST',
  SELECT: 'SELECT',
};
function checkIsNewJoinedParticipant(newJoinedParticipants, id) {
  const el = newJoinedParticipants?.find((i) => i === id);
  return !!el;
}

const ParticipantList = React.memo(
  forwardRef((props, ref) => {
    const { checkEnabledFeatures } = useFeatureFlagsContext();
    const [enabledAddHost] = checkEnabledFeatures([FeatureFlagsType.SPACE_ADD_HOST]);

    const { showHeader } = props;
    const participantFormRef = useRef();
    const chatFormRef = useRef();
    const [chatParticipants, setChatParticipants] = useState([]);
    const [chatWith, setChatWith] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const { participants, deleteSpaceUser, numOnlineUsers } = useSpaceUsersContext();
    const [newAddedUsers, setNewAddedUsers] = useState(false);
    const getSpaceUsersQuery = useSpaceUsersQuery(props.spaceId, false, true, true);

    const {
      newNotifications,
      setNotifications,
      setActiveChannel,
      currentTabInParticipant,
      setCurrentTabInParticipant,
      space,
      isPublicSpace,
      isPrivateSpace,
      setIsOpenAddParticipantForm,
      sidebar,
      setSidebar,
      getFeatureSpace,
    } = useSpaceContext();

    const newJoinedParticipants = newNotifications?.Participants?.details?.firstJoinedSpaceUsers;

    const [selectedParticipants, setSelectedParticipants] = useState([]); // to store selected participants to invite
    const [mode, setMode] = useState(MODE.LIST);
    const isSpace = true;
    const isLoading = getSpaceUsersQuery.data === undefined;
    const spaceFeatures = getFeatureSpace(false);

    const currentParticipant = spaceUser.getSpaceAccessSession();
    const isEditing =
      showForm &&
      !!selectedParticipant &&
      selectedParticipant.userInfoId > 0 &&
      !props.participantWidget;

    const getOrgUsersHook = useOrganizationsUsersQuery(
      props.isModerator && showForm,
      'space-users-select'
    );
    const showHeaderPrivateChat = chatParticipants.length === 0;

    const users = useMemo(() => {
      const usersOrg = getOrgUsersHook.data || [];
      return usersOrg;
    }, [getOrgUsersHook.data]);

    const dictUsers = useMemo(() => {
      const dict = users.reduce((acc, curr) => {
        if (curr?.contactEmail?.length > 0) {
          acc[curr.contactEmail.toLowerCase()] = curr;
        }
        return acc;
      }, {});
      return dict;
    }, [users]);

    const { tabs, changeTab, activeTab } = useTabs(
      [i18n.t('Users'), i18n.t('Requests')],
      currentTabInParticipant
    );

    const participantsMapping = useMemo(() => {
      let countExternalIndex = 0;
      return participants.map((participant) => {
        const isCurrentUser = participant.userInfoId === props.userInfoId;
        const isVisitor = participant.isExternal && isPublicSpace;
        if (isVisitor) {
          countExternalIndex += 1;
        }
        return {
          ...participant,
          canChat: participant.userInfoId && props.mainChannelId && props.userInfoId,
          canInviteOrRemovePariticipant:
            props.isModerator && participant.isOnline && !isCurrentUser,
          isCurrentParticipant: isCurrentUser,
          isNewParticipant: checkIsNewJoinedParticipant(
            newJoinedParticipants,
            participant.userInfoId
          ),
          lastActive: participant?.isOnline ? new Date() : participant?.lastActive,
          fullName: isVisitor ? `User ${countExternalIndex}` : participant.fullName,
          email: isVisitor ? '' : participant.email,
        };
      });
    }, [
      participants,
      newJoinedParticipants,
      props.isModerator,
      props.mainChannelId,
      props.userInfoId,
      isPublicSpace,
    ]);

    React.useEffect(() => {
      if (props.isOpenAddParticipantForm) {
        setShowForm(true);
        if (props.isOpenAddParticipantForm?.userInfoId) {
          setSelectedParticipant(props.isOpenAddParticipantForm);
        }
      }
    }, [props.isOpenAddParticipantForm]);

    useEffect(() => {
      changeTab(currentTabInParticipant);
    }, [changeTab, currentTabInParticipant]);

    useUnmountEffect(() => {
      setCurrentTabInParticipant(0);
    });

    function onCloseActionMenu() {
      setSelectedParticipant(null);
    }

    const startChatWith = (participant) => {
      setChatWith(participant);
      setChatParticipants([currentParticipant, participant]);
      onCloseActionMenu();
      if (props.setSelectedChannel) {
        props.setSelectedChannel(participant);
      }
      if (props.onOpenChat) {
        props.onOpenChat(participant);
      }
    };

    function handleOnClickLeaveChannel() {
      setChatParticipants([]);
      if (props.setSelectedChannel) {
        props.setSelectedChannel(null);
      }
    }

    const handleOnClickChatMenuItem = () => {
      const participant = { ...selectedParticipant };
      setSelectedParticipant(participant);
      startChatWith(participant);
    };

    const handleClickParticipant = (participant) => {
      if (spaceFeatures.Chat) {
        setSelectedParticipant(participant);
        startChatWith(participant);
      }
    };

    function handleOpenAddParticipantPanel(participant = true) {
      if (sidebar === SidebarType.Participants) {
        setSidebar(null);
        setIsOpenAddParticipantForm();
      } else {
        setSidebar(SidebarType.Participants);
        setIsOpenAddParticipantForm(participant);
      }
    }

    async function handleOnCLickEditMenuItem() {
      console.log('selectedParticipant', selectedParticipant);
      if (props.participantWidget) {
        handleOpenAddParticipantPanel(selectedParticipant);
      } else {
        setShowForm(true);
      }
    }

    function handleOnClickCancelSelectMode() {
      setMode(MODE.LIST);
      setSelectedParticipants([]);
    }

    function handleToogleSelectAll() {
      const isSelectAll = selectedParticipants.length === participants.length;
      if (isSelectAll) {
        setSelectedParticipants([]);
      } else {
        const newSelectedParticipants = [...selectedParticipants];
        participants.forEach((participant) => {
          const foundIndex = _findIndex(
            selectedParticipants,
            (item) => item.userInfoId === participant.userInfoId
          );
          if (foundIndex === -1) {
            newSelectedParticipants.push(participant);
          }
        });
        if (newSelectedParticipants.length !== selectedParticipants.length) {
          setSelectedParticipants(newSelectedParticipants);
        }
      }
    }

    async function handleDeleteParticipantMenuItem() {
      const spaceOwner = selectedParticipant?.userInfoId === space.createdByUserInfoId;
      if (spaceOwner) {
        sendNotification(
          i18n.t(
            `Transfer ownership of the ${props.isTemplate ? 'template' : 'space'} before deletion.`
          ),
          { type: 'error' },
          i18n.t(
            `It is not possible to delete the ${props.isTemplate ? 'template' : 'space'} owner.`
          )
        );
        return;
      }
      function getParticipantDeletionMessage() {
        if (props.isTemplate) {
          return 'Users can still join this template.';
        } else if (isPrivateSpace) {
          return 'Users cannot join this space until host grants access.';
        } else if (isPublicSpace) {
          return 'Users can still join this space as a visitor.';
        } else {
          return 'Users can still join this space by registering their name and email.';
        }
      }
      const isConfirmed = await ConfirmService.show(
        `${i18n.t('DELETE USER')} ?`,
        <>
          {i18n.t('Are you sure you want to delete the selected user?')} <br />
          {i18n.t(getParticipantDeletionMessage())}
        </>,
        i18n.t('Yes'),
        i18n.t('Cancel')
      );
      if (!isConfirmed) {
        return;
      }

      if (selectedParticipant) {
        const participantUserInfoId = selectedParticipant.userInfoId;

        try {
          await deleteSpaceUser(participantUserInfoId);
          queryCache.removeQueries({ queryKey: [CacheKeys.fetchSpacesList], exact: false });
        } catch (error) {
          console.log('ERROR delete participant: ', error.message);
          sendNotification(error.message, { type: 'error' });
        } finally {
          setSelectedParticipant(null);
        }
      }
    }

    function handleOnClickAdd() {
      setSelectedParticipant(null);
      setShowForm(true);
      if (props.participantWidget) {
        handleOpenAddParticipantPanel();
      }
    }

    async function handleOnSaveParticipant(data) {
      const payload = {
        ...data,
        userInfoId: selectedParticipant?.userInfoId,
      };
      setIsSaving(true);
      try {
        const result = await addOrUpdateSpaceUser(props.spaceId, payload);

        setIsSaving(false);
        if (result.error) {
          throw new Error(result.error);
        }
        setShowForm(false);
        if (!selectedParticipant?.userInfoId) {
          queryCache.removeQueries({ queryKey: [CacheKeys.fetchSpacesList], exact: false });
        }
        return result;
      } catch (error) {
        setIsSaving(false);
        sendNotification(error.message, { type: 'error' });
        return null;
      }
    }

    async function handleOnAddParticipants(data) {
      setIsSaving(true);
      try {
        const result = await addSpaceUsers(props.spaceId, data);
        setIsSaving(false);
        if (result.error) {
          throw new Error(result.error);
        }
        setShowForm(false);
        if (!selectedParticipant?.userInfoId) {
          queryCache.removeQueries({ queryKey: [CacheKeys.fetchSpacesList], exact: false });
        }
        return result;
      } catch (error) {
        setIsSaving(false);
        sendNotification(error.message, { type: 'error' });
        return null;
      }
    }

    async function handleOnSave(data) {
      console.log('data', data);
      let result = null;
      if (isEditing) {
        result = await handleOnSaveParticipant(data);
      } else {
        result = await handleOnAddParticipants(data);
      }
      if (result) {
        const newUsers = data.filter((item) => !item.isOrgUser);
        setNewAddedUsers(newUsers);
      }
      return result;
    }

    function renderParticipantListBody() {
      const canChat =
        !spaceUser.isHidden() &&
        spaceFeatures.Chat &&
        !props.isEdit &&
        !(selectedParticipant?.isExternal && isPublicSpace) &&
        !spaceUser.isVisitor();

      return (
        <ParticipantsTable
          participants={participantsMapping}
          setSelectedParticipant={setSelectedParticipant}
          onClickItem={canChat ? handleClickParticipant : null}
          onClickEdit={handleOnCLickEditMenuItem}
          onClickDelete={handleDeleteParticipantMenuItem}
          onClickChat={canChat ? handleOnClickChatMenuItem : null}
          isModerator={props.isModerator}
          isHost={props.isHost}
          userInfoId={props.userInfoId}
          canSearch={!props.participantWidget && !props.isMobile}
          disabledActionButton={props.isEdit}
          isMobile={props.isMobile}
          canChat={canChat}
          isPublicSpace={isPublicSpace}
        />
      );
    }

    function renderParticipantListHeader() {
      const isSelectAll = selectedParticipants.length === participants.length;

      if (mode === MODE.SELECT) {
        return (
          <div className="participant-list-filter select-header">
            <span>{i18n.t('Select users to invite')}</span>
            <div>
              {isSelectAll ? (
                <Button onClick={handleToogleSelectAll}>{i18n.t('Deselect all')}</Button>
              ) : (
                <Button onClick={handleToogleSelectAll}>{i18n.t('Select all')}</Button>
              )}
            </div>
          </div>
        );
      }

      return null;
    }

    function renderParticipantList() {
      return (
        <div className="participant-list-content">
          {renderParticipantListHeader()}
          {renderParticipantListBody()}
          {!spaceFeatures.Participants && (
            <Alert icon={<i className="icon-info" />} severity="info" className="information-alert">
              {i18n.t('The panel is only visible to hosts in the space.')}
            </Alert>
          )}
        </div>
      );
    }

    function renderParticipantForm() {
      if (!showForm) {
        return null;
      }
      console.log('### 703 selectedParticipant', selectedParticipant);
      let hasPartnerRole = false;
      if (selectedParticipant?.email) {
        // while editing participant
        const orgUser = dictUsers[selectedParticipant.email.toLowerCase()];
        hasPartnerRole = isOrgRole(orgUser?.role, OrganizationUserRole.Partner);
      }
      const disableRoleSelect = hasPartnerRole;

      let defaultRole;
      if (isSpace) {
        defaultRole =
          selectedParticipant?.isAdmin === true
            ? ParticipantRole.Host
            : ParticipantRole.Participant;
      }
      if (hasPartnerRole) {
        defaultRole = ParticipantRole.Participant;
      }
      if (!enabledAddHost) {
        defaultRole = ParticipantRole.Participant;
      }

      if (isEditing) {
        defaultRole = selectedParticipant?.lastRole;
      }

      return (
        <CardContent className="participant-form">
          <Card className="participant-form-content">
            <ParticipantInfoForm
              ref={participantFormRef}
              isSavingData={isSaving}
              onSubmit={handleOnSave}
              canSetRole
              key={selectedParticipant?.id}
              defaultFirstName={selectedParticipant?.firstName}
              defaultLastName={selectedParticipant?.lastName}
              defaultEmail={selectedParticipant?.email}
              defaultPhone={selectedParticipant?.phone}
              defaultRole={defaultRole}
              isEdit={isEditing}
              disableRoleSelect={disableRoleSelect}
              participants={participants}
              orgUsers={users}
              canAddHost={enabledAddHost}
              spaceId={props.spaceId}
              showMessagePartner={hasPartnerRole}
            />
          </Card>
        </CardContent>
      );
    }

    function renderDefaultHeaderTitle() {
      return (
        <>
          <span className="side-panel-title-bold">{props.title || i18n.t('Users')}</span>
          {!props.isTemplate && (
            <span className="side-panel-title-info">{`${numOnlineUsers} / ${
              participants?.length
            } ${i18n.t('Online')}`}</span>
          )}
        </>
      );
    }

    async function handleOnClickBack() {
      const isCloseForm = await participantFormRef.current.handleCloseForm();
      if (isCloseForm) {
        setShowForm(false);
      }
    }

    function renderFormHeader() {
      return (
        <div className="form-header-title" onClick={handleOnClickBack}>
          <ArrowBackIosIcon />
          <span className="side-panel-title-bold">
            {i18n.t(isEditing ? 'Edit user' : 'New user')}
          </span>
        </div>
      );
    }

    const handleOnChannelLoaded = (channel) => {
      setActiveChannel(channel);
    };

    const handleCloseSideBar = async () => {
      let isCloseForm = true;

      if (showForm) {
        isCloseForm = await participantFormRef.current.handleCloseForm();
      } else if (chatParticipants.length > 0) {
        isCloseForm = await chatFormRef.current.handleCloseForm();
      }

      if (isCloseForm) {
        props.onClose();
      }
    };

    async function handleOnLeaveChat() {
      const result = await chatFormRef.current?.handleCloseForm();
      return result;
    }

    React.useImperativeHandle(ref, () => ({
      handleCloseForm: handleOnLeaveChat,
    }));

    async function handleOnChangeTab(value) {
      changeTab(value);
      setCurrentTabInParticipant(value);
    }

    function getNotificationForTabs(data) {
      let newTabs = [...data];
      const total = newNotifications?.Participants?.details?.requestSpaceAccessIds?.length;
      newTabs = newTabs.map((item) => {
        return {
          ...item,
          total: item.label === 'Requests' ? total : 0,
        };
      });
      return newTabs;
    }

    function renderParticipantTabs() {
      return (
        <>
          <Tabs
            tabs={getNotificationForTabs(tabs)}
            onChange={handleOnChangeTab}
            variant="tabs-level-2"
          />
          {activeTab === 0 && renderParticipantList()}
          {activeTab === 1 && (
            <RequestAccessList
              spaceId={props.spaceId}
              disabledActions={props.isEdit}
              canSearch={!props.participantWidget && !props.isMobile}
              newNotifications={newNotifications}
              setNotifications={setNotifications}
            />
          )}
        </>
      );
    }

    function renderHeader() {
      const { isTemplate, isModerator } = props;
      const showActionHeader =
        !spaceUser.isViewOnly() &&
        isModerator &&
        !isTemplate &&
        !isLoading &&
        (!showForm || props.participantWidget);
      return (
        <div className="side-panel-title side-panel-participant">
          <div className="side-panel-header-left">
            {showForm && !props.participantWidget ? renderFormHeader() : renderDefaultHeaderTitle()}
          </div>
          <div className="side-panel-header-right">
            {showActionHeader && (
              <>
                {selectedParticipants.length === 0 && (
                  <LightTooltip
                    title="Go back to the space to add users."
                    disableHoverListener={!props.isEdit}
                  >
                    <div>
                      <OfficialButton
                        label={i18n.t('Add')}
                        disabled={props.isEdit}
                        onClick={handleOnClickAdd}
                        variant="rectangle-green"
                        icon={<AddIcon />}
                      />
                    </div>
                  </LightTooltip>
                )}
                {selectedParticipants.length > 0 && (
                  <OfficialButton
                    label={i18n.t('Clear')}
                    onClick={handleOnClickCancelSelectMode}
                    variant="rectangle-grey"
                  />
                )}
              </>
            )}
            {!props.participantWidget && (
              <IconButton
                aria-label="close"
                size="small"
                color="secondary"
                className="close-icon"
                onClick={handleCloseSideBar}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
      );
    }

    function renderBody() {
      if (props.isTemplate) {
        return (
          <NoDaTaBox
            icon={<i className="icon-participants" />}
            title={
              <>
                {i18n.t('In spaces created from this template')}
                <br />
                {i18n.t('there will be a user list in this view.')}
              </>
            }
          />
        );
      }
      if (isLoading) {
        return (
          <div className="loading-container">
            <LoadingMessage color="#fff">{i18n.t('Loading users...')}</LoadingMessage>
          </div>
        );
      }

      if (showForm && !props.participantWidget) {
        return renderParticipantForm();
      }

      if (chatParticipants.length > 0) {
        const channelInfo = {
          icon: `${chatWith.firstName ? chatWith.firstName[0] : ''}${
            chatWith.lastName ? chatWith.lastName[0] : ''
          }`,
          name: chatWith.fullName,
        };
        return (
          <Chat
            ref={chatFormRef}
            spaceId={props.spaceId}
            participants={chatParticipants}
            participantId={props.userInfoId}
            mainChannelId={props.mainChannelId}
            onClose={handleOnClickLeaveChannel}
            channelInfo={channelInfo}
            onLoaded={handleOnChannelLoaded}
          />
        );
      }
      if (props.isShowRequestUsersAccess) {
        return renderParticipantTabs();
      }
      return renderParticipantList();
    }

    const handOnCloseAddPartnerUserDialog = () => {
      setNewAddedUsers([]);
    };

    const renderAddPartnerUsersDialog = () => {
      return (
        <AddPartnerUsersDialog users={newAddedUsers} onClose={handOnCloseAddPartnerUserDialog} />
      );
    };

    return (
      <div className="participant-list-container">
        {showHeader && showHeaderPrivateChat && renderHeader()}
        {renderBody()}
        {newAddedUsers.length > 0 && renderAddPartnerUsersDialog()}
      </div>
    );
  })
);

ParticipantList.propTypes = {
  userInfoId: PropTypes.number,
  spaceId: PropTypes.string,
  mainChannelId: PropTypes.number,
  isModerator: PropTypes.bool,
  showHeader: PropTypes.bool,
  onOpenChat: PropTypes.func,
  isTemplate: PropTypes.bool,
  isOpenAddParticipantForm: PropTypes.bool,
  onClose: PropTypes.func,
  isShowRequestUsersAccess: PropTypes.bool,
  setSelectedChannel: PropTypes.func,
  isHost: PropTypes.bool,
  participantWidget: PropTypes.bool,
  isEdit: PropTypes.bool,
  title: PropTypes.string,
  isMobile: PropTypes.bool,
};

ParticipantList.defaultProps = {
  showHeader: true,
  isOpenAddParticipantForm: false,
  isShowRequestUsersAccess: false,
  isEdit: false,
  participantWidget: false,
};

ParticipantList.displayName = 'ParticipantList';

export default ParticipantList;
