import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceResourceDetailsById } from '../services/SpaceAnalyticsService';

export type InteractionResource = {
  clicks: number;
  shares: number;
  downloads: number;
  viewTimeInSeconds: number;
  userInfoId: number;
  pageRead: number;
  totalPages: number;
  lastClick: string;
  lastShare: string;
  lastDownload: string;
};

const useSpaceResourcesByIdQuery = (
  spaceId: string | undefined,
  materialId: number,
  startDate?: string,
  endDate?: string,
  isPublic?: boolean,
  excludedUserInfoIds?: number[],
  userInfoIds?: number[],
  enabled?: boolean
) => {
  const getSpaceResourcesByIdQuery = useQuery({
    queryKey: [
      CacheKeys.spaceAnalytics_FetchSpaceResourcesSummaryById,
      spaceId,
      materialId,
      endDate,
      excludedUserInfoIds,
      userInfoIds,
    ],
    queryFn: async () => {
      if (!spaceId) {
        return [];
      }
      const resp = await fetchSpaceResourceDetailsById(
        spaceId,
        materialId,
        startDate,
        endDate,
        isPublic,
        excludedUserInfoIds,
        userInfoIds
      );
      return resp;
    },

    retry: 3,
    retryDelay: () => 5000,
    enabled: enabled,
  });

  const isFetching: boolean = getSpaceResourcesByIdQuery.isFetching;
  const resourceInteractions: InteractionResource[] = getSpaceResourcesByIdQuery?.data?.value?.data;
  return { resourceInteractions, isFetching };
};

export default useSpaceResourcesByIdQuery;
