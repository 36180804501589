export function findNewLinks(links, currentLinks) {
  const newLinks = [];
  links.forEach((link) => {
    const found = currentLinks.find(
      (currentLink) => currentLink.url === link.url && currentLink.text === link.text
    );
    if (!found) {
      newLinks.push(link);
    }
  });
  return newLinks;
}

export function findDeletedLinks(links, currentLinks) {
  const deletedLinks = [];
  currentLinks.forEach((currentLink) => {
    const found = links.find(
      (link) => currentLink.url === link.url && currentLink.text === link.text
    );
    if (!found) {
      deletedLinks.push(currentLink);
    }
  });
  return deletedLinks;
}
