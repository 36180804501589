import React from 'react';
import { Button } from '@mui/material';
import currentOrganization from '../../../commons/CurrentOrganization';
import './style.scss';
import { ConnectButtonProps } from './types';

const ConnectButton = (props: ConnectButtonProps) => {
  const { serviceProvider, isProcessing, onClickConnect, onClickDisconnect } = props;
  const label = serviceProvider?.isActive ? 'connected' : 'connect';

  let disabled = serviceProvider?.disabled || serviceProvider?.comingSoon;
  if (serviceProvider?.allowBetaTesting && currentOrganization.isBetaTesting()) {
    disabled = false;
  }
  const handleOnClick = async () => {
    if (!serviceProvider) {
      return;
    }
    if (serviceProvider.isActive && onClickDisconnect) {
      await onClickDisconnect();
    }
    if (!serviceProvider.isActive && onClickConnect) {
      await onClickConnect();
    }
  };

  return (
    <Button className={`connect-button ${label} btn`} onClick={handleOnClick} disabled={disabled}>
      {label}
      {isProcessing && (
        <i className="icon-spinner" style={{ fontSize: 9, color: '#fff', marginLeft: 5 }} />
      )}
    </Button>
  );
};

export default ConnectButton;
