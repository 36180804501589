import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LockIcon from '@mui/icons-material/Lock';
import currentOrganization from '../commons/CurrentOrganization';
import i18n from '../i18n';
import './SpaceActionMenu.scss';
import { Ids } from '../commons/pendoTaggings';
import LightTooltip from '../components/LightTooltip';
import { useSpaceContext } from '../spaces/SpaceContext';
import { useChatContext } from '../spaces/Comments/ChatContextProvider';

function SpaceRuntimeActionMenu(props) {
  const {
    anchorEl,
    onClose,
    onClickEdit,
    onClickGoToDesign,
    onClickVaam,
    onClickSpaceAnalytics,
    space,
    className,
    shouldVaamButtonDisabled,
  } = props;

  const { setShowVaamRecorder, setVaamTargetChannel, setIsVaamFromChat } = useSpaceContext();
  const { chatPublicChannel } = useChatContext();

  function handleOnClick() {
    if (shouldVaamButtonDisabled) {
      return;
    }
    setShowVaamRecorder(true);
    setVaamTargetChannel(chatPublicChannel);
    setIsVaamFromChat(false);
    if (onClickVaam) {
      onClickVaam();
    }
  }

  return (
    <Menu
      id="space-action-list"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      className={`space-action-list ${className}`}
    >
      {!!onClickEdit && (
        <MenuItem onClick={onClickEdit} data-id={Ids.editSpace}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {space.isTemplate
              ? i18n.t('Edit template information')
              : i18n.t('Edit space information')}
          </ListItemText>
        </MenuItem>
      )}
      {!!onClickGoToDesign && (
        <MenuItem onClick={onClickGoToDesign} data-id={Ids.EditDesignSpaceButtonIcon}>
          <ListItemIcon>
            <i className="icon icon-design-1" />
          </ListItemIcon>
          {i18n.t('Design')}
        </MenuItem>
      )}
      {!!onClickVaam && (
        <LightTooltip
          title={i18n.t(
            shouldVaamButtonDisabled
              ? 'Video recording functionality is currently only available in Chrome and Edge browsers'
              : 'Record and share video messages to your users.'
          )}
        >
          <div>
            <MenuItem
              data-id={Ids.vaamIconButton}
              onClick={handleOnClick}
              disabled={shouldVaamButtonDisabled}
            >
              <ListItemIcon>
                <i className="icon icon-video-message" />
              </ListItemIcon>
              {i18n.t('Vaam')}
            </MenuItem>
          </div>
        </LightTooltip>
      )}
      {!!onClickSpaceAnalytics && (
        <MenuItem onClick={onClickSpaceAnalytics} data-id={Ids.SpaceAnalyticsBackButton}>
          <ListItemIcon>
            <EqualizerIcon fontSize="small" />
          </ListItemIcon>

          {i18n.t('Space Analytics')}

          {currentOrganization.isPremium() && <LockIcon className="lockicon" />}
        </MenuItem>
      )}
    </Menu>
  );
}

SpaceRuntimeActionMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  space: PropTypes.instanceOf(Object),
  onClickEdit: PropTypes.func,
  onClickGoToDesign: PropTypes.func,
  onClickVaam: PropTypes.func,
  onClickSpaceAnalytics: PropTypes.func,
  className: PropTypes.string,
  shouldVaamButtonDisabled: PropTypes.bool,
};

export default SpaceRuntimeActionMenu;
