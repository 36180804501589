import React from 'react';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import './style.scss';
import VideoMessageById from './VideoMessagesById';

type SpaceVideoMessageModalProps = {
  open: boolean;
  messageId: number;
  title: string;
  onClose: () => void;
};

function SpaceVideoMessageModal(props: SpaceVideoMessageModalProps) {
  const { open, onClose, messageId, title } = props;
  console.log('open: ', open);

  function renderActiveTab() {
    return <VideoMessageById messageId={messageId} title={title} />;
  }

  if (!open) {
    return null;
  }

  return (
    <ResponsiveDialog
      modal
      maxWidth="lg"
      className="white confirm-dialog space-users-details-modal"
      onClose={onClose}
    >
      <div className="tab-content">{renderActiveTab()}</div>
    </ResponsiveDialog>
  );
}

export default React.memo(SpaceVideoMessageModal);
