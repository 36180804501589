/* eslint-disable */
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import _findIndex from 'lodash/findIndex';
import { CMS_COMPONENT_TYPE } from '../../cms/cmsConstants';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import { CacheKeys } from '../../app/queryCache';
import { IPage, ISpaceCustomTheme } from '../../types/types';

export type CmsMaterial = {
  resourceId: number;
  materialId: number;
  tag: string;
  resourceName: string;
  useCmsName?: boolean;
};

const excludeFromCms: string[] = [
  CMS_COMPONENT_TYPE.WEB_CONTENT,
  CMS_COMPONENT_TYPE.HTML_CONTENT,
  CMS_COMPONENT_TYPE.SCRIPT,
  CMS_COMPONENT_TYPE.WIDGET,
];

const convertToCmsMaterials = (customThemeItems: any) =>
  customThemeItems
    .reduce((prev: any, curr: any) => [...prev, ...curr], [])
    .map((x: { type: any }) => ({ ...x, type: x.type || '' }))
    .filter((x: { type: string }) => !excludeFromCms.includes(x.type))
    .map(
      (x: {
        videoUrl: any;
        videoUrlResourceId: any;
        videoUrlMaterialId: any;
        backgroundImageUrl: any;
        backgroundImageUrlResourceId: any;
        backgroundImageUrlMaterialId: any;
        headingLinkResourceId: any;
        headingLinkMaterialId: any;
        heading: any;
        buttonLinkResourceId: any;
        buttonLinkMaterialId: any;
        buttonLabel: any;
        secondaryButtonLinkResourceId: any;
        secondaryButtonLinkMaterialId: any;
        secondaryButtonLabel: any;
      }) => {
        const resources: CmsMaterial[] = [];
        if (x.videoUrl && x.videoUrlResourceId && x.videoUrlMaterialId) {
          resources.push({
            resourceId: x.videoUrlResourceId,
            materialId: x.videoUrlMaterialId,
            resourceName: '',
            tag: 'video',
          });
        }
        if (
          typeof x.backgroundImageUrl === 'number' &&
          x.backgroundImageUrlResourceId &&
          x.backgroundImageUrlMaterialId
        ) {
          resources.push({
            resourceId: x.backgroundImageUrlResourceId,
            materialId: x.backgroundImageUrlMaterialId,
            resourceName: '',
            tag: 'file',
          });
        }
        if (x.headingLinkResourceId && x.headingLinkMaterialId) {
          resources.push({
            resourceId: x.headingLinkResourceId,
            materialId: x.headingLinkMaterialId,
            resourceName: x.heading || 'Untitled',
            tag: 'heading',
            useCmsName: true,
          });
        }
        if (x.buttonLinkResourceId && x.buttonLinkMaterialId) {
          resources.push({
            resourceId: x.buttonLinkResourceId,
            materialId: x.buttonLinkMaterialId,
            resourceName: x.buttonLabel || 'Get Started',
            tag: 'primary button',
            useCmsName: true,
          });
        }
        if (x.secondaryButtonLinkResourceId && x.secondaryButtonLinkMaterialId) {
          resources.push({
            resourceId: x.secondaryButtonLinkResourceId,
            materialId: x.secondaryButtonLinkMaterialId,
            resourceName: x.secondaryButtonLabel || 'Get Started',
            tag: 'secondary button',
            useCmsName: true,
          });
        }

        return resources;
      }
    )
    .reduce((prev: any, curr: any) => [...prev, ...curr], []);

const getCmsMaterialsFromCustomTheme = (customTheme: any) => {
  var customThemeItems = customTheme?.items.map((x: { items: { items: any }[] }) =>
    x.items
      .map((y: { items: any }) => y.items || [y])
      .reduce((prev: any, curr: any) => [...prev, ...curr], [])
  );
  return convertToCmsMaterials(customThemeItems);
};

const getCmsMaterialsFromPagesBlocks = (pagesBlocks: any) => {
  var allMaterials: any[] = [];
  pagesBlocks.pages.forEach((page: IPage) => {
    var customThemeItems = page?.blocks.map((x: ISpaceCustomTheme) =>
      x.items
        .map((y: { items: any }) => y.items || [y])
        .reduce((prev: any, curr: any) => [...prev, ...curr], [])
    );
    var customThemeMaterials = convertToCmsMaterials(customThemeItems);
    for (var i = 0; i < customThemeMaterials.length; i++) {
      if (
        _findIndex(allMaterials, (x) => x.materialId === customThemeMaterials[i].materialId) === -1
      ) {
        allMaterials.push(customThemeMaterials[i]);
      }
    }
  });
  return allMaterials;
};

const useCmsMaterialsQuery = () => {
  const { space, reportEndDate } = useSpaceAnalyticsContext();
  const endDate = moment(reportEndDate).toDate().toISOString();
  const populateCmsResources = () => {
    const allCmsResources = getCmsMaterialsFromCustomTheme(space?.customTheme);
    if (space?.pagesBlock) {
      const allCmsResourcesFromPagesBlocks = getCmsMaterialsFromPagesBlocks(space?.pagesBlock);
      for (var i = 0; i < allCmsResourcesFromPagesBlocks.length; i++) {
        if (
          _findIndex(
            allCmsResources,
            (x: any) => x.materialId === allCmsResourcesFromPagesBlocks[i].materialId
          ) === -1
        ) {
          allCmsResources.push(allCmsResourcesFromPagesBlocks[i]);
        }
      }
    }
    console.log('____allCmsResources', allCmsResources);
    return allCmsResources;
  };

  return useQuery({
    queryFn: () => populateCmsResources(),
    queryKey: [CacheKeys.spaceAnalytics_fetchAllCmsResources, space?.id, endDate],
  });
};

export default useCmsMaterialsQuery;
