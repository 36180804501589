/* eslint-disable no-param-reassign */

export function getResourceIdsInComponent(component) {
  const resourceIds = [];
  if (!component) return resourceIds;
  if (component.backgroundImageUrlCropped > 0)
    resourceIds.push(component.backgroundImageUrlCropped);
  if (component.backgroundImageUrl > 0) resourceIds.push(component.backgroundImageUrl);
  if (component.videoUrl > 0) resourceIds.push(component.videoUrl);
  return resourceIds;
}

export function getResourceIdsInList(items) {
  if (!items?.length) return [];
  let resourceIds = [];
  items.forEach((item) => {
    const ids = getResourceIdsInComponent(item);
    resourceIds = resourceIds.concat(ids);
  });
  return resourceIds;
}

export function getResourceIdsInBlock(block) {
  let resourceIds = [];
  if (!block) return resourceIds;

  // for block background
  const resourceId = block.backgroundImageUrlCropped || block.backgroundImageUrl;
  if (resourceId > 0) resourceIds.push(resourceId);

  const columns = block.items || [];
  let ids = getResourceIdsInList(columns);
  resourceIds = resourceIds.concat(ids);

  // for carousels
  columns.forEach((column) => {
    if (column?.items?.length > 0) {
      ids = getResourceIdsInList(column.items);
      resourceIds = resourceIds.concat(ids);
    }
  });

  resourceIds = [...new Set(resourceIds)]; // distinct
  return resourceIds;
}

export function getResourceIds(blocks) {
  let resourceIds = [];
  if (!blocks?.length) return resourceIds;
  blocks.forEach((block) => {
    const ids = getResourceIdsInBlock(block);
    resourceIds = resourceIds.concat(ids);
  });

  resourceIds = [...new Set(resourceIds)]; // distinct
  return resourceIds;
}

export function getBlockResourcesMap(block, resourcesMap) {
  const blockResourcesMap = {};
  if (!block || !resourcesMap) return blockResourcesMap;
  const ids = getResourceIdsInBlock(block);
  ids.forEach((id) => {
    if (id > 0 && resourcesMap[id]) {
      blockResourcesMap[id] = resourcesMap[id];
    }
  });
  return blockResourcesMap;
}

export function getComponentResourcesMap(component, resourcesMap) {
  const componentResourcesMap = {};
  if (!component || !resourcesMap) return componentResourcesMap;
  const ids = getResourceIdsInComponent(component);
  ids.forEach((id) => {
    if (id > 0 && resourcesMap[id]) {
      componentResourcesMap[id] = resourcesMap[id];
    }
  });
  return componentResourcesMap;
}

export function shouldDoAction(oldArr, newArr) {
  // expectation:
  // oldArr & newArr are arrays
  // each element in input array is unique.
  const hasAnyDiff = newArr.some((x) => !oldArr?.includes(x));
  if (hasAnyDiff) return true;
  return false;
}

function deleteResourcesProperty(blocks) {
  blocks.forEach((block) => {
    try {
      delete block.resources;
      if (block.items) {
        block.items.forEach((item) => {
          delete item.resources;
          if (item.items) {
            item.items.forEach((subItem) => {
              delete subItem.resources;
            });
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  });
}

/**
 * Delete resources property in theme object (warning: this function will modify the input object)
 * @param {object} theme
 */
export function deleteResourcesPropertyInTheme(theme) {
  if (!theme) return;
  if (theme.items && theme.items.length > 0) {
    deleteResourcesProperty(theme.items);
  }
}
