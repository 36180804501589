import React from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { StyledEngineProvider } from '@mui/system';
// import { createGenerateClassName, StylesProvider } from '@mui/system';
import queryCache from './app/queryCache';
import AppRouter from './app/appRouter';
import './responsiveUtils.scss';
import './app.scss';
import './styles/newPopup.scss';

import ErrorBoundary from './components/ErrorBoundary';
import { reactPlugin } from './app/AppInsights';

// const generateClassName = createGenerateClassName({
//   productionPrefix: 'spce',
// });

function App() {
  return (
    // <StylesProvider generateClassName={generateClassName}>
    <StyledEngineProvider injectFirst>
      <ErrorBoundary ctx="App">
        <QueryClientProvider client={queryCache}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <AppRouter />
          </AppInsightsContext.Provider>
        </QueryClientProvider>
      </ErrorBoundary>
    </StyledEngineProvider>
    // </StylesProvider>
  );
}

export default App;
