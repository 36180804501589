import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import { getGlobalResourcesMngt, getRuntimeGlobalResources } from './globalResourcesService';
import { SelectedFolderProps } from './context/Interfaces';
import { useBreadcrumbContext } from './context/BreadcrumbsContextProvider';

export default function useGlobalResourcesQuery(
  selectedFolder: SelectedFolderProps | null,
  page: number,
  rowsPerPage: number,
  keyword: string,
  sortBy: string,
  cacheKey: string | null | undefined,
  enableGlobalResources = false,
  isPortal = false
) {
  const { updateBreadcrumbItem } = useBreadcrumbContext();
  const mCacheKey = cacheKey || CacheKeys.getGlobalResourcesMngt;
  const directoryId = !selectedFolder || selectedFolder.name === '_root' ? null : selectedFolder.id;

  const fetchData = async () => {
    const offset = page * rowsPerPage;

    let response = null;
    if (isPortal) {
      response = await getGlobalResourcesMngt(directoryId, rowsPerPage, offset, keyword, sortBy);
    } else {
      response = await getRuntimeGlobalResources(directoryId, rowsPerPage, offset, keyword, sortBy);
    }
    return response;
  };

  const globalResourcesQuery = useQuery({
    queryKey: [mCacheKey, directoryId, page, rowsPerPage, keyword, sortBy, isPortal],
    queryFn: fetchData,
    enabled: enableGlobalResources,
    // Todo: Uncomment this block to handle error
    // throwOnError: (error) => {
    //   console.error(error);
    //   sessionStorage.removeItem(getStorageCacheKey(GLOBAL_PROVIDER_TYPE.SPCE));
    // },
  });

  useEffect(() => {
    if (!globalResourcesQuery.data?.id) {
      return;
    }
    const currentFolder = {
      id: globalResourcesQuery.data?.id,
      name: globalResourcesQuery.data?.name,
      parentId: globalResourcesQuery.data?.parentId,
    };

    updateBreadcrumbItem(currentFolder);
  }, [globalResourcesQuery.data, updateBreadcrumbItem]);

  const data = globalResourcesQuery.data?.children;

  return {
    data,
    isLoading: globalResourcesQuery.isLoading,
    refetch: globalResourcesQuery.refetch,
    isError: globalResourcesQuery.isError,
  };
}
