import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import i18n from '../../i18n';
import Loading from '../Loading';
import './style.scss';
import { ResourceType } from '../../app/appConstants';
import { getPlayer } from '../../commons/ResourceUtils';
import { CacheKeys } from '../../app/queryCache';
import { getResourceThumbnailUrl } from '../../resources/ResourceServices';
import { isThumbnailSupported } from './utils';
import { getUrlParameters, sleep } from '../../commons/utils';
import ImageViewer from '../ImageViewer';
import VimeoThumbnail from '../VimeoThumbnail';
import AudioViewer from '../AudioViewer';
import ThumbnailImg from './ThumbnailImg';
import useCadTokenQuery from '../../resources/useCadTokenQuery';
import ResourceIcon from '../Material/ResourceIcon';
import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';

function ResourceThumbnail(props) {
  const {
    resourceId,
    resourceType,
    thumbnail,
    extension,
    isPortal,
    isExternal,
    src,
    inView,
    provider,
    resourceName,
  } = props;
  const isCadFile = resourceType === ResourceType.cad;
  const cadTokenQuery = useCadTokenQuery(isCadFile);

  const isSupported = isThumbnailSupported(resourceType);
  const isEnabled = inView && resourceId > 0 && !isExternal && isSupported && !thumbnail;

  const getResourceThumbnailQuery = useQuery({
    queryKey: [CacheKeys.getResourceThumbnail, resourceId],
    queryFn: async () => {
      await sleep(3);
      const resp = await getResourceThumbnailUrl(resourceId, isPortal);
      if (!resp?.src) {
        throw Error('Could not fetch resource thumbnail src');
      }
      return resp;
    },
    retry: 3,
    retryDelay: 1000,
    enabled: isEnabled,
  });

  const isExternalDrive =
    provider === SERVICE_PROVIDER.ONEDRIVE || provider === SERVICE_PROVIDER.GOOGLE_DRIVE;

  const isLoading = getResourceThumbnailQuery.isFetching || cadTokenQuery.isLoading;

  const previewUnavailable = () => {
    return (
      <section className="resource-thumbnail">
        <ResourceIcon
          type={resourceType}
          name={resourceName}
          extension={extension}
          className="item-icon"
        />
      </section>
    );
  };

  if (isLoading) {
    return (
      <div className="resource-thumbnail">
        <Loading className="loading" message={i18n.t('Getting thumbnail image...')} />
      </div>
    );
  }

  const resourceToken = isCadFile ? cadTokenQuery.data?.token : null;
  let resourceThumbnail;
  if (isExternalDrive) {
    resourceThumbnail = thumbnail;
  } else {
    resourceThumbnail = thumbnail?.length > 0 ? thumbnail : getResourceThumbnailQuery.data?.src;
  }

  if (resourceThumbnail === '__unavailable__') {
    return previewUnavailable();
  }

  const renderThumbnail = () => {
    if (isExternalDrive) {
      if (!resourceThumbnail) {
        return previewUnavailable();
      }

      return (
        <ThumbnailImg
          resourceId={resourceId}
          src={resourceThumbnail}
          resourceType={resourceType}
          extension={extension}
        />
      );
    }

    switch (resourceType) {
      case ResourceType.image: {
        const finalThumbnail = isExternal ? decodeURIComponent(src) : resourceThumbnail;
        return (
          <ThumbnailImg
            src={finalThumbnail}
            token={resourceToken}
            resourceType={resourceType}
            extension={extension}
          />
        );
      }
      case ResourceType.pdf:
      case ResourceType.powerpoint:
      case ResourceType.excel:
      case ResourceType.word:
      case ResourceType.keynote:
      case ResourceType.cad:
        return (
          <ThumbnailImg
            src={resourceThumbnail}
            token={resourceToken}
            resourceType={resourceType}
            extension={extension}
          />
        );

      case ResourceType.audio:
        return <AudioViewer src={src} />;
      case ResourceType.video: {
        const player = getPlayer(src);
        if (player.type === 'youtube') {
          const params = getUrlParameters(src);
          const youtubeId = params?.v;
          if (youtubeId) {
            const thumbnailUrl = `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`; // or 0.jpg
            return <ImageViewer src={thumbnailUrl} className="resource-thumbnail" />;
          }
        } else if (player.type === 'vimeo') {
          return <VimeoThumbnail videoUrl={src} className="resource-thumbnail" />;
        }
        return (
          <ThumbnailImg
            src={resourceThumbnail}
            token={resourceToken}
            resourceType={resourceType}
            extension={extension}
          />
        );
      }
      default:
        return previewUnavailable;
    }
  };

  return renderThumbnail();
}

ResourceThumbnail.propTypes = {
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  thumbnail: PropTypes.string,
  resourceType: PropTypes.number,
  extension: PropTypes.string,
  isPortal: PropTypes.bool,
  isExternal: PropTypes.bool,
  src: PropTypes.string,
  inView: PropTypes.bool,
  provider: PropTypes.string,
  resourceName: PropTypes.string,
};

ResourceThumbnail.defaultProps = {
  inView: false,
  isExternal: false,
};

const ResourceThumbnailWrapper = (props) => {
  const { thumbnail, resourceType, extension, inView, isExternal, resourceName } = props;
  const hasThumbnail = isThumbnailSupported(resourceType);
  const finalResourceType =
    isExternal && resourceType === ResourceType.video ? ResourceType.podcast : resourceType;

  if (
    !inView ||
    !hasThumbnail ||
    // resourceType === ResourceType.signingDocument ||
    // resourceType === ResourceType.zip ||
    // resourceType === ResourceType.font ||
    thumbnail === '__unavailable__'
  ) {
    return (
      <section className="resource-thumbnail">
        <ResourceIcon
          type={finalResourceType}
          name={resourceName}
          extension={extension}
          className="item-icon"
        />
      </section>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ResourceThumbnail {...props} />;
};

ResourceThumbnailWrapper.propTypes = ResourceThumbnail.propTypes;
ResourceThumbnailWrapper.defaultProps = ResourceThumbnail.defaultProps;
ResourceThumbnailWrapper.displayName = 'ResourceThumbnail';

export default ResourceThumbnailWrapper;
