import React, { useMemo, useCallback } from 'react';
import { Box } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { CacheKeys } from '../../app/queryCache';
import SpaceAnalyticsInfoBox from '../SpaceAnalyticsInfoBox';
import DataSummaryBox from '../DataSummaryBox';
import { fetchUserTraffic } from '../services/SpaceAnalyticsService';
import useSpaceAnalyticsQuery from '../hooks/useSpaceAnalyticsQuery';
import Loading from '../../components/Loading';
import Chart, { ChartDefinition } from '../components/Chart';
import { SpaceTrafficSummary } from '../types/userTraffic';
import ParticipantTrafficDetailsTable from './ParticipantTrafficDetailsTable';
import DownloadTrafficSummaryButton from './DownloadButton';
import './style.scss';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import ShareSpaceButton from './ShareSpaceButton';
import { Ids } from '../../commons/pendoTaggings';
import LightTooltip from '../../components/LightTooltip';
import { PrivacySpace } from '../../app/appConstants';

const generateSummary = (metrics?: SpaceTrafficSummary, isPublic?: boolean) => {
  const dataSummaryList = [
    {
      id: 'participants',
      label: isPublic ? 'Unique Visitors' : 'Users',
      value: metrics?.numberOfParticipants || 0,
      toolTip: isPublic
        ? 'Total number unique visitors in the space.'
        : 'Total number of users in the space.',
    },
    {
      id: 'visits',
      label: 'Visits ',
      value: metrics?.numberOfParticipantsVisitedSpace || 0,
      toolTip: 'Total number of visits in the space.',
    },
    {
      id: 'timeSpent',
      label: 'Time spent',
      value: metrics?.timeSpentInSeconds || 0,
    },
  ];

  return dataSummaryList;
};

type ParticipantTrafficTableProps = {
  trafficSummary?: SpaceTrafficSummary;
};

function ParticipantTrafficTable(props: ParticipantTrafficTableProps) {
  const { trafficSummary } = props;
  const { space, isPublic, visibleUsers, excludeUserIds } = useSpaceAnalyticsContext();
  const spaceId = space?.id;

  const { data, isLoading } = useSpaceAnalyticsQuery({
    fetcher: fetchUserTraffic,
    queryKey: CacheKeys.spaceAnalytics_fetchUserTraffic,
    params: {
      excludedUserInfoIds: excludeUserIds,
      isPublic,
      userInfoIds: visibleUsers?.map((x) => x.userInfoId),
    },
    select: (resp) => resp.value,
    enabled: !trafficSummary && !!spaceId && !!visibleUsers && visibleUsers.length > 0,
  });

  const trafficData = trafficSummary || data;
  if (trafficData) {
    trafficData.numberOfParticipants = visibleUsers.length;
  }

  const defineAxis = useCallback(() => {
    if (!trafficData?.data) {
      return { left: undefined, right: undefined };
    }
    const flatData = trafficData?.data?.map((x) => x.data);
    const maxDuration = Math.max(...flatData.map((x) => x.timeSpentInSeconds));
    const maxCount = Math.max(...flatData.map((x) => Math.max(x.visits)));
    return {
      left: {
        maxValue: maxDuration,
        isDuration: true,
      },
      right: {
        maxValue: maxCount,
        isDuration: false,
      },
    };
  }, [trafficData?.data]);

  const chartData = useMemo(() => trafficData?.data || [], [trafficData?.data]);

  const chartDefinitions = useMemo<ChartDefinition[]>(
    () => [
      {
        type: 'bar',
        label: 'Time spent',
        data: chartData?.map((x) => x.data.timeSpentInSeconds),
        color: '#eed2f9',
        axisBinding: 'left',
        isDuration: true,
        order: 2,
      },
      {
        type: 'line',
        label: 'Visits',
        data: chartData?.map((x) => x.data.visits),
        color: '#ad2de1',
        axisBinding: 'right',
        order: 1,
      },
    ],
    [chartData]
  );

  const toolTipLabels = useMemo<string[]>(() => chartData?.map((x) => x.label) || [], [chartData]);

  const renderChart = () => {
    if (!chartData || chartData?.length === 0) {
      return null;
    }

    const axis = defineAxis();

    return (
      <Chart
        chartDefinitions={chartDefinitions}
        leftAxesDefinition={axis.left}
        rightAxesDefinition={axis.right}
        tooltipLabels={toolTipLabels}
        chartLabels={trafficData?.labels || []}
      />
    );
  };

  function renderContent() {
    if (isLoading) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }

    return (
      <>
        <DataSummaryBox
          dataSummaryList={generateSummary(
            trafficData,
            space?.spacePrivacy === PrivacySpace.PUBLIC
          )}
          skipSeconds
        />
        <Box className="chart-area">{renderChart()}</Box>
        {!!trafficData?.userTrafficDetails && (
          <ParticipantTrafficDetailsTable data={trafficData?.userTrafficDetails} />
        )}
      </>
    );
  }

  const title = space?.spacePrivacy === PrivacySpace.PUBLIC ? 'Visitor Traffic' : 'User Traffic';

  return (
    <Box className="widget-box participant-traffic">
      <SpaceAnalyticsInfoBox
        icon={<GroupIcon className="icon-group" />}
        title={title}
        description="See how often users visited the space."
        className="participant"
        downloadButton={
          isLoading ? undefined : (
            <DownloadTrafficSummaryButton dataId={Ids.participantTrafficTableDownloadTraffic} />
          )
        }
        shareSpaceButton={
          isLoading ? undefined : (
            <LightTooltip title="Increase user traffic by sharing your space.">
              <div>
                <ShareSpaceButton dataId={Ids.participantTrafficTableShareSpace} />
              </div>
            </LightTooltip>
          )
        }
      />
      {renderContent()}
    </Box>
  );
}

export default React.memo(ParticipantTrafficTable);
