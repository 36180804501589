import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceUsersBasicInfo } from '../services/SpaceAnalyticsService';
import { ISpaceUser } from '../../types/User';

const useSpaceUsersBasicInfo = (
  spaceId: string | undefined,
  endDate: string,
  isExcludeInternalUser: boolean | undefined = undefined,
  userInfoId: number | undefined = undefined,
  enabled = true
) => {
  const [visibleUsers, setVisibleUsers] = useState<ISpaceUser[]>([]);
  const [excludeUserIds, setExcludeUserIds] = useState<number[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(!!spaceId && enabled);

  const getSpaceUsersBasicInfo = useQuery({
    queryKey: [CacheKeys.fetchSpaceUsersBasicInfo, spaceId, userInfoId, endDate],
    queryFn: async () => {
      if (!spaceId) {
        return [];
      }
      const resp = await fetchSpaceUsersBasicInfo(spaceId, userInfoId);
      return resp;
    },

    retry: 3,
    retryDelay: () => 5000,
    enabled: !!spaceId && enabled,
  });

  const users: ISpaceUser[] = useMemo(
    () => getSpaceUsersBasicInfo.data?.value?.items || [],
    [getSpaceUsersBasicInfo.data?.value?.items]
  );

  const { isFetching: isQueryFetching, isLoading } = getSpaceUsersBasicInfo;

  useEffect(() => {
    if (!users?.length) {
      if (!isQueryFetching && !isLoading) {
        setIsFetching(false);
      }
      return;
    }

    let excludeUsers = users.filter((user: ISpaceUser) => user.isHidden);

    if (isExcludeInternalUser) {
      excludeUsers = users.filter((user: ISpaceUser) => user.isOrganizationUser);
    }

    const ids = excludeUsers?.map((user: ISpaceUser) => user.userInfoId);
    setExcludeUserIds(ids);
    setVisibleUsers(users.filter((user) => !ids.includes(user.userInfoId)));
    setIsFetching(false);
  }, [isExcludeInternalUser, users, isQueryFetching, isLoading]);

  return { users, isFetching, visibleUsers, excludeUserIds };
};

export default useSpaceUsersBasicInfo;
