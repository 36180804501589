import React from 'react';
import { Box } from '@mui/system';
import { Checkbox } from '@mui/material';
import './UserCheckBoxItem.scss';
import { UserCheckBoxItemProps } from './type';
import LightTooltip from '../LightTooltip';
import UserAvatar from '../UserAvatar';
import Tags from '../Tags';

function UserCheckBoxItem(props: UserCheckBoxItemProps) {
  const { user, tags, tooltip, disabled, selected, onChange } = props;
  console.log(user, selected, onChange);

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(user, event.target.checked);
  }

  return (
    <LightTooltip
      title={tooltip}
      placement="right"
      disableHoverListener={disabled}
      disableFocusListener={disabled}
    >
      <Box component="li" className={`user-checkbox-item ${disabled ? 'disabled' : ''}`}>
        <div className="avatar-block">
          <div className="avatar">
            <UserAvatar fullName={user.fullName} email={user.email} />
          </div>
        </div>
        <div className="user-info">
          <div className="user-full-name">
            {user.fullName}
            {tags && tags.length > 0 && <Tags items={tags} />}
          </div>
          <div className="user-email">{user.email}</div>
        </div>
        <div>
          <Checkbox
            color="primary"
            checked={selected}
            disabled={disabled}
            onChange={handleOnChange}
          />
        </div>
      </Box>
    </LightTooltip>
  );
}

export default UserCheckBoxItem;
