import React, { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { addDays, startOfDay } from 'date-fns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './DateFilterSelect.scss';
import { DateRangePicker, RangeKeyDict, Range } from 'react-date-range';
import { newDate } from '../../commons/DateTimeUtils';
import LightTooltip from '../../components/LightTooltip';
import { useFeatureFlagsContext } from '../../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../../app/appConstants';

const FilterValues: FilterValue[] = [
  { id: 9, value: 'Today' },
  { id: 10, value: 'Yesterday' },
  { id: 11, value: 'Last 7 days' },
  { id: 12, value: 'Last 30 days' },
  { id: 13, value: 'Last 90 days' },
  { id: 14, value: 'Last 180 days' },
];

export interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface FilterValue {
  id: number;
  value: string;
}

const calculateRange = (filterValue: string): Range => {
  let startDate;
  switch (filterValue) {
    case 'Today':
      startDate = newDate().utc().startOf('day');
      break;
    case 'Yesterday':
      startDate = newDate().add(-1, 'days').utc().startOf('day');
      break;
    case 'Last 7 days':
      startDate = newDate().add(-7, 'days').utc().startOf('day');
      break;
    case 'Last 30 days':
      startDate = newDate().add(-30, 'days').utc().startOf('day');
      break;
    case 'Last 90 days':
      startDate = newDate().add(-90, 'days').utc().startOf('day');
      break;
    case 'Last 180 days':
      startDate = newDate().add(-180, 'days').utc().startOf('day');
      break;
    default:
      startDate = newDate().add(-30, 'days').utc().startOf('day');
  }
  return { startDate: startDate.toDate(), endDate: new Date() };
};

const maxAllowedDate = addDays(new Date(), 180);

const disabledDates: Date[] = [];
const farFutureDate = addDays(new Date(), 365);
for (let d = new Date(maxAllowedDate); d <= farFutureDate; d = addDays(d, 1)) {
  disabledDates.push(new Date(d));
}

const customStaticRanges = FilterValues.map((filterValue) => ({
  label: filterValue.value,
  range: () => calculateRange(filterValue.value),
  isSelected: function (thisRange: Range) {
    const definedRange = this.range();

    if (
      definedRange.startDate &&
      definedRange.endDate &&
      thisRange.startDate &&
      thisRange.endDate
    ) {
      return (
        definedRange.startDate.getTime() === thisRange.startDate.getTime() &&
        definedRange.endDate.getTime() === thisRange.endDate.getTime()
      );
    }

    return false;
  },
}));

interface DateFilterSelectProps {
  onChange: (range: Range) => void;
  initialDateRange: DateRange[];
}

const DateFilterSelect: React.FC<
  DateFilterSelectProps & { filterLabel: string; setFilterLabel: (label: string) => void }
> = ({ onChange, initialDateRange, filterLabel, setFilterLabel }) => {
  const [dateRange, setDateRange] = useState(initialDateRange);
  console.log('initialDateRange', initialDateRange);
  const [currentFilterLabel, setCurrentFilterLabel] = useState(filterLabel);
  const [isPickerVisible, setPickerVisible] = useState(false);
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [DatePickerEnabled] = checkEnabledFeatures([FeatureFlagsType.DATE_PICKER]);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const pickerElement = document.querySelector('.date-range-picker');
      if (pickerElement && !pickerElement.contains(event.target as Node)) {
        setPickerVisible(false);
      }
    };

    if (isPickerVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPickerVisible]);

  const handleSelectRange = (range: RangeKeyDict) => {
    const newRange = range.selection as Range;
    if (newRange.startDate && newRange.endDate) {
      const newDateRange: DateRange = {
        startDate: newRange.startDate as Date,
        endDate: newRange.endDate as Date,
        key: 'selection',
      };
      setDateRange([newDateRange]);
      if (onChange) {
        onChange(newDateRange);
      }

      let selectedLabel = '';
      customStaticRanges.forEach((staticRange) => {
        if (staticRange.isSelected(newRange)) {
          selectedLabel = staticRange.label;
        }
      });

      setCurrentFilterLabel(selectedLabel);
      setFilterLabel(selectedLabel);
    }
  };

  const isStartDate = (day: Date) => {
    const startDate = startOfDay(dateRange[0]?.startDate);
    return startDate?.getTime() === startOfDay(day).getTime();
  };

  const isEndDate = (day: Date) => {
    const endDate = startOfDay(dateRange[0]?.endDate);
    return endDate?.getTime() === startOfDay(day).getTime();
  };

  const renderDayContent = (day: Date) => {
    const isDisabled = disabledDates.some(
      (disabledDay) =>
        disabledDay.getDate() === day.getDate() &&
        disabledDay.getMonth() === day.getMonth() &&
        disabledDay.getFullYear() === day.getFullYear()
    );

    let dayClassName = 'day-cell';
    if (isStartDate(day)) {
      dayClassName += ' start-date';
    } else if (isEndDate(day)) {
      dayClassName += ' end-date';
    } else if (isDisabled) {
      dayClassName += ' disabled-day-cell';
    }

    const dayContent = <span>{day.getDate()}</span>;

    if (isDisabled) {
      return (
        <LightTooltip
          title="You cannot select more than 180 days."
          disableHoverListener={!isDisabled}
        >
          <div className="disabled-day-cell">{dayContent}</div>
        </LightTooltip>
      );
    } else {
      return <div className={dayClassName}>{dayContent}</div>;
    }
  };

  const formatDateRange = () => {
    return (
      currentFilterLabel ||
      `${dateRange[0]?.startDate.toLocaleDateString()} - ${dateRange[0]?.endDate.toLocaleDateString()}`
    );
  };

  if (!DatePickerEnabled) {
    return null;
  }

  return (
    <div className="date-filter-select">
      <TextField
        variant="outlined"
        value={formatDateRange()}
        onClick={() => setPickerVisible(!isPickerVisible)}
        style={{ width: 258, height: 40 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <span className="icon-calendar"></span>
            </InputAdornment>
          ),
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              {isPickerVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </InputAdornment>
          ),
        }}
      />
      {isPickerVisible && (
        <DateRangePicker
          onChange={handleSelectRange}
          ranges={dateRange}
          disabledDates={disabledDates}
          staticRanges={[...customStaticRanges]}
          dayContentRenderer={renderDayContent}
          className="date-range-picker"
          months={2}
          direction="horizontal"
        />
      )}
    </div>
  );
};

export default DateFilterSelect;
