import React, { useCallback } from 'react';
import { Waypoint } from 'react-waypoint';

import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import AssessmentCardItem from './AssessmentCardItem';
import Loading from '../../components/Loading';
import i18n from '../../i18n';
import NoDaTaBox from '../../components/NoDataBox';

const ListCard = React.memo(
  ({ query, keywords, onClickMore, columns, onClick, selectedAssessments, listItems }) => {
    const fetchNextPage = query.fetchNextPage;

    const handleLoadMore = useCallback(() => {
      if (fetchNextPage) {
        fetchNextPage();
      }
    }, [fetchNextPage]);

    if (query?.isLoading) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }
    if (listItems?.length === 0) {
      if (keywords) {
        return (
          <NoDaTaBox
            icon={<i className="icon-search" />}
            title={i18n.t('No assessments found')}
            description={
              <>
                {i18n.t('There is no assessment available to display.')}
                <br />
                {i18n.t('Please choose another keyword and try again')}
              </>
            }
          />
        );
      } else {
        return (
          <NoDaTaBox
            icon={<i className="icon-users" />}
            title={i18n.t('No assessments to display')}
            description={i18n.t('Add an assessment to get started')}
          />
        );
      }
    }

    return (
      <section className="assessment-list-body styled-scrollbar">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={columns || { xs: 4, sm: 12, md: 12, xl: 10 }}
        >
          {listItems?.map((assessment) => {
            return (
              <AssessmentCardItem
                selected={selectedAssessments?.some((item) => item.id === assessment.id)}
                assessment={assessment}
                onClickMore={onClickMore}
                onClick={onClick}
              />
            );
          })}

          {query.isFetchingNextPage && (
            <div className="loading-more">
              <Loading />
              {i18n.t('Loading more')}...
            </div>
          )}
          <Waypoint
            onEnter={() => {
              if (!query?.isFetching && !!fetchNextPage) {
                handleLoadMore();
              }
            }}
          />
        </Grid>
      </section>
    );
  }
);

ListCard.propTypes = {
  query: PropTypes.instanceOf(Object),
  keywords: PropTypes.string,
  onClickMore: PropTypes.func,
  columns: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  selectedAssessments: PropTypes.arrayOf(Object),
  listItems: PropTypes.arrayOf(Object),
};

ListCard.displayName = 'ListCard';

export default ListCard;
