import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../../app/queryCache';
import { getFonts } from '../../../resources/ResourceServices';
import { useAppContext } from '../../../components/AppProvider';

const useMyFontListQuery = (isReady, includeDeletedFonts) => {
  const { isPortal } = useAppContext();
  // console.log('### useMyFontListQuery', isPortal, includeDeletedFonts);

  const getMyFontList = useQuery({
    queryKey: [CacheKeys.fetchFonts, includeDeletedFonts],
    queryFn: async () => {
      const resp = await getFonts(isPortal, includeDeletedFonts);
      return resp;
    },
    ...{
      retry: 1,
      retryDelay: () => 5000,
      enabled: !!isReady,
    },
  });

  return {
    getMyFontsQuery: getMyFontList,
    myFonts: getMyFontList.data,
    isMyFontsLoading: getMyFontList.isLoading,
  };
};

export default useMyFontListQuery;
