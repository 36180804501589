import React, { useEffect } from 'react';
import Loading from '../components/Loading';
import { getCookie, getDomain, setCookie } from '../commons/utils';

const OAuthCallbackScreen = () => {
  useEffect(() => {
    function handleOneDriveCallback(params) {
      const origin = window.location.origin;
      const subDomain = getCookie('tmpSubDomain');
      console.log('###subDomain', subDomain);
      setCookie('tmpSubDomain', '', 0, getDomain(origin) ?? '');
      if (subDomain && origin.startsWith('https://spp.')) {
        // redirect to subdomain
        window.location.href = `${origin.replace('https://spp.', `https://${subDomain}.`)}/oauth/callback?${params.toString()}`;
      } else {
        console.log('###subDomain', subDomain);
        const code = params.get('code');
        if (code !== null) {
          window.close();
          window.onunload = function () {
            window.opener.CallParentfunc(true, code);
          };
        }
      }
    }

    function handleGoogleDriveCallback(params) {
      console.log('###params', params);
      const origin = window.location.origin;
      const subDomain = getCookie('tmpSubDomain');
      console.log('###subDomain', subDomain);
      setCookie('tmpSubDomain', '', 0, getDomain(origin) ?? '');
      if (subDomain && origin.startsWith('https://spp.')) {
        // redirect to subdomain
        window.location.href = `${origin.replace('https://spp.', `https://${subDomain}.`)}/oauth/callback?${params.toString()}`;
      } else {
        console.log('###subDomain', subDomain);
        const code = params.get('code');
        const scope = params.get('scope');
        if (code !== null) {
          window.close();
          window.onunload = function () {
            window.opener.CallParentfunc(true, code, scope);
          };
        }
      }
    }

    function initialize() {
      const params = new URLSearchParams(window.location.search);
      const state = params.get('state');
      switch (state) {
        case 'onedrive':
          handleOneDriveCallback(params);
          break;
        case 'google-drive':
          handleGoogleDriveCallback(params);
          break;
        default:
          break;
      }
    }

    initialize();
  }, []);

  return (
    <div className="loading-container">
      <Loading />
    </div>
  );
};

export default OAuthCallbackScreen;
