/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import LightTooltip from './LightTooltip';
import './Icons.scss';

const Icons = (props) => {
  const {
    // not passing down these props
    name,
    className,
    title,
    ...iconProps
  } = props;
  let icon;
  if (name === 'map-marked-alt') {
    icon = (
      <span {...iconProps} className={`icon-map-marked-alt ${className}`}>
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    );
  } else if (name === 'radio-button-checked') {
    icon = (
      <span {...iconProps} className={`icon-radiobuttonchecked ${className}`}>
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    );
  } else if (name === 'hotel' || name === 'studio' || name === 'square-dualtone') {
    icon = (
      <span className={`icon-${name} ${className}`}>
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    );
  } else if (name === 'square-check-dualtone') {
    icon = (
      <span className={`icon-${name} ${className}`}>
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
      </span>
    );
  } else if (
    name === 'icon-correct' ||
    name === 'icon-check-circle' ||
    name === 'icon-quiz-question' ||
    name === 'icon-poll-question' ||
    name === 'icon-wordcloud-question' ||
    name === 'icon-scale-question' ||
    name === 'icon-desktop-dark' ||
    name === 'icon-desktop-light'
  ) {
    icon = (
      <span className={`${name} ${className}`}>
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    );
  } else if (name === 'icon-no-internet' || name === 'icon-onedrive' || name === 'icon-google') {
    icon = (
      <span className={`${name} ${className}`}>
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
      </span>
    );
  } else if (name === 'icon-googledrive') {
    icon = (
      <span className={`${name} ${className}`}>
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
        <span className="path6"></span>
      </span>
    );
  } else if (name === 'icon-new-info') {
    icon = (
      <span className={`${name} ${className}`}>
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    );
  } else if (name === 'icon-space-analytics') {
    icon = (
      <span className={`${name} ${className}`}>
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
      </span>
    );
  } else if (name === 'icon-company-analytics') {
    icon = (
      <span className={`${name} ${className}`}>
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
      </span>
    );
  } else {
    icon = <span className={className}></span>;
  }
  if (!title) {
    return icon;
  }
  return (
    <LightTooltip title={title} arrow>
      <span>{icon}</span>
    </LightTooltip>
  );
};

Icons.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icons;
