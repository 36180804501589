/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/system';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import './ResponsiveDialog.scss';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const defaultProps = {
  modal: false,
  actions: null,
  fullScreen: null,
  className: '',
  maxWidth: 'sm',
  CloseIconProps: {},
  onClose: null,
  disableBackdropClick: false,
  showCloseIcon: true,
  disableEscapeKeyDown: false,
  open: true,
};
function ResponsiveDialog(props) {
  const {
    open,
    children,
    actions,
    modal,
    maxWidth,
    disableBackdropClick,
    showCloseIcon,
    disableEscapeKeyDown,
    fullScreen,
    onClose,
    title,
    className,
  } = { ...defaultProps, ...props };
  const theme = useTheme();
  let isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (fullScreen !== null) {
    isFullScreen = fullScreen;
  }

  function renderTitle() {
    if (!title) return null;
    if (React.isValidElement(title)) {
      return <>{title}</>;
    }
    return <span className="responsive-dialog-title">{title}</span>;
  }

  function handleOnClose(evt, reason) {
    if (disableBackdropClick && reason === 'backdropClick') {
      return;
    }
    if (onClose) {
      onClose(evt, reason);
    }
  }

  return (
    <Dialog
      className={`responsive-dialog ${fullScreen ? 'fullscreen' : ''} ${className}`}
      open={open}
      fullScreen={isFullScreen}
      onClose={modal ? null : handleOnClose}
      maxWidth={maxWidth}
      disableEscapeKeyDown={disableEscapeKeyDown}
      transitionDuration={400}
    >
      <DialogContent className="dialog-content styled-scrollbar">
        {renderTitle()}
        {handleOnClose && showCloseIcon ? (
          <IconButton
            aria-label="close"
            className="close-button"
            color="secondary"
            onClick={handleOnClose}
          >
            <CloseIcon className="close-icon" />
          </IconButton>
        ) : null}

        {children}
      </DialogContent>
      {actions !== null && <DialogActions className="dialog-actions">{actions}</DialogActions>}
    </Dialog>
  );
}

// ResponsiveDialog.propTypes = {
//   modal: PropTypes.bool,
//   open: PropTypes.bool,
//   className: PropTypes.string,
//   maxWidth: PropTypes.string,
//   fullScreen: PropTypes.bool,
//   children: PropTypes.instanceOf(Object),
//   actions: PropTypes.instanceOf(Object),
//   onClose: PropTypes.func,
//   disableBackdropClick: PropTypes.bool,
//   title: PropTypes.node,
//   showCloseIcon: PropTypes.bool,
//   dataIds: PropTypes.instanceOf(Object),
//   disableEscapeKeyDown: PropTypes.bool,
// };

export default ResponsiveDialog;
