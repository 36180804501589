import ResourceListItem from './index';
import { Indicator } from '../../globalResources/context/Interfaces';

export interface ResourceListItemProps {
  resourceId: number | string;
  hasChanges?: boolean;
  lastModified: string;
  created?: string;
  isExternal?: boolean;
  resourceName: string;
  state?: number;
  src?: string;
  extension?: string;
  resourceType?: number;
  selected?: boolean;
  multiple?: boolean;
  isMobile?: boolean;
  subscriptionLock?: boolean;
  isViewOnly?: boolean;
  isFolder?: boolean;
  isNewFile?: boolean;
  isDisabled?: boolean;
  isProcessing?: boolean;
  isConvertingFile: boolean;
  dimmed?: boolean;
  indicator?: Indicator;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDoubleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickMoreButton?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showActionMenu?: boolean;
  disabledMoreButton?: boolean;
  draggable?: boolean;
  isDragging?: boolean;
  showCheckbox?: boolean;
  selectBoxType?: string;
  labelId?: string;
  numberFileOfFolder: () => void;
  columns?: string[];
  location?: string;
  disabledRowMessage?: string;
}

const ResourceListItemTs = ResourceListItem;
export default ResourceListItemTs as React.FC<ResourceListItemProps>;
