/* eslint no-bitwise: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { getSpaceHubUrl, sendNotification } from '../commons/utils';
import CustomSigalRHttpClient from '../commons/CustomSigalRHttpClient';
import spaceUser from './spaceUser';
import ConfirmService from '../components/ConfirmService';
import i18n from '../i18n';
import Icons from '../components/Icons';
import TransferOwnershipHandler from '../homepage/TransferOwnershipHandler';
import DeletedUserEventHandler from '../homepage/DeletedUserEventHandler';
// import { LongPollingConnection } from '../commons/LongPollingConnection';
import { Ids } from '../commons/pendoTaggings';
import FeatureFlagsHandler from '../commons/FeatureFlags/FeatureFlagsHandler';

const signalR = require('@microsoft/signalr');

const onSpaceUserRoleUpdatedEvent = (message) => {
  if (spaceUser.getSpaceId() === message.spaceId) {
    location.reload();
  }
};

const onExternalFileUploadFailed = async (message) => {
  console.log('onExternalFileUploadFailed', message);
  sendNotification(`File ${message.fileName} was failed to upload to ${message.provider}.`, {
    type: 'error',
    duration: 4000,
  });
};

// const onExternalRecordingStarted = (message) => {
//   console.log('onExternalRecordingStarted');
// };

function createConnection() {
  return new signalR.HubConnectionBuilder()
    .withUrl(getSpaceHubUrl(), {
      accessTokenFactory: () => {
        return spaceUser.getAccessToken();
      },
      httpClient: new CustomSigalRHttpClient(),
    })
    .withAutomaticReconnect(2000)
    .build();
}

class SpaceSignalRHandler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      connectionHub: null,
    };
    this.connection = null;
    this.shouldStopConnection = false;
    this.handleOnNetworkOnline = this.handleOnNetworkOnline.bind(this);
    this.handleConnectionFailure = this.handleConnectionFailure.bind(this);
    this.showPopupNoConnection = this.showPopupNoConnection.bind(this);
  }

  componentDidMount() {
    if (this.shouldStopConnection) {
      this.cleanUpConnection();
      this.shouldStopConnection = false;
      return;
    }
    this.connection = createConnection();

    this.connection
      .start()
      .then(() => {
        this.setState({ connectionHub: this.connection });
        if (this.props.onConnected) {
          this.props.onConnected(this.connection);
        }
      })
      .catch((error) => {
        this.shouldStopConnection = true;
        console.log(`SignalRHandler Fail: ${error.message}`);
      });

    window.addEventListener('online', this.handleOnNetworkOnline);

    this.connection.on('ExternalFileUploadFailed', onExternalFileUploadFailed);
    this.connection.on('SpaceUserRoleUpdated', onSpaceUserRoleUpdatedEvent);
  }

  async showPopupNoConnection(isCloseIcon, onReconnect) {
    const isConfirmed = await ConfirmService.show(
      `${i18n.t('NO CONNECTION')}`,
      i18n.t('The connection was lost, check the internet connection and try again.'),
      i18n.t('Reconnect'),
      null,
      true,
      <Icons name="icon-no-internet" />,
      isCloseIcon,
      'z-index-1403',
      null,
      false,
      {
        ok: Ids.ReconnectButton,
      }
    );
    if (isConfirmed) {
      onReconnect();
    }
    console.log(this);
  }

  async handleConnectionFailure() {
    const onReconnect = () => {
      window.location.reload();
    };
    this.showPopupNoConnection(true, onReconnect);
  }

  async handleOnNetworkOnline() {
    const onReconnect = () => {
      this.props.setKeySectionSignal();
    };

    console.log(
      'SpaceSignalRHandler Became online',
      window.navigator.onLine,
      this.state.connection
    );
    if (this.state.connection?.state === 'Disconnected' || this.state.connection?.isStopped) {
      this.showPopupNoConnection(false, onReconnect);
    }
  }

  async cleanUpConnection() {
    if (
      (this.connection && this.connection?.state === 'Connecting') ||
      this.connection?.state === 'Reconnecting'
    ) {
      this.shouldStopConnection = true;
      return;
    }
    if (!this.connection) return;

    this.connection.off();
    await this.connection.stop();
    this.connection = null;
  }

  componentWillUnmount() {
    this.cleanUpConnection();
    window.removeEventListener('online', this.handleOnNetworkOnline);
  }

  render() {
    if (this.props.isAuthenticated) {
      return null;
    }
    return (
      <>
        <DeletedUserEventHandler
          connection={this.connection}
          spaceUserInfoId={this.props.spaceUserInfoId}
        />
        <TransferOwnershipHandler connection={this.connection} isSpace />
        <FeatureFlagsHandler connection={this.connection} />
      </>
    );
  }
}

SpaceSignalRHandler.propTypes = {
  onConnected: PropTypes.func,
  setKeySectionSignal: PropTypes.func,
  spaceUserInfoId: PropTypes.number,
  isAuthenticated: PropTypes.bool,
};

export default SpaceSignalRHandler;
