import React from 'react';
import { Box } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import SpaceAnalyticsInfoBox from '../SpaceAnalyticsInfoBox';
import DataSummaryBox from '../DataSummaryBox';
import Tabs, { useTabs } from '../../components/Tabs';
import i18n from '../../i18n';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import NoSpaceAnalyticsData from '../components/NoSpaceAnalyticsData';
import Loading from '../../components/Loading';
import GroupByAssessmentsTable from './GroupByAssessmentsTable';
import GroupByUsersTable from './GroupByUsersTable';
import useSpaceAssessmentSummaryQuery from '../queries/useSpaceAssessmentSummaryQuery';
import useAssessmentsUsersSummaryQuery from '../queries/useAssessmentsUsersSummaryQuery';

const AssessmentTable = () => {
  const navigate = useNavigate();
  const { tabs, changeTab, activeTab } = useTabs([
    i18n.t('Group by assessments'),
    i18n.t('Group by users'),
  ]);
  const { space, reportEndDate, users, excludeUserIds } = useSpaceAnalyticsContext();
  const spaceId = space?.id;
  const endDate = moment(reportEndDate).toDate().toISOString();

  const { assessmentsSummary, isFetching: isFetchingAssessments } = useSpaceAssessmentSummaryQuery(
    spaceId,
    !!spaceId && !!users?.length,
    endDate,
    excludeUserIds
  );

  const { assessmentsUsersSummary, isFetching: isFetchingUsers } = useAssessmentsUsersSummaryQuery(
    spaceId,
    !!spaceId && !!users?.length,
    endDate,
    excludeUserIds
  );

  const emptyState = !assessmentsSummary?.length && !assessmentsUsersSummary?.length;

  function handleOnChangeTab(value: unknown) {
    changeTab(value);
  }
  const dataSummaryList = [
    {
      id: 'assessments',
      label: 'Assessments',
      value: assessmentsSummary?.length || 0,
      toolTip: 'Total number of assessments in the space.',
    },
  ];
  const renderContent = () => {
    if (isFetchingUsers || isFetchingAssessments) {
      return (
        <div className="loading-box">
          <Loading />
        </div>
      );
    }

    if (emptyState) {
      return (
        <NoSpaceAnalyticsData
          icon={<i className="icon-assessments" style={{ fontSize: 20 }} />}
          description={i18n.t('See how much users have communicated in the space.')}
          button={{
            label: i18n.t('add assessment'),
            onClick: () => navigate(`/profile/settings/assessments`),
          }}
        />
      );
    }
    return (
      <>
        <DataSummaryBox dataSummaryList={dataSummaryList} className="col-1" />
        <Tabs
          tabs={tabs}
          onChange={handleOnChangeTab}
          variant="tabs-level-2 preview-details-privacy-level"
        />
        <Box>
          {activeTab === 0 ? (
            <GroupByAssessmentsTable data={assessmentsSummary} />
          ) : (
            <GroupByUsersTable
              data={assessmentsUsersSummary}
              totalAssessments={assessmentsSummary?.length}
            />
          )}
        </Box>
      </>
    );
  };

  return (
    <Box className="widget-box assessment-table">
      <SpaceAnalyticsInfoBox
        icon="icon-assessments"
        title="Assessments"
        description={!emptyState ? 'See how many users have taken assessments in the space.' : ''}
        className="assessment"
      />
      {renderContent()}
    </Box>
  );
};

export default AssessmentTable;
