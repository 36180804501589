export interface ResourceResponse {
  resourceId: number;
}

export interface FetchResourcesPayload {
  resourceIds: Array<number>;
  includesResourceInteractions?: boolean;
  includeRecordedFiles?: boolean;
}

export enum ResourceSources {
  Global = 'Global',
  OneDrive = 'OneDrive',
  GoogleDrive = 'GoogleDrive',
  Space = 'Space',
}
