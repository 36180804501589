import React, { PureComponent } from 'react';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import Loading from './Loading';

class ScrollingList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.ref = React.createRef();
    this.isLoadingMore = false;
    this.handleScrollDebounced = _debounce(() => this.handleScroll(), 500);
  }

  componentDidMount() {
    if (this.props.onScrollToTop) {
      this.handleScrollDebounced();
    }
  }

  async handleScroll() {
    const holder = this.ref.current;
    console.log('handleScroll', this.isLoadingMore);
    if (!holder || this.isLoadingMore) {
      return;
    }
    if (this.props.onScrollToBottom) {
      const distanceToBottom = holder.scrollHeight - holder.scrollTop - holder.offsetHeight;
      console.log('distanceToBottom', distanceToBottom);
      if (distanceToBottom <= 10) {
        this.isLoadingMore = true;
        // this.forceUpdate();
        await this.props.onScrollToBottom();
        this.isLoadingMore = false;
        // this.forceUpdate();
      }
    }
    if (this.props.onScrollToTop) {
      const distanceToTop = holder.scrollHeight + holder.scrollTop - holder.offsetHeight;
      console.log('distanceToTop', distanceToTop);
      if (distanceToTop <= 10) {
        this.isLoadingMore = true;
        // this.forceUpdate();
        await this.props.onScrollToTop();
        this.isLoadingMore = false;
        // this.forceUpdate();
      }
    }
  }

  render() {
    const { className, children } = this.props;
    if (this.props.onScrollToBottom || this.props.onScrollToTop) {
      return (
        <div onScroll={this.handleScrollDebounced} ref={this.ref} className={className}>
          <>
            {children}
            <div
              className="loading-item"
              style={{ display: 'block', width: '100%', textAlign: 'center', marginTop: 5 }}
            >
              <Loading />
            </div>
          </>
        </div>
      );
    }
    return <div className={className}>{children}</div>;
  }
}

ScrollingList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  onScrollToBottom: PropTypes.func,
  onScrollToTop: PropTypes.func,
};

ScrollingList.defaultProps = {
  className: '',
  onScrollToBottom: null,
  onScrollToTop: null,
};

export default ScrollingList;
