import React from 'react';
import PropTypes from 'prop-types';
import ActionMenu from '../components/Material/ActionMenu';
import { deleteDirectory } from './globalResourcesService';
import { deleteResource } from '../resources/ResourceServices';
import i18n from '../i18n';
import { sendNotification } from '../commons/utils';
import ConfirmService from '../components/ConfirmService';
import { GLOBAL_RESOURCE_TYPE } from './utils';
import { downloadFile } from '../commons/ResourceUtils';
import useExitPrompt from '../commons/useExitPrompt';
import { ResourceState, ResourceType } from '../app/appConstants';

const ActionsMenu = (props) => {
  const { selectedResource, anchorEl, onClose } = props;
  const isFolder = selectedResource?.type === GLOBAL_RESOURCE_TYPE.FOLDER;
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  async function handleDeleteButtonClick() {
    if (!selectedResource) return false;

    const isConfirmed = await ConfirmService.show(
      i18n.t('Delete Resource?'),
      <>
        <b>{i18n.t('This can’t be undone.')}</b>
        <br />
        <span>{i18n.t('Are you sure you want to delete this resource?')}</span>
        <br />
        <br />
        <span>
          {i18n.t('The spaces where this resource is already being used will not be affected.')}
        </span>
      </>
    );
    if (!isConfirmed) {
      onClose();
      return false;
    }

    try {
      if (isFolder) {
        await deleteDirectory(selectedResource.id);
      } else {
        await deleteResource(selectedResource.resourceId);
      }

      if (props.onDeleteButtonClick) props.onDeleteButtonClick();
      onClose();
      sendNotification(i18n.t('Deleted successfully'), { type: 'success' });
      return true;
    } catch (error) {
      sendNotification(error.message, { type: 'error' });
    }

    return false;
  }

  function handleViewButtonClick() {
    onClose();
    props.onResourceView();
  }

  async function handleDownloadDirectory() {
    const directoryId = selectedResource.id;
    if (props.onDownloadFolderClick) {
      await props.onDownloadFolderClick(directoryId);
      onClose();
    }
  }

  async function handleDownloadButtonClick() {
    if (selectedResource.type === GLOBAL_RESOURCE_TYPE.RESOURCE) {
      if (showExitPrompt) {
        return;
      }
      setShowExitPrompt(true);
      await downloadFile(selectedResource, true);
      setShowExitPrompt(false);
    } else if (selectedResource.type === GLOBAL_RESOURCE_TYPE.FOLDER) {
      handleDownloadDirectory();
    }

    onClose();
  }

  function handleOnClickReNameButton() {
    onClose();
    if (props.onRenameButtonClick) {
      props.onRenameButtonClick();
    }
  }

  function handleOnClickUploadNewVersion() {
    onClose();
    if (props.onUploadNewVersionButtonClick) {
      props.onUploadNewVersionButtonClick();
    }
  }

  // async function handleShareLinkButtonClick() {
  //   let shareLink;
  //   if (selectedResource.type === GLOBAL_RESOURCE_TYPE.RESOURCE) {
  //     const directoryId = currentFolderId;
  //     const resourceId = selectedResource.resourceId;
  //     shareLink = `${location.origin}/profile/settings/global-resources?srid=${directoryId}-${resourceId}`;
  //     // we use srid=${directoryId}-${resourceId} instead of drid=${directoryId}&srid=${resourceId} since while doing redirect after logged in, server can't take the second url parameter with &
  //   } else if (selectedResource.type === GLOBAL_RESOURCE_TYPE.FOLDER) {
  //     const directoryId = selectedResource.id;
  //     shareLink = `${location.origin}/profile/settings/global-resources?drid=${directoryId}`;
  //   }

  //   if (shareLink) {
  //     // console.log('###', shareLink);
  //     await copyTextToClipboard(shareLink);
  //     sendNotification(i18n.t('Link copied!'));
  //     onClose();
  //   }
  // }

  if (!anchorEl) return null;

  if (isFolder) {
    return (
      <ActionMenu
        anchorEl={anchorEl}
        onClose={onClose}
        onDeleteButtonClick={handleDeleteButtonClick}
        onRenameButtonClick={handleOnClickReNameButton}
        onDownloadButtonClick={handleDownloadButtonClick}
        // onShareLinkButtonClick={handleShareLinkButtonClick}
      />
    );
  }

  const disabledView = [ResourceState.ConversionFailed, ResourceState.TranscodingFailed].includes(
    selectedResource?.state
  );

  const onViewFile = disabledView ? null : handleViewButtonClick;

  return (
    <ActionMenu
      anchorEl={anchorEl}
      onClose={onClose}
      onDeleteButtonClick={handleDeleteButtonClick}
      onRenameButtonClick={handleOnClickReNameButton}
      onViewButtonClick={onViewFile}
      onDownloadButtonClick={
        !!selectedResource &&
        !selectedResource.isExternal &&
        selectedResource.type !== ResourceType.podcast
          ? handleDownloadButtonClick
          : null
      }
      // onShareLinkButtonClick={handleShareLinkButtonClick}
      onNewVersionButtonClick={handleOnClickUploadNewVersion}
    />
  );
};

ActionsMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  selectedResource: PropTypes.instanceOf(Object),
  // currentFolderId: PropTypes.number,
  onRenameButtonClick: PropTypes.func,
  onResourceView: PropTypes.func,
  onUploadNewVersionButtonClick: PropTypes.func,
  onDeleteButtonClick: PropTypes.func,
  onDownloadFolderClick: PropTypes.func,
};

export default React.memo(ActionsMenu);
