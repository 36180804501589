import React from 'react';
import PropTypes from 'prop-types';
import ResourcesDisplayView from '../../components/Material/ResourcesDisplayView/ResourcesDisplayView';
import { getAvailableItems, getFilterListByText } from '../../components/Material/function';
import { useMaterialsContext } from './MaterialsContextProvider';
import useSmartFolderSearch from './useSmartFolderQuery';
import i18n from '../../i18n';
import { useAppContext } from '../../components/AppProvider';
import Loading from '../../components/Loading';
import NoDaTaBox from '../../components/NoDataBox';

function SearchingFolder(props) {
  const {
    searchText,
    renderingFolder,
    isWebinar,
    canDragDrop,
    handleDirectoryClick,
    handleMaterialClick,
    newResourceId,
    selectedFile,
  } = props;
  const { flatDirectories, processingResources } = useMaterialsContext();
  const { isMobilePortrait } = useAppContext();

  const { internalsData, externalsData } = getAvailableItems(flatDirectories, renderingFolder);
  const oneDriveFolders = externalsData?.oneDriveFolders || [];
  const googleDriveFolders = externalsData?.googleDriveFolders || [];

  const { smartFolderResults, isLoading: smartFolderLoading } = useSmartFolderSearch(
    oneDriveFolders,
    googleDriveFolders,
    searchText,
    searchText?.length > 0 && (oneDriveFolders.length > 0 || googleDriveFolders.length > 0)
  );

  if (smartFolderLoading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  const data = getFilterListByText(internalsData ?? [], searchText);

  const filteredData = [...data, ...smartFolderResults];

  if (filteredData.length === 0) {
    return (
      <NoDaTaBox
        icon={<i className="icon-search" />}
        title={i18n.t('No files or folders found,')}
        description={i18n.t('Choose another keyword and try again.')}
      />
    );
  }
  return (
    <ResourcesDisplayView
      data={filteredData}
      isWebinar={isWebinar}
      canDragDrop={canDragDrop}
      onClickFolder={handleDirectoryClick}
      onClickFile={handleMaterialClick}
      onClickMoreButton={props.handleMoreButtonClick}
      processingResources={processingResources}
      newResourceId={newResourceId}
      isMobile={isMobilePortrait}
      selectedFile={selectedFile}
      spaceFolderId={renderingFolder.id}
      isPortal={props.isPortal}
    />
  );
}

SearchingFolder.propTypes = {
  searchText: PropTypes.string,
  renderingFolder: PropTypes.instanceOf(Object),
  isWebinar: PropTypes.bool,
  canDragDrop: PropTypes.bool,
  handleDirectoryClick: PropTypes.func,
  handleMaterialClick: PropTypes.func,
  handleMoreButtonClick: PropTypes.func,
  newResourceId: PropTypes.number,
  selectedFile: PropTypes.instanceOf(Object),
  isPortal: PropTypes.bool,
};

SearchingFolder.defaultProps = {
  isPortal: false,
};

export default SearchingFolder;
