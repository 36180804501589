import React from 'react';
import { Box, Typography } from '@mui/material';
import { Column } from '@material-table/core';
import Table from '../components/Table';
import {
  tagViewTimeSupportedList,
  MaterialBasicInfo,
  ResourceMetricsDataItem,
  typeViewTimeNotSupportedList,
  AllResourceMetricsResponse,
} from '../types/resources';
import ResourceIcon from '../../components/Material/ResourceIcon';
import { getStringTime } from '../common';
import useSpaceAnalyticsQuery from '../hooks/useSpaceAnalyticsQuery';
import { fetchResourcesMetrics } from '../services/SpaceAnalyticsService';
import { CacheKeys } from '../../app/queryCache';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import Loading from '../../components/Loading';
import LightTooltip from '../../components/LightTooltip';
import SpaceTag from '../../components/SpaceTag/SpaceTag';
import './ResourceMetricsTable.scss';
import { SpaceResourceDetailsTab } from '../ResourceDetailsModal';
import { sortSpaceAnalyticMetrics } from '../components/Table/utils';
import useAvailableSpaceResources from '../hooks/useAvailableSpaceResources';
import { Ids } from '../../commons/pendoTaggings';
import { ResourceType } from '../../app/appConstants';
import { isIntegrationResource } from '../../integrations/integrationResourceUtils';
import ResourcePreviewDialog, {
  getResourcePreviewContext,
} from '../../resources/ResourcePreviewDialog';
import eventBus, { EVENT_BUS } from '../../commons/EventBus';
import { isExternalLinkNotVideo } from '../../commons/ResourceUtils';
import { openWindow } from '../../commons/utils';

type ResourceMetricsTableProps = {
  resourceMetricsByResources?: AllResourceMetricsResponse;
};

interface PreviewingResource {
  resourceId: number;
  src: string;
  name: string;
  resourceType: number;
  isExternal: boolean;
}

const ResourceMetricsTable = (props: ResourceMetricsTableProps) => {
  const { resourceMetricsByResources } = props;
  const { excludeUserIds, visibleUsers, openSpaceResourceModal } = useSpaceAnalyticsContext();
  const {
    cmsMaterials,
    availableMaterials,
    availableMaterialIds,
    isLoading: isResourcesLoading,
  } = useAvailableSpaceResources();

  const participantFilter = availableMaterials?.filter((p) =>
    typeViewTimeNotSupportedList.find(
      ([boolValue, numValue]) => boolValue === p?.isCmsFile && numValue === p?.resourceType
    )
  );
  const { data: allResourceMetrics, isLoading: isMetricsLoading } = useSpaceAnalyticsQuery({
    fetcher: fetchResourcesMetrics,
    params: {
      groupBy: 'Resource',
      availableMaterialIds,
      notSupportedViewMaterialIds: participantFilter?.map((x) => x.materialId),
      excludedUserInfoIds: excludeUserIds,
      userInfoIds: visibleUsers?.map((x) => x.userInfoId),
    },
    queryKey: CacheKeys.spaceAnalytics_FetchResourcesMetrics,
    select: (resp) => resp.value.data,
    enabled: !resourceMetricsByResources && !isResourcesLoading,
  });

  const resourceMetricsData = resourceMetricsByResources?.data || allResourceMetrics;

  const handleOnClickLink = React.useCallback(
    (
      rowData: ResourceMetricsDataItem<MaterialBasicInfo>,
      tab: (typeof SpaceResourceDetailsTab)[keyof typeof SpaceResourceDetailsTab]
    ) => {
      openSpaceResourceModal(rowData, tab);
    },
    [openSpaceResourceModal]
  );

  const handleResourcePreview = (previewingResource: PreviewingResource) => {
    console.log(
      '### previewingResource',
      previewingResource,
      isExternalLinkNotVideo(previewingResource)
    );
    if (!previewingResource) return;
    if (
      isExternalLinkNotVideo(previewingResource) &&
      !isIntegrationResource(previewingResource.src)
    ) {
      openWindow(previewingResource.src, '_blank');
    } else {
      const previewContext = getResourcePreviewContext('space');
      ResourcePreviewDialog.show(previewingResource, previewContext);
      eventBus.publish(EVENT_BUS.ToggleUploadResourcePopup, false);
    }
  };

  const columns: Column<ResourceMetricsDataItem<MaterialBasicInfo>>[] = [
    {
      title: 'Name',
      field: 'resourceName',
      cellStyle: {
        width: '30%',
      },
      render: ({ resourceType, resourceName, extension, tag, resourceId, src, isExternal }) => (
        <Box
          className="resource-info"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            const resourceToPreview: PreviewingResource = {
              resourceId: resourceId as number,
              src: src,
              name: resourceName,
              resourceType: resourceType as number,
              isExternal: isExternal,
            };
            handleResourcePreview(resourceToPreview);
          }}
        >
          <ResourceIcon type={resourceType} name={resourceName} extension={extension} />
          <Typography className="resource-info-name" noWrap>
            {resourceName !== '' ? resourceName : 'Video Message'}
          </Typography>
          {tag && <SpaceTag colorId={15} name={tag} />}
        </Box>
      ),
    },
    {
      title: 'Resource',
      field: 'extension',
      cellStyle: {
        width: '40%',
      },
      render: ({ src, isExternal, extension, resourceType, resourceName }) => {
        if (isExternal && resourceType !== ResourceType.vaam && !isIntegrationResource(src)) {
          return (
            <Typography
              style={{ maxWidth: '25rem', color: 'rgba(71, 131, 255, 1)', fontWeight: 700 }}
              noWrap
              data-id={Ids.resourceMetricExternalLink}
            >
              <a href={src} target="_blank" rel="noopener noreferrer">
                {src}
              </a>
            </Typography>
          );
        }

        let strExtension: string | undefined = extension;
        if (!extension) {
          strExtension = resourceName?.split('.').pop();
        }

        return <Typography style={{ textTransform: 'uppercase' }}>{strExtension}</Typography>;
      },
    },
    {
      title: 'Clicks',
      field: 'clicks',
      defaultSort: 'desc',
      cellStyle: {
        width: '20%',
      },
      customSort: (a, b) => sortSpaceAnalyticMetrics(a.clicks, b.clicks),
      render: (rowData: ResourceMetricsDataItem<MaterialBasicInfo>) => {
        const { clicks } = rowData;
        return (
          <span
            className="btn-link"
            onClick={() => handleOnClickLink(rowData, SpaceResourceDetailsTab.VIEW)}
            data-id={Ids.resourceMetricViews}
          >
            {clicks}
          </span>
        );
      },
    },
    {
      title: 'Shares',
      field: 'shares',
      align: 'right',
      cellStyle: {
        width: '20%',
      },
      customSort: (a, b) => sortSpaceAnalyticMetrics(a.shares, b.shares),
      render: (rowData) => {
        if (rowData.isCmsFile || isIntegrationResource(rowData.src)) {
          return (
            <LightTooltip title={'This resource cannot be shared.'}>
              <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
            </LightTooltip>
          );
        }
        return (
          <span
            className="btn-link"
            onClick={() => handleOnClickLink(rowData, SpaceResourceDetailsTab.SHARES)}
            data-id={Ids.resourceMetricShares}
          >
            {rowData?.shares}
          </span>
        );
      },
    },
    {
      title: 'Downloads',
      field: 'downloads',
      align: 'right',
      cellStyle: {
        width: '20%',
      },
      customSort: (a, b) => sortSpaceAnalyticMetrics(a.downloads, b.downloads),
      render: (rowData) => {
        if (rowData.isCmsFile || rowData.resourceType === ResourceType.vaam || rowData.isExternal) {
          return (
            <LightTooltip title={'This resource cannot be downloaded.'}>
              <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
            </LightTooltip>
          );
        }
        return (
          <span
            className="btn-link"
            onClick={() => handleOnClickLink(rowData, SpaceResourceDetailsTab.DOWNLOADS)}
            data-id={Ids.resourceMetricDownloads}
          >
            {rowData.downloads}
          </span>
        );
      },
    },
    {
      title: 'View time',
      field: 'viewTimeInSeconds',
      align: 'right',
      cellStyle: {
        width: '20%',
      },
      customSort: (a, b) => sortSpaceAnalyticMetrics(a.viewTimeInSeconds, b.viewTimeInSeconds),
      render: ({ viewTimeInSeconds, isViewNotSupported, isCmsFile, tag }) => {
        const cmsViewSupported = tagViewTimeSupportedList.find(
          ([boolValue, stringValue]) => boolValue === isCmsFile && stringValue === tag
        );
        if (isViewNotSupported && !cmsViewSupported) {
          return (
            <LightTooltip title={'This resource cannot be measured by view time.'}>
              <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
            </LightTooltip>
          );
        }
        return <Typography>{getStringTime(viewTimeInSeconds)}</Typography>;
      },
    },
  ];

  function processData() {
    const data: ResourceMetricsDataItem<MaterialBasicInfo>[] = [];
    console.log('### resourceMetricsData', resourceMetricsData);
    resourceMetricsData?.forEach((metrics) => {
      const res = availableMaterials?.find((m) => m.materialId === metrics.materialId);
      if (res) {
        const cms = cmsMaterials?.find(
          (x: { materialId: unknown }) => x.materialId === res.materialId
        );
        data.push({
          ...res,
          ...metrics,
          downloads: res.isCmsFile ? -1 : metrics.downloads,
          shares: res.isCmsFile ? -1 : metrics.shares,
          viewTimeInSeconds: res.isViewNotSupported ? -1 : metrics.viewTimeInSeconds,
          tag: cms?.tag,
          resourceName: cms?.useCmsName ? cms?.resourceName : res.resourceName,
        });
      }
    });
    return data.sort((a, b) => a.resourceName.localeCompare(b.resourceName)) || [];
  }

  if (isMetricsLoading || isResourcesLoading) {
    return (
      <div className="loading-box">
        <Loading />
      </div>
    );
  }

  const data = processData();
  return <Table columns={columns} data={data} />;
};

export default ResourceMetricsTable;
