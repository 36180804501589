import React from 'react';
import { MaterialBasicInfo } from '../types/resources';
import { ResourceType } from '../../app/appConstants';
import { getCssClassByResourceType } from '../../commons/ResourceUtils';
import ResourcePreviewDialog, {
  getResourcePreviewContext,
} from '../../resources/ResourcePreviewDialog';
import SpaceTag from '../../components/SpaceTag/SpaceTag';

type ResourceItemByTypeProps = {
  material?: MaterialBasicInfo;
  className?: string;
  tag?: string;
};

const ResourceItemByType = (props: ResourceItemByTypeProps) => {
  const { material, className, tag } = props;
  if (!material) {
    return null;
  }
  const { resourceType, src, resourceName } = material;
  const classNameIcon = getCssClassByResourceType(resourceType);
  // console.log('material: ', material);

  function handleOnClickLink(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
  }

  function handleOnClick() {
    if (!material?.isViewNotSupported) {
      ResourcePreviewDialog.show(material, getResourcePreviewContext('portal'));
    }

    console.log('click');
  }

  function renderResourceName() {
    let curName = resourceName;
    if (curName === '' && resourceType === ResourceType.vaam) {
      curName = 'Video Message';
    }
    if (
      classNameIcon === 'icon-resource-link' ||
      (classNameIcon === 'icon-video' &&
        (src?.indexOf('youtu') !== -1 || src?.indexOf('vimeo') !== -1))
    ) {
      return (
        <a
          href={src}
          target="_blank"
          rel="noreferrer"
          onClick={handleOnClickLink}
          title={resourceName}
        >
          {resourceName}
        </a>
      );
    }
    return <span title={curName}>{curName}</span>;
  }

  return (
    <div className={`resource-icon-name ${className}`} onClick={handleOnClick}>
      <span className={`resource-icon ${classNameIcon}`}>
        <span className="path1"></span>
        <span className="path2"></span>
        {resourceType === ResourceType.cad && (
          <>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
            <span className="path6"></span>
          </>
        )}
      </span>

      <span className="name" title={resourceName}>
        {renderResourceName()}
      </span>
      {tag && <SpaceTag colorId={15} name={tag} />}
    </div>
  );
};

export default ResourceItemByType;
