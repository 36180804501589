import PropTypes from 'prop-types';
import React from 'react';
import './GlobalResourcePreviewPane.scss';
import ResourceThumbnail from '../components/ResourceThumbnail';
import i18n from '../i18n';
import PreviewResources from '../integrations/components/PreviewResource';
import useStatsResourceQuery from './useStatsResourceQuery';

const GlobalResourcePreviewPane = (props) => {
  // console.log('render GlobalResourcePreviewPane');
  const {
    resourceId,
    resourceFolderId,
    name,
    type,
    modified,
    description,
    src,
    thumbnail,
    isFolder,
    // token,
    // videoIndexer,
    context,
    isAdmin,
    extension,
    // resourceName,
  } = props;
  const isPortal = context?.type === 'portal';
  const { resourceInfo, isLoading } = useStatsResourceQuery(
    resourceFolderId,
    resourceId,
    isAdmin && isPortal && !isFolder
  );

  function renderViewer() {
    if (!src) return null;
    return (
      <ResourceThumbnail
        resourceId={resourceId}
        thumbnail={thumbnail}
        resourceType={type}
        extension={extension}
        isPortal={isPortal}
        isExternal={props.isExternal}
        src={src}
        inView
        resourceName={name}
      />
    );
  }

  if (!resourceId && !isFolder) {
    return (
      <div className="no-selected-file">
        {i18n.t('Select a file or folder to view the details')}
      </div>
    );
  }
  return (
    <div className="global-resource-preview-pane">
      <div className="preview-pane-title text-truncate">
        <span title={name} className="bold">
          {name}
        </span>
      </div>
      {isFolder ? (
        <span className="icon-folder bold"></span>
      ) : (
        <div className="previewer">{renderViewer()}</div>
      )}

      <PreviewResources
        isFolder={isFolder}
        type={type}
        src={src}
        modified={modified}
        resourceId={resourceId}
        description={isAdmin && isPortal ? description : ''}
        totalSpaces={resourceInfo?.totalUsagesInSpaces}
        totalUsage={resourceInfo?.totalUsages}
        views={resourceInfo?.totalViews}
        isShowExtend={isAdmin && isPortal && !isFolder && !isLoading}
      />
    </div>
  );
};

GlobalResourcePreviewPane.propTypes = {
  resourceId: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.number,
  modified: PropTypes.string,
  description: PropTypes.string,
  src: PropTypes.string,
  thumbnail: PropTypes.string,
  isFolder: PropTypes.bool,
  isExternal: PropTypes.bool,
  context: PropTypes.instanceOf(Object),
  isAdmin: PropTypes.bool,
  extension: PropTypes.string,
  resourceFolderId: PropTypes.number,
  // resourceName: PropTypes.string,
};

GlobalResourcePreviewPane.defaultProps = {
  isFolder: false,
  isExternal: false,
  isAdmin: false,
};

export default React.memo(GlobalResourcePreviewPane);
