export const SpacePanelType = {
  Chat: 'Chat',
  Resources: 'Resources',
};

export const sortSpacesOptions = [
  { value: 'Name:Asc', name: 'Name', title: 'Name' },
  { value: 'Created:Desc', name: 'Creation date', title: 'Date' },
  { value: 'SpaceEngagement.LastUpdated:Desc', name: 'Engagement', title: 'Engagement' },
];

export const sortSpaceTemplateOptions = [
  { value: 'Name:Asc', name: 'Name', title: 'Name' },
  { value: 'Created:Desc', name: 'Creation date', title: 'Date' },
];

export const spaceStatusWOptions = [
  { name: 'Open', value: 'Open' },
  { name: 'Closed', value: 'Closed' },
];

export const spaceThemeOptions = [
  { name: 'space', value: 0 },
  { name: 'Showroom', value: 1 },
];

export const SpaceTheme = {
  space: 'space Theme',
  Showroom: 'Showroom Theme',
};

export const SpaceNameDefault = {
  space: 'Space title',
  template: 'Template title',
};

export const SpaceTypes = {
  space: 'space',
  Showroom: 'Showroom',
};

export const SpaceNumTypes = {
  space: 0,
  Showroom: 1,
};

export const SpaceActiveTypes = {
  Today: 'Today',
  LastSevenDays: 'LastSevenDays',
  LastThirtyDays: 'LastThirtyDays',
  InActive: 'InActive',
};

export const SpaceActiveType = {
  Today: 'active',
  LastSevenDays: 'last-seven-days',
  LastThirtyDays: 'last-thirty-days',
  InActive: 'in-active',
};

export const SpaceActiveStateMessages = {
  Today: 'Users are active in the space today.',
  LastSevenDays: 'Users have been active in the space this week.',
  LastThirtyDays: 'Users have been active in the space this month.',
  InActive: 'Users have not been active in the space for the past month.',
};
