import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@emotion/react';

import queryCache from '../app/queryCache';
import { getTheme } from '../theme';
import ResponsiveDialog from '../components/ResponsiveDialog';
import ExternalResourceViewer from '../components/ExternalResourceViewer';

let containerElement;
let rootEl;

class UrlResourcePreviewDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpen !== prevState.isOpen && !this.state.isOpen) {
      UrlResourcePreviewDialog.destroy();
    }
  }

  componentWillUnmount() {
    setTimeout(() => {
      rootEl.unmount();
      containerElement = null;
    }, 0);
  }

  static isOpen() {
    return !!containerElement;
  }

  static destroy() {
    if (containerElement) {
      try {
        setTimeout(() => {
          rootEl.unmount();
          containerElement = null;
        }, 0);
      } catch (e) {
        console.error(e);
      }
    }
  }

  static show(url) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <QueryClientProvider client={queryCache}>
        <ThemeProvider theme={getTheme()}>
          <UrlResourcePreviewDialog url={url} />
        </ThemeProvider>
      </QueryClientProvider>
    );
  }

  handleClose() {
    this.setState({ isOpen: false });
    this.props.onClose();
  }

  render() {
    const { isOpen } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <ResponsiveDialog
        maxWidth="lg"
        className="resource-preview-dialog"
        onClose={this.handleClose}
      >
        <div className="resource-preview-container">
          <ExternalResourceViewer src={this.props.url} />
          {/* <div className="cancel-button-wrapper">
            <IconButton arial-label="cancel" onClick={this.handleClose}>
              <CancelIcon />
            </IconButton>
          </div> */}
        </div>
      </ResponsiveDialog>
    );
  }
}

UrlResourcePreviewDialog.propTypes = {
  url: PropTypes.string,
  onClose: PropTypes.func,
};

UrlResourcePreviewDialog.defaultProps = {
  onClose: () => null,
};

export default UrlResourcePreviewDialog;
