import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './AudioViewer.scss';
import useUnmountEffect from '../commons/useUnmountEffect';
import { VideoResourceViewedBatchTime } from '../app/appConstants';

const AudioViewer = (props) => {
  const { src, name, onChange, sentAudioViewEventToInsight, autoPlay } = props;
  const [isAutoPlayPlayFirstTime, setIsAutoPlayPlayFirstTime] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [viewTime, setViewTime] = useState(0);
  const [timer, setTimer] = useState(0);
  console.log('AudioViewer -> timer', timer, viewTime);

  useEffect(() => {
    if (isPlaying && viewTime >= VideoResourceViewedBatchTime) {
      sentAudioViewEventToInsight(viewTime);
      setViewTime(0);
    }
  }, [isPlaying, sentAudioViewEventToInsight, viewTime]);

  function handlerOnChange() {
    if (!isAutoPlayPlayFirstTime) {
      setIsAutoPlayPlayFirstTime(true);
    } else {
      if (onChange) onChange();
    }
  }

  function handleStartView() {
    handlerOnChange();
    setIsPlaying(true);
    if (!isAutoPlayPlayFirstTime) {
      setIsAutoPlayPlayFirstTime(true);
    }
    setTimer(
      setInterval(() => {
        setViewTime((prevTime) => {
          const newWatchTime = prevTime + 1;
          return newWatchTime;
        });
      }, 1000)
    ); // Increment view time every second
  }

  function SentEventAndClearTimer() {
    sentAudioViewEventToInsight(viewTime);
    clearInterval(timer);
    setViewTime(0);
    setIsPlaying(false);
  }

  function handlePauseView() {
    // Clear the timer when the video is paused
    SentEventAndClearTimer();
  }

  useUnmountEffect(() => {
    SentEventAndClearTimer();
  });

  return (
    <div className="audio-viewer">
      {name && (
        <div className="title text-truncate" title={name}>
          {name}
        </div>
      )}
      <div className="audio">
        <audio
          controls
          autoPlay={autoPlay}
          onPlay={handleStartView}
          onPause={handlePauseView}
          onEnded={SentEventAndClearTimer}
        >
          <source src={src} />
        </audio>
      </div>
    </div>
  );
};

AudioViewer.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  sentAudioViewEventToInsight: PropTypes.func,
  autoPlay: PropTypes.bool,
};

AudioViewer.defaultProps = {
  sentAudioViewEventToInsight: () => console.log('sentAudioViewEventToInsight'),
  autoPlay: false,
};

export default AudioViewer;
