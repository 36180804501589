import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import update from 'immutability-helper';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './CmsColumnList.scss';
import './AssessmentList.scss';
import ConfirmRemoveBlockDialog from '../components/ConfirmRemoveBlockDialog';
import i18n from '../../i18n';
import OfficialButton from '../../components/OfficialButtons';
import CMSSelectAssessmentPopup from '../../assessments/components/CMSSelectAssessmentPopup';
import { useMountEffect } from '../../commons/CustomHooks';
import { uuidv4 } from '../../commons/utils';
import useSpaceAssessmentsQuery from '../../assessments/queries/useSpaceAssessmentsQuery';

const ItemList = (props) => {
  const { index, id, canMove } = props;
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemList.name,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    // drop(item) {
    //   props.onDropped(item);
    // },
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.onMove(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemList.name,
    item: { type: ItemList.name, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      props.onDropped();
    },
  });

  useLayoutEffect(() => {
    drag(drop(ref));
    return () => {
      drag(null);
      drop(null);
    };
  }, [drag, drop, ref]);

  const className = ` ${isDragging ? ' dragging' : ''} ${canMove ? '' : 'disabled-drag'}`;

  const handleOnRemoveColumn = () => {
    props.onRemove(props.item.id);
  };

  return (
    <div ref={ref} className={`cms-pages-item ${className}`} data-handler-id={handlerId}>
      <span className="cms-pages-item-left text-truncate" title={props.item.name}>
        <span className="icon-columns"></span>
        {props.item.name}
      </span>
      <IconButton className="delete-button" onClick={handleOnRemoveColumn}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

ItemList.propTypes = {
  index: PropTypes.number,
  item: PropTypes.instanceOf(Object),
  id: PropTypes.number,
  onRemove: PropTypes.func,
  onMove: PropTypes.func,
  onDropped: PropTypes.func,
  canMove: PropTypes.bool,
};

const AssessmentList = (props) => {
  const { name, data, onChange } = props;
  const [list, setList] = useState(data && data.length > 0 ? data : []);
  const [openPopup, setOpenPopup] = useState(false);

  const assessmentIds = data?.map((item) => item.assessmentId);
  const { spaceAssessments: assessmentsData, refetch } = useSpaceAssessmentsQuery(
    assessmentIds,
    true,
    false
  );

  useMountEffect(() => {
    if (assessmentIds?.length > 0) {
      refetch();
    }
  });

  useEffect(() => {
    if (data && data?.length > 0 && assessmentsData?.length > 0) {
      // Replace assessment data with the selected assessment data
      const newAssessmentData = [];
      data.forEach((item) => {
        const assessment = assessmentsData.find((i) => i.assessmentId === item.assessmentId);
        if (assessment) {
          newAssessmentData.push({
            ...item,
            name: assessment?.assessmentName || item.name,
            thumbnailResourceUrl: assessment?.thumbnailResourceUrl || item.thumbnailResourceUrl,
          });
        }
      });
      setList(newAssessmentData);
    }
  }, [data, assessmentsData]);

  const handleRemoveItem = async (id) => {
    const { hasConfirmed } = await ConfirmRemoveBlockDialog.show(
      'Remove Assessment',
      `Are you sure you want to remove this assessment in the widget? All historical data of this
        assessment in this space will be kept in analytics.`,
      false,
      null,
      'Yes',
      'Cancel'
    );
    if (hasConfirmed) {
      setList((prev) => {
        const newList = prev.filter((item) => item.id !== id);
        console.log('remove item', id, prev, newList);

        onChange(newList);
        return newList;
      });
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleAddItem = () => {
    setOpenPopup(true);
  };

  const handleOnMove = useCallback(
    (dragIndex, hoverIndex) => {
      setList((prevList) => {
        const dragItem = prevList[dragIndex];
        const newList = update(prevList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        });
        onChange(newList);
        return newList;
      });
    },
    [onChange]
  );

  const onDropped = () => {
    setList(list.map((item, index) => ({ ...item, id: index })));
  };

  const handleOnChangeSelectedAssessments = (selectedAssessments) => {
    setList((prev) => {
      let newItems = prev;
      selectedAssessments.map((item) => {
        newItems = update(newItems, {
          $push: [
            {
              id: uuidv4(),
              name: item.name,
              assessmentId: item.id,
              thumbnailResourceUrl: item.thumbnailResourceUrl,
            },
          ],
        });
        return item;
      });
      onChange(newItems);
      return newItems;
    });
  };

  return (
    <div className="cms-assessment-list cms-pages-list">
      {list?.length === 0 ? (
        <p className="message">
          {i18n.t('No assessments yet. Click the button to add your first assessment.')}
        </p>
      ) : (
        <>
          <div className="label">{i18n.t('Number of assessments')}</div>
          <div>
            {list.map((item, index) => (
              <ItemList
                name={name}
                index={index}
                key={`items-${item.id}`}
                id={item.id}
                item={item}
                onRemove={handleRemoveItem}
                onMove={handleOnMove}
                onDropped={onDropped}
              />
            ))}
          </div>
        </>
      )}

      <OfficialButton
        className="add-button"
        label={i18n.t('Add')}
        onClick={handleAddItem}
        variant="rectangle-primary"
        icon={<AddIcon />}
      />
      {openPopup && (
        <CMSSelectAssessmentPopup
          onChange={handleOnChangeSelectedAssessments}
          onClose={handleClosePopup}
          excludeIds={list.map((item) => item.assessmentId)}
        />
      )}
    </div>
  );
};

AssessmentList.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  data: PropTypes.instanceOf(Array),
};

export default AssessmentList;
