import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './SpaceTags.scss';
import { sortBy } from 'lodash';
import SpaceTag from './SpaceTag';

// the number of ms the window size must stay the same size before the dimension state variable is reset
const RESET_TIMEOUT = 100;

const numberTagsFitting = (arrElms, width) => {
  // console.log('### numberTagsFitting');
  let fittingNumber = 0;
  arrElms?.reduce((total, item, index) => {
    const newTotal = total + item.offsetWidth + 4;
    if (newTotal < width) {
      fittingNumber = index + 1;
    }
    return newTotal;
  }, 0);
  return fittingNumber;
};

const SpaceTags = ({ items = [], showMoreOption = true }) => {
  const tags = useMemo(() => {
    if (!items || items.length === 0) {
      return [];
    }
    return sortBy(items, (t) => t.name);
  }, [items]);
  const [numberTagsShow, setNumberTagsShow] = useState(tags.length);
  const targetRef = useRef();
  const fullTagsRef = useRef();
  const movementTimer = useRef(null);

  const totalTags = tags.length;

  const updateDimensions = useCallback(() => {
    if (!fullTagsRef?.current) {
      return;
    }
    const parentWidth = targetRef?.current?.offsetWidth;
    const restWidth = parentWidth - 26;
    const arrElms = [...fullTagsRef.current.children];

    const showTags = numberTagsFitting(arrElms, restWidth);
    setNumberTagsShow(showTags);
  }, []);

  React.useLayoutEffect(() => {
    if (!showMoreOption || totalTags <= 1) {
      return;
    }
    updateDimensions();
  }, [showMoreOption, updateDimensions, totalTags]);

  useEffect(() => {
    // every time the window is resized, the timer is cleared and set again
    // the net effect is the component will only reset after the window size
    // is at rest for the duration set in RESET_TIMEOUT. This prevents rapid
    // redrawing of the component for more complex components such as charts
    // console.log('### showMoreOption', showMoreOption);
    if (!showMoreOption || totalTags <= 1) return;
    const handleOnResize = () => {
      clearTimeout(movementTimer.current);
      movementTimer.current = setTimeout(updateDimensions, RESET_TIMEOUT);
    };
    window.addEventListener('resize', handleOnResize);
  }, [showMoreOption, updateDimensions, totalTags]);

  const renderMoreButton = () => {
    if (!showMoreOption || totalTags <= 1 || totalTags <= numberTagsShow) {
      return null;
    }
    const hiddenCount = totalTags - numberTagsShow;
    const renderFullTags = () => {
      return (
        <div className="space-tags">
          {tags.map((item, index) => {
            return <SpaceTag name={item.name} colorId={item.colorId} key={index} />;
          })}
        </div>
      );
    };
    return (
      <SpaceTag
        className="show-more-button"
        name={`+ ${hiddenCount}`}
        tooltipContent={renderFullTags()}
      />
    );
  };
  return (
    <>
      <div className="space-tags-wrap" ref={targetRef}>
        <div className={`space-tags ${showMoreOption && 'space-tags-nowrap'}`} ref={fullTagsRef}>
          {tags.map((item, index) => (
            <SpaceTag
              className={index >= numberTagsShow && showMoreOption ? 'hidden-tag' : ''}
              name={item.name}
              colorId={item.colorId}
              key={index}
            />
          ))}
        </div>
        {renderMoreButton()}
      </div>
    </>
  );
};

SpaceTags.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  showMoreOption: PropTypes.bool,
};

export default SpaceTags;
