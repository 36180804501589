import React from 'react';
import PropTypes from 'prop-types';
import { ResourceState, ResourceType } from '../../app/appConstants';
import ResourceIcon from '../Material/ResourceIcon';
import i18n from '../../i18n';
import './ResourceNameCell.scss';
import { getVideoResourceData, getExtensionFromUrl } from '../../commons/ResourceUtils';
import { formatDateTime } from '../../commons/DateTimeUtils';

function ResourceNameCell(props) {
  const {
    id,
    state,
    type,
    extension,
    isConvertingFile,
    isFolder,
    resourceName,
    isExternal,
    src,
    lastModified,
    created,
    numberFileOfFolder,
    location,
  } = props;

  function renderResourceProcessingTag() {
    const isBlink =
      isConvertingFile || state === ResourceState.Converting || state === ResourceState.Transcoding;

    const videoResourceData = getVideoResourceData({ src, type, state, extension });
    let text = null;
    if (
      props.isProcessing ||
      isConvertingFile ||
      state === ResourceState.Converting ||
      state === ResourceState.Transcoding
    ) {
      text = i18n.t('Processing');
    } else if (state === ResourceState.ConversionFailed) {
      text = i18n.t('Converting failed');
    } else if (videoResourceData?.isTranscodingFailed) {
      text = i18n.t('Transcoding failed');
    }
    if (!text) return null;

    return (
      <div className="processing-tag-wrapper">
        <span className={`processing ${isBlink ? 'blink_me' : ''}`}>{text}</span>
      </div>
    );
  }

  const renderModifiedTime = () => {
    return (
      <div className="number-file">
        {i18n.t('Last modified')}: {formatDateTime(lastModified || created, 'MMM DD, YYYY')}
      </div>
    );
  };
  const renderSubText = () => {
    if (props.isMobile) {
      return renderModifiedTime();
    } else {
      if (location) {
        return <div className="number-file">{location}</div>;
      } else if (numberFileOfFolder) {
        return numberFileOfFolder();
      }
    }
    return null;
  };

  if (isFolder) {
    return (
      <div className="resource-name" key={id}>
        <div className="resource-item-thumbnail">
          <span className="icon-folder" />
        </div>
        <div className="name-details">
          <div className="text-truncate name" title={resourceName}>
            {resourceName}
          </div>
          {renderSubText()}
        </div>
      </div>
    );
  }
  const isVideoResourceExternal = isExternal && type === ResourceType.video;

  return (
    <div className="resource-name" key={id}>
      <div className="resource-item-thumbnail">
        <ResourceIcon
          type={isVideoResourceExternal ? ResourceType.podcast : type}
          name={resourceName}
          extension={getExtensionFromUrl(src, extension)}
        />
      </div>
      <div className="name-details-content">
        <div className="name-details flex-direction-row">
          <div className="text-truncate name" title={resourceName}>
            {resourceName ?? 'Video Message'}
          </div>
          {renderResourceProcessingTag()}
        </div>
        {props.isMobile && (
          <div className="number-file">
            {i18n.t('Last modified')}: {formatDateTime(lastModified || created, 'MMM DD, YYYY')}
          </div>
        )}
      </div>
    </div>
  );
}

ResourceNameCell.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  state: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.number,
  extension: PropTypes.string,
  isConvertingFile: PropTypes.bool,
  isFolder: PropTypes.bool,
  resourceName: PropTypes.string,
  isExternal: PropTypes.bool,
  src: PropTypes.string,
  isProcessing: PropTypes.bool,
  isMobile: PropTypes.bool,
  lastModified: PropTypes.string,
  created: PropTypes.string,
  numberFileOfFolder: PropTypes.func,
  location: PropTypes.string,
};

ResourceNameCell.defaultProps = {
  isShowChildCount: true,
};

export default React.memo(ResourceNameCell);
