import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, Grid } from '@mui/material';

import { isString, isArray } from 'lodash';
import { OrganizationUserRole, ParticipantRole } from '../../app/appConstants';
import { isValidEmail } from '../../commons/ValidationUtils';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import i18n from '../../i18n';
import TextFieldEnhanced from '../../components/TextFieldEnhanced';
import AutocompleteUsers from '../../components/ParticipantSelect/AutocompleteUsers';
import ParticipantRoleSelect from './ParticipantRoleSelect';
import OfficialButton from '../../components/OfficialButtons';
import './ParticipantInfoForm.scss';
import { isOrgRole, newGuid } from '../../commons/utils';

function getAutoFocusField(props) {
  if (!props.email) {
    return 'email';
  }
  if (!props.firstName) {
    return 'firstName';
  }
  if (!props.lastName) {
    return 'lastName';
  }

  return null;
}

class ParticipantInfoForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.defaultFirstName,
      lastName: props.defaultLastName,
      email: props.defaultEmail,
      selectedRole: props.defaultRole,
      hasChangesForm: false,
      participants: [],
      disableRoleSelect: props.disableRoleSelect,
      showMessagePartner: props.showMessagePartner,
    };
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleOnChangeRole = this.handleOnChangeRole.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleCheckEmailValidForHost = this.handleCheckEmailValidForHost.bind(this);
    this.handleParticipantChange = this.handleParticipantChange.bind(this);
    this.updateParticipantList = this.updateParticipantList.bind(this);

    this.autoFocusField = getAutoFocusField(props);

    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.emailRef = React.createRef();
  }

  isValid() {
    if (this.props.isEdit) {
      const isValidFirstName = this.firstNameRef.current.isValid();
      const isValidLastName = this.lastNameRef.current.isValid();
      const isEmailValid = this.emailRef.current.isValid() && !this.state.errorEmail;
      return isValidFirstName && isValidLastName && isEmailValid;
    } else {
      return this.state.participants.length > 0 && !this.state.errorEmail;
    }
  }

  updateParticipantList(role) {
    if (!this.state.participants?.length) return this.state.participants;
    const newParticipants = this.state.participants.map((item) => {
      return {
        ...item,
        role,
      };
    });
    this.setState({ participants: newParticipants });
    return newParticipants;
  }

  handleCheckEmailValidForHost(email, role, hasPartner = false) {
    let invalid = false;
    let showMessagePartner = hasPartner || this.props.showMessagePartner;
    let disableRoleSelect = hasPartner || this.props.disableRoleSelect;
    let hasAnyPartner = hasPartner || false;
    let newRole = this.state.selectedRole;
    const shouldResetRole = !this.props.canAddHost;
    if (!email || !this.props.orgUsers?.length || (isArray(email) && email.length === 0)) {
      const newState = {
        errorEmail: invalid,
        disableRoleSelect: disableRoleSelect,
        showMessagePartner: showMessagePartner,
      };
      // reset
      if (shouldResetRole) {
        newState.selectedRole = this.props.defaultRole;
        this.updateParticipantList(this.props.defaultRole);
      }
      this.setState(newState);
      return;
    }

    const dictUsers = this.props.orgUsers.reduce((acc, curr) => {
      if (curr.contactEmail?.length > 0) {
        acc[curr.contactEmail.toLowerCase()] = curr;
      }
      return acc;
    }, {});

    if (isString(email) && isValidEmail(email)) {
      const lowerCaseEmail = email.toLowerCase();
      const foundUser = dictUsers[lowerCaseEmail];
      // console.log('### 703 foundUser: ', foundUser);
      if (!foundUser) {
        if (role === ParticipantRole.Host) invalid = true;
      } else {
        hasAnyPartner = isOrgRole(foundUser.role, OrganizationUserRole.Partner);
      }
    } else if (isArray(email)) {
      // check item in array email should have value in orgUsers
      const foundInValidEmail = email.find((item) => {
        const lowerCaseEmail = item?.toLowerCase();
        return !dictUsers[lowerCaseEmail];
      });
      if (foundInValidEmail) {
        if (role === ParticipantRole.Host) invalid = true;
      }
      hasAnyPartner = email.some((item) => {
        const lowerCaseEmail = item?.toLowerCase();
        return isOrgRole(dictUsers[lowerCaseEmail]?.role, OrganizationUserRole.Partner);
      });
    }

    if (hasAnyPartner) {
      invalid = false;
      disableRoleSelect = true;
      showMessagePartner = true;
      newRole = ParticipantRole.Participant;
    } else {
      if (shouldResetRole) {
        // e.g. case 1: public space, select a partner, then remove it. The role should be reset to default
        newRole = this.props.defaultRole;
      }
      // otherwise, keep the selected role and access level
    }

    const newState = {
      errorEmail: invalid,
      disableRoleSelect: disableRoleSelect,
      showMessagePartner: showMessagePartner,
    };
    if (newRole !== this.state.selectedRole) newState.selectedRole = newRole;
    this.setState(newState);
    this.updateParticipantList(newRole);
  }

  handleChangeTextField(name, value) {
    if (!this.state.hasChangesForm) {
      this.setState({ hasChangesForm: true });
    }
    this.setState({ [name]: value });
    if (name === 'email') {
      this.handleCheckEmailValidForHost(value, this.state.selectedRole);
    }
  }

  async onSave() {
    if (!this.isValid()) {
      return false;
    }
    const { firstName, lastName, email, participants } = this.state;
    const editedParticipant = {
      firstName,
      lastName,
      email,
      role: this.state.selectedRole,
    };
    const data = this.props.isEdit ? editedParticipant : participants;

    const rs = await this.props.onSubmit(data);
    if (rs) {
      this.setState({ hasChangesForm: false });
      setTimeout(() => {
        this.setState({
          firstName: '',
          lastName: '',
          email: '',
          participants: [],
        });
        if (this.firstNameRef?.current) {
          this.firstNameRef.current.reset();
        }
        if (this.lastNameRef?.current) {
          this.lastNameRef.current.reset();
        }
        if (this.emailRef?.current) {
          this.emailRef.current.reset();
        }
      }, 100);
    }
    return true;
  }

  handleOnChangeRole(value) {
    const newState = { selectedRole: value };
    let emailCheck = this.state.email;
    if (this.state.participants.length > 0) {
      emailCheck = this.state.participants.map((item) => item.email);
    }
    this.setState(newState, () => {
      this.handleCheckEmailValidForHost(emailCheck, value);
    });
  }

  async handleCloseForm() {
    let isCloseForm = true;
    if (this.state.hasChangesForm) {
      const { isSaved, discard } = await UnsavedChangesDialog.show(
        i18n.t('UNSAVED CHANGES'),
        <>
          {i18n.t(`You have made changes.`)}
          <br />
          {i18n.t(`Would you like to save?`)}
        </>
      );
      isCloseForm = discard;
      if (isSaved) {
        const result = await this.onSave();
        if (result) {
          isCloseForm = true;
        } else {
          return false;
        }
      }
    }
    return isCloseForm;
  }

  validateEmail(value) {
    let error = null;
    const lowerCaseValue = value.toLowerCase();
    if (this.props.isEdit && lowerCaseValue === this.state.email.toLowerCase()) return error;
    const foundParticipant = this.props.participants?.find(
      (user) => user.email?.toLowerCase() === lowerCaseValue
    );
    if (foundParticipant) {
      error = i18n.t('The email address is already taken by another participant.');
    }
    return error;
  }

  handleParticipantChange(value) {
    console.log('### 703 handleParticipantChange:', value, this.state.selectedRole);
    const newParticipants = value?.map((item) => {
      return {
        id: newGuid(),
        email: item.email,
        firstName: item.firstName,
        lastName: item.lastName,
        fullName: `${item.firstName} ${item.lastName}`,
        role: this.state.selectedRole,
        isOrgUser: !!item.role,
      };
    });
    const emails = newParticipants.map((item) => item.email?.toLowerCase());
    const hasPartner = value.find((item) => isOrgRole(item.role, OrganizationUserRole.Partner));

    this.setState({ participants: newParticipants, hasChangesForm: true }, () => {
      this.handleCheckEmailValidForHost(emails, this.state.selectedRole, !!hasPartner);
    });
  }

  render() {
    const { firstName, lastName, email, errorEmail } = this.state;
    const { isEdit, spaceId, canSetRole, canAddHost, isSavingData } = this.props;

    return (
      <>
        <div className="form-body-wrapper participant-form">
          <div className="form-body">
            <Grid container spacing={3} className="participant-form-container">
              {isEdit ? (
                <Grid item xs>
                  <p className="item-label">{i18n.t('Email')}</p>
                  <TextFieldEnhanced
                    autoFocus={this.autoFocusField === 'email'}
                    ref={this.emailRef}
                    required
                    maxLength={200}
                    type="email"
                    name="email"
                    defaultValue={email}
                    disabled={isEdit}
                    onChange={this.handleChangeTextField}
                    variant="outlined"
                    validate={this.validateEmail}
                  />
                </Grid>
              ) : (
                <Grid item sm={12}>
                  <p className="item-label">{i18n.t('Search Email')}</p>

                  <AutocompleteUsers
                    spaceId={spaceId}
                    multiple
                    onChange={this.handleParticipantChange}
                    name="input-contact"
                  />
                </Grid>
              )}
              <Grid item sm={isEdit ? 6 : 12}>
                {canSetRole && (
                  <ParticipantRoleSelect
                    onChange={this.handleOnChangeRole}
                    defaultRole={this.state.selectedRole}
                    disabled={this.state.disableRoleSelect}
                    showTooltip={this.state.disableRoleSelect && !this.state.showMessagePartner}
                    canAddHost={canAddHost}
                  />
                )}
                {errorEmail && (
                  <FormHelperText error>
                    {i18n.t('A user account is required to be added as host in a space.')}
                  </FormHelperText>
                )}

                {this.state.showMessagePartner && (
                  <FormHelperText className="partner-message">
                    {i18n.t(
                      'As a partner, this will be added automatically as a user in the space.'
                    )}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            {isEdit && (
              <Grid container spacing={3} className="participant-edit-names">
                <Grid item xs>
                  <p className="item-label">{i18n.t('First name')}</p>
                  <TextFieldEnhanced
                    autoFocus={this.autoFocusField === 'firstName'}
                    ref={this.firstNameRef}
                    required
                    maxLength={100}
                    name="firstName"
                    defaultValue={firstName}
                    onChange={this.handleChangeTextField}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs>
                  <p className="item-label">{i18n.t('Last name')}</p>
                  <TextFieldEnhanced
                    required
                    autoFocus={this.autoFocusField === 'lastName'}
                    ref={this.lastNameRef}
                    maxLength={100}
                    name="lastName"
                    defaultValue={lastName}
                    onChange={this.handleChangeTextField}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </div>
        <div className="buttons">
          <OfficialButton
            isProcessing={isSavingData}
            variant="regular-green"
            label={i18n.t('Save')}
            onClick={this.onSave}
          />
        </div>
      </>
    );
  }
}

ParticipantInfoForm.propTypes = {
  defaultFirstName: PropTypes.string,
  defaultLastName: PropTypes.string,
  defaultEmail: PropTypes.string,
  defaultRole: PropTypes.number,
  isEdit: PropTypes.bool,
  isSavingData: PropTypes.bool,
  onSubmit: PropTypes.func,
  canSetRole: PropTypes.bool,
  disableRoleSelect: PropTypes.bool,
  participants: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  orgUsers: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  canAddHost: PropTypes.bool,
  spaceId: PropTypes.string,
  showMessagePartner: PropTypes.bool,
};

ParticipantInfoForm.defaultProps = {
  defaultFirstName: null,
  defaultLastName: null,
  defaultEmail: null,
  isSavingData: false,
  isEdit: false,
  canSetRole: false,
  defaultRole: ParticipantRole.Participant,
  disableRoleSelect: false,
  defaultHasChangedForm: false,
  canAddHost: true,
  showMessagePartner: false,
};

export default ParticipantInfoForm;
