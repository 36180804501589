import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { useDebouncedCallback } from 'use-debounce';

const SearchBar = ({
  onChange,
  defaultValue = '',
  placeholder = 'Search',
  autoFocus = true,
  disabled = false,
}) => {
  const handleOnChange = useDebouncedCallback(({ target: input }) => {
    onChange(input.value);
  }, 800);

  return (
    <div className="form-search">
      <TextField
        autoFocus={autoFocus}
        placeholder={placeholder}
        variant="outlined"
        size="small"
        className="input-search"
        fullWidth
        onChange={handleOnChange}
        defaultValue={defaultValue}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

SearchBar.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SearchBar;
