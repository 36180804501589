import React, { useState, useCallback } from 'react';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Checkbox, IconButton, Typography, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

import Loading from '../../components/Loading';
import i18n from '../../i18n';
import './SelectRootFoldersDialog.scss';
import Icons from '../../components/Icons';
import useArrayState from '../../commons/useArrayState';
import { IRootFolderTreeItem } from './SelectedRootFoldersDialog.types';
import OfficialButton from '../../components/OfficialButtons';

type SelectRootFoldersDialogProps<TRootFolder> = {
  onClose?: (() => void) | null;
  onSubmit?: ((payload: TRootFolder[]) => void) | null;
  onRequestSupport?: (() => void) | null;
  isOpen: boolean;
  rootFolders: TRootFolder[] | null;
  isLoading: boolean;
  onExpandItem: (item: TRootFolder) => Promise<TRootFolder[]>;
  disabledItemIds?: string[];
};

const SelectRootFoldersDialog = <TRootFolder extends IRootFolderTreeItem<TRootFolder>>(
  props: SelectRootFoldersDialogProps<TRootFolder>
) => {
  const {
    onClose,
    onSubmit,
    onRequestSupport,
    isOpen,
    rootFolders,
    isLoading,
    onExpandItem,
    disabledItemIds,
  } = props;
  const [isClosing, setIsClosing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    items: selectedFolders,
    addItem: addSelectedFolder,
    removeItem: removeSelectedFolder,
    removeItems: removeSelectedFolders,
  } = useArrayState<TRootFolder>([], (f1, f2) => f1.id === f2.id);

  const {
    items: expandedItemIds,
    addItem: addExpandedItemId,
    removeItem: removeExpandedItemId,
  } = useArrayState<string>();

  const {
    items: fetchingItems,
    addItem: addFetchingItem,
    removeItem: removeFetchingItem,
  } = useArrayState<string>();
  console.log('expandedItemIds: ', expandedItemIds);

  const isItemExpanded = useCallback(
    (item: TRootFolder) => {
      return expandedItemIds.includes(item.id);
    },
    [expandedItemIds]
  );

  // const isItemFetching = (item) => {
  //   return fetchingItems.includes(item.id);
  // };
  function removeSelectedChildrenFolder(childrenFolders: TRootFolder[]) {
    removeSelectedFolders(childrenFolders);
    const children = childrenFolders
      .filter((x) => (x.children?.length || 0) > 0)
      .map((x) => x.children)
      .reduce((prev, current) => prev.concat(current), []);
    if (children.length > 0) {
      removeSelectedChildrenFolder(children);
    }
  }

  function handleToggleFolder(value: TRootFolder) {
    const foundItem = selectedFolders.find((item) => item.id === value.id);
    if (!foundItem) {
      addSelectedFolder(value);
      if (value.children?.length > 0) {
        // if (isItemExpanded(value)) {
        //   removeExpandedItemId(value.id);
        // }
        removeSelectedChildrenFolder(value.children);
      }
      const selectedParent = selectedFolders.find((x) => x.id === value.parent?.id);
      if (selectedParent) {
        removeSelectedFolder(selectedParent);
      }
    } else {
      removeSelectedFolder(value);
    }
  }

  async function handleSave() {
    setIsSaving(true);
    const payload = selectedFolders;
    if (onSubmit) {
      onSubmit(payload);
    }
  }

  async function handleExpandItem(item: TRootFolder) {
    const itemToUpdate = item;
    if (!itemToUpdate.children) {
      addFetchingItem(item.id);
      try {
        const subFolders = await onExpandItem(item);
        if (subFolders?.length > 0) {
          itemToUpdate.children = subFolders;
          itemToUpdate.children.forEach((x) => {
            const childToUpdate = x;
            childToUpdate.parent = itemToUpdate;
          });
        }
        return true;
      } catch (error) {
        console.log('handleExpandItem ERROR', error);
        return false;
      } finally {
        removeFetchingItem(item.id);
      }
    }
    return true;
  }

  function handleOnClickCloseButton() {
    setIsClosing(true);
    if (onClose) {
      onClose();
    }
    setIsSaving(false);
  }

  const handleItemIconClick = async (item: TRootFolder) => {
    if (!isItemExpanded(item)) {
      const result = await handleExpandItem(item);
      if (result) {
        addExpandedItemId(item.id);
      }
    } else {
      removeExpandedItemId(item.id);
    }
  };

  const renderItemIcon = (item: TRootFolder) => {
    if (fetchingItems.includes(item.id)) {
      return <Loading />;
    }
    if (item.itemsCount > 0) {
      if (isItemExpanded(item)) {
        return <ExpandMore />;
      }
      return <ChevronRight />;
    }

    return null;
  };

  const renderTreeItem = (item: TRootFolder) => {
    return (
      <TreeItem
        key={`ex-storage-item-${item.id}`}
        itemId={item.id}
        onClick={() => {
          handleItemIconClick(item);
        }}
        slots={{
          icon: () => renderItemIcon(item),
        }}
        label={
          <Box className="item-content-wrapper">
            <Icons className="icon-folder" />
            <Typography className="item-name">{item.name}</Typography>
            {/* {item.itemsCount && <Typography>{item.itemsCount}</Typography>} */}
            {!disabledItemIds || disabledItemIds.indexOf(item.id) === -1 ? (
              <Checkbox
                edge="start"
                tabIndex={-1}
                onChange={() => handleToggleFolder(item)}
                disableRipple
                checked={selectedFolders.findIndex((x) => x.id === item.id) !== -1}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
              />
            ) : null}
          </Box>
        }
      >
        {item.children?.map((c) => renderTreeItem(c))}
      </TreeItem>
    );
  };

  const renderList = () => {
    if (isLoading) {
      return (
        <Loading
          className="loading-indicator"
          message={i18n.t(
            'Loading your accessible root folders. This may take a while, please be patient...'
          )}
        />
      );
    }
    if (isClosing) {
      return <Loading className="loading-indicator" />;
    }
    return (
      <SimpleTreeView className="styled-scrollbar" expandedItems={expandedItemIds}>
        {rootFolders?.map((value) => renderTreeItem(value))}
      </SimpleTreeView>
    );
  };

  if (!isOpen) {
    return null;
  }
  const supportEmail = 'support@spce.com';

  return (
    <Dialog
      key="ex-storage-select-root-folders-dialog"
      disableEscapeKeyDown
      open
      className="ex-storage-select-root-folders-dialog"
      hideBackdrop={false}
      onClose={handleOnClickCloseButton}
    >
      <DialogTitle>
        <div className="dialog-title-text">Select folders to share within your organization</div>
        {selectedFolders.length >= 0 && (
          <div className="dialog-subtitle-text">{selectedFolders.length} selected</div>
        )}
        <IconButton
          disabled={isClosing}
          aria-label="close"
          className="close-button"
          color="secondary"
          onClick={handleOnClickCloseButton}
        >
          <CloseIcon className="close-icon" />
        </IconButton>
      </DialogTitle>
      <DialogContent className="content">
        <div className="list-container styled-scrollbar">{renderList()}</div>
        <Alert icon={<InfoIcon />} severity="warning" className="information-alert">
          You can select multiple folders to be shared within the organization. All users can see
          and use resources in the folders you share. To add, change or remove folders after
          selection, please contact us at{' '}
          <span
            className="link"
            onClick={() => {
              if (onRequestSupport) {
                onRequestSupport();
              }
            }}
          >
            {supportEmail}
          </span>
          .
        </Alert>
      </DialogContent>
      <DialogActions className="actions">
        <OfficialButton
          label={i18n.t('Confirm')}
          className="btn"
          onClick={handleSave}
          disabled={selectedFolders.length === 0}
          isProcessing={isSaving}
          variant="large-primary"
        />
      </DialogActions>
    </Dialog>
  );
};

export default SelectRootFoldersDialog;
