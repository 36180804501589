import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import './TextAlignment.scss';

const TextAlignment = (props) => {
  const { onChange, alignmentList, isCarousel } = props;
  let defaultValue = props.value === 'middle' ? 'middleCenter' : props.value;

  if (!props.value) {
    defaultValue = 'middleCenter';
  }

  return (
    <Grid className="cms-text-alignment">
      <div className="label">Text alignment</div>
      <div className={`${isCarousel ? 'carousel-alignment' : 'alignment'}`}>
        {alignmentList.map(({ value, icon }, index) => (
          <div
            key={index}
            className={`${isCarousel ? 'carousel-item' : 'item'} ${
              value === defaultValue ? 'active' : ''
            }`}
            onClick={() => onChange(value)}
          >
            <span className={`${icon} icon`}></span>
          </div>
        ))}
      </div>
    </Grid>
  );
};

TextAlignment.propTypes = {
  alignmentList: PropTypes.instanceOf(Array),
  isCarousel: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default TextAlignment;
