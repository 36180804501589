import useMediaQuery from '@mui/material/useMediaQuery';

function useMobileQuery() {
  const isMobilePortrait = useMediaQuery('(max-width: 767px) and (orientation: portrait)');
  const isMobileLandscape = useMediaQuery('(max-width: 900px) and (orientation: landscape)');
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
  const isDesktop = useMediaQuery('(min-width: 1024px) and (max-width: 1279px)');
  const isXDesktop = useMediaQuery('(min-width: 1280px)');
  return {
    isMobile: isMobilePortrait || isMobileLandscape,
    isMobilePortrait,
    isMobileLandscape,
    isTablet,
    isDesktop,
    isXDesktop,
  };
}

export default useMobileQuery;
