/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@mui/material';
import { formatDateTime } from '../../commons/DateTimeUtils';
import LightTooltip from '../LightTooltip';
// import { SubscriptionLockIcon } from '../../commons/Subscription';
// import ScrollIntoView from '../ScrollIntoView';
import Icons from '../Icons';
import { ResourceState } from '../../app/appConstants';
import { getVideoResourceData, getExtensionFromUrl } from '../../commons/ResourceUtils';
import './style.scss';
import i18n from '../../i18n';
import ResourceSelection from '../Material/ResourceSelection/ResourceSelection';
import ResourceThumbnail from '../ResourceThumbnail';
import { useInViewport } from '../../commons/useInViewport';

const ResourceGridItem = React.forwardRef((props, ref) => {
  const {
    resourceId,
    resourceType,
    hasChanges,
    lastModified,
    created,
    isExternal,
    resourceName,
    thumbnail,
    src,
    extension,
    location,
    // resource,
    selected,
    isMobile,
    subscriptionLock,
    // selectedFileId,
    isViewOnly,
    isFolder,
    isNewFile,
    isDisabled,
    isProcessing,
    dimmed,
    indicator,
    onClick,
    onDoubleClick,
    onClickCheckbox,
    onClickMoreButton,
    showCheckbox,
    labelId,
    disableCheckbox,
    numberFileOfFolder,
    state,
    isConvertingFile,
    multiple,
    isPortal,
    // driveId,
    disabledRowMessage,
    provider,
  } = props;
  const refInView = React.useRef();
  const { isVisible } = useInViewport(refInView);
  console.log('isVisible: ', isVisible);
  // if (!resource) return null;
  // const resourceType = resource.resourceType !== undefined ? resource.resourceType : resource.type;

  const renderThumbnail = () => {
    const finalExtension = getExtensionFromUrl(src, extension);
    return (
      <ResourceThumbnail
        resourceId={resourceId}
        thumbnail={thumbnail}
        resourceType={resourceType}
        extension={finalExtension}
        isPortal={isPortal}
        isExternal={isExternal}
        src={src}
        // driveId={driveId}
        inView={isVisible}
        provider={provider}
        resourceName={resourceName}
      />
    );
  };

  function renderProcessingTag() {
    const isBlink =
      isConvertingFile || state === ResourceState.Converting || state === ResourceState.Transcoding;
    const videoResource = getVideoResourceData({ src, extension, type: resourceType, state });

    let text = null;
    if (
      props.isProcessing ||
      isConvertingFile ||
      state === ResourceState.Converting ||
      state === ResourceState.Transcoding
    ) {
      text = i18n.t('Processing');
    } else if (state === ResourceState.ConversionFailed) {
      text = i18n.t('Converting failed');
    } else if (videoResource?.isTranscodingFailed) {
      text = i18n.t('Transcoding failed');
    }
    // text = 'processing...'; // testing
    if (!text) return null;

    return (
      <div className="processing-tag-wrapper">
        <span className={`processing ${isBlink ? 'blink_me' : ''}`}>{text}</span>
      </div>
    );
  }

  function renderItem() {
    return (
      <div
        key={resourceId}
        className={clsx('resource-grid-item', {
          draggable: props.draggable && !isFolder,
          isDragging: props.isDragging,
          'disabled-row': isDisabled || isProcessing || dimmed || subscriptionLock,
          'new-file': isNewFile || hasChanges,
          'mobile-mode': isMobile,
          'folder-row': isFolder,
        })}
        ref={ref}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <section className="thumbnail-section" ref={refInView} data-inview={isVisible}>
          <section className="actions">
            <ResourceSelection
              isShow={!isMobile && !isViewOnly && showCheckbox}
              labelId={labelId}
              selectBoxType={multiple ? 'checkbox' : 'radio'}
              disabled={isProcessing || disableCheckbox}
              checked={selected}
              onChange={onClickCheckbox}
            />
            {props.showActionMenu && (
              <LightTooltip
                title="Go back to the space to edit files."
                disableHoverListener={!props.disabledMoreButton}
                placement="bottom-start"
              >
                <div className="more-button-wrapper">
                  <IconButton
                    disabled={props.disabledMoreButton || isProcessing}
                    className="more-button"
                    size="small"
                    onClick={onClickMoreButton}
                  >
                    <MoreHorizIcon className="icon-more" />
                  </IconButton>
                </div>
              </LightTooltip>
            )}
          </section>
          {isFolder && (
            <section className="img-section">
              <span className={`icon-folder`} />
            </section>
          )}
          {!isFolder && (
            <>
              {renderThumbnail()}
              {renderProcessingTag()}
            </>
          )}
        </section>
        <section className="info" id={labelId}>
          <section className="name" title={resourceName}>
            {resourceName ?? 'Video Message'}
          </section>
          {location && (
            <div className="location text-truncate" title={location}>
              {location}
            </div>
          )}

          <section className="sub">
            {!isMobile && indicator && (
              <section className="left">
                <Icons
                  name={indicator.iconName}
                  className={`resource-indicator ${indicator.indicatorCss}`}
                />
              </section>
            )}
            <section className="right">
              <span className="updated-time">
                {formatDateTime(lastModified || created, 'MMM DD, YYYY')}
              </span>
              {isFolder && numberFileOfFolder && numberFileOfFolder()}
            </section>
          </section>
        </section>
      </div>
    );
  }

  if (disabledRowMessage) {
    return (
      <LightTooltip title={disabledRowMessage} placement="top">
        {renderItem()}
      </LightTooltip>
    );
  }

  return renderItem();
});

ResourceGridItem.propTypes = {
  // newMarkResourceRef: PropTypes.instanceOf(Object),
  // resource: PropTypes.instanceOf(Object),
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasChanges: PropTypes.bool,
  lastModified: PropTypes.string,
  created: PropTypes.string,
  isExternal: PropTypes.bool,
  resourceName: PropTypes.string,
  thumbnail: PropTypes.string,
  src: PropTypes.string,
  extension: PropTypes.string,
  resourceType: PropTypes.number,
  selected: PropTypes.bool,
  isMobile: PropTypes.bool,
  subscriptionLock: PropTypes.bool,
  // selectedFileId: PropTypes.number,
  isViewOnly: PropTypes.bool,
  isFolder: PropTypes.bool,
  isNewFile: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isProcessing: PropTypes.bool,
  dimmed: PropTypes.bool,
  indicator: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onClickCheckbox: PropTypes.func,
  onClickMoreButton: PropTypes.func,
  showActionMenu: PropTypes.bool,
  disabledMoreButton: PropTypes.bool,
  draggable: PropTypes.bool,
  isDragging: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  labelId: PropTypes.string,
  disableCheckbox: PropTypes.bool,
  numberFileOfFolder: PropTypes.func,
  state: PropTypes.string,
  isConvertingFile: PropTypes.bool,
  multiple: PropTypes.bool,
  isPortal: PropTypes.bool,
  // driveId: PropTypes.string,
  disabledRowMessage: PropTypes.string,
  location: PropTypes.string,
  provider: PropTypes.string,
};

ResourceGridItem.defaultProps = {
  showCheckbox: true,
  multiple: true,
  isPortal: false,
};

ResourceGridItem.displayName = 'ResourceGridItem';

export default ResourceGridItem;
