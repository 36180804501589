import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceResourcesByUser } from '../services/SpaceAnalyticsService';
import { ResourceMetricDetails } from '../types/resources';
import useAvailableSpaceResources from '../hooks/useAvailableSpaceResources';

export type UserInteractionResource = ResourceMetricDetails & {
  materialId: number;
  userInfoId: number;
  tag?: string;
};

const useSpaceResourcesByUserQuery = (
  spaceId: string | undefined,
  userInfoId: number,
  notSupportedViewMaterialIds: number[],
  startDate: string,
  endDate: string,
  isPublic: boolean,
  enabled: boolean
) => {
  const { availableMaterialIds, isLoading } = useAvailableSpaceResources();
  const getSpaceResourcesByUserQuery = useQuery({
    queryKey: [
      CacheKeys.spaceAnalytics_FetchSpaceResourcesSummaryByUser,
      spaceId,
      userInfoId,
      endDate,
    ],
    queryFn: async () => {
      if (!spaceId) {
        return [];
      }
      const resp = await fetchSpaceResourcesByUser(
        spaceId,
        userInfoId,
        startDate,
        endDate,
        isPublic,
        availableMaterialIds || [],
        notSupportedViewMaterialIds
      );
      return resp;
    },
    retry: 3,
    retryDelay: () => 5000,
    enabled: !!spaceId && enabled && !!userInfoId && !!startDate && !isLoading,
  });

  const isFetching: boolean = getSpaceResourcesByUserQuery.isFetching;
  const resourcesInteractions: UserInteractionResource[] =
    getSpaceResourcesByUserQuery?.data?.value?.data;
  return { resourcesInteractions, isFetching };
};

export default useSpaceResourcesByUserQuery;
