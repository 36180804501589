import React, { useEffect, useState } from 'react';
import { getGoogleDriveRootFoldersList } from './googleDriveService';
import SelectRootFoldersDialog from '../components/SelectRootFoldersDialog';
import { GoogleDriveTreeItem } from './googleDrive.types';

type GoogleDriveSelectRootFoldersDialogProps = {
  onClose?: (() => void) | null;
  onSubmit?: ((payload: GoogleDriveTreeItem[]) => void) | null;
  onRequestSupport?: (() => void) | null;
  isOpen: boolean;
};

const GoogleDriveSelectRootFoldersDialog = (props: GoogleDriveSelectRootFoldersDialogProps) => {
  const { onClose, onSubmit, onRequestSupport, isOpen } = props;
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchRootFolders() {
      try {
        const rootFolders = await getGoogleDriveRootFoldersList(null, null, true, true);
        setFolders(rootFolders.items);
      } catch (error) {
        console.log('getGoogleDriveFoldersList ERROR ', error);
      } finally {
        setIsLoading(false);
      }
    }
    if (isOpen) fetchRootFolders();
  }, [isOpen]);

  async function handleExpandItem(item: GoogleDriveTreeItem): Promise<GoogleDriveTreeItem[]> {
    try {
      const subFolders = await getGoogleDriveRootFoldersList(item.id, item.driveId, true, true);
      return subFolders?.items;
    } catch (error) {
      console.log('getGoogleDriveFoldersList ERROR', error);
    }

    return [];
  }

  return (
    <SelectRootFoldersDialog
      onClose={onClose}
      onSubmit={onSubmit}
      onRequestSupport={onRequestSupport}
      isOpen={isOpen}
      rootFolders={folders}
      isLoading={isLoading}
      onExpandItem={handleExpandItem}
      disabledItemIds={['sharedWithMe', 'sharedDrives']}
    />
  );
};

export default GoogleDriveSelectRootFoldersDialog;
