import React from 'react';
import PropTypes from 'prop-types';
import DefaultLogo from '../images/spce-logo.png';
import './LogoSpace.scss';
import { replaceBlobUrl } from '../commons/ResourceUtils';

const LogoSpace = ({ src, className, alt }) => {
  const logoSrc = replaceBlobUrl(src);

  return (
    <div className={`logo-space ${className}`}>
      <img src={logoSrc || DefaultLogo} alt={alt || 'SPCE logo'} />
    </div>
  );
};

LogoSpace.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
};

export default LogoSpace;
