import React, { useCallback, useMemo } from 'react';
import { GLOBAL_RESOURCE_TYPE } from '../utils';
import { checkDisabledCheckBox, checkDisabledSelectRow, checkRowSelected } from './utils';
import { RowData, ResourcesDisplayViewProps } from '../context/Interfaces';
import { GRSelectType } from '../GlobalResourcesPicker';
import ResourcesListView from '../ResourcesListView/ResourcesListView';
import { useResourcesViewContext } from '../../components/ResourcesView/ResourcesViewContextProvider';
import ResourcesGridView from '../ResourcesGridView/ResourcesGridView';
import { ViewModeType } from '../../resources/resourceConstants';
import { useGlobalResourcesContext } from '../context/GlobalResourcesContextProvider';

let timer: NodeJS.Timeout;
const delay = 234;
let prevent = false;

function ResourcesDisplayView(props: ResourcesDisplayViewProps) {
  const {
    processingResources,
    className,
    onClickMore,
    onClickRow,
    onDoubleClickRow,
    selectType,
    multiple,
    otherTypeDisabled,
    disabledFolderIds,
    accept,
    rowCount,
    disableSortingColumns,
    onLoadMore,
    hasMore,
    isLoading,
    isPortal,
  } = props;

  const { viewMode, hasSelectColumn, isPicker } = useResourcesViewContext();
  const {
    selectedResources,
    setSelectedResources,
    toogleSelectedResource,
    addSelectedResources,
    removedSelectedResources,
  } = useGlobalResourcesContext();

  const resources = useMemo(() => {
    return props.resources.map((rowData) => {
      const isDisabled = isPicker
        ? checkDisabledSelectRow(rowData, selectType, otherTypeDisabled, disabledFolderIds, accept)
        : false;
      const disabledCheckbox =
        isDisabled ||
        checkDisabledCheckBox(rowData, selectType, hasSelectColumn, processingResources, accept);
      const isSelected = !disabledCheckbox && checkRowSelected(selectedResources, rowData);
      Object.assign(rowData, { isDisabled, disabledCheckbox, isSelected });
      return rowData;
    });
  }, [
    accept,
    disabledFolderIds,
    hasSelectColumn,
    otherTypeDisabled,
    processingResources,
    props.resources,
    selectType,
    selectedResources,
    isPicker,
  ]);

  // const handleSelectionChange = useCallback(
  //   (selectedResources: SelectedItemProps[]) => {
  //     if (onSelectionChange && resources) {
  //       let filtered = _filter(
  //         resources,
  //         (resource) =>
  //           _findIndex(selectedResources, (item) => isResourceEqual(item, resource)) !== -1
  //       );
  //       filtered = filtered.map((item) => ({
  //         ...item,
  //         src: item.orgSrc || item.url,
  //       }));
  //       // if (selectType === GRSelectType.File) {
  //       //   filtered = _filter(filtered, (item) => item.type === GLOBAL_RESOURCE_TYPE.RESOURCE);
  //       // } else if (selectType === GRSelectType.Folder) {
  //       //   filtered = _filter(filtered, (item) => item.type === GLOBAL_RESOURCE_TYPE.FOLDER);
  //       // }
  //       filtered = _sortBy(filtered, (resource) =>
  //         _findIndex(filtered, (item) => isResourceEqual(item, resource))
  //       );
  //       onSelectionChange(filtered);
  //     }
  //   },
  //   [onSelectionChange, resources]
  // );

  const handleSelectItem = useCallback(
    (row: RowData) => {
      if (!row) {
        return;
      }
      if (!multiple) {
        setSelectedResources([row]);
        return;
      }
      toogleSelectedResource(row);
      // setSelected((prevSelected) => {
      //   const selectedIndex = _findIndex(prevSelected, (item) => isResourceEqual(item, resource));
      //   let newSelected: SelectedItemProps[] = [];
      //   if (multiple) {
      //     if (selectedIndex === -1) {
      //       newSelected = newSelected.concat(prevSelected, resource);
      //     } else if (selectedIndex === 0) {
      //       newSelected = newSelected.concat(prevSelected.slice(1));
      //     } else if (selectedIndex === prevSelected.length - 1) {
      //       newSelected = newSelected.concat(prevSelected.slice(0, -1));
      //     } else if (selectedIndex > 0) {
      //       newSelected = newSelected.concat(
      //         prevSelected.slice(0, selectedIndex),
      //         prevSelected.slice(selectedIndex + 1)
      //       );
      //     }
      //   } else {
      //     newSelected = [resource];
      //   }
      //   handleSelectionChange(newSelected);
      //   return newSelected;
      // });
    },
    [multiple, setSelectedResources, toogleSelectedResource]
  );

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newSelecteds = resources;
      if (event.target.checked) {
        if (selectType === GRSelectType.File) {
          newSelecteds = newSelecteds.filter(
            (x) => x.type === GLOBAL_RESOURCE_TYPE.RESOURCE && !x.isDisabled
          );
        } else if (selectType === GRSelectType.Folder) {
          newSelecteds = newSelecteds.filter((x) => x.type === GLOBAL_RESOURCE_TYPE.FOLDER);
        }
        // setSelected(newSelecteds);
        addSelectedResources(newSelecteds);
        return;
      }
      // setSelected([]);
      removedSelectedResources(newSelecteds);
    },
    [addSelectedResources, removedSelectedResources, resources, selectType]
  );

  // const checkDisabledSelectRow = useCallback(
  //   (rowData: RowData) => {
  //     if (rowData.type === GLOBAL_RESOURCE_TYPE.RESOURCE) {
  //       return (
  //         selectType === GRSelectType.Folder ||
  //         !validateResource(accept, rowData) ||
  //         isItemDisabled(
  //           rowData.state,
  //           rowData.resourceType,
  //           rowData.type,
  //           selectType,
  //           otherTypeDisabled,
  //           rowData.extension,
  //           rowData.allowSelectedType
  //         )
  //       );
  //     } else {
  //       return isFolderDisabled(disabledFolderIds, rowData.id);
  //     }
  //   },
  //   [accept, disabledFolderIds, otherTypeDisabled, selectType]
  // );

  // const checkDisabledCheckBox = useCallback(
  //   (rowData: RowData) => disableCheckboxRows.includes(rowData),
  //   [disableCheckboxRows]
  // );

  const handleOnClickItem = useCallback(
    (event: React.MouseEvent<HTMLElement>, rowData: RowData) => {
      event.stopPropagation();
      timer = setTimeout(() => {
        if (!prevent) {
          // console.log('### single clicked');
          if (rowData.isDisabled) {
            return;
          }

          if (rowData.type === GLOBAL_RESOURCE_TYPE.RESOURCE) {
            handleSelectItem(rowData);
          }

          if (onClickRow) {
            onClickRow(rowData);
          }
        }
        prevent = false;
      }, delay);
    },
    [handleSelectItem, onClickRow]
  );

  const handleOnDoubleClickRow = useCallback(
    (event: React.MouseEvent<HTMLElement>, rowData: RowData) => {
      event.stopPropagation();
      event.preventDefault();
      clearTimeout(timer);
      prevent = true;
      if (rowData.isDisabled) {
        return;
      }
      if (onDoubleClickRow) {
        onDoubleClickRow(rowData);
      }
    },
    [onDoubleClickRow]
  );

  // const isSelected = useCallback(
  //   (rowData: RowData) => {
  //     const resource: SelectedItemProps = {
  //       id: rowData.id,
  //       resourceId: rowData.resourceId,
  //       type: rowData.type,
  //     };
  //     return _findIndex(selectedResources, (item) => isResourceEqual(item, resource)) !== -1;
  //   },
  //   [selectedResources]
  // );

  // const isFolderSelected = useCallback(
  //   (folder: SelectedItemProps) => {
  //     return selectedFolder && folder.id === selectedFolder.id;
  //   },
  //   [selectedFolder]
  // );

  const handleOnClickCheckbox = useCallback(
    (checked: boolean, rowData: RowData) => {
      handleSelectItem(rowData);
      // if (selectType === GRSelectType.Folder && !multiple && onClickFolder) {
      //   const selectedResources = _find(resources, (resource) => resource.id === data.id);
      //   if (selectedResources) {
      //     onClickFolder(selectedResources);
      //   }
      // }
    },
    [handleSelectItem]
  );

  if (viewMode === ViewModeType.LIST) {
    return (
      <ResourcesListView
        resources={resources}
        className={className}
        onClickMore={onClickMore}
        selectType={selectType}
        multiple={multiple}
        accept={accept}
        rowCount={rowCount}
        onClickItem={handleOnClickItem}
        onDoubleClickItem={handleOnDoubleClickRow}
        processingResources={processingResources}
        selected={selectedResources}
        handleSelectAllClick={handleSelectAllClick}
        disableSortingColumns={disableSortingColumns}
        hasMore={hasMore}
        onLoadMore={onLoadMore}
        isLoading={isLoading}
        onClickCheckbox={handleOnClickCheckbox}
        isPortal={isPortal}
      />
    );
  }

  // Grid view
  return (
    <ResourcesGridView
      resources={resources}
      className={className}
      onClickMore={onClickMore}
      selectType={selectType}
      multiple={multiple}
      accept={accept}
      rowCount={rowCount}
      onClickItem={handleOnClickItem}
      onDoubleClickItem={handleOnDoubleClickRow}
      processingResources={processingResources}
      selected={selectedResources}
      handleSelectAllClick={handleSelectAllClick}
      disableSortingColumns={disableSortingColumns}
      hasMore={hasMore}
      onLoadMore={onLoadMore}
      isLoading={isLoading}
      onClickCheckbox={handleOnClickCheckbox}
      isPortal={isPortal}
    />
  );
}

ResourcesDisplayView.defaultProps = {
  processingResources: [],
};

export default React.memo(ResourcesDisplayView);
