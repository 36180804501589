import { HubConnection } from '@microsoft/signalr';
import { useEffect } from 'react';

export function useSignalRClientMethod(
  hubConnection: HubConnection | undefined,
  methodName: string,
  method: (...args: unknown[]) => void
) {
  useEffect(() => {
    if (!hubConnection) {
      return undefined;
    }
    hubConnection.on(methodName, method);

    return () => {
      hubConnection.off(methodName, method);
    };
  }, [hubConnection, method, methodName]);
}
