import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import CopyShareLink from '../components/CopyShareLink';
// import ShareSpaceViaEmail from './ShareSpaceViaEmail';
import spaceUser from '../spaces/spaceUser';
import useSpaceAcessInfoQuery from '../spaces/useSpaceAcessInfoQuery';
import useURLSpace from './useURLSpace';
import ShareSpace3rdParties from './ShareSpace3rdParties';
import ShareSettings from '../createSpace/theme/ShareSettings';
import { updateSpace } from './services';

const ShareSpaceInvite = ({ space, onShare, isMobile }) => {
  const spaceId = space.id;
  const { getSpaceAcessInfoQuery } = useSpaceAcessInfoQuery(space.urlSlug ?? spaceId);
  const { getShareLinkSpace } = useURLSpace(space);
  if (!getSpaceAcessInfoQuery.data) {
    return null;
  }
  const isRestrictedSpace = getSpaceAcessInfoQuery.data?.space?.isPrivate === true;
  const isBlockedByFirewall = !!sessionStorage.getItem('isBlockedByFirewall');
  const shareableLink = getShareLinkSpace();

  const hideShareLink = spaceUser.isViewOnly() && !isBlockedByFirewall;

  const onChangeSpace = async (name, spacePrivacy) => {
    console.log('call api to update', spacePrivacy);
    if (spacePrivacy) {
      await updateSpace(spaceId, { spacePrivacy: spacePrivacy });
    }
  };

  return (
    <>
      {!hideShareLink && <CopyShareLink shareableLink={shareableLink} onShare={onShare} />}

      <ShareSpace3rdParties
        spaceId={spaceId}
        shareableLink={shareableLink}
        isRestrictedSpace={isRestrictedSpace}
        spacePrivacy={space.spacePrivacy}
        spaceName={space.name}
        lastEdited={space.lastEdited}
      />

      {spaceUser.isHost() && (
        <>
          <Divider />

          <ShareSettings
            className="invite-settings"
            isTemplate={space.isTemplate}
            spacePrivacy={space.spacePrivacy}
            isMobile={isMobile}
            isEdit
            onChange={onChangeSpace}
          />
        </>
      )}
    </>
  );
};

ShareSpaceInvite.propTypes = {
  space: PropTypes.instanceOf(Object),
  onShare: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default ShareSpaceInvite;
