import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import { getInitials, hasSpecialCharacter } from '../commons/avatarUtils';
import LightTooltip from './LightTooltip';
import './UserAvatar.scss';

const UserAvatar = ({ fullName, imageSrc, children, isVisitor, email, title }) => {
  const [initials, setInitials] = useState(null);
  const titleMessage = title ?? fullName;

  useEffect(() => {
    if (!imageSrc && !children) {
      setInitials(getInitials(fullName && !hasSpecialCharacter(fullName) ? fullName : email));
    }
  }, [imageSrc, fullName, email, children]);

  const renderAvatar = () => {
    if (imageSrc) {
      return <Avatar alt="avatar" src={imageSrc} />;
    }
    if (children) {
      return (
        <Avatar alt="avatar" className="user-avatar">
          {children}
        </Avatar>
      );
    }
    if (isVisitor) {
      return (
        <Avatar alt="avatar" className="user-avatar">
          <i className="icon-participants"></i>
        </Avatar>
      );
    }
    return (
      <Avatar alt="avatar" className={`user-avatar`}>
        <h6>{initials || 'NN'}</h6>
      </Avatar>
    );
  };

  if (!titleMessage) {
    return renderAvatar();
  }

  return (
    <LightTooltip title={titleMessage} placement="top">
      {renderAvatar()}
    </LightTooltip>
  );
};

UserAvatar.propTypes = {
  fullName: PropTypes.string,
  imageSrc: PropTypes.string,
  children: PropTypes.node,
  email: PropTypes.string,
  title: PropTypes.string,
  isVisitor: PropTypes.bool,
};

export default UserAvatar;
