import { useMemo } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchAssessments } from '../services';
import { CacheKeys } from '../../app/queryCache';

function useAssessmentsQuery(keywords = '', enabled = true) {
  const fetchData = ({ pageParam = 0 }) => {
    return fetchAssessments(keywords, pageParam);
  };

  const getNextPageParam = (lastPage, pages) => {
    if (lastPage.hasMore) {
      let numberOfItems = 0;
      pages.forEach((page) => {
        numberOfItems += page.items.length;
      });
      return numberOfItems;
    }
    return undefined;
  };

  const assessmentsQuery = useInfiniteQuery({
    queryKey: [CacheKeys.fetchAssessments, keywords],
    queryFn: fetchData,
    getNextPageParam,
    retry: 2,
    enabled: enabled,
  });

  const assessments = useMemo(() => {
    if (!assessmentsQuery?.data?.pages) return [];
    return assessmentsQuery.data.pages.reduce((m, page) => {
      const { items } = page;
      if (!items) {
        console.error('items is undefined', { page });
        return m;
      }
      return m.concat(items);
    }, []);
  }, [assessmentsQuery.data]);

  return {
    assessments,
    assessmentsQuery,
    isLoading: assessmentsQuery.isLoading,
    refetch: assessmentsQuery.refetch,
  };
}

useAssessmentsQuery.propTypes = {};

export default useAssessmentsQuery;
