import { Divider, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ResponsiveDialog from '../components/ResponsiveDialog';
import { getTheme } from '../theme';
import queryCache from '../app/queryCache';
import i18n from '../i18n';
import CurrentOrganization from '../commons/CurrentOrganization';
import { SERVICE_PROVIDER } from '../integrations/integrationConstants';
import './GlobalResourcesSelectDialog.scss';
import GlobalResourcesPicker, { GRSelectType } from './GlobalResourcesPicker';
import AppProvider from '../components/AppProvider';
import GlobalResourcesContextProvider, {
  GlobalResourcesContextConsumer,
} from './context/GlobalResourcesContextProvider';
import IntegrationContextProvider from '../integrations/IntegrationContextProvider';
import OfficialButton from '../components/OfficialButtons';
import FeatureFlagsContextProvider from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { mapOneDriveItemToResource } from '../integrations/onedrive/utils';
import { mapGoogleDriveItemToResource } from '../integrations/googleDrive/common/utils';
import { isResourceEqual } from './ResourcesDisplayView/utils';
import { GLOBAL_PROVIDER_TYPE } from './utils';

let resolve;
let containerElement;
let rootEl;

class GlobalResourcesSelectDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      selectedTab: GLOBAL_PROVIDER_TYPE.SPCE,
    };
    // Events binding
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleOnChangeTab = this.handleOnChangeTab.bind(this);
  }

  componentDidMount() {
    if (this.props.onOpened) {
      this.props.onOpened();
    }
  }

  componentWillUnmount() {
    resolve = null;
    containerElement = null;
  }

  handleOnChangeTab(newTab) {
    this.setState({ selectedTab: newTab });
  }

  handleOnClose() {
    this.setState({ isOpen: false }, () => {
      GlobalResourcesSelectDialog.destroy();
    });
  }

  handleMappingResources(resources, selectedResources) {
    if (this.props.multiple) {
      return selectedResources;
    }
    const selectedResource = selectedResources[0];
    let foundItem = resources.find((x) => isResourceEqual(x, selectedResource));
    if (foundItem.provider === SERVICE_PROVIDER.ONEDRIVE) {
      foundItem = mapOneDriveItemToResource(foundItem);
    } else if (foundItem.provider === SERVICE_PROVIDER.GOOGLE_DRIVE) {
      foundItem = mapGoogleDriveItemToResource(foundItem);
    }
    return [foundItem];
  }

  handleOnsubmit(resources, selectedResources) {
    this.setState({ isOpen: false }, () => {
      const selectedFolder = selectedResources[0];
      const newSelectedResources = this.handleMappingResources(resources, selectedResources);
      GlobalResourcesSelectDialog.destroy({
        selectedFolder,
        selectedResources: newSelectedResources,
      });
    });
  }

  static destroy(result = false) {
    sessionStorage.removeItem('gr-currentPickerSpceFolderId');
    // console.log('### destroy', containerElement, resolve);
    if (containerElement) {
      try {
        setTimeout(() => {
          rootEl.unmount();
          containerElement = null;
        }, 0);
      } catch (e) {
        console.error(e);
      }
    }
    if (resolve) {
      resolve(result);
    }
  }

  static show(
    multiple,
    accept,
    isPortal = false,
    isSpace = false,
    onOpened = null,
    hideIntegration = false,
    isAdmin = false,
    selectType = GRSelectType.File,
    canUploadFolderFromOneDrive = false,
    canUploadFolderFromGoogleDrive = false,
    disabledUploadExternal = false
  ) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    const organizationId = CurrentOrganization.getOrganizationId();

    rootEl.render(
      <QueryClientProvider client={queryCache}>
        <ThemeProvider theme={getTheme()}>
          <AppProvider isPortal={isPortal} isSpace={isSpace}>
            <FeatureFlagsContextProvider organizationId={organizationId}>
              <IntegrationContextProvider>
                <GlobalResourcesSelectDialog
                  multiple={multiple}
                  accept={accept}
                  onOpened={onOpened}
                  hideIntegration={hideIntegration}
                  isPortal={isPortal}
                  isAdmin={isAdmin}
                  selectType={selectType}
                  canUploadFolderFromOneDrive={canUploadFolderFromOneDrive}
                  canUploadFolderFromGoogleDrive={canUploadFolderFromGoogleDrive}
                  disabledUploadExternal={disabledUploadExternal}
                />
              </IntegrationContextProvider>
            </FeatureFlagsContextProvider>
          </AppProvider>
        </ThemeProvider>
      </QueryClientProvider>
    );

    return new Promise((res) => {
      resolve = res;
    });
  }

  render() {
    const { isOpen, selectedTab } = this.state;
    if (!isOpen) {
      return null;
    }

    return (
      <ResponsiveDialog
        modal
        maxWidth="lg"
        className={`white confirm-dialog global-resources-dialog select-resources-dialog`}
        showCloseIcon
        onClose={this.handleOnClose}
      >
        <div className="content-container">
          <GlobalResourcesContextProvider key={selectedTab} provider={selectedTab}>
            <GlobalResourcesPicker
              multiple={this.props.multiple}
              selectType={this.props.selectType || GRSelectType.File}
              previewDisabled={this.props.selectType === GRSelectType.Folder}
              accept={this.props.accept}
              hideIntegration={this.props.hideIntegration}
              isPortal={this.props.isPortal}
              isAdmin={this.props.isAdmin}
              canUploadFolderFromOneDrive={this.props.canUploadFolderFromOneDrive}
              canUploadFolderFromGoogleDrive={this.props.canUploadFolderFromGoogleDrive}
              disabledUploadExternal={this.props.disabledUploadExternal}
              selectedTab={selectedTab}
              onChangeTab={this.handleOnChangeTab}
            />
            <Divider className="footer-divider" />
            <GlobalResourcesContextConsumer>
              {({ resources, selectedResources }) => (
                <div className="footer-actions">
                  <div className="text-right">
                    <OfficialButton
                      disabled={!selectedResources?.length}
                      onClick={() => this.handleOnsubmit(resources, selectedResources)}
                      label={i18n.t('Select')}
                      variant="regular-primary"
                      className="btn submit-btn"
                    />
                  </div>
                </div>
              )}
            </GlobalResourcesContextConsumer>
          </GlobalResourcesContextProvider>
        </div>
      </ResponsiveDialog>
    );
  }
}

GlobalResourcesSelectDialog.propTypes = {
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  onOpened: PropTypes.func,
  hideIntegration: PropTypes.bool,
  isPortal: PropTypes.bool,
  isAdmin: PropTypes.bool,
  selectType: PropTypes.string,
  canUploadFolderFromOneDrive: PropTypes.bool,
  canUploadFolderFromGoogleDrive: PropTypes.bool,
  disabledUploadExternal: PropTypes.bool,
  // resources: PropTypes.instanceOf(Array),
  // selectedResources: PropTypes.instanceOf(Array),
};

GlobalResourcesSelectDialog.defaultProps = {
  multiple: true,
  hideIntegration: false,
  isPortal: false,
  isAdmin: false,
  canUploadFolderFromOneDrive: false,
  canUploadFolderFromGoogleDrive: false,
  disabledUploadExternal: false,
  // resources: [],
  // selectedResources: [],
};

export default GlobalResourcesSelectDialog;
