import React from 'react';
import PropTypes from 'prop-types';
import FileActionMenu from '../../spaces/Materials/FileActionMenu';
import FolderActionMenu from '../../spaces/Materials/FolderActionMenu';
import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';

const ActionButtons = ({
  currentUserInfoId,
  spaceId,
  flatDirectories,
  selectedFile,
  selectedFolderForActionMenu,
  isHost,
  updateAnchorEl,
  handleOpenFileFormAction,
  handleCloseFileActionMenu,
  handleResourceDownload,
  handleResourceView,
  handleShareLinkFileClick,
  handleOpenFormAction,
  handleCloseFolderActionMenu,
  handleShareLinkFolderClick,
  handleDownloadFolder,
  handleRetryConversion,
  handleMoveResources,
  handleConvertFileToPDF,
  handleToggleCanDownload,
}) => {
  if (selectedFile) {
    let folderId;
    let isExternalFolder = false;
    const foundFolder = flatDirectories?.find((d) => d.id === selectedFile.directoryId);
    let spaceFolderId;
    if (foundFolder) {
      folderId = foundFolder.id;
      isExternalFolder = foundFolder.isExternal;
      spaceFolderId = foundFolder.spaceFolderId > 0 ? foundFolder.spaceFolderId : folderId;
    } else {
      folderId = selectedFile.directoryId;
      spaceFolderId = selectedFile.spaceFolderId;
      isExternalFolder = true;
    }
    return (
      <FileActionMenu
        spaceId={spaceId}
        spaceFolderId={spaceFolderId}
        file={selectedFile}
        anchorEl={updateAnchorEl}
        folderId={folderId}
        isExternalFolder={isExternalFolder}
        onOpenForm={handleOpenFileFormAction}
        onClose={handleCloseFileActionMenu}
        onResourceDownload={handleResourceDownload}
        onResourceView={handleResourceView}
        onClickShareLinkFile={handleShareLinkFileClick}
        onRetryConversion={handleRetryConversion}
        handleMoveResources={handleMoveResources}
        onConvertToPDFClick={handleConvertFileToPDF}
        handleToggleCanDownload={handleToggleCanDownload}
      />
    );
  }
  if (selectedFolderForActionMenu) {
    const { createdBy } = selectedFolderForActionMenu;
    const isUserCreateFolder = createdBy === currentUserInfoId;
    const hideButtons =
      selectedFolderForActionMenu.isExternal &&
      selectedFolderForActionMenu?.parentFolder?.isExternal;

    const subFolderCreatedByOther = selectedFolderForActionMenu?.subFolders?.find(
      (subFolder) => !isHost && subFolder.createdByUserInfoId !== currentUserInfoId
    );

    const filesInSubFolderCreatedByOther = selectedFolderForActionMenu?.spaceResources?.find(
      (file) => !isHost && file.createdByUserInfoId !== currentUserInfoId
    );

    const isShowEditButton = isHost || isUserCreateFolder;
    let isShowDeleteButton =
      (isHost || isUserCreateFolder) &&
      !subFolderCreatedByOther &&
      !filesInSubFolderCreatedByOther &&
      !hideButtons;
    if (selectedFolderForActionMenu?.externalFolderSettings?.provider === SERVICE_PROVIDER.LMS) {
      isShowDeleteButton = false;
    }
    const isShowMoveButton = isHost && handleMoveResources && !hideButtons;
    return (
      <FolderActionMenu
        folder={selectedFolderForActionMenu}
        spaceId={spaceId}
        onClose={handleCloseFolderActionMenu}
        anchorEl={updateAnchorEl}
        onOpenForm={handleOpenFormAction}
        isShowEditButton={isShowEditButton && !selectedFolderForActionMenu.isExternal}
        isShowDeleteButton={isShowDeleteButton}
        onShareLinkFolderClick={handleShareLinkFolderClick}
        handleDownloadFolder={handleDownloadFolder}
        handleMoveResources={isShowMoveButton ? handleMoveResources : null}
      />
    );
  }
  return null;
};

ActionButtons.propTypes = {
  currentUserInfoId: PropTypes.number.isRequired,
  spaceId: PropTypes.string,
  flatDirectories: PropTypes.instanceOf(Array),
  selectedFile: PropTypes.instanceOf(Object),
  selectedFolderForActionMenu: PropTypes.instanceOf(Object),
  isHost: PropTypes.bool.isRequired,
  updateAnchorEl: PropTypes.instanceOf(Object),
  handleOpenFileFormAction: PropTypes.func.isRequired,
  handleCloseFileActionMenu: PropTypes.func.isRequired,
  handleResourceDownload: PropTypes.func,
  handleResourceView: PropTypes.func.isRequired,
  handleShareLinkFileClick: PropTypes.func.isRequired,
  handleOpenFormAction: PropTypes.func.isRequired,
  handleCloseFolderActionMenu: PropTypes.func.isRequired,
  handleShareLinkFolderClick: PropTypes.func.isRequired,
  handleDownloadFolder: PropTypes.func.isRequired,
  handleRetryConversion: PropTypes.func,
  handleMoveResources: PropTypes.func,
  handleConvertFileToPDF: PropTypes.func,
  handleToggleCanDownload: PropTypes.func,
};

export default ActionButtons;
