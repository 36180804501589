import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import GlobalResourceButton from './GlobalResourceButton';
import FormHelper from './FormHelper';
import { getErrorMessage } from './DndFileUploaderUtils';

const UploadButtons = ({
  resources,
  errors,
  multiple,
  isGlobalResourcesEnabled,
  uploadBtnLabel,
  pickFiles,
  filePickerRef,
  isPortal,
  accept,
  onGlobalResourcesDialogOpened,
  hideIntegrationGlobalResource,
  onGlobalResourcesDialogClosed,
  handleResourcesChanged,
  isAdmin,
  disabledUploadExternalFiles,
}) => {
  const [drag, setDrag] = React.useState(false);
  const dragCounter = React.useRef(0);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setDrag(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      pickFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
      dragCounter.current = 0;
    }
  };

  const renderGlobalResourcesButton = () => {
    return (
      <GlobalResourceButton
        resources={resources}
        multiple={multiple}
        accept={accept}
        isPortal={isPortal}
        hideIntegrationGlobalResource={hideIntegrationGlobalResource}
        onGlobalResourcesDialogOpened={onGlobalResourcesDialogOpened}
        onGlobalResourcesDialogClosed={onGlobalResourcesDialogClosed}
        handleResourcesChanged={handleResourcesChanged}
        isAdmin={isAdmin}
        disabledUploadExternalFiles={disabledUploadExternalFiles}
      />
    );
  };

  return (
    <>
      <div className="upload-btns" id="upload-btns">
        {/* <LightTooltip title={i18n.t(fromPCTooltip)} placement="bottom-start"> */}
        <div
          className={`upload-btn ${drag ? 'active' : ''}`}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (!isGlobalResourcesEnabled) filePickerRef?.current?.onClick();
          }}
          onDrop={handleDrop}
          onDragOver={handleDrag}
          onDragEnter={handleDragIn}
          onDragLeave={handleDragOut}
        >
          <p>
            {i18n.t('Drop ')} {multiple ? i18n.t('files') : i18n.t('a file')}
            {isGlobalResourcesEnabled ? i18n.t(' here, ') : i18n.t(' here, or')}
            <br />
            {!isGlobalResourcesEnabled && <a>{i18n.t(uploadBtnLabel)}</a>}
            {isGlobalResourcesEnabled && (
              <>
                <a
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    filePickerRef?.current?.onClick();
                  }}
                >
                  {i18n.t(uploadBtnLabel)}
                </a>
                <br />
                {` ${i18n.t('or')} `}
                {renderGlobalResourcesButton()}
              </>
            )}
          </p>
        </div>
        {/* </LightTooltip> */}
      </div>
      {/* <FormHelper
        show={!!uploadBtnHint}
        helperText={uploadBtnHint}
        className="form-info"
      /> */}
      <FormHelper show={!!errors?.file} helperText={getErrorMessage(errors, 'file', multiple)} />
      <FormHelper show={!!errors?.files} helperText={getErrorMessage(errors, 'files', multiple)} />
    </>
  );
};

UploadButtons.propTypes = {
  errors: PropTypes.instanceOf(Object),
  filePickerRef: PropTypes.instanceOf(Object),
  resources: PropTypes.instanceOf(Array),
  multiple: PropTypes.bool,
  isGlobalResourcesEnabled: PropTypes.bool,
  uploadBtnLabel: PropTypes.string,
  accept: PropTypes.string,
  isPortal: PropTypes.bool,
  hideIntegrationGlobalResource: PropTypes.bool,
  onGlobalResourcesDialogOpened: PropTypes.func,
  onGlobalResourcesDialogClosed: PropTypes.func,
  handleResourcesChanged: PropTypes.func,
  pickFiles: PropTypes.func,
  isAdmin: PropTypes.bool,
  disabledUploadExternalFiles: PropTypes.bool,
};

export default UploadButtons;
