/* eslint-disable react/jsx-props-no-spreading */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import './ResizableDrawer.scss';

const ResizableDrawer = (props) => {
  const { children, setDrawerWidth, drawerWidth, minDrawerWidth, maxDrawerWidth, ...other } = props;

  const handleMouseMove = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const newWidth = e.clientX - document.body.offsetLeft;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  }, []);

  const handleMouseUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  return (
    <Drawer PaperProps={{ style: { width: drawerWidth } }} {...other}>
      <div onMouseDown={(e) => handleMouseDown(e)} className="resize-dragger" />
      {children}
    </Drawer>
  );
};

ResizableDrawer.propTypes = {
  children: PropTypes.instanceOf(Object),
  drawerWidth: PropTypes.number,
  setDrawerWidth: PropTypes.func,
  minDrawerWidth: PropTypes.number,
  maxDrawerWidth: PropTypes.number,
};

ResizableDrawer.defaultProps = {
  minDrawerWidth: 500,
  maxDrawerWidth: 1000,
};

export default ResizableDrawer;
