import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import { getSpaceDirectoryByType } from '../resources/ResourceServices';
import { DirectoryType } from '../app/appConstants';

function useMiscDirectory(spaceId, isPortal = false) {
  const [miscDirectory, setMiscDirectory] = React.useState(null);

  const getSpaceMiscDirectoryQuery = useQuery({
    queryKey: [CacheKeys.getSpaceMiscDirectory, spaceId],
    queryFn: async () => {
      const resp = await getSpaceDirectoryByType(spaceId, DirectoryType.Misc, isPortal);
      // console.log('miscDirectory resp', resp);
      return resp;
    },
    ...{
      retry: 3,
      retryDelay: () => 5000,
      enabled: !!spaceId,
    },
  });

  React.useEffect(() => {
    if (!getSpaceMiscDirectoryQuery.data) {
      return;
    }
    setMiscDirectory(getSpaceMiscDirectoryQuery.data);
  }, [getSpaceMiscDirectoryQuery.data]);

  return [miscDirectory, setMiscDirectory];
}

useMiscDirectory.propTypes = {
  spaceId: PropTypes.string,
};

export default useMiscDirectory;
