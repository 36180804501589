import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Grid } from '@mui/material';
import CardItem from '../../components/CardItem';

const AssessmentCardItem = React.memo((props) => {
  const { assessment, onClickMore, onClick, selected } = props;
  const getDataItems = (item) => {
    const dataItems = [
      {
        icon: <i className="icon-assessments" />,
        text: `${item.noOfQuestions || 0} ${item.noOfQuestions === 1 ? 'Question' : 'Questions'}`,
      },
      // {
      //   icon: <i className="icon-person" />,
      //   text: `${item.usersCount || 0} ${item.usersCount === 1 ? 'User' : 'Users'}`,
      // },
    ];

    return dataItems;
  };

  const handleOnClickMore = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClickMore(event, assessment);
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick(assessment);
    }
  };

  const renderHeaderElement = () => {
    if (selected !== undefined) {
      return <Checkbox className="iconbox iconbox-left" checked={selected} />;
    }
    return null;
  };

  const dataItems = getDataItems(assessment);
  return (
    <Grid item key={assessment.id} xs={2} sm={4} md={3} xl={2}>
      <CardItem
        classes={{ item: selected ? 'active' : '' }}
        key={assessment.id}
        name={assessment.name}
        createdAt={assessment.created}
        lastEdited={assessment.lastUpdated}
        dataItems={dataItems}
        thumbnail={assessment.thumbnailResourceUrl}
        onClick={handleOnClick}
        onClickMore={onClickMore ? handleOnClickMore : null}
        headerElement={renderHeaderElement()}
      />
    </Grid>
  );
});

AssessmentCardItem.propTypes = {
  assessment: PropTypes.instanceOf(Object),
  onClickMore: PropTypes.func,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

AssessmentCardItem.displayName = 'AssessmentCardItem';

export default AssessmentCardItem;
