import { useQuery } from '@tanstack/react-query';
import memoizeOne from 'memoize-one';
import { fetchSpaceAssessments } from '../services';
import { CacheKeys } from '../../app/queryCache';

// sort ids function
const sortIds = memoizeOne((ids) => {
  return ids?.sort();
});

function useSpaceAssessmentsQuery(ids, isPortal, enabled = true) {
  const sortedIds = sortIds(ids);
  const userSpaceAssessments = useQuery({
    queryKey: [CacheKeys.fetchSpaceAssessments, sortedIds, isPortal],
    queryFn: async () => {
      const resp = await fetchSpaceAssessments(sortedIds, isPortal);
      return resp;
    },
    enabled: enabled,
  });

  return {
    userSpaceAssessments,
    isLoading: userSpaceAssessments.isLoading,
    spaceAssessments: userSpaceAssessments.data,
    refetch: userSpaceAssessments.refetch,
  };
}

export default useSpaceAssessmentsQuery;
