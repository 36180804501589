import React from 'react';

import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import LightTooltip from './LightTooltip';
import './Tags.scss';

const getTags = memoizeOne((items) => {
  return items.map((item) => {
    if (typeof item === 'string' && item.length !== 0) {
      return {
        text: item,
        backgroundColor: '#000',
        color: '#fff',
      };
    }
    return item;
  });
});

export const Tag = (props) => {
  const { className, backgroundColor, color, fontFamily, children } = props;
  return (
    <span
      className={`tag ${className}`}
      style={{
        backgroundColor: backgroundColor,
        color: color,
        fontFamily: fontFamily,
      }}
    >
      {children}
    </span>
  );
};

const Tags = (props) => {
  const { items } = props;
  const tags = getTags(items);

  return (
    <span className="tags">
      {tags.map((tag, index) => (
        <Tag
          key={index}
          className="space-tag"
          backgroundColor={tag.backgroundColor || '#f4f6f8'}
          color={tag.color || '#000'}
          fontFamily={tag.fontFamily || 'MediumLLWeb-Bold'}
        >
          {tag.tooltip ? (
            <LightTooltip title={tag.tooltip} placement="top">
              <span>{tag.text}</span>
            </LightTooltip>
          ) : (
            tag.text
          )}
        </Tag>
      ))}
    </span>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Tags.propTypes = {
  items: PropTypes.instanceOf(Array),
};

export default Tags;
