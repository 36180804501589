/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import './NotificationMessages.scss';
import eventBus, { EVENT_BUS } from '../commons/EventBus';

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NotificationMessages extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      messagesQueue: [],
      currentMessage: null,
      duration: 4000,
    };
    this.handleMessageFinished = this.handleMessageFinished.bind(this);
    this.handleOnAddNotificationMessage = this.handleOnAddNotificationMessage.bind(this);
    this.handleRemoveNotificationMessage = this.handleRemoveNotificationMessage.bind(this);
    this.unsubscribes = [];
  }

  componentDidMount() {
    this.unsubscribes.push(
      eventBus.subscribe(EVENT_BUS.AddNotificationMessage, this.handleOnAddNotificationMessage)
    );
    this.unsubscribes.push(
      eventBus.subscribe(EVENT_BUS.RemoveNotificationMessage, this.handleRemoveNotificationMessage)
    );
  }

  componentWillUnmount() {
    this.unsubscribes.forEach((unsubscribe) => unsubscribe());
  }

  handleRemoveNotificationMessage(detail) {
    // console.log('###', event, this.state.currentMessage, this.state.messagesQueue);
    const removingId = detail?.id;
    // if removingId is null, remove the current message.
    // if removingId is not null, the current message's id === removingId, remove the current message.
    if (
      this.state.currentMessage !== null &&
      (!removingId || (!!removingId && this.state.currentMessage.id === removingId))
    ) {
      this.setState({ currentMessage: null });
    }
    if (!!removingId && this.state.messagesQueue.length > 0) {
      const messagesQueue = this.state.messagesQueue.slice();
      const newQueue = messagesQueue.filter((x) => x.id !== removingId);
      this.setState({ messagesQueue: newQueue });
    }
  }

  handleOnAddNotificationMessage(detail) {
    // console.log('handleOnAddNotificationMessage', event);
    if (this.state.messagesQueue.length > 10) {
      console.log('ERROR: Too many messages in queue!', this.state.messagesQueue);
      return;
    }
    const messagesQueue = this.state.messagesQueue.slice();
    // console.log('###', messagesQueue, event);
    const { isCustom, message, title, id, content, options } = detail;
    const duration = options.duration === null ? null : options.duration || 4000;

    const type = options.type || 'info';
    if (isCustom) {
      messagesQueue.push({
        content,
        duration,
        isCustom,
        type,
        id,
      });
    } else {
      messagesQueue.push({
        message,
        duration,
        type,
        title,
        id,
      });
    }

    // event.detail.forEach(msg => messagesQueue.push(msg));
    if (this.state.currentMessage === null) {
      const currentMessage = messagesQueue.shift();
      this.setState({ messagesQueue, currentMessage, duration, isCustom });
    } else {
      this.setState({ messagesQueue });
    }
  }

  handleMessageFinished(event, reason) {
    if (event) {
      event.stopPropagation();
    }

    // don't dismiss notification when user clicks on the screen
    if (reason === 'clickaway') {
      return;
    }
    const messagesQueue = this.state.messagesQueue.slice();
    const currentMessage = messagesQueue.shift();
    this.setState({
      messagesQueue,
      currentMessage: currentMessage === undefined ? null : currentMessage,
    });
  }

  // renderMessage(message) {
  //   return <div key={`message_${this.state.activeMessageIndex}`}>{message}</div>;
  // }

  renderContent() {
    const { currentMessage } = this.state;
    const iconSuccess = (
      <span className="icon-correct">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    );
    const iconInfo = (
      <span className="icon-info-blue">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    );
    const iconError = (
      <span className="icon-white-error">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    );
    const iconSpinner = <span className="icon-spinner"></span>;
    const { message, type, title, content, isCustom } = currentMessage;
    let iconOption = null;
    let severity = type;
    switch (type) {
      case 'success':
        iconOption = iconSuccess;
        break;
      case 'error':
        iconOption = iconError;
        break;
      case 'info':
        iconOption = iconInfo;
        break;
      case 'processing':
        iconOption = iconSpinner;
        severity = 'success';
        break;
      default:
        break;
    }
    return (
      <Alert
        key={`message_${this.state.activeMessageIndex}`}
        onClose={this.handleMessageFinished}
        severity={severity}
        icon={iconOption}
      >
        {isCustom ? (
          content
        ) : (
          <>
            {title ? <AlertTitle>{title}</AlertTitle> : null}
            {message}
          </>
        )}
      </Alert>
    );
  }

  render() {
    const { currentMessage, duration } = this.state;
    if (!currentMessage || currentMessage?.message === 'SubscriptionQuotaReached') {
      return null;
    }
    return (
      <Snackbar
        key={currentMessage?.id}
        open={currentMessage !== null}
        autoHideDuration={duration}
        onClose={this.handleMessageFinished}
        className="notification-mes"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <span>{this.renderContent()}</span>
      </Snackbar>
    );
  }
}

NotificationMessages.propTypes = {};

export default NotificationMessages;
