import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Paper, TextField } from '@mui/material';

import memoizeOne from 'memoize-one';
import { useDebouncedCallback } from 'use-debounce';
import _sortBy from 'lodash/sortBy';
import queryCache, { CacheKeys } from '../../../app/queryCache';
import { fontsWithVariants } from './LoadFonts';
import './FontsSelect.scss';
import { removeFontNameExtension } from './utils';
import { systemFonts, FontVariantsDictionary } from '../../../app/appConstants';
import useMyFontListQuery from './MyFontListQuery';

const getOptions = memoizeOne((fonts) => {
  const options = fontsWithVariants(fonts);
  const sortedOptions = options.sort((a, b) => {
    const sortStringA = (a.groupName.toUpperCase() === 'MY FONTS' ? 0 : 1) + a.name.toUpperCase();
    const sortStringB = (b.groupName.toUpperCase() === 'MY FONTS' ? 0 : 1) + b.name.toUpperCase();
    if (sortStringA < sortStringB) {
      return -1;
    }
    if (sortStringA > sortStringB) {
      return 1;
    }
    return 0;
  });
  return sortedOptions;
});

const filteredOptions = memoizeOne((fonts, keywords, value) => {
  let options = [...fonts];

  if (!!value && typeof value === 'object' && keywords !== value.name) {
    options = fonts.filter((o) => o.name.toLowerCase().includes(keywords.toLowerCase()));
  }

  if (!!value && typeof value === 'string' && keywords !== value) {
    options = fonts.filter((font) => font.name.toLowerCase().includes(keywords.toLowerCase()));
  }

  if (!keywords) {
    options = fonts;
  }

  const sortedOptions = options.sort((a, b) => {
    const sortStringA = (a.groupName.toUpperCase() === 'MY FONTS' ? 0 : 1) + a.name.toUpperCase();
    const sortStringB = (b.groupName.toUpperCase() === 'MY FONTS' ? 0 : 1) + b.name.toUpperCase();
    if (sortStringA < sortStringB) {
      return -1;
    }
    if (sortStringA > sortStringB) {
      return 1;
    }
    return 0;
  });
  return sortedOptions;
});

const getMyFontsOption = memoizeOne((fonts) => {
  const newFonts = [];

  if (fonts.length) {
    fonts.forEach((font) => {
      const fontName = font.resourceName
        ? removeFontNameExtension(font.resourceName)
        : removeFontNameExtension(font.name);
      newFonts.push({
        id: fontName,
        name: fontName,
        groupName: 'My fonts',
        fontFamily: fontName,
        variants: [],
      });
    });
  }

  return _sortBy(newFonts, (item) => item.name);
});

const getDefaultValue = memoizeOne((defaultValue) => {
  let returnValue = defaultValue;
  if (defaultValue?.includes(':')) {
    const fontFamily = defaultValue.split(':');
    if (FontVariantsDictionary[fontFamily[1]]) {
      const variantDetails = FontVariantsDictionary[fontFamily[1]].split(':');
      returnValue = fontFamily[0].concat(variantDetails[0]);
    }
  }
  return returnValue;
});

const FontsSelect = (props) => {
  const { defaultValue, onChange, label, name } = props;
  const [value, setValue] = useState(getDefaultValue(defaultValue));
  const fonts = queryCache.getQueryData([CacheKeys.requestGoogleFontsList]);
  const { myFonts } = useMyFontListQuery(true, false);
  const [inputValue, setInputValue] = useState(''); // for filter purpose
  const [fullFonts, setFullFonts] = useState([]);

  // useEffect(() => {
  //   // console.log('### FontSelect useEffect: ', defaultValue);
  //   const finalValue = getDefaultValue(defaultValue);
  //   setValue(finalValue);
  // }, [defaultValue]);

  function handleOnChange(event, newValue) {
    if (newValue) {
      setValue(newValue.name);
    }
    if (onChange) {
      onChange(newValue?.variant || newValue?.fontFamily);
    }
  }

  useEffect(() => {
    // console.log('### 113 FontSelect useEffect: ', myFonts, fonts);
    if (fonts && myFonts) {
      const myFontsData = getMyFontsOption(myFonts);
      setFullFonts([...myFontsData, ...systemFonts, ...fonts]);
    }
  }, [fonts, myFonts]);

  function handleInputChanged(event, newValue) {
    setInputValue(newValue);
  }

  const renderPaperComponent = ({ children }) => {
    return (
      <Paper>
        <div className="options-container">{children}</div>
      </Paper>
    );
  };

  const handleInputChangedDebounced = useDebouncedCallback((e, v) => handleInputChanged(e, v), 300);

  if (!fonts?.length) {
    return null;
  }

  return (
    <div className="fonts-select">
      <label className="label">{label}</label>
      <Autocomplete
        id="fonts-select-autocomplete"
        options={getOptions(fullFonts)}
        onChange={handleOnChange}
        value={value}
        disableClearable
        getOptionLabel={(option) => {
          if (option.name) {
            return option.name;
          }
          if (typeof option === 'string') {
            return option;
          }
          return option.name;
        }}
        isOptionEqualToValue={(option, val) => {
          if (typeof val === 'string') {
            return option.name === val;
          }
          return option.name === val.name;
        }}
        className="fonts-select-autocomplete"
        classes={{ popper: 'fonts-select-autocomplete-popper' }}
        groupBy={(option) => option.groupName}
        renderOption={(optionProps, option) => {
          return option?.name ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div style={{ fontFamily: option?.fontFamily, ...option?.styles }} {...optionProps}>
              {option?.name}
            </div>
          ) : null;
        }}
        onInputChange={handleInputChangedDebounced}
        filterOptions={() => filteredOptions(fontsWithVariants(fullFonts), inputValue, value)}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            name={name} // must have the name & autocomplete=new-password to disable autocomplete and autofill
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
            className="fonts-select-control-input"
          />
        )}
        PaperComponent={renderPaperComponent}
      />
    </div>
  );
};

FontsSelect.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default FontsSelect;
