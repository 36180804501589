import React from 'react';
import { Box } from '@mui/material';
import i18n from '../i18n';
import Icons from '../components/Icons';
import LightTooltip from '../components/LightTooltip';
import { SpaceActiveStateMessages, SpaceActiveType } from '../homepage/spaceConstants';

type SpaceActivityBoxProps = {
  activeType: string;
  totalActions: number;
  toolTip: string;
};

function SpaceActivityBox({ activeType, totalActions, toolTip }: SpaceActivityBoxProps) {
  const messages: { [key: string]: string } = SpaceActiveStateMessages;

  const toolTipMessage = messages[activeType as keyof typeof messages];
  const kClass = SpaceActiveType[activeType as keyof typeof SpaceActiveType];

  return (
    <>
      <LightTooltip title="The metric excludes internal users.">
        <div className={`active-box ${kClass}`}>
          <div className="outer-circle">
            <div className="inner-circle"></div>
          </div>
          <span>{toolTipMessage}</span>
        </div>
      </LightTooltip>
      <Box className="actions-box">
        <div className="total-space-actions">{totalActions}</div>
        <div className="actions">
          {i18n.t('Actions')}
          <LightTooltip title={toolTip} disableHoverListener={!toolTip}>
            <div className="icon">
              <Icons name="icon-new-info" />
            </div>
          </LightTooltip>
        </div>
      </Box>
    </>
  );
}

export default SpaceActivityBox;
