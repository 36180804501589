import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n';
import { getMaterialResource, getResourceForViewer } from './ResourceServices';
import './DownloadResourceView.scss';
import { ResourceType } from '../app/appConstants';
import ButtonEnhanced from '../components/ButtonEnhanced';
import { getExtensionFromFileName, getExtensionFromUrl } from '../commons/ResourceUtils';
import Loading from '../components/Loading';

const DownloadResourceView = (props) => {
  const { isLoading, onDownloaded, resourceId, name, isPortal, materialId, spaceId } = props;
  const [isProcessing, setIsProcessing] = React.useState(false);

  async function handleDownloadFile() {
    setIsProcessing(true);
    let downloadFileName = name;
    let finalUrl = props.url;

    const extension = getExtensionFromFileName(name);
    if (extension === '') {
      downloadFileName = `${downloadFileName}.${getExtensionFromUrl(finalUrl)}`;
    }

    if (!finalUrl) {
      if (resourceId) {
        // need to fetch the url with token for downloading
        let resp;
        if (materialId > 0) {
          resp = await getMaterialResource(spaceId, materialId, isPortal);
        } else {
          resp = await getResourceForViewer(resourceId, isPortal);
        }
        if (resp) {
          if (resp.resourceType === ResourceType.video) {
            finalUrl = resp.orgSrc;
          } else {
            finalUrl = resp.url;
          }
        }
      }
    }

    const link = document.createElement('a');
    link.target = '_blank';
    link.download = downloadFileName;
    link.href = finalUrl;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (onDownloaded) onDownloaded();

    setIsProcessing(false);
  }

  function renderBody() {
    return (
      <div className="text-content">
        <span className="text-content-message">
          {i18n.t(
            "This file can't be viewed. Download to open locally with the relevant application."
          )}
        </span>
        <div className="info">
          <span className="name text-truncate">{name}</span>
          {props.fileSize && (
            <>
              <div className="dot">
                <span></span>
              </div>
              <span className="size">{props.fileSize}</span>
            </>
          )}
        </div>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="download-resource-view-container">
      <div className="content">
        <div className="icon">
          <span className="icon-no-preview">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
        </div>
        {renderBody()}
      </div>
      <div className="buttons">
        <ButtonEnhanced
          disabled={isLoading}
          onClick={handleDownloadFile}
          className="large-button button"
          size="large"
          fullWidth
          color="secondary"
          isProcessing={isProcessing}
        >
          {i18n.t('download')}
        </ButtonEnhanced>
      </div>
    </div>
  );
};

DownloadResourceView.propTypes = {
  onDownloaded: PropTypes.func,
  name: PropTypes.string,
  resourceId: PropTypes.number,
  url: PropTypes.string,
  isPortal: PropTypes.bool,
  materialId: PropTypes.number,
  spaceId: PropTypes.string,
  isLoading: PropTypes.bool,
  fileSize: PropTypes.string,
};

export default DownloadResourceView;
