import React from 'react';
import PropTypes from 'prop-types';

function FormHelper(props) {
  if (!props?.show) {
    return null;
  }
  return <div className={props.className}>{props?.helperText}</div>;
}

FormHelper.propTypes = {
  show: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
};

FormHelper.defaultProps = {
  className: 'form-warning',
};

export default FormHelper;
