import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import { Ids } from '../commons/pendoTaggings';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../app/appConstants';

export function TermsAndConditionData(props) {
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledTC] = checkEnabledFeatures([FeatureFlagsType.TC]);
  const { handleConfirmSpceTC, termsAndConditions, handleConfirmCustomerTC, organizationName } =
    props;

  return (
    <div className="policy">
      {termsAndConditions?.src && isEnabledTC && (
        <div className="customer-terms">
          <label htmlFor="customer-tc" className="terms-label">
            <Checkbox
              id="customer-tc"
              onChange={(event) => {
                handleConfirmCustomerTC(event.target.checked);
              }}
              className="terms"
              data-id={Ids.CustomerTAndC}
            />
            I have read and agree to {organizationName}{' '}
            <a
              className="spce-link"
              target="_blank"
              rel="noreferrer"
              href={termsAndConditions?.src}
            >
              terms and conditions
            </a>
          </label>
        </div>
      )}

      <div className="spce-terms">
        <label htmlFor="spce-tc" className="terms-label">
          <Checkbox
            id="spce-tc"
            onChange={(event) => {
              handleConfirmSpceTC(event.target.checked);
            }}
            className="terms"
            data-id={Ids.SpaceTAndC}
          />
          I have read and agree to SP_CE{' '}
          <a
            className="spce-link"
            href="https://www.spce.com/termsofservice/"
            target="_blank"
            rel="noreferrer"
          >
            terms and conditions
          </a>
        </label>
      </div>
      {props.showError && (
        <div className="error">Agree to the terms and conditions to proceed.</div>
      )}
      <div className="privacy-policy">
        SP_CE will process your personal data as described in the{' '}
        <a
          className="spce-link"
          href="https://www.spce.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
          data-id={Ids.PrivacyPolicyLink}
        >
          privacy policy
        </a>
        .
      </div>
    </div>
  );
}

TermsAndConditionData.propTypes = {
  termsAndConditions: PropTypes.instanceOf(Object),
  handleConfirmSpceTC: PropTypes.func,
  handleConfirmCustomerTC: PropTypes.func,
  showError: PropTypes.bool,
  organizationName: PropTypes.string,
};
