import React from 'react';

const useArrayState = (defaultItems = [], isEqualFunc = null) => {
  const [items, setItems] = React.useState(defaultItems);

  const isEqual = React.useCallback(
    (a, b) => {
      if (!isEqualFunc) {
        return a === b;
      }
      return isEqualFunc(a, b);
    },
    [isEqualFunc]
  );

  const toggleItem = React.useCallback(
    (item) => {
      setItems((prevItems) => {
        if (prevItems.findIndex((e) => isEqual(e, item)) >= 0) {
          let newItems = prevItems.slice();
          newItems = newItems.filter((e) => !isEqual(e, item));
          return newItems;
        }
        const newItems = prevItems.slice();
        newItems.push(item);
        return newItems;
      });
    },
    [isEqual]
  );

  const addItem = React.useCallback(
    (item, isPush = true) => {
      setItems((prevItems) => {
        if (prevItems.findIndex((e) => isEqual(e, item)) >= 0) {
          return prevItems;
        }
        const newItems = prevItems.slice();
        if (isPush) {
          newItems.push(item);
        } else {
          newItems.unshift(item);
        }
        return newItems;
      });
    },
    [isEqual]
  );

  const removeItem = React.useCallback(
    (item) => {
      setItems((prevItems) => {
        let newItems = prevItems.slice();
        newItems = newItems.filter((e) => !isEqual(e, item));
        return newItems;
      });
    },
    [isEqual]
  );

  const removeItems = React.useCallback(
    (array) => {
      setItems((prevItems) => {
        let newItems = prevItems.slice();
        newItems = newItems.filter((item) => array.findIndex((e) => isEqual(e, item)) < 0);
        return newItems;
      });
    },
    [isEqual]
  );

  const clear = React.useCallback(() => {
    setItems([]);
  }, []);

  const updateItem = React.useCallback(
    (item) => {
      setItems((prevItems) => {
        const foundIndex = prevItems.findIndex((e) => isEqual(e, item));

        if (foundIndex >= 0) {
          const newItems = prevItems.slice();
          newItems[foundIndex] = item;
          return newItems;
        }

        return prevItems;
      });
    },
    [isEqual]
  );

  return { items, addItem, removeItem, toggleItem, removeItems, clear, updateItem };
};

export default useArrayState;
