import React from 'react';

export interface IAppContext {
  isPortal: boolean;
  isAuthenticated: boolean;
  isMobile: boolean;
}

export const AppContext = React.createContext<IAppContext>({
  isPortal: false,
  isAuthenticated: false,
  isMobile: false,
});
AppContext.displayName = 'AppContext';

export const useAppContext = () => React.useContext(AppContext);
