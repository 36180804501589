import _cloneDeep from 'lodash/cloneDeep';
import { getDefaultDesignSettingsByTypeAndTextSize, updateBlockProp } from './functions';
import { CMS_COMPONENT_STYLE, CMS_DEFAULT_VALUE } from '../../cmsConstants';

const updateVideoBlockProps = (space, block, value) => {
  // block is the old block, value is the new value
  let newBlock = _cloneDeep(block);

  let firstItem = block.items?.[0];
  const oldDesignSettings = getDefaultDesignSettingsByTypeAndTextSize(
    space?.theme,
    firstItem?.type,
    firstItem?.textSize,
    block.layout
  );

  const videoLayout = value;
  const toBeside = value === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.BESIDE;
  const toUnder = value === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.UNDER;

  if (block.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.BESIDE) {
    // beside
    if (toBeside) {
      // beside -> beside // do nothing
    } else if (toUnder) {
      // beside -> under
      const newTextPosition = CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM; // under
      newBlock = updateBlockProp(newBlock, 'textPosition', newTextPosition);

      const newTextAlign = CMS_COMPONENT_STYLE.TEXT_ALIGN.CENTER;
      newBlock = updateBlockProp(newBlock, 'textAlign', newTextAlign);
      const sizeText =
        newBlock.size === CMS_COMPONENT_STYLE.SIZE.SMALL
          ? CMS_COMPONENT_STYLE.SIZE.MEDIUM
          : CMS_COMPONENT_STYLE.SIZE.LARGE;
      newBlock = updateBlockProp(newBlock, 'textSize', sizeText);
    } else {
      // beside -> overlay
      const newTextPosition = CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_CENTER;
      newBlock = updateBlockProp(newBlock, 'textPosition', newTextPosition);
      const sizeText =
        newBlock.size === CMS_COMPONENT_STYLE.SIZE.SMALL
          ? CMS_COMPONENT_STYLE.SIZE.MEDIUM
          : CMS_COMPONENT_STYLE.SIZE.LARGE;
      newBlock = updateBlockProp(newBlock, 'textSize', sizeText);

      firstItem = newBlock.items?.[0];
      const designSettings = getDefaultDesignSettingsByTypeAndTextSize(
        space?.theme,
        firstItem?.type,
        firstItem?.textSize,
        videoLayout
      );

      newBlock.items = newBlock.items.map((blockItem) => {
        const newBlockItem = _cloneDeep(blockItem);
        // // heading
        // if (
        //   !newBlockItem.headingColor ||
        //   newBlockItem.headingColor === oldDesignSettings.headingColor
        // ) {
        //   newBlockItem.headingColor = designSettings.headingColor;
        // }

        // if (!newBlockItem.headingFontFamily) {
        //   newBlockItem.headingFontFamily = designSettings.headingFontFamily;
        // }

        // if (!newBlockItem.headingFontSize) {
        //   console.log('newBlockItem1', newBlockItem);
        //   newBlockItem.headingFontSize = designSettings.headingFontSize;
        // }

        // // description
        // if (
        //   !newBlockItem.descriptionColor ||
        //   newBlockItem.descriptionColor === oldDesignSettings.descriptionColor
        // ) {
        //   newBlockItem.descriptionColor = designSettings.descriptionColor;
        // }

        // if (!newBlockItem.descriptionFontFamily) {
        //   newBlockItem.descriptionFontFamily = designSettings.descriptionFontFamily;
        // }

        if (!newBlockItem.descriptionFontSize) {
          newBlockItem.descriptionFontSize = designSettings.descriptionFontSize;
        }

        return newBlockItem;
      });
    }
  } else if (block.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.UNDER) {
    // under
    if (toBeside) {
      // under -> beside
      newBlock.size = CMS_COMPONENT_STYLE.SIZE.FIXEDWIDTH;

      const newTextPosition = CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT; // beside
      newBlock = updateBlockProp(newBlock, 'textPosition', newTextPosition);

      const noColumns = newBlock.items?.length || 0;
      if (noColumns > 1) {
        newBlock.items.splice(1);
        newBlock.columns = 1;
      }

      const sizeText = CMS_COMPONENT_STYLE.SIZE.MEDIUM;
      newBlock = updateBlockProp(newBlock, 'textSize', sizeText);

      firstItem = newBlock.items?.[0];
      const designSettings = getDefaultDesignSettingsByTypeAndTextSize(
        space?.theme,
        firstItem?.type,
        firstItem?.textSize,
        videoLayout
      );

      newBlock.items = newBlock.items.map((blockItem) => {
        const newBlockItem = _cloneDeep(blockItem);

        // heading
        // if (
        //   !newBlockItem.headingColor ||
        //   newBlockItem.headingColor === oldDesignSettings.headingColor
        // ) {
        //   newBlockItem.headingColor = designSettings.headingColor;
        // }

        // if (!newBlockItem.headingFontFamily) {
        //   newBlockItem.headingFontFamily = designSettings.headingFontFamily;
        // }

        // if (
        //   !newBlockItem.headingFontSize ||
        //   newBlockItem.headingFontSize === oldDesignSettings.headingFontSize
        // ) {
        //   console.log('newBlockItem1', newBlockItem);
        //   newBlockItem.headingFontSize = designSettings.headingFontSize;
        // }

        // description
        // if (
        //   !newBlockItem.descriptionColor ||
        //   newBlockItem.descriptionColor === oldDesignSettings.descriptionColor
        // ) {
        //   newBlockItem.descriptionColor = designSettings.descriptionColor;
        // }

        // if (!newBlockItem.descriptionFontFamily) {
        //   newBlockItem.descriptionFontFamily = designSettings.descriptionFontFamily;
        // }

        if (
          !newBlockItem.descriptionFontSize ||
          newBlockItem.descriptionFontSize === oldDesignSettings.descriptionFontSize
        ) {
          newBlockItem.descriptionFontSize = designSettings.descriptionFontSize;
        }

        return newBlockItem;
      });
    } else if (toUnder) {
      // under -> under // do nothing
    } else {
      // under -> overlay
      const newTextPosition = CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_CENTER;
      newBlock = updateBlockProp(newBlock, 'textPosition', newTextPosition);
      const sizeText =
        newBlock.size === CMS_COMPONENT_STYLE.SIZE.MEDIUM
          ? CMS_COMPONENT_STYLE.SIZE.MEDIUM
          : CMS_COMPONENT_STYLE.SIZE.LARGE;
      newBlock = updateBlockProp(newBlock, 'textSize', sizeText);

      firstItem = newBlock.items?.[0];
      const designSettings = getDefaultDesignSettingsByTypeAndTextSize(
        space?.theme,
        firstItem?.type,
        firstItem?.textSize,
        videoLayout
      );

      const noColumns = newBlock.items?.length || 0;

      newBlock.items = newBlock.items.map((blockItem) => {
        const newBlockItem = _cloneDeep(blockItem);

        // heading
        // if (
        //   !newBlockItem.headingColor ||
        //   newBlockItem.headingColor === oldDesignSettings.headingColor
        // ) {
        //   newBlockItem.headingColor = designSettings.headingColor;
        // }

        // if (!newBlockItem.headingFontFamily) {
        //   newBlockItem.headingFontFamily = designSettings.headingFontFamily;
        // }

        // if (!newBlockItem.headingFontSize) {
        //   newBlockItem.headingFontSize = designSettings.headingFontSize;
        // }

        // description
        if (noColumns >= 3) {
          newBlockItem.description = null; // rule dont show description if video is overlay with >= 3 columns
        }

        // if (
        //   !newBlockItem.descriptionColor ||
        //   newBlockItem.descriptionColor === oldDesignSettings.descriptionColor
        // ) {
        //   newBlockItem.descriptionColor = designSettings.descriptionColor;
        // }

        // if (!newBlockItem.descriptionFontFamily) {
        //   newBlockItem.descriptionFontFamily = designSettings.descriptionFontFamily;
        // }

        if (!newBlockItem.descriptionFontSize) {
          newBlockItem.descriptionFontSize = designSettings.descriptionFontSize;
        }

        return newBlockItem;
      });
    }
  } else {
    // overlay
    if (toBeside || toUnder) {
      // overlay -> beside, under

      let newTextPosition = CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM; // under

      let sizeText =
        newBlock.size === CMS_COMPONENT_STYLE.SIZE.MEDIUM
          ? CMS_COMPONENT_STYLE.SIZE.MEDIUM
          : CMS_COMPONENT_STYLE.SIZE.LARGE;
      if (toBeside) sizeText = CMS_COMPONENT_STYLE.SIZE.MEDIUM;
      newBlock = updateBlockProp(newBlock, 'textSize', sizeText);

      if (toBeside) {
        newBlock.size = CMS_COMPONENT_STYLE.SIZE.FIXEDWIDTH;
        newTextPosition = CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT;

        const noColumns = newBlock.items?.length || 0;
        if (noColumns > 1) {
          newBlock.items.splice(1);
          newBlock.columns = 1;
        }
      }

      firstItem = newBlock.items?.[0];
      const designSettings = getDefaultDesignSettingsByTypeAndTextSize(
        space?.theme,
        firstItem?.type,
        firstItem?.textSize,
        videoLayout
      );

      const newTextAlign = CMS_COMPONENT_STYLE.TEXT_ALIGN.CENTER;

      newBlock.items = newBlock.items.map((blockItem) => {
        const newBlockItem = _cloneDeep(blockItem);

        newBlockItem.textPosition = newTextPosition;
        newBlockItem.textAlign = newTextAlign;

        // heading
        // if (
        //   !newBlockItem.headingColor ||
        //   newBlockItem.headingColor === oldDesignSettings.headingColor
        // ) {
        //   newBlockItem.headingColor = designSettings.headingColor;
        // }

        // if (!newBlockItem.headingFontFamily) {
        //   newBlockItem.headingFontFamily = designSettings.headingFontFamily;
        // }

        // if (
        //   !newBlockItem.headingFontSize ||
        //   newBlockItem.headingFontSize === oldDesignSettings.headingFontSize
        // ) {
        //   console.log('newBlockItem1', newBlockItem);
        //   newBlockItem.headingFontSize = designSettings.headingFontSize;
        // }

        // description
        if (!newBlockItem.description) {
          newBlockItem.description = CMS_DEFAULT_VALUE.DESCRIPTION;
        }

        // if (
        //   !newBlockItem.descriptionColor ||
        //   newBlockItem.descriptionColor === oldDesignSettings.descriptionColor
        // ) {
        //   newBlockItem.descriptionColor = designSettings.descriptionColor;
        // }

        // if (!newBlockItem.descriptionFontFamily) {
        //   newBlockItem.descriptionFontFamily = designSettings.descriptionFontFamily;
        // }

        if (
          !newBlockItem.descriptionFontSize ||
          newBlockItem.descriptionFontSize === oldDesignSettings.descriptionFontSize
        ) {
          newBlockItem.descriptionFontSize = designSettings.descriptionFontSize;
        }

        // primary button
        if (!newBlockItem.buttonLabel) {
          // always show primary and secondary button
          newBlockItem.buttonLabel = CMS_DEFAULT_VALUE.BUTTON_LABEL;
        }

        // if (!newBlockItem.buttonLabelColor) {
        //   newBlockItem.buttonLabelColor = designSettings.buttonLabelColor;
        // }

        if (!newBlockItem.buttonLabelFontSize) {
          newBlockItem.buttonLabelFontSize = designSettings.buttonLabelFontSize;
        }

        // if (!newBlockItem.buttonLabelFontFamily) {
        //   newBlockItem.buttonLabelFontFamily = designSettings.buttonLabelFontFamily;
        // }

        // if (!newBlockItem.buttonBackgroundColor) {
        //   newBlockItem.buttonBackgroundColor = designSettings.buttonBackgroundColor;
        // }

        // secondary button
        if (!newBlockItem.secondaryButtonLabel) {
          // always show primary and secondary button
          newBlockItem.secondaryButtonLabel = CMS_DEFAULT_VALUE.SECONDARY_BUTTON_LABEL;
        }

        // if (!newBlockItem.secondaryButtonLabelColor) {
        //   newBlockItem.secondaryButtonLabelColor = designSettings.secondaryButtonLabelColor;
        // }

        if (!newBlockItem.secondaryButtonLabelFontSize) {
          newBlockItem.secondaryButtonLabelFontSize = designSettings.secondaryButtonLabelFontSize;
        }

        // if (!newBlockItem.secondaryButtonLabelFontFamily) {
        //   newBlockItem.secondaryButtonLabelFontFamily =
        //     designSettings.secondaryButtonLabelFontFamily;
        // }

        // if (!newBlockItem.secondaryButtonBackgroundColor) {
        //   newBlockItem.secondaryButtonBackgroundColor =
        //     designSettings.secondaryButtonBackgroundColor;
        // }

        return newBlockItem;
      });
    } else {
      // overlay -> overlay // do nothing
    }
  }

  return newBlock;
};

export default updateVideoBlockProps;
