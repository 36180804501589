import RuntimeApi from '../../commons/RuntimeApi';
import Api from '../../commons/api';
import { getIntegrationApi, getIntegrationRuntimeApi } from '../../commons/utils';
import { RowData } from '../../globalResources/context/Interfaces';
import { GoogleDriveItem } from './googleDrive.types';
import { mapGoogleDriveSortBy } from './utils';

export async function getGoogleDriveRootFoldersList(
  parentId: string | null = null,
  driveId: string | null = null,
  includeChildrenItemsCount = false,
  countFolderOnly = false
) {
  let url = `${getIntegrationApi()}/api/google-drive/root-folders?includeChildrenItemsCount=${includeChildrenItemsCount}&countFolderOnly=${countFolderOnly}`;
  if (driveId) {
    url += `&driveId=${driveId}`;
  }
  if (parentId) {
    url += `&parentId=${parentId}`;
  }

  return Api.get(url);
}

export async function saveGoogleDriveDefaultRootFolders(selectedFolders: GoogleDriveItem[]) {
  return Api.post(`${getIntegrationApi()}/api/google-drive/root-folders`, { selectedFolders });
}

export async function getGoogleDriveResources(
  id: string | null | undefined,
  driveId: string | null | undefined,
  pageSize = 100,
  pageToken: number | null = null,
  order = 'asc',
  orderBy: keyof RowData | string | null = 'fileName',
  includeChildrenItemsCount = true
) {
  let url = `${getIntegrationApi()}/api/google-drive?includeChildrenItemsCount=${includeChildrenItemsCount}`;
  if (id) {
    url = `${getIntegrationApi()}/api/google-drive/${id}/children?includeChildrenItemsCount=${includeChildrenItemsCount}`;
  }
  if (pageSize) {
    url = `${url}&pageSize=${pageSize}`;
    if (pageToken) {
      url = `${url}&pageToken=${pageToken}`;
    }
  }
  if (driveId) {
    url = `${url}&driveId=${driveId}`;
  }
  if (orderBy) {
    url = `${url}&orderBy=${mapGoogleDriveSortBy(orderBy)}&order=${order}`;
  }

  return Api.get(url);
}

export async function searchGoogleDriveItem(keyword: string) {
  const url = `${getIntegrationApi()}/api/google-drive/search?keyword=${keyword}`;
  return Api.get(url);
}

// export async function getGoogleDriveDownloadUrl(fileId: string) {
//   const url = `${getIntegrationApi()}/api/google-drive/${fileId}/download`;

//   return Api.get(url);
// }

export async function getGoogleDriveResourceId(fileId: string) {
  const url = `${getIntegrationApi()}/api/google-drive/${fileId}/resource-id`;

  return Api.get(url)
    .then((resp) => resp)
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function searchRuntimeGoogleDriveItem(
  driveId: string,
  folderId: string,
  keyword: string
) {
  const url = `${getIntegrationRuntimeApi()}/api/runtime/google-drive/search?driveId=${driveId}&folderId=${folderId}&keyword=${keyword}`;
  return RuntimeApi.get(url);
}

export async function getDriveInfo(itemId: string) {
  // let url = `${getIntegrationRuntimeApi()}/api/runtime/google-drive/${itemId}/children?includeChildrenItemsCount=false&filterOutUnsupportedTypes=false`;
  // if (driveId) {
  //   url += `&driveId=${driveId}`;
  // }
  // const resp = await RuntimeApi.get(url);
  // if (resp) {
  //   return {
  //     name: resp.name,
  //     itemsCount: resp.children?.itemsCount,
  //   };
  // }
  // return null;
  const url = `${getIntegrationRuntimeApi()}/api/runtime/google-drive/items/${itemId}`;
  return RuntimeApi.get(url);
}
