class AppInfo {
  constructor() {
    this.context = null;
    const context = sessionStorage.getItem('appInfo');
    if (context) {
      const jsonData = JSON.parse(context);
      this.context = jsonData;
    }
  }

  isPortal() {
    return this.context?.type === 'portal';
  }

  isSpace() {
    return this.context?.type === 'space';
  }

  getContext() {
    return this.context;
  }

  setContext(context) {
    console.log('setContext', context);
    this.context = context;
    sessionStorage.setItem('appInfo', JSON.stringify(this.context));
  }

  reset() {
    console.log('appInfo reset()');
    this.context = null;
    sessionStorage.removeItem('appInfo');
  }
}

const appInfo = new AppInfo();

export default appInfo;
