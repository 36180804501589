import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import i18n from '../../i18n';
import spaceUser from '../../spaces/spaceUser';
// import '../../cms/cmsStyle.scss';
import './ActionMenu.scss';

const paperProps = {
  style: {
    minWidth: 200,
  },
};

export const Item = React.forwardRef((props, ref) => {
  const [processing, setProcessing] = React.useState(false);

  async function onClick(event) {
    event.preventDefault();
    event.stopPropagation();
    setProcessing(true);
    try {
      const rs = await props.onClick(event);
      if (rs === false) {
        setProcessing(false);
      }
    } catch (e) {
      console.error(e);
      setProcessing(false);
    }
  }
  return (
    <MenuItem disabled={props?.isDisabled || processing} onClick={onClick} ref={ref}>
      {processing ? (
        <section className="displayFlex row-direction alignItemsCenter gap8">
          <i className="icon-spinner displayFlex justifyContentCenter width24" />
          <span>{i18n.t('Processing...')}</span>
        </section>
      ) : (
        props.children
      )}
    </MenuItem>
  );
});

function ActionMenu(props) {
  const {
    anchorEl,
    onClose,
    onNewFolderButtonClick,
    onNewFileButtonClick,
    onViewButtonClick,
    onSignButtonClick,
    onRenameButtonClick,
    onEditButtonClick,
    onDeleteButtonClick,
    onShareFolderButtonClick,
    onDownloadButtonClick,
    onShareLinkButtonClick,
    onNewVersionButtonClick,
    onUploadFolder,
    onUpdateSetting,
  } = props;
  const isViewOnly = spaceUser.isViewOnly();
  const isBlockedByFirewall = !!sessionStorage.getItem('isBlockedByFirewall');

  if (!anchorEl) {
    return null;
  }

  const handleCloseButtonClick = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const handleNewFolderButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onNewFolderButtonClick === 'function') {
      onNewFolderButtonClick();
    }
  };

  const handleNewFileButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onNewFileButtonClick === 'function') {
      onNewFileButtonClick();
    }
  };

  const handleViewButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onViewButtonClick === 'function') {
      onViewButtonClick();
    }
  };

  const handleSignButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onSignButtonClick === 'function') {
      onSignButtonClick();
    }
  };

  const handleRenameButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onRenameButtonClick === 'function') {
      onRenameButtonClick();
    }
  };

  const handleOnUpdateSetting = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onUpdateSetting === 'function') {
      onUpdateSetting();
    }
  };

  const handleShareFolderButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onShareFolderButtonClick === 'function') {
      onShareFolderButtonClick();
    }
  };

  const handleEditButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onEditButtonClick === 'function') {
      onEditButtonClick();
    }
  };

  const handleDeleteButtonClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onDeleteButtonClick === 'function') {
      const rs = await onDeleteButtonClick();
      return rs !== false;
    }
    return false;
  };

  const handleDownloadButtonClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onDownloadButtonClick === 'function') {
      await onDownloadButtonClick();
    }
  };

  const handleShareLinkButtonClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onShareLinkButtonClick === 'function') {
      await onShareLinkButtonClick();
    }
  };

  const handleNewVersionButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onNewVersionButtonClick === 'function') {
      onNewVersionButtonClick();
    }
  };

  return (
    <Menu
      className="add-material-action-menu"
      PaperProps={paperProps}
      anchorEl={anchorEl}
      open
      keepMounted={false}
      onClose={handleCloseButtonClick}
      classes={{ paper: 'select-menu' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {onNewFolderButtonClick && (
        <Item onClick={handleNewFolderButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-folder-plus displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Create folder')}</span>
          </section>
        </Item>
      )}

      {onNewFileButtonClick && (
        <Item onClick={handleNewFileButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-upload-files displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Add files')}</span>
          </section>
        </Item>
      )}
      {onUploadFolder && (
        <Item onClick={onUploadFolder}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-upload-folder displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Add folder')}</span>
          </section>
        </Item>
      )}

      {onViewButtonClick && (
        <Item onClick={handleViewButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-show displayFlex justifyContentCenter width24" />
            <span>{i18n.t('View')}</span>
          </section>
        </Item>
      )}

      {onNewVersionButtonClick && (
        <Item onClick={handleNewVersionButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-upload displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Upload new version')}</span>
          </section>
        </Item>
      )}

      {((onShareLinkButtonClick && !isViewOnly) || isBlockedByFirewall) && (
        <Item onClick={handleShareLinkButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <LinkIcon fontSize="small" style={{ width: 24 }} />
            <span>{i18n.t('Copy link')}</span>
          </section>
        </Item>
      )}

      {onShareFolderButtonClick && (
        <Item onClick={handleShareFolderButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-share-folder displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Access Rights')}</span>
          </section>
        </Item>
      )}

      {onDownloadButtonClick && (
        <Item onClick={handleDownloadButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-download displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Download')}</span>
          </section>
        </Item>
      )}

      {onRenameButtonClick && (
        <Item onClick={handleRenameButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-update displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Rename')}</span>
          </section>
        </Item>
      )}
      {onUpdateSetting && (
        <Item onClick={handleOnUpdateSetting}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-settings displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Settings')}</span>
          </section>
        </Item>
      )}

      {onEditButtonClick && (
        <Item onClick={handleEditButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-update displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Edit')}</span>
          </section>
        </Item>
      )}

      {onSignButtonClick && (
        <Item onClick={handleSignButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-sign displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Sign')}</span>
          </section>
        </Item>
      )}

      {onDeleteButtonClick && (
        <Item onClick={handleDeleteButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-delete displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Delete')}</span>
          </section>
        </Item>
      )}
    </Menu>
  );
}

ActionMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  onNewFolderButtonClick: PropTypes.func,
  onNewFileButtonClick: PropTypes.func,
  onViewButtonClick: PropTypes.func,
  onSignButtonClick: PropTypes.func,
  onRenameButtonClick: PropTypes.func,
  onEditButtonClick: PropTypes.func,
  onDeleteButtonClick: PropTypes.func,
  onShareFolderButtonClick: PropTypes.func,
  onDownloadButtonClick: PropTypes.func,
  onShareLinkButtonClick: PropTypes.func,
  onNewVersionButtonClick: PropTypes.func,
  onUploadFolder: PropTypes.func,
  onUpdateSetting: PropTypes.func,
};

Item.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element,
  isDisabled: PropTypes.bool,
};

export default ActionMenu;
