import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageViewer from '../../components/ImageViewer';
import VideoPlayer from '../../components/VideoPlayer';
import Loading from '../../components/Loading';
import {
  ResourceInteractionType,
  ResourceType,
  ResourceTypeNames,
  SpaceInteractionType,
  VideoResourceViewedBatchTime,
} from '../../app/appConstants';
import PdfViewer from '../../components/PdfViewer';
import CadViewer from '../../components/CadViewer';
import AudioViewer from '../../components/AudioViewer';
import DownloadResourceView from '../../resources/DownloadResourceView';
import { getIntegrationResourceUrl } from '../integrationService';
import ImagePlaceholder from '../../components/ImagePlaceholder';
import { isIntegrationResource, isIntegrationResourceDeleted } from '../integrationResourceUtils';
import eventBus, { EVENT_BUS } from '../../commons/EventBus';
import { getExtensionFromUrl } from '../../commons/ResourceUtils';
import TiffViewer from '../../components/TiffViewer/TiffViewer';
import HTMLViewer from '../../components/Material/HTMLViewer';
import { createResourceInteraction } from '../../commons/CommonServices';
import ResourceInfoView from '../../resources/ResourceInfoView';

const OneDriveResourceViewer = (props) => {
  const {
    resourceId,
    materialId,
    src,
    name,
    type,
    externalViewerToken,
    publicUrl,
    autoPlay,
    showDownloadView,
    showInfoView,
    isPortal,
    onDownloaded,
    extension,
    fileSize,
    onClose,
  } = props;
  const [oneDriveUrl, setOneDriveurl] = useState(null);

  function sentViewEventByType(resourceType, totalTime) {
    if (resourceId && materialId) {
      eventBus.publish(EVENT_BUS.SpaceAnalyticsEvents, SpaceInteractionType.VIEW_RESOURCE, {
        resourceId: resourceId,
        materialId: materialId,
        isCms: false,
        duration: Math.round(totalTime),
        resourceType: resourceType,
      });
    }
  }

  function handleSentViewedEventToInsight(totalTime) {
    if (resourceId > 0) {
      sentViewEventByType(ResourceTypeNames.video, totalTime);
    }
  }

  function sentAudioViewEventToInsight(totalTime) {
    if (!isPortal && resourceId && materialId) {
      sentViewEventByType(ResourceTypeNames.audio, totalTime);
    }
  }

  function handleOnVideoPlayed() {
    if (!isPortal && resourceId && materialId) {
      createResourceInteraction(resourceId, {
        materialId: materialId,
        interactionType: ResourceInteractionType.Click,
        data: JSON.stringify({
          resourceType: ResourceTypeNames.video,
        }),
      });
    }
  }

  useEffect(() => {
    async function loadOneDriveDownloadUrl() {
      const resp = await getIntegrationResourceUrl(src, isPortal);
      console.log('### resp:', resp);
      if (resp) {
        setOneDriveurl(resp);
      }
    }
    if (publicUrl && !isIntegrationResource(publicUrl)) {
      setOneDriveurl(publicUrl);
    } else {
      loadOneDriveDownloadUrl();
    }
  }, [src, publicUrl, isPortal]);

  if (showDownloadView) {
    return (
      <DownloadResourceView
        url={oneDriveUrl}
        name={name}
        isPortal={isPortal}
        onDownloaded={onDownloaded}
        fileSize={fileSize}
      />
    );
  }

  if (showInfoView) {
    return (
      <ResourceInfoView
        name={name}
        isLoading={!oneDriveUrl}
        fileSize={fileSize}
        onClose={onClose}
      />
    );
  }

  if (!oneDriveUrl) {
    return <Loading className="loading" />;
  }

  if (isIntegrationResourceDeleted(oneDriveUrl)) {
    return <ImagePlaceholder />;
  }

  if (extension === 'html') {
    return <HTMLViewer src={oneDriveUrl} />;
  }

  // console.log('### oneDrive:', oneDriveUrl);

  switch (type) {
    case ResourceType.image: {
      const mExtension = getExtensionFromUrl(oneDriveUrl, extension);
      const isTiff = mExtension === 'tiff' || mExtension === 'tif';
      if (isTiff) {
        return <TiffViewer src={oneDriveUrl} className="image-viewer" resourceId={resourceId} />;
      }
      return <ImageViewer src={oneDriveUrl} />;
    }
    case ResourceType.video:
      return (
        <VideoPlayer
          materialId={materialId}
          url={oneDriveUrl}
          autoPlay={autoPlay}
          sentViewedEventToInsight={handleSentViewedEventToInsight}
          sentToInsightEventBatchTimeInSeconds={VideoResourceViewedBatchTime}
          onPlayed={handleOnVideoPlayed}
        />
      );
    case ResourceType.pdf:
    case ResourceType.keynote:
      return <PdfViewer resourceId={resourceId} materialId={materialId} src={oneDriveUrl} />;
    case ResourceType.powerpoint:
    case ResourceType.excel:
    case ResourceType.word:
      return (
        <iframe
          title={name}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            oneDriveUrl
          )}`}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          style={{ backgroundColor: '#fff' }}
        />
      );
    case ResourceType.cad:
      return externalViewerToken ? (
        <CadViewer urn={oneDriveUrl} token={externalViewerToken} />
      ) : (
        <DownloadResourceView
          url={oneDriveUrl}
          name={name}
          isPortal={isPortal}
          onDownloaded={onDownloaded}
          fileSize={fileSize}
        />
      );

    case ResourceType.audio:
      return (
        <AudioViewer
          src={oneDriveUrl}
          name={name}
          sentAudioViewEventToInsight={sentAudioViewEventToInsight}
        />
      );
    default: {
      return (
        <DownloadResourceView
          url={oneDriveUrl}
          name={name}
          isPortal={isPortal}
          onDownloaded={onDownloaded}
          fileSize={fileSize}
        />
      );
    }
  }
};

OneDriveResourceViewer.propTypes = {
  resourceId: PropTypes.number,
  materialId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  src: PropTypes.string,
  type: PropTypes.number,
  externalViewerToken: PropTypes.string,
  name: PropTypes.string,
  publicUrl: PropTypes.string,
  autoPlay: PropTypes.bool,
  showDownloadView: PropTypes.bool,
  showInfoView: PropTypes.bool,
  isPortal: PropTypes.bool,
  onDownloaded: PropTypes.func,
  extension: PropTypes.string,
  fileSize: PropTypes.string,
  onClose: PropTypes.func,
};

export default OneDriveResourceViewer;
