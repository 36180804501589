import _memoize from 'lodash/memoize';
import memoizeOne from 'memoize-one';
import { IResource } from '../context/Interfaces';
import { GRSelectType } from '../GlobalResourcesPicker';
import { GLOBAL_RESOURCE_TYPE } from '../utils';
import { getVideoResourceData } from '../../commons/ResourceUtils';
import { ResourceType } from '../../app/appConstants';

export const isRowDisabled = _memoize(
  (
    state: string | undefined,
    resourceType: number | undefined,
    type: (typeof GLOBAL_RESOURCE_TYPE)[keyof typeof GLOBAL_RESOURCE_TYPE],
    selectType: (typeof GRSelectType)[keyof typeof GRSelectType] | undefined,
    otherTypeDisabled: boolean | undefined,
    extension?: string | undefined,
    allowSelectedTypes?: boolean | undefined,
    src?: string | undefined
  ) => {
    const isCadDisabled = false; // enable cad files for testing
    // resourceType === ResourceType.cad && state !== ResourceState.Converted;
    let isDisabled = isCadDisabled;

    if (resourceType === ResourceType.video) {
      const videoResourceData = getVideoResourceData({ src, type: resourceType, state, extension });
      isDisabled = !videoResourceData?.canView;
    }
    if (otherTypeDisabled) {
      if (selectType === GRSelectType.Folder && type === GLOBAL_RESOURCE_TYPE.RESOURCE) {
        isDisabled = true;
      }
      if (selectType === GRSelectType.File && type === GLOBAL_RESOURCE_TYPE.FOLDER) {
        isDisabled = true;
      }
    }
    if (!allowSelectedTypes) isDisabled = true;
    return isDisabled;
  },
  (state, resourceType, type, selectType, otherTypeDisabled, extension, allowSelectedTypes) =>
    `${state}${resourceType}${type}${selectType}${otherTypeDisabled}${extension}${allowSelectedTypes}`
);

export const getRowCount = (
  resources: IResource[],
  selectType: (typeof GRSelectType)[keyof typeof GRSelectType] | undefined
) => {
  let rowCount = 0;
  let shouldCountResources = [...resources];
  if (selectType === GRSelectType.File) {
    shouldCountResources = shouldCountResources.filter(
      (x) => x.type === GLOBAL_RESOURCE_TYPE.RESOURCE
    );
  } else if (selectType === GRSelectType.Folder) {
    shouldCountResources = shouldCountResources.filter(
      (x) => x.type === GLOBAL_RESOURCE_TYPE.FOLDER
    );
  }
  rowCount = shouldCountResources.length;
  return rowCount;
};

export const getGROrderBy = memoizeOne((orderBy: string, order: string) => {
  const mOrder = order ?? 'asc';
  // const rOrder = mOrder === 'asc' ? 'desc' : 'asc';
  let mOrderBy = '';
  switch (orderBy) {
    case 'name':
      mOrderBy = `name:${mOrder},lastModified:desc`;
      break;
    case 'itemsCount':
      mOrderBy = `itemsCount:${mOrder},name:asc`;
      break;
    case 'lastModified':
      mOrderBy = `lastModified:${mOrder}`;
      break;
    default:
      mOrderBy = `name:${mOrder},lastModified:desc`;
      break;
  }
  return mOrderBy;
});
