import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ChatIcon from '@mui/icons-material/Chat';
import i18n from '../../i18n';
import LightTooltip from '../../components/LightTooltip';

export const Item = React.forwardRef((props, ref) => {
  const [processing, setProcessing] = React.useState(false);

  async function onClick(e) {
    if (props.disabled) return;
    setProcessing(true);
    const rs = await props.onClick(e);
    setProcessing(false);
    if (rs) {
      props.onClose();
    }
  }

  if (props.tooltip) {
    return (
      <LightTooltip title={props.tooltip} classes={{ tooltip: 'action-buttons-item-tooltip' }}>
        <MenuItem disabled={props.disabled || processing} onClick={onClick} ref={ref}>
          <ListItemIcon>{processing ? <i className="icon-spinner" /> : props.icon}</ListItemIcon>
          <Typography variant="inherit" noWrap>
            {props.label}
          </Typography>
        </MenuItem>
      </LightTooltip>
    );
  }

  return (
    <MenuItem disabled={props.disabled || processing} onClick={onClick} ref={ref}>
      <ListItemIcon>{processing ? <i className="icon-spinner" /> : props.icon}</ListItemIcon>
      <Typography variant="inherit" noWrap>
        {props.label}
      </Typography>
    </MenuItem>
  );
});

export default function ActionButtons(props) {
  const { anchorEl } = props;

  return (
    <div>
      <Menu
        className="menu-action-buttons"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => props.onClose()}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props.menuProps}
      >
        {props.onClickEdit && (
          <Item
            onClose={props.onClose}
            onClick={props.onClickEdit}
            icon={<span className="icon-update"></span>}
            label={i18n.t('Edit')}
          />
        )}

        {props.onClickChat && (
          <MenuItem onClick={props.onClickChat}>
            <ListItemIcon>
              <ChatIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {i18n.t('Chat')}
            </Typography>
          </MenuItem>
        )}
        {props.onClickDelete && (
          <Item
            onClose={props.onClose}
            onClick={props.onClickDelete}
            icon={<span className="icon-delete"></span>}
            label={i18n.t('Delete')}
            disabled={props.disabledDelete}
            tooltip={props.tooltipDelete}
          />
        )}
      </Menu>
    </div>
  );
}

ActionButtons.propTypes = {
  anchorEl: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickChat: PropTypes.func,
  disabledDelete: PropTypes.bool,
  tooltipDelete: PropTypes.node,
  menuProps: PropTypes.instanceOf(Object),
};

ActionButtons.defaultProps = {
  disabledDelete: false,
  menuProps: {
    classes: { paper: 'select-menu' },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
};

Item.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  tooltip: PropTypes.node,
};

Item.defaultProps = {
  disabled: false,
};
