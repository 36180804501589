/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import PublicRoute from './PublicRoute';
import AuthorizeRoute from '../components/api-authorization/AuthorizeRoute';
import AppProvider from '../components/AppProvider';
import Loading from '../components/Loading';
import appInfo from '../app/appInfo';
// import useMountEffect from '../commons/useMountEffect';
import NotiSnackbarProvider from '../commons/NotiSnackbarProvider';
// import useSubscriptionOrganizationQuery from '../commons/useSubscriptionOrganizationQuery';

const AppRoute = (props) => {
  const { isPublic, isPortal, isSpace, ...rest } = props;

  React.useEffect(() => {
    let type = '';
    if (isSpace === true) {
      type = 'space';
    } else if (isPortal) {
      type = 'portal';
    }
    appInfo.setContext({ type });
  }, [isSpace, isPortal]);

  const isHomePage = props.path === '/';
  const includeDeletedFonts = isHomePage || isSpace || isPublic;

  return (
    <AppProvider isPortal={!isPublic} isSpace={isSpace} isRouter>
      <NotiSnackbarProvider>
        <Suspense
          fallback={
            <div className="app">
              <Loading />
            </div>
          }
        >
          {isPublic ? (
            <PublicRoute {...rest} isSpace={isSpace} includeDeletedFonts={includeDeletedFonts} />
          ) : (
            <AuthorizeRoute {...rest} includeDeletedFonts={includeDeletedFonts} />
          )}
        </Suspense>
      </NotiSnackbarProvider>
    </AppProvider>
  );
};

AppRoute.propTypes = {
  isPublic: PropTypes.bool,
  path: PropTypes.string,
  isSpace: PropTypes.bool,
  isPortal: PropTypes.bool,
  noSignalR: PropTypes.bool,
};

export default AppRoute;
