import { useState, useEffect } from 'react';

const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
    }
    return '';
  };
};

// Hook
export default function useExitPrompt(bool) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    if (showExitPrompt) {
      initBeforeUnLoad(showExitPrompt);
    } else {
      window.onbeforeunload = undefined;
    }
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}
