/* eslint react/jsx-props-no-spreading: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';
import './SwitchEnhanced.scss';

/**
 *
 * @param {*} props
 * Default value is empty string
 * @returns void
 */
function SwitchEnhanced(props) {
  const handledOnchange = (event) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <FormControlLabel
      control={<Switch color="primary" checked={props.checked} className="switch-enhanced" />}
      labelPlacement="start"
      {...props}
      onChange={handledOnchange}
      className={`${props.className}`}
    />
  );
}

SwitchEnhanced.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  dataId: PropTypes.string,
};

export default SwitchEnhanced;
