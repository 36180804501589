import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import { AssessmentSummary, IUserAssessment } from '../AssessmentTable/types';
import { formatDateTime } from '../../commons/DateTimeUtils';
import SpaceModalHeader from '../components/SpaceModalHeader';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import UsersDetailsByAssessmentTable from './UsersDetailsByAssessmentTable';
import useAssessmentDetailsUsersQuery from '../queries/useAssessmentDetailsUsersQuery';
import i18n from '../../i18n';
import Loading from '../../components/Loading';

type AssessmentModalProps = {
  open: boolean;
  assessment: AssessmentSummary;
  onClose: () => void;
};

function AssessmentModal(props: AssessmentModalProps) {
  const { open, onClose, assessment } = props;

  const [usersAssessmentDetails, setUsersAssessmentDetails] = useState<IUserAssessment[]>([]);
  const { space, visibleUsers, excludeUserIds, reportEndDate } = useSpaceAnalyticsContext();
  const endDate = moment(reportEndDate).toDate().toISOString();

  const { data, isFetching } = useAssessmentDetailsUsersQuery(
    space?.id,
    assessment.assessmentId,
    endDate,
    excludeUserIds,
    !!assessment.assessmentId
  );

  useEffect(() => {
    if (visibleUsers?.length > 0) {
      const mappingData = data
        .map((userAssessment: IUserAssessment) => {
          const foundUser = visibleUsers?.find(
            (user) => user.userInfoId === userAssessment.userInfoId
          );

          return {
            ...foundUser,
            ...userAssessment,
            isCompleted: true,
          } as IUserAssessment;
        })
        .filter(Boolean);
      setUsersAssessmentDetails(mappingData);
    }
  }, [visibleUsers, data]);

  function renderTable() {
    return <UsersDetailsByAssessmentTable data={usersAssessmentDetails} />;
  }

  function renderContent() {
    if (isFetching) {
      return (
        <div className="loading-box">
          <Loading />
        </div>
      );
    }
    return (
      <div className="video-message-info-container">
        <SpaceModalHeader
          title={assessment.name}
          icon="icon-assessments"
          description={formatDateTime(Date.now(), 'MMM DD, yyyy')}
          className="video-message-info-header"
        />
        <p className="short-description">
          {assessment.percentageNeededToPass === 0 ? (
            i18n.t('No required score to pass.')
          ) : (
            <span>To pass, user must score {assessment.percentageNeededToPass * 100}%.</span>
          )}
        </p>
        {renderTable()}
      </div>
    );
  }

  if (!open) {
    return null;
  }
  return (
    <ResponsiveDialog
      modal
      maxWidth="lg"
      className="white confirm-dialog space-users-details-modal"
      onClose={onClose}
    >
      <div className="tab-content">{renderContent()}</div>
    </ResponsiveDialog>
  );
}

export default React.memo(AssessmentModal);
