export const renderStyling = (variant: string) => {
  switch (variant) {
    // the ordinary rounded buttons
    case 'regular-primary':
      return `official regularPrimary`;
    case 'regular-secondary':
      return `official regularSecondary`;
    case 'regular-green':
      return `official regularGreen`;
    case 'regular-red':
      return `official regularRed`;
    case 'regular-dark-grey':
      return `official regularDarkGrey`;
    // small rectangle buttons
    case 'rectangle-primary':
      return `rectangle rectanglePrimary`;
    case 'rectangle-secondary':
      return `rectangle rectangleSecondary`;
    case 'rectangle-green':
      return `rectangle rectangleGreen`;
    case 'rectangle-grey':
      return `rectangle rectangleGrey`;
    case 'rectangle-dark-grey':
      return `rectangle rectangleDarkGrey`;
    // large buttons for popups
    case 'large-primary':
      return `large largePrimary`;
    case 'large-secondary':
      return `large largeSecondary`;
    // small rounded buttons
    case 'small-primary':
      return 'small primary';
    case 'small-secondary':
      return 'small secondary';
    case 'small-green':
      return 'small green';
    case 'small-grey':
      return 'small grey';
    case 'small-dark-grey':
      return 'small DarkGrey';
    // small rectangle buttons
    case 'small-rectangle-primary':
      return 'small rectangle primary';
    case 'small-rectangle-secondary':
      return 'small rectangle secondary';
    case 'small-rectangle-green':
      return 'small rectangle green';
    case 'small-rectangle-grey':
      return 'small rectangle grey';
    case 'small-rectangle-dark-grey':
      return 'small rectangle DarkGrey';
    default:
      return '';
  }
};
