import { ResourceState, ResourceType } from '../app/appConstants';
import { getExtensionFromUrl } from '../commons/ResourceUtils';
import { getIntegrationApi, getIntegrationRuntimeApi } from '../commons/utils';
import { SERVICE_PROVIDER } from './integrationConstants';
import { getIntegrationResourceUrl } from './integrationService';

export const IntegrationResourcePrefixes = {
  IntegrationResource: 'spce_sis_resource:/',
  OneDrive: 'spce_sis_resource://onedrive',
  GoogleDrive: 'spce_sis_resource://google-drive',
};

export function getIntegrationResourceDetails(src) {
  if (src?.startsWith(IntegrationResourcePrefixes.OneDrive))
    return {
      provider: SERVICE_PROVIDER.ONEDRIVE,
      iconName: 'icon-onedrive',
      indicatorCss: 'onedrive-gray',
    };

  if (src?.startsWith(IntegrationResourcePrefixes.GoogleDrive)) {
    return {
      provider: SERVICE_PROVIDER.GOOGLE_DRIVE,
      iconName: 'icon-googledrive',
      indicatorCss: 'google-drive-gray',
    };
  }

  return null;
}

export function getIntegrationFolderDetails(provider) {
  if (provider === SERVICE_PROVIDER.ONEDRIVE)
    return {
      provider: SERVICE_PROVIDER.ONEDRIVE,
      iconName: 'icon-onedrive',
      indicatorCss: 'onedrive-gray',
    };

  if (provider === SERVICE_PROVIDER.GOOGLE_DRIVE) {
    return {
      provider: SERVICE_PROVIDER.GOOGLE_DRIVE,
      iconName: 'icon-googledrive',
      indicatorCss: 'google-drive-gray',
    };
  }

  return null;
}

export function isIntegrationResource(src) {
  return src?.startsWith(IntegrationResourcePrefixes.IntegrationResource);
}

export function createIntegrationResourceUrl(sisResourceUrl, isPortal) {
  if (isPortal) {
    return sisResourceUrl.replace(
      IntegrationResourcePrefixes.IntegrationResource,
      `${getIntegrationApi()}/api`
    );
  }
  return sisResourceUrl.replace(
    IntegrationResourcePrefixes.IntegrationResource,
    `${getIntegrationRuntimeApi()}/api/runtime`
  );
}

export function createIntegrationPublicResourceUrl(sisResourceUrl) {
  return sisResourceUrl.replace(
    IntegrationResourcePrefixes.IntegrationResource,
    `${getIntegrationRuntimeApi()}/api/public`
  );
}

export function generateSrcResource(resource) {
  const { provider, driveId, id, extension } = resource;
  if (provider === SERVICE_PROVIDER.ONEDRIVE) {
    return `${IntegrationResourcePrefixes.OneDrive}/${driveId}/items/${id}/downloadUrl?sis_ext=${extension}`;
  }
  return null;
}

export async function downloadIntegrationResource(resource, isPortal) {
  const { src, name } = resource;

  let finalUrl;
  if (src) {
    // need to fetch the url for downloading
    const resp = await getIntegrationResourceUrl(src, isPortal);
    if (resp) {
      finalUrl = resp;
    }
  }

  //   let extension = getExtensionFromFileName(name);
  //   if (extension === '') {
  //     // TODO: get extension from original file in 3rd party service
  //   }

  if (finalUrl) {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = name;
    link.href = finalUrl;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function isIntegrationResourceDeleted(src) {
  return src === 'sis_file_deleted';
}

export function isIntegrationVideoResource(src, type) {
  return isIntegrationResource(src) && (type === ResourceType.video || type === ResourceType.audio);
}

export function shouldConvertIntegrationResourceToPDF(resource) {
  return (
    isIntegrationResource(resource.src) &&
    resource.type === ResourceType.keynote &&
    resource.state !== ResourceState.Converted &&
    resource.state !== ResourceState.Converting &&
    resource.state !== ResourceState.Deleted
  );
}

export function canViewIntegrationResource(url) {
  if (
    url?.startsWith(IntegrationResourcePrefixes.IntegrationResource) &&
    getExtensionFromUrl(url) === 'avi'
  ) {
    return false;
  }

  return true;
}
