import Api from '../../commons/api';
import { getBffClientUrl } from '../../commons/utils';
import { QueryParameterNames } from './ApiAuthorizationConstants';

export async function fetchClaims() {
  const resp = await Api.get(`${getBffClientUrl()}/bff/user`);
  // console.log('fetchClaims', resp);
  return resp;
}

export async function fetchUserProfile() {
  const resp = await Api.get(`${getBffClientUrl()}/connect/userinfo`);
  // console.log('fetchUserProfile', resp);
  return resp;
}

export function getReturnUrl() {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get(QueryParameterNames.ReturnUrl);
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    // This is an extra check to prevent open redirects.
    throw new Error(
      'Invalid return url. The return url needs to have the same origin as the current page.'
    );
  }
  const returnUrl = fromQuery || `${window.location.origin}/`;
  return returnUrl;
}

export function getRedirectUrl() {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get('redirect_uri');
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    // This is an extra check to prevent open redirects.
    throw new Error(
      'Invalid return url. The return url needs to have the same origin as the current page.'
    );
  }
  return fromQuery;
}
