import React from 'react';
import PropTypes from 'prop-types';

const ScrollIntoView = ({ behavior, block, inline }) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current.scrollIntoView({ behavior, block, inline });
  }, [behavior, block, inline]);
  return <i ref={ref} />;
};

ScrollIntoView.propTypes = {
  behavior: PropTypes.string,
  block: PropTypes.string,
  inline: PropTypes.string,
};

ScrollIntoView.defaultProps = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'nearest',
};

export default ScrollIntoView;
