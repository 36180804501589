import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

const BeforeLeaveHandler = () => {
  useEffect(() => {
    // if (!hasChanged) {
    //   return null;
    // }
    const unloadCallback = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);

    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);

  const shouldBlock = useCallback(({ currentLocation, nextLocation }) => {
    return currentLocation?.pathname !== nextLocation?.pathname;
  }, []);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Changes you made may not be saved. Do you want to continue?')) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker]);

  return null;
};

BeforeLeaveHandler.propTypes = {};

export default BeforeLeaveHandler;
