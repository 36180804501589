import memoizeOne from 'memoize-one';
import { formatDateTime } from '../../../commons/DateTimeUtils';
import { IResource, RowData } from '../../../globalResources/context/Interfaces';
import { GLOBAL_RESOURCE_TYPE } from '../../../globalResources/utils';
import { SERVICE_PROVIDER } from '../../integrationConstants';

export const formatGoogleDriveResources = memoizeOne((data, acceptResourceTypes): RowData[] => {
  return data.map((item: IResource, index: number) => {
    let allowSelectedType = true;
    if (!item.isFolder) {
      allowSelectedType =
        acceptResourceTypes === null ? true : acceptResourceTypes.includes(item.resourceType);
    }
    return {
      ...item,
      index,
      displayDateTime: formatDateTime(item.lastModified, 'MMM DD, YYYY'),
      name: item.name || item.resourceName || 'Untitled',
      type: item.isFolder ? GLOBAL_RESOURCE_TYPE.FOLDER : GLOBAL_RESOURCE_TYPE.RESOURCE,
      description: item.description || item.resourceDescription,
      allowSelectedType: allowSelectedType,
      provider: SERVICE_PROVIDER.GOOGLE_DRIVE,
    };
  });
});

export function mapGoogleDriveItemToResource(driveItem: RowData): RowData {
  return {
    id: driveItem.id,
    description: driveItem.name,
    resourceName: driveItem.name,
    name: driveItem.name,
    fileName: driveItem.name,
    src: driveItem.downloadUrl,
    orgSrc: driveItem.downloadUrl,
    resourceType: driveItem.resourceType,
    lastModified: driveItem.lastModified,
    isExternal: false,
    extension: driveItem.extension,
    driveId: driveItem.driveId,
    type: driveItem.type,
    thumbnail: driveItem.thumbnail,
    size: driveItem.size,
    settings: {
      external: {
        provider: SERVICE_PROVIDER.GOOGLE_DRIVE,
        id: driveItem.id,
        parentId: driveItem.parentId,
        rootParentId: driveItem.driveId,
      },
    },
  };
}
