import React from 'react';
import { Typography } from '@mui/material';
import { Column } from '@material-table/core';
import Table from '../components/Table';
import {
  AllResourceMetricsResponse,
  ResourceMetricsDataItem,
  typeViewTimeNotSupportedList,
} from '../types/resources';
import { getStringTime } from '../common';
import useSpaceAnalyticsQuery from '../hooks/useSpaceAnalyticsQuery';
import { fetchResourcesMetrics } from '../services/SpaceAnalyticsService';
import { CacheKeys } from '../../app/queryCache';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import Loading from '../../components/Loading';
import LightTooltip from '../../components/LightTooltip';
import i18n from '../../i18n';
import './ResourceMetricsTable.scss';
import { ISpaceUser } from '../../types/User';
import SpaceUserAvatar from '../components/SpaceUserAvatar';
import { SpaceUserDetailsTab } from '../SpaceUserDetailsModal';
import { sortSpaceAnalyticMetrics } from '../components/Table/utils';
import useAvailableSpaceResources from '../hooks/useAvailableSpaceResources';
import { Ids } from '../../commons/pendoTaggings';

type ParticipantMetricsTableProps = {
  resourceMetricsByParticipants?: AllResourceMetricsResponse;
};

const ParticipantMetricsTable = (props: ParticipantMetricsTableProps) => {
  const { resourceMetricsByParticipants } = props;
  const { openSpaceUserModal, visibleUsers, excludeUserIds } = useSpaceAnalyticsContext();
  const {
    availableMaterialIds,
    availableMaterials,
    isLoading: isResourcesLoading,
  } = useAvailableSpaceResources();

  const participantFilter = availableMaterials?.filter((p) =>
    typeViewTimeNotSupportedList.find(
      ([boolValue, numValue]) => boolValue === p?.isCmsFile && numValue === p?.resourceType
    )
  );
  const { data: allResourceMetrics, isLoading: isMetricsLoading } = useSpaceAnalyticsQuery({
    fetcher: fetchResourcesMetrics,
    params: {
      groupBy: 'Participant',
      availableMaterialIds,
      notSupportedViewMaterialIds: participantFilter?.map((x) => x.materialId),
      excludedUserInfoIds: excludeUserIds,
      userInfoIds: visibleUsers?.map((x) => x.userInfoId),
    },
    queryKey: CacheKeys.spaceAnalytics_FetchResourcesMetrics,
    select: (resp) => resp.value.data,
    enabled: !resourceMetricsByParticipants && !isResourcesLoading,
  });

  const resourceMetricsData = resourceMetricsByParticipants?.data || allResourceMetrics;

  const handleOnClickLink = React.useCallback(
    (rowData: ResourceMetricsDataItem<ISpaceUser>) => {
      openSpaceUserModal(rowData, SpaceUserDetailsTab.RESOURCES);
    },
    [openSpaceUserModal]
  );

  const columns: Column<ResourceMetricsDataItem<ISpaceUser>>[] = [
    {
      title: 'Name',
      field: 'firstName',
      cellStyle: {
        width: '30%',
      },
      render: (rowData: ResourceMetricsDataItem<ISpaceUser>) => {
        const userInfo: ISpaceUser = { ...rowData, id: rowData.userInfoId };
        return <SpaceUserAvatar userInfo={userInfo} />;
      },
    },
    {
      title: 'Clicks',
      field: 'clicks',
      defaultSort: 'desc',
      cellStyle: {
        width: '20%',
      },
      customSort: (a, b) => sortSpaceAnalyticMetrics(a.clicks, b.clicks),
      render: (rowData: ResourceMetricsDataItem<ISpaceUser>) => {
        const { clicks, isJoinedSpace } = rowData;
        if (!isJoinedSpace) {
          return (
            <LightTooltip title={<span>{i18n.t("The user hasn't joined the space yet")}</span>}>
              <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
            </LightTooltip>
          );
        }
        return (
          <span
            className="btn-link"
            onClick={() => handleOnClickLink(rowData)}
            data-id={Ids.participantMetricViews}
          >
            {clicks}
          </span>
        );
      },
    },
    {
      title: 'Shares',
      field: 'shares',
      cellStyle: {
        width: '20%',
      },
      customSort: (a, b) => sortSpaceAnalyticMetrics(a.shares, b.shares),
      render: (rowData: ResourceMetricsDataItem<ISpaceUser>) => {
        const { shares, isJoinedSpace } = rowData;
        if (!isJoinedSpace) {
          return (
            <LightTooltip title={<span>{i18n.t("The user hasn't joined the space yet")}</span>}>
              <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
            </LightTooltip>
          );
        }
        return (
          <span
            className="btn-link"
            onClick={() => handleOnClickLink(rowData)}
            data-id={Ids.participantMetricShares}
          >
            {shares}
          </span>
        );
      },
    },
    {
      title: 'Downloads',
      field: 'downloads',
      cellStyle: {
        width: '20%',
      },
      customSort: (a, b) => sortSpaceAnalyticMetrics(a.downloads, b.downloads),
      render: (rowData: ResourceMetricsDataItem<ISpaceUser>) => {
        const { downloads, isJoinedSpace } = rowData;
        if (!isJoinedSpace) {
          return (
            <LightTooltip title={<span>{i18n.t("The user hasn't joined the space yet")}</span>}>
              <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
            </LightTooltip>
          );
        }
        return (
          <span
            className="btn-link"
            onClick={() => handleOnClickLink(rowData)}
            data-id={Ids.participantMetricDownloads}
          >
            {downloads}
          </span>
        );
      },
    },
    {
      title: 'View time',
      field: 'viewTimeInSeconds',
      align: 'right',
      cellStyle: {
        width: '20%',
      },
      customSort: (a, b) => sortSpaceAnalyticMetrics(a.viewTimeInSeconds, b.viewTimeInSeconds),
      render: ({ viewTimeInSeconds, isJoinedSpace }: ResourceMetricsDataItem<ISpaceUser>) => {
        if (!isJoinedSpace) {
          return (
            <LightTooltip title={<span>{i18n.t("The user hasn't joined the space yet")}</span>}>
              <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
            </LightTooltip>
          );
        }
        return <Typography>{getStringTime(viewTimeInSeconds)}</Typography>;
      },
    },
  ];

  if (isMetricsLoading) {
    return (
      <div className="loading-box">
        <Loading />
      </div>
    );
  }

  const data: ResourceMetricsDataItem<ISpaceUser>[] = visibleUsers.map((x) => {
    if (x.isJoinedSpace) {
      const metrics = resourceMetricsData?.find((m) => m.userInfoId === x.userInfoId);
      return {
        ...x,
        ...(metrics || {
          resourceId: 0,
          materialId: 0,
          downloads: 0,
          clicks: 0,
          shares: 0,
          viewTimeInSeconds: 0,
        }),
      };
    }
    return {
      ...x,
      resourceId: 0,
      materialId: 0,
      downloads: -1,
      clicks: -1,
      shares: -1,
      viewTimeInSeconds: -1,
    };
  });

  return <Table columns={columns} data={data} />;
};

export default ParticipantMetricsTable;
