import memoizeOne from 'memoize-one';
import { getResourceFileType, validateFile } from '../../commons/ResourceUtils';
import { isValidUrl } from '../../commons/ValidationUtils';
// import { ResourceType } from '../../app/appConstants';
import i18n from '../../i18n';

export const DefaultAccept = '*';
export const RetryTimes = 3;
export const DefaultMaxFiles = 15;
// export const DefaultMaxFileSize = 50; // mb

export function validateInput(name, value, props) {
  if (!name) return null;
  if (name === 'url') {
    if (!value) return null;
    const isValid = isValidUrl(value);
    if (!isValid) {
      return { type: 'pattern' };
    }
  }
  if (name === 'name') {
    if (!value || !value.trim()) {
      return { type: 'required' };
    }
  }
  if (name === 'file') {
    const file = value;
    const resourceType = getResourceFileType(file);
    if (resourceType === null) {
      return { type: 'unsupported' };
    }

    const accept = props?.accept || DefaultAccept;
    const isValid = validateFile(accept, file);
    if (!isValid) {
      return { type: 'unaccepted' };
    }

    const defaultMaxFileSize = 5000; // 5gb
    const maxFileSizeMsg = i18n.t('The file exceeds the maximum file size 5 GB.');
    const maxFileSize = props?.maxFileSize || defaultMaxFileSize;
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > maxFileSize) {
      return { type: 'maxFileSize', message: maxFileSizeMsg };
    }
  }

  if (name === 'files') {
    // used for checking the number of picked files
    const files = value;
    const maxFiles = props?.multiple ? props?.maxFiles || DefaultMaxFiles : 1;
    if (files.length > maxFiles) {
      return { type: 'maxFiles' };
    }
  }
  return null;
}

export function errorMessageByType(type) {
  switch (type) {
    case 'required':
      return i18n.t('This field is required');
    case 'unsupported' || 'unaccepted':
      return i18n.t('The file format is not supported. Please try another file.');
    case 'maxFileSize':
      return i18n.t('The file exceeds the maximum file size');
    case 'common':
      return i18n.t('Upload failed.');
    case 'signature':
      return i18n.t(
        'The file format has been changed to an unsupported file type. This can happen if the file has been downloaded from a messaging, or web service even if the title has not changed. Try to convert the file to a supported format and upload it again.'
      );
    default:
      return '';
  }
}

export function renderErrorMessage(errors, property, props) {
  if (errors && errors[property]) {
    if (errors[property].type === 'required') {
      return i18n.t('This field is required');
    }
    if (errors[property].type === 'pattern') {
      return i18n.t('This is not a valid link. Please check again.');
    }
    // if (errors[property].type === 'validate') {
    //   return i18n.t("This link doesn't match, please check again");
    // }
    if (errors[property].type === 'unsupported' || errors[property].type === 'unaccepted') {
      return i18n.t('The file format is not supported. Please try another file.');
    }
    if (errors[property].type === 'maxFiles') {
      const maxFiles = props?.multiple ? props?.maxFiles || DefaultMaxFiles : 1;
      return i18n.t(`Upload maximum ${maxFiles === 1 ? '1 file' : `${maxFiles} files`} at a time.`);
    }
    if (errors[property].type === 'maxFileSize') {
      const msg = errors[property].message || i18n.t('The file exceeds the maximum file size');
      return msg;
    }
    if (errors[property].type === 'signature') {
      const msg =
        errors[property].message ||
        i18n.t(
          'The file format has been changed to an unsupported file type. This can happen if the file has been downloaded from a messaging, or web service even if the title has not changed. Try to convert the file to a supported format and upload it again.'
        );
      return msg;
    }
    if (errors[property].type === 'common') {
      const msg = errors[property].message || i18n.t('Upload failed.');
      return msg;
    }
    // if (errors[property].type === 'subscriptionQuotaReached') {
    //   return (
    //     <span>
    //       {i18n.t(
    //         "Files can't be uploaded because your organization storage is full. Free up space by removing unnecessary files or "
    //       )}
    //       <span onClick={handleGetMoreStorageButtonClick}>{i18n.t('Get more storage')}</span>
    //     </span>
    //   );
    // }
  }
  return null;
}

export function getErrorMessage(errors, property, props) {
  return renderErrorMessage(errors, property, props);
}

export function renderWarningMessage(warnings, property) {
  if (warnings && warnings[property]) {
    // if (warnings[property].type === 'incompatible') {
    //   return i18n.t('This field is incompatible');
    // }
  }
  return null;
}

export function getWarningMessage(warnings, property) {
  return renderWarningMessage(warnings, property);
}

export const getProgress = memoizeOne((progresses, totalFile) => {
  if (!progresses) return 0;
  const keys = Object.keys(progresses);
  const total = totalFile || keys.length;
  if (total === 0) return 0;

  let sum = 0;
  keys.forEach((key) => {
    sum += progresses[key];
  });

  const result = Math.round(sum / total);
  return result;
});
