import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from '../../components/Tags';

export function getStatus(assessment, isResult) {
  if (isResult) {
    return 'Result';
  }
  if (assessment.isPassed) {
    return 'Passed';
  } else if (!assessment.isPassed && assessment.isCompleted) {
    return 'Failed';
  } else if (!assessment.isCompleted) {
    return 'NotStarted';
  }
  return 'Result';
}

function StatusAssessmentTag(props) {
  const { assessment, isResult } = props;
  const state = getStatus(assessment, isResult);
  const tags = {
    Result: {
      text: 'Result',
      backgroundColor: '#DBE1E3',
      color: '#000',
    },
    Passed: {
      text: 'Passed',
      backgroundColor: '#D7FFEF',
      color: '#11784C',
    },
    Failed: {
      text: 'Failed',
      backgroundColor: '#FEE7E7',
      color: '#862929',
    },
    NotSubmitted: {
      text: 'Not submitted',
      backgroundColor: '#DBE1E3',
      color: '#000',
    },
    NotStarted: {
      text: 'Not started',
      backgroundColor: '#DBE1E3',
      color: '#000',
    },
  };
  const tag = tags[state] || tags.Result;
  return (
    <Tag className="tag-name" backgroundColor={tag.backgroundColor} color={tag.color}>
      {tag.text}
    </Tag>
  );
}

StatusAssessmentTag.propTypes = {
  assessment: PropTypes.instanceOf(Object),
  isResult: PropTypes.bool,
};

export default StatusAssessmentTag;
