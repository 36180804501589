/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, Hidden, IconButton, Tooltip } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { tableIcons } from '.';

type Props = TablePaginationActionsProps & {
  showFirstLastPageButtons: boolean;
  localization?: object;
  classes?: any;
  forwardedRef?: any;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
};
const SteppedPaginationInner = (props: Props) => {
  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onChangePage(event, props.page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onChangePage(event, props.page + 1);
  };

  const handleNumberButtonClick =
    (number: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
      props.onChangePage(event, number);
    };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onChangePage(event, Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1));
  };

  function renderPagesButton(
    start: number,
    end: number,
    showNextDots: boolean,
    showPrevDots: boolean,
    maxPages: number
  ) {
    const buttons = [];
    for (let p = start; p <= end; p += 1) {
      const buttonVariant = p === props.page ? 'contained' : 'text';
      if (p === start && showPrevDots) {
        buttons.push(
          <Button
            size="small"
            style={{
              boxShadow: 'none',
              maxWidth: '30px',
              maxHeight: '30px',
              minWidth: '30px',
              minHeight: '30px',
            }}
            variant="text"
            onClick={handleNumberButtonClick(0)}
            key="first-page"
          >
            {1}
          </Button>
        );
        buttons.push(
          <Button
            size="small"
            style={{
              boxShadow: 'none',
              maxWidth: '30px',
              maxHeight: '30px',
              minWidth: '30px',
              minHeight: '30px',
            }}
            disabled
            variant="text"
            key="prev-dots"
          >
            <MoreHorizIcon />
          </Button>
        );
      }
      buttons.push(
        <Button
          size="small"
          style={{
            boxShadow: 'none',
            maxWidth: '30px',
            maxHeight: '30px',
            minWidth: '30px',
            minHeight: '30px',
          }}
          disabled={p === props.page}
          variant={buttonVariant}
          onClick={handleNumberButtonClick(p)}
          key={p}
        >
          {p + 1}
        </Button>
      );
      if (p === end && showNextDots) {
        buttons.push(
          <Button
            size="small"
            style={{
              boxShadow: 'none',
              maxWidth: '30px',
              maxHeight: '30px',
              minWidth: '30px',
              minHeight: '30px',
            }}
            disabled
            variant="text"
            key="next-dots"
          >
            <MoreHorizIcon />
          </Button>
        );
        buttons.push(
          <Button
            size="small"
            style={{
              boxShadow: 'none',
              maxWidth: '30px',
              maxHeight: '30px',
              minWidth: '30px',
              minHeight: '30px',
            }}
            variant="text"
            onClick={handleNumberButtonClick(maxPages)}
            key="last-page"
          >
            {maxPages + 1}
          </Button>
        );
      }
    }

    return <span>{buttons}</span>;
  }

  function render() {
    const { classes, count, page, rowsPerPage, showFirstLastPageButtons } = props;

    const localization = {
      ...SteppedPaginationInner.defaultProps.localization,
      ...props.localization,
    };
    const maxPages = Math.ceil(count / rowsPerPage) - 1;
    let showPrevDots = false;
    let showNextDots = false;
    let pageStart = 0;
    let pageEnd = 0;
    if (maxPages <= 4) {
      pageStart = 0;
      pageEnd = maxPages;
      showNextDots = false;
      showPrevDots = false;
    } else {
      if (page < 4) {
        pageStart = 0;
        pageEnd = 4;
        showNextDots = true;
      } else if (page > maxPages - 4) {
        pageStart = maxPages - 4;
        pageEnd = maxPages;
        showNextDots = false;
        showPrevDots = true;
      } else {
        pageStart = page - 1;
        pageEnd = page + 1;
        showNextDots = true;
        showPrevDots = true;
      }
    }

    return (
      <div className={classes?.root} ref={props.forwardedRef}>
        {showFirstLastPageButtons && (
          <Tooltip title={localization.firstTooltip}>
            <span>
              <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label={localization.firstAriaLabel}
              >
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <tableIcons.FirstPage />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title={localization.previousTooltip}>
          <span>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label={localization.previousAriaLabel}
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <tableIcons.PreviousPage />
            </IconButton>
          </span>
        </Tooltip>
        <Hidden smDown>
          {renderPagesButton(pageStart, pageEnd, showNextDots, showPrevDots, maxPages)}
        </Hidden>
        <Tooltip title={localization.nextTooltip}>
          <span>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= maxPages}
              aria-label={localization.nextAriaLabel}
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <tableIcons.NextPage />
            </IconButton>
          </span>
        </Tooltip>
        {showFirstLastPageButtons && (
          <Tooltip title={localization.lastTooltip}>
            <span>
              <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={localization.lastAriaLabel}
              >
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <tableIcons.LastPage />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>
    );
  }
  return render();
};

SteppedPaginationInner.defaultProps = {
  showFirstLastPageButtons: true,
  localization: {
    firstAriaLabel: 'First Page',
    firstTooltip: 'First Page',
    previousAriaLabel: 'Previous Page',
    previousTooltip: 'Previous Page',
    nextAriaLabel: 'Next Page',
    nextTooltip: 'Next Page',
    lastAriaLabel: 'Last Page',
    lastTooltip: 'Last Page',
    labelDisplayedRows: '{from}-{to} of {count}',
    labelRowsPerPage: 'Rows per page:',
  },
};

const SteppedPaginationRef = React.forwardRef<any, Props>((props, ref) => {
  return <SteppedPaginationInner {...props} forwardedRef={ref} className="steps-pagination" />;
});

// const actionsStyles = (theme: Theme) => ({
//   flexShrink: 0,
//   color: theme.palette.text.secondary,
//   marginLeft: theme.spacing(2.5),
// });

const SteppedPagination = SteppedPaginationRef;

export default SteppedPagination;
