/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import './CmsCheckbox.scss';

function CmsCheckbox(props) {
  const { checked, onChange, label, name } = props;
  return (
    <FormControlLabel
      classes={{ root: 'checkbox-control', label: 'checkbox-control-label' }}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name={name}
          color="default"
          className="checkbox-control-input"
        />
      }
      label={label}
    />
  );
}

CmsCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default CmsCheckbox;
