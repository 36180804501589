/* eslint no-useless-escape: 0 */
/* eslint no-bitwise: 0 */
/* eslint no-param-reassign: 0 */
/* eslint one-var: 0 */
/* eslint no-restricted-syntax: 0 */
/* eslint max-lines: 0 */

import memoizeOne from 'memoize-one';
import _get from 'lodash/get';
import _differenceBy from 'lodash/differenceBy';
import _isString from 'lodash/isString';
import DOMPurify from 'dompurify';
import I18n from '../i18n';
import eventBus, { EVENT_BUS } from './EventBus';
// import { isValidEmail } from './ValidationUtils';

const moment = require('moment-timezone');

function s4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function isEnv(env = 'local') {
  return process.env.REACT_APP_ENV === env;
}

export function isStaging() {
  return window.location.hostname.includes('.appstg.spce.com');
}

export function newGuid() {
  return `${s4() + s4()}-${s4()}-4${s4().substr(0, 3)}-${s4()}-${s4()}${s4()}${s4()}`.toLowerCase();
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// eslint-disable-next-line no-unused-vars
export function getObjectHash(obj) {
  return uuidv4();
}

export function isPromise(p) {
  return p && Object.prototype.toString.call(p) === '[object Promise]';
}

/**
 *
 * @param {*} message
 * @param {*} options { type, duration }
 */
export function sendNotification(message, options = {}, title = '', id = newGuid()) {
  let { type, duration } = options;
  if (type === undefined) {
    type = 'success';
  }
  if (duration === undefined) {
    duration = 4000;
  }
  const detail = {
    message,
    options: {
      type,
      duration,
    },
    title,
    id,
  };
  eventBus.publish(EVENT_BUS.AddNotificationMessage, detail);
}

export function sendCustomNotification(content, options = {}, id = newGuid()) {
  let { type, duration } = options;
  if (type === undefined) {
    type = 'success';
  }
  if (duration === undefined) {
    duration = 4000;
  }
  const detail = {
    isCustom: true,
    content,
    options: {
      type,
      duration,
    },
    id,
  };
  eventBus.publish(EVENT_BUS.AddNotificationMessage, detail);
}

export function dismissNotification(id) {
  const detail = { id };
  eventBus.publish(EVENT_BUS.RemoveNotificationMessage, detail);
}

export function formatTime(val, format = 'YYYY-MM-DD HH:mm') {
  if (!val) {
    return '';
  }
  return moment.utc(val).local().format(format);
}

export function getParamValueAsString(match, name, defaultValue = null) {
  const value = _get(match, `params.${name}`, null);
  if (!value) {
    return defaultValue;
  }
  return value;
}

export function getParamValueAsNumber(match, name, defaultValue = null) {
  const value = _get(match, `params.${name}`, null);
  if (!value) {
    return defaultValue;
  }
  return Number(value);
}

export function getRandomElement(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export function trimSentence(strippedString, maxWords) {
  if (!strippedString) {
    return '';
  }
  const array = strippedString.split(' ');
  const arrayLength = array.length;
  let string = array.splice(0, maxWords).join(' ');
  if (arrayLength > maxWords) {
    string += ' ...';
  }
  return string;
}

export function trimText(text, maxLength = 200, extraText = '') {
  if (!text) {
    return '';
  }
  if (text.length <= maxLength) {
    return text;
  }

  let newText = text.slice(0, maxLength - extraText.length);
  newText += extraText;
  return newText;
}

export const zeroPad = (num, places) => String(num).padStart(places, '0'); // IE do not support padStart.
export const zeroPad2 = (num, size) => {
  num = num.toString();
  while (num.length < size) num = `0${num}`;
  return num;
};

export function createArrayFrom(min, max) {
  const array = [];
  let i = min;
  while (i <= max) {
    array.push(i);
    i += 1;
  }
  return array;
}

export function getUrlParameters(url = null) {
  const vars = {};
  const finalUrl = url || window.location.href;
  finalUrl.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
}

export function getUrlParameter(param, defaultValue = null, isNumber = false, url = null) {
  let params = null;
  if (url) {
    params = new URLSearchParams(url);
  } else {
    params = new URLSearchParams(window.location.search);
  }
  let value = params.get(param);
  if (value === null) {
    value = defaultValue;
  } else if (isNumber) {
    value = Number(value);
  }
  return value;
}

export function removeURLParams(params) {
  const url = new URL(location);
  params.forEach((param) => {
    if (url.searchParams.has(param)) {
      url.searchParams.delete(param);
    }
  });
  console.log('replaceState removeURLParams', url);
  history.replaceState(null, document.title, url);
}

export function setURLParams(params) {
  const url = new URL(location);
  Object.keys(params).forEach((item) => url.searchParams.set(item, params[item]));
  console.log('replaceState setURLParams', url);
  history.replaceState(null, document.title, url);
}

export function appendScript(scriptToAppend) {
  return new Promise((resolve) => {
    const allsuspects = document.getElementsByTagName('script');
    let flag = true;
    // console.log('allsuspects', allsuspects);
    for (let i = 0; i < allsuspects.length; i += 1) {
      if (
        allsuspects[i].getAttribute('src') !== null &&
        allsuspects[i].getAttribute('src').includes(scriptToAppend)
      ) {
        flag = false;
        break;
      }
    }
    if (flag) {
      console.log('Append script ', scriptToAppend);
      const script = document.createElement('script');
      script.src = scriptToAppend;
      script.async = true;
      script.onload = () => {
        // console.log('Loaded!');
        resolve(true);
      };
      document.body.appendChild(script);
    } else {
      resolve(true);
    }
  });
}

export function appendRawScript(scriptToAppend) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.innerHTML = scriptToAppend;
    script.async = true;
    script.onload = () => {
      resolve(true);
    };
    document.body.appendChild(script);
  });
}

export function getActivityDateTimeString(dateTime) {
  const currentDateTime = new Date();
  const diffMiliseconds = Math.abs(currentDateTime - dateTime);
  const diffSeconds = Math.round(diffMiliseconds / 1000);
  const diffMinutes = Math.round(diffMiliseconds / (1000 * 60));
  const diffHours = Math.round(diffMiliseconds / (1000 * 60 * 60));
  if (diffSeconds < 10) {
    return I18n.t('Just now');
  } else if (diffSeconds < 30) {
    return I18n.t('A few seconds ago');
  } else if (diffSeconds < 60) {
    return `${diffSeconds} ${I18n.t('seconds ago')}`;
  } else if (diffMinutes < 60) {
    if (diffMinutes === 1) {
      return I18n.t('one minute ago');
    }
    return `${diffMinutes} ${I18n.t('minutes ago')}`;
  } else if (diffHours < 24) {
    if (diffHours === 1) {
      return I18n.t('One hour ago');
    }
    return `${diffHours} ${I18n.t('hours ago')}`;
  } else if (currentDateTime.getYear() === dateTime.getYear()) {
    return `${formatTime(dateTime, 'MMMM Do')} ${I18n.t('at')} ${formatTime(dateTime, 'LT')}`;
  }
  return `${formatTime(dateTime, 'LL')} ${I18n.t('at')} ${formatTime(dateTime, 'LT')}`;
}

export function invertColor(hex, bw = true) {
  if (!hex) {
    return '';
  }
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    return '#6C81AF';
  }
  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return `#${zeroPad(r, 2)}${zeroPad(g, 2)}${zeroPad(b, 2)}`;
}

export function getDomain(str) {
  try {
    let url = str.trim();
    if (!url.startsWith('http')) {
      url = `http://${url}`;
    }
    const urlObj = new URL(url);
    const arr = urlObj.hostname.split('.');
    if (arr.length < 2) {
      return null;
    }
    return arr.slice(-2).join('.');
  } catch (e) {
    return null;
  }
}

export function sleep(value, type = 'seconds') {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), type === 'seconds' ? value * 1000 : value);
  });
}

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
}

export async function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error(err);
    sendNotification(err.message, { type: 'error' });
  }
}

export function getPasswordStrength(pwString) {
  if (!pwString) {
    return 0;
  }
  let strength = 0;
  if (!/.{8}/.test(pwString)) {
    return 0;
  } else {
    strength += 1;
  }
  strength += /.{9}/.test(pwString) ? 1 : 0;
  strength += /[0-9]+/.test(pwString) ? 1 : 0;
  strength += /[A-Z]+/.test(pwString) ? 1 : 0;
  strength += /[a-z]+/.test(pwString) ? 1 : 0;
  strength += /[\W]+/.test(pwString) ? 1 : 0;
  return strength;
}

export function isGuid(guid) {
  let s = `${guid}`;

  s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
  if (s === null) {
    return false;
  }
  return true;
}

export function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1).toLowerCase();
}

export function enterFullscreen() {
  if (document.requestFullScreen) {
    document.requestFullScreen();
  } else if (document.mozRequestFullScreen) {
    document.mozRequestFullScreen();
  } else if (document.webkitRequestFullScreen) {
    document.webkitRequestFullScreen();
  } else if (document.msRequestFullscreen) {
    document.msRequestFullscreen();
  }
}

export function exitFullscreen() {
  if (document.cancelFullScreen) {
    document.cancelFullScreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}

export function popupWindow(url, title, w, h) {
  const y = window.outerHeight / 2 + window.screenY - h / 2;
  const x = window.outerWidth / 2 + window.screenX - w / 2;
  return window.open(
    url,
    title,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  );
}

export function diffArray(array1, array2) {
  let diff = false;
  if (array1.length >= array2.length) {
    diff = _differenceBy(array1, array2, 'email');
  } else {
    diff = _differenceBy(array2, array1, 'email');
  }
  return diff;
}

export function getBffClientUrl() {
  if (process.env.REACT_APP_ENV === 'local') {
    return process.env.REACT_APP_BFF_CLIENT;
  }
  return `${window.location.origin}/bffclient`;
}

export function getAppUrl() {
  return window.location.origin;
}

export function getSignInUrl(returnUrl, isSilentLogin) {
  const url = getBffClientUrl();

  if (isSilentLogin) {
    return `${url}/bff/silent-login?returnUrl=${returnUrl}`;
  } else {
    return `${url}/bff/login?returnUrl=${returnUrl}`;
  }
}

export function getCoreApi() {
  return `${getBffClientUrl()}/api/core`;
}

export function getPortalHubUrl() {
  return `${getBffClientUrl()}/api/core/portal`;
}

export function getSpaceHubUrl() {
  return `${window.location.origin}/api/core/runtime`;
}

export function getCoreRuntimeApi() {
  return `${window.location.origin}/api/core`;
}

export function getResourceApi() {
  return `${getBffClientUrl()}/api/resources`;
}

export function getLMSApi() {
  return `${getBffClientUrl()}/api/lms`;
}

export function getLMSRuntimeApi() {
  return `${window.location.origin}/api/lms`;
}

export function getROIApi() {
  return `${getBffClientUrl()}/api/roi`;
}

export function getROIRuntimeApi() {
  return `${window.location.origin}/api/roi`;
}

export function getDMSApi() {
  return `${getBffClientUrl()}/api/dms`;
}

export function getDMSRuntimeApi() {
  return `${window.location.origin}/api/dms`;
}

export function getResourceRuntimeApi() {
  return `${window.location.origin}/api/resources`;
}

export function getMediaKindProxyUrl() {
  return `${window.location.origin}/api/resources/api/runtime/MediaKindProxy/Manifest`;
}

export function getManifestProxyUrl() {
  // if localhost
  if (process.env.REACT_APP_ENV === 'local') {
    return 'https://spp.dev2.spce.com/api/resources/api/runtime/ManifestProxy/Manifest';
  }
  return `${getAppUrl()}/api/resources/api/runtime/ManifestProxy/Manifest`;
}

export function getInsightsAnalyticApi() {
  return `${getBffClientUrl()}/api/insights`;
}

export function getInsightsAnalyticRuntimeApi() {
  return `${window.location.origin}/api/insights`;
}

export function getIntegrationApi() {
  return `${getBffClientUrl()}/api/integrations`;
}

export function getIntegrationRuntimeApi() {
  // if (process.env.REACT_APP_ENV === 'local') {
  //   return `https://localhost:5003`;
  // }
  return `${window.location.origin}/api/integrations`;
}

export function isProductionEnv() {
  return location.origin === 'https://app.spce.com';
}

export function urlEncodeData(data) {
  return Object.keys(data)
    .map((key) => [key, data[key]].map(encodeURIComponent).join('='))
    .join('&');
}

export function truncateText(str) {
  if (str.length > 30) {
    return `${str.slice(0, 30)}...`;
  }
  return str;
}

export function getNotificationApi() {
  return `${getBffClientUrl()}/api/notifications`;
}

export function getNotificationRuntimeApi() {
  return `${window.location.origin}/api/notifications/runtime`;
}

export function getIdentityApi(useBff = true) {
  if (!useBff) {
    return `${window.location.origin}/identity`;
  }
  return `${getBffClientUrl()}/identity`;
}

export function getFeatureFlagApi() {
  return `${getBffClientUrl()}/api/feature-flags`;
}

export function getFeatureFlagRuntimeApi() {
  return `${window.location.origin}/api/feature-flags`;
}

export function getFirstNameAndLastNameFromEmail(email) {
  if (!email) {
    return { firstName: '', lastName: '', fullName: '' };
  }
  // Split the email address at the "@" symbol
  const [localPart] = email.split('@');

  // Split the local part at "." or "_"
  const s = localPart.split(/[._]/);

  const firstName = s[0];
  const lastName = s[s.length - 1];
  const fullName = `${firstName} ${lastName}`;

  return { firstName, lastName, fullName };
}

export function extractNamesFromEmail(email) {
  // Split the email address at the "@" symbol
  const [localPart] = email.split('@');

  // Split the local part at "." or "_"
  const nameParts = localPart.split(/[._]/);

  let firstName = '';
  let lastName = '';

  if (nameParts.length === 1) {
    // If there's only one part, it's the first name
    firstName = nameParts[0];
  } else if (nameParts.length === 2) {
    // If there are two parts, they are the first name and last name
    firstName = nameParts[0];
    lastName = nameParts[1];
  } else if (nameParts.length > 2) {
    // If there are more than two parts, the first part is the first name
    // and the rest are the last name
    firstName = nameParts[0];
    lastName = nameParts.slice(1).join(' ');
  }

  // Capitalize the first letter of each name part
  firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  lastName = lastName
    .split(' ')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');

  return { firstName, lastName };
}

export function getHttpsUrl(url) {
  let newUrl = url;
  if (!url || url.startsWith('https://')) {
    newUrl = url;
  } else if (url.startsWith('http://')) {
    newUrl = url.replace('http://', 'https://');
  } else {
    newUrl = `https://${url}`;
  }
  return newUrl;
}

export function refineUrl(url) {
  let finalUrl = url;
  if (!url.match(/^https?:\/\//i)) {
    finalUrl = `http://${finalUrl}`;
  }
  return finalUrl;
}

export function openWindow(url, name, specs) {
  const finalUrl = refineUrl(url);
  return window.open(finalUrl, name, specs);
}

export function range({ from = 0, to, step = 1, length = Math.ceil((to - from) / step) }) {
  return Array.from({ length }, (_, i) => from + i * step);
}

export function getPathFromUrl(url) {
  return url.split('?')[0];
}

export function getBlobDomain() {
  if (isEnv('prod') && isStaging()) {
    return process.env.REACT_APP_BLOB_STORAGE_URL_STAGING;
  }
  return process.env.REACT_APP_BLOB_STORAGE_URL;
}

export function getGoogleDriveClientId() {
  if (isEnv('prod') && isStaging()) {
    return process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID_STAGING;
  }
  return process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
}

export function getOneDriveClientId() {
  if (isEnv('prod') && isStaging()) {
    return process.env.REACT_APP_ONEDRIVE_CLIENT_ID_STAGING;
  }
  return process.env.REACT_APP_ONEDRIVE_CLIENT_ID;
}

export function readValueFromSessionStorageAsNumber(key) {
  const value = sessionStorage.getItem(key);
  if (!value) {
    return 0;
  }
  return parseInt(value);
}

const htmlEntities = ['®', '&#174;', '&reg;'];

export function containsHTMLCharacterEntities(text) {
  if (!text) return false;
  for (let index = 0; index < htmlEntities.length; index += 1) {
    const entity = htmlEntities[index];
    if (text.indexOf(entity) >= 0) {
      return true;
    }
  }
  return false;
}

export function renderHTMLCharacterEntitiesAsSuperscript(text) {
  if (!text) return text;
  let newText = text;
  for (let index = 0; index < htmlEntities.length; index += 1) {
    const pattern = htmlEntities[index];
    const replacement = `<sup>${pattern}</sup>`;
    newText = newText.replaceAll(pattern, replacement);
  }
  return newText;
}

const defaultOptions = {
  FORCE_BODY: true,
  FORBID_TAGS: ['script'],
  ADD_TAGS: ['iframe'], // spc-7534 allow iframe in html/css component
  // ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a'],
  // ALLOWED_ATTR: ['href'],
};

export const sanitize = (dirty, options) => {
  const combinedOptions = { ...defaultOptions, ...options };
  const clean = DOMPurify.sanitize(dirty, combinedOptions);
  return clean;
};

const containsNewLines = (text) => {
  return text.includes('\n');
};

export function getDisplayText(text) {
  if (!text) return { displayText: text, htmlAttributes: { children: text } };
  const hasHCEInText = containsHTMLCharacterEntities(text);
  const hasNewLine = containsNewLines(text);

  let displayText = text;
  const htmlAttributes = {};

  if (hasHCEInText) {
    displayText = renderHTMLCharacterEntitiesAsSuperscript(displayText);
    displayText = sanitize(displayText);
  }
  if (hasNewLine) {
    displayText = displayText.replace(/\n/g, '<br>');
  }
  if (hasHCEInText || hasNewLine) {
    htmlAttributes.dangerouslySetInnerHTML = { __html: displayText };
  } else {
    displayText = text;
    htmlAttributes.children = displayText;
  }
  return { displayText, htmlAttributes };
}

export function trimString(text, maxChars) {
  if (!text) {
    return '';
  }

  let string = text.slice(0, maxChars);
  if (text.length > maxChars) {
    string += '...';
  }
  return string;
}

function processSelectors(selectors) {
  if (!selectors?.length) return selectors;
  const newSelectors = selectors.slice();
  newSelectors.sort((a, b) => {
    // ASC  -> a.length - b.length
    // DESC -> b.length - a.length
    return a.length - b.length;
  });

  const results = [];
  let i = 0;
  while (true) {
    if (i >= newSelectors.length) break;
    const selector = newSelectors[i];
    results.push(selector);
    let j = i + 1;
    while (j < newSelectors.length && newSelectors[j].startsWith(selector)) j += 1;
    i = j;
  }

  return results;
}

function processStyleTagsInHTMLString(html, parentSelector) {
  if (!parentSelector || !html) return html;
  const openTag = '<style>';
  const closeTag = '</style>';
  // const pattern = /[#|\.]?[\w|:|\-|\s|\.]+(\([\w|:|\-|\s|\.]+\))?{/g;
  let start = 0;
  let processedHTML = html;
  while (true) {
    const openIndex = processedHTML.indexOf(openTag, start);
    // console.log('### openIndex', openIndex, start);
    if (openIndex < 0) {
      break;
    }

    start = openIndex + openTag.length;
    const closeIndex = processedHTML.indexOf(closeTag, start);
    // console.log('### closeIndex', closeIndex, start);
    if (closeIndex < 0) {
      // if no close tag.
      break;
    }

    // The two parameters of substr() are start and length, while for substring(), they are start and end.
    const css = processedHTML.substring(start, closeIndex);
    start = closeIndex + closeTag.length;
    if (css) {
      const replacedCss = css.replace(/{([^{}]*)}/gm, '~~~');
      let selectors = replacedCss.match(/([\w]*[#|\.]?[\w|:|\-|\s|\.|\,]+\s*)~~~/gm);
      if (selectors) {
        selectors = selectors.map((s) => s.replace('~~~', '').trim());
        selectors = [...new Set(selectors)]; // distinct
        if (selectors.length > 0) {
          selectors = processSelectors(selectors);
          // console.log('### processHTML', start, selectors);
          // eslint-disable-next-line no-loop-func
          selectors.forEach((selector) => {
            const newText = `${parentSelector} ${selector}`;
            processedHTML = processedHTML.replaceAll(selector, newText);
          });
        }
      }
    }
  }
  // console.log('### processHTML', processedHTML);
  return processedHTML;
}

export const processHTML = (html, params) => {
  const { parentSelector } = params;
  let processedHTML = html;
  // console.log('### 1', processedHTML);
  processedHTML = processStyleTagsInHTMLString(processedHTML, parentSelector);
  // console.log('### 2', processedHTML);
  return processedHTML;
};

export const getSessionStorageObject = (key) => {
  const item = sessionStorage.getItem(key);
  // console.log('typeof item', typeof item);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};

export function extractContent(value) {
  const span = document.createElement('span');
  span.innerHTML = value;
  return span.textContent || span.innerText;
}

export function isUrlsEqual(url1, url2) {
  let url1Lower = url1.toLowerCase();
  let url2Lower = url2.toLowerCase();

  if (url1Lower.endsWith('/')) {
    url1Lower = url1Lower.substring(0, url1Lower.length - 1);
  }
  if (url2Lower.endsWith('/')) {
    url2Lower = url2Lower.substring(0, url2Lower.length - 1);
  }
  if (url1Lower === url2Lower) {
    return true;
  }

  return false;
}

export const openInNewTab = (url, target = '_blank') => {
  const newWindow = window.open(url, target, 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
  return newWindow;
};

export const goToHomePage = (callback) => {
  if (callback) {
    callback();
  } else {
    window.location.href = '/';
  }
};

export function isJSON(text) {
  if (typeof text !== 'string') return false;
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    console.log('testJSON error', error);
  }
  return false;
}

export const getPathNameFromUrl = memoizeOne((url) => {
  if (!url || url === '__unavailable__') return '';
  if (!url.startsWith('http')) return url;
  const urlObj = new URL(url);
  return urlObj?.pathname;
});

export const isOrgRole = (role, roleCheck) => {
  if (_isString(role)) {
    return role === roleCheck;
  } else {
    const roleNames = {
      0: 'Admin',
      1: 'User',
      2: 'Partner',
    };
    return roleNames[role] === roleCheck;
  }
};

export function setCookie(cname, cValue, exDays, domain = '') {
  const d = new Date();
  d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  let cookie = `${cname}=${cValue};${expires};path=/`;
  if (domain) {
    cookie += `;domain=${domain}`;
  }
  document.cookie = cookie;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getAppInsightsKey() {
  if (isEnv('prod') && isStaging()) {
    return process.env.REACT_APP_INSIGHTS_KEY_STAGING;
  }
  return process.env.REACT_APP_INSIGHTS_KEY;
}
