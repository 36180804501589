import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import './HomeButton.scss';
import { Ids } from '../commons/pendoTaggings';

const HomeButton = ({ link }) => {
  const navigate = useNavigate();

  function handleOnClick() {
    navigate(link);
  }

  return (
    <IconButton
      className="button-icon back-to-home-button"
      data-id={Ids.BackToHomeButton}
      onClick={handleOnClick}
    >
      <i className="icon icon-home"></i>
    </IconButton>
  );
};

HomeButton.propTypes = {
  link: PropTypes.string,
};

HomeButton.defaultProps = {
  link: '/',
};

export default HomeButton;
