/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

import MaterialTable, { Icons, MaterialTableProps } from '@material-table/core';
import TablePagination from './TablePagination';
import NoSpaceAnalyticsData from '../NoSpaceAnalyticsData';
import './style.scss';
import i18n from '../../../i18n';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tableIcons: Icons<any> = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TableProps = MaterialTableProps<any> & {
  emptyDataSourceMessage?: string;
  emptyState?: {
    icon?: React.ReactNode;
    message?: string;
  };
};

const Table: React.FC<TableProps> = (props) => {
  const { data, emptyState } = props;
  const isPaging = !!data?.length && data?.length > 7;

  if ((!data?.length || data.length === 0) && emptyState) {
    return (
      <div style={{ marginTop: 30 }} className="no-data-container">
        <NoSpaceAnalyticsData icon={emptyState.icon} message={emptyState.message} />
      </div>
    );
  }

  return (
    <div className="table-insight">
      <MaterialTable
        {...props}
        icons={tableIcons}
        options={{
          draggable: false,
          showTitle: false,
          searchFieldAlignment: 'left',
          searchFieldVariant: 'outlined',
          searchFieldStyle: {
            padding: '4px 0px 4px 12px',
            borderRadius: '4px',
          },
          headerStyle: {
            fontFamily: 'MediumLLWeb-Bold',
            position: 'sticky',
            top: 0,
            whiteSpace: 'nowrap',
            backgroundColor: '#fff',
          },
          pageSize: 7,
          pageSizeOptions: [7, 25, 50],
          paginationType: 'stepped',
          thirdSortClick: false,
          maxBodyHeight: 500,
          minBodyHeight: 20,
          emptyRowsWhenPaging: false,
          search: false,
          toolbar: false,
          paging: isPaging,
          ...props.options,
        }}
        style={{
          boxShadow: 'none',
          position: 'relative',
        }}
        components={{ Pagination: TablePagination }}
        localization={{
          body: {
            emptyDataSourceMessage:
              props.emptyDataSourceMessage || 'There is no data available to display.',
          },
        }}
      />
    </div>
  );
};

Table.defaultProps = {
  emptyState: {
    message: i18n.t('No data to show'),
  },
};

export default Table;
