import { GoogleDriveItem, GoogleDriveTreeItem } from './googleDrive.types';

export function createGoogleDriveRootFolderModel(treeItem: GoogleDriveTreeItem): GoogleDriveItem {
  return {
    driveId: treeItem.driveId,
    id: treeItem.id,
    name: treeItem.name,
    isFolder: true,
    modifiedTime: treeItem.modifiedTime,
    version: treeItem.version,
    path: treeItem.path,
    pathName: treeItem.pathName,
    isSharedWithMe: treeItem.isSharedWithMe,
    itemsCount: treeItem.itemsCount,
  };
}
export function createGoogleDriveRootFoldersPayload(
  selectedFolders: GoogleDriveTreeItem[]
): GoogleDriveItem[] {
  return selectedFolders.map(createGoogleDriveRootFolderModel);
}

export const mapGoogleDriveSortBy = (originalSortBy: string) => {
  switch (originalSortBy) {
    case 'fileName':
      return 'name';
    case 'lastModified':
      return 'modifiedTime';
    case 'itemsCount':
      return 'folder';
    default:
      return 'name';
  }
};
