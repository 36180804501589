import React from 'react';
import { LinearProgress, linearProgressClasses } from '@mui/material';

type LinearProgressProps = {
  backgroundColor?: string;
  color?: 'primary' | 'secondary' | string;
  value: number;
  height?: number;
};

const LinearProgressBar = (props: LinearProgressProps) => {
  const { value, color, height, backgroundColor } = props;
  if (props.color === 'primary' || props.color === 'secondary') {
    return (
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          height: height || 6,
          backgroundColor: backgroundColor,
        }}
      />
    );
  }
  // a trick to avoid warning in color property
  return (
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        height: height || 6,
        backgroundColor: backgroundColor,
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 0,
          backgroundColor: color,
        },
      }}
    />
  );
};

export default LinearProgressBar;
