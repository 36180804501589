import { Button, LinearProgress } from '@mui/material';
import React from 'react';
import { UploadingFileState } from '../../app/appConstants';
import i18n from '../../i18n';

export interface IUploadStatusProps {
  totalFiles: number;
  totalUploadedFiles: number;
  uploadingState: number;
  onCancel: () => void;
  progress: number;
}

export default function UploadStatus(props: IUploadStatusProps) {
  const { uploadingState, progress, totalUploadedFiles, totalFiles, onCancel } = props;

  return (
    <div className="upload-progress">
      <span className="file-name">
        {`Uploading ${totalUploadedFiles} of ${totalFiles} ${totalFiles > 1 ? 'items' : 'item'}`}
      </span>
      <LinearProgress variant="determinate" value={progress} />
      <div className="bottom-section">
        {uploadingState === UploadingFileState.Completed && (
          <span className="message completed">{i18n.t('Complete')}</span>
        )}
        {uploadingState <= UploadingFileState.Started && (
          <>
            <span className="message">{`${i18n.t('Uploading')} ${progress}%...`}</span>
            <Button className="action-button" onClick={onCancel}>
              {i18n.t('Cancel')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
