import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/system';
import ConnectFailedDialog from './ConnectFailedDialog';
import { getTheme } from '../theme';

let resolve;
let containerElement;
let rootEl;

class ConnectFailedDialogService extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillUnmount() {
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (containerElement) {
      rootEl.unmount();
    }
    if (resolve) resolve(retVal);
  }

  static show(serviceProvider, failureMessage) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <ConnectFailedDialogService
          serviceProvider={serviceProvider}
          failureMessage={failureMessage}
        />
      </ThemeProvider>
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose() {
    this.setState({ isOpen: false }, () => {
      ConnectFailedDialogService.destroy();
    });
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }
    const { failureMessage, serviceProvider } = this.props;
    return (
      <ConnectFailedDialog
        failureMessage={failureMessage}
        serviceProvider={serviceProvider}
        submitButtonContent="OK"
        isOpen={failureMessage !== undefined}
        onClose={this.handleClose}
        onSubmit={this.handleClose}
        showCloseIcon={false}
      />
    );
  }
}

ConnectFailedDialogService.propTypes = {
  failureMessage: PropTypes.string,
  serviceProvider: PropTypes.string,
};

export default ConnectFailedDialogService;
