import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import './ContentStyleTab.scss';
import IconButton from '@mui/material/IconButton';
import { useDebouncedCallback } from 'use-debounce';
import _cloneDeep from 'lodash/cloneDeep';
import i18n from '../../../i18n';
import ColorPicker from '../../commons/ColorPicker';
import FontsSelect from '../../commons/FontSelect/FontSelect';
import { CMS_COMPONENT_STYLE, CMS_COMPONENT_TYPE } from '../../cmsConstants';
import FontSizeSelect from '../../commons/FontSizeSelect';
import { getShowOptions } from '../../cmsUtils';
import CmsPaddingOptions from '../../commons/CmsPaddingOptions';
import LightTooltip from '../../../components/LightTooltip';
import { useThemeSettingsContext } from '../context/ThemeSettingsContextProvider';

const ContentStyleTab = (props) => {
  const { layout, onChange, defaultDesignSettings } = props;
  const { selectedBlock, updateBlock, savedColors } = useThemeSettingsContext();

  const { showHeading, showDescription, showButton, showSecondaryButton } =
    getShowOptions(selectedBlock);

  let firstItem = selectedBlock?.items?.[0];
  const isConstrainedPadding = selectedBlock?.isConstrainedPadding || false;
  // console.log('### ContentStyleTab', isConstrainedPadding, selectedBlock);
  const [isActive, setIsActive] = useState(isConstrainedPadding); // for isConstrainedPadding

  // current settings > org settings > default settings.
  let headingColor = defaultDesignSettings?.headingColor;
  let headingFontFamily = defaultDesignSettings?.headingFontFamily;
  let headingFontSize = defaultDesignSettings?.headingFontSize;
  let descriptionColor = defaultDesignSettings?.descriptionColor;
  let descriptionFontFamily = defaultDesignSettings?.descriptionFontFamily;
  let descriptionFontSize = defaultDesignSettings?.descriptionFontSize;
  let buttonLabelColor = defaultDesignSettings?.buttonLabelColor;
  let buttonLabelFontFamily = defaultDesignSettings?.buttonLabelFontFamily;
  let buttonLabelFontSize = defaultDesignSettings?.buttonLabelFontSize;
  let buttonBackgroundColor = defaultDesignSettings?.buttonBackgroundColor;
  let secondaryButtonLabelColor = defaultDesignSettings?.secondaryButtonLabelColor;
  let secondaryButtonLabelFontFamily = defaultDesignSettings?.secondaryButtonLabelFontFamily;
  let secondaryButtonLabelFontSize = defaultDesignSettings?.secondaryButtonLabelFontSize;
  let secondaryButtonBackgroundColor = defaultDesignSettings?.secondaryButtonBackgroundColor;
  const blockBackgroundColor =
    selectedBlock.backgroundColor || defaultDesignSettings?.blockBackgroundColor;
  const topPaddingSize = selectedBlock.topPaddingSize || defaultDesignSettings?.topPaddingSize;
  const bottomPaddingSize =
    selectedBlock.bottomPaddingSize || defaultDesignSettings?.bottomPaddingSize;
  let rightPaddingSize = selectedBlock.rightPaddingSize || defaultDesignSettings?.rightPaddingSize;
  let leftPaddingSize = selectedBlock.leftPaddingSize || defaultDesignSettings?.leftPaddingSize;
  let underlineColor;
  let headingSelectedFontFamily;
  let headingSelectedColor;
  let headingSelectedFontSize;
  if (firstItem) {
    const componentType = firstItem.type;
    if (componentType === CMS_COMPONENT_TYPE.CAROUSEL) {
      firstItem = firstItem.items?.[0];
    }

    const isSmall =
      !!selectedBlock.size &&
      selectedBlock.size === CMS_COMPONENT_STYLE.SIZE.MEDIUM &&
      selectedBlock.columns === 1; // has the size is Small (value is medium)

    const disableGutters =
      componentType === CMS_COMPONENT_TYPE.CAROUSEL ||
      (!!selectedBlock.size &&
        (selectedBlock.size === 'fullWidth' || selectedBlock.size === 'banner') &&
        selectedBlock.columns === 1);

    if (disableGutters) {
      if (!selectedBlock.rightPaddingSize) {
        rightPaddingSize = '0px';
      }
      if (!selectedBlock.leftPaddingSize) {
        leftPaddingSize = '0px';
      }
    } else if (isSmall) {
      if (!selectedBlock.rightPaddingSize) {
        rightPaddingSize = '240px';
      }
      if (!selectedBlock.leftPaddingSize) {
        leftPaddingSize = '240px';
      }
    }
    headingColor = firstItem.headingColor || headingColor;
    headingFontFamily = firstItem.headingFontFamily || headingFontFamily;
    headingFontSize = firstItem.headingFontSize || headingFontSize;
    descriptionColor = firstItem.descriptionColor || descriptionColor;
    descriptionFontFamily = firstItem.descriptionFontFamily || descriptionFontFamily;
    descriptionFontSize = firstItem.descriptionFontSize || descriptionFontSize;
    buttonLabelColor = firstItem.buttonLabelColor || buttonLabelColor;
    buttonLabelFontFamily = firstItem.buttonLabelFontFamily || buttonLabelFontFamily;
    buttonLabelFontSize = firstItem.buttonLabelFontSize || buttonLabelFontSize;
    buttonBackgroundColor = firstItem.buttonBackgroundColor || buttonBackgroundColor;
    secondaryButtonLabelColor = firstItem.secondaryButtonLabelColor || secondaryButtonLabelColor;
    secondaryButtonLabelFontFamily =
      firstItem.secondaryButtonLabelFontFamily || secondaryButtonLabelFontFamily;
    secondaryButtonLabelFontSize =
      firstItem.secondaryButtonLabelFontSize || secondaryButtonLabelFontSize;
    secondaryButtonBackgroundColor =
      firstItem.secondaryButtonBackgroundColor || secondaryButtonBackgroundColor;

    underlineColor = firstItem.underlineColor || defaultDesignSettings?.underlineColor;
    headingSelectedFontFamily =
      firstItem.headingSelectedFontFamily || defaultDesignSettings?.headingSelectedFontFamily;
    headingSelectedColor =
      firstItem.headingSelectedColor || defaultDesignSettings?.headingSelectedColor;
    headingSelectedFontSize =
      firstItem.headingSelectedFontSize || defaultDesignSettings?.headingSelectedFontSize;
  }

  const handleHeadingColorChange = useDebouncedCallback((value) => {
    if (onChange) onChange('headingColor', value);
  }, 400);

  const handleHeadingFontFamilyChange = useDebouncedCallback((value) => {
    if (onChange) onChange('headingFontFamily', value);
  }, 400);

  const handleHeadingFontSizeChange = useDebouncedCallback((value) => {
    if (onChange) onChange('headingFontSize', value);
  }, 400);

  const handleDescriptionColorChange = useDebouncedCallback((value) => {
    if (onChange) onChange('descriptionColor', value);
  }, 400);

  const handleDescriptionFontFamilyChange = useDebouncedCallback((value) => {
    if (onChange) onChange('descriptionFontFamily', value);
  }, 400);

  const handleDescriptionFontSizeChange = useDebouncedCallback((value) => {
    if (onChange) onChange('descriptionFontSize', value);
  }, 400);

  const handleButtonLabelColorChange = useDebouncedCallback((value) => {
    if (onChange) onChange('buttonLabelColor', value);
  }, 400);

  const handleButtonLabelFontFamilyChange = useDebouncedCallback((value) => {
    if (onChange) onChange('buttonLabelFontFamily', value);
  }, 400);

  const handleButtonLabelFontSizeChange = useDebouncedCallback((value) => {
    if (onChange) onChange('buttonLabelFontSize', value);
  }, 400);

  const handleButtonBackgroundColorChange = useDebouncedCallback((value) => {
    if (onChange) onChange('buttonBackgroundColor', value);
  }, 400);

  const handleSecondaryButtonLabelColorChange = useDebouncedCallback((value) => {
    if (onChange) onChange('secondaryButtonLabelColor', value);
  }, 400);

  const handleSecondaryButtonLabelFontFamilyChange = useDebouncedCallback((value) => {
    if (onChange) onChange('secondaryButtonLabelFontFamily', value);
  }, 400);

  const handleSecondaryButtonLabelFontSizeChange = useDebouncedCallback((value) => {
    if (onChange) onChange('secondaryButtonLabelFontSize', value);
  }, 400);

  const handleSecondaryButtonBackgroundColorChange = useDebouncedCallback((value) => {
    if (onChange) onChange('secondaryButtonBackgroundColor', value);
  }, 400);

  const handleBlockBackgroundColorChange = useDebouncedCallback((value) => {
    if (onChange) onChange('blockBackgroundColor', value);
  }, 400);

  const handleUnderlineChange = useDebouncedCallback((value) => {
    if (onChange) onChange('underlineColor', value);
  }, 400);

  const handleHeadingSelectedFontFamilyChange = useDebouncedCallback((value) => {
    if (onChange) onChange('headingSelectedFontFamily', value);
  }, 400);

  const handleHeadingSelectedColorChange = useDebouncedCallback((value) => {
    if (onChange) onChange('headingSelectedColor', value);
  }, 400);

  const handleHeadingSelectedFontSizeChange = useDebouncedCallback((value) => {
    if (onChange) onChange('headingSelectedFontSize', value);
  }, 400);

  const handlePaddingChange = (prop, value) => {
    const newBlock = _cloneDeep(selectedBlock);
    newBlock[prop] = value;
    if (isActive) {
      newBlock.topPaddingSize = value;
      newBlock.bottomPaddingSize = value;
      newBlock.rightPaddingSize = value;
      newBlock.leftPaddingSize = value;
    }
    updateBlock(newBlock);
  };

  function handleLinkBtnClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const newValue = !isActive;
    setIsActive(newValue);
    const newBlock = _cloneDeep(selectedBlock);
    newBlock.isConstrainedPadding = newValue;

    if (newValue) {
      const paddingTopValue = (topPaddingSize || '0px').replace('px', '');
      const paddingBottomValue = (bottomPaddingSize || '0px').replace('px', '');
      const paddingRightValue = (rightPaddingSize || '0px').replace('px', '');
      const paddingLeftValue = (leftPaddingSize || '0px').replace('px', '');

      const paddingTop = parseInt(paddingTopValue);
      const paddingBottom = parseInt(paddingBottomValue);
      const paddingRight = parseInt(paddingRightValue);
      const paddingLeft = parseInt(paddingLeftValue);
      const maxPadding = Math.max(paddingTop, paddingBottom, paddingRight, paddingLeft);

      const maxPaddingSize = `${maxPadding}px`;
      // Get the max value among 4 paddings' values
      newBlock.topPaddingSize = maxPaddingSize;
      newBlock.bottomPaddingSize = maxPaddingSize;
      newBlock.rightPaddingSize = maxPaddingSize;
      newBlock.leftPaddingSize = maxPaddingSize;
      // console.log('### maxpadding', maxPaddingSize, newBlock);
    }

    updateBlock(newBlock, true);
  }

  const renderPaddingOptions = () => {
    return (
      <div className="padding-options">
        <section className="label-wrapper">
          <span className="label">Block padding</span>
          <LightTooltip title="Constrain padding">
            <IconButton className="constrain" onClick={handleLinkBtnClick}>
              {isActive ? (
                <LinkIcon className="icon-constrain" />
              ) : (
                <LinkOffIcon className="icon-constrain" />
              )}
            </IconButton>
          </LightTooltip>
        </section>
        <div className="four-fields">
          <div className="two-fields">
            <CmsPaddingOptions
              name="topPaddingSize"
              defaultValue={topPaddingSize}
              onChange={(value) => handlePaddingChange('topPaddingSize', value)}
              label={'T'}
              toolTip={'Top'}
            />
            <CmsPaddingOptions
              name="bottomPaddingSize"
              defaultValue={bottomPaddingSize}
              onChange={(value) => handlePaddingChange('bottomPaddingSize', value)}
              label={'B'}
              toolTip={'Bottom'}
            />
          </div>
          <div className="two-fields">
            <CmsPaddingOptions
              name="leftPaddingSize"
              defaultValue={leftPaddingSize}
              onChange={(value) => handlePaddingChange('leftPaddingSize', value)}
              label={'L'}
              toolTip={'Left'}
            />
            <CmsPaddingOptions
              name="rightPaddingSize"
              defaultValue={rightPaddingSize}
              onChange={(value) => handlePaddingChange('rightPaddingSize', value)}
              label={'R'}
              toolTip={'Right'}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPagesForm = () => {
    return (
      <>
        <div className="field">
          <ColorPicker
            label="Underline color"
            defaultColor={underlineColor}
            onChange={handleUnderlineChange}
            savedColors={savedColors}
          />
        </div>
        <div className="field">
          <FontsSelect
            label="Heading default state"
            name="headingFontFamily"
            defaultValue={headingFontFamily}
            onChange={handleHeadingFontFamilyChange}
          />
        </div>
        <div className="two-field">
          <FontSizeSelect
            name="headingFontSize"
            defaultValue={headingFontSize}
            onChange={handleHeadingFontSizeChange}
          />
          <ColorPicker
            defaultColor={headingColor}
            onChange={handleHeadingColorChange}
            savedColors={savedColors}
          />
        </div>
        <div className="field">
          <FontsSelect
            label="Heading selected state"
            name="headingSelectedFontFamily"
            defaultValue={headingSelectedFontFamily}
            onChange={handleHeadingSelectedFontFamilyChange}
          />
        </div>
        <div className="two-field">
          <FontSizeSelect
            name="headingSelectedFontSize"
            defaultValue={headingSelectedFontSize}
            onChange={handleHeadingSelectedFontSizeChange}
          />
          <ColorPicker
            defaultColor={headingSelectedColor}
            onChange={handleHeadingSelectedColorChange}
            savedColors={savedColors}
          />
        </div>
      </>
    );
  };

  const renderSpecialForm = (showColorPicker = true, isPages = false) => {
    return (
      <>
        <div className="fields">
          {showColorPicker && (
            <div className="field">
              <ColorPicker
                label="Background color"
                defaultColor={blockBackgroundColor}
                onChange={handleBlockBackgroundColorChange}
                savedColors={savedColors}
              />
            </div>
          )}
          {isPages && renderPagesForm()}
          {renderPaddingOptions()}
        </div>
      </>
    );
  };

  const renderDefaultForm = () => {
    return (
      <>
        <div className="fields">
          <div className="field">
            <ColorPicker
              label="Background color"
              defaultColor={blockBackgroundColor}
              onChange={handleBlockBackgroundColorChange}
              savedColors={savedColors}
            />
          </div>
          {showHeading && (
            <>
              <div className="field">
                <FontsSelect
                  label="Heading font"
                  name="headingFontFamily"
                  defaultValue={headingFontFamily}
                  onChange={handleHeadingFontFamilyChange}
                />
              </div>
              <div className="two-field">
                <FontSizeSelect
                  name="headingFontSize"
                  defaultValue={headingFontSize}
                  onChange={handleHeadingFontSizeChange}
                />
                <ColorPicker
                  defaultColor={headingColor}
                  onChange={handleHeadingColorChange}
                  savedColors={savedColors}
                />
              </div>
            </>
          )}
          {showDescription && (
            <>
              <div className="field">
                <FontsSelect
                  label="Description font"
                  name="descriptionFontFamily"
                  defaultValue={descriptionFontFamily}
                  onChange={handleDescriptionFontFamilyChange}
                />
              </div>
              <div className="two-field">
                <FontSizeSelect
                  name="descriptionFontSize"
                  defaultValue={descriptionFontSize}
                  onChange={handleDescriptionFontSizeChange}
                />
                <ColorPicker
                  defaultColor={descriptionColor}
                  onChange={handleDescriptionColorChange}
                  savedColors={savedColors}
                />
              </div>
            </>
          )}
          {showButton && (
            <>
              <div className="field">
                <FontsSelect
                  label="Primary button font and color"
                  name="buttonLabelFontFamily"
                  defaultValue={buttonLabelFontFamily}
                  onChange={handleButtonLabelFontFamilyChange}
                />
              </div>
              <div className="three-field">
                <FontSizeSelect
                  name="buttonLabelFontSize"
                  defaultValue={buttonLabelFontSize}
                  onChange={handleButtonLabelFontSizeChange}
                />
                <ColorPicker
                  defaultColor={buttonLabelColor}
                  onChange={handleButtonLabelColorChange}
                  tooltipMessage={i18n.t('Text color')}
                  savedColors={savedColors}
                />
                <ColorPicker
                  defaultColor={buttonBackgroundColor}
                  onChange={handleButtonBackgroundColorChange}
                  tooltipMessage={i18n.t('Background color')}
                  savedColors={savedColors}
                />
              </div>
            </>
          )}
          {showSecondaryButton && (
            <>
              <div className="field">
                <FontsSelect
                  label="Secondary button font and color"
                  name="secondaryButtonLabelFontFamily"
                  defaultValue={secondaryButtonLabelFontFamily}
                  onChange={handleSecondaryButtonLabelFontFamilyChange}
                />
              </div>
              <div className="three-field">
                <FontSizeSelect
                  name="secondaryButtonLabelFontSize"
                  defaultValue={secondaryButtonLabelFontSize}
                  onChange={handleSecondaryButtonLabelFontSizeChange}
                />
                <ColorPicker
                  defaultColor={secondaryButtonLabelColor}
                  onChange={handleSecondaryButtonLabelColorChange}
                  tooltipMessage={i18n.t('Secondary button text color')}
                  savedColors={savedColors}
                />
                <ColorPicker
                  defaultColor={secondaryButtonBackgroundColor}
                  onChange={handleSecondaryButtonBackgroundColorChange}
                  tooltipMessage={i18n.t('Secondary button background')}
                  savedColors={savedColors}
                />
              </div>
            </>
          )}
          {renderPaddingOptions()}
          <div className="alert-info">
            <span>
              The fonts you select will apply <br></br> to text across all columns of this <br></br>
              component. You can upload <br></br> fonts in{' '}
              <a href="/profile/settings" rel="noreferrer">
                Settings.
              </a>
            </span>
          </div>
        </div>
      </>
    );
  };

  const renderStyleContent = () => {
    const type = selectedBlock?.items?.[0]?.type || selectedBlock.type;
    const showColorPicker = !layout?.showBackgroundImage;
    let element;
    switch (type) {
      case CMS_COMPONENT_TYPE.PAGES:
        element = renderSpecialForm(showColorPicker, true);
        break;
      case CMS_COMPONENT_TYPE.WIDGET:
        element = renderSpecialForm(showColorPicker);
        break;
      case CMS_COMPONENT_TYPE.WEB_CONTENT:
      case CMS_COMPONENT_TYPE.HTML_CONTENT:
      case CMS_COMPONENT_TYPE.SCRIPT:
        element = renderSpecialForm();
        break;
      default:
        element = renderDefaultForm();
        break;
    }
    return element;
  };

  return <div className="cms-editing-component-panel-tabpanel-style">{renderStyleContent()}</div>;
};

ContentStyleTab.propTypes = {
  layout: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  defaultDesignSettings: PropTypes.instanceOf(Object),
};

export default ContentStyleTab;
