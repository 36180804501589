import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEnv, isStaging } from '../commons/utils';

const HandleAcceptCookie = ({ setShowAcceptCookie }) => {
  const refIframe = useRef();
  useEffect(() => {
    const handleOnReceived = (event) => {
      // console.log('handleOnReceived', event);
      const hasConsented = event.data?.hasConsented;
      if (hasConsented === true) {
        sessionStorage.setItem('hasConsented', 'true');
        setShowAcceptCookie(false);
      }
    };
    window.addEventListener('message', handleOnReceived);
    return () => {
      window.removeEventListener('message', handleOnReceived, false);
    };
  }, [setShowAcceptCookie]);

  if (!isEnv('prod')) {
    return null;
  }
  let url;
  if (isStaging()) {
    url = 'https://appstg.spce.com/static/cookieFirst.html';
  } else {
    url = 'https://app.spce.com/static/cookieFirst.html';
  }

  return (
    <iframe
      ref={refIframe}
      title="cookieFirst"
      src={url}
      style={{
        backgroundColor: 'transparent',
        zIndex: 999,
        position: 'fixed',
        width: '100%',
        height: '100%',
        border: 'none',
        bottom: '0',
      }}
    />
  );
};

HandleAcceptCookie.propTypes = {
  setShowAcceptCookie: PropTypes.func,
};
export default HandleAcceptCookie;
