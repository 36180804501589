import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import _cloneDeep from 'lodash/cloneDeep';
import './VideoMessagesTable.scss';
import OfficialButtons from '../../components/OfficialButtons';
import { VideoMessageSummaryDetails } from '../queries/useSpaceChatSummaryQuery';
import VideoMessageDialog from '../../vaam/VideoMessageDialog';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import Loading from '../../components/Loading';
import { VideoChatMessageData } from './types';
import Table from '../components/Table';
import { formatDateTime } from '../../commons/DateTimeUtils';
import { CacheKeys } from '../../app/queryCache';
import { getVideoMessageSummaryByIds } from '../services/SpaceAnalyticsService';
import useSpaceAnalyticsQuery from '../hooks/useSpaceAnalyticsQuery';
import { Ids } from '../../commons/pendoTaggings';

type VideoMessagesTableProps = {
  videoMessageDetails?: VideoMessageSummaryDetails[];
};

function VideoMessagesTable(props: VideoMessagesTableProps) {
  const { videoMessageDetails } = props;
  const [videoMessageData, setVideoMessageData] = useState<VideoMessageSummaryDetails[]>();
  const { space, openSpaceVideoMessageModal, visibleUsers, excludeUserIds } =
    useSpaceAnalyticsContext();
  const spaceId = space?.id;
  const chatMessageId = videoMessageDetails?.map((videoMessage) => videoMessage.resourceId);

  const { data: videoMessagesSummary, isLoading: isVideoMessageSummaryFetching } =
    useSpaceAnalyticsQuery({
      fetcher: getVideoMessageSummaryByIds,
      queryKey: CacheKeys.spaceAnalytics_FetchSpaceVideoMessageSummary,
      params: {
        chatMessageId: chatMessageId,
        userInfoIds: visibleUsers.map((x) => x.userInfoId),
        excludedUserInfoIds: excludeUserIds,
      },
      enabled: !!spaceId && !!chatMessageId && chatMessageId?.length > 0,
      select: (resp) => resp.value,
    });

  useEffect(() => {
    if (videoMessagesSummary !== undefined && videoMessagesSummary.data.length > 0) {
      let updatedVideoMessageDetails = _cloneDeep(videoMessageDetails);

      updatedVideoMessageDetails = updatedVideoMessageDetails?.map((videoMessage) => {
        const matchingRespItem = videoMessagesSummary.data.find(
          (respItem: VideoChatMessageData) => respItem.messageId === videoMessage.resourceId
        );
        if (matchingRespItem) {
          return {
            ...videoMessage,
            totalViews: matchingRespItem.viewCount,
          };
        }
        return videoMessage;
      });
      setVideoMessageData(updatedVideoMessageDetails);
    }
  }, [videoMessageDetails, videoMessagesSummary]);

  function handleOnClickLink(chatSummary: VideoMessageSummaryDetails) {
    openSpaceVideoMessageModal(chatSummary.resourceId, chatSummary.title);
  }

  async function handlePlayVideo(rowData: VideoMessageSummaryDetails) {
    VideoMessageDialog.show(
      spaceId,
      rowData.documentId,
      rowData.title === '' ? 'Untitled video message' : rowData.title,
      rowData.description,
      rowData.createdByUserInfoId,
      rowData.createdDate,
      false,
      null
    );
  }

  const renderContent = () => {
    const videoMessages = videoMessageData || [];

    if (isVideoMessageSummaryFetching) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }

    return (
      <Table
        columns={[
          {
            title: 'Title',
            field: 'title',
            type: 'string',
            cellStyle: {
              width: '30%',
            },
            render: (rowData: VideoMessageSummaryDetails) =>
              rowData.title === '' ? 'Untitled video message' : rowData.title,
          },
          {
            title: 'Creation date',
            field: 'createdDate',
            type: 'string',
            render: (rowData: VideoMessageSummaryDetails) =>
              formatDateTime(rowData.createdDate, 'MMM DD, yyyy'),
            cellStyle: {
              width: '30%',
            },
          },
          {
            title: 'Clicks',
            field: 'totalViews',
            defaultSort: 'desc',
            render: (rowData: VideoMessageSummaryDetails) => (
              <span
                className="btn-link"
                onClick={() => handleOnClickLink(rowData)}
                data-id={Ids.videoMessagesTotalViews}
              >
                {rowData.totalViews ?? 0}
              </span>
            ),
            cellStyle: {
              width: '20%',
            },
          },
          {
            title: 'Video',
            cellStyle: {
              width: '10%',
              fontFamily: 'Roboto',
            },
            render: (rowData: VideoMessageSummaryDetails) => (
              <OfficialButtons
                label="View"
                dataId={Ids.videoMessagesViewButtons}
                variant="rectangle-dark-grey"
                onClick={() => handlePlayVideo(rowData)}
              />
            ),
          },
        ]}
        data={videoMessages}
      />
    );
  };

  return (
    <Grid item md={12} xs={12}>
      <Box className="video-messages-table">
        <Box>{renderContent()}</Box>
      </Box>
    </Grid>
  );
}

VideoMessagesTable.propTypes = {};

export default VideoMessagesTable;
