import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Radio } from '@mui/material';

function ResourceSelection(props) {
  const { labelId, isShow, selectBoxType, disabled, checked, onChange, onClick } = props;
  const [value, setValue] = React.useState(checked);

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  if (!isShow) {
    return null;
  }

  const handleOnClick = (event) => {
    event.stopPropagation();
    if (onClick) {
      onClick();
    }
  };
  const handleOnChange = (event) => {
    setValue(event.target.checked);
    if (onChange) {
      onChange(event);
    }
  };

  if (selectBoxType === 'radio') {
    return (
      <Radio
        className="resource-radio"
        disabled={disabled}
        checked={value}
        onClick={handleOnClick}
        inputProps={{ 'aria-labelledby': labelId }}
        onChange={handleOnChange}
      />
    );
  }
  return (
    <Checkbox
      className="button-checkbox"
      color="primary"
      disabled={disabled}
      checked={value}
      onClick={handleOnClick}
      onChange={handleOnChange}
    />
  );
}

ResourceSelection.propTypes = {
  isShow: PropTypes.bool,
  labelId: PropTypes.string,
  selectBoxType: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default ResourceSelection;
