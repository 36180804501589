import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceMaterialsByIds } from '../../resources/ResourceServices';

const useSpaceMaterialsByIdsQuery = (
  spaceId,
  materialIds,
  shouldCreateInteractionToken = false
) => {
  const joinedMaterialIds = materialIds.join(',');

  const getMaterials = useQuery({
    queryKey: [
      CacheKeys.getSpaceMaterials,
      spaceId,
      joinedMaterialIds,
      shouldCreateInteractionToken,
    ],
    queryFn: async () => {
      if (!spaceId || !joinedMaterialIds.length) {
        return [];
      }
      const materials = await fetchSpaceMaterialsByIds(
        spaceId,
        joinedMaterialIds,
        shouldCreateInteractionToken
      );
      return materials;
    },

    retry: 3,
    retryDelay: () => 5000,
  });

  const materials = getMaterials.data;
  return { materials, isFetching: getMaterials.isFetching, refetchMaterials: getMaterials.refetch };
};

export default useSpaceMaterialsByIdsQuery;
