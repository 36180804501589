import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceChatDetail } from '../services/SpaceAnalyticsService';

export interface IChat {
  createdDate: string;
  ChatMessageId: number;
}

const useChatsByUserQuery = (
  spaceId: string | undefined,
  userInfoId: number,
  endDate: string,
  enabled: boolean
) => {
  const getChatsByUser = useQuery({
    queryKey: [CacheKeys.spaceAnalytics_FetchSpaceChatsByUser, spaceId, userInfoId, endDate],
    queryFn: async () => {
      if (!spaceId || !userInfoId) {
        return [];
      }
      const resp = await fetchSpaceChatDetail(spaceId, userInfoId, endDate);
      return resp;
    },

    retry: 3,
    retryDelay: () => 5000,
    enabled: enabled,
  });

  const isFetching = getChatsByUser.isFetching;
  const chats = getChatsByUser.data?.value?.chatHistory || [];

  return { chats, isFetching };
};

export default useChatsByUserQuery;
