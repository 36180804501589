import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import { fetchStatsResource } from './globalResourcesService';

const useStatsResourceQuery = (resourceFolderId: number, resourceId: number, enabled: boolean) => {
  console.log('resourceFolderId', resourceFolderId);
  const resourceStatsQuery = useQuery({
    queryKey: [CacheKeys.getResourceUsageStats, resourceFolderId, resourceId],
    queryFn: async () => {
      return fetchStatsResource(resourceFolderId, resourceId);
    },
    retry: 1,
    retryDelay: () => 3000,
    enabled: !!resourceFolderId && !!resourceId && enabled,
  });
  return {
    resourceInfo: resourceStatsQuery.data,
    isLoading: resourceStatsQuery.isLoading,
  };
};

export default useStatsResourceQuery;
