import React, { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';

export const AssessmentContext = React.createContext();

const getAssessmentContext = memoizeOne((selectedAssessment, setSelectedAssessment) => ({
  selectedAssessment,
  setSelectedAssessment,
}));

export const useAssessmentContext = () => useContext(AssessmentContext);

function AssessmentContextProvider(props) {
  const { children } = props;

  const [selectedAssessment, setSelectedAssessment] = React.useState(null);

  const context = getAssessmentContext(selectedAssessment, setSelectedAssessment);

  const handleClose = () => {
    setSelectedAssessment(null);
  };

  context.openAssessment = useCallback((assessment) => {
    setSelectedAssessment(assessment);
  }, []);

  context.closeAssessment = handleClose;

  return <AssessmentContext.Provider value={context}>{children}</AssessmentContext.Provider>;
}

AssessmentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AssessmentContextProvider;
