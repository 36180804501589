/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { DefaultParticipantRoles, ParticipantRole } from '../../app/appConstants';
import i18n from '../../i18n';
import LightTooltip from '../../components/LightTooltip';
import { pendoTrackEvent } from '../../commons/pendoTaggings';
import { MenuEnhancedProps } from '../../components/SelectEnhanced';

function ParticipantRoleSelect(props) {
  const { onChange, defaultRole, disabled, canAddHost, showTooltip } = props;

  const [value, setValue] = React.useState(() => {
    const v = DefaultParticipantRoles.find((e) => e.value === defaultRole);
    if (!v) {
      return DefaultParticipantRoles[0];
    }
    return v;
  });

  const options = useMemo(() => {
    let opts = [...DefaultParticipantRoles];
    if (!canAddHost) {
      opts = opts.filter((e) => e.value !== ParticipantRole.Host);
    }
    return opts;
  }, [canAddHost]);

  React.useEffect(() => {
    // console.log('### 703 ParticipantRoleSelect useEffect defaultRole:', defaultRole, options);
    if (defaultRole) {
      const v = options.find((e) => e.value === defaultRole);
      if (v) {
        setValue(v);
      }
    }
  }, [defaultRole, options]);

  function handleChange(e) {
    setValue(e.target.value.value);
    pendoTrackEvent('DropDownChange', { value: e.target.value.name });
    if (onChange) {
      onChange(e.target.value.value);
    }
  }

  return (
    <div className="participant-role-picker marginTop24">
      <p className="item-label">{i18n.t('Role')}</p>

      <Select
        MenuProps={MenuEnhancedProps}
        disabled={disabled}
        variant="outlined"
        // defaultValue={value}
        value={value}
        onChange={handleChange}
        renderValue={(e) => (
          <LightTooltip
            title={
              disabled &&
              i18n.t('Public showrooms are open for all visitors but you can add other hosts')
            }
            placement="bottom-start"
            disableHoverListener={!showTooltip}
            classes={{ tooltip: 'participant-form-tooltip' }}
          >
            <div className="selected-value">
              <Typography variant="inherit">{e.name}</Typography>
            </div>
          </LightTooltip>
        )}
      >
        {options.map((e) => (
          <MenuItem key={e.value} value={e}>
            <Typography variant="inherit">{e.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

ParticipantRoleSelect.propTypes = {
  onChange: PropTypes.func,
  defaultRole: PropTypes.number,
  disabled: PropTypes.bool,
  canAddHost: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

export default ParticipantRoleSelect;
