import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './NotiSnackbarUtils';

const anchorOriginDefault = {
  vertical: 'bottom',
  horizontal: 'right',
};

const NotiSnackbarProvider = ({ children, anchorOrigin = anchorOriginDefault }) => {
  if (!children) {
    return null;
  }
  return (
    <SnackbarProvider
      classes={{ root: 'spce-snackbar' }}
      maxSnack={1}
      preventDuplicate
      anchorOrigin={anchorOrigin}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </SnackbarProvider>
  );
};

NotiSnackbarProvider.propTypes = {
  children: PropTypes.element,
  anchorOrigin: PropTypes.instanceOf(Object),
};

export default NotiSnackbarProvider;
