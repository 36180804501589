import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import { CMS_DEFAULT_DESIGN_SETTING } from '../cmsConstants';
import { processHTML, sanitize } from '../../commons/utils';
import './HTMLPreview.scss';
import { useMountEffect } from '../../commons/CustomHooks';
import { handleLinkNavigation, isSharingPageOrBlockInSpace } from '../cmsUtils';
import { useSpaceContext } from '../../spaces/SpaceContext';

const HTMLPreview = React.memo((props) => {
  const { containerId, htmlContent, isPortal, urlSlug, spaceId } = props;
  const elementRef = React.useRef(null);
  const aTags = React.useRef(null);

  const { scrollToBlockId } = useSpaceContext();

  const handleOnClick = (aTag) => {
    const url = aTag.getAttribute('href');
    // console.log('### clicked', aTag.href, url);
    const paramIds = isSharingPageOrBlockInSpace(url, props.spaceId, props.urlSlug);
    if (paramIds) {
      scrollToBlockId(paramIds, props.isEnabledCMSPages);
      return;
    }
    handleLinkNavigation(url, spaceId, urlSlug, isPortal);
  };

  useMountEffect(() => {
    // console.log('### 105 HTMLPreview elementRef', elementRef.current);
    if (elementRef.current) {
      aTags.current = elementRef.current.getElementsByTagName('a');
      // console.log('### 105 aTags', aTags.current);
      [...aTags.current].forEach((aTag) => {
        aTag.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();
          handleOnClick(aTag);
        });
      });
    }
  });

  React.useEffect(() => {
    return () => {
      // console.log('### 105 HTMLPreview unMount', aTags.current);
      if (aTags.current) {
        [...aTags.current].forEach((aTag) => {
          aTag.removeEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            handleOnClick(aTag);
          });
        });
      }
    };
  });

  function renderPreviewMessage() {
    if (!isPortal) return null;
    return (
      <div className="preview-message-section">
        {i18n.t('A preview of your code will be shown here')}
      </div>
    );
  }

  function getCustomStyle() {
    let customStyle = {};
    if (!isPortal && !htmlContent) {
      customStyle = {
        padding: 0,
      };
    } else {
      customStyle = {
        paddingTop: props.paddingStyle?.paddingTop || CMS_DEFAULT_DESIGN_SETTING.TOP_PADDING_SIZE,
        paddingBottom:
          props.paddingStyle?.paddingBottom || CMS_DEFAULT_DESIGN_SETTING.BOTTOM_PADDING_SIZE,
        paddingLeft: '0px',
        paddingRight: '0px',
      };
    }
    return customStyle;
  }

  if (!htmlContent) {
    return (
      <div className="cms-html-preview" style={getCustomStyle()}>
        {renderPreviewMessage()}
      </div>
    );
  }

  const cleanHtml = sanitize(htmlContent);
  // console.log('### cleanHtml', cleanHtml);
  const parentSelector = `#${containerId}`;
  const params = { parentSelector, isPortal, urlSlug, spaceId: spaceId };
  const processedHtml = processHTML(cleanHtml, params);

  return (
    <div className="cms-html-preview" style={getCustomStyle()}>
      <div id={containerId} dangerouslySetInnerHTML={{ __html: processedHtml }} ref={elementRef} />
    </div>
  );
});

HTMLPreview.propTypes = {
  containerId: PropTypes.string,
  htmlContent: PropTypes.string,
  isPortal: PropTypes.bool,
  paddingStyle: PropTypes.instanceOf(Object),
  spaceId: PropTypes.string,
  urlSlug: PropTypes.string,
  isEnabledCMSPages: PropTypes.bool,
};

export default HTMLPreview;
