import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import memoizeOne from 'memoize-one';
import _reject from 'lodash/reject';
import i18n from '../../i18n';
import SubscriptionCheckoutButton from '../../components/SubscriptionCheckoutButton';
import {
  SubscriptionType,
  premiumFeatureList,
  enterpriseFeatureList,
} from './subscriptionConstants';
import { getSubscriptionInfo } from './subscriptionValidationUtils';
import { getTagLabel } from '../../commons/Subscription/functions';

const getPremiumFeatureList = memoizeOne((type) => {
  return _reject(premiumFeatureList, (item) => item.id === type);
});

const getEnterpriseFeatureList = memoizeOne((type) => {
  return _reject(enterpriseFeatureList, (item) => item.id === type);
});

export default function SubscriptionInfo(props) {
  const { onClick, type, currentSubscription, subscriptionRequire } = props;
  const { title, description, tagMessage, showCheckoutButton } = getSubscriptionInfo(
    type,
    currentSubscription
  );
  const tagLabel = getTagLabel(currentSubscription);
  const tagRequiredLabel = getTagLabel(subscriptionRequire);
  const isNotFreemium =
    subscriptionRequire === SubscriptionType.premium ||
    subscriptionRequire === SubscriptionType.enterprise;
  console.log('description', subscriptionRequire);

  function renderEnterpriseInfo() {
    return (
      <div className="subscription-info">
        {/* {message && (
          <div className="message">
            <i className="icon-subscription-error" />
            <p>{message}</p>
          </div>
        )} */}
        <div className="body">
          {title && (
            <div className="header">
              <h1 className="title">{title}</h1>
            </div>
          )}
          {description && <p className="description">{description}</p>}
          {showCheckoutButton && (
            <div className="footer">
              <div className="button-container">
                <SubscriptionCheckoutButton
                  onClick={onClick}
                  subscriptionRequire={subscriptionRequire}
                  className={tagRequiredLabel}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (currentSubscription === SubscriptionType.enterprise) {
    return renderEnterpriseInfo();
  }

  const filteredPremiumFeatureList = getPremiumFeatureList(type);
  const filteredEnterpriseFeatureList = getEnterpriseFeatureList(type);

  return (
    <div className="subscription-info">
      {/* {message && (
        <div className="message">
          <i className="icon-subscription-error" />
          <p>{message}</p>
        </div>
      )} */}
      <div className="body">
        {title && (
          <div className="header">
            <h1 className="title">{title}</h1>
          </div>
        )}

        <>
          {isNotFreemium && (
            <p className="description">
              Go for{' '}
              <span className={`tag tag-${tagRequiredLabel} ${tagLabel}`}>{tagRequiredLabel}</span>{' '}
              and get a ton of {tagMessage} features. Including:
            </p>
          )}
          <ul>
            {subscriptionRequire === SubscriptionType.premium &&
              filteredPremiumFeatureList.map((item) => (
                <li key={item.id}>
                  <DoneRoundedIcon className="premium" />
                  {item.label}
                </li>
              ))}
            {subscriptionRequire === SubscriptionType.enterprise &&
              filteredEnterpriseFeatureList.map((item) => (
                <li key={item.id}>
                  <DoneRoundedIcon className="enterprise" />
                  {item.label}
                </li>
              ))}
          </ul>
        </>

        <section className="footer-description">
          <a href="https://www.spce.com/pricing/" target="_blank" rel="noreferrer">
            {i18n.t('See all features & team plan')}
          </a>
          <KeyboardArrowRightRoundedIcon />
        </section>
        {isNotFreemium && (
          <div className="footer">
            <div className="button-container">
              <SubscriptionCheckoutButton
                onClick={onClick}
                subscriptionRequire={subscriptionRequire}
                className={tagRequiredLabel}
                showCheckoutButton={showCheckoutButton}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

SubscriptionInfo.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  currentSubscription: PropTypes.number,
  subscriptionRequire: PropTypes.number,
};

SubscriptionInfo.defaultProps = {
  description: i18n.t(
    'But this is not the end of the galaxy and your upgrade is a single click away.'
  ),
};
