import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import useGoogleLogin from './useGoogleLogin';

const GoogleLogin = forwardRef((props, ref) => {
  const {
    children,
    clientId,
    scope,
    uxMode,
    redirectUri,
    onSuccess,
    onError,
    onClick,
    onCancelled,
    skipNativeSignIn,
  } = props;

  const { loaded, signIn } = useGoogleLogin({
    clientId,
    scope,
    uxMode,
    redirectUri,
    onSuccess,
    onError,
    onCancelled,
  });

  useImperativeHandle(ref, () => ({
    signIn,
  }));

  const handleOnClick = () => {
    if (!skipNativeSignIn) {
      signIn();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="google-login-button" disabled={!loaded} onClick={handleOnClick}>
      {children}
    </div>
  );
});

GoogleLogin.propTypes = {
  children: PropTypes.node,
  clientId: PropTypes.string,
  scope: PropTypes.string,
  uxMode: PropTypes.string,
  redirectUri: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onClick: PropTypes.func,
  onCancelled: PropTypes.func,
  skipNativeSignIn: PropTypes.bool,
};

export default GoogleLogin;
