import React from 'react';
import { Drawer } from '@mui/material';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './SidePanelDrawer.scss';
import SidePanel from './SidePanel';
import I18n from '../../i18n';
import { SidebarType } from '../../app/appConstants';

const SidePanelDrawer = ({
  open,
  onClose,
  sidebarType,
  isLoading,
  renderMaterialsTab,
  renderChatTab,
  renderParticipantsTab,
  anchor,
}) => {
  let widget = null;
  let title = '';
  let isShowCloseButton = true;
  if (isLoading) {
    widget = null;
  }

  switch (sidebarType) {
    case SidebarType.Material:
      title = I18n.t('Resources');
      isShowCloseButton = false;
      widget = renderMaterialsTab();
      break;
    case SidebarType.Chat:
      title = I18n.t('Chat');
      isShowCloseButton = false;
      widget = renderChatTab();
      break;
    case SidebarType.Participants:
      title = I18n.t('Participants');
      isShowCloseButton = false;
      widget = renderParticipantsTab();
      break;
    default:
      break;
  }

  return (
    <Drawer
      variant="persistent"
      open={open}
      className={clsx('runtime-drawer', 'MuiDrawer-root', {
        drawerLeftOpen: open && anchor === 'left',
        drawerClose: !open,
      })}
      anchor={anchor}
    >
      <div className={'runtime-drawer-widgets'}>
        <SidePanel title={title} onClose={isShowCloseButton ? onClose : null}>
          {widget}
        </SidePanel>
      </div>
    </Drawer>
  );
};

SidePanelDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  sidebarType: PropTypes.string,
  isLoading: PropTypes.bool,
  renderMaterialsTab: PropTypes.func,
  renderChatTab: PropTypes.func,
  renderParticipantsTab: PropTypes.func,
  anchor: PropTypes.string,
};

export default SidePanelDrawer;
