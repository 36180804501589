/* eslint no-bitwise: 0 */
import React from 'react';
import _debounce from 'lodash/debounce';
import { getNotificationApi } from '../commons/utils';
import queryCache, { CacheKeys, clearCache } from '../app/queryCache';
import CustomSigalRHttpClient from '../commons/CustomSigalRHttpClient';

const signalR = require('@microsoft/signalr');

const debouncedClearCacheNotifications = _debounce((message) => {
  clearCache([CacheKeys.fetchNotificationsBadges]);
  if (message.context === 'Chats') {
    clearCache([CacheKeys.fetchUserNewMessages], true);
  } else {
    if (message.messageTemplateId === 'RequestAccessToSpace') {
      clearCache([CacheKeys.fetchRequestsAccess, message.messageTemplateData.spaceId], {
        exact: true,
      });
    }
    clearCache([CacheKeys.fetchUserNotifications], true);
  }
}, 1000);

function createConnection() {
  const url = `${getNotificationApi()}/portal`;
  return new signalR.HubConnectionBuilder()
    .withUrl(url, { httpClient: new CustomSigalRHttpClient() })
    .withAutomaticReconnect()
    .build();
}

class NotificationSignalRHandler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.connection = null;
    this.shouldStopConnection = false;
  }

  async componentDidMount() {
    if (this.shouldStopConnection) {
      this.cleanUpConnection();
      this.shouldStopConnection = false;
      return;
    }
    this.connection = createConnection();

    this.connection
      .start()
      .then(() => {
        console.log('SignalRHandler Connected');
      })
      .catch((error) => {
        this.shouldStopConnection = true;
        console.log(`SignalRHandler Fail: ${error.message}`);
      });

    this.connection.on('UserSpaceNotificationAdded', (message) => {
      debouncedClearCacheNotifications(message);
    });
    this.connection.on('SpaceNotificationSettingsUpdated', (message) => {
      const { spaceId, enabled } = message;
      queryCache.setQueryData([CacheKeys.fetchSpaceNotificationSettings, spaceId], (cacheData) => {
        if (!cacheData) {
          return cacheData;
        }
        return { ...cacheData, enabled };
      });
    });
  }

  async cleanUpConnection() {
    if (
      (this.connection && this.connection?.state === 'Connecting') ||
      this.connection?.state === 'Reconnecting'
    ) {
      this.shouldStopConnection = true;
      return;
    }
    if (!this.connection) return;

    this.connection.off();
    await this.connection.stop();
    this.connection = null;
  }

  componentWillUnmount() {
    this.cleanUpConnection();
  }

  render() {
    return null;
  }
}

export default NotificationSignalRHandler;
