import React from 'react';
import { createRoot } from 'react-dom/client';

import SubscriptionPopup from './SubscriptionPopup';
import {
  SubscriptionType,
  SubscriptionFeature,
  // SubscriptionMessage,
  SubscriptionFeaturesTypes,
} from './subscriptionConstants';
import i18n from '../../i18n';

let containerElement;
let rootEl;

export function closePopup() {
  setTimeout(() => {
    rootEl.unmount();
    containerElement = null;
  }, 0);
}

export function getSubscriptionInfo(type, currentSubscription) {
  let title = i18n.t('Do you want to up your SP_CE game?');
  let description = '';
  let tagMessage = '';
  let showCheckoutButton = false;
  switch (type) {
    case SubscriptionFeaturesTypes.common:
      description = '';
      tagMessage = 'extra';
      break;
    case SubscriptionFeaturesTypes.enterprise:
      description = '';
      tagMessage = 'next-level';
      break;
    case SubscriptionFeaturesTypes.space:
      description = `${i18n.t('With Premium you also get access to awesome features like')}:`;
      break;
    case SubscriptionFeaturesTypes.participant:
      if (currentSubscription === SubscriptionType.free) {
        description = `${i18n.t('With Premium you also get access to awesome features like')}:`;
      } else if (currentSubscription === SubscriptionType.premium) {
        description = `${i18n.t('With Enterprise you also get access to awesome features like')}:`;
      } else if (currentSubscription === SubscriptionType.enterprise) {
        description = `${i18n.t('We can help. Would you like us to contact you?')}`;
      }
      break;
    case SubscriptionFeaturesTypes.breakout:
      description = `${i18n.t('With Premium you also get access to awesome features like')}:`;
      break;
    case SubscriptionFeaturesTypes.storage:
      if (currentSubscription === SubscriptionType.free) {
        description = `${i18n.t('With Premium you also get access to awesome features like')}:`;
      } else if (currentSubscription === SubscriptionType.premium) {
        description = `${i18n.t('With Premium you also get access to awesome features like')}:`;
      } else if (currentSubscription === SubscriptionType.enterprise) {
        description = `${i18n.t('We can help. Would you like us to contact you?')}`;
      }
      break;
    case SubscriptionFeaturesTypes.recording:
      description = `${i18n.t(
        'With Enterprise plan you will get access to awesome features like'
      )}:`;
      break;
    case SubscriptionFeaturesTypes.user:
      title = i18n.t('whoops! you’ve filled all your seats');
      description = 'Talk to Sales to add more seats and invite more users.';
      showCheckoutButton = true;
      break;
    default:
      break;
  }
  return {
    title,
    description,
    tagMessage,
    showCheckoutButton,
  };
}

export function showPopup(
  type,
  validationMessage,
  currentSubscription,
  subscriptionRequire,
  showCheckoutButton
) {
  containerElement = document.createElement('div');
  rootEl = createRoot(containerElement);
  // console.log('subscriptionRequire', subscriptionRequire);
  rootEl.render(
    <SubscriptionPopup
      open
      onClose={closePopup}
      type={type}
      message={validationMessage}
      currentSubscription={currentSubscription}
      subscriptionRequire={subscriptionRequire}
      showCheckoutButton={showCheckoutButton}
    />
  );
}

export function validateActivation() {
  return {
    isValid: true,
    message: null,
  };
}

export function validateExternalTools() {
  return {
    isValid: true,
    message: null,
  };
}

export function validateBreakout(data, subscriptionType) {
  const flag = subscriptionType !== SubscriptionType.premium;
  return {
    isValid: flag,
    type: SubscriptionFeaturesTypes.breakout,
    // message: !flag ? SubscriptionMessage[subscriptionType][SubscriptionFeature.breakout] : null,
    subscriptionRequire: SubscriptionType.enterprise,
  };
}

export function validateRoomSettings(data, subscriptionType) {
  const flag = subscriptionType !== SubscriptionType.free;
  return {
    isValid: flag,
    // message: !flag ? SubscriptionMessage[subscriptionType][SubscriptionFeature.roomSettings] : null,
    description: !flag
      ? i18n.t(
          'Changing the theme will affect all your spaces. Go for Premium and give each space a unique design'
        )
      : null,
    subscriptionRequire: SubscriptionType.premium,
  };
}

export function validateRecording(subscriptionType) {
  const flag = subscriptionType === SubscriptionType.enterprise;
  return {
    isValid: flag,
    type: SubscriptionFeaturesTypes.recording,
    // message: !flag ? SubscriptionMessage[subscriptionType][SubscriptionFeature.recording] : null,
    subscriptionRequire: SubscriptionType.enterprise,
  };
}

export function validateSpotlight(data, subscriptionType) {
  const flag = subscriptionType === SubscriptionType.enterprise;
  return {
    isValid: flag,
    type: SubscriptionFeaturesTypes.spotlight,
    // message: !flag ? SubscriptionMessage[subscriptionType][SubscriptionFeature.spotlight] : null,
    subscriptionRequire: SubscriptionType.enterprise,
  };
}

export function validateSubscription(feature, data, subscriptionType) {
  let validationResp = true;
  switch (feature) {
    case SubscriptionFeature.activation:
      validationResp = validateActivation();
      break;
    case SubscriptionFeature.externalTools:
      validationResp = validateExternalTools();
      break;
    case SubscriptionFeature.breakout:
      validationResp = validateBreakout(data, subscriptionType);
      break;
    case SubscriptionFeature.roomSettings:
      validationResp = validateRoomSettings(data, subscriptionType);
      break;
    case SubscriptionFeature.recording:
      validationResp = validateRecording(subscriptionType);
      break;
    case SubscriptionFeature.spotlight:
      validationResp = validateSpotlight(data, subscriptionType);
      break;
    default:
      break;
  }
  if (!validationResp.subscriptionRequire) {
    validationResp.subscriptionRequire = SubscriptionType.premium;
  }
  return validationResp;
}

export function getRequireSubscription(currentSubscription) {
  let subscriptionRequire = SubscriptionType.premium;
  if (currentSubscription === SubscriptionType.free) {
    subscriptionRequire = SubscriptionType.premium;
  }
  if (currentSubscription === SubscriptionType.premium) {
    subscriptionRequire = SubscriptionType.enterprise;
  } else if (currentSubscription === SubscriptionType.enterprise) {
    subscriptionRequire = SubscriptionType.enterprise;
  }

  return subscriptionRequire;
}
