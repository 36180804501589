/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const defaultProps = {
  isProcessing: false,
  className: null,
  processingText: 'Processing...',
  variant: 'contained',
  color: 'primary',
  size: 'small',
};

const ButtonEnhanced = (props) => {
  const {
    // not passing down these props
    isProcessing,
    processingText,
    ...newProps
  } = { ...defaultProps, ...props };
  if (props.isProcessing) {
    return (
      <Button {...newProps} disabled>
        {props.processingText}
      </Button>
    );
  }
  return <Button {...newProps}>{props.children}</Button>;
};

ButtonEnhanced.propTypes = {
  isProcessing: PropTypes.bool,
  className: PropTypes.string,
  tooltipMessage: PropTypes.string,
  processingText: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ButtonEnhanced;
