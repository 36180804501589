import { useState } from 'react';

const useWaypoint = () => {
  const [visible, setVisible] = useState(false);

  const onEnter = () => {
    setVisible(true);
  };

  return { visible, onEnter };
};

export default useWaypoint;
