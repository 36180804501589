import { useCallback, useState } from 'react';
import { ViewModeType } from '../../resources/resourceConstants';

const useViewMode = (
  id: string | null = null,
  userInfoId: number | null = null,
  isMobile: boolean | null = false
) => {
  const key = id && userInfoId ? `view-mode-${userInfoId}` : null;
  console.log('key', key);
  const [viewMode, setViewMode] = useState(() => {
    if (isMobile || !key || !id) return ViewModeType.LIST;
    try {
      // Get from local storage by key
      const items = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      const parsedItems = items ? JSON.parse(items) : {};
      return parsedItems?.[id] ?? ViewModeType.LIST;
    } catch (error) {
      return ViewModeType.LIST;
    }
  });

  const setValue = useCallback(
    (value: string) => {
      setViewMode(value);
      if (!key || !id) return;
      try {
        // Save to local storage
        const items = window.localStorage.getItem(key);
        const parsedItems = items ? JSON.parse(items) : {};
        parsedItems[id] = value;
        window.localStorage.setItem(key, JSON.stringify(parsedItems));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [key, id]
  );
  if (isMobile) {
    return [ViewModeType.LIST, setValue] as [string, (value: string) => void];
  }

  return [viewMode, setValue] as [string, (value: string) => void];
};

export default useViewMode;
