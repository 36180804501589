import React, { useState, useEffect, useRef, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import './Chat.scss';
import { addChannel, getChannelByParticipants, joinChannel, leaveChannel } from './CommentService';
import Loading from '../../components/Loading';
import CommentsContainer from './CommentsContainer';
import i18n from '../../i18n';
import { getSendToParticipantId } from './CommentUtils';
import UserAvatar from '../../components/UserAvatar';
import useUnmountEffect from '../../commons/useUnmountEffect';
import { useSpaceContext } from '../SpaceContext';
import { ChannelType } from '../../app/appConstants';

function getParticipantIds(participants) {
  const ids = [];
  participants.forEach((item) => {
    ids.push(item.userInfoId);
  });
  return ids;
}

const Chat = forwardRef((props, ref) => {
  const { participants, onLoaded, isChatWiget } = props;
  const { signalRConnection, openedP2PChannels, setOpenedP2PChannels } = useSpaceContext();
  const signalRConnectionId = signalRConnection?.connectionId;
  const [isLoading, setIsLoading] = useState(true);
  const [channelId, setChannelId] = useState(null);
  const participant = useMemo(() => {
    return participants.find((p) => p.userInfoId !== props.participantId);
  }, [participants, props.participantId]);
  const commentsRef = useRef();

  useEffect(() => {
    async function handleAddChannel() {
      const participantIds = getParticipantIds(props.participants);
      const data = {
        channelType: ChannelType.P2P,
        broadcastChannelId: props.mainChannelId,
        participants: participantIds,
      };
      const newChannelId = await addChannel(props.spaceId, data);
      setIsLoading(false);
      setChannelId(newChannelId);
      onLoaded({ id: newChannelId, participants, channelType: ChannelType.P2P });
      return newChannelId;
    }

    async function handleCheckAndAddChannel() {
      const participantIds = getParticipantIds(props.participants);
      const channel = await getChannelByParticipants(props.spaceId, participantIds);
      let cId;
      if (channel) {
        setChannelId(channel.id);
        setIsLoading(false);
        onLoaded({ ...channel, participants });
        cId = channel.id;
      } else {
        console.log('### 202 handleAddChannel');
        cId = await handleAddChannel();
      }
      if (cId && signalRConnectionId) {
        console.log(
          '### 202 handleCheckAndAddChannel 1: ',
          cId,
          signalRConnectionId,
          openedP2PChannels
        );
        let openedTimes = openedP2PChannels?.[cId] || 0;
        const isOpened = openedTimes > 0;
        if (!isOpened) {
          console.log('### 202 joinChannel');
          const resp = await joinChannel(props.spaceId, signalRConnectionId, cId, ChannelType.P2P);
          if (resp) {
            openedTimes = 1;
          }
        } else {
          openedTimes += 1;
        }
        console.log('### 202 handleCheckAndAddChannel 2: ', openedTimes);
        if (openedTimes > 0) {
          setOpenedP2PChannels(cId, openedTimes);
        }
      }
    }

    handleCheckAndAddChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.spaceId, props.participants, props.mainChannelId]);

  useUnmountEffect(() => {
    if (channelId && signalRConnectionId) {
      console.log('### 202 useUnmountEffect 1:', channelId, signalRConnectionId, openedP2PChannels);
      let openedTimes = openedP2PChannels?.[channelId] || 0;
      if (openedTimes > 0) {
        if (openedTimes === 1) {
          console.log('### 202 leaveChannel');
          leaveChannel(props.spaceId, signalRConnectionId, channelId, ChannelType.P2P);
        }
        openedTimes -= 1;
        console.log('### 202 useUnmountEffect 2:', openedTimes);
        setOpenedP2PChannels(channelId, openedTimes);
      }
    }
  });

  function handleOnFocus() {
    if (props.onFocus) {
      props.onFocus(channelId);
    }
  }

  async function handleOnClose() {
    if (!channelId) {
      return true;
    }
    const isCloseForm = await commentsRef.current.handleCloseForm();
    return isCloseForm;
  }

  React.useImperativeHandle(ref, () => ({
    handleCloseForm: handleOnClose,
  }));

  if (isLoading) {
    return (
      <div className="loading-container">
        <Loading size="small" />
      </div>
    );
  }

  if (!participant) {
    return null;
  }

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className="back-button" onClick={props.onClose}>
          <ChevronLeftIcon />
        </div>
        <UserAvatar fullName={participant.fullName} email={participant.email} />
        <p className="name">{participant.fullName}</p>
      </div>
      <div className="chat-body">
        {channelId && (
          <CommentsContainer
            key={`comments-container-private-${props.id}`}
            ref={commentsRef}
            spaceId={props.spaceId}
            participantId={props.participantId}
            toParticipantId={getSendToParticipantId(participants, props.participantId)}
            channelId={channelId}
            channelType={ChannelType.P2P}
            placeholder={i18n.t('Enter your text here...')}
            onFocus={handleOnFocus}
            isPrivate
            isChatWiget={isChatWiget}
            onResourceView={props.onResourceView}
          />
        )}
      </div>
    </div>
  );
});

Chat.propTypes = {
  id: PropTypes.string,
  isChatWiget: PropTypes.bool,
  participants: PropTypes.arrayOf(PropTypes.shape({})),
  participantId: PropTypes.number,
  spaceId: PropTypes.string,
  mainChannelId: PropTypes.number,
  onClose: PropTypes.func,
  onFocus: PropTypes.func,
  onLoaded: PropTypes.func,
  onResourceView: PropTypes.func,
};

Chat.defaultProps = {
  onLoaded: () => {},
};

Chat.displayName = 'Chat';

export default Chat;
