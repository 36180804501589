/* eslint prefer-object-spread: 0 */
import ApiError from '../commons/ApiError';
import spaceUser from '../spaces/spaceUser';
// import participantUser from '../commons/ParticipantUser';
import i18n from '../i18n';
import ConfirmService from '../components/ConfirmService';
import { getUrlParameter } from './utils';
import { ExceptionTypes } from '../app/appConstants';
import showNotifyNotEnabledFeature from './FeatureFlags/showNotifyNotEnabledFeature';

export function errorHandling(error) {
  console.log('ERROR errorHandling', error);
  if (error instanceof Error) {
    return error;
  }
  try {
    if (error.errors && Array.isArray(error.errors)) {
      return error.errors.map((e) => new Error(e.message));
    }
    if (error.message) {
      return new Error(error.message);
    }
    return error;
  } catch (e) {
    return error;
  }
}

let showErrorTimeout = null;
async function handleShowRequestBlockError() {
  if (showErrorTimeout) {
    clearTimeout(showErrorTimeout);
  }
  showErrorTimeout = setTimeout(async () => {
    await ConfirmService.show(
      i18n.t("We're having an issue loading this space"),
      i18n.t(
        'This can be caused by a slow network connection. Please click OK and refresh your browser .'
      ),
      'OK',
      i18n.t(''),
      null
    );
    clearTimeout(showErrorTimeout);
  }, 500);
}

function isViewOnlyMode() {
  return getUrlParameter('viewOnly', '') === '1';
}
class RuntimeApi {
  static headers(initHeaders = {}) {
    // console.log('token', token);
    const date = new Date();
    const headers = Object.assign(initHeaders || {}, {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF': '1',
      'x-spce-timezone-offset': date.getTimezoneOffset(),
    });
    const userRegion = sessionStorage.getItem('user_region');
    if (userRegion) {
      const { shard, region } = JSON.parse(userRegion);
      Object.assign(headers, {
        'x-spce-user-shard': shard,
        'x-spce-user-region': region,
      });
    }
    const realIp = sessionStorage.getItem('realIp');
    if (realIp) {
      Object.assign(headers, {
        'x-spce-realip': realIp,
      });
    }
    // console.log('guestPlanner', guestPlanner);
    const spaceAccessToken = spaceUser.getAccessToken();
    if (spaceAccessToken) {
      Object.assign(headers, {
        Authorization: `Bearer ${spaceAccessToken}`,
      });
    }
    return headers;
  }

  static get(route, params, headers) {
    let url = route;
    if (params) {
      url = `${url}?${this.encodeData(JSON.parse(JSON.stringify(params)))}`;
    }
    return this.xhr(url, null, headers, 'GET');
  }

  static put(route, params, headers) {
    return this.xhr(route, params, headers, 'PUT');
  }

  static post(route, params, headers, isEncodeData, isFormData) {
    return this.xhr(route, params, headers, 'POST', isEncodeData, isFormData);
  }

  static delete(route, params, headers) {
    return this.xhr(route, params, headers, 'DELETE');
  }

  static encodeData(data) {
    return Object.keys(data)
      .map((key) => [key, data[key]].map(encodeURIComponent).join('='))
      .join('&');
  }

  static async xhr(url, params, headers, method, isEncodeData = false, isFormData = false) {
    const formData = isFormData ? params : JSON.stringify(params);
    const options = Object.assign(
      { method: method },
      params ? { body: isEncodeData ? this.encodeData(params) : formData } : null
    );
    options.headers = RuntimeApi.headers(headers);
    let resp = null;
    try {
      resp = await fetch(url, options);
    } catch (error) {
      console.error(error, { url, method });
      throw error;
    }

    if (!resp && isViewOnlyMode()) {
      handleShowRequestBlockError();
      throw new Error('Got empty response in view only mode');
    }

    if (!resp.ok) {
      try {
        if (resp.status === 403 && isViewOnlyMode()) {
          handleShowRequestBlockError();
          throw new Error('Got 403 error in view only mode');
        }
        if (resp.headers.get('content-type') === 'application/json') {
          const error = await resp.json();
          if (error.type === ExceptionTypes.FeatureNotEnabledException) {
            sessionStorage.removeItem('FeatureFlagsOrg');
            showNotifyNotEnabledFeature.show(error);
          } else {
            throw new ApiError(error, resp.status);
          }
        }
        throw new Error(resp.statusText);
      } catch (err) {
        console.error(err, { url, method });
        throw err;
      }
    }
    const contentType = resp.headers.get('content-type');
    // console.log('contentType', contentType);
    if (contentType && contentType.indexOf('text/plain') !== -1) {
      return resp.text();
    }
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return resp.json();
    }
    if (
      contentType &&
      (contentType.indexOf('application/pdf') !== -1 ||
        contentType.indexOf('text/calendar') !== -1 ||
        contentType.indexOf('application/zip') !== -1)
    ) {
      return resp.blob();
    }
    return resp.text();
  }

  static fetch(url, headers = null) {
    const options = Object.assign({ method: 'GET' });
    options.headers = {};
    options.headers = RuntimeApi.headers(headers);
    if (headers) {
      options.headers = Object.assign({}, options.headers, headers);
    }
    return fetch(url, options).then((resp) => {
      if (!resp.ok) {
        throw new Error(resp);
      }
      console.log(resp.ok);
      return resp;
    });
  }
}
export default RuntimeApi;
