import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';
import { ResourceType } from '../../../app/appConstants';
import PreviewResources from '../../components/PreviewResource';
import { CacheKeys } from '../../../app/queryCache';
import { getGoogleDriveResourceId } from '../googleDriveService';
import { SERVICE_PROVIDER } from '../../integrationConstants';
import ResourceThumbnailTs from '../../../components/ResourceThumbnail/ResourceThumbnailTs';

type GoogleDrivePreviewProps = {
  resourceType: (typeof ResourceType)[keyof typeof ResourceType];
  name?: string;
  description?: string;
  src?: string;
  lastModified?: string;
  thumbnail?: string;
  isAdmin?: boolean;
  context?: { type: string };
  id: string;
  extension?: string;
  // driveId?: string;
  isExternal?: boolean;
};

function GoogleDrivePreview(props: GoogleDrivePreviewProps) {
  const {
    id,
    name,
    resourceType,
    lastModified,
    description,
    src,
    thumbnail,
    isAdmin,
    context,
    extension,
    // driveId,
    isExternal,
  } = props;
  const isPortal = context?.type === 'portal';

  const { data: resourceId } = useQuery({
    queryKey: [CacheKeys.getGoogleDriveResourceId, id],
    queryFn: async () => {
      const resp = await getGoogleDriveResourceId(id);
      return resp?.resourceId;
    },

    enabled: true,
  });

  function renderViewer() {
    return (
      <ResourceThumbnailTs
        resourceId={id}
        // driveId={driveId}
        isPortal={isPortal}
        resourceType={resourceType}
        thumbnail={thumbnail}
        extension={extension}
        src={src}
        isExternal={isExternal}
        provider={SERVICE_PROVIDER.GOOGLE_DRIVE}
        inView
      />
    );
  }

  return (
    <Box className="onedrive-resource-preview-pane">
      <div className="preview-pane-title text-truncate">
        <span title={name} className="bold">
          {name}
        </span>
      </div>
      <div className="previewer">{renderViewer()}</div>

      <PreviewResources
        type={resourceType}
        modified={lastModified}
        resourceId={resourceId}
        description={isAdmin && isPortal ? description : ''}
      />
    </Box>
  );
}

export default React.memo(GoogleDrivePreview);
