import React, { PureComponent } from 'react';
import { createRoot } from 'react-dom/client';

import PropTypes from 'prop-types';
import ConfirmationDialog from './ConfirmationDialog';

let resolve;
let containerElement;
let rootEl;

class UnsavedChangesDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleHistoryStateChanged = this.handleHistoryStateChanged.bind(this);
    this.handleOnCloseButton = this.handleOnCloseButton.bind(this);
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleHistoryStateChanged);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount UnsavedChangesDialog');
    window.removeEventListener('popstate', this.handleHistoryStateChanged);
    resolve = null;
    containerElement = null;
  }

  static destroy({ isSaved = false, discard = true }) {
    if (containerElement) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 0);
    }
    if (resolve) resolve({ isSaved, discard });
  }

  static show(
    title,
    message,
    okLabel,
    cancelLabel = 'discard',
    hideCancelButton = false,
    icon = null,
    showCloseIcon = true,
    className = ''
  ) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <UnsavedChangesDialog
        title={title}
        message={message}
        okLabel={okLabel || 'Save'}
        cancelLabel={cancelLabel}
        hideCancelButton={hideCancelButton}
        icon={icon}
        showCloseIcon={showCloseIcon}
        className={className}
      />
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleCancel() {
    this.setState({ isOpen: false }, () => {
      UnsavedChangesDialog.destroy({ isSaved: false, discard: true });
    });
    // resolve(false);
  }

  handleHistoryStateChanged() {
    this.setState({ isOpen: false }, () => {
      UnsavedChangesDialog.destroy();
    });
  }

  handleConfirm() {
    this.setState({ isOpen: false }, () => {
      UnsavedChangesDialog.destroy({ isSaved: true });
    });
  }

  handleOnCloseButton() {
    this.setState({ isOpen: false }, () => {
      UnsavedChangesDialog.destroy({ isSaved: false, discard: false });
    });
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    return (
      <ConfirmationDialog
        title={this.props.title}
        onClickOK={this.handleConfirm}
        onClickCancel={this.handleCancel}
        okLabel={this.props.okLabel}
        cancelLabel={this.props.hideCancelButton ? null : this.props.cancelLabel}
        icon={this.props.icon}
        showCloseIcon={this.props.showCloseIcon}
        className={`${this.props.className} unsaved-changes-dialog-btn`}
        dataIds={this.props.dataIds}
        onClickCloseButton={this.handleOnCloseButton}
      >
        <div className="message">{this.props.message}</div>
      </ConfirmationDialog>
    );
  }
}

UnsavedChangesDialog.propTypes = {
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  showCloseIcon: PropTypes.bool,
  className: PropTypes.string,
  dataIds: PropTypes.instanceOf(Object),
};

export default UnsavedChangesDialog;
