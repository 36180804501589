import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import { getMaterialResource, getResourceForViewer } from './ResourceServices';

const useGetResourceForViewerQuery = (
  spaceId,
  resourceId,
  materialId,
  isPortal,
  enabled,
  isViewFromGlobalResource = false
) => {
  return useQuery({
    queryKey: [
      CacheKeys.getResourceForViewer,
      resourceId,
      isPortal,
      materialId,
      spaceId,
      isViewFromGlobalResource,
    ],
    queryFn: async () => {
      if (isViewFromGlobalResource) return null;

      if (materialId) {
        const resp = await getMaterialResource(spaceId, materialId, isPortal);
        return resp;
      }
      if (!resourceId) {
        return null;
      }
      const resp = await getResourceForViewer(resourceId, isPortal);
      return resp;
    },
    retry: 2,
    retryDelay: () => 5000,
    enabled: enabled,
  });
};

export default useGetResourceForViewerQuery;
