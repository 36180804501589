import React from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import TextFieldEnhanced from './TextFieldEnhanced';
import i18n from '../i18n';
import { getPasswordStrength } from '../commons/utils';
import './PasswordField.scss';

const PasswordField = React.forwardRef((props, ref) => {
  const { id, name, onChange, placeholder, validate, helperText } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  const validatePassword = React.useCallback(
    (value) => {
      let error = null;
      const strength = getPasswordStrength(value);
      if (strength < 2) {
        error = i18n.t('Password is not valid');
      }
      if (!error && validate) {
        error = validate(value);
      }
      return error;
    },
    [validate]
  );

  return (
    <TextFieldEnhanced
      ref={ref}
      id={id}
      autoComplete="off"
      type={showPassword ? 'text' : 'password'}
      required
      name={name}
      onChange={onChange}
      validate={validatePassword}
      placeholder={placeholder}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton tabIndex={-1} size="small" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <VisibilityIcon fontSize="small" className="password-field-icon" />
              ) : (
                <VisibilityOffIcon fontSize="small" className="password-field-icon" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});

PasswordField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  helperText: PropTypes.string,
};

PasswordField.defaultProps = {
  validate: null,
};

export default PasswordField;
