import React, { PureComponent } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { getTheme } from '../theme';
import i18n from '../i18n';
import ResponsiveDialog from '../components/ResponsiveDialog';
import './ClosedSpaceDialog.scss';

let resolve;
let containerElement;
let rootEl;

class ClosedSpaceDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleHistoryStateChanged = this.handleHistoryStateChanged.bind(this);
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleHistoryStateChanged);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleHistoryStateChanged);

    console.log('componentWillUnmount ClosedSpaceDialog');
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (containerElement) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 0);
    }
    resolve(retVal);
  }

  static show(spaceHostEmail) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <ClosedSpaceDialog spaceHostEmail={spaceHostEmail} />
      </ThemeProvider>,
      containerElement
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleHistoryStateChanged() {
    this.setState({ isOpen: false }, () => {
      ClosedSpaceDialog.destroy();
    });
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    const { spaceHostEmail } = this.props;

    return (
      <ResponsiveDialog
        showCloseIcon={false}
        modal
        maxWidth="lg"
        className="white new-popup closed-space-dialog"
      >
        <div className="content" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="icon">
            <LockIcon className="lock-icon" />
          </div>
          <div className="title">{i18n.t('This space is closed')}</div>
          <div className="message">
            <Trans i18nKey="closedSpaceMessage">
              Please contact the host <a href={`mailto:${spaceHostEmail}`}>{spaceHostEmail}</a> if
              you need to access it.
            </Trans>
          </div>
        </div>
      </ResponsiveDialog>
    );
  }
}

ClosedSpaceDialog.propTypes = {
  spaceHostEmail: PropTypes.string,
};

export default ClosedSpaceDialog;
