import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from './NotificationMessages';
import i18n from '../i18n';
import './ProcessingSnackbar.scss';

// https://stackoverflow.com/a/74862198

const ProcessingSnackbar = ({ show, message }) => {
  return (
    <Snackbar
      autoHideDuration={1}
      open={show}
      className="saving-snackbar"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div>
        <Alert
          icon={
            <div>
              <i className="icon-spinner" style={{ color: '#1FDA8B' }} />
            </div>
          }
        >
          {message || i18n.t('Automatically saving your details')}
        </Alert>
      </div>
    </Snackbar>
  );
};

ProcessingSnackbar.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
};

export default ProcessingSnackbar;
