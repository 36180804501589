import React, { useEffect, useState } from 'react';
import BlockUI from './BlockUI';
import eventBus, { EVENT_BUS } from '../commons/EventBus';

const BlockScreenHandler = () => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const unsubscribe = eventBus.subscribe(EVENT_BUS.BlockScreenChanged, setDisplay);
    return () => {
      unsubscribe();
    };
  }, []);

  return <BlockUI display={display} backgroundColor="#fff" iconColor="#000" />;
};

export default BlockScreenHandler;
