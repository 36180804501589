import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFeatureFlagsOrg } from '../../settings/SettingsServices';
import { CacheKeys } from '../../app/queryCache';
import { defaultFeatureFlags } from './services';
import { useAppContext } from '../../components/AppProvider';

const useFeatureFlagsQuery = (organizationId, enabled = true) => {
  const { isPortal } = useAppContext();

  const featureFlagsOrgSaved = sessionStorage.getItem('FeatureFlagsOrg')
    ? JSON.parse(sessionStorage.getItem('FeatureFlagsOrg'))
    : null;

  const [featureFlags, setFeatureFlags] = useState(() => {
    if (featureFlagsOrgSaved?.length) {
      return featureFlagsOrgSaved;
    }
    return [];
  });

  const saveFeaturesToSessionStorage = (items) => {
    sessionStorage.setItem('FeatureFlagsOrg', JSON.stringify(items));
  };

  const fetchFeatureFlagsQuery = useQuery({
    queryKey: [CacheKeys.getFeatureFlags, organizationId, isPortal],
    queryFn: async () => {
      const resp = await getFeatureFlagsOrg(isPortal);
      if (resp?.items?.length > 0) {
        saveFeaturesToSessionStorage(resp.items);
        return resp.items;
      } else {
        return defaultFeatureFlags.items;
      }
    },
    ...{
      retry: 0,
      retryDelay: () => 5000,
      enabled: !!organizationId && !featureFlags.length && enabled,
    },
  });

  useEffect(() => {
    if (fetchFeatureFlagsQuery?.data) {
      setFeatureFlags(fetchFeatureFlagsQuery.data);
    }
  }, [fetchFeatureFlagsQuery?.data]);

  return {
    featureFlags,
    isLoading: fetchFeatureFlagsQuery.isLoading,
  };
};

export default useFeatureFlagsQuery;
