import React from 'react';
import PropTypes from 'prop-types';
import FilterBox from '../FilterBox';
import ViewModeSelect from '../Material/ViewModeSelect';
import { useAppContext } from '../AppProvider';
import i18n from '../../i18n';
import SortSelect from '../SortSelect';
import './ResourcesViewControls.scss';
import LightTooltip from '../LightTooltip';
import { useResourcesViewContext } from './ResourcesViewContextProvider';
import { ViewModeType } from '../../resources/resourceConstants';

function ResourcesViewControls(props) {
  const {
    searchPlaceholder,
    searchInputRef,
    searchText,
    onSearchChange,
    canChangeView,
    disabledSearchMessage,
  } = props;
  const { isMobile } = useAppContext();
  const { viewMode, order, orderBy, setSortData, setViewMode, sortOptions } =
    useResourcesViewContext();

  const handleOnCancelSearch = () => {
    onSearchChange('');
  };

  function renderSearchBox() {
    const disabled = props.disabledSearchMessage?.length > 0;

    if (props.disabledSearchMessage) {
      return (
        <LightTooltip
          title={disabledSearchMessage}
          disableHoverListener={!disabled}
          disableTouchListener={!disabled}
          disableFocusListener={!disabled}
          placement="bottom-start"
        >
          <div className="filter-box-wrapper">
            <FilterBox
              variant="light"
              disabled={disabled}
              placeholder={searchPlaceholder ?? i18n.t('Search for a file or folder')}
              onClick={props.onClickSearchInput}
            />
          </div>
        </LightTooltip>
      );
    }
    return (
      <FilterBox
        ref={searchInputRef}
        variant="light"
        autoFocus={!isMobile && props.autoFocusSearchInput}
        placeholder={searchPlaceholder ?? i18n.t('Search for a file or folder')}
        value={searchText}
        onChange={onSearchChange}
        onCancel={handleOnCancelSearch}
        onClick={props.onClickSearchInput}
      />
    );
  }

  if (isMobile || !canChangeView) {
    return <div className="control-box">{renderSearchBox()}</div>;
  }

  return (
    <div className="control-box">
      {renderSearchBox()}
      {viewMode === ViewModeType.GRID && (
        <SortSelect order={order} orderBy={orderBy} options={sortOptions} onChange={setSortData} />
      )}
      <ViewModeSelect value={viewMode} onChange={setViewMode} />
    </div>
  );
}

ResourcesViewControls.propTypes = {
  searchInputRef: PropTypes.instanceOf(Object),
  searchPlaceholder: PropTypes.string,
  searchText: PropTypes.string,
  canChangeView: PropTypes.bool,
  onSearchChange: PropTypes.func,
  // handleOnCancelSearch: PropTypes.func,
  // sortBy: PropTypes.instanceOf(Object),
  // handleSortChange: PropTypes.func,
  // viewMode: PropTypes.string,
  // handleChangeViewMode: PropTypes.func,
  disabledSearchMessage: PropTypes.string,
  onClickSearchInput: PropTypes.func,
  // sortOptions: PropTypes.instanceOf(Object),
  // sortDefault: PropTypes.instanceOf(Object),
  autoFocusSearchInput: PropTypes.bool,
};

ResourcesViewControls.defaultProps = {
  canChangeView: true,
  autoFocusSearchInput: true,
  // sortOptions: sortMaterialOptions,
};

export default ResourcesViewControls;
