import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import { getTheme } from '../theme';
import ResponsiveDialog from './ResponsiveDialog';
import OfficialButton from './OfficialButtons';

let resolve;
let containerElement;
let rootEl;

class ConfirmDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleHistoryStateChanged = this.handleHistoryStateChanged.bind(this);
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleHistoryStateChanged);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount ConfirmDialog');
    window.removeEventListener('popstate', this.handleHistoryStateChanged);
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (containerElement) {
      rootEl.unmount();
    }
    resolve(retVal);
  }

  static show(title, message, okLabel = 'Accept', cancelLabel = 'Cancel') {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <ConfirmDialog
          title={title}
          message={message}
          okLabel={okLabel}
          cancelLabel={cancelLabel}
        />
      </ThemeProvider>
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleCancel() {
    if (this.props.onClickCancel) {
      this.props.onClickCancel();
    } else {
      this.setState({ isOpen: false }, () => {
        ConfirmDialog.destroy();
      });
    }
  }

  handleHistoryStateChanged() {
    this.setState({ isOpen: false }, () => {
      ConfirmDialog.destroy();
    });
    // resolve(false);
  }

  handleConfirm() {
    if (this.props.onClickOk) {
      this.props.onClickOk();
    } else {
      this.setState({ isOpen: false }, () => {
        ConfirmDialog.destroy(true);
      });
    }
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }
    const { title, message, cancelLabel, okLabel } = this.props;
    return (
      <ResponsiveDialog modal maxWidth="lg" className="white new-popup" onClose={this.handleCancel}>
        <div className="content">
          <h1>{title}</h1>
          <p>{message}</p>
        </div>
        <div className="action-buttons">
          <OfficialButton
            onClick={this.handleCancel}
            label={cancelLabel}
            variant="large-secondary"
          />
          <OfficialButton onClick={this.handleConfirm} label={okLabel} variant="large-primary" />
        </div>
      </ResponsiveDialog>
    );
  }
}

ConfirmDialog.propTypes = {
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onClickOk: PropTypes.func,
  onClickCancel: PropTypes.func,
};

export default ConfirmDialog;
