import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import _find from 'lodash/find';
import { useSpaceContext } from '../SpaceContext';
import useIntegrationConnectionsQuery from '../../integrations/useIntegrationConnectionsQuery';
import { useAppContext } from '../../components/AppProvider';
import IntegrationContextProvider from '../../integrations/IntegrationContextProvider';
import FeatureFlagsContextProvider from '../../commons/FeatureFlags/FeatureFlagsContextProvider';

export const IntegrationRuntimeContext = React.createContext({});

IntegrationRuntimeContext.displayName = 'IntegrationRuntimeContext';

export const useIntegrationRuntimeContext = () => React.useContext(IntegrationRuntimeContext);

export const getIntegrationRuntimeContext = memoizeOne((integrationConnections) => {
  return { integrationConnections };
});

const IntegrationRuntimeContextProvider = ({ children }) => {
  const { isSpaceUserLoggedIn, isPublicSpace, space } = useSpaceContext();
  const { isAuthenticated } = useAppContext();
  const organizationId = space?.organizationId;

  const [integrationConnections, setIntegrationConnections] = React.useState([]);

  const getRuntimeIntegrationConnectionsResp = useIntegrationConnectionsQuery(
    !!isSpaceUserLoggedIn && !isPublicSpace
  );

  React.useEffect(() => {
    const connectionItems = getRuntimeIntegrationConnectionsResp.data;
    setIntegrationConnections(connectionItems);
  }, [getRuntimeIntegrationConnectionsResp.data]);

  const context = getIntegrationRuntimeContext(integrationConnections);

  context.hasConnection = (serviceProvider) => {
    const foundConnection = _find(
      integrationConnections,
      (item) => item.serviceProvider === serviceProvider
    );
    return !!foundConnection;
  };

  context.setIntegrationConnections = React.useCallback((newConnections) => {
    setIntegrationConnections(newConnections);
  }, []);

  if (isAuthenticated) {
    return (
      <IntegrationRuntimeContext.Provider value={context}>
        <IntegrationContextProvider showLoading={false}>
          <FeatureFlagsContextProvider organizationId={organizationId}>
            {children}
          </FeatureFlagsContextProvider>
        </IntegrationContextProvider>
      </IntegrationRuntimeContext.Provider>
    );
  }

  return (
    <IntegrationRuntimeContext.Provider value={context}>
      <FeatureFlagsContextProvider organizationId={organizationId}>
        {children}
      </FeatureFlagsContextProvider>
    </IntegrationRuntimeContext.Provider>
  );
};

IntegrationRuntimeContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
};

export default IntegrationRuntimeContextProvider;
