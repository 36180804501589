import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import './style.scss';
// import { getRowCount } from './utils';
import { RowData, ResourcesViewProps, HeadCell } from '../context/Interfaces';
import TablePagination from '../TablePagination';
import ScrollingList from '../../components/ScrollingList';
import { useResourcesViewContext } from '../../components/ResourcesView/ResourcesViewContextProvider';
import ResourceItem from '../ResourcesDisplayView/ResourceItem';
import { ViewModeType } from '../../resources/resourceConstants';
import EnhancedTableHeader from '../../components/ResourceListItem/EnhancedTableHeader';
import i18n from '../../i18n';

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: i18n.t('Name'),
    className: 'name-column',
  },
  {
    id: 'itemsCount',
    numeric: true,
    disablePadding: false,
    label: i18n.t('Items'),
    className: 'items-count-column',
    width: '100px',
  },
  {
    id: 'lastModified',
    numeric: false,
    disablePadding: false,
    label: i18n.t('Last modified'),
    className: 'last-modified-column',
    width: '160px',
  },
  {
    id: 'actions',
    numeric: false,
    align: 'center',
    disablePadding: false,
    label: i18n.t('Action'),
    width: '80px',
  },
];

function ResourcesListView(props: ResourcesViewProps) {
  const {
    resources,
    className,
    onClickMore,
    selectType,
    multiple,
    rowCount,
    onClickItem,
    onDoubleClickItem,
    processingResources,
    // selected,
    handleSelectAllClick,
    hasMore,
    onLoadMore,
    isLoading,
    onClickCheckbox,
    isPortal,
  } = props;

  const { order, orderBy, setSortData, columns, sortOptions } = useResourcesViewContext();

  const { hasSelectedItems, isSelectAll } = useMemo(() => {
    const result = { hasSelectedItems: false, isSelectAll: false };
    const selectables = resources.filter((resource) => !resource.disabledCheckbox);
    if (selectables.length === 0) return result;
    let flag = true;
    selectables.forEach((resource) => {
      if (resource.isSelected) {
        result.hasSelectedItems = true;
      } else {
        flag = false;
      }
    });
    result.isSelectAll = flag;
    return result;
  }, [resources]);

  function renderTableRow(rowData: RowData, index: number) {
    return (
      <ResourceItem
        viewMode={ViewModeType.LIST}
        key={rowData.id ?? rowData.resourceId}
        rowData={rowData}
        index={index}
        isSelected={rowData.isSelected ?? false}
        onClickItem={onClickItem}
        onDoubleClickItem={onDoubleClickItem}
        processingResources={processingResources}
        multiple={multiple}
        selectType={selectType}
        onClickMore={onClickMore}
        onClickCheckbox={onClickCheckbox}
        isPortal={isPortal}
        columns={columns}
      />
    );
  }

  // const headRowCount = React.useMemo(() => getRowCount(resources, selectType), [
  //   resources,
  //   selectType,
  // ]);

  const handleRequestSort = (event: React.MouseEvent<Element>, property: keyof RowData | null) => {
    console.log('### handleRequestSort', property);
    const isAsc = orderBy === property && order === 'asc';
    setSortData({
      orderBy: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };
  // console.log('### 811 Table3:', selected, selectType, headRowCount);

  function renderTable() {
    return (
      <Table className="table" size="medium" aria-label="sticky table" stickyHeader>
        <EnhancedTableHeader
          headCells={headCells}
          columns={columns}
          sortOptions={sortOptions}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          showCheckBox={multiple}
          hasSelectedItems={hasSelectedItems}
          isSelectAll={isSelectAll}
        />
        <TableBody>{resources.map((rowData, index) => renderTableRow(rowData, index))}</TableBody>
      </Table>
    );
  }

  if (onLoadMore) {
    return (
      <div className="table-data-component">
        <ScrollingList
          className={`global-resources-table styled-scrollbar ${className || ''}`}
          onScrollToBottom={hasMore && !isLoading ? onLoadMore : null}
        >
          {renderTable()}
        </ScrollingList>
      </div>
    );
  }

  return (
    <div className="table-data-component">
      <TableContainer className={`global-resources-table styled-scrollbar ${className || ''}`}>
        {renderTable()}
      </TableContainer>
      <TablePagination info={`${rowCount} results`} rowCount={rowCount} />
    </div>
  );
}

export default ResourcesListView;
