/* eslint-disable no-undef */
import { createRoot } from 'react-dom/client';
import memoizeOne from 'memoize-one';
import _forEach from 'lodash/forEach';
import React from 'react';
import { appendScript } from '../commons/utils';

export const ScriptProvider = {
  Hubspot: 'Hubspot',
  Marketo: 'Marketo',
  Salesforce: 'Salesforce',
  Facebook: 'Facebook',
  Javascript: 'Javascript',
  SpaceWidgets: 'SpaceWidgets',
  GoogleAnalytics: 'Google Analytics',
};

export const ProviderId = {
  Hubspot: 1,
  Marketo: 2,
  Salesforce: 3,
  Facebook: 5,
  GoogleAnalytics: 6,
  Javascript: 4,
};

export const ScriptProviders = [
  { id: ProviderId.Hubspot, name: ScriptProvider.Hubspot },
  { id: ProviderId.Marketo, name: ScriptProvider.Marketo },
  { id: ProviderId.Salesforce, name: ScriptProvider.Salesforce },
  { id: ProviderId.Facebook, name: ScriptProvider.Facebook },
  { id: ProviderId.GoogleAnalytics, name: ScriptProvider.GoogleAnalytics },
  { id: ProviderId.Javascript, name: ScriptProvider.Javascript },
];

function getMarketoScriptValue(src) {
  const regex = /(?:MktoForms2.loadForm\().*(?=\))/gs;
  const match = regex.exec(src);
  return match?.[0] || '';
}

function getHubSpotPropertyValue(propsName, stringValue) {
  const stringRegex = `(?:PropertyName: )(?:)(.*)(?:")`.replace('PropertyName', propsName);
  const regex = new RegExp(stringRegex, 'g');
  const match = regex.exec(stringValue);

  return match?.[0].split('"')?.[1] || '';
}

function getGoogleAnalyticsId(script) {
  const googleAnalyticsIdRegex = /gtag\/js\?id=([A-Z0-9-]+)/;
  const match = script.match(googleAnalyticsIdRegex);
  return match ? match[1] : null;
}

const hubSpotRegex = /\bjs-(\w+).hsforms.net|js.hsforms.net\b/gs;
const marketoRegex = /\bMktoForms2.loadForm\b/gs;
const salesforceRegex = /\bwebto.salesforce.com\b/gs;
const facebookRegex = /\bconnect.facebook.net\b/gs;
const javascriptRegex = /\b(function|var|const|void)\b/gs;
const spceWidgetsRegex = /\bloadSpaceWidgets\b/gs;
const googleAnalyticsRegex = /googletagmanager.com/gs;

export function isSpaceWidgets(script) {
  return script.match(spceWidgetsRegex);
}

export function isJavascript(script) {
  return script.match(javascriptRegex);
}

export function isHubspotScript(script) {
  return script.match(hubSpotRegex);
}

export function isMarketoScript(script) {
  return script.match(marketoRegex);
}

export function isSalesforceScript(script) {
  return script.match(salesforceRegex);
}

export function isFacebookScript(script) {
  return script.match(facebookRegex);
}

export function isGoogleAnalyticsScript(script) {
  return script.match(googleAnalyticsRegex);
}

export const validateScript = memoizeOne((script) => {
  const hubSpotScript = script.match(hubSpotRegex);
  const markettoScript = script.match(marketoRegex);
  if (
    hubSpotScript?.length > 1 ||
    markettoScript?.length > 1 ||
    (hubSpotScript?.length > 0 && markettoScript?.length > 0)
  ) {
    return false;
  }

  return true;
});

export const isSupportedScript = memoizeOne((script) => {
  if (
    isHubspotScript(script) ||
    isMarketoScript(script) ||
    isSalesforceScript(script) ||
    isJavascript(script) ||
    isFacebookScript(script) ||
    isGoogleAnalyticsScript(script)
  ) {
    return true;
  }
  return false;
});

function getWidgetId(scriptContent) {
  const idRegex = /loadSpaceWidgets\('([^']+)'\)/;
  const match = scriptContent.match(idRegex);

  console.log('match: ', match);
  return match ? match[1] : null;
}

export const parseScript = memoizeOne((script) => {
  if (isSpaceWidgets(script)) {
    const widgetId = getWidgetId(script);
    return {
      provider: ScriptProvider.SpaceWidgets,
      widgetId: widgetId,
      form: script,
    };
  }

  if (isHubspotScript(script)) {
    const region = getHubSpotPropertyValue('region', script);
    const portalId = getHubSpotPropertyValue('portalId', script);
    const formId = getHubSpotPropertyValue('formId', script);

    if (!region || !portalId || !formId) {
      return null;
    }

    return {
      provider: ScriptProvider.Hubspot,
      region: region,
      portalId: portalId,
      formId: formId,
    };
  }

  if (isMarketoScript(script)) {
    const data = getMarketoScriptValue(script);
    const marketoLighboxRegex = /\blightbox\b/gs;
    const isLightBox = script.match(marketoLighboxRegex);

    const portalId = data.split(',')?.[1].trim().replaceAll('"', '');
    const formId = data.split(',')?.[2].trim();

    if (!portalId || !formId) {
      return null;
    }

    return {
      provider: ScriptProvider.Marketo,
      portalId: portalId,
      formId: formId,
      isPopup: !!isLightBox,
    };
  }

  if (isSalesforceScript(script)) {
    return {
      provider: ScriptProvider.Salesforce,
      form: script,
    };
  }

  if (isFacebookScript(script)) {
    return {
      provider: ScriptProvider.Facebook,
      form: script,
    };
  }

  if (isGoogleAnalyticsScript(script)) {
    const gaId = getGoogleAnalyticsId(script);
    return {
      provider: ScriptProvider.GoogleAnalytics,
      gaId: gaId,
    };
  }

  if (isJavascript(script)) {
    return {
      provider: ScriptProvider.Javascript,
      form: script,
    };
  }

  return null;
});

export function findScriptBySrc(src) {
  const scripts = document.getElementsByTagName('script');
  let foundScript = false;
  _forEach(scripts, (script) => {
    if (script.src.indexOf(src) !== -1) {
      foundScript = true;
    }
  });
  return foundScript;
}

export function removeMarketoScript(id) {
  const mktoModalEls = document.getElementsByClassName('ktoModal');
  if (mktoModalEls && mktoModalEls.length > 0) {
    mktoModalEls[0]?.remove();
  }
  const mktoFormEls = document.getElementsByClassName(`mktoForm-${id}`);
  if (mktoFormEls && mktoFormEls.length > 0) {
    mktoFormEls[0]?.remove();
  }
  const mktoIframe = document.getElementById('MktoForms2XDIframe');
  mktoIframe?.remove();
  const mktoStyleEl = document.getElementById('mktoStyleLoaded');
  mktoStyleEl?.remove();
}

export async function loadMarketoScript(scriptObject, id) {
  const scriptEl = document.getElementById(`script-content-${id}`);
  if (!scriptEl) {
    reject();
  }
  const foundMarketoForm = scriptEl.getElementsByClassName(`mktoForm-${id}`);
  if (foundMarketoForm.length > 0) {
    reject();
  }
  scriptEl.innerHTML = '';
  const formEl = <form id={`mktoForm_${scriptObject.formId}`} className={`mktoForm-${id}`}></form>;

  const rootEl = createRoot(scriptEl);
  rootEl.render(formEl);

  const loadScriptsPromises = [];
  if (!findScriptBySrc('//go.atlascopco.com/js/forms2/js/forms2.min.js')) {
    loadScriptsPromises.push(appendScript('//go.atlascopco.com/js/forms2/js/forms2.min.js'));
  }
  Promise.all(loadScriptsPromises).then(() => {
    console.log('###loadMarketoScript', id);
    if (window.MktoForms2) {
      if (scriptObject.isPopup) {
        window.MktoForms2.loadForm(
          '//go.atlascopco.com',
          scriptObject.portalId,
          scriptObject.formId,
          (form) => {
            window.MktoForms2.lightbox(form).show();
          }
        );
      } else {
        window.MktoForms2.loadForm(
          '//go.atlascopco.com',
          scriptObject.portalId,
          scriptObject.formId
        );
      }
    }
  });
}

export async function loadHubspotScript(scriptObject, id) {
  const scriptEl = document.getElementById(`script-content-${id}`);
  const loadScriptsPromises = [];
  if (!findScriptBySrc('//js.hsforms.net/forms/v2-legacy.js')) {
    loadScriptsPromises.push(appendScript('//js.hsforms.net/forms/v2-legacy.js'));
  }
  if (!findScriptBySrc('//js.hsforms.net/forms/v2.js')) {
    loadScriptsPromises.push(appendScript('//js.hsforms.net/forms/v2.js'));
  }

  Promise.all(loadScriptsPromises).then(() => {
    setTimeout(() => {
      if (window.hbspt) {
        scriptEl.innerHTML = '';
        window.hbspt.forms.create({
          region: scriptObject.region,
          portalId: scriptObject.portalId,
          formId: scriptObject.formId,
          target: `#script-content-${id}`,
        });
      }
    }, 100);
  });
}

function getFacebookPixelId(scriptContent) {
  const regex = /fbq\('init', '([^']+)'\)/;
  const match = scriptContent.match(regex);

  console.log('match: ', match);
  return match ? match[1] : null;
}

export function loadFacebook(scriptObject, id) {
  try {
    console.log('###loadJavascript', `script-content-${id}`);
    const scriptContentEl = document.getElementById(`script-content-${id}`); // parent div
    scriptContentEl.inert = '';
    const pixelId = getFacebookPixelId(scriptObject.form);
    console.log('###loadFacebook:', pixelId);

    const scriptContent = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${pixelId}');
    fbq('track', 'PageView');
  `;

    // Check if the script already exists
    const existingScript = Array.from(document.getElementsByTagName('script')).find((script) =>
      script.innerHTML.includes(`fbq('init', '${pixelId}')`)
    );
    if (!existingScript) {
      // Create script element
      const scriptEl = document.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.innerHTML = scriptContent;

      // Create noscript element and img element
      const noscriptEl = document.createElement('noscript');
      const imgEl = document.createElement('img');
      imgEl.height = 1;
      imgEl.width = 1;
      imgEl.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`;
      noscriptEl.appendChild(imgEl);

      // Append script and noscript elements to the head
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(scriptEl);
      head.appendChild(noscriptEl);
    } else {
      console.log('Script already exists in the head.');
    }
  } catch (error) {
    console.error('Error loading JavaScript:', error);
  }
}

function loadJavascript(scriptObject, id) {
  try {
    console.log('###loadJavascript', `script-content-${id}`);
    const parentEl = document.getElementById(`script-content-${id}`); // parent div
    parentEl.innerHTML = '';
    const iframeEl = document.createElement('iframe');
    iframeEl.id = `iframe-${id}`;
    iframeEl.sandbox = 'allow-scripts allow-pointer-lock allow-same-origin';
    iframeEl.className = 'iframe-temp';
    iframeEl.src = 'about:blank';
    parentEl.appendChild(iframeEl);

    // 1st way to allow users paste the whole script
    // const iframeContent = `<script>${scriptObject.form}</script>`;
    // const iframeContent = `<script>console.log=function(){};${scriptObject.form}</script>`;
    const iframeContent = `<script>console={};${scriptObject.form}</script>`;
    const content = `<!DOCTYPE html><head><body style="margin:0">${iframeContent}</body></html>`;
    const doc = iframeEl.contentDocument || iframeEl.contentWindow.document;
    doc.open('text/html', 'replace');
    doc.write(content);
    doc.close();

    // 2nd way to allow users paste the external js file link.
    // const scriptEl = document.createElement('script');
    // scriptEl.type = 'text/javascript';
    // scriptEl.src = scriptObject.form;
    // iframeEl.contentWindow.document.body.appendChild(scriptEl);
    //

    // iframeEl.contentWindow.console.log = () => {};

    // resize iframe to fit content
    iframeEl.width = iframeEl.contentWindow.document.body.scrollWidth;
    iframeEl.height = iframeEl.contentWindow.document.body.scrollHeight;
  } catch (e) {
    console.log('error', e);
    console.error(e);
  }
}

function loadGoogleAnalytics(scriptObject, id) {
  try {
    const scriptContentEl = document.getElementById(`script-content-${id}`); // parent div
    appendScript(`https://www.googletagmanager.com/gtag/js?id=${scriptObject.gaId}`).then(() => {
      scriptContentEl.innerHTML = '';
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        // eslint-disable-next-line prefer-rest-params
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', scriptObject.gaId, {
        page_path: window.location.pathname,
        page_title: document.title,
        page_location: window.location.href,
      });
    });
  } catch (error) {
    console.error('Error loading Google Analytics:', error);
  }
}

export async function loadScript(scriptObject, id) {
  const scriptEl = document.getElementById(`script-content-${id}`);
  if (!scriptEl || !scriptObject?.provider) {
    return;
  }

  switch (scriptObject.provider) {
    case ScriptProvider.Marketo:
      await loadMarketoScript(scriptObject, id);
      break;
    case ScriptProvider.Hubspot:
      await loadHubspotScript(scriptObject, id);
      break;
    case ScriptProvider.Javascript:
      loadJavascript(scriptObject, id);
      break;
    case ScriptProvider.Facebook:
      loadFacebook(scriptObject, id);
      break;
    case ScriptProvider.GoogleAnalytics:
      loadGoogleAnalytics(scriptObject, id);
      break;
    default:
      break;
  }
}

export function removeScript(id) {
  removeMarketoScript(id);
}

export function getDefaultScriptProvider(providerId, script) {
  if (providerId) {
    return ScriptProviders.find((x) => x.id === providerId);
  }
  if (!script) {
    return { id: '' };
  }
  if (isHubspotScript(script)) {
    return ScriptProviders[0];
  }
  if (isMarketoScript(script)) {
    return ScriptProviders[1];
  }
  if (isSalesforceScript(script)) {
    return ScriptProviders[2];
  }
  if (isFacebookScript(script)) {
    return ScriptProviders[3];
  }
  if (isGoogleAnalyticsScript(script)) {
    return ScriptProviders[4];
  }

  return { id: '' };
}
