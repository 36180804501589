import React, { useCallback, useMemo } from 'react';
import { Column } from '@material-table/core';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import SpaceUserAvatar from '../components/SpaceUserAvatar';
import { ISpaceUser } from '../../types/User';
import { SpaceUserDetailsTab } from '../SpaceUserDetailsModal';
import { Tag } from '../../components/Tags';
import i18n from '../../i18n';
import { getTimeActive } from '../../spaces/SpaceUsers/functions';
import LightTooltip from '../../components/LightTooltip';
import { getStringTime } from '../common';
import Table from '../components/Table';
import { Ids } from '../../commons/pendoTaggings';
import { UserTrafficDetails } from '../types/userTraffic';

type ParticipantSummary = ISpaceUser & {
  timeSpent: number;
  visitCount: number;
};

function convertToUserInfo(participantSummary: ParticipantSummary): ISpaceUser {
  return {
    id: participantSummary.userInfoId,
    userInfoId: participantSummary.userInfoId,
    firstName: participantSummary.firstName,
    orgRole: participantSummary.orgRole,
    lastName: participantSummary.lastName,
    email: participantSummary.email,
    fullName: participantSummary.fullName,
    isOrganizationUser: participantSummary.isOrganizationUser,
    role: participantSummary.role,
    lastActive: participantSummary.lastActive,
  };
}

type ParticipantTrafficeDetailsTableProps = {
  data: UserTrafficDetails[];
};

function ParticipantTrafficDetailsTable(props: ParticipantTrafficeDetailsTableProps) {
  const { data } = props;
  const { openSpaceUserModal, visibleUsers } = useSpaceAnalyticsContext();

  const handleOnClickLink = useCallback(
    (participantSummary: ParticipantSummary) => {
      const userInfo = convertToUserInfo(participantSummary);
      openSpaceUserModal(userInfo, SpaceUserDetailsTab.TRAFFIC);
    },
    [openSpaceUserModal]
  );

  const columns: Column<ParticipantSummary>[] = useMemo(
    () => [
      {
        title: 'Name',
        field: 'firstName',
        cellStyle: {
          width: '30%',
        },
        render: (rowData: ParticipantSummary) => {
          const userInfo: ISpaceUser = {
            id: rowData.userInfoId,
            userInfoId: rowData.userInfoId,
            firstName: rowData.firstName,
            lastName: rowData.lastName,
            orgRole: rowData.orgRole,
            email: rowData.email,
            fullName: rowData.fullName,
            isOrganizationUser: rowData.isOrganizationUser,
            role: rowData.role,
            isVisitor: rowData.isVisitor,
          };
          return <SpaceUserAvatar userInfo={userInfo} />;
        },
      },
      {
        title: 'Last active',
        field: 'lastActive',
        defaultSort: 'desc',
        cellStyle: {
          width: '20%',
        },
        render: (rowData: ParticipantSummary) => {
          return (
            getTimeActive(rowData.lastActive, rowData.isOnline, false, 'spce-en-detail') || (
              <Tag className="space-tag" backgroundColor="rgba(219, 225, 227, 1)">
                {i18n.t('Not joined')}
              </Tag>
            )
          );
        },
      },
      {
        title: 'Visits',
        field: 'visitCount',
        cellStyle: {
          width: '20%',
        },
        render: (rowData: ParticipantSummary) => {
          if (!rowData.lastActive) {
            return (
              <LightTooltip title={i18n.t("The user hasn't joined the space yet.")}>
                <span style={{ cursor: 'pointer' }}>N/A</span>
              </LightTooltip>
            );
          }
          return (
            <span
              data-id={Ids.participantTrafficVisits}
              className="btn-link"
              onClick={() => handleOnClickLink(rowData)}
            >
              {rowData.visitCount || 0}
            </span>
          );
        },
      },
      {
        title: 'Time spent',
        field: 'timeSpent',
        align: 'right',
        cellStyle: {
          width: '20%',
        },
        render: (rowData: ParticipantSummary) => {
          if (!rowData.lastActive) {
            return (
              <LightTooltip title={i18n.t("The user hasn't joined the space yet.")}>
                <span style={{ cursor: 'pointer' }}>N/A</span>
              </LightTooltip>
            );
          }
          const timeSpent = rowData?.timeSpent;
          return timeSpent ? getStringTime(timeSpent) : i18n.t('N/A');
        },
      },
    ],
    [handleOnClickLink]
  );

  function renderTable() {
    if (visibleUsers?.length === 0 || !data || data?.length === 0) {
      return null;
    }

    const usersTrafficDetails = data;

    const updatedData = visibleUsers
      .map((user) => {
        const formattedParticipantDetails = usersTrafficDetails.find(
          (detail) => detail.userInfoId === user.userInfoId
        );

        return {
          ...user,
          lastActive: formattedParticipantDetails?.lastActive,
          visitCount: formattedParticipantDetails?.visitCount,
          timeSpent: formattedParticipantDetails?.timeSpentInSeconds,
        };
      })
      .sort((a, b) => a.fullName.localeCompare(b.fullName));
    return <Table columns={columns} data={updatedData} />;
  }

  return renderTable();
}

export default React.memo(ParticipantTrafficDetailsTable);
