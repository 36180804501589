import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import queryCache, { CacheKeys } from '../../app/queryCache';
import { fetchSpaceInvitation } from '../../spaces/spaceAccessServices';

const useSpaceInvitationQuery = (spaceId, shouldCreateHiddenUser) => {
  const queryKey = useMemo(
    () => [CacheKeys.fetchSpaceInvitation, spaceId, shouldCreateHiddenUser],
    [spaceId, shouldCreateHiddenUser]
  );
  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const resp = await fetchSpaceInvitation(spaceId, shouldCreateHiddenUser);
      return resp;
    },

    retry: 0,
    retryDelay: () => 5000,
    refetchOnMount: true,
  });

  query.updateSpace = (spaceData) => {
    queryCache.setQueryData(queryKey, (oldData) => {
      if (!oldData?.space) {
        return oldData;
      }
      const space = { ...oldData.space, ...spaceData };
      return { ...oldData, space };
    });
  };
  return query;
};

export default useSpaceInvitationQuery;
