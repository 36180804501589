import React from 'react';
import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FavoriteIcon from '@mui/icons-material/Favorite';
import './CommentActionMenu.scss';
import { ChannelType } from '../../app/appConstants';

function CommentActionMenu({
  handleOnClickReply,
  handleOnClickLike,
  onClickMore,
  comment,
  parentId,
  liked,
  isMyself,
  channelType,
  isHost,
  isShow,
}) {
  const shouldShowButton = isMyself || (isHost && channelType === ChannelType.RUNTIME);
  return (
    <Box className={`comment-action-menu ${isShow ? 'active' : ''}`}>
      <IconButton className="like-btn" onClick={() => handleOnClickLike(comment)}>
        {liked ? <span className="icon-minus-liked"></span> : <FavoriteIcon />}
      </IconButton>
      <IconButton className="reply-btn" onClick={() => handleOnClickReply(comment, parentId)}>
        <span className="icon-share"></span>
      </IconButton>
      {shouldShowButton ? (
        <IconButton
          className="more-btn"
          onClick={(event) => {
            onClickMore(event, comment);
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      ) : null}
    </Box>
  );
}

CommentActionMenu.propTypes = {
  handleOnClickReply: PropTypes.func,
  handleOnClickLike: PropTypes.func,
  onClickMore: PropTypes.func,
  comment: PropTypes.instanceOf(Object),
  parentId: PropTypes.number,
  liked: PropTypes.bool,
  isMyself: PropTypes.bool,
  isHost: PropTypes.bool,
  channelType: PropTypes.number,
  isShow: PropTypes.bool,
};

export default CommentActionMenu;
