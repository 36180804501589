import { useRef, useLayoutEffect } from 'react';

function useUnmountEffect(callback) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useLayoutEffect(() => {
    return () => {
      const callbackFunction = callbackRef.current;

      if (!callbackFunction) {
        return;
      }

      callbackFunction();
    };
  }, []);
}

export default useUnmountEffect;
