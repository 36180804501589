/* eslint no-bitwise: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { getPortalHubUrl } from '../commons/utils';
import authService from '../components/api-authorization/AuthorizeService';
import CustomSigalRHttpClient from '../commons/CustomSigalRHttpClient';
import currentOrganization from '../commons/CurrentOrganization';
import TransferOwnershipHandler from '../homepage/TransferOwnershipHandler';
import DeletedUserEventHandler from '../homepage/DeletedUserEventHandler';
import SpacesEventsHandler from '../homepage/SpacesEventsHandler';
import FeatureFlagsHandler from '../commons/FeatureFlags/FeatureFlagsHandler';

const signalR = require('@microsoft/signalr');

async function onOrganizationUserRoleChanged(message) {
  // console.log('### SignalRHandler OrganizationUserRoleChanged', message);
  const { organizationId } = message;
  if (organizationId === currentOrganization.getOrganizationId()) {
    const isAuthenticated = await authService.isAuthenticated();
    if (isAuthenticated) {
      // update current Org
      const currentData = currentOrganization.getData();
      currentOrganization.setData({
        ...currentData,
        role: message.newRole,
      });
      // silent login
      const rs = await authService.signInSilent();
      if (!rs) {
        authService.signOut();
      }
    }
  }
}

function onOrganizationUserDeleted(message) {
  const { deletedUserInfoId, organizationId } = message;
  const userInfoId = currentOrganization.getUserInfoId();
  if (
    deletedUserInfoId === userInfoId &&
    organizationId === currentOrganization.getOrganizationId()
  ) {
    currentOrganization.clearData();

    authService.signOut();
  }
}

function createConnection(organizationId) {
  const url = `${getPortalHubUrl()}?orgId=${organizationId}`;
  return new signalR.HubConnectionBuilder()
    .withUrl(url, { httpClient: new CustomSigalRHttpClient() })
    .withAutomaticReconnect()
    .build();
}

class SignalRHandler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      connectionHub: null,
    };
    this.connection = null;
    this.shouldStopConnection = false;
  }

  async componentDidMount() {
    if (this.shouldStopConnection) {
      this.cleanUpConnection();
      this.shouldStopConnection = false;
      return;
    }
    this.connection = createConnection(this.props.organizationId);

    this.connection
      .start()
      .then(() => {
        this.setState({ connectionHub: this.connection });
        localStorage.setItem('connectionId', this.connection?.connectionId);
        if (this.props.onConnected) {
          this.props.onConnected(this.connection);
        }
      })
      .catch((error) => {
        this.shouldStopConnection = true;
        console.log(`SignalRHandler Fail: ${error.message}`);
      });

    this.connection.on('OrganizationUserRoleChanged', onOrganizationUserRoleChanged);
    this.connection.on('OrganizationUserDeleted', onOrganizationUserDeleted);
  }

  async cleanUpConnection() {
    if (
      (this.connection && this.connection?.state === 'Connecting') ||
      this.connection?.state === 'Reconnecting'
    ) {
      this.shouldStopConnection = true;
      return;
    }
    if (!this.connection) return;

    this.connection.off();
    await this.connection.stop();
    this.connection = null;
  }

  componentWillUnmount() {
    this.cleanUpConnection();
  }

  render() {
    return (
      <>
        <DeletedUserEventHandler connection={this.state.connectionHub} />
        <TransferOwnershipHandler connection={this.state.connectionHub} />
        <SpacesEventsHandler connection={this.state.connectionHub} />
        <FeatureFlagsHandler connection={this.state.connectionHub} />
      </>
    );
  }
}

SignalRHandler.propTypes = {
  onConnected: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
};

export default SignalRHandler;
