/* eslint-disable react/jsx-props-no-spreading */
import { Box, Popover, PopoverOrigin } from '@mui/material';
import { ChartData, ChartDataSets, ChartOptions, ChartTooltipModel } from 'chart.js';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Bar, Scatter } from 'react-chartjs-2';
import { getStringTime } from '../../common';
import './index.scss';
import { AvatarPoint, SpaceAnalyticsChartData } from './types';
import { adaptTickData, calculateTickData, initChartExtensions, verticalLinePlugin } from './utils';
import ChartTooltipContent, { ChartTooltipContentProps } from './ChartTooltipContent';
import { avatarPoint } from './avatarPoints';

type AxisBinding = 'left' | 'right' | 'none';
type ChartType = 'bar' | 'line' | 'scatter';
const DEFAULT_TICKS_COUNT = 6;
type ScatterData = {
  x: number;
  y: number;
};

export type ChartDefinition = {
  type: ChartType;
  data: number[] | ScatterData[];
  label: string;
  secondLabel?: string;
  color: string;
  axisBinding: AxisBinding;
  isDuration?: boolean;
  order?: number;
};

export type AxesDefinition = {
  maxValue?: number;
  isDuration?: boolean;
  labelString?: string;
};

type Props = {
  chartDefinitions: ChartDefinition[];
  tooltipLabels: string[];
  chartLabels: string[];
  leftAxesDefinition?: AxesDefinition;
  rightAxesDefinition?: AxesDefinition;
  maxTicksCount?: number;
  avatarPoints?: AvatarPoint[];
  showLegend?: boolean;
  showTooltip?: boolean;
  chartEvents?: ChartOptions['events'] | undefined;
};

interface SpaceAnalyticsChartDataSet extends ChartDataSets {
  isDuration?: boolean;
  secondLabel?: string;
}

const mapBarData: (def: ChartDefinition, index: number) => SpaceAnalyticsChartDataSet = (
  def,
  index
) => {
  return {
    type: 'bar',
    label: def.label,
    data: def.data,
    fill: false,
    backgroundColor: def.color,
    borderColor: def.color,
    yAxisID: def.axisBinding,
    order: def.order || index,
    isDuration: def.isDuration,
    secondLabel: def.secondLabel,
    hoverBackgroundColor: def.color,
  };
};

const mapLineData: (def: ChartDefinition, index: number) => SpaceAnalyticsChartDataSet = (
  def,
  index
) => {
  return {
    type: 'line',
    label: def.label,
    data: def.data,
    borderColor: def.color,
    pointHoverBackgroundColor: def.color,
    pointHoverBorderColor: `${def.color}33`,
    yAxisID: def.axisBinding,
    order: def.order || index,
    secondLabel: def.secondLabel,
    isDuration: def.isDuration,
  };
};

const mapScatterData: (def: ChartDefinition, index: number) => SpaceAnalyticsChartDataSet = (
  def,
  index
) => {
  return {
    type: 'scatter',
    label: def.label,
    data: def.data,
    borderColor: `#FFFFFF`,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    pointHoverBackgroundColor: def.color,
    pointHoverBorderColor: `${def.color}33`,
    yAxisID: def.axisBinding,
    order: def.order || index,
    isDuration: def.isDuration,
    borderWidth: 2,
    pointRadius: 6,
    borderJoinStyle: 'round',
    tension: 2,
  };
};

initChartExtensions();

const BarChart = React.memo(Bar);

const Chart = ({
  chartDefinitions,
  chartLabels,
  tooltipLabels,
  leftAxesDefinition,
  rightAxesDefinition,
  maxTicksCount,
  avatarPoints,
  showLegend = true,
  showTooltip = true,
  chartEvents = undefined,
}: Props) => {
  const chartRef = useRef<Bar>(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipOrigin, setTooltipOrigin] = useState<PopoverOrigin>();
  const tooltipModel = useRef<ChartTooltipContentProps>({ title: '', body: [], colors: [] });
  const calculateAxis = useCallback(() => {
    const leftAxes = leftAxesDefinition?.maxValue
      ? calculateTickData(
          0,
          leftAxesDefinition.maxValue,
          maxTicksCount || DEFAULT_TICKS_COUNT,
          leftAxesDefinition.isDuration
        )
      : undefined;
    const rightAxes = rightAxesDefinition?.maxValue
      ? calculateTickData(
          0,
          rightAxesDefinition.maxValue,
          maxTicksCount || DEFAULT_TICKS_COUNT,
          rightAxesDefinition.isDuration
        )
      : undefined;
    if (leftAxes && rightAxes) {
      return adaptTickData(leftAxes, rightAxes);
    }

    return { left: leftAxes, right: rightAxes };
  }, [leftAxesDefinition, maxTicksCount, rightAxesDefinition]);

  const isScatterChart = chartDefinitions?.some((x) => x.type === 'scatter');

  const handleShouldShowTooltip = useCallback(
    (model: ChartTooltipModel) => {
      if (!showTooltip) {
        return;
      }
      if (model.opacity !== 0) {
        tooltipModel.current = {
          title:
            model.title?.filter((x) => !!x)[0] ||
            tooltipLabels[model.dataPoints[0].index as number],
          body: model.body?.map((x) => x.lines[0]).reverse() || [],
          colors: model.labelColors?.map((x) => x.borderColor.toString()).reverse() || [],
        };
      }
      if (!chartRef.current?.chartInstance?.canvas) {
        return;
      }

      let tooltipX = model.caretX;
      let tooltipY = model.caretY - 12;
      if (model.dataPoints?.length === 1 && model.dataPoints[0].x && model.dataPoints[0].y) {
        tooltipX = model.dataPoints[0].x;
        tooltipY = model.dataPoints[0].y - 20;
      }

      setTooltipOrigin((prev) => {
        if (prev?.horizontal !== tooltipX || prev.vertical !== tooltipY) {
          return {
            horizontal: tooltipX,
            vertical: tooltipY,
          };
        }
        return prev;
      });

      setIsTooltipOpen((prev) => {
        const shouldOpen = model.opacity !== 0;
        if (prev === shouldOpen) {
          return prev;
        }
        return shouldOpen;
      });
    },
    [showTooltip, tooltipLabels]
  );

  const axis = calculateAxis();

  const options = useMemo<ChartOptions>(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      spanGaps: true,
      layout: {
        padding: {
          right: 5,
        },
      },
      legend: {
        display: false,
      },
      hover: {
        intersect: false,
        mode: 'index',
        onHover: (event) => {
          if (event.type === 'mouseout') {
            setIsTooltipOpen(false);
          }
        },
      },
      plugins: {
        verticalLine: {
          color: '#00000066',
          lineWidth: 1,
          enabled:
            chartDefinitions.some((x) => x.type === 'bar') &&
            chartDefinitions.some((x) => x.type === 'line'),
        },
        avatarPoint: {
          imageElements: [],
          enabled: isScatterChart,
        },
      },
      elements: {
        point: {
          radius: isScatterChart ? 4 : 0,
          borderWidth: isScatterChart ? 8 : 1,
          borderColor: '#ffffff',
          hitRadius: 4,
          hoverRadius: 4,
          hoverBorderWidth: 8,
          pointStyle: 'circle',
        },
        line: {
          borderWidth: 2,
          tension: 0,
          fill: false,
        },
      },
      scales: {
        yAxes: [
          {
            display: !!axis.left,
            id: 'left',
            position: 'left',
            gridLines: {
              display: true,
              drawOnChartArea: true,
              drawBorder: false,
              color: '#ECEEEF',
              zeroLineColor: '#ECEEEF',
            },
            ticks: {
              beginAtZero: true,
              min: axis.left?.min,
              stepSize: axis.left?.stepSize,
              max: axis.left?.max,
              callback: (dataLabel) => {
                if (!dataLabel || dataLabel === '') {
                  return '0';
                }
                if (leftAxesDefinition?.isDuration) {
                  return getStringTime(dataLabel);
                }
                return dataLabel;
              },
            },
            scaleLabel: {
              display: !!leftAxesDefinition?.labelString,
              labelString: leftAxesDefinition?.labelString,
            },
          },
          {
            display: !!axis.right,
            id: 'right',
            position: 'right',
            gridLines: {
              display: true,
              drawBorder: false,
              drawOnChartArea: !axis.left,
              color: '#ECEEEF',
              zeroLineColor: '#ECEEEF',
            },
            ticks: {
              beginAtZero: true,
              min: axis.right?.min,
              stepSize: axis.right?.stepSize,
              max: axis.right?.max,
            },
          },
        ],
        xAxes: [
          {
            display: true,
            gridLines: {
              display: isScatterChart,
              drawOnChartArea: true,
              drawBorder: false,
              color: '#ECEEEF',
              zeroLineColor: '#ECEEEF',
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              beginAtZero: true,
              maxTicksLimit: 5,
              callback: (dataLabel, index, values) => {
                if (index === 0 || index === values.length - 1) {
                  return null;
                }
                return dataLabel;
              },
            },
            scaleLabel: {
              display: !!rightAxesDefinition?.labelString,
              labelString: rightAxesDefinition?.labelString,
            },
          },
        ],
      },
      tooltips: {
        enabled: false,
        mode: 'index',
        position: 'top',
        multiKeyBackground: 'transparent',
        intersect: false,
        xPadding: 16,
        yPadding: 16,
        caretSize: 16,
        callbacks: {
          label: (tooltipItem, chartData) => {
            if (
              chartData.datasets &&
              tooltipItem.datasetIndex !== undefined &&
              tooltipItem.value !== undefined
            ) {
              const label = chartData.datasets[tooltipItem.datasetIndex].label;
              const def = chartData.datasets[
                tooltipItem.datasetIndex
              ] as SpaceAnalyticsChartDataSet;
              if (def?.isDuration) {
                return `${label} ${def.secondLabel || ''}: ${getStringTime(tooltipItem.value)}`;
              }
              return `${label} ${def.secondLabel || ''}: ${tooltipItem.value}`;
            }

            return '';
          },
          title: (items, chartData) => {
            const saChartData = chartData as SpaceAnalyticsChartData;
            if (saChartData && saChartData.tooltipLabels && items[0].index) {
              return saChartData.tooltipLabels[items[0].index];
            }
            return '';
          },
        },
        custom: handleShouldShowTooltip,
      },
    }),
    [
      axis.left,
      axis.right,
      chartDefinitions,
      handleShouldShowTooltip,
      isScatterChart,
      leftAxesDefinition?.isDuration,
      leftAxesDefinition?.labelString,
      rightAxesDefinition?.labelString,
    ]
  );

  const chartConfig: ChartData & { tooltipLabels: string[] } = useMemo(
    () => ({
      tooltipLabels: tooltipLabels,
      labels: chartLabels,
      datasets: chartDefinitions.map((x, index) => {
        if (x.type === 'bar') {
          return mapBarData(x, index);
        } else if (x.type === 'scatter') {
          return mapScatterData(x, index);
        }
        return mapLineData(x, index);
      }),
      avatarPoints,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartDefinitions, chartLabels, tooltipLabels]
  );

  const renderLegend = (def: ChartDefinition) => {
    return (
      <Box key={Math.random()} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <span className="icon-minus-solid" style={{ color: def.color, gap: '8px' }}></span>
        <span>{def.label}</span>
      </Box>
    );
  };

  if (chartEvents) {
    options.events = chartEvents;
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }} className="chart-area">
      <Box
        className="chart-container"
        style={{
          height: 280,
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {isScatterChart ? (
          <Scatter data={chartConfig} options={options} ref={chartRef} plugins={[avatarPoint]} />
        ) : (
          <BarChart
            data={chartConfig}
            options={options}
            ref={chartRef}
            plugins={chartDefinitions.length === 1 ? [] : [verticalLinePlugin]}
            datasetKeyProvider={() => Math.random()}
          />
        )}
      </Box>
      {/* <Box style={{ display: 'flex', justifyContent: 'space-between' }} px={6}>
        {['', ...chartLabels, ''].map(renderChartLabel)}
      </Box> */}
      {showLegend && (
        <Box
          style={{ display: 'flex', gap: 16, paddingLeft: 0 }}
          className="chart-legend-container"
        >
          {chartDefinitions.map(renderLegend)}
        </Box>
      )}

      <Popover
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        anchorOrigin={tooltipOrigin}
        disableRestoreFocus
        anchorEl={chartRef.current?.chartInstance.canvas}
        open={isTooltipOpen}
        style={{ pointerEvents: 'none' }}
        classes={{ paper: 'chart-tooltip' }}
      >
        {isTooltipOpen && <ChartTooltipContent {...tooltipModel.current} />}
      </Popover>
    </Box>
  );
};

export default React.memo(Chart);
