import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import HomeButton from '../components/HomeButton';
import './style.scss';
import i18n from '../i18n';
import AppHeader from '../app/AppHeader';
import { PrivacySpace } from '../app/appConstants';
import Icons from '../components/Icons';
import OfficialButtons from '../components/OfficialButtons';
import { SpaceAnalyticsContextProvider, useSpaceAnalyticsContext } from './SpaceAnalyticsContext';
import { Ids } from '../commons/pendoTaggings';
import SpaceInfo from '../homepage/SpaceInfo';
import FilterButtonDropdown from './FilterButton';
import { useLocalStorage } from '../commons/CustomHooks';
import DateFilterSelect from '../CompanyAnalytics/Filter/DateFilterSelect';
import SpaceAnalyticsSummary from './Summary';
import { Feature } from '../types/types';

function SpaceAnalyticsBase() {
  const navigate = useNavigate();
  const { space, setIsExcludeInternalUser, dateRange, handleFilterByDateChange } =
    useSpaceAnalyticsContext();
  const spacePrivacy = space?.spacePrivacy;
  const spaceId = space?.id;
  const isPublicSpace = spacePrivacy === PrivacySpace.PUBLIC;
  const [dontShowInfoBox, setDontShowInfoBox] = useLocalStorage('dismissInfoBox', false);
  const [filterLabel, setFilterLabel] = useLocalStorage('companyAnalyticsFilterLabel', '');

  const initialDateRange = [
    {
      startDate: dateRange.startDate ? new Date(dateRange.startDate) : new Date(),
      endDate: dateRange.endDate ? new Date(dateRange.endDate) : new Date(),
      key: 'selection',
    },
  ];

  const spaceFeatures: Feature[] = space?.features || [];
  const isChatEnabled = spaceFeatures.some((feature) => feature.id === 'Chat' && feature.enabled);

  console.log('isChatEnabled', isChatEnabled);

  function handleBackToSpace() {
    navigate(`/s/${spaceId}`);
  }

  function renderContent() {
    return <SpaceAnalyticsSummary />;
  }

  const handleOnChangeFilter = (checked: boolean) => {
    setIsExcludeInternalUser(checked);
  };

  function handleOnDismissInfoBox() {
    setDontShowInfoBox(true);
  }

  return (
    <>
      <AppHeader hideLogo className="insights-header-2">
        <div className="insights-header-2-content">
          <SpaceInfo
            spaceName={space?.name}
            spaceId={space?.id}
            spaceLogo={space?.theme?.logoImageSrc}
            isPortal
          />
          <div className="insights-header-2-right">
            <OfficialButtons
              variant="regular-primary"
              label={i18n.t('back')}
              onClick={handleBackToSpace}
              dataId="space-analytics-back-button"
            />
            <div className="vertical-divider">
              <Divider className="divider" />
            </div>
            <HomeButton />
          </div>
        </div>
      </AppHeader>

      <div className={`space-container-2 ${isPublicSpace ? 'public-space' : ''}`}>
        <div className="insights-2 space-analytics-2">
          <div className="space-analytics-2-header">
            <div className="header-info">
              <Typography className="title">{i18n.t('Space Analytics')}</Typography>
            </div>
            <Box display="flex" className="header-right">
              <FilterButtonDropdown onChange={handleOnChangeFilter} />
              <DateFilterSelect
                onChange={handleFilterByDateChange}
                initialDateRange={initialDateRange}
                filterLabel={filterLabel}
                setFilterLabel={setFilterLabel}
              />
            </Box>
          </div>
          <div className="styled-scrollbar">
            <div className="space-analytics-2-body">
              {isPublicSpace && !dontShowInfoBox && (
                <div className="info-wrapper">
                  <div className="alert-info">
                    <div>
                      <Icons name="icon-new-info" className="icon" />
                      <span className="message">
                        {i18n.t(
                          'Data in public spaces are anonymous since users enter without name and email.'
                        )}
                      </span>
                    </div>
                    <IconButton
                      aria-label="close"
                      className="close-button"
                      color="primary"
                      data-id={Ids.dismissPublicSpaceInfoBox}
                      onClick={handleOnDismissInfoBox}
                    >
                      <CloseIcon className="close-icon" />
                    </IconButton>
                  </div>
                </div>
              )}
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const SpaceAnalytics = () => (
  <SpaceAnalyticsContextProvider>
    <SpaceAnalyticsBase />
  </SpaceAnalyticsContextProvider>
);

export default SpaceAnalytics;
