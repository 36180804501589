import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import { useDebouncedCallback } from 'use-debounce';
import TextFieldEnhanced from './TextFieldEnhanced';

const SearchField = React.forwardRef((props, ref) => {
  const textInput = React.useRef(null);
  const onChangeText = useDebouncedCallback((name, value) => props.onChange(value), props.delay);

  function reset() {
    props.onChange('');
    if (textInput?.current) {
      textInput.current.value = '';
      if (textInput.current.focus) textInput.current.focus();
    }
  }

  // React.useImperativeHandle(ref, () => textInput.current);
  React.useImperativeHandle(ref, () => ({
    reset,
  }));

  return (
    <TextFieldEnhanced
      autoFocus
      inputRef={textInput}
      maxLength={200}
      name="name"
      onChange={onChangeText}
      onPressEnter={props.onPressEnter}
      placeholder={props.placeholder}
      variant={props.variant}
      disabled={props.disabled}
      InputProps={{
        disableunderline: 'true',
        startAdornment: (
          <InputAdornment>
            <span className="icon-search" style={{ fontSize: 18 }}></span>
          </InputAdornment>
        ),
        endAdornment: props.showClearButton && textInput?.current?.value && (
          <InputAdornment>
            <span
              onClick={reset}
              className="clickable icon-close"
              style={{ fontSize: 14, cursor: 'pointer' }}
            ></span>
          </InputAdornment>
        ),
      }}
      className={props.className}
      defaultValue={props.defaultValue}
      value={props.value}
    />
  );
});

SearchField.propTypes = {
  onChange: PropTypes.func,
  delay: PropTypes.number,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  onPressEnter: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  showClearButton: PropTypes.bool,
};

SearchField.defaultProps = {
  delay: 600,
  placeholder: '',
  variant: 'standard',
  disabled: false,
  className: 'search-field',
  showClearButton: true,
};

export default SearchField;
