import React from 'react';
import propTypes from 'prop-types';
import AssessmentDialog from '../../assessments/components/AssessmentDialog';
import AssessmentSpace from './AssessmentSpace';
import { useAssessmentContext } from '../../assessments/components/AssessmentContextProvider';

import TrialAssessment from '../../assessments/settings/TrialAssessment';

function AssessmentPopup({ spaceHostEmail }) {
  const { selectedAssessment, closeAssessment, openAssessment } = useAssessmentContext();

  return (
    <AssessmentDialog open={!!selectedAssessment} onClose={closeAssessment}>
      {selectedAssessment?.type === 'trial' ? (
        <TrialAssessment id={selectedAssessment?.id} isMobile={selectedAssessment.isMobile} />
      ) : (
        <AssessmentSpace
          id={selectedAssessment?.id}
          userAssessmentId={selectedAssessment?.userAssessmentId}
          type={selectedAssessment?.type}
          spaceHostEmail={spaceHostEmail}
          onClose={closeAssessment}
          openAssessment={openAssessment}
        />
      )}
    </AssessmentDialog>
  );
}

AssessmentPopup.propTypes = {
  spaceHostEmail: propTypes.string,
};

export default AssessmentPopup;
