import { SubscriptionType, SubscriptionFeaturesTypes } from './subscriptionConstants';
import i18n from '../../i18n';

export function getTagLabel(subscriptionType) {
  let tagLabel = '';
  switch (subscriptionType) {
    case SubscriptionType.premium:
      tagLabel = i18n.t('premium');
      break;
    case SubscriptionType.enterprise:
      tagLabel = i18n.t('enterprise');
      break;
    default:
      tagLabel = i18n.t('freemium');
      break;
  }
  return tagLabel;
}

export function getButtonLabel(subscriptionType, buttonLabelCustom) {
  const text = buttonLabelCustom || 'Go for';
  let buttonLabel = i18n.t(`${text} premium`);
  let buttonClass = 'premium';

  if (subscriptionType === SubscriptionType.enterprise) {
    buttonLabel = i18n.t(`${text} enterprise`);
    buttonClass = 'enterprise';
  }
  return {
    buttonLabel,
    buttonClass,
  };
}

export function getSubscriptionRequireAndFeatureTypes(currentSubscription) {
  let subscriptionRequire = null;
  let subscriptionFeatureType = null;
  if (currentSubscription === SubscriptionType.free) {
    subscriptionRequire = SubscriptionType.premium;
    subscriptionFeatureType = SubscriptionFeaturesTypes.common;
  } else if (currentSubscription === SubscriptionType.premium) {
    subscriptionRequire = SubscriptionType.enterprise;
    subscriptionFeatureType = SubscriptionFeaturesTypes.enterprise;
  }

  return { subscriptionRequire, subscriptionFeatureType };
}

export function hasPackageId(packages, id) {
  if (!packages) {
    return false;
  }
  const ids = packages.map((p) => p.id);
  return ids.includes(id);
}
