import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import { getTheme } from '../theme';
import i18n from '../i18n';
import ResponsiveDialog from './ResponsiveDialog';
import Loading from './Loading';
import { fetchFolderDetails } from '../resources/ResourceServices';
import OfficialButton from './OfficialButtons';
import { removeURLParams } from '../commons/utils';

let resolve;
let containerElement;
let rootEl;

class FolderNotFoundDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isLoading: true,
      hasNoAccess: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    try {
      const resp = await fetchFolderDetails(this.props.spaceId, this.props.folderId);
      if (resp.folder === null) {
        this.setState({ hasNoAccess: false });
      }
    } catch (error) {
      console.error(error);
    }
    this.setState({ isLoading: false });
  }

  async componentWillUnmount() {
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (containerElement) {
      rootEl.unmount();
    }
    if (resolve) resolve(retVal);
  }

  static show(spaceId, folderId) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);

    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <FolderNotFoundDialog spaceId={spaceId} folderId={folderId} />
      </ThemeProvider>
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose() {
    removeURLParams(['srId']);
    this.setState({ isOpen: false }, () => {
      FolderNotFoundDialog.destroy();
    });
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }
    if (this.state.isLoading) {
      return (
        <ResponsiveDialog
          modal
          maxWidth="lg"
          className="white new-popup"
          onClose={this.handleClose}
        >
          <Loading />
        </ResponsiveDialog>
      );
    }
    return (
      <ResponsiveDialog modal maxWidth="lg" className="white new-popup" onClose={this.handleClose}>
        {this.state.hasNoAccess ? (
          <div className="content">
            <h1>{i18n.t('YOU HAVE NO ACCESS')}</h1>
            <p>
              {i18n.t(
                'Uh-oh, you need higher access to view this resource. Please contact host to get access.'
              )}
            </p>
          </div>
        ) : (
          <div className="content">
            <h1>{i18n.t('RESOURCE DELETED')}</h1>
            <p style={{ display: 'block' }}>
              {i18n.t('This resource has been deleted.')}
              <br />
              {i18n.t('Please contact the host if you need to access it.')}
            </p>
          </div>
        )}
        <div className="action-buttons">
          <OfficialButton
            onClick={this.handleClose}
            label={this.state.hasNoAccess ? i18n.t('OK, I UNDERSTAND') : i18n.t('Ok')}
            variant="large-primary"
          />
        </div>
      </ResponsiveDialog>
    );
  }
}

FolderNotFoundDialog.propTypes = {
  spaceId: PropTypes.string,
  folderId: PropTypes.number,
};

export default FolderNotFoundDialog;
