/* global pendo */
import _capitalize from 'lodash/capitalize';

export const Ids = {
  SpaceTab: 'g1lkQiNEdtY2',
  TemplateTab: 'g4vPo0RBa6jS',
  AddPagesButton: 'add-pages-item',
  ActivitiesTab: 'qGVzo78RB6ek',
  viewDemoSpaceAnalyticsButton: 'view-demo-space-analytics',
  viewDemoCompanyAnalyticsButton: 'view-demo-company-analytics',
  TagsFilterButtonCompanyAnalytics: 'tags-filter-company-analytics',
  BackButtonDemoViewCompanyAnalytics: 'back-view-button-company-analytics',
  GotItDemoViewCompanyAnalytics: 'got-it-button-company-analytics',
  NextDemoViewCompanyAnalytics: 'next-button-company-analytics',
  VisitSpaceTopPerformingCompanyAnalytics: 'visit-space-top-performing-company-analytics',
  ExternalResourceCompanyAnalytics: 'external-resource-company-analytics',
  CreateTagBtnCompanyAnalytics: 'create-tag-btn-company-analytics',
  viewDemoSpaceAnalyticsWelcomePopup: 'view-demo-space-analytics-welcome-popup',
  maybeLaterButtonSpaceAnalytics: 'maybe-later-button-space-analytics',
  saveSpace: 'cVj8NQABErnE',
  templateEmptyCard: 'qd7OHN3ldhjg',
  dismissPublicSpaceInfoBox: 'dismiss-public-space-info-box',

  spaceEmptyCard: 'ql1LbCh4mGEC',
  spaceThemePreview: 'mypHnM01dJ5a',
  spaceThemeSelect: 'QcaYW4T7GEqY',
  spaceTemplateCard: 'cvPGwgIsGt1x',
  maybeLaterButtonCompanyAnalytics: 'maybe-later-button-company-analytics',
  viewDemoCompanyAnalyticsWelcomePopup: 'view-demo-company-analytics-welcome-popup',
  ShowroomEmptyCard: 'eBpVerjpK6tQ',
  ShowroomThemePreview: 'BaN1gqoNrPs8',
  ShowroomThemeSelect: 'zaBM83VoTB74',
  ShowroomTemplateCard: '6mBskY4N6Xn5',

  useShowroomThemeSelect: '0ev5VZJGl2vZ',
  usespaceThemeSelect: 'RURuiGjFpOD1',

  ShowroomThemeTemplateCard: '0ev5VZJGl2vZ',
  spaceThemeTemplateCard: 'RURuiGjFpOD1',
  useShowroomThemeTemplateCard: 'L4Pxjneo6NYs',
  usespaceThemeTemplateCard: 'iK6V6qsQJ2yT',

  useNowspaceTheme: 'cAKT78VGX5EF',
  useNowShowroomTheme: 'Dp9JFmR9EZxU',
  useNowspaceThemeTemplate: 'W0Lf7LLcpcGO',
  useNowShowroomThemeTemplate: '9U5E7LAzdSZE',

  vaamIconButton: 'u6UZIh8WQ6dd',
  vaamRetryBtn: 'UjbERi6UDvPs',
  vaamStartRecording: 'oYcDReY31pK9',
  vaamShareBtn: 'vb2MH7yG8Xi3',
  vaamShareBtnDialogContent: 'qxuVgL3FEj7k',
  vaamDeleteMsgBtnDialogContent: 'UGZyYWMQ9lII',
  shareSpace: 'P2Ies06piBk7',
  shareSpaceCopyButton: '3g7X6g2w9ZrA',
  shareSpaceGmail: '0nlpsMWsOAoy',
  shareSpaceLinkedin: 'waMSaFea5Z11',
  shareSpaceFacebook: 'znPY0MgxCgNz',
  shareSpaceTwitter: 'Xq1IDeIFpg5B',
  checkboxIncludeParticipant: 'ot7cW9zrpdc1',
  editSpace: '2UckJHeLAzA5',
  duplicateSpace: 'tDHD1PTMtMdq',
  saveAsTemplateSpace: 'RbHoEYQ1vgl3',
  analyticsSpace: 'mlHiCQ83Hy0L',
  transferOwnershipSpace: 'pfZc6j7tP5HX',
  closeSpace: '0Z1Kbg3fBwYG',
  openSpace: 'hdRVHj1qzGwp',
  deleteSpace: 'qtoPC1EUL35C',
  addedGoogleAnalytics: 'DGg7IvPgJxm1',
  sendNotification: 'wH3YkdMJ314P',
  googleAnalyticsToggle: 'aGSnC737HpdI',
  confirmedTransferOwnerShip: 'Rh0WUV4wzO58',
  confirmedDuplicateSpace: '2fSYkqkVn6Gt',
  getLogoCreateSpace: 'nEjWP5J9Yaug',
  uploadLogoImage: 'b5WtcGBu3Fpz',
  uploadBackgroundImage: '4FEyxTuU6nXr',
  createSpaceWizard: '2HgbJlXGppqX',
  saveSpaceTag: 'py7zIZBaD3ae',
  selectUnsplashImage: 'e1IFpLte47I3',
  tagItem: '7QJbgBcOoEJE',
  JoinsFirstTimeAppYou: 'olluZYGr6RcY',
  JoinsFirstTimeEmailYou: 'xi0I6vUbUzbE',
  ResourceAddedAppYou: '3Y5a6rkkjUOq',
  ResourceAddedEmailYou: 'bwBz34YA0B0R',
  ChatAddedAppYou: 'y5oHSleA9uTV',
  ChatAddedEmailYou: 'Nxk3T3cr4W0y',
  ActivityAssignedAppYou: 'UWdA6rBsPc96',
  ActivityAssignedEmailYou: 'I002U4pVUtem',
  ActivityUpdatedAppYou: 'UWdA6rBsPc96',
  ActivityUpdatedEmailYou: 'I002U4pVUtem',
  ActivityDueAppYou: 'YZO14GkUcNLo',
  ActivityDueEmailYou: '23zNKk5oymqO',
  ParticipantAssignedHostAppYou: 'tER4Y7ylIQxx',
  ParticipantAssignedHostEmailYou: 'sbh2O7doMePk',
  VideoMessageViewedAppYou: 'tVRl5J59ttrK',
  VideoMessageViewedEmailYou: 'TPFjfX1D7K4Z',
  ResourceAddedEmailParticipant: 'oUJOY5W7oDO9',
  ChatAddedEmailParticipant: 'Z28ZOeQjbUam',
  ActivityAssignedEmailParticipant: 'dOIr6A44sOG7',
  ActivityUpdatedEmailParticipant: 'dOIr6A44sOG7',
  ActivityDueEmailParticipant: 'kTryK97f8osN',
  JoinsFirstTimeEmailYouNotifyTime: 'McFN4VSXSLPQ',
  ResourceAddedEmailYouNotifyTime: 'eeOA0j1ers8z',
  ChatAddedEmailYouNotifyTime: '1vrOFV1qqSKl',
  ActivityAssignedEmailYouNotifyTime: 'kQM4eBZ1pAYe',
  ActivityUpdatedEmailYouNotifyTime: '2bBA4zocZ0Yk',
  ActivityDueEmailYouNotifyTime: '46BOcJ4eY2FK',
  ParticipantAssignedHostEmailYouNotifyTime: 'TBj5DKMmz3gd',
  VideoMessageViewedEmailYouNotifyTime: 'hJUQR6eLh6Kb',
  ResourceAddedEmailParticipantNotifyTime: 'nAh9640Jm5c9',
  ChatAddedEmailParticipantNotifyTime: '7Mhxp1e8CKmr',
  ActivityAssignedEmailParticipantNotifyTime: 'cw8YtfuBLRRZ',
  ActivityUpdatedEmailParticipantNotifyTime: 'WF2JO95Hpsib',
  ActivityDueEmailParticipantNotifyTime: 'yoRoCxPXfON0',
  SpaceCard: 'tyN9qi8wvZQ4',
  SpaceTemplateCard: 'nEP6QY95VAi5',
  CreateSpace: 'p73CzHoigBrt',
  CreateSpaceTemplate: 'znpXR4EN6Toh',
  AvatarButton: 'fY1VR3aR8ipS',
  CompanyAnalytics: 'hdRVHj1qzGwp',
  Settings: 'XmUrBvs19R5o',
  UserManagement: 'a7W0CzXFmphl',
  GlobalResources: 'vEtMM5hwGo6W',
  Logout: '30OWDxF7UcUo',
  SearchSpace: '9rmhTl16CfiD',
  ClearFilterSpace: 'VUDOAL46h0Zv',
  SpaceStateOpen: 'egW476S9V4GL',
  SpaceStateClosed: 'qJCc5QH0RZ8X',
  SpaceThemesspace: 'a0TD8nLkwVNi',
  SpaceThemesShowroom: 'C3CAxbs62Jiz',
  SpaceUsersSearch: 'kNSFp17EIGmd',
  SpaceUsersCheckbox: '9hNQhnA4Vo2I',
  SpaceUsersViewAll: 'lOJIRE6H6bqs',
  SpaceTagsSearch: 'oPbRu1XXPLcr',
  SpaceTagsCheckbox: 'ass9Ju41BbUL',
  SpaceTagsViewAll: 'fGKPXn9E3SUd',
  SortSpaceByName: '9rmhTl16CfiD',
  SortSpaceByDate: 'cPKCN3NGKsgb',
  SortSpaceByEngagement: '4gAsQjGKs12t',
  SearchSpaceTemplate: 'Gl295oMqWwkY',
  ClearFilterSpaceTemplate: 'tnZW7RWyBOJ4',
  TemplateThemesspace: 'v9kXvixD1VD4',
  TemplateThemesShowroom: 'mddBDH7m5TWT',
  TemplateAdminsSearch: 'kBwj86bQsIpE',
  TemplateAdminsCheckbox: 'D0yGQonaezBq',
  TemplateAdminsViewAll: 'NDnmmrGKK5Mk',
  TemplateTagsSearch: 'PE7CaLKcqZ89',
  TemplateTagsCheckbox: '4s6kBjrSR2xl',
  TemplateTagsViewAll: 'YVybnn4t14my',
  SortTemplateByName: '9E0R5T1wGYmP',
  SortTemplateByDate: '7FGEpyXm13E1',
  SortTemplateByEngagement: 'wzpmFdhS4H9D',
  F98mJaa3l3g6: 'F98mJaa3l3g6',
  ConfirmDeleteSpace: 'D0bNN8KYVy8z',
  CustomerTAndC: 'eVd0shvKNL9p',
  SpaceTAndC: 'MHdV4f0MGsDw',
  PrivacyPolicyLink: 'jecK3DJQP1CG',
  EnterSpaceButton: 'wwhFJ5SeCQtH',
  GoToSpaceButton: '2XkyC1ZHdVij',
  RequestAccessSpaceButton: '0QxvMUKxI25M',
  CancelCloseSpace: 'gP8dgOtuQEIt',
  ConfirmCloseSpace: 'xtGBKWPxa9SW',
  WhiteListDomainButton: 'WZeeMHSy0mXf',
  EnterSpaceFormError: 'BBK12cS1bXnz',
  ContactSupportLink: 'HSGY96ulCddd',
  WaitSlowConnectionButton: 'EqFycCtJ9iE0',
  RefreshSlowConnectionButton: 'l6UP8lWDYzKt',
  ReconnectButton: 'EE9AksfldL38',
  ActivitiesShareWithMe: 'cTIWBwBiAI4h',
  ActivitiesRecording: 'Jh4QFR36l23T',
  SearchMeetings: 'rFs2Ul0iXwG4',
  EditMeeting: '6gNKWLe6VdsC',
  RecordingsAction: 'wvYU0xdJG8h5',
  DeleteMeeting: 'zVwW6fwC9Esv',
  RecordingItem: 'Ffh1NT1orRMz',
  ConfirmDeleteMeeting: 'za3uDBTQNoqV',
  SearchTodos: '9btmCifOGrKm',
  todoClear: 'rRF4Y1YITqRx',
  todostatusopen: 'xw9jI884ibXc',
  todostatusclosed: '7Czs3ow4zgKm',
  TodoUpdate: 'WJ604xRLv4mi',
  TodoMarkAsClosed: 'AbtFZ683Jkla',
  TodoReOpen: '7ZwruOgYmh8E',
  TodoUnpin: 'gfM8rSwKg4HM',
  TodoDelete: 'iArPp4FumEvD',
  ConfirmedDeleteActivity: 'L80o5izMwAAu',
  RecordingSearch: 'X1hThjTlkQFK',
  recordingClear: 'z0sVJEFY7TKr',
  recordingstatusnew: '7X2ofD4Nvv8e',
  recordingstatusviewed: 'LSririDJYJt6',
  RecordingPlay: 'lma7vO0xfgzz',
  RecordingShare: 'VeCKst0buC8N',
  RecordingRate: 'nHpWlDI94XiY',
  RecordingDelete: 'O766ycCiE34R',
  RecordingRemoveFormList: 'FKJzH9LO7Arl',
  RatingStars: 'e0e8LSAjRiJQ',
  ReviewRecording: '8zB1kEXekvJS',
  SubmitRate: 'I9CnYQOrqaHv',
  SendShareRecording: 'DOLXzaF8YGmn',
  CouldNotFoundRecording: 'ivhbmVggE78y',
  AddNewBlock: 'hKEBgo5w9dEu',
  SaveDesignSpace: 'k6pP46ZDGWFB',
  BlockMoveUp: 'wa80vS8Rgo8n',
  BlockMoveDown: 'VgHG1FjM7vzJ',
  BlockDuplicate: '417ZKzrQmGYZ',
  BlockHide: 'swQx7WSwk5M9',
  BlockDelete: '1arGb7YR4t0C',
  BlockCopy: 'block-copy-button',
  BlockPaste: 'block-paste-button',
  EditTemplate: 'bRm4XS2XOoEP',
  DuplicateTemplate: '046ObSBMGGnB',
  googleAnalyticsToggleTemplate: 'aGSnC737HpdI',
  transferOwnershipTemplate: 'VEqwb1xR4Zb3',
  DeleteTemplate: 'qtoPC1EUL35C',
  EditSpaceButtonIcon: 'S2u4h94Fm0Jk',
  EditDesignSpaceButtonIcon: 'mluIoe7BoTs0',
  BackToHomeButton: 'OM8DgFD6ax9y',
  NotificationButton: '2QUfz6ooa8CW',
  InsightSpaceIconButton: 'noDaq45K3Ikm',
  OpenTimelineIconButton: '564TjYeKQjiL',
  ParticipantIconButton: 'tDbn0O5SwJo0',
  ResourceIconButton: 'biC5gnTw94eB',
  ChatIconButton: '3P6NwJC888l6',
  TodoIconButton: 'LSrbhCYmPb1D',
  CloseSpaceCancel: 'gP8dgOtuQEIt',
  CloseSpaceConfirm: 'xtGBKWPxa9SW',
  NewDomainAddedCancel: 'zyQM2ATBL0Eb',
  NewDomainAddedConfirm: 'F98mJaa3l3g6',
  ReloadPageButton: 'F98mJab3l3g6',
  MeetingsJoinButton: 'meetings-join-button',
  TodoListSpaceCard: 'to-do-list-space-card',
  SettingIconButton: 'setting-icon-button',
  SpacePrivacypaceIconInfo: 'privacy-level-space-icon-info',
  SpaceThemeIconInfo: 'space-theme-icon-info',
  ReloadBackgroundImageIcon: 'reload-background-image-icon',
  ReloadDefaultLogoIcon: 'reload-default-logo-icon',
  SpaceThemeSettingSelect: 'space-theme-setting-select',
  SpacePrivacypaceSelect: 'privacy-level-space-select',
  RequireWorkEmailCheckbox: 'require-work-email-checkbox',
  RequireEmailCheckbox: 'require-email-checkbox',
  LearnMoreAboutSpaceLink: '2g3JxRafkh34',
  LearnMoreAboutSpaceTemplateLink: '8eWctvPFutrN',
  MeetingListSpaceCard: 'meetings-list-space-card',
  InfoSpaceIconButton: 'info-space-icon-button',
  AddColumnButton: 'add-column-button',
  AddPrivateChatWidget: 'add-private-chat-widget',
  CancelRemoveBlockCms: 'cancel-remove-block-cms',
  ConfirmRemoveBlockCms: 'confirm-remove-block-cms',
  SpaceAnalyticsBackButton: 'space-analytics-back-button',
  GoogleAnalyticsSpace: 'googleAnalyticsSpace',
  CancelSpaceWizard: 'cancel-space-wizard',
  SearchUnsplashForBackgroundImage: 'search-unsplash-for-background-image',
  SearchLogoButton: 'search-logo-button',
  GetCompanyLogoButton: 'get-company-logo-button',
  enabledNotificationParticipant: 'enabledNotificationParticipant',
  TagFieldInput: 'tag-field-input',
  CompanyLogoInputSearch: 'company-logo-input-search',
  CustomUrlSpaceInput: 'custom-url-space-input',
  DescriptionYourSpaceInput: 'describe-your-space-input',
  SpaceNameInput: 'space-name-input',
  CreateSpaceDialog: {
    close: 'close-create-space-button',
  },
  UnsavedCreateSpace: {
    close: 'unsaved-changes-create-space-close',
    cancel: 'unsaved-changes-create-space-cancel',
    ok: 'unsaved-changes-create-space-ok',
  },
  useThumbnailImage: 'useThumbnailImage',
  ParticipantListSpaceCard: 'participant-list-space-card',
  deleteChecklistActivity: 'L80o5izMwAAu',
  OrganizationUsersDialogEnterButton: 'organization-users-dialog-enter-button',
  OrganizationUsersDialogBackToLoginButton: 'organization-users-dialog-back-to-login-button',
  // Space Analytics Pendo Ids
  engagementTableShowDetails: 'engagement-table-show-details',
  participantTrafficTableShowDetails: 'participant-traffic-table-show-details',
  todoTableShowDetails: 'todo-table-show-details',
  chatTableShowDetails: 'chat-table-show-details',
  detailsUserTotalTodos: 'details-user-total-todos',
  chatUserTotalMeetings: 'details-user-total-meetings',
  meetingRecordingButton: 'meeting-recording-button',
  totalMeetingsByParticipants: 'total-meetings-by-participants',
  participantTrafficVisits: 'participant-traffic-visits',
  engagementParticipantActions: 'engagement-participant-actions',
  AddBlockFromMenuId: 'T67892RPTUVX',
  resourceMetricViews: 'resource-metric-views',
  resourceMetricShares: 'resource-metric-shares',
  resourceMetricDownloads: 'resource-metric-downloads',
  resourceMetricExternalLink: 'resource-metric-external-link',
  participantMetricViews: 'participant-metric-views',
  participantMetricShares: 'participant-metric-shares',
  participantMetricDownloads: 'participant-metric-downloads',
  meetingTableJoinedParticipants: 'participant-metric-joined-participations',
  videoMessagesTotalViews: 'video-messages-total-views',
  videoMessagesViewButtons: 'video-messages-view-button',
  participantTrafficTableShareSpace: 'participant-traffic-table-share-space',
  participantTrafficTableDownloadTraffic: 'participant-traffic-table-download-traffic',
  chatTableSendChatMessageButton: 'chat-table-send-chat-message-button',
  meetingTableAddMeetingButton: 'meeting-table-add-space-button',
  resourceTableAddResourceButton: 'resource-table-add-resource-button',
  todoTableAddTodoButton: 'todo-table-add-todo-button',
  switchBackToSpaceAnalyticsV1: 'switch-back-to-space-analytics-v1',
  switchToSpaceAnalyticsV2: 'switch-to-space-analytics-v2',
  SwitchDesktopMode: 'switch-desktop-mode',
  SwitchMobileMode: 'switch-mobile-mode',
  GoToParticipantMode: 'go-to-participant-mode',
  ApplyChangesDialog: {
    cancel: 'apply-changes-dialog-cancel-button',
    confirm: 'apply-changes-dialog-confirm-button',
    visit: 'apply-changes-dialog-visit-space-button',
    override: 'apply-changes-dialog-override-changes-switch',
  },
};

const getPageName = () => {
  let pathName = document.location.pathname.split('/').join(' ').trim();
  pathName = _capitalize(pathName);
  if (pathName === '') {
    return 'Homepage';
  }
  return pathName;
};
export const pendoTrackEvent = (name, dataTrack) => {
  try {
    console.log('track', name, dataTrack);

    if (process.env.REACT_APP_ENV === 'local') {
      return null;
    }
    const data = {
      ...dataTrack,
      pageName: getPageName(),
    };
    if (pendo && pendo.isReady && pendo.isReady()) {
      return pendo.track(name, data);
    }
    return setTimeout(() => {
      pendoTrackEvent(name, data);
    }, 500);
  } catch (error) {
    console.error('Error pendo tagging', error);
    return null;
  }
};
