// [TODO]: globalResources
import * as React from 'react';
import i18n from '../../i18n';
import { GlobalResourceButton } from './GlobalResourceButton';
import { GRSelectType } from '../../globalResources/GlobalResourcesPicker';
import { getAllFileEntries, getSelectedFolders } from './getAllFileEntries';
import { typeFolderErrors } from './utils';

export interface IUploadButtonProps {
  isAdmin: boolean;
  isPortal: boolean;
  multiple?: boolean;
  accept?: string;
  isGlobalResourcesEnabled?: boolean;
  pickFolder: (files: File[] | FileList) => void;
  onOpenSelectFolder: () => void;
  onError: (error: keyof typeof typeFolderErrors) => void;
  addFolder: (folder: object) => void;
}

export default function UploadButton(props: IUploadButtonProps) {
  const [drag, setDrag] = React.useState(false);
  const {
    isAdmin,
    isPortal,
    multiple,
    accept,
    isGlobalResourcesEnabled,
    pickFolder,
    onOpenSelectFolder,
    onError,
    addFolder,
  } = props;
  const uploadBtnLabel = i18n.t('Browse your device');
  const dragCounter = React.useRef(0);

  const supportsFileSystemAccessAPI = 'getAsFileSystemHandle' in DataTransferItem.prototype;
  const supportsWebkitGetAsEntry = 'webkitGetAsEntry' in DataTransferItem.prototype;

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (!supportsFileSystemAccessAPI && !supportsWebkitGetAsEntry) {
      onError('common');
      return;
    }
    const { folders, files } = await getSelectedFolders(e.dataTransfer.items);
    if ((folders.length > 0 && files.length > 0) || folders.length > 1) {
      onError('folderNumber');
    } else if (folders.length === 0 && files.length > 0) {
      onError('unsupported');
    } else if (folders.length === 1 && files.length === 0) {
      const filesData = await getAllFileEntries(e.dataTransfer.items);
      const flattenFiles = filesData.reduce((acc, val) => acc.concat(val), []);
      if (flattenFiles?.length > 0) {
        pickFolder(flattenFiles);
        e.dataTransfer.clearData();
        dragCounter.current = 0;
      }
    } else {
      onError('common');
    }
  };
  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };
  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setDrag(false);
    }
  };

  const handleSelectFolder = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isGlobalResourcesEnabled) {
      onOpenSelectFolder();
    }
  };

  const handleClickSelectFolder = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    onOpenSelectFolder();
  };

  return (
    <div className="upload-btns" id="upload-btns">
      <div
        className={`upload-btn ${drag ? 'active' : ''}`}
        onClick={handleSelectFolder}
        onDrop={handleDrop}
        onDragOver={handleDrag}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
      >
        <p>
          {i18n.t('Drop a folder')}
          {isGlobalResourcesEnabled ? i18n.t(' here, ') : i18n.t(' here, or')}
          <br />
          {!isGlobalResourcesEnabled && <a>{i18n.t(uploadBtnLabel)}</a>}
          {isGlobalResourcesEnabled && (
            <>
              <a onClick={handleClickSelectFolder}>{i18n.t(uploadBtnLabel)}</a>
              <br />
              {` ${i18n.t('or')} `}
              <GlobalResourceButton
                isAdmin={isAdmin}
                isPortal={isPortal}
                multiple={multiple}
                accept={accept}
                selectType={GRSelectType.Folder}
                addFolder={addFolder}
              />
            </>
          )}
        </p>
      </div>
    </div>
  );
}
