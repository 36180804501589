import React from 'react';
import PropTypes from 'prop-types';
import { ResourceType } from '../../app/appConstants';
import ResourceIcon from '../../components/Material/ResourceIcon';
import './CommentText.scss';

export const CommentText = ({ resource, text }) => {
  let commentMessage = (
    <span
      className="comment-message"
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
  if (resource) {
    const { resourceType, resourceName } = resource;
    if (resourceType === ResourceType.vaam) {
      commentMessage = (
        <div className="comment-text">
          <span className="icon icon-video-message" />
          Video message
        </div>
      );
    } else {
      commentMessage = (
        <div className="comment-text">
          {commentMessage}
          <div className="comment-file-name">
            {resourceType ? <ResourceIcon type={resourceType} /> : null}
            {resourceName}
          </div>
        </div>
      );
    }
  }
  return commentMessage;
};

CommentText.propTypes = {
  resource: PropTypes.instanceOf(Object),
  text: PropTypes.string,
};

export default CommentText;
