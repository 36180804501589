import React from 'react';

const useInterval = (func, time) => {
  const timer = React.useRef();

  React.useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setInterval(func, time);
    return () => {
      clearInterval(timer.current);
    };
  }, [func, time]);
};

export default useInterval;
