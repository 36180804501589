import { Menu } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import AddButton from '../../components/AddButton';
import { Item } from '../../components/Material/ActionMenu';
import i18n from '../../i18n';

const AddResourceButton = (props) => {
  const {
    onNewFileButtonClick,
    onNewFolderButtonClick,
    tooltip,
    variant,
    onUploadFolderButtonClick,
    onClick,
  } = props;
  const [addAnchorEl, setAddAnchorEl] = useState();

  const handleOpen = useCallback(
    (event) => {
      setAddAnchorEl(event.currentTarget);
      if (onClick) onClick();
    },
    [onClick]
  );

  const handleOnClose = useCallback(() => {
    setAddAnchorEl(null);
  }, []);

  const handleAddNewFolderButton = useCallback(
    (event) => {
      onNewFolderButtonClick(event);
      setAddAnchorEl(null);
    },
    [onNewFolderButtonClick]
  );

  const handleAddNewFileButton = useCallback(
    (event) => {
      onNewFileButtonClick(event);
      setAddAnchorEl(null);
    },
    [onNewFileButtonClick]
  );

  const handleUploadFolder = useCallback(
    (event) => {
      onUploadFolderButtonClick(event);
      setAddAnchorEl(null);
    },
    [onUploadFolderButtonClick]
  );

  return (
    <div className="add-button-container">
      <AddButton
        label={i18n.t('Add')}
        onClick={handleOpen}
        className={props.className}
        disabled={props.disabled}
        variant={variant}
        tooltip={tooltip}
      />
      <Menu
        anchorEl={addAnchorEl}
        onClose={handleOnClose}
        PaperProps={{ style: { minWidth: 200 } }}
        open={!!addAnchorEl}
        keepMounted={false}
        classes={{ paper: 'select-menu' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {onNewFolderButtonClick && (
          <Item onClick={handleAddNewFolderButton}>
            <section className="displayFlex row-direction alignItemsCenter gap8">
              <span className="icon-folder-plus displayFlex justifyContentCenter width24" />
              <span>{i18n.t('Create folder')}</span>
            </section>
          </Item>
        )}
        {onNewFileButtonClick && (
          <Item onClick={handleAddNewFileButton}>
            <section className="displayFlex row-direction alignItemsCenter gap8">
              <span
                className="icon-upload-files displayFlex justifyContentCenter width24"
                style={{ fontSize: 20 }}
              />
              <span>{i18n.t('Add files')}</span>
            </section>
          </Item>
        )}
        {onUploadFolderButtonClick && (
          <Item onClick={handleUploadFolder}>
            <section className="displayFlex row-direction alignItemsCenter gap8">
              <span className="icon-upload-folder displayFlex justifyContentCenter width24" />
              <span>{i18n.t('Add folder')}</span>
            </section>
          </Item>
        )}
      </Menu>
    </div>
  );
};

AddResourceButton.propTypes = {
  tooltip: PropTypes.string,
  onNewFileButtonClick: PropTypes.func,
  onNewFolderButtonClick: PropTypes.func,
  onUploadFolderButtonClick: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
};

AddResourceButton.defaultProps = {
  disabled: false,
};

export default AddResourceButton;
