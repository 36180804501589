import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';

import { CacheKeys } from '../../../app/queryCache';
import { getMaterialResource, getResourceForViewer } from '../../../resources/ResourceServices';
import { IResource } from '../../../types/resources';
import { IComment } from '../../../types/comments';
import { CommentStatus, Provider } from '../../../app/appConstants';
import AddCommentHandler from '../AddCommentHandler';

type CommentContextType = {
  isLoading: boolean;
  error?: unknown;
  resource?: IResource;
  canEdit?: boolean;
  resourceId?: string | number;
  materialId?: number;
  participantId?: number;
  comment?: IComment;
  menuAnchorEl?: Element | null;
  setMenuAnchorEl?: (el: Element | null) => void;
  retryAddComment?: () => void;
};

export const CommentContext = createContext<CommentContextType>({
  isLoading: true,
  error: undefined,
  resource: undefined,
  canEdit: false,
  resourceId: undefined,
  materialId: undefined,
  participantId: undefined,
  comment: undefined,
  menuAnchorEl: null,
  setMenuAnchorEl: () => undefined,
});

type Props = {
  children: ReactNode;
  spaceId: string;
  comment: IComment;
  updateComment: (value: IComment) => void;
};

export const CommentContextProvider: FC<Props> = ({
  children,
  spaceId,
  comment: initComment,
  updateComment,
}) => {
  const { materialId, participantId } = initComment;
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const [comment, setComment] = useState<IComment>(initComment);
  const resourceId = initComment.resourceId;

  const {
    data,
    isLoading: isQueryLoading,
    isFetching,
    error,
  } = useQuery({
    queryKey: [CacheKeys.getResourceForViewer, resourceId, false, materialId, spaceId, false],
    queryFn: async () => {
      let resp;

      if (materialId) {
        resp = await getMaterialResource(spaceId, materialId, false);
      } else {
        resp = await getResourceForViewer(resourceId, false);
      }

      return resp;
    },
    retry: 1,
    retryDelay: () => 3000,
    enabled: !comment.resource,
  });

  const resource = comment.resource ?? data;

  // useEffect(() => {
  //   if (data) {
  //     setResource(data);
  //   }
  // }, [data]);
  const isLoading = isQueryLoading || isFetching;

  // useEffect(() => {
  //   if (!resource || isLoading) {
  //     return;
  //   }
  //   const { externalDocumentSettings } = resource;
  //   if (externalDocumentSettings?.provider === Provider.Vaam) {
  //     setCanEdit(false);
  //   }
  // }, [isLoading, resource]);
  const canEdit = resource?.externalDocumentSettings?.provider !== Provider.Vaam;

  // useEffect(() => {
  //   setIsLoading(isQueryLoading || isFetching);
  // }, [isFetching, isQueryLoading]);

  const retryAddComment = useCallback(() => {
    setComment((prev: IComment) => ({ ...prev, status: CommentStatus.SENDING }));
  }, []);

  const contextValue = useMemo(
    () => ({
      isLoading,
      error,
      resource,
      canEdit,
      resourceId,
      materialId,
      participantId,
      comment,
      menuAnchorEl,
      setMenuAnchorEl,
      retryAddComment,
    }),
    [
      canEdit,
      comment,
      error,
      isLoading,
      materialId,
      menuAnchorEl,
      participantId,
      resource,
      resourceId,
      retryAddComment,
    ]
  );

  const handleUpdateComment = (newComment: IComment) => {
    setComment((prev: IComment) => ({ ...prev, ...newComment }));
    updateComment(newComment);
  };

  const renderAddCommentHandler = () => {
    if (comment.status === CommentStatus.SENDING && !comment.id) {
      const payload = {
        text: comment.text,
        parentCommentId: comment.parentCommentId || null,
        attachFileUrl: '',
        resourceId: comment?.resourceId,
        resourceName: comment?.resourceName,
        materialId: comment?.materialId,
        refId: comment.refId,
      };
      return (
        <AddCommentHandler
          channelId={comment.channelId}
          comment={payload}
          setComment={handleUpdateComment}
        />
      );
    }
    return null;
  };

  return (
    <CommentContext.Provider value={contextValue}>
      {children}
      {renderAddCommentHandler()}
    </CommentContext.Provider>
  );
};

export default CommentContextProvider;

export const useCommentContext = () => useContext(CommentContext);
