import React from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import { getSpaceDirectoryByType } from '../resources/ResourceServices';
import { DirectoryType } from '../app/appConstants';

function useExtraDirectories(flatDirectories, spaceId, isPortal = false) {
  const [rootShareDirectory, setRootShareDirectory] = React.useState(null);

  const getSpaceExtraDirectoriesQuery = useQuery({
    queryKey: [CacheKeys.getSpaceExtraDirectory, spaceId],
    queryFn: async () => {
      const extraDir = await getSpaceDirectoryByType(spaceId, DirectoryType.Extra, isPortal);
      console.log('rootShareDirectory extraDir', extraDir);
      return extraDir;
    },
    ...{
      retry: 3,
      retryDelay: () => 5000,
      enabled: !!spaceId && !!flatDirectories && flatDirectories?.length === 0,
    },
  });

  React.useEffect(() => {
    if (flatDirectories?.length) {
      const clonedFolders = _cloneDeep(flatDirectories);
      const data = clonedFolders.find((d) => d.directoryType === DirectoryType.Extra);
      setRootShareDirectory(data);
    }
  }, [flatDirectories]);

  React.useEffect(() => {
    const data = getSpaceExtraDirectoriesQuery.data;
    if (data) {
      setRootShareDirectory(data);
    }
  }, [getSpaceExtraDirectoriesQuery.data]);

  return {
    rootShareDirectory,
    setRootShareDirectory,
    getSpaceExtraDirectoriesQuery,
  };
}

useExtraDirectories.propTypes = {
  spaceId: PropTypes.string,
};

export default useExtraDirectories;
