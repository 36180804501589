import React from 'react';
import { Delete } from '@mui/icons-material';

import PropTypes from 'prop-types';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import i18n from '../../i18n';
import OfficialButton from '../../components/OfficialButtons';

function DeletedAssessmentDialog(props) {
  const { spaceHostEmail, onClose } = props;

  return (
    <ResponsiveDialog
      showCloseIcon={false}
      modal
      open
      maxWidth="lg"
      className="white new-popup closed-space-dialog has-buttons"
    >
      <div className="content" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="icon">
          <Delete className="delete-icon" />
        </div>
        <div className="title">{i18n.t('THIS ASSESSMENT HAS BEEN DELETED')}</div>
        <div className="message">
          Please contact the host <a href={`mailto:${spaceHostEmail}`}>{spaceHostEmail}</a> if you
          need to access it.
        </div>
      </div>
      <div className="action-buttons">
        <OfficialButton
          onClick={onClose}
          label={i18n.t('go back to space')}
          variant="large-primary"
        />
      </div>
    </ResponsiveDialog>
  );
}

DeletedAssessmentDialog.propTypes = {
  spaceHostEmail: PropTypes.string,
  onClose: PropTypes.func,
};

export default DeletedAssessmentDialog;
