import React from 'react';
import { Box } from '@mui/material';
import './GroupByAssessmentsTable.scss';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import Table from '../components/Table';
import LinearProgressBar from '../components/LinearProgress';
import { getPercent } from '../common';
import { AssessmentSummary } from './types';
import NoDaTaBox from '../../components/NoDataBox';

type GroupByAssessmentsTableProps = {
  data?: AssessmentSummary[];
};

function GroupByAssessmentsTable(props: GroupByAssessmentsTableProps) {
  const { data } = props;
  const { openUsersAssessmentModal, visibleUsers } = useSpaceAnalyticsContext();

  const totalUsers = visibleUsers?.length;
  function handleOnClickLink(assessment: AssessmentSummary) {
    openUsersAssessmentModal(assessment);
  }

  const renderContent = () => {
    if (!data || data.length === 0) {
      return <NoDaTaBox icon={<i className="icon-assessments" />} title="No data to show" />;
    }

    return (
      <Table
        columns={[
          {
            title: 'Name',
            field: 'name',
            cellStyle: {
              width: '40%',
            },
            type: 'string',
            sorting: false,
            render: (rowData: AssessmentSummary) => {
              return <div>{rowData.name}</div>;
            },
          },

          {
            title: 'User taken',
            field: 'noOfUsersCompleted',
            cellStyle: {
              width: '40%',
              fontFamily: 'Roboto',
            },
            defaultSort: 'desc',
            customSort: (a: AssessmentSummary, b: AssessmentSummary) => {
              const aNum = a.noOfUsersCompleted || 0;
              const bNum = b.noOfUsersCompleted || 0;
              return aNum - bNum;
            },

            render: (rowData: AssessmentSummary) => {
              return (
                <Box display="flex" alignItems="center" className="page-read-progress full-width">
                  <LinearProgressBar
                    color="#4783FF"
                    backgroundColor="#DBE1E3"
                    value={getPercent(rowData?.noOfUsersCompleted, totalUsers)}
                  />
                  <span>{`${rowData?.noOfUsersCompleted}/${totalUsers}`}</span>
                </Box>
              );
            },
          },
          {
            title: 'Passed rate',
            field: 'usersPassedPercent',
            cellStyle: {
              width: '20%',
              fontFamily: 'Roboto',
            },
            align: 'left',
            type: 'numeric',
            sorting: false,
            render: (rowData: AssessmentSummary) => {
              const percent = Math.round(rowData.avgPercentageScore * 100) || 0;
              return (
                <span className="btn-link" onClick={() => handleOnClickLink(rowData)}>
                  {`${percent}%`}
                </span>
              );
            },
          },
        ]}
        data={data}
      />
    );
  };

  return (
    <Box className="assessments-table">
      <Box>{renderContent()}</Box>
    </Box>
  );
}

GroupByAssessmentsTable.propTypes = {};

export default GroupByAssessmentsTable;
