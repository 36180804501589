import _find from 'lodash/find';
import {
  OrganizationUserRole,
  TryForFreeOrganizationIdsDev,
  TryForFreeOrganizationIdsProd,
} from '../app/appConstants';
import { getTagLabel } from '../commons/Subscription/functions';
import { SubscriptionType } from './Subscription';
import { isOrgRole } from './utils';

export class CurrentOrganization {
  constructor() {
    this.data = null;
    console.log('Reading CurrentOrganization data from local storage...');
    const data = localStorage.getItem('CurrentOrganization');
    // console.log('CurrentOrganization', data);
    if (data) {
      const jsonData = JSON.parse(data);
      this.data = jsonData;
    } else {
      this.data = null;
    }
  }

  saveToStorage() {
    localStorage.setItem('CurrentOrganization', JSON.stringify(this.data));
  }

  setData(organization) {
    // this.data = organization;
    // console.log('setData organization', organization);
    this.data = {
      ...organization,
      subscriptionType: getTagLabel(organization?.subscriptionTypeId),
    };
    this.saveToStorage();
  }

  clearData() {
    this.data = null;
    localStorage.removeItem('CurrentOrganization');
  }

  getData() {
    return this.data;
  }

  isAdmin() {
    return isOrgRole(this.data?.role, OrganizationUserRole.Admin);
  }

  isPartner() {
    return isOrgRole(this.data?.role, OrganizationUserRole.Partner);
  }

  getUserInfoId() {
    return this.data?.userInfoId;
  }
  isEnterprise() {
    return this.data?.subscriptionTypeId === SubscriptionType.enterprise;
  }

  isPremium() {
    return this.data?.subscriptionTypeId === SubscriptionType.premium;
  }

  isFreemium() {
    return this.data?.subscriptionTypeId === SubscriptionType.free;
  }

  getSubscriptionType() {
    return this.data?.subscriptionTypeId;
  }

  getOrganizationId() {
    return this.data?.id;
  }

  getOrganizationName() {
    return this.data?.organizationName;
  }

  setOrganizationName(name) {
    this.data.organizationName = name;
    this.saveToStorage();
  }

  getAccountMetadata() {
    const orgData = this.getData();
    return {
      id: orgData?.id,
      AccountID: orgData?.id,
      OrgAccountName: orgData?.name,
      SubscriptionType: getTagLabel(orgData?.subscriptionTypeId),
      PayingCustomers: orgData && orgData.subscriptionTypeId > 100,
      OwnerOfAccount: orgData?.owner?.contactEmail,
    };
  }

  setSubscription(subscriptionTypeId) {
    this.data.subscriptionTypeId = subscriptionTypeId;
    this.data.subscriptionType = getTagLabel(subscriptionTypeId);
    this.saveToStorage();
  }

  hasConnection(serviceProvider) {
    const connections = this.data?.connections;
    const foundConnection = _find(connections, (item) => item.serviceProvider === serviceProvider);
    return !!foundConnection;
  }

  isBetaTesting() {
    return this.data?.isBetaTesting;
  }

  setIntegrationConnections(connections) {
    if (this.data) {
      this.data.connections = connections;
    }

    this.saveToStorage();
  }

  isGlobalResourcesEnabled() {
    return this.isEnterprise();
  }

  setOrganizationFeatures(organizationFeatures) {
    this.data = { ...this.data, organizationFeatures };
    this.saveToStorage();
  }

  setSubscriptionPackages(subscriptionPackages) {
    if (!subscriptionPackages) {
      this.subscriptionPackages = null;
      sessionStorage.setItem('SubscriptionPackages', null);
      return;
    }
    this.subscriptionPackages = subscriptionPackages;
    sessionStorage.setItem('SubscriptionPackages', JSON.stringify(subscriptionPackages));
  }

  getSubscriptionPackages() {
    if (!this.subscriptionPackages) {
      const data = sessionStorage.getItem('SubscriptionPackages');
      if (!data) {
        return this.data?.subscriptionPackages;
      }
      this.subscriptionPackages = JSON.parse(data);
    }
    return this.subscriptionPackages;
  }
  isTryForFreeOrganization() {
    if (process.env.REACT_APP_ENV === 'prod') {
      return TryForFreeOrganizationIdsProd.includes(this.data?.id);
    } else {
      return TryForFreeOrganizationIdsDev.includes(this.data?.id);
    }
  }

  getSubDomain() {
    return this.data?.subdomain;
  }
}

const currentOrganization = new CurrentOrganization();

export default currentOrganization;
