import React from 'react';
import { Button, Grid } from '@mui/material';
import ResponsiveDialog from '../components/ResponsiveDialog';
import './ConnectDialog.scss';
import { serviceProviderList, SERVICE_PROVIDER } from './integrationConstants';

type ConnectFailedDialogProps = {
  isOpen: boolean;
  showCloseIcon: boolean;
  onSubmit: () => void;
  onClose: () => void;
  submitButtonContent?: string;
  serviceProvider: (typeof SERVICE_PROVIDER)[keyof typeof SERVICE_PROVIDER];
  failureMessage: string | JSX.Element;
};

const ConnectFailedDialog = (props: ConnectFailedDialogProps) => {
  const {
    serviceProvider,
    isOpen,
    onSubmit,
    onClose,
    submitButtonContent,
    failureMessage,
    showCloseIcon,
  } = props;
  const serviceProviderData = serviceProviderList.find((x) => x.type === serviceProvider);
  function handleOnClickSubmit() {
    if (onSubmit) {
      onSubmit();
    }
  }

  if (!isOpen || !serviceProviderData) return null;

  const { name } = serviceProviderData;

  return (
    <ResponsiveDialog
      modal
      maxWidth="lg"
      className={`white confirm-dialog connect-dialog`}
      onClose={onClose}
      showCloseIcon={showCloseIcon}
    >
      <>
        <Grid className="content" container direction="column">
          <div className="logo">
            <span className="icon-info-blue" />
          </div>
          {typeof failureMessage === 'string' ? (
            <>
              <h1 className="title">Can't connect {name}</h1>
              <p>Your email is already connected to {name} in another SP_CE organization:</p>
              <p>
                <strong>{failureMessage}</strong>
              </p>
              <p>Disconnect {name} in the other organization to connect in this one.</p>
            </>
          ) : (
            failureMessage
          )}
        </Grid>
        <div className="buttons">
          <Button onClick={handleOnClickSubmit} variant="contained" className="button confirm">
            {submitButtonContent}
            {!submitButtonContent && 'OK'}
          </Button>
        </div>
      </>
    </ResponsiveDialog>
  );
};

export default ConnectFailedDialog;
