import Api from '../commons/RuntimeApi';
import { getResourceRuntimeApi } from '../commons/utils';

export function addVaam(spaceId, data) {
  return Api.post(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-materials/vaams`,
    data
  );
}

export function updateVaam(spaceId, resourceId, data) {
  return Api.put(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-materials/vaams/${resourceId}`,
    data
  );
}

export function deleteVaam(spaceId, resourceId) {
  return Api.delete(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-materials/vaams/${resourceId}`
  );
}

export function finalizeVaam(spaceId, resourceId, channelId, data) {
  let url = `/api/runtime/spaces/${spaceId}/runtime-materials/vaams/${resourceId}/finalize`;
  if (channelId) {
    url += `?channelId=${channelId}`;
  }
  return Api.post(`${getResourceRuntimeApi()}${url}`, data);
}
