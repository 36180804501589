import React, { createContext, useCallback, useState, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import _isString from 'lodash/isString';
import { useMaterialsContext } from '../../spaces/Materials/MaterialsContextProvider';
import { getFilteredDirectory } from '../../commons/ResourceUtils';

const MultipleSelectResourcesContext = createContext({});

MultipleSelectResourcesContext.displayName = 'MultipleSelectResourcesContext';

export const useMultipleSelectResourcesContext = () => useContext(MultipleSelectResourcesContext);

const getMultipleSelectResourcesContext = memoizeOne(
  (
    selectedFileIds,
    selectedFolderIds,
    selectedSmartFolders,
    selectedSmartFiles,
    canRemoveResources,
    canMoveResources,
    currentDirectoryId,
    canShowDownloadButton
  ) => {
    const isSelectedResourceIds =
      selectedFileIds?.length > 0 ||
      selectedFolderIds?.length > 0 ||
      selectedSmartFolders?.length > 0 ||
      selectedSmartFiles?.length > 0;
    return {
      selectedFileIds,
      selectedFolderIds,
      selectedSmartFolders,
      selectedSmartFiles,
      isSelectedResourceIds,
      canRemoveResources,
      canMoveResources,
      currentDirectoryId,
      canShowDownloadButton,
    };
  }
);

const MultipleSelectResourcesContextProvider = (props) => {
  const [selectedSmartFolders, setSelectedSmartFolders] = useState([]);
  const [selectedSmartFiles, setSelectedSmartFiles] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [canRemoveResources, setCanRemoveResources] = useState(true);
  const [canShowDownloadButton, setCanShowDownloadButton] = useState(false);
  const [canMoveResources, setCanMoveResources] = useState(false);
  const [currentDirectoryId, setCurrentDirectoryId] = useState(null);

  const { currentUser } = useMaterialsContext();

  // const selectedFileIds = selectedFiles?.map((item) => item.id) || [];
  const selectedFileIds = useMemo(() => {
    return selectedFiles?.map((item) => item.id) || [];
  }, [selectedFiles]);

  // const selectedFolderIds = selectedFolders?.map((item) => item.id) || [];
  const selectedFolderIds = useMemo(() => {
    return selectedFolders?.map((item) => item.id) || [];
  }, [selectedFolders]);

  const selectedSmartFoldersData = useMemo(() => {
    return (
      selectedSmartFolders?.map((item) => ({
        directoryId: item.id,
        spaceFolderId: item.spaceFolderId,
      })) || []
    );
  }, [selectedSmartFolders]);

  const selectedSmartFilesData = useMemo(() => {
    return (
      selectedSmartFiles?.map((item) => ({
        id: item.externalId,
        url: item.src || item.orgSrc,
        name: item.resourceName || item.displayName || item.name,
      })) || []
    );
  }, [selectedSmartFiles]);

  useEffect(() => {
    const foundFolder = selectedFolders?.find(
      (folder) => !currentUser.isHost && folder.createdBy !== currentUser.userInfoId
    );

    const foundFile = selectedFiles?.find(
      (file) =>
        file.isPresentationFile ||
        (!currentUser.isHost && file.createdByUserInfoId !== currentUser.userInfoId)
    );
    const foundDownloadAbleFile = selectedFiles?.find((file) => file.isDownloadable);

    const FoundSubFolderCreatedByOther = selectedFolders?.find((folder) =>
      folder?.subFolders?.find(
        (subFolder) =>
          !currentUser.isHost && subFolder.createdByUserInfoId !== currentUser.userInfoId
      )
    );

    const FoundFilesInSubFolderCreatedByOther = selectedFolders?.find((folder) =>
      folder?.spaceResources?.find(
        (file) => !currentUser.isHost && file.createdByUserInfoId !== currentUser.userInfoId
      )
    );

    if (
      foundDownloadAbleFile ||
      selectedFolders.length > 0 ||
      selectedSmartFolders.length > 0 ||
      selectedSmartFiles.length > 0
    ) {
      setCanShowDownloadButton(true);
    } else {
      setCanShowDownloadButton(false);
    }

    if (
      foundFolder ||
      foundFile ||
      FoundSubFolderCreatedByOther ||
      FoundFilesInSubFolderCreatedByOther ||
      selectedSmartFolders.length > 0 ||
      selectedSmartFiles.length > 0
    ) {
      setCanRemoveResources(false);
      setCanMoveResources(false);
    } else {
      setCanRemoveResources(true);
      // Note: Participants aren't allowed to move resources (access level related) yet
      if (currentUser.isHost) {
        setCanMoveResources(true);
      }
    }
  }, [currentUser, selectedFolders, selectedFiles, selectedSmartFolders, selectedSmartFiles]);

  useEffect(() => {
    if (selectedFolders.length > 0) {
      setCurrentDirectoryId(selectedFolders[0].parentFolderId);
    } else if (selectedFiles.length > 0) {
      setCurrentDirectoryId(selectedFiles[0].directoryId);
    } else {
      setCurrentDirectoryId(null);
    }
  }, [selectedFiles, selectedFolders]);

  const handleOnChangeCheckBoxFile = useCallback((value, material) => {
    if (!material) return;
    const checked = value;
    const { id, externalId, resourceId } = material;
    const isSmartFile = (externalId && _isString(externalId) && !id) || false; // if it is a file in a sub smart folder, e.g. externalId = 'OneDrive:XXXXX'
    if (isSmartFile) {
      setSelectedSmartFiles((prev) => {
        const newSelectedSmartFiles = [...prev];
        const foundIndex = newSelectedSmartFiles.findIndex(
          (item) => item.externalId === externalId
        );
        if (checked) {
          if (foundIndex === -1) {
            newSelectedSmartFiles.push(material);
          } else {
            newSelectedSmartFiles.splice(foundIndex, 1);
          }
        } else {
          if (foundIndex > -1) {
            newSelectedSmartFiles.splice(foundIndex, 1);
          }
        }
        return newSelectedSmartFiles;
      });
      return;
    }

    setSelectedFiles((prev) => {
      const newSelectedFiles = [...prev];
      const foundIndex = newSelectedFiles.findIndex((item) => {
        if (id) {
          return item.id === id;
        }
        return item.resourceId === resourceId;
      });

      if (checked) {
        if (foundIndex === -1) {
          newSelectedFiles.push(material);
        } else {
          newSelectedFiles.splice(foundIndex, 1);
        }
      } else {
        if (foundIndex > -1) {
          newSelectedFiles.splice(foundIndex, 1);
        }
      }
      return newSelectedFiles;
    });
  }, []);

  const handleOnChangeCheckBoxFolder = useCallback((value, directory) => {
    if (!directory) return;

    const checked = value;
    const { id, isExternal, spaceFolderId } = directory;
    const isSmartFolder = isExternal && _isString(id) && spaceFolderId > 0; // if it is a sub smart folder, e.g. id = 'OneDrive:XXXXX'

    if (isSmartFolder) {
      setSelectedSmartFolders((prev) => {
        const newSelectedSmartFolders = [...prev];
        const foundIndex = newSelectedSmartFolders.findIndex((item) => item.id === id);
        if (!checked) {
          if (foundIndex > -1) {
            newSelectedSmartFolders.splice(foundIndex, 1);
          }
        } else {
          if (foundIndex === -1) {
            newSelectedSmartFolders.push(directory);
          }
        }
        return newSelectedSmartFolders;
      });
      return;
    }
    setSelectedFolders((prev) => {
      const newSelectedFolders = [...prev];
      const foundIndex = newSelectedFolders.findIndex((item) => id === item.id);

      if (!checked) {
        if (foundIndex > -1) {
          newSelectedFolders.splice(foundIndex, 1);
        }
      } else {
        if (foundIndex === -1) {
          newSelectedFolders.push(directory);
        }
      }
      return newSelectedFolders;
    });
  }, []);

  const handleOnSelectAll = useCallback((folders, materials) => {
    if (folders) {
      const { newSelectedFolders, newSelectedSmartFolders } = folders.reduce(
        (acc, item) => {
          const { id, isExternal, spaceFolderId } = item;
          const isSmartFolder = isExternal && _isString(id) && spaceFolderId > 0; // if it is a sub smart folder, e.g. id = 'OneDrive:XXXXX'

          if (isSmartFolder) {
            acc.newSelectedSmartFolders.push(item);
          } else {
            acc.newSelectedFolders.push(item);
          }

          return acc;
        },
        { newSelectedFolders: [], newSelectedSmartFolders: [] }
      );
      setSelectedFolders(newSelectedFolders);
      setSelectedSmartFolders(newSelectedSmartFolders);
    }
    if (materials) {
      const { newSelectedFiles, newSelectedSmartFiles } = materials.reduce(
        (acc, item) => {
          const { id, externalId } = item;
          const isSmartFile = (externalId && _isString(externalId) && !id) || false; // if it is a file in a sub smart folder, e.g. externalId = 'OneDrive:XXXXX'

          if (isSmartFile) {
            acc.newSelectedSmartFiles.push(item);
          } else {
            acc.newSelectedFiles.push(item);
          }

          return acc;
        },
        { newSelectedFiles: [], newSelectedSmartFiles: [] }
      );
      setSelectedFiles(newSelectedFiles);
      setSelectedSmartFiles(newSelectedSmartFiles);
    }
  }, []);

  const resetSelected = useCallback(() => {
    setSelectedSmartFiles([]);
    setSelectedSmartFolders([]);
    setSelectedFolders([]);
    setSelectedFiles([]);
    setCurrentDirectoryId(null);
  }, []);

  const selectMonoResource = useCallback((data) => {
    // click Move in the context menu
    if (data.isFolder) {
      const { id, isExternal, spaceFolderId } = data;
      const isSmartFolder = isExternal && _isString(id) && spaceFolderId > 0; // if it is a sub smart folder, e.g. id = 'OneDrive:XXXXX'
      if (!isSmartFolder) setSelectedFolders([data]);
      setSelectedFiles([]);
    } else {
      const { id, externalId } = data;
      const isSmartFile = (externalId && _isString(externalId) && !id) || false;
      if (!isSmartFile) setSelectedFiles([data]);
      setSelectedFolders([]);
    }
  }, []);

  const context = getMultipleSelectResourcesContext(
    selectedFileIds,
    selectedFolderIds,
    selectedSmartFoldersData,
    selectedSmartFilesData,
    canRemoveResources,
    canMoveResources,
    currentDirectoryId,
    canShowDownloadButton
  );

  context.handleOnChangeCheckBoxFile = handleOnChangeCheckBoxFile;
  context.handleOnChangeCheckBoxFolder = handleOnChangeCheckBoxFolder;
  context.handleOnSelectAll = handleOnSelectAll;
  context.resetSelected = resetSelected;
  context.setCanRemoveResources = setCanRemoveResources;
  context.selectMonoResource = selectMonoResource;
  context.isCheckedAll = (selectedFolder, rootFolder, directories) => {
    let subFoldersCount = 0;
    let materialsCount = 0;
    if (!selectedFolder) {
      if (rootFolder) {
        materialsCount = rootFolder.spaceResources?.length || 0;
        const mainFolders = getFilteredDirectory(directories);
        subFoldersCount = mainFolders.length;
      }
    } else {
      subFoldersCount = selectedFolder.subFolders?.length || 0;
      materialsCount = selectedFolder.spaceResources?.length || 0;
    }

    const checkedCount =
      (selectedFileIds?.length || 0) +
      (selectedFolderIds?.length || 0) +
      (selectedSmartFoldersData?.length || 0) +
      (selectedSmartFilesData?.length || 0);
    const itemsCount = materialsCount + subFoldersCount;
    return checkedCount === itemsCount;
  };

  return (
    <MultipleSelectResourcesContext.Provider value={context}>
      {props.children}
    </MultipleSelectResourcesContext.Provider>
  );
};

MultipleSelectResourcesContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
};

export default MultipleSelectResourcesContextProvider;
