import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { removeURLParams } from '../commons/utils';
import ConfirmService from '../components/ConfirmService';
import i18n from '../i18n';
import { useSpaceContext } from './SpaceContext';

const ResourceDeletedHandler = (props) => {
  const { selectedMaterial, setSelectedMaterial, materialId, sharingFolderId } = props;
  const { resourceHubConnection } = useSpaceContext();

  const handleOnMaterialDeleted = useCallback(
    (msg) => {
      // console.log('### NHT onMaterialDeleted', materialId, selectedMaterial, msg);
      const deletedMaterialId = msg?.materialId;
      if (
        deletedMaterialId > 0 &&
        selectedMaterial &&
        (deletedMaterialId === selectedMaterial.id || deletedMaterialId === materialId)
      ) {
        setSelectedMaterial(null);
        if (materialId) {
          removeURLParams(['srId']);
        }
        ConfirmService.show(
          i18n.t('RESOURCE DELETED'),
          <Box display="flex" flexDirection="column">
            <span>{i18n.t('This resource has been deleted.')}</span>
            <span>{i18n.t('Please contact the host if you need to access it.')}</span>
          </Box>,
          i18n.t('Ok'),
          null,
          true
        );
      }
    },
    [materialId, selectedMaterial, setSelectedMaterial]
  );

  const handleOnDirectoryRemoved = React.useCallback(
    (msg) => {
      // console.log('### handleOnDirectoryRemoved', sharingFolderId, msg, selectedMaterial);
      const deletedFolderId = msg?.directoryId;
      if (deletedFolderId > 0 && deletedFolderId === sharingFolderId) {
        removeURLParams(['drId']);
        if (selectedMaterial?.directoryId === deletedFolderId) {
          setSelectedMaterial(null);
        }
      }
    },
    [selectedMaterial?.directoryId, setSelectedMaterial, sharingFolderId]
  );

  useEffect(() => {
    // console.log('### NHT resourceHubConnection', resourceHubConnection);
    if (resourceHubConnection) {
      resourceHubConnection.on('MaterialRemoved', handleOnMaterialDeleted);
      resourceHubConnection.on('DirectoryRemoved', handleOnDirectoryRemoved);
    }
    return () => {
      if (resourceHubConnection) {
        resourceHubConnection.off('MaterialRemoved', handleOnMaterialDeleted);
        resourceHubConnection.off('DirectoryRemoved', handleOnDirectoryRemoved);
      }
    };
  }, [handleOnDirectoryRemoved, handleOnMaterialDeleted, resourceHubConnection]);
  return null;
};

ResourceDeletedHandler.propTypes = {
  selectedMaterial: PropTypes.instanceOf(Object),
  setSelectedMaterial: PropTypes.func,
  materialId: PropTypes.string,
  sharingFolderId: PropTypes.string,
};

export default ResourceDeletedHandler;
