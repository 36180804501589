import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import './MovingTable.scss';
import { getExtensionFromUrl } from '../../../commons/ResourceUtils';
import { formatDateTime } from '../../../commons/DateTimeUtils';
import LightTooltip from '../../LightTooltip';
import ResourceIcon from '../ResourceIcon';
import { ResourceType } from '../../../app/appConstants';
import i18n from '../../../i18n';
import EnhancedTableHeader from '../../EnhancedTable/EnhancedTableHeader';
import { getComparator, stableSort } from '../function';
import { MenuEnhancedProps } from '../../SelectEnhanced';

const headCells = [
  { id: 'name', disablePadding: false, label: 'Name', width: 'auto' },
  { id: 'noItems', disablePadding: false, label: 'Items', width: '120px' },
  { id: 'lastModified', disablePadding: false, label: 'Last modified', width: '170px' },
];

// this table sort and search on client side
const MovingTable = (props) => {
  const { data, variant } = props;

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('lastModified');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const rowCount = data.length;
  // console.log('### MovingTable', folder, items);

  const displayItems = React.useMemo(() => {
    const sortedItems = stableSort(data, getComparator(order, orderBy));
    const results = sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return results;
  }, [data, order, orderBy, page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    // console.log('### handleRequestSort', order, orderBy, property);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    // console.log('### changed page', newPage);
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log('### changed page size', event.target);
    const newRowsPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const renderPagination = () => {
    // TODO: refactor, to use EnhancedTablePagination instead
    if (rowCount <= 10) {
      return null;
    }

    const info = `${rowCount} results`;

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" className="pagination">
        <Pagination
          page={page + 1}
          count={Math.ceil(rowCount / rowsPerPage)}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
        <Box display="flex" alignItems="center" gap={3}>
          <span className="info">{info}</span>
          <Select
            MenuProps={MenuEnhancedProps}
            variant="outlined"
            name="rowsPerPage"
            className="select-rows-per-page"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          >
            {[10, 25, 50].map((op, idx) => (
              <MenuItem key={idx} value={op}>
                {op}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    );
  };

  const renderContent = () => {
    // console.log('### MovingTable renderContent', items, order, orderBy);
    if (displayItems.length === 0) {
      return (
        <section className="no-data-section">
          <span>{i18n.t('No materials available')}</span>
        </section>
      );
    }
    return (
      <>
        <TableContainer
          className={`table-container styled-scrollbar ${rowCount < 10 ? 'marginBottom16' : ''}`}
        >
          <Table className="table" size="medium" aria-label="sticky table" stickyHeader>
            <EnhancedTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowCount}
              headCells={headCells}
            />
            <TableBody>
              {displayItems.map((row, index) => {
                const isNotFolder = row.spaceResources === undefined;
                let tooltipTitle = '';
                let iconClassName = '';
                let itemType = null;
                if (isNotFolder) {
                  tooltipTitle = 'Cannot select this resource because it is not a folder';
                  itemType =
                    row.isExternal && row.type === ResourceType.video
                      ? ResourceType.podcast
                      : row.type;
                } else {
                  tooltipTitle = row.isExternal
                    ? 'Cannot move the resource into external folder'
                    : 'Cannot move the resource into itself';
                  iconClassName = 'icon-folder';
                  if (props.isHost) {
                    iconClassName = `${iconClassName} ${variant}-bg`;
                  }
                }
                return (
                  <LightTooltip
                    key={index}
                    title={tooltipTitle}
                    disableHoverListener={!row.isDisabled}
                    placement="bottom-start"
                    classes={{ tooltip: 'moving-table-row-tooltip' }}
                  >
                    <TableRow
                      hover
                      className={`row ${row.isDisabled ? 'disabled' : ''}`}
                      // disabled={row.isDisabled}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (!row.isDisabled && props.handleFolderClick) {
                          props.handleFolderClick(row);
                        }
                      }}
                    >
                      <TableCell>
                        <section className="name-section">
                          <section className="left">
                            {!isNotFolder && iconClassName && (
                              <span className={iconClassName}></span>
                            )}
                            {isNotFolder && (
                              <ResourceIcon
                                name={row.name}
                                type={itemType}
                                extension={getExtensionFromUrl(row.src, row.extension)}
                              />
                            )}
                          </section>
                          <section className="right">
                            <div className="name">{row.name}</div>
                          </section>
                        </section>
                      </TableCell>
                      <TableCell>{row.noItems}</TableCell>
                      <TableCell>
                        {formatDateTime(row.lastModified || row.created, 'DD MMM, YYYY')}
                      </TableCell>
                    </TableRow>
                  </LightTooltip>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {renderPagination()}
      </>
    );
  };

  return <div className="materials-moving-table">{renderContent()}</div>;
};

MovingTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  handleFolderClick: PropTypes.func,
  // parentFolderId: PropTypes.number, // folder contains moving items.
  isHost: PropTypes.bool,
  variant: PropTypes.string,
  // selectedFolderIds: PropTypes.arrayOf(PropTypes.number),
};

MovingTable.defaultProps = {
  isHost: false,
  variant: 'white',
};

export default MovingTable;
