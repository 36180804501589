/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import { Badge } from '@mui/material';
import './Badge.scss';

const BadgeCustom = (props) => {
  return <Badge {...props} className="badge-custom" />;
};

export default BadgeCustom;
