import React from 'react';
import moment from 'moment';
import { ISpaceUser } from '../../types/User';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import Table from '../components/Table';
import Chart, { ChartDefinition } from '../components/Chart';
import useParticipantTrafficDetail from '../queries/useParticipantTrafficDetailQuery';
import Loading from '../../components/Loading';
import { ParticipantIndividualTrafficSummary } from '../types/participantIndividualTraffic';
import { getStringTime } from '../common';
import { PrivacySpace } from '../../app/appConstants';
import { formatDateTime } from '../../commons/DateTimeUtils';

type ParticipantTrafficByUserProps = {
  userInfo: ISpaceUser;
};

function ParticipantTrafficByUser(props: ParticipantTrafficByUserProps) {
  const { userInfo } = props;
  const { space, reportEndDate, isPublic } = useSpaceAnalyticsContext();
  const spaceId = space?.id;
  const startDate = moment(space?.created).toDate().toISOString();
  const endDate = moment(reportEndDate).toDate().toISOString();

  const { participantTrafficDetail, isFetching: isFetchingSummary } = useParticipantTrafficDetail(
    spaceId,
    startDate,
    endDate,
    userInfo.id,
    isPublic
  );

  const participantTrafficInfo = participantTrafficDetail?.trafficDetails;

  const defineAxis = React.useCallback(() => {
    if (!participantTrafficDetail?.data) {
      return { left: undefined, right: undefined };
    }
    const flatData = participantTrafficDetail?.data?.map(
      (x: ParticipantIndividualTrafficSummary) => x.data
    );
    const maxDuration = Math.max(
      ...flatData.map((x: ParticipantIndividualTrafficSummary) => x.timeSpentInSeconds)
    );
    const maxCount = Math.max(
      ...flatData.map((x: ParticipantIndividualTrafficSummary) => Math.max(x.visits))
    );
    return {
      left: {
        maxValue: maxDuration,
        isDuration: true,
      },
      right: {
        maxValue: maxCount,
        isDuration: false,
      },
    };
  }, [participantTrafficDetail?.data]);

  const chartData = React.useMemo(
    () => participantTrafficDetail?.data || [],
    [participantTrafficDetail?.data]
  );

  const chartDefinitions = React.useMemo<ChartDefinition[]>(
    () => [
      {
        type: 'bar',
        label: 'Time spent',
        data: chartData?.map((x: ParticipantIndividualTrafficSummary) => x.data.timeSpentInSeconds),
        color: '#EED2F9',
        axisBinding: 'left',
        isDuration: true,
        order: 2,
      },
      {
        type: 'line',
        label: 'Visits',
        data: chartData?.map((x: ParticipantIndividualTrafficSummary) => x.data.visits),
        color: '#AD2DE1',
        axisBinding: 'right',
        order: 1,
      },
    ],
    [chartData]
  );

  const toolTipLabels = React.useMemo<string[]>(
    () =>
      participantTrafficDetail?.data?.map((x: ParticipantIndividualTrafficSummary) => x.label) ||
      [],
    [participantTrafficDetail?.data]
  );

  const renderChart = () => {
    const axis = defineAxis();

    return (
      <Chart
        chartDefinitions={chartDefinitions}
        leftAxesDefinition={axis.left}
        rightAxesDefinition={axis.right}
        tooltipLabels={toolTipLabels}
        chartLabels={participantTrafficDetail?.labels || []}
      />
    );
  };

  function renderTable() {
    return (
      <Table
        columns={[
          {
            title: 'Date visit',
            field: 'visitTime',
            cellStyle: {
              width: '50%',
            },
            defaultSort: 'desc',
            render: (rowData: ParticipantIndividualTrafficSummary) => {
              return formatDateTime(rowData.visitTime, 'MMM DD, yyyy hh:mm A');
            },
          },
          {
            title: 'Time spent',
            field: 'durationInSecond',
            cellStyle: {
              width: '30%',
              minWidth: '150px',
              textAlign: 'right',
            },
            headerStyle: {
              paddingLeft: '25px !important',
              backgroundColor: '#fff',
            },
            render: (rowData: ParticipantIndividualTrafficSummary) => {
              return <div style={{ maxWidth: 90 }}>{getStringTime(rowData.durationInSecond)}</div>;
            },
          },
        ]}
        data={participantTrafficInfo}
      />
    );
  }

  function renderContent() {
    if (isFetchingSummary) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }
    return (
      <>
        <p className="short-description">
          {space?.spacePrivacy === PrivacySpace.PUBLIC ? 'The user' : userInfo.fullName} has visited
          the space <b>{participantTrafficInfo.length}</b>{' '}
          {participantTrafficInfo.length <= 1 ? 'time' : ' times'}.
        </p>
        {renderChart()}
        {renderTable()}
      </>
    );
  }

  return <div className="participant-traffic-by-user-container">{renderContent()}</div>;
}

export default ParticipantTrafficByUser;
