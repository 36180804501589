import React from 'react';
import PropTypes from 'prop-types';
import './LoggedOut.scss';
import Logo from '../../images/spce-logo-white.png';
import OfficialButton from '../OfficialButtons';

const LoggedOut = (props) => {
  const [processing, setProcessing] = React.useState(false);

  async function onClick() {
    setProcessing(true);
    const rs = await props.onLogin();
    if (!rs) {
      setProcessing(false);
    }
  }

  return (
    <div className="logged-out-page">
      <div className="text-center">
        <div className="spce-logout-logo">
          <img src={Logo} className="icon-banner" alt="logo" />
        </div>
        <div className="spce-logout-txt">Oops, looks like you’ve been logged out.</div>

        <OfficialButton
          className="spce-button"
          variant="regular-green"
          disabled={processing}
          onClick={onClick}
          label="Log In"
        />
      </div>
    </div>
  );
};

LoggedOut.propTypes = {
  onLogin: PropTypes.func,
};

export default LoggedOut;
