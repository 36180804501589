import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import Loading from '../Loading';
import './style.scss';
import { ResourceType } from '../../app/appConstants';
import { getTheFirstPageInTiffImage, isTiffFile } from '../../commons/TiffUtils';
import { getPathNameFromUrl } from '../../commons/utils';
import ResourceIcon from '../Material/ResourceIcon';

function renderThumbnailUnavailable(resourceType, extension) {
  return (
    <section className="resource-thumbnail">
      <ResourceIcon type={resourceType} extension={extension} className="item-icon" />
    </section>
  );
}

const ThumbnailImg = (props) => {
  const { src, token, resourceType, extension, resourceId } = props;
  const pathName = getPathNameFromUrl(src);

  const getThumbnailQuery = useQuery({
    queryKey: ['thumbnail', pathName, resourceId],
    queryFn: async () => {
      if (resourceType === ResourceType.video || !src) return src;
      const headers = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const res = await fetch(src, {
        headers,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        redirect: 'follow',
      });

      if (!res?.ok) {
        throw Error('Could not fetch image');
      }

      // console.log('### 3011: ', thumbnail, extension, headers, res);

      let result = src;
      if (isTiffFile(src, extension)) {
        const arrB = await res.arrayBuffer();
        // console.log('### 3011b: ', arrB);
        const canvas = getTheFirstPageInTiffImage(arrB);
        if (canvas) {
          result = canvas.toDataURL('image/png');
        }
      } else {
        const blob = await res.blob();
        const imgBitmap = await createImageBitmap(blob);
        const canvas = document.createElement('canvas');
        const scale = 200 / imgBitmap.height;
        canvas.width = imgBitmap.width * scale;
        canvas.height = imgBitmap.height * scale;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(imgBitmap, 0, 0, canvas.width, canvas.height);
        result = canvas.toDataURL('image/png');
      }
      return result;
    },
    retry: 1,
    retryDelay: 2000,
    retryOnMount: false,
  });

  if (getThumbnailQuery.isFetching) {
    return (
      <div className="resource-thumbnail">
        <Loading className="loading" />
      </div>
    );
  }

  if (!src || getThumbnailQuery.isError || !getThumbnailQuery.data) {
    return renderThumbnailUnavailable(resourceType, extension);
  }

  return (
    <div className="resource-thumbnail">
      <img className="image-viewer" alt="thumbnail" src={getThumbnailQuery.data} />
    </div>
  );
};

ThumbnailImg.propTypes = {
  src: PropTypes.string,
  token: PropTypes.string,
  resourceType: PropTypes.number,
  extension: PropTypes.string,
  resourceId: PropTypes.string,
};

export default ThumbnailImg;
