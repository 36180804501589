import { useQuery } from '@tanstack/react-query';
import { fetchAssessment } from '../services';
import { CacheKeys } from '../../app/queryCache';

function useAssessmentQuery(id) {
  const userAssessments = useQuery({
    queryKey: [CacheKeys.fetchAssessment, id],
    queryFn: async () => {
      const resp = await fetchAssessment(id);
      return resp;
    },
    enabled: !!id,
  });

  return userAssessments;
}

export default useAssessmentQuery;
