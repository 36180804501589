import { Dispatch, SetStateAction } from 'react';
import { GLOBAL_PROVIDER_TYPE } from './utils';
import usePersistedBreadcrumbs from './usePersistedBreadcrumbs';
import { SelectedFolderProps } from './context/Interfaces';
import useTransientBreadcrumbs from './useTransientBreadcrumbs';
import { BreadcrumbsData } from './context/BreadcrumbsContextProvider';

const useBreadcrumbs = (
  provider: (typeof GLOBAL_PROVIDER_TYPE)[keyof typeof GLOBAL_PROVIDER_TYPE],
  useStateOnly: boolean
) => {
  const transientBreadcrumbs = useTransientBreadcrumbs();
  const persistedBreadcrumbs = usePersistedBreadcrumbs(provider, !useStateOnly);
  let breadcrumbs: SelectedFolderProps[] | null;
  let selectedFolder: SelectedFolderProps | null;
  let setBreadcrumbsData: Dispatch<SetStateAction<BreadcrumbsData>>;
  let clearBreadcrumbsData: () => void;
  // let updateBreadcrumbs: (
  //   newData: SelectedFolderProps[],
  //   newCurrentFolder: SelectedFolderProps | null
  // ) => void;

  if (useStateOnly) {
    breadcrumbs = transientBreadcrumbs.breadcrumbs;
    selectedFolder = transientBreadcrumbs.selectedFolder;
    setBreadcrumbsData = transientBreadcrumbs.setBreadcrumbsData;
    clearBreadcrumbsData = transientBreadcrumbs.clearBreadcrumbsData;
  } else {
    breadcrumbs = persistedBreadcrumbs.breadcrumbs;
    selectedFolder = persistedBreadcrumbs.selectedFolder;
    setBreadcrumbsData = persistedBreadcrumbs.setBreadcrumbsData;
    clearBreadcrumbsData = persistedBreadcrumbs.clearBreadcrumbsData;
  }
  return { breadcrumbs, selectedFolder, setBreadcrumbsData, clearBreadcrumbsData };
};

export default useBreadcrumbs;
