import { useMemo } from 'react';
import memoizeOne from 'memoize-one';
import { useSpaceContext } from '../../spaces/SpaceContext';

const checkHasChanges = (item, newFolders, newResources) => {
  let hasChanges = false;
  let isNewFolder = false;
  if (newFolders) {
    isNewFolder = newFolders.includes(item.id);
    if (isNewFolder) {
      hasChanges = true;
    }
  }
  if (newResources && !hasChanges) {
    hasChanges = !!item.spaceResources.find((i) => newResources.includes(i.id));
  }
  if (!hasChanges) {
    hasChanges = !!item.subFolders?.find((e) => e.hasChanges === true);
  }
  return { hasChanges, isNewFolder };
};

const mergeFlatDirectoriesWithNotifications = memoizeOne(
  (flatDirectories, newFolders, newResources) => {
    // console.log('mergeFlatDirectoriesWithNotifications');
    if (!flatDirectories) return;
    flatDirectories
      .slice()
      .reverse()
      .map((item) => {
        const { hasChanges, isNewFolder } = checkHasChanges(item, newFolders, newResources);
        Object.assign(item, { hasChanges, isNewFolder });
        return item;
      });
  }
);

const useResourceNotifications = (flatDirectories) => {
  const { newNotifications } = useSpaceContext();

  const newFolders = useMemo(
    () => newNotifications?.Resources?.details?.newFolders || [],
    [newNotifications?.Resources?.details?.newFolders]
  );
  const newResources = useMemo(
    () => newNotifications?.Resources?.details?.newMaterials || [],
    [newNotifications?.Resources?.details?.newMaterials]
  );

  return {
    mergeFlatDirectoriesWithNotifications: () =>
      mergeFlatDirectoriesWithNotifications(flatDirectories, newFolders, newResources),
  };
};

export default useResourceNotifications;
