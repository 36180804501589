import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { createSpaceAccessibility } from '../spaceAccessServices';

function useSpaceAccessibilityQuery(spaceId, shouldCreateHiddenUser) {
  const spaceAccessibilityQuery = useQuery({
    queryKey: [CacheKeys.getSpaceAccessibility, spaceId, shouldCreateHiddenUser],
    queryFn: async () => {
      const data = await createSpaceAccessibility(spaceId, shouldCreateHiddenUser);
      return data;
    },
    config: {
      enabled: !!spaceId,
    },
  });

  return spaceAccessibilityQuery;
}

useSpaceAccessibilityQuery.propTypes = {};

export default useSpaceAccessibilityQuery;
