import React, { forwardRef } from 'react';
import { SnackbarContent } from 'notistack';
import { snackActions } from '../NotiSnackbarUtils';
import i18n from '../../i18n';
import OfficialButtons from '../../components/OfficialButtons';
import './NotifyNotEnabledFeature.scss';

const NotifyNotEnabledFeature = forwardRef((props, ref) => {
  function handleReloadPage() {
    window.location.reload();
  }

  return (
    <SnackbarContent>
      <div className="reload-snackbar" ref={ref}>
        <span className="icon-error"></span>
        <div className="reload-snackbar-content">
          <div className="title-message">{i18n.t('Something went wrong')}</div>
          <div>{i18n.t('Please refresh your browser and give it another try.')}</div>
          <OfficialButtons
            variant="rectangle-green"
            onClick={handleReloadPage}
            label={i18n.t('Reload')}
          />
        </div>
      </div>
    </SnackbarContent>
  );
});

export default {
  show: (error) => {
    snackActions.confirmNotify(error, {
      content: (key, message) => {
        return <NotifyNotEnabledFeature key={key} message={message} />;
      },
      autoHideDuration: 6000,
    });
  },
};
