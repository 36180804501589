/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import ResourceListItem from '.';

const DndResourceListItem = (props) => {
  const { dragObject, ...others } = props;
  const [{ isDragging }, drag] = useDrag({
    type: 'DragableResourceItem',
    item: { type: 'DragableResourceItem', id: props.id },
    canDrag: true,
    options: {
      dropEffect: 'copy',
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    begin: () => {
      return dragObject;
    },
  });

  return <ResourceListItem ref={drag} draggable isDragging={isDragging} {...others} />;
};

DndResourceListItem.propTypes = {
  dragObject: PropTypes.instanceOf(Object),
  id: PropTypes.string,
};

export default DndResourceListItem;
