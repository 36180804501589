import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FolderForm from '../../spaces/Materials/FolderForm';
import appInfo from '../../app/appInfo';
import { createDirectory, createRuntimeDirectory } from '../../resources/ResourceServices';
import { sendNotification } from '../../commons/utils';
import i18n from '../../i18n';
import { DirectoryType } from '../../app/appConstants';
import './MaterialNewFolderDrawer.scss';
import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';

function MaterialNewFolderDrawer(props) {
  const [processing, setProcessing] = useState(false);
  // console.log('### MaterialNewFolderDrawer', props.currentFolder);

  const getParentFolderId = () => {
    let parentFolderId = null;

    if (props.currentFolder && props.currentFolder.directoryType !== DirectoryType.Extra) {
      parentFolderId = props.currentFolder.id;
    }

    return parentFolderId;
  };

  const renderHeader = () => {
    return (
      <div className="header">
        <div>New folder</div>
        <IconButton color="primary" className="close-button" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  const handleSave = async (folder) => {
    if (!folder) return;

    const isPortal = appInfo.isPortal();
    // console.log('### handleSave', folder, isPortal, props, props.spaceId);
    let resp = null;

    try {
      setProcessing(true);
      if (isPortal) {
        resp = await createDirectory(props.spaceId, folder);
      } else {
        const folderData = {
          ...folder,
          externalFolder: {
            provider: SERVICE_PROVIDER.NORMAL_FOLDER,
          },
        };
        resp = await createRuntimeDirectory(props.spaceId, folderData);
      }
      sendNotification(i18n.t('Created successfully'), { type: 'success' });
      setProcessing(false);
      props.onClose(resp);
    } catch (e) {
      console.error(e);
      sendNotification(e.message, { type: 'error' });
      setProcessing(false);
    }
  };

  return (
    <>
      <Drawer className="material-new-folder-drawer" anchor="left" open onClose={props.onClose}>
        {renderHeader()}
        <FolderForm
          data={{}}
          onSave={handleSave}
          processing={processing}
          parentFolderId={getParentFolderId()}
        />
      </Drawer>
    </>
  );
}

MaterialNewFolderDrawer.propTypes = {
  spaceId: PropTypes.string.isRequired,
  currentFolder: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
};

MaterialNewFolderDrawer.defaultProps = {
  currentFolder: null,
  currentUser: null,
};

export default MaterialNewFolderDrawer;
