import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import { useAppContext } from '../components/AppProvider';
import { fetchCadToken } from './ResourceServices';

const useCadTokenQuery = (enabled) => {
  const { isPortal } = useAppContext();
  const { data, isLoading } = useQuery({
    queryKey: [CacheKeys.getCadToken],
    queryFn: async () => {
      const resp = await fetchCadToken(isPortal);
      return resp;
    },
    retry: 3,
    retryDelay: (attemptIndex) => 2000 * attemptIndex + 1000,
    enabled,
  });

  return { data, isLoading };
};

export default useCadTokenQuery;
