/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { createUploader } from '../DndFileUploader3/DndFileUploaderUtils';
import { newGuid, sendNotification } from '../../commons/utils';
import { getProgress } from '../DndFileUploader3/utils';
import { ErrorFile, FileType, InvalidFileType } from './utils';
import { cancelSessionsId } from '../../resources/ResourceServices';

type IFilesUploaderProps = {
  files: FileType[];
  spaceId: string;
  spacePrivacy: string;
  isPortal: boolean;
  onChangeResource: (resource: any) => void;
  onUploadFailed: (file: InvalidFileType) => void;
  onChangeProgress: (progress: number) => void;
};

export type ImperativeHandleRef = {
  cancelSessions: () => void;
  resetSessionIds: () => void;
  getSessionIds: () => string[];
};

type ResumableFile = Resumable.ResumableFile;

const FilesUploader = forwardRef((props: IFilesUploaderProps, ref) => {
  const {
    files,
    spaceId,
    spacePrivacy,
    isPortal,
    onChangeResource,
    onUploadFailed,
    onChangeProgress,
  } = props;
  const [queueFiles, setQueueFiles] = useState([...files]);
  const [uploaders, setUploaders] = useState<ResumableFile[]>([]);
  const [progresses, setProgresses] = useState({});

  const sessionIds = useMemo(() => {
    const ids = Object.keys(progresses);
    return ids;
  }, [progresses]);

  function cancelSessions() {
    if (uploaders.length) {
      uploaders.forEach((uploader) => {
        uploader.cancel();
      });
    }

    try {
      setQueueFiles([]);
      setUploaders([]);

      if (sessionIds.length > 0) {
        cancelSessionsId(sessionIds, isPortal);
      }

      setProgresses({});
    } catch (error) {
      console.log('error: ', error);
    }
  }
  function resetSessionIds() {
    setQueueFiles([]);
    setUploaders([]);
    setProgresses({});
  }

  useImperativeHandle(ref, () => ({
    getSessionIds: () => sessionIds,
    resetSessionIds,
    cancelSessions,
  }));

  const removeUploaders = useCallback((file: FileType) => {
    setUploaders((prev) =>
      prev.filter((x: any) => !x.getFromUniqueIdentifier(file.uniqueIdentifier))
    );
  }, []);

  const updateProgress = useCallback(
    (sessionId: string, currentProgress: number) => {
      setProgresses((prev) => {
        const newProgresses = { ...prev, [sessionId]: currentProgress };
        const progressValue = getProgress(newProgresses, files.length);
        onChangeProgress(progressValue);
        return newProgresses;
      });
    },
    [onChangeProgress, files.length]
  );

  const handleErrorCases = useCallback(
    (
      uploadingFile: FileType,
      validationResult: ErrorFile | null | undefined,
      sessionId: string
    ) => {
      if (!uploadingFile || !validationResult) return;
      removeUploaders(uploadingFile);
      const invalidResource: InvalidFileType = {
        name: uploadingFile.name,
        fileName: uploadingFile.name,
        file: uploadingFile,
        isValid: false,
        id: newGuid(),
        validationResult,
      };
      onUploadFailed(invalidResource);
      updateProgress(sessionId, 100);
    },
    [removeUploaders, updateProgress, onUploadFailed]
  );

  const handleOnUploadResourceError = useCallback(
    (error: ErrorFile, uploadingFile: FileType, sessionId: string) => {
      if (error && typeof error === 'object') {
        const validationResult = { type: 'common' };
        handleErrorCases(uploadingFile, validationResult, sessionId);
      }
    },
    [handleErrorCases]
  );

  const handleOnFileProgress = useCallback(
    (f: any, sessionId: string) => {
      const currentProgress = Math.round(f.progress() * 100);
      if (sessionId) {
        updateProgress(sessionId, currentProgress);
      }
    },
    [updateProgress]
  );

  const handleOnFileSuccess = useCallback(
    (f: any, message: string, sessionId: string, file: FileType) => {
      const messageObj = JSON.parse(message);
      messageObj.sessionId = sessionId;
      messageObj.name = messageObj.fileName || file.name;
      messageObj.path = file.webkitRelativePath || file.path;

      onChangeResource(messageObj);
      removeUploaders(f);
    },
    [removeUploaders, onChangeResource]
  );

  const handleOnFileError = useCallback(
    async (f: any, message: string, sessionId: string) => {
      console.log('uploaders ### fileError', f, message);
      let displayError = message || '';
      try {
        // error was returnned from server
        const error = JSON.parse(message);
        if (error) {
          displayError = error.message || error.error;
          if (error.vars === 'FileValidation' || error.data?.category === 'FileValidation') {
            const subCategory = error.data?.subCategory;

            let validationResult = null;
            if (subCategory === 'FileSignature') {
              // check magic number from the server
              validationResult = { type: 'signature', message: displayError };
            } else if (subCategory === 'FileExtension') {
              validationResult = { type: 'unaccepted' };
            } else if (subCategory === 'FileMediaType') {
              validationResult = { type: 'unsupported' };
            }

            handleErrorCases(f?.file, validationResult, sessionId);
            return;
          }
        }
      } catch (e) {
        handleErrorCases(f?.file, { type: 'common' }, sessionId);
        console.log('uploaders ### 257 JSON.parse error', e);
      }

      if (displayError) {
        sendNotification(displayError, { type: 'error' });
      }
    },
    [handleErrorCases]
  );

  const handleUploadFile = useCallback(
    async (file: FileType | undefined) => {
      let uploader: any;
      let sessionId: string | undefined;
      try {
        const infoUpload = { spaceId, spacePrivacy };
        uploader = await createUploader(
          file,
          handleOnUploadResourceError,
          handleOnFileProgress,
          handleOnFileSuccess,
          handleOnFileError,
          infoUpload
        );
        sessionId = (uploader?.opts as { sessionId?: string })?.sessionId;
        setUploaders((prev) => [...prev, uploader]);
      } catch (error) {
        console.log('error: ', error);
        if (file) {
          handleErrorCases(file, { type: 'common' }, sessionId || '');
        }
      }
    },
    [
      handleErrorCases,
      handleOnFileError,
      handleOnFileProgress,
      handleOnFileSuccess,
      handleOnUploadResourceError,
      spaceId,
      spacePrivacy,
    ]
  );

  useEffect(() => {
    async function uploadFiles() {
      if (queueFiles.length === 0 || uploaders.length >= 10) return;

      const file = queueFiles.shift();
      setQueueFiles(queueFiles);

      const foundItem = uploaders.find(
        (x: any) => x.getFromUniqueIdentifier(file?.uniqueIdentifier) !== false
      );

      if (!foundItem) {
        await handleUploadFile(file);
      }
    }
    uploadFiles();
  }, [handleUploadFile, queueFiles, uploaders]);

  return null;
});

export default FilesUploader;
