import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';

const SpaceSidebarContext = createContext(null);

SpaceSidebarContext.displayName = 'SpaceSidebarContext';

export const useSpaceSidebarContext = () => useContext(SpaceSidebarContext);

const getSpaceSidebarContext = memoizeOne((sidebar) => {
  return { sidebar };
});

const SpaceSidebarContextProvider = (props) => {
  const context = getSpaceSidebarContext(props.sidebar);
  return (
    <SpaceSidebarContext.Provider value={context}>{props.children}</SpaceSidebarContext.Provider>
  );
};

SpaceSidebarContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
  sidebar: PropTypes.string,
};

export default SpaceSidebarContextProvider;
