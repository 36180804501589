import React, { PureComponent } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import PropTypes from 'prop-types';
import { getTheme } from '../theme';
import i18n from '../i18n';
import ResponsiveDialog from './ResponsiveDialog';
import { requestAccessSpace } from '../settings/notifications/service';
import { sendNotification } from '../commons/utils';
import OfficialButton from './OfficialButtons';

let resolve;
let containerElement;
let rootEl;

class RequestAccessDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      processing: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSendRequest = this.handleSendRequest.bind(this);
  }

  componentWillUnmount() {
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (containerElement) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 0);
    }
    if (resolve) resolve(retVal);
  }

  static show(title, message, data, spaceId, isClosePopup = true) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <RequestAccessDialog
          title={title}
          message={message}
          data={data}
          spaceId={spaceId}
          isClosePopup={isClosePopup}
        />
      </ThemeProvider>
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose() {
    this.setState({ isOpen: false }, () => {
      RequestAccessDialog.destroy();
    });
  }

  async handleSendRequest() {
    const { spaceId, data } = this.props;

    this.setState({ processing: true });

    try {
      await requestAccessSpace(spaceId, data);
      sendNotification(
        'Request sent! You will get an email invitation when the host has given you access',
        { type: 'success' }
      );
    } catch (error) {
      sendNotification(error.message, { type: 'error' });
    }

    if (this.props.isClosePopup) {
      this.setState({ isOpen: true, processing: false }, () => {
        RequestAccessDialog.destroy({
          sendRequest: true,
        });
      });
    } else {
      this.setState({ processing: false });
    }
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }
    return (
      <ResponsiveDialog
        modal
        maxWidth="lg"
        className="white new-popup"
        onClose={this.handleClose}
        showCloseIcon={this.props.isClosePopup}
      >
        <div className="content">
          <h1>{this.props.title}</h1>
          <p>{this.props.message}</p>
        </div>
        <div className="action-buttons">
          <OfficialButton
            onClick={this.handleSendRequest}
            disabled={this.state.processing}
            variant="large-primary"
            label={this.state.processing ? i18n.t('PROCESSING...') : i18n.t('REQUEST ACCESS')}
          />
        </div>
      </ResponsiveDialog>
    );
  }
}

RequestAccessDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  spaceId: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  isClosePopup: PropTypes.bool,
};

export default RequestAccessDialog;
