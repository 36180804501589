/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Popover, Paper } from '@mui/material';
import { ChromePicker, CompactPicker } from 'react-color';
import useEyeDropper from 'use-eye-dropper';
import Draggable from 'react-draggable';
import LightTooltip from '../../components/LightTooltip';
import I18n from '../../i18n';
import './ColorPicker.scss';

const PaperComponent = React.forwardRef((props, ref) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="draggable-dialog"]'}>
      <Paper ref={ref} {...props} />
    </Draggable>
  );
});

function getColorCode(c, defaultColor) {
  if (!c) {
    return defaultColor;
  }
  return c.rgb.a < 1
    ? `rgba(${Math.round(c.rgb.r)}, ${Math.round(c.rgb.g)}, ${Math.round(c.rgb.b)}, ${c.rgb.a})`
    : c.hex;
}

function ColorPicker(props) {
  const { savedColors } = props;
  const [color, setColor] = useState(props.defaultColor);
  const [colors, setColors] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openColorPicker = Boolean(anchorEl);

  // useEffect(() => {
  //   // console.log('### ColorPicker useEffect: ', props.defaultColor);
  //   setColor(props.defaultColor);
  // }, [props.defaultColor]);

  // console.log('color', color);

  useEffect(() => {
    if (openColorPicker) {
      const colorsOption = ['#000000', '#ffffff'];
      if (savedColors) {
        setColors(() => {
          savedColors.forEach((savedColor) => {
            if (!colorsOption.includes(savedColor)) {
              colorsOption.unshift(savedColor);
            }
          });
          if (!colorsOption.includes(props.defaultColor)) {
            colorsOption.unshift(props.defaultColor);
          }
          return colorsOption;
        });
      } else {
        setColors(() => {
          if (!colorsOption.includes(props.defaultColor)) {
            colorsOption.unshift(props.defaultColor);
          }
          return colorsOption;
        });
      }
    }
  }, [openColorPicker, props.defaultColor, savedColors]);

  const { open, isSupported } = useEyeDropper();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (c) => {
    setColor(getColorCode(c, props.defaultColor));
  };

  const handleOnChangeComplete = (c) => {
    const colorCode = getColorCode(c, props.defaultColor);
    setColor(colorCode);
    if (props.onChange) {
      props.onChange(colorCode, 'Color');
    }
  };

  const id = openColorPicker ? 'simple-popover-1' : undefined;

  // const renderColor = (c) => {
  //   if (!c?.r) {
  //     return c;
  //   }
  //   return `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a})`;
  // };

  const pickColor = () => {
    open()
      .then(
        (c) =>
          props.onChange(c.sRGBHex, 'Color') === setColor(c.sRGBHex) &&
          !colors.includes(c.sRGBHex) &&
          setColors([c.sRGBHex, ...colors])
      )
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="picker-color-field">
        <label>{props.label}</label>
        <LightTooltip title={props.tooltipMessage}>
          <div className="picker-color-button" aria-describedby={id} onClick={handleClick}>
            <span style={{ backgroundColor: color }}></span>
          </div>
        </LightTooltip>
      </div>
      {openColorPicker && (
        <Popover
          id={id}
          open
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{ component: PaperComponent }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className="spc-color-popup"
        >
          <div
            aria-labelledby="draggable-dialog-title"
            id="draggable-dialog-title"
            className="spc-color-popup-title"
          >
            {props.label}
          </div>
          <ChromePicker
            color={color}
            onChange={handleOnChange}
            onChangeComplete={handleOnChangeComplete}
            className="spc-color-chrome"
          />
          {isSupported() ? (
            <LightTooltip
              title={I18n.t('Select any color from your screen')}
              placement="bottom-start"
            >
              <IconButton onClick={pickColor} className="eye-dropper">
                <span className="icon-eye-dropper"></span>
              </IconButton>
            </LightTooltip>
          ) : null}
          <CompactPicker
            colors={colors}
            className="spc-color-chrome"
            onChangeComplete={handleOnChangeComplete}
          />
        </Popover>
      )}
    </>
  );
}

ColorPicker.propTypes = {
  defaultColor: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  tooltipMessage: PropTypes.string,
  savedColors: PropTypes.arrayOf(PropTypes.string),
};

ColorPicker.defaultProps = {
  label: '',
  tooltipMessage: '',
  savedColors: null,
};

export default ColorPicker;
