import ResourceThumbnail from './index';

interface ResourceThumbnailProps {
  resourceId: number | string;
  thumbnail?: string;
  resourceType?: number;
  extension?: string;
  isPortal?: boolean;
  isExternal?: boolean;
  src?: string;
  inView?: boolean;
  provider?: string;
  resourceName?: string;
}

const ResourceThumbnailTs = ResourceThumbnail;
export default ResourceThumbnailTs as React.FC<ResourceThumbnailProps>;
