import React from 'react';
import { Box } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import SpaceAnalyticsInfoBox from '../SpaceAnalyticsInfoBox';
import DataSummaryBox from '../DataSummaryBox';
import Tabs, { useTabs } from '../../components/Tabs';
import i18n from '../../i18n';
import VideoMessagesTable from './VideoMessagesTable';
import ChatMessagesTable from './ChatMessageTable';
import useSpaceChatSummaryQuery from '../queries/useSpaceChatSummaryQuery';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import { Ids } from '../../commons/pendoTaggings';
import NoSpaceAnalyticsData from '../components/NoSpaceAnalyticsData';
import { SidebarType } from '../../app/appConstants';
import Loading from '../../components/Loading';

const ChatInfoTable = () => {
  const navigate = useNavigate();
  const { tabs, changeTab, activeTab } = useTabs([
    i18n.t('Chat messages'),
    i18n.t('Video messages'),
  ]);
  const { space, reportEndDate, users, excludeUserIds } = useSpaceAnalyticsContext();
  const spaceId = space?.id;
  const endDate = moment(reportEndDate).toDate().toISOString();

  const { chatsSummary, isFetching } = useSpaceChatSummaryQuery(
    spaceId,
    !!spaceId && !!users?.length,
    endDate,
    excludeUserIds
  );

  const emptyState =
    !chatsSummary || (chatsSummary.totalChatMessage === 0 && chatsSummary.totalVideoMessage === 0);

  function handleOnChangeTab(value: unknown) {
    changeTab(value);
  }
  const dataSummaryList = [
    {
      id: 'chat-messages',
      label: 'Chat messages',
      value: chatsSummary?.totalChatMessage || 0,
      toolTip: 'Total number of public chat messages sent in the space.',
    },
    {
      id: 'video-messages',
      label: 'Video messages',
      value: chatsSummary?.totalVideoMessage || 0,
      toolTip: 'Total number of public video messages sent in the space.',
    },
  ];
  const renderContent = () => {
    if (isFetching) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }

    if (emptyState) {
      return (
        <NoSpaceAnalyticsData
          icon={<i className="icon-chat-new" style={{ fontSize: 20 }} />}
          description={i18n.t('See how much users have communicated in the space.')}
          button={{
            label: i18n.t('Send a message'),
            onClick: () =>
              navigate(`/s/${space?.id}`, { state: { openSidebar: SidebarType.Chat } }),
            dataId: Ids.chatTableSendChatMessageButton,
          }}
        />
      );
    }
    return (
      <>
        <DataSummaryBox dataSummaryList={dataSummaryList} className="col-2" />
        <Tabs
          tabs={tabs}
          onChange={handleOnChangeTab}
          variant="tabs-level-2 preview-details-privacy-level"
        />
        <Box>
          {activeTab === 0 ? (
            <ChatMessagesTable chatMessageDetails={chatsSummary?.chatMessageDetails} />
          ) : (
            <VideoMessagesTable videoMessageDetails={chatsSummary?.videoMessageDetails} />
          )}
        </Box>
      </>
    );
  };

  return (
    <Box className="widget-box chat-info-table">
      <SpaceAnalyticsInfoBox
        icon="icon-chat-new"
        title="Chats"
        description={!emptyState ? 'See how much users have communicated in the space.' : ''}
        className="chat"
      />
      {renderContent()}
    </Box>
  );
};

export default ChatInfoTable;
