import React from 'react';

const TestScreen = () => {
  // const a = null;
  // console.log(a.test);
  // navigator.mediaDevices.enumerateDevices().then((devices) => {
  //   console.log(devices);
  // });
  return <div>Hello This is Test page</div>;
};

export default TestScreen;
