/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Card, CardContent, IconButton, Typography } from '@mui/material';
import { parseDateTimeStringFromServer, timeFromNow } from '../commons/DateTimeUtils';
import BlockUI from './BlockUI';
import BackgroundSpace from './BackgroundSpace';
import LogoSpace from './LogoSpace';
import { getDisplayText } from '../commons/utils';
import SpaceTags from './SpaceTag/SpaceTags';
import LightTooltip from './LightTooltip';
import './CardItem.scss';

function CardItem(props) {
  const {
    name,
    logo,
    thumbnail,
    classes,
    onClick,
    isLoading,
    tags,
    dataItems,
    lastEdited,
    createdAt,
    actionsButton,
    headerElement,
    footerElement,
    processing,
    onClickMore,
  } = props;

  const { htmlAttributes } = getDisplayText(name);

  const localLastEditedDate = useMemo(() => {
    const createDate = createdAt;
    let dateValue;
    let text = 'Created';
    if (lastEdited) {
      text = 'Edited';
      dateValue = parseDateTimeStringFromServer(lastEdited).local();
    } else if (createDate) {
      dateValue = parseDateTimeStringFromServer(createDate).local();
    }
    if (dateValue) {
      dateValue = timeFromNow(dateValue);
      return `${text} ${dateValue}`;
    }
    return null;
  }, [lastEdited, createdAt]);

  return (
    <Card
      className={`card-item ${actionsButton ? 'has-actions-button' : ''} ${classes?.item}`}
      onClick={onClick}
    >
      {isLoading && <BlockUI display />}
      <div className="card-item-header">
        {headerElement}
        {onClickMore ? (
          <IconButton disabled={processing} onClick={onClickMore} size="small" className="iconbox">
            <MoreHorizIcon />
            {processing && (
              <i className="icon-spinner" style={{ fontSize: 12, position: 'absolute' }} />
            )}
          </IconButton>
        ) : null}
      </div>

      <BackgroundSpace
        alt="Space"
        className="customer-background"
        src={thumbnail}
        title={name}
        height={125}
      />
      {logo && <LogoSpace className="customer-logo" src={logo} alt="SPCE logo" />}

      <CardContent className={classes?.content}>
        <div className="title-info">
          <LightTooltip title={name} placement="bottom-start">
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className="text-truncate"
              {...htmlAttributes}
            />
          </LightTooltip>
          {tags?.length ? (
            <div className="space-info-tags">
              <SpaceTags items={tags} showOnlyNumberInMore />
            </div>
          ) : null}
        </div>

        {dataItems?.map((item) => (
          <div className="sub-info">
            <LightTooltip
              title={item.message}
              placement="bottom"
              disableFocusListener={!item.message}
              disableTouchListener={!item.message}
            >
              <div className="sub-info-icon">{item.icon}</div>
            </LightTooltip>

            <span className="sub-info-text">{item.text}</span>
          </div>
        ))}
      </CardContent>

      <div className={`card-item-footer ${localLastEditedDate && 'border-top'}`}>
        {localLastEditedDate && (
          <Typography className="modified-date text-truncate" component="p">
            {localLastEditedDate}
          </Typography>
        )}
        {footerElement}
      </div>

      {actionsButton && <div className="action-buttons">{actionsButton}</div>}
    </Card>
  );
}

CardItem.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.string,
  thumbnail: PropTypes.string,
  classes: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  tags: PropTypes.arrayOf(Object),
  headerElement: PropTypes.element,
  dataItems: PropTypes.arrayOf(Object),
  lastEdited: PropTypes.string,
  createdAt: PropTypes.string,
  actionsButton: PropTypes.node,
  footerElement: PropTypes.element,
  processing: PropTypes.bool,
  onClickMore: PropTypes.func,
};

export default React.memo(CardItem);
