import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceChatSummary } from '../services/SpaceAnalyticsService';
import { ISpaceUser } from '../../types/User';

export type ChatSummaryDetails = ISpaceUser & {
  userInfoId: number;
  fullName: string;
  email: string;
  totalActiveChat: number;
  isJoinedSpace: boolean;
  isOrganizationUser: boolean;
};
export type VideoMessageSummaryDetails = {
  title: string;
  createdDate: string;
  description: string;
  createdByUserInfoId: number;
  videoMessageId: string;
  resourceId: number;
  totalViews: number;
  documentId: number;
};

export type ChatSummary = {
  totalChatMessage: number;
  totalVideoMessage: number;
  chatMessageDetails: ChatSummaryDetails[];
  videoMessageDetails: VideoMessageSummaryDetails[];
};

const useSpaceChatSummaryQuery = (
  spaceId: string | undefined,
  enabled: boolean,
  endDate: string,
  excludeUserIds: number[]
) => {
  const getTodosByUser = useQuery({
    queryKey: [CacheKeys.spaceAnalytics_FetchSpaceChatSummary, spaceId, endDate, excludeUserIds],
    queryFn: async () => {
      if (!spaceId) {
        return [];
      }
      const resp = await fetchSpaceChatSummary(spaceId, excludeUserIds);
      return resp;
    },

    retry: 3,
    retryDelay: () => 5000,
    enabled: enabled,
  });

  const isFetching: boolean = getTodosByUser.isFetching;
  const chatsSummary: ChatSummary = getTodosByUser.data?.value;
  return { chatsSummary, isFetching };
};

export default useSpaceChatSummaryQuery;
