import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import eventBus from '../commons/EventBus';
import { SpaceInteractionType } from '../app/appConstants';
import { sendBrowserEvents } from '../commons/CommonServices';
import { uuidv4 } from '../commons/utils';

class SpaceAnalyticsHandler extends PureComponent {
  constructor(props) {
    super(props);
    this.unSubscribeSpaceAnalyticsEvents = null;
    this.onClick = this.onClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onVisible = this.onVisible.bind(this);
    this.onBeforeunload = this.onBeforeunload.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.sendEventsDebounced = _debounce(() => this.sendEvents(), 4000);
    const now = new Date();
    this.session = {
      sessionId: uuidv4(),
      lastAccessTime: now,
    };
    this.actions = [
      {
        type: SpaceInteractionType.PAGE_LOAD,
        time: now,
        data: JSON.stringify({}),
      },
    ];
    this.spaceContainerElement = null;
  }

  getSession() {
    // if lastAccessTime is less than 6 hours, then reuse the session.
    const now = new Date();
    const diff = now - this.session.lastAccessTime;
    console.log('getAnalyticsSession diff', diff);
    // if (diff < 100000) {
    if (diff < 21600000) {
      return this.session;
    }
    return {
      sessionId: uuidv4(),
      lastAccessTime: now,
    };
  }

  sendEvents() {
    if (this.actions.length > 0) {
      const session = this.getSession();
      let lastAccessTime = session.lastAccessTime;
      let duration = 0;
      const events = this.actions.map((action) => {
        if (action.type === SpaceInteractionType.VIEW_RESOURCE) {
          duration = JSON.parse(action.data).duration;
        } else {
          duration = Math.floor((action.time - lastAccessTime) / 1000);
        }

        lastAccessTime = action.time;
        return {
          duration,
          spaceInteractionType: action.type,
          data: action.data,
          occurred: action.time,
        };
      });
      session.lastAccessTime = lastAccessTime;
      this.session = session;
      sendBrowserEvents(this.props.spaceId, session.sessionId, events);
      this.actions = [];
    }
  }

  onClick() {
    // console.log('### SpaceAnalyticsHandler onClick');
    this.actions.push({
      type: SpaceInteractionType.CLICK,
      time: new Date(),
      data: JSON.stringify({}),
    });
    this.sendEventsDebounced();
  }

  onKeyDown(evt) {
    if (evt.key === 'Enter') {
      // console.log('### SpaceAnalyticsHandler Enter');
      this.actions.push({
        type: SpaceInteractionType.SUBMIT,
        time: new Date(),
        data: JSON.stringify({}),
      });
      this.sendEventsDebounced();
    }
  }

  onVisible() {
    if (document.visibilityState === 'visible') {
      // console.log('### SpaceAnalyticsHandler onFocus');
      this.actions.push({
        type: SpaceInteractionType.FOCUS,
        time: new Date(),
        data: JSON.stringify({}),
      });
      this.sendEventsDebounced();
    }
  }

  onBeforeunload() {
    this.sendEventsDebounced.flush();
  }

  onScroll() {
    // console.log('### SpaceAnalyticsHandler onScroll');
    this.actions.push({
      type: SpaceInteractionType.SCROLL,
      time: new Date(),
      data: JSON.stringify({}),
    });
    this.sendEventsDebounced();
  }

  componentDidMount() {
    this.sendEvents();
    this.unSubscribeSpaceAnalyticsEvents = eventBus.subscribe(
      'SpaceAnalyticsEvents',
      (action, data) => {
        // console.log('### SpaceAnalyticsHandler SpaceAnalyticsEvents', action, data);
        this.actions.push({
          type: action,
          time: new Date(),
          data: JSON.stringify(data),
        });
        this.sendEventsDebounced();
      }
    );
    document.body.addEventListener('click', this.onClick, true);
    document.body.addEventListener('keydown', this.onKeyDown, true);
    document.addEventListener('visibilitychange', this.onVisible, true);
    window.addEventListener('beforeunload', this.onBeforeunload);
    setTimeout(() => {
      this.spaceContainerElement = document.getElementById('cms-theme-preview-container');

      if (this.spaceContainerElement) {
        this.spaceContainerElement.addEventListener('scroll', _debounce(this.onScroll, 3000), true);
      }
    }, 1000);
  }

  componentWillUnmount() {
    this.sendEventsDebounced.flush();
    if (this.unSubscribeSpaceAnalyticsEvents) {
      this.unSubscribeSpaceAnalyticsEvents();
    }
    document.body.removeEventListener('click', this.onClick, true);
    document.body.removeEventListener('keydown', this.onKeyDown, true);
    document.removeEventListener('visibilitychange', this.onVisible, true);
    window.removeEventListener('beforeunload', this.onBeforeunload);
    if (this.spaceContainerElement) {
      this.spaceContainerElement.removeEventListener('scroll', this.onScroll, true);
    }
  }
  render() {
    return null;
  }
}

SpaceAnalyticsHandler.propTypes = {
  spaceId: PropTypes.string.isRequired,
};

export default SpaceAnalyticsHandler;
