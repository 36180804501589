import i18n from '../../i18n';

export const SubscriptionFeaturesTypes = {
  common: 'common',
  space: 'space',
  participant: 'participant',
  liveSupport: 'liveSupport',
  storage: 'storage',
  breakout: 'breakout',
  webinar: 'webinar',
  recording: 'recording',
  spotlight: 'spotlight',
  enterprise: 'enterprise',
};

export const SubscriptionFeature = {
  addSpace: 'ADD_SPACE',
  cloneSpace: 'CLONE_SPACE',
  activation: 'ACTIVATION',
  externalTools: 'EXTERNAL_TOOLS',
  breakout: 'BREAKOUT',
  roomSettings: 'ROOM_SETTINGS',
  recording: 'RECORDING',
  spotlight: 'SPOTLIGHT',
};

export const SubscriptionType = {
  free: 100,
  premium: 200,
  premiumPlus: 300,
  enterprise: 400,
  super: 500,
};

// export const SubscriptionMessage = {
//   [SubscriptionType.free]: {
//     [SubscriptionFeature.addSpace]: i18n.t('10 Spaces used'),
//     [SubscriptionFeature.cloneSpace]: i18n.t('Copy space needs premium or higher'),
//     [SubscriptionFeature.activation]: i18n.t(
//       'Other questions than wordcloud available in premium or higher'
//     ),
//     [SubscriptionFeature.externalTools]: i18n.t('Third party application available in premium'),
//     [SubscriptionFeature.breakout]: i18n.t('Oops, Breakout rooms are not available in Freemium'),
//     [SubscriptionFeature.roomSettings]: i18n.t('In freemium you can have one design only'),
//     [SubscriptionFeature.recording]: i18n.t(
//       'Oops, meeting recordings are not available in Freemium'
//     ),
//     [SubscriptionFeature.spotlight]: i18n.t('Recording available in enterprise subscription'),
//   },
//   [SubscriptionType.premium]: {
//     [SubscriptionFeature.recording]: i18n.t(
//       'Oops, meeting recordings are not available in Premium'
//     ),
//     [SubscriptionFeature.spotlight]: i18n.t('Oops,  recordings are not available in Premium'),
//   },
//   [SubscriptionType.enterprise]: {},
// };

export const premiumFeatureList = [
  {
    id: SubscriptionFeaturesTypes.participant,
    label: i18n.t('Space and meeting templates'),
  },
  {
    id: SubscriptionFeaturesTypes.storage,
    label: i18n.t('Integrations with 3rd party tools'),
  },
];

export const enterpriseFeatureList = [
  {
    id: 1,
    label: i18n.t('Insights & analytics'),
  },
  {
    id: SubscriptionFeaturesTypes.participant,
    label: i18n.t('Access management'),
  },
  {
    id: SubscriptionFeaturesTypes.storage,
    label: i18n.t('Meeting recordings & transcriptions'),
  },
  {
    id: SubscriptionFeaturesTypes.space,
    label: i18n.t('Participant feedback'),
  },
  {
    id: SubscriptionFeaturesTypes.webinar,
    label: i18n.t('Product-marketing showrooms'),
  },
  {
    id: 2,
    label: i18n.t('Enterprise-level security'),
  },
  {
    id: 3,
    label: i18n.t('Custom implementation and onboarding'),
  },
];

export const superFeatureList = [
  {
    id: 1,
    label: i18n.t('Unlimited usage of all features and fully customizing'),
  },
];

export const SubscriptionAction = {
  add: 'ADD',
};

export const SubscriptionContext = {
  space: 'space',
};

export const enterpriseSubscriptionList = [
  {
    id: 1,
    label: i18n.t('Insights & Analytics'),
  },
  {
    id: SubscriptionFeaturesTypes.participant,
    label: i18n.t('Meeting recordings & transcpriptions'),
  },
  {
    id: SubscriptionFeaturesTypes.storage,
    label: i18n.t('Access management'),
  },
  {
    id: SubscriptionFeaturesTypes.space,
    label: i18n.t('Participant feedback'),
  },
];

export const freemiumSubscriptionList = [
  {
    id: 1,
    label: i18n.t('Beautifuly designed spaces'),
  },
  {
    id: SubscriptionFeaturesTypes.participant,
    label: i18n.t('Custom branding (CMS)'),
  },
  {
    id: SubscriptionFeaturesTypes.storage,
    label: i18n.t('Timeline with activities'),
  },
];

export const preemiumSubscriptionList = [
  {
    id: SubscriptionFeaturesTypes.participant,
    label: i18n.t('Space and meeting templates'),
  },
  {
    id: SubscriptionFeaturesTypes.storage,
    label: i18n.t('Integrations with 3rd party tools'),
  },
];
