import React, { Suspense, forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import ResponsiveDialog from '../components/ResponsiveDialog';
import './CreateSpaceDialog.scss';
import { Ids } from '../commons/pendoTaggings';
import Loading from '../components/Loading';
import { useAppContext } from '../components/AppProvider';

const CreateSpaceContainer = React.lazy(() => import('./CreateSpaceContainer'));

const CreateSpaceDialog = forwardRef((props, ref) => {
  const { isInSpace } = props;
  const [open, setOpen] = useState(false);
  const [space, setSpace] = useState(null);
  const [hideContent, setHideContent] = useState(false);
  const [action, setAction] = useState(null);
  const dataIds = Ids.CreateSpaceDialog;
  const { isTablet } = useAppContext();

  const handleOnOpen = (data, actionType) => {
    setOpen(true);
    setSpace(data);
    setAction(actionType);
    setHideContent(false);
  };
  const handleOnClose = async () => {
    setOpen(false);
    setSpace(null);
  };

  useImperativeHandle(ref, () => ({
    onOpen: handleOnOpen,
  }));

  return (
    <ResponsiveDialog
      open={open}
      maxWidth="xl"
      className={`create-space-popup create-space-dialog  ${hideContent ? 'hide-content' : ''}`}
      onClose={handleOnClose}
      disableBackdropClick
      disableEscapeKeyDown
      showCloseIcon={false}
      dataIds={dataIds}
      fullScreen={isTablet}
    >
      <Suspense fallback={<Loading />}>
        <CreateSpaceContainer
          space={space}
          action={action}
          onClosePopup={handleOnClose}
          hideContentPopup={setHideContent}
          isInSpace={isInSpace}
        />
      </Suspense>
    </ResponsiveDialog>
  );
});

CreateSpaceDialog.propTypes = {
  isInSpace: PropTypes.bool,
};

CreateSpaceDialog.displayName = 'CreateSpaceDialog';

export default CreateSpaceDialog;
