import React from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem, Pagination, Select } from '@mui/material';

import { MenuEnhancedProps } from '../SelectEnhanced';

function EnhancedTablePagination(props) {
  const {
    page,
    rowCount,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;

  if (rowCount <= rowsPerPageOptions[0]) {
    // have only 1 page, dont show the pagination
    return null;
  }

  const info = props.info ?? `${rowCount} results`;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className="table-pagination"
    >
      <Pagination
        page={page + 1}
        count={Math.ceil(rowCount / rowsPerPage)}
        onChange={handleChangePage}
        variant="outlined"
        shape="rounded"
        className="pagination"
      />
      <Box display="flex" alignItems="center" gap={2} className="right-section">
        <span className="result-info">{info}</span>
        <Select
          variant="outlined"
          name="rowsPerPage"
          className="select-rows-per-page"
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          MenuProps={MenuEnhancedProps}
        >
          {rowsPerPageOptions.map((op, idx) => (
            <MenuItem key={idx} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
}

EnhancedTablePagination.propTypes = {
  page: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  info: PropTypes.string,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

EnhancedTablePagination.defaultProps = {
  rowsPerPageOptions: [10, 25, 50],
};

export default EnhancedTablePagination;
