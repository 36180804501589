import React, { useCallback, useState } from 'react';
import memoizeOne from 'memoize-one';
import useViewMode from './useViewMode';
import { ViewModeType } from '../../resources/resourceConstants';
import { IResourcesViewContext, ResourcesViewContextProviderProps } from './types';
import { ResourceSources } from '../../resources/types';
import { ISortOption } from '../SortSelect/types';
import i18n from '../../i18n';
import { useBreadcrumbContext } from '../../globalResources/context/BreadcrumbsContextProvider';
import { useAppContext } from '../AppProvider';

const ResourcesViewContext = React.createContext<IResourcesViewContext>({
  searchText: '',
  page: 0,
  pageSize: 10,
  viewMode: ViewModeType.LIST,
  order: 'desc',
  orderBy: 'lastModified',
  sortOptions: [],
  columns: [],
  hasSelectColumn: false,
  setSortData: () => null,
  setSearchText: () => null,
  setPage: () => null,
  setPageSize: () => null,
  setViewMode: () => null,
  isPicker: false,
});
ResourcesViewContext.displayName = 'ResourcesViewContext';

const getResourcesViewContext = memoizeOne(
  (
    searchText,
    sortData,
    page,
    pageSize,
    viewMode,
    sortOptions,
    columns,
    isPicker
  ): IResourcesViewContext => ({
    searchText,
    page,
    pageSize,
    viewMode,
    sortOptions,
    columns,
    isPicker,
    hasSelectColumn: columns.includes('select'),
    order: sortData.order,
    orderBy: sortData.orderBy,
    setSortData: () => null,
    setSearchText: () => null,
    setPage: () => null,
    setPageSize: () => null,
    setViewMode: () => null,
  })
);

const getSortOptionsAndColumns = memoizeOne(
  (
    source: ResourceSources,
    isPicker?: boolean,
    canSelectResource?: boolean
  ): {
    sortOptions: Array<ISortOption>;
    columns: string[];
  } => {
    let sortOptions = [] as Array<ISortOption>;
    let columns = [] as string[];
    if (source === ResourceSources.Global) {
      columns = ['name', 'itemsCount', 'lastModified'];
      sortOptions = [
        { value: 'name', name: i18n.t('Name') },
        { value: 'itemsCount', name: i18n.t('Items') },
        { value: 'lastModified', name: i18n.t('Last modified'), default: true },
      ];
    } else if (source === ResourceSources.OneDrive) {
      columns = ['name', 'itemsCount', 'lastModified'];
      sortOptions = [{ value: 'name', name: i18n.t('Name'), default: true }];
    } else if (source === ResourceSources.GoogleDrive) {
      columns = ['name', 'itemsCount', 'lastModified'];
      sortOptions = [
        { value: 'name', name: i18n.t('Name') },
        { value: 'itemsCount', name: i18n.t('Items') },
        { value: 'lastModified', name: i18n.t('Last modified'), default: true },
      ];
    } else if (source === ResourceSources.Space) {
      columns = ['name', 'indicator', 'lastModified'];
      sortOptions = [
        { value: 'name', name: i18n.t('Name') },
        { value: 'lastModified', name: i18n.t('Last modified'), default: true },
      ];
    }
    const shouldShowSelectColumn =
      (isPicker && canSelectResource) ||
      (source === ResourceSources.Global && canSelectResource) ||
      source === ResourceSources.Space;
    if (shouldShowSelectColumn) {
      columns.unshift('select');
    }
    if (!isPicker && (source === ResourceSources.Global || source === ResourceSources.Space)) {
      columns.push('actions');
    }
    return { sortOptions, columns };
  }
);

const ResourcesViewContextProvider = ({
  children,
  id,
  userInfoId,
  source,
  isPicker,
  canSelectResource,
  isMobileMode,
}: ResourcesViewContextProviderProps) => {
  const { sortOptions, columns } = getSortOptionsAndColumns(source, isPicker, canSelectResource);
  const [searchText, setSearchText] = useState('');
  const [sortData, setSortData] = useState<object>({
    orderBy: sortOptions.find((s) => s.default === true)?.value ?? 'lastModified',
    order: 'desc',
  });
  // console.log('sortData', sortData);
  // const [page, setPage] = useState(0);
  const { isMobile } = useAppContext();
  const [pageSize, setPageSize] = useState(10);
  const [viewMode, setViewMode] = useViewMode(id, userInfoId, isMobileMode || isMobile);

  const { currentBreadcrumbItem, setPage } = useBreadcrumbContext();
  const page = currentBreadcrumbItem?.page ?? 0;

  const context = getResourcesViewContext(
    searchText,
    sortData,
    page,
    pageSize,
    viewMode,
    sortOptions,
    columns,
    isPicker
  );

  context.setPageSize = useCallback((size: number) => {
    setPageSize(size);
  }, []);
  context.setViewMode = setViewMode;
  context.setPage = setPage;
  context.setSearchText = setSearchText;
  context.setSortData = setSortData;

  return <ResourcesViewContext.Provider value={context}>{children}</ResourcesViewContext.Provider>;
};
ResourcesViewContextProvider.defaultProps = {
  canSelectResource: true,
};
export const useResourcesViewContext = () => React.useContext(ResourcesViewContext);
export default ResourcesViewContextProvider;
