import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import useChatPublicChannel from './useChatPublicChannel';
import { useSpaceContext } from '../SpaceContext';
import { SpaceState } from '../../app/appConstants';
import { getUrlParameter } from '../../commons/utils';
import currentOrganization from '../../commons/CurrentOrganization';

const ChatContext = React.createContext({});
ChatContext.displayName = 'ChatContext';

export const useChatContext = () => React.useContext(ChatContext);

export const getChatContext = memoizeOne((chatPublicChannel, spaceId) => {
  return { chatPublicChannel, spaceId };
});

const ChatContextProvider = ({ children }) => {
  const { space, isSpaceUserLoggedIn, spaceFeatures } = useSpaceContext();
  const isAdmin = currentOrganization?.isAdmin();
  const isOpenSpace = space?.state === SpaceState.Open;
  const spaceId = space?.id;
  const isRecorder = getUrlParameter('isRecorder', null, true) === 1;
  const canRunChat =
    spaceFeatures?.Chat &&
    !isRecorder &&
    !!spaceId &&
    isOpenSpace &&
    (isSpaceUserLoggedIn || isAdmin);

  const [chatPublicChannel] = useChatPublicChannel({ canRunChat });

  const context = getChatContext(chatPublicChannel, spaceId);
  return <ChatContext.Provider value={context}>{children}</ChatContext.Provider>;
};

ChatContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
};

export default ChatContextProvider;
