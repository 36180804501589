import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import './DataSummaryBox.scss';
import Icons from '../components/Icons';
import { renderValueSummary } from './common';

function DataSummaryBox({ dataSummaryList, className, skipSeconds }) {
  return (
    <Box className={`data-summary-box ${className}`}>
      {dataSummaryList?.map((item, index) => (
        <Box key={index} className="summary-item">
          <Typography className="number">
            {renderValueSummary(item.label, item.value, skipSeconds)}
          </Typography>
          <Box className="summary-info">
            <Typography className="title">{item.label}</Typography>
            {item.toolTip && <Icons name="icon-new-info" title={item.toolTip} />}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

DataSummaryBox.propTypes = {
  dataSummaryList: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  skipSeconds: PropTypes.bool,
};

export default DataSummaryBox;
