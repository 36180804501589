import React, { PureComponent } from 'react';

import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import PropTypes from 'prop-types';
import { getTheme } from '../theme';
import i18n from '../i18n';
import ResponsiveDialog from './ResponsiveDialog';
import OfficialButton from './OfficialButtons';

let resolve;
let containerElement;
let rootEl;

class ErrorDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillUnmount() {
    // console.log('componentWillUnmount ErrorDialog');
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (containerElement) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 0);
    }
    if (resolve) resolve(retVal);
  }

  static show(title, message, onClose = null) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <ErrorDialog title={title} message={message} onClose={onClose} />
      </ThemeProvider>,
      containerElement
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose() {
    this.setState({ isOpen: false }, () => {
      ErrorDialog.destroy();
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }
    return (
      <ResponsiveDialog modal maxWidth="lg" className="white new-popup" onClose={this.handleClose}>
        <div className="content">
          <h1>{this.props.title}</h1>
          <p>{this.props.message}</p>
        </div>
        <div className="action-buttons">
          <OfficialButton
            onClick={this.handleClose}
            label={i18n.t('OK, I UNDERSTAND')}
            variant="large-primary"
          />
        </div>
      </ResponsiveDialog>
    );
  }
}

ErrorDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
};

export default ErrorDialog;
