import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import i18n from '../i18n';
import './Loading.scss';
import OfficialButton from './OfficialButtons';
// import { defaultTheme } from '../theme';

const Loading = ({ className, style, color, message, fontSize, timeout, onClickRetry }) => {
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    if (!timeout) {
      return () => {};
    }
    const timer = setTimeout(() => {
      const onClick = onClickRetry || (() => location.reload());
      const action = () => (
        <OfficialButton
          fullWidth
          onClick={onClick}
          variant="regular-primary"
          label={i18n.t('Retry')}
        />
      );

      setError(
        <ErrorMessage
          message={i18n.t('Sorry, something went wrong. Please click button below to reload.')}
          renderActions={action}
        />
      );
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout, onClickRetry]);

  function renderContent() {
    if (error) {
      return error;
    }
    return (
      <>
        <i className="icon-loading icon-spinner" style={{ fontSize: fontSize || 20, color }} />
        {message && <p style={{ color: color }}>{message}</p>}
      </>
    );
  }
  return (
    <div className={className} style={{ margin: 'auto', ...style }}>
      {renderContent()}
    </div>
  );
};

Loading.propTypes = {
  style: PropTypes.instanceOf(Object),
  color: PropTypes.string,
  timeout: PropTypes.number,
  fontSize: PropTypes.number,
  className: PropTypes.string,
  message: PropTypes.string,
  onClickRetry: PropTypes.func,
};

export default Loading;
