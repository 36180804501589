import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchAssessmentsUsersSummary } from '../services/SpaceAnalyticsService';

const useAssessmentsUsersSummaryQuery = (
  spaceId: string | undefined,
  enabled: boolean,
  endDate: string,
  excludeUserIds: number[]
) => {
  const assessmentsUsersSummaryQuery = useQuery({
    queryKey: [
      CacheKeys.spaceAnalytics_FetchAssessmentsUserSummary,
      spaceId,
      endDate,
      excludeUserIds,
    ],
    queryFn: async () => {
      if (!spaceId) {
        return [];
      }
      const resp = await fetchAssessmentsUsersSummary(excludeUserIds, endDate);
      return resp;
    },

    retry: 1,
    retryDelay: () => 5000,
    enabled: enabled,
  });

  const isFetching: boolean = assessmentsUsersSummaryQuery.isFetching;
  const assessmentsUsersSummary = assessmentsUsersSummaryQuery.data?.assessments || [];

  return { assessmentsUsersSummary, isFetching };
};

export default useAssessmentsUsersSummaryQuery;
