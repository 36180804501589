import React from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { TextField, InputAdornment } from '@mui/material';
import './CmsPaddingOptions.scss';
import LightTooltip from '../../components/LightTooltip';

const CmsPaddingOptions = (props) => {
  const { defaultValue, onChange, name, label, toolTip } = props;
  const paddingSize = defaultValue?.replace('px', '');
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    const newPadding = defaultValue?.replace('px', '');
    if (inputRef.current && newPadding !== inputRef.current.value) {
      inputRef.current.value = newPadding;
    }
  }, [defaultValue]);

  function handleInputChanged(event) {
    const newValue = event.target.value;
    let finalValue = newValue.trim(); // .replace(/[^0-9]/g, '');
    let isValid = true;
    if (!finalValue || finalValue < 0) {
      finalValue = '0';
      isValid = false;
    }
    if (finalValue > 150) {
      finalValue = '150';
      isValid = false;
    }
    finalValue = Math.max(0, parseInt(finalValue)).toString();
    console.log(
      '### CmsPaddingOptions onInputChange: ',
      inputRef.current.value,
      newValue,
      finalValue
    );
    if (inputRef.current && !isValid) {
      inputRef.current.value = finalValue;
    }
    if (onChange) {
      onChange(`${finalValue}px`);
    }
  }

  const onDebouncedChange = useDebouncedCallback((event) => handleInputChanged(event), 800);

  // attr: maxlength is ignored on <input type="number">.
  // https://stackoverflow.com/a/18510925
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#maxlength

  return (
    <LightTooltip title={toolTip}>
      <div className="padding-option-select">
        <TextField
          inputRef={inputRef}
          fullWidth
          name={name} // must have the name & autocomplete=new-password to disable autocomplete and autofill
          variant="outlined"
          defaultValue={paddingSize}
          onChange={onDebouncedChange}
          type="number"
          InputProps={{
            inputProps: { max: 150, min: 0 },
            startAdornment: (
              <InputAdornment position="start" className="start-label">
                {label}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <span className="pixels">px</span>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </LightTooltip>
  );
};

CmsPaddingOptions.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  toolTip: PropTypes.string,
};

export default CmsPaddingOptions;
