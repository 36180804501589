import React from 'react';
import { Box } from '@mui/material';
import Tabs, { useTabs } from '../../components/Tabs';
import i18n from '../../i18n';
import ResourceMetricsTable from './ResourceMetricsTable';
import ParticipantMetricsTable from './ParticipantMetricsTable';
import { AllResourceMetricsResponse } from '../types/resources';

type ResourceMetricsContainerProps = {
  resourceMetricsByResources?: AllResourceMetricsResponse;
  resourceMetricsByParticipants?: AllResourceMetricsResponse;
};

const ResourceMetricsContainer = (props: ResourceMetricsContainerProps) => {
  const { resourceMetricsByResources, resourceMetricsByParticipants } = props;
  const { tabs, changeTab, activeTab } = useTabs([
    i18n.t('Group by resources'),
    i18n.t('Group by users'),
  ]);
  const handleOnChangeTab = (value: string) => {
    changeTab(value);
  };

  return (
    <Box>
      <Box justifyContent="left" display="flex">
        <Tabs
          tabs={tabs}
          onChange={handleOnChangeTab}
          variant="tabs-level-2 preview-details-privacy-level resource-metrics-tabs"
        />
      </Box>
      <Box minHeight={200}>
        {activeTab === 0 ? (
          <ResourceMetricsTable resourceMetricsByResources={resourceMetricsByResources} />
        ) : (
          <ParticipantMetricsTable resourceMetricsByParticipants={resourceMetricsByParticipants} />
        )}
      </Box>
    </Box>
  );
};

export default ResourceMetricsContainer;
