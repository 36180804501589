/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import AppContainer from '../components/AppContainer';

const PublicRoute = ({ component: Component, ...rest }) => {
  let hideHeader = rest.hideHeader ? rest.hideHeader : false;
  // const isRuntime = rest.isRuntime ? rest.isRuntime : false;
  const fullScreen = rest.fullScreen ? rest.fullScreen : false;
  // const location = rest.location || {};
  let hideFooter = rest.hideFooter ? rest.hideFooter : false;
  if (fullScreen) {
    hideHeader = true;
    hideFooter = true;
  }
  const logo = rest.logo ? rest.logo : null;
  return (
    <AppContainer
      logo={logo}
      noSignalR
      fullScreen={fullScreen}
      hideHeader={hideHeader}
      hideFooter={hideFooter}
      path={rest.path}
      includeDeletedFonts={rest.includeDeletedFonts}
      isSpace={rest.isSpace}
    >
      <Component {...rest} />
    </AppContainer>
  );
};

PublicRoute.propTypes = {
  isSpace: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.instanceOf(Object)]),
};

export default PublicRoute;
