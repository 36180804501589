import React from 'react';
import { Grid } from '@mui/material';
import './InsightContent.scss';

import DefaultImage from '../images/Login-image.png';

function InsightContext() {
  return (
    <Grid item xs={12} sm={6} md={6} className="right-column">
      <div className="insight-context">
        <div className="background">
          <img src={DefaultImage} alt="insight" />
          {/* <div className="content">
            <p className="title">
              5 tips to boost engagement <br />
              in your space
            </p>
            <a
              className="btn btn-primary"
              href="https://www.spce.com/productutorials/boost-engagement-in-your-space/"
              target="_blank"
              rel="noreferrer" // Add rel="noreferrer" attribute
            >
              LEARN MORE
            </a>
          </div> */}
        </div>
      </div>
    </Grid>
  );
}

export default InsightContext;
