import React from 'react';
import ButtonEnhanced from '../../components/ButtonEnhanced';
import i18n from '../../i18n';
import { useGlobalResourcesContext } from '../context/GlobalResourcesContextProvider';
import MoveGlobalResourcesDialog from '../MoveGlobalResourcesDialog';

import { getSelectedResourceIds } from './utils';
import { IFolder } from '../context/Interfaces';

interface MoveButtonProps {
  onMoveSuccess: () => void;
  selectedFolder: IFolder | null;
}

function MoveButton(props: MoveButtonProps) {
  const { onMoveSuccess, selectedFolder } = props;
  const { selectedResources } = useGlobalResourcesContext();

  async function openMoveResourcesDialog(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();
    const currentFolderId = selectedFolder?.id;
    const currentFolderParentId = selectedFolder?.parentId;
    if (!currentFolderId) {
      return null;
    }

    const { resourceIds, directoryIds } = getSelectedResourceIds(selectedResources);
    let messagePrefix = '';
    if (selectedResources.length > 1) {
      messagePrefix = `${selectedResources.length} items`;
    } else if (selectedResources.length === 1) {
      messagePrefix = `"${selectedResources[0]?.name}"` || '';
    }

    const isSuccess = await MoveGlobalResourcesDialog.show({
      currentFolderId: currentFolderId,
      currentFolderParentId: currentFolderParentId,
      resourceIds: resourceIds,
      directoryIds: directoryIds,
      messagePrefix: messagePrefix,
    });
    if (isSuccess) {
      onMoveSuccess();
    }
    return null;
  }

  return (
    <>
      <ButtonEnhanced
        onClick={openMoveResourcesDialog}
        className="button"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore:next-line
        endIcon={<span className="icon icon-move" />}
      >
        {i18n.t('Move')}
      </ButtonEnhanced>
    </>
  );
}

export default React.memo(MoveButton);
