import RuntimeApi from '../commons/RuntimeApi';
import Api from '../commons/api';
import { clearCache, CacheKeys } from '../app/queryCache';
import { getIntegrationApi, getIntegrationRuntimeApi } from '../commons/utils';
import { createIntegrationResourceUrl } from './integrationResourceUtils';
import i18n from '../i18n';

export async function getIntegrationConnections(isPortal = true) {
  try {
    if (isPortal) {
      const resp = await Api.get(`${getIntegrationApi()}/api/connections`);
      return resp.items;
    } else {
      const resp = await RuntimeApi.get(`${getIntegrationRuntimeApi()}/api/runtime/connections`);
      return resp.items;
    }
  } catch (error) {
    console.log('ERROR getIntegrationConnections', error);
    return [];
  }
}

// export async function getUserIntegrationConnections(userInfoId, serviceProvider, isPortal) {
//   try {
//     if (isPortal) {
//       const resp = await Api.get(
//         `${getIntegrationApi()}/api/connections/user-connections?ServiceProvider=${serviceProvider}&userInfoId=${userInfoId}`
//       );
//       return resp.items;
//     }
//     const resp = await RuntimeApi.get(
//       `${getIntegrationRuntimeApi()}/api/runtime/connections/user-connections?ServiceProvider=${serviceProvider}&userInfoId=${userInfoId}`
//     );
//     return resp.items;
//   } catch (error) {
//     console.log('ERROR getIntegrationConnections', error);
//     return [];
//   }
// }

// // ToDo: move to runtime service
// export async function getRuntimeIntegrationConnections() {
//   try {
//     const resp = await RuntimeApi.get(`${getIntegrationRuntimeApi()}/api/runtime/connections`);
//     return resp.items;
//   } catch (error) {
//     console.log('ERROR getRuntimeIntegrationConnections', error);
//     return [];
//   }
// }

export function clearConnectionsCache() {
  clearCache([CacheKeys.getIntegrationConnections], false);
  clearCache([CacheKeys.getUserIntegrationConnections], false);
  clearCache([CacheKeys.getRuntimeIntegrationConnections], false);
}

export async function addIntegrationConnection(data, keepConnectionsCache) {
  try {
    const resp = await Api.post(`${getIntegrationApi()}/api/connections`, data);
    if (!keepConnectionsCache) {
      clearConnectionsCache();
    }

    return resp;
  } catch (ex) {
    return {
      isSuccess: false,
      reason: i18n.t('Sorry, something went wrong. Please try again!'),
    };
  }
}

export async function removeIntegrationConnection(serviceProvider) {
  const resp = await Api.delete(`${getIntegrationApi()}/api/connections`, { serviceProvider });
  clearConnectionsCache();
  return resp;
}

export async function getExternalResource(serviceProvider, channelId, fileId) {
  const response = await RuntimeApi.fetch(
    `${getIntegrationRuntimeApi()}/api/runtime/common/attachments?serviceProvider=${serviceProvider}&channelId=${channelId}&fileId=${fileId}`
  );
  if (!response) {
    return null;
  }
  const blob = await response.blob();
  return {
    url: URL.createObjectURL(blob),
  };
}

// export function getExternalFileToken(provider, downloadUrl) {
//   return RuntimeApi.get(
//     `${getIntegrationRuntimeApi()}/api/runtime/common/external-files/${provider}/token?downloadUrl=${downloadUrl}`
//   );
// }

export async function getIntegrationResourceUrl(sisResourceUrl, isPortal) {
  let url = createIntegrationResourceUrl(sisResourceUrl, isPortal);
  const apiHost = getIntegrationRuntimeApi();
  if (url.includes('?')) {
    url = `${url}&integrationApi=${apiHost}`;
  } else {
    url = `${url}?integrationApi=${apiHost}`;
  }
  if (isPortal) {
    return Api.get(url);
  }

  return RuntimeApi.get(url);
}
