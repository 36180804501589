import React from 'react';
import styled from '@emotion/styled';

import Loading from './Loading';

import BloggingImage from '../images/blogging.jpg';
import { themeOptions } from '../theme';

const defaultProps = {
  backgroundColor: themeOptions.palette.primary.main,
  textColor: '#fff',
  children: null,
  showLoading: true,
  fullScreen: false,
  iconColor: '#fff',
  backgroundOpacity: 0.7,
};

const BlockUI = (props) => {
  const {
    fullScreen,
    display,
    backgroundColor,
    textColor,
    transitionSrc,
    iconColor,
    backgroundOpacity,
    showLoading,
    children,
  } = {
    ...defaultProps,
    ...props,
  };
  if (!display) {
    return null;
  }

  function renderLoading() {
    if (transitionSrc === undefined) {
      return <Loading color={iconColor} style={{ position: 'absolute' }} />;
    }
    return (
      <div className="loading-image">
        <img src={transitionSrc || BloggingImage} style={{ height: '100%' }} alt="background" />
        <div className="loading-icon">
          <Loading color={iconColor} />
        </div>
      </div>
    );
  }

  return (
    <Container
      fullScreen={fullScreen}
      textColor={textColor}
      backgroundColor={backgroundColor}
      backgroundOpacity={backgroundOpacity}
      className="block-ui"
    >
      {showLoading && renderLoading()}
      {children}
    </Container>
  );
};

const Container = styled.div`
  position: ${(props) => (props.fullScreen ? 'fixed' : 'absolute')};
  width: 100%;
  height: 100%;
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  z-index: 1000;
  opacity: ${(props) => props.backgroundOpacity};
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .circular-progress {
    margin: auto;
    position: absolute;
  }

  span {
    margin-top: 50px;
    z-index: 10;
  }

  .loading-image {
    position: relative;
    height: 100%;
    overflow: hidden;

    .loading-icon {
      position: absolute;
      top: 47%;
      left: 48%;
    }
  }
`;

export default BlockUI;
