import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LightTooltip from './LightTooltip';
import i18n from '../i18n';
import Badge from './Badge';

import './FloatingMenu.scss';
import { SidebarType } from '../app/appConstants';

const FloatingMenu = ({
  features,
  className,
  position,
  isMobile,
  sidebar,
  onClick,
  newNotifications,
}) => {
  const isFeatureEnabled = useMemo(() => {
    if (features === undefined) return true;
    return Object.values(features)?.some((feature) => feature);
  }, [features]);

  if (!isFeatureEnabled) {
    return null;
  }

  const handleOnClick = (type) => {
    if (onClick) {
      onClick(type);
    }
  };

  return (
    <Box
      className={`space-footer ${className} position-${position} ${isMobile ? 'mobile-mode' : ''}`}
    >
      <Box className="runtime-footer-left">
        {features?.Participants && (
          <Box
            className={`square-button${sidebar === SidebarType.Participants ? ' active' : ''}`}
            onClick={() => handleOnClick(SidebarType.Participants)}
          >
            <LightTooltip title={i18n.t('Users')} placement="top-start">
              <Badge badgeContent={newNotifications?.Participants?.total}>
                <i className="icon-participants"></i>
              </Badge>
            </LightTooltip>
          </Box>
        )}
        {features?.Resources && (
          <Box
            className={`square-button${sidebar === SidebarType.Material ? ' active' : ''}`}
            onClick={() => handleOnClick(SidebarType.Material)}
          >
            <LightTooltip title={i18n.t('Resources')} placement="top-start">
              <Badge badgeContent={newNotifications?.Resources?.total}>
                <i className="icon-resources"></i>
              </Badge>
            </LightTooltip>
          </Box>
        )}

        {features?.Chat && (
          <Box
            className={`square-button chat-button ${sidebar === SidebarType.Chat ? ' active' : ''}`}
            onClick={() => handleOnClick(SidebarType.Chat)}
          >
            <LightTooltip title={i18n.t('Chat')} placement="top-start">
              <Badge badgeContent={newNotifications?.Chats?.total}>
                <i className="icon-chat"></i>
              </Badge>
            </LightTooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

FloatingMenu.propTypes = {
  className: PropTypes.string,
  features: PropTypes.instanceOf(Object),
  newNotifications: PropTypes.instanceOf(Object),
  position: PropTypes.string,
  isMobile: PropTypes.bool,
  sidebar: PropTypes.string,
  onClick: PropTypes.func,
};

export default FloatingMenu;
