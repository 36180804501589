import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';
import ConfirmService from './ConfirmService';

function validatePowerPointFile(file) {
  const maxSize = 50;
  const pPtFileTypes = [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ];
  if (pPtFileTypes.includes(file.type)) {
    const sizeFile = file.size / 1024 / 1024;
    return sizeFile <= maxSize;
  }
  return true;
}

class FilePicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.openFilePicker = this.openFilePicker.bind(this);
    this.inputFileRef = React.createRef();
  }

  async handleFileChange(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ isProcessing: true });

    console.log('event.target.files: ', event.target.files);
    if (this.props.multiple || this.props.isFolder) {
      if (this.props.onChange) await this.props.onChange(event.target.files);
    } else {
      const file = event.target.files[0];
      if (this.props.validatePowerPointFile) {
        const isValidFile = validatePowerPointFile(file);
        if (!isValidFile) {
          // console.log('isValidFile: ', isValidFile);
          ConfirmService.show(
            'Oops! Your file is a little too big',
            'Please note that the Microsoft Powerpoint web service has a limit of 50Mb files today. Tips: Divide the presentation in multiple files and run them separately.',
            'ok',
            null,
            true,
            null,
            false
          );
        } else {
          if (this.props.onChange) await this.props.onChange(file);
        }
      } else {
        if (this.props.onChange) await this.props.onChange(file);
      }
    }

    if (this.inputFileRef.current) {
      this.inputFileRef.current.value = null;
    }
    this.setState({ isProcessing: false });
  }

  renderContent() {
    if (this.state.isProcessing) {
      return <Loading />;
    }
    return this.props.children;
  }

  openFilePicker() {
    this.inputFileRef.current.click();
  }

  onClick() {
    // console.log('this.inputFileRef.current', this.inputFileRef.current);
    if (this.props.disableClickArea) return;
    this.openFilePicker();
    // if (this.props.onCancel) {
    //   this.props.onCancel();
    // }
  }
  componentDidMount() {
    if (this.props.isFolder) {
      this.inputFileRef.current.setAttribute('webkitdirectory', 'webkitdirectory');
      this.inputFileRef.current.setAttribute('mozdirectory', 'mozdirectory');
      this.inputFileRef.current.setAttribute('directory', 'directory');
    }
  }

  render() {
    return (
      <>
        <div className="file-picker" onClick={this.onClick}>
          {this.renderContent()}
        </div>
        <input
          onChange={this.handleFileChange}
          ref={this.inputFileRef}
          type="file"
          accept={this.props.accept}
          style={{ display: 'none' }}
          multiple={this.props.multiple}
        />
      </>
    );
  }
}

FilePicker.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  validatePowerPointFile: PropTypes.bool,
  disableClickArea: PropTypes.bool,
  isFolder: PropTypes.bool,
};

FilePicker.defaultProps = {
  accept: '*',
  multiple: false,
  validatePowerPointFile: true,
  disableClickArea: false,
  isFolder: false,
};

export default FilePicker;
