import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

import defaultBackgroundImage from '../images/cms/default-preview.jpeg';

import './BackgroundSpace.scss';
import Loading from './Loading';
import { replaceBlobUrl } from '../commons/ResourceUtils';

function formatSrc(src) {
  if (!src) return '';
  if (src.startsWith('/static/')) {
    return `${window.location.origin}${src}`;
  }
  let url = replaceBlobUrl(src);
  try {
    url = new URL(url);
    url.searchParams.append('w', '300');
    return url.toString();
  } catch (e) {
    console.error(e);
    return src;
  }
}

const BackgroundSpace = ({ src, className, title }) => {
  const ref = useRef();

  const getThumbnailQuery = useQuery({
    queryKey: ['space-thumbnail', src],
    queryFn: async () => {
      const headers = {};
      const url = formatSrc(src);
      const res = await fetch(url, {
        headers,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        redirect: 'follow',
      });

      if (!res?.ok) {
        throw Error('Could not fetch image');
      }

      // console.log('### 3011: ', thumbnail, extension, headers, res);
      const blob = await res.blob();
      const imgBitmap = await createImageBitmap(blob);
      const canvas = document.createElement('canvas');
      let width = ref.current?.offsetWidth ? ref.current.offsetWidth : 240;
      if (width < 195) {
        width = 195;
      }
      let height = (imgBitmap.height * width) / imgBitmap.width;
      if (height < 125) {
        height = 125;
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(imgBitmap, 0, 0, imgBitmap.width, imgBitmap.height, 0, 0, width, height);
      return canvas.toDataURL('image/png');
    },
    retry: 1,
    retryDelay: 3000,
    enabled: !!src,
  });

  function renderImg() {
    if (!src) {
      return (
        <img
          className={`thumbnail ${className || ''}`}
          alt="default background"
          title={title}
          src={defaultBackgroundImage}
        />
      );
    }

    if (getThumbnailQuery.isFetching) {
      return <Loading className="background-space-loading" />;
    }

    if (getThumbnailQuery.isError || !getThumbnailQuery.data) {
      return <img className={`thumbnail ${className || ''}`} alt="" title={title} />;
    }

    return (
      <img
        className={`thumbnail ${className || ''}`}
        alt=""
        title={title}
        src={getThumbnailQuery.data}
      />
    );
  }

  return (
    <div className="background-space" ref={ref}>
      {renderImg()}
    </div>
  );
};

BackgroundSpace.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  // height: PropTypes.number,
  // alt: PropTypes.string,
};

export default BackgroundSpace;
