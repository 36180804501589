import React from 'react';
import { Box } from '@mui/material';
import './ChatMessageTable.scss';
import { ISpaceUser } from '../../types/User';
import { ChatSummaryDetails } from '../queries/useSpaceChatSummaryQuery';
import Loading from '../../components/Loading';
import SpaceUserAvatar from '../components/SpaceUserAvatar';
import LightTooltip from '../../components/LightTooltip';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import { SpaceUserDetailsTab } from '../SpaceUserDetailsModal';
import i18n from '../../i18n';
import Table from '../components/Table';
import { Ids } from '../../commons/pendoTaggings';

function convertToUserInfo(userInfo: ChatSummaryDetails): ISpaceUser {
  return {
    id: userInfo.userInfoId,
    userInfoId: userInfo.userInfoId,
    firstName: userInfo.firstName,
    orgRole: userInfo.orgRole,
    lastName: userInfo.lastName,
    email: userInfo.email,
    fullName: userInfo.fullName,
    isOrganizationUser: userInfo.isOrganizationUser,
    role: userInfo.role,
  };
}

type ChatMessagesTableProps = {
  chatMessageDetails?: ChatSummaryDetails[];
};

function ChatMessagesTable(props: ChatMessagesTableProps) {
  const { chatMessageDetails: chats } = props;
  const { openSpaceUserModal } = useSpaceAnalyticsContext();

  function handleOnClickLink(chatSummary: ChatSummaryDetails) {
    const userInfo = convertToUserInfo(chatSummary);
    openSpaceUserModal(userInfo, SpaceUserDetailsTab.CHATS);
  }

  const renderContent = () => {
    if (!chats || chats.length === 0) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }

    return (
      <Table
        columns={[
          {
            title: 'Name',
            field: 'fullName',
            cellStyle: {
              width: '70%',
            },
            type: 'string',
            sorting: false,
            render: (rowData: ChatSummaryDetails) => {
              const userInfo: ISpaceUser = {
                id: rowData.userInfoId,
                userInfoId: rowData.userInfoId,
                firstName: rowData.firstName,
                orgRole: rowData.orgRole,
                lastName: rowData.lastName,
                email: rowData.email,
                fullName: rowData.fullName,
                isOrganizationUser: rowData.isOrganizationUser,
                role: rowData.role,
              };
              console.log('userInfo8', userInfo);
              return <SpaceUserAvatar userInfo={userInfo} />;
            },
          },

          {
            title: 'Messages',
            field: 'totalActiveChat',
            type: 'numeric',
            align: 'left',
            cellStyle: {
              width: '30%',
              fontFamily: 'Roboto',
            },
            defaultSort: 'desc',
            customSort: (a: ChatSummaryDetails, b: ChatSummaryDetails) => {
              const aNum = a.totalActiveChat || 0;
              const bNum = b.totalActiveChat || 0;

              if (!a.isJoinedSpace) {
                if (!b.isJoinedSpace) {
                  return aNum - bNum;
                }
                return -1;
              }

              if (!b.isJoinedSpace) {
                return 1;
              }
              return aNum - bNum;
            },

            render: (rowData: ChatSummaryDetails) => {
              return !rowData.isJoinedSpace ? (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <LightTooltip title={i18n.t("The user hasn't joined the space yet")}>
                  <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
                </LightTooltip>
              ) : (
                <span
                  className="btn-link"
                  onClick={() => handleOnClickLink(rowData)}
                  data-id={Ids.chatUserTotalMeetings}
                >
                  {rowData.totalActiveChat}
                </span>
              );
            },
          },
        ]}
        data={chats}
      />
    );
  };

  return (
    <Box className="chat-messages-table">
      <Box>{renderContent()}</Box>
    </Box>
  );
}

ChatMessagesTable.propTypes = {};

export default ChatMessagesTable;
