import { ResourceType } from '../../app/appConstants';
import { ISpaceUser } from '../../types/User';
import { SpaceSummaryResponse } from './common';

export type ResourceMetrics = {
  downloads: number;
  shares: number;
  clicks: number;
  viewTimeInSeconds: number;
};

export type ResourceMetricDetails = ResourceMetrics & {
  pageRead?: number;
  totalPages?: number;
  lastClick?: Date;
  lastShare?: Date;
  lastDownload?: Date;
};

export type SpaceResourceMetrics = ResourceMetrics & {
  materialId: number;
  userInfoId: number;
};

export type ResourcesSummary = SpaceSummaryResponse<ResourceMetrics> & {
  summary: ResourceMetrics;
};

export type AllResourceMetricsResponse = {
  data: SpaceResourceMetrics[];
};

export type MaterialBasicInfo = {
  materialId: number;
  resourceId: number;
  resourceName: string;
  resourceType: number;
  extension: string;
  src: string;
  isExternal: boolean;
  isViewNotSupported: boolean;
  isCmsFile: boolean;
};

export type ResourceMetricsDataItem<TData> = SpaceResourceMetrics &
  TData & {
    tag?: string;
  };

export type ResourceParticipantMetrics = SpaceResourceMetrics & ISpaceUser;

export type BoolStringCombination = [boolean, string];
export type BoolNumberCombination = [boolean, number];

export const tagViewTimeSupportedList: BoolStringCombination[] = [
  // [IsCMSFile, CMS TagName]
  [true, 'file'],
  [true, 'video'],
];

export const typeViewTimeNotSupportedList: BoolNumberCombination[] = [
  // [IsCMSFile, ResourceType]
  [true, ResourceType.externalLink],
  [false, ResourceType.externalLink],
  [true, ResourceType.font],
  [false, ResourceType.font],
  [true, ResourceType.zip],
  [false, ResourceType.zip],
  [false, ResourceType.webSharing],
  [true, ResourceType.webSharing],
  // etc
];
