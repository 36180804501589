import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import i18n from '../../i18n';
import GlobalResourcesSelectDialog from '../../globalResources/GlobalResourcesSelectDialog';
import { GRSelectType } from '../../globalResources/GlobalResourcesPicker';

function addNewResources(newResources, globalResources) {
  const totalResources = [...globalResources];
  newResources.forEach((resource) => {
    if (!resource.externalId) {
      const found = totalResources.find((x) => x.resourceId === resource.resourceId);
      if (!found) {
        totalResources.push(resource);
      }
    } else {
      const found = totalResources.find((x) => x.externalId === resource.externalId);
      if (!found) {
        totalResources.push(resource);
      }
    }
  });
  return totalResources;
}

const GlobalResourceButton = forwardRef((props, ref) => {
  const {
    className,
    resources,
    multiple,
    accept,
    isPortal,
    hideIntegrationGlobalResource,
    onGlobalResourcesDialogOpened,
    onGlobalResourcesDialogClosed,
    handleResourcesChanged,
    isAdmin,
    disabledUploadExternalFiles,
  } = props;
  const handleToPickGlobalResources = async () => {
    const { selectedResources } = await GlobalResourcesSelectDialog.show(
      multiple,
      accept,
      isPortal,
      false,
      onGlobalResourcesDialogOpened,
      hideIntegrationGlobalResource,
      isAdmin,
      GRSelectType.File,
      false,
      false,
      disabledUploadExternalFiles
    );

    if (onGlobalResourcesDialogClosed) {
      onGlobalResourcesDialogClosed();
    }

    if (!selectedResources?.length) return;

    let globalResources = [...selectedResources];
    globalResources = globalResources.map((x) => {
      return {
        fileName: x.resourceName || x.name,
        fileDescription: x.description || '',
        name: x.name || x.resourceName,
        resourceType: x.resourceType,
        orgSrc: x.orgSrc,
        src: x.src,
        brandingSrc: x.brandingSrc,
        resourceId: x.resourceId,
        resourceFolderId: x.resourceFolderId,
        isExternal: x.isExternal,
        isGlobal: true,
        settings: x.settings,
        externalId: x.id,
        size: x.size,
      };
    });

    // update state
    console.log('### newResources', resources);
    console.log('### globalResources', globalResources);
    let newResources;
    if (multiple) {
      newResources = _cloneDeep(resources);
      newResources = addNewResources(newResources, globalResources);
    } else {
      newResources = [globalResources[0]];
    }
    handleResourcesChanged(newResources);
  };

  useImperativeHandle(ref, () => ({
    openDialog: handleToPickGlobalResources,
  }));

  return (
    <a
      className={className}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        handleToPickGlobalResources();
      }}
    >
      {i18n.t('add from global resources')}
    </a>
  );
});

GlobalResourceButton.propTypes = {
  className: PropTypes.string,
  resources: PropTypes.instanceOf(Array),
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  isPortal: PropTypes.bool,
  hideIntegrationGlobalResource: PropTypes.bool,
  onGlobalResourcesDialogOpened: PropTypes.func,
  onGlobalResourcesDialogClosed: PropTypes.func,
  handleResourcesChanged: PropTypes.func,
  isAdmin: PropTypes.bool,
  disabledUploadExternalFiles: PropTypes.bool,
};

export default GlobalResourceButton;
