import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getAllPagesInTiffImage } from '../../commons/TiffUtils';

const useTiffFile = (url, resourceId = 0, cacheKey = null) => {
  // states
  const [pages, setPages] = React.useState([]);

  const mCacheKey = cacheKey || 'tiff-query';

  // console.log('### 712 useTiffFile: ', resourceId, url);

  const fetchQuery = useQuery({
    queryKey: [mCacheKey, resourceId],
    queryFn: async () => {
      const resp = await fetch(url);
      // console.log('### 712 resp: ', resp);
      if (!resp?.ok) {
        throw Error('Could not fetch tiff image');
      }
      const arrayBuffer = await resp.arrayBuffer();
      return arrayBuffer;
    },

    retry: 2,
    retryDelay: 3000,
    enabled: url?.length > 0,
  });

  useEffect(() => {
    const arrayBuffer = fetchQuery.data;
    if (arrayBuffer) {
      const items = getAllPagesInTiffImage(arrayBuffer);
      items.splice(1); // version 1: only take 1st page if has multiple pages.
      setPages(items || []);
    }
  }, [fetchQuery.data]);

  return { pages, tiffQuery: fetchQuery };
};

export default useTiffFile;
