import { useCallback, useEffect, useState } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import i18n from '../../i18n';

const usePinFolder = (settingsDirectoryId, updateBlock, blocks, rowId, id, selectedFolder) => {
  const {
    id: selectedFolderId,
    externalId,
    externalFolderSettings,
    spaceFolderId,
    isExternal,
    parentFolderId,
  } = selectedFolder;
  const [isPinned, setIsPinned] = useState(false);
  useEffect(() => {
    if (settingsDirectoryId !== null && settingsDirectoryId !== undefined) {
      setIsPinned(true);
    }
  }, [settingsDirectoryId]);

  const handlePinButtonClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (updateBlock) {
        const currentBlock = blocks?.find((row) => row.id === rowId);
        if (!currentBlock) return;
        const newBlock = _cloneDeep(currentBlock);
        const index = newBlock.items.findIndex((item) => item.id === id);
        if (index === -1) return;

        if (selectedFolderId) {
          // pin folder
          newBlock.items[index].settings.directoryId = selectedFolderId;
          if (isExternal) {
            newBlock.items[index].settings.folderSettings = {
              externalId: externalId,
              parentFolderId: parentFolderId,
              externalFolderSettings: externalFolderSettings,
              spaceFolderId: spaceFolderId,
            };
          }
        } else {
          // pin tab
          newBlock.items[index].settings.directoryId = null;
          newBlock.items[index].settings.folderSettings = null;
        }
        updateBlock(newBlock);
        setIsPinned(true);
      }
    },
    [
      updateBlock,
      blocks,
      selectedFolderId,
      rowId,
      id,
      isExternal,
      externalId,
      parentFolderId,
      externalFolderSettings,
      spaceFolderId,
    ]
  );

  const handleUnPinButtonClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (updateBlock) {
        const currentBlock = blocks?.find((row) => row.id === rowId);
        if (!currentBlock) return;
        const newBlock = _cloneDeep(currentBlock);
        const index = newBlock.items.findIndex((item) => item.id === id);
        if (index === -1) return;

        if (selectedFolderId) {
          // unpin folder
          newBlock.items[index].settings.directoryId = null;
          newBlock.items[index].settings.folderSettings = null;
        }

        updateBlock(newBlock);
        setIsPinned(false);
      }
    },
    [id, rowId, selectedFolderId, blocks, updateBlock]
  );

  function getPinTooltip() {
    let tooltip = i18n.t('Pin');
    if (selectedFolderId) {
      tooltip = i18n.t('Pin to always show this folder in the widget');
    }
    return tooltip;
  }

  function getUnpinTooltip(pinnedFolder) {
    let tooltip = i18n.t('Unpin');

    if (pinnedFolder) {
      tooltip = `${i18n.t('Unpin')} this folder`;
    }

    return tooltip;
  }

  return { isPinned, handlePinButtonClick, handleUnPinButtonClick, getPinTooltip, getUnpinTooltip };
};

export default usePinFolder;
