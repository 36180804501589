import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import i18n from '../i18n';
import ResponsiveDialog from '../components/ResponsiveDialog';
import './ResourceFormDialog.scss';
import OfficialButton from '../components/OfficialButtons';
import DndFolderUpload from '../components/DndFolderUploader';
import { buildFolderStructure } from '../components/DndFolderUploader/utils';
import { DefaultResourceAccept, UploadingFileState } from '../app/appConstants';
import { createGlobalResourcesDirectory } from './globalResourcesService';
import { sendNotification } from '../commons/utils';

const UploadFolderForm = (props) => {
  const uploaderRef = useRef();
  const { onClose, selectedFolderId } = props;
  const [isUploading, setIsUploading] = React.useState(false);
  const [folder, setFolder] = React.useState([]);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleResourceChange = useDebouncedCallback((data) => {
    console.log('resources', data);
    if (data) {
      const dataFolder = buildFolderStructure(data);
      setFolder(dataFolder);
    }
  }, 500);

  const handleUploadingStateChange = (status) => {
    let uploading = false;
    if (status <= UploadingFileState.Started) {
      uploading = true;
    }
    setIsUploading(uploading);
  };

  const handleAddFolder = async () => {
    console.log('submit');
    if (!folder || Object.keys(folder).length === 0) {
      return;
    }
    setIsProcessing(true);
    const newFolder = {
      ...folder,
      description: '',
      parentId: selectedFolderId,
      filesAndFolders: JSON.stringify(folder.filesAndFolders),
    };
    await createGlobalResourcesDirectory(newFolder);
    setIsProcessing(false);
    sendNotification(i18n.t('Created successfully'), { type: 'success' });
    uploaderRef?.current?.resetSessionIds();
    setTimeout(() => {
      onClose();
    }, 200);
  };

  return (
    <ResponsiveDialog
      className={`white upload-resources-dialog`}
      onClose={() => onClose([])}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div className="content">
        <div className="header">
          <span className="title">{i18n.t('Add folder')}</span>
        </div>
        <section className="uploader-container styled-scrollbar">
          <div className="uploader-area">
            <DndFolderUpload
              ref={uploaderRef}
              accept={DefaultResourceAccept}
              multiple={false}
              isPortal
              isAdmin
              isGlobal
              variant="light"
              spaceId="00000000-0000-0000-0000-000000000000"
              onChangeResources={handleResourceChange}
              onUploadingStateChange={handleUploadingStateChange}
            />
          </div>
        </section>
        <div className="submit-button">
          <OfficialButton
            label={i18n.t('Save')}
            disabled={isUploading}
            isProcessing={isProcessing}
            onClick={handleAddFolder}
            variant="large-primary"
          />
        </div>
      </div>
    </ResponsiveDialog>
  );
};

UploadFolderForm.propTypes = {
  selectedFolderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
};

export default UploadFolderForm;
