/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LightTooltip from '../LightTooltip';
import i18n from '../../i18n';
import ResourcePreviewDialog, {
  getResourcePreviewContext,
} from '../../resources/ResourcePreviewDialog';
import {
  cancelSessionsId,
  removeMaterial,
  removeRuntimeMaterial,
} from '../../resources/ResourceServices';
import { getErrorMessage } from './DndFileUploaderUtils';
import { useFeatureFlagsContext } from '../../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../../app/appConstants';
import eventBus, { EVENT_BUS } from '../../commons/EventBus';

const ListItem = ({
  resources,
  spaceId,
  folderId,
  isPortal,
  combinedResources,
  multiple,
  canPreview,
  canEditNameSameStep,
  allowEditNameInput,
  handleResourcesChanged,
  handleDeleteInvalidResourceClick,
  handleCancelEditResourceNameClick,
  inputResourceNameRef,
}) => {
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledMaterialSlider] = checkEnabledFeatures([FeatureFlagsType.MATERIAL_SLIDER]);

  const handleEditResourceClick = (resource) => {
    const newResources = _cloneDeep(resources);
    // check if one resource is on edit; if so, cancel it
    for (let idx = 0; idx < newResources.length; idx += 1) {
      const item = newResources[idx];
      if (item.isEditing) {
        // revert the change.
        const ref =
          inputResourceNameRef?.[item.resourceId] || inputResourceNameRef?.[item.externalId];
        if (ref) {
          ref.value = item.name;
        }
      }
      delete newResources[idx].isEditing;
      if (
        (item.resourceId && item.resourceId === resource.resourceId) ||
        (item.externalId && item.externalId === resource.externalId)
      ) {
        newResources[idx].isEditing = true;
      }
    }
    const callback = () => {
      const ref =
        inputResourceNameRef?.[resource.resourceId] || inputResourceNameRef?.[resource.externalId];
      if (ref) {
        ref.focus();
        ref.select();
        // ref.setSelectionRange(0, ref.value.length);
      }
    };
    handleResourcesChanged(newResources, callback);
  };

  const handleDeleteResourceClick = (removingResource) => {
    // console.log('### handleDeleteResourceClick', removingResource);
    let newResources = _cloneDeep(resources);
    newResources = newResources.filter((r) => {
      if (r.resourceId) {
        return r.resourceId !== removingResource.resourceId;
      } else {
        return r.externalId !== removingResource.externalId;
      }
    });

    handleResourcesChanged(newResources);
    if (removingResource.materialId) {
      const removeMaterialMethod = isPortal ? removeMaterial : removeRuntimeMaterial;
      removeMaterialMethod(spaceId, removingResource.materialId, folderId);
    }
    if (removingResource.sessionId) {
      cancelSessionsId([removingResource.sessionId]);
    }
  };

  const handleDoneEditResourceNameClick = (resource) => {
    const newResources = _cloneDeep(resources);
    const foundIndex = newResources.findIndex(
      (r) =>
        (r.resourceId && r.resourceId === resource.resourceId) ||
        (r.externalId && r.externalId === resource.externalId)
    );
    if (foundIndex >= 0) {
      newResources[foundIndex].isEditing = false;
      const ref =
        inputResourceNameRef?.[resource.resourceId] || inputResourceNameRef?.[resource.externalId];
      if (ref) {
        const newValue = ref.value?.trim();
        if (newValue?.length > 0) {
          newResources[foundIndex].name = newValue;
        }
        ref.blur();
      }
      handleResourcesChanged(newResources);
    }
  };

  const handleResourcePreview = (previewingResource) => {
    console.log('### preview', previewingResource);
    if (!canPreview || !previewingResource) return;

    const previewContext = isPortal
      ? getResourcePreviewContext('portal')
      : getResourcePreviewContext('space');

    ResourcePreviewDialog.show(previewingResource, previewContext, isEnabledMaterialSlider);
    eventBus.publish(EVENT_BUS.ToggleUploadResourcePopup, false);
  };

  const renderValidItem = (item) => {
    const key = item.resourceId ?? item.externalId;
    const showEditName = canEditNameSameStep || allowEditNameInput;
    return (
      <TextField
        key={key}
        id="file-input"
        name="resource-name"
        inputRef={(ref) => {
          if (item.resourceId) {
            inputResourceNameRef[item.resourceId] = ref;
          } else if (item.externalId) {
            inputResourceNameRef[item.externalId] = ref;
          }
        }}
        defaultValue={item.name}
        autoComplete="off"
        className={`dnd-file-uploader-control-input ${
          canPreview && item.isValid !== false && item.name?.length > 0 ? 'text-underline' : ''
        }`}
        variant="outlined"
        placeholder="Rename resource"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (item.isEditing) return;
          // console.log('### onClick to preview', resource);
          const resourceToPreview = {
            resourceId: item.resourceId,
            src: item.src,
            name: item.name,
            resourceType: item.resourceType,
          };
          handleResourcePreview(resourceToPreview);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!item.isEditing && (
                <div className="action-buttons">
                  {showEditName && (
                    <IconButton
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleEditResourceClick(item);
                      }}
                    >
                      <EditIcon className="edit-icon" />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      handleDeleteResourceClick(item);
                    }}
                  >
                    <DeleteIcon className="delete-icon" />
                  </IconButton>
                </div>
              )}
              {!!item.isEditing && (
                <div className="action-buttons">
                  <Button
                    className="action-button"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      handleCancelEditResourceNameClick(item);
                    }}
                  >
                    {i18n.t('Cancel')}
                  </Button>
                  <Button
                    className="action-button"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      handleDoneEditResourceNameClick(item);
                    }}
                  >
                    {i18n.t('Save')}
                  </Button>
                </div>
              )}
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const renderInvalidItem = (item, index) => {
    const showError = !!item.validationResult;
    const errText = showError
      ? getErrorMessage({ file: item.validationResult }, 'file', multiple)
      : '';
    return (
      <section className="invalid-item" key={index}>
        <section className="top">
          <span className="name">{item.name}</span>
          <div className="action-buttons">
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleDeleteInvalidResourceClick(item);
              }}
            >
              <DeleteIcon className="delete-icon" />
            </IconButton>
          </div>
        </section>
        {showError && (
          <section className="bottom">
            <LightTooltip
              title={errText}
              placement="bottom-start"
              // classes={{ tooltip: '' }}
            >
              <span className="form-warning ellipsis">{errText}</span>
            </LightTooltip>
          </section>
        )}
      </section>
    );
  };

  return (
    combinedResources.length > 0 && (
      <section className={`uploaded-files-section ${multiple ? 'marginTop16' : ''}`}>
        {combinedResources.map((resource, index) => {
          if (resource.isValid === false) {
            return renderInvalidItem(resource, index);
          }
          return renderValidItem(resource);
        })}
      </section>
    )
  );
};

ListItem.propTypes = {
  resources: PropTypes.instanceOf(Array),
  combinedResources: PropTypes.instanceOf(Array),
  isPortal: PropTypes.bool,
  multiple: PropTypes.bool,
  canPreview: PropTypes.bool,
  canEditNameSameStep: PropTypes.bool,
  allowEditNameInput: PropTypes.bool,
  spaceId: PropTypes.string,
  folderId: PropTypes.number,
  handleResourcesChanged: PropTypes.func,
  handleDeleteInvalidResourceClick: PropTypes.func,
  handleCancelEditResourceNameClick: PropTypes.func,
  inputResourceNameRef: PropTypes.instanceOf(Object),
};

export default ListItem;
