import { formatDateTime } from '../../commons/DateTimeUtils';
import { GLOBAL_RESOURCE_TYPE } from '../../globalResources/utils';
import { SERVICE_PROVIDER } from '../integrationConstants';

export const formatOneDriveResources = (data, acceptResourceTypes) => {
  return data.map((item, index) => {
    let allowSelectedType = true;
    if (!item.isFolder) {
      allowSelectedType =
        acceptResourceTypes === undefined ? true : acceptResourceTypes.includes(item.resourceType);
    }
    return {
      ...item,
      index,
      displayDateTime: formatDateTime(item.lastModified, 'MMM DD, YYYY'),
      name: item.name || item.resourceName || 'Untitled',
      type: item.isFolder ? GLOBAL_RESOURCE_TYPE.FOLDER : GLOBAL_RESOURCE_TYPE.RESOURCE,
      description: item.description || item.resourceDescription,
      allowSelectedType: allowSelectedType,
      provider: SERVICE_PROVIDER.ONEDRIVE,
    };
  });
};

export const ONEDRIVE_THUMBNAIL_SIZE = {
  Small: 1,
  Medium: 2,
  Large: 3,
  Source: 4,
};

export function mapOneDriveItemToResource(driveItem) {
  if (!driveItem) return {};
  return {
    id: driveItem.id,
    description: driveItem.name,
    resourceName: driveItem.name,
    name: driveItem.name,
    fileName: driveItem.name,
    src: driveItem.downloadUrl,
    orgSrc: driveItem.downloadUrl,
    resourceType: driveItem.resourceType,
    lastModified: driveItem.lastModified,
    isExternal: false,
    extension: driveItem.extension,
    driveId: driveItem.driveId,
    type: GLOBAL_RESOURCE_TYPE.RESOURCE,
    size: driveItem.size,
    settings: {
      external: {
        provider: SERVICE_PROVIDER.ONEDRIVE,
        id: driveItem.id,
        parentId: driveItem.parentId,
        rootParentId: driveItem.driveId,
      },
    },
  };
}

const getRootChannelData = (folder) => {
  let parent = folder;
  while (parent.parent) {
    parent = parent.parent;
  }

  return { teamId: parent.teamId, channelId: parent.channelId };
};

export function createRootFolderModels(selectedFolders) {
  return selectedFolders.map((folder) => {
    const rootData = getRootChannelData(folder);
    return {
      id: folder.id,
      driveId: folder.driveId,
      // parentId: folder.parentId,
      name: folder.name,
      lastModified: folder.lastModified,
      isFolder: true,
      driveType: folder.driveType,
      // location: populatePath(folder),
      teamId: rootData.teamId,
      channelId: rootData.channelId,
      isRoot: !folder.parent,
    };
  });
}

export const mapOneDriveSortBy = (originalSortBy) => {
  switch (originalSortBy) {
    case 'fileName':
      return 'name';
    case 'lastModified':
      return 'lastModifiedDateTime';
    case 'itemsCount':
      return 'folder';
    default:
      return 'name';
  }
};
