// Type of file is missing when using the drag and drop feature.
const getTypeOfFile = (file) => {
  const types = {
    '.avi': 'video/avi',
    '.mov': 'video/quicktime',
  };
  const keyOfTypes = Object.keys(types);
  const typeOfFile = keyOfTypes.find((key) => file.name.endsWith(key));
  if (typeOfFile) {
    return types[typeOfFile];
  }
  return file.type;
};

const readEntryContentAsync = async (entry) => {
  return new Promise((resolve) => {
    let reading = 0;
    const contents = [];

    reading += 1;
    entry.file(async (file) => {
      reading -= 1;

      const rawFile = file;
      if (rawFile.type === '' && getTypeOfFile(rawFile)) {
        rawFile.typeFile = getTypeOfFile(rawFile);
      }
      // remove / from the beginning of the full path
      rawFile.path = entry.fullPath.replace(/^\//, '');

      contents.push(rawFile);
      if (reading === 0) {
        resolve(contents);
      }
    });
  });
};
// Wrap readEntries in a promise to make working with readEntries easier
const readEntriesPromise = async (directoryReader) => {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.error(err);
    return [];
  }
};

const readAllDirectoryEntries = async (directoryReader) => {
  const entries = [];
  let readEntries = await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    // eslint-disable-next-line no-await-in-loop
    readEntries = await readEntriesPromise(directoryReader);
  }
  return entries;
};
export const getSelectedFolders = (dataTransferItemList) => {
  const folders = [];
  const files = [];
  for (let i = 0; i < dataTransferItemList.length; i += 1) {
    const entry = dataTransferItemList[i].webkitGetAsEntry();
    if (entry.isDirectory) {
      folders.push(entry);
    } else {
      files.push(entry);
    }
  }
  return { folders, files };
};

// Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
export const getAllFileEntries = async (dataTransferItemList) => {
  const fileEntries = [];
  // Use BFS to traverse entire directory/file structure
  const queue = [];
  // Unfortunately dataTransferItemList is not iterable i.e. no forEach
  for (let i = 0; i < dataTransferItemList.length; i += 1) {
    queue.push(dataTransferItemList[i].webkitGetAsEntry());
  }
  while (queue.length > 0) {
    const entry = queue.shift();
    if (entry.isFile) {
      fileEntries.push(entry);
    } else if (entry.isDirectory) {
      const reader = entry.createReader();
      // eslint-disable-next-line no-await-in-loop
      const waiter = await readAllDirectoryEntries(reader);
      queue.push(...waiter);
    }
  }
  return Promise.all(fileEntries.map((entry) => readEntryContentAsync(entry)));
};
