import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import './Tabs.scss';
import BadgeCustom from './Badge';
import LightTooltip from './LightTooltip';

const convertString = (str, className = 'tabs') => {
  const value = `${className} ${str}`;
  return value?.replace(/\s/g, '-').toLowerCase();
};

const getTabs = memoizeOne((tabs, activeIndex, disabledTabs = [], toolTipMessage = '') => {
  return tabs.map((label, i) => {
    return {
      label,
      active: i === activeIndex,
      disabled: disabledTabs.includes(label),
      tooltip: disabledTabs.includes(label) ? toolTipMessage : '',
    };
  });
});

export const useTabs = (
  initialTabs,
  defaultActiveIndex = 0,
  disabledTabs = [],
  toolTipMessage = ''
) => {
  const [tabs, setTabs] = React.useState(initialTabs);
  const [activeTab, setActiveTab] = React.useState(defaultActiveIndex);

  const changeTab = React.useCallback((index) => {
    setActiveTab(index);
  }, []);

  return {
    tabs: getTabs(tabs, activeTab, disabledTabs, toolTipMessage),
    changeTab,
    activeTab,
    setTabs,
    setActiveTab,
  };
};
const Tabs = ({ tabs, onChange, variant = 'dark' }) => {
  return (
    <ul className={`tabs ${variant}`}>
      {tabs?.map((tab, i) => (
        <LightTooltip title={tab.tooltip || ''} key={i}>
          <li
            key={i}
            className={`tab-item ${tab.active ? 'active' : ''} ${tab.disabled ? 'disabled' : ''}${
              !tab.label ? 'tab-item-hidden' : ''
            }`}
          >
            <div
              className="tab-wrapper"
              data-id={convertString(tab.label, variant)}
              onClick={tab.disabled ? null : () => onChange(i)}
            >
              <span className="tab-label">{tab.label}</span>
              {!!tab.total && <BadgeCustom badgeContent={tab.total} color="secondary" />}
            </div>
          </li>
        </LightTooltip>
      ))}
    </ul>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  variant: PropTypes.string,
};

export default Tabs;
