/* eslint no-useless-escape: 0 */
import moment from 'moment';
import _, { isString } from 'lodash';
import memoizeOne from 'memoize-one';
import ReactPlayer from '@spce/react-player/lazy';
import {
  ResourceType,
  ResourceTypeNames,
  ResourceState,
  ConvertToPDFResourceTypes,
  ResourceTypeDisplayNames,
  DirectoryType,
  SupportedExtensions,
  SupportedExtensionsByCategory,
} from '../app/appConstants';
import { getBlobDomain, getMediaKindProxyUrl, getPathFromUrl } from './utils';
import { getMaterialResource, getResourceForViewer } from '../resources/ResourceServices';
import {
  isIntegrationResource,
  downloadIntegrationResource,
  getIntegrationResourceDetails,
  shouldConvertIntegrationResourceToPDF,
  getIntegrationFolderDetails,
} from '../integrations/integrationResourceUtils';
import { SERVICE_PROVIDER } from '../integrations/integrationConstants';
import { downloadRoiFile } from '../components/ROICalculation/service';
import { downloadCertificateFile } from '../assessments/services';

export function validateFileExtension(accept, extension) {
  // console.log('validateFileExtension', accept, extension);
  // const fileName = file?.name;
  // const index = fileName?.lastIndexOf('.');
  // if (!index || index < 0) return false;

  // const extension = fileName.toLowerCase().substring(index);
  if (!accept || accept === '*') {
    const isOk1 = SupportedExtensions.includes(extension);
    return isOk1;
  }

  const parts = accept.split(',').map((x) => x.trim().toLowerCase());
  let extensions = []; // supporting
  parts.forEach((e) => {
    if (e === 'audio/*') {
      extensions = extensions.concat(SupportedExtensionsByCategory.audio);
    } else if (e === 'video/*') {
      extensions = extensions.concat(SupportedExtensionsByCategory.video);
    } else if (e === 'image/*') {
      extensions = extensions.concat(SupportedExtensionsByCategory.image);
    } else if (e === '.jpg' || e === '.jpeg') {
      // dont have image/jpg and image/jpeg, pls refer to https://www.iana.org/assignments/media-types/media-types.xhtml
      extensions.push('.jpg');
      extensions.push('.jpeg');
    } else if (e === 'image/png' || e === '.png') {
      extensions.push('.png');
    } else if (e === 'video/mp4' || e === '.mp4') {
      extensions.push('.mp4');
    } else if (e === 'application/pdf' || e === '.pdf') {
      extensions = extensions.concat(SupportedExtensionsByCategory.pdf);
    } else if (e === '.avi') {
      // dont have video/avi
      extensions.push('.avi');
    } else if (e === 'audio/mpeg' || e === '.mpg' || e === '.mpeg') {
      extensions.push('.mpg');
      extensions.push('.mpeg');
    } else if (
      e === 'application/vnd.ms-excel' ||
      e === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      extensions = extensions.concat(SupportedExtensionsByCategory.excel);
    } else if (e === 'application/msword') {
      extensions = extensions.concat(SupportedExtensionsByCategory.word);
    } else if (SupportedExtensions.includes(e)) {
      extensions.push(e);
    }
  });
  extensions = [...new Set(extensions)]; // distinct

  const isOk2 = extensions.includes(extension);
  return isOk2;
}

export function validateFile(accept, file) {
  const fileName = file?.name;
  if (!fileName) return false;
  const index = fileName.lastIndexOf('.');
  if (!index || index < 0) return false;

  const extension = fileName.toLowerCase().substring(index);
  return validateFileExtension(accept, extension);
}

export function validateResource(accept, resource) {
  let extension = resource.extension ? resource.extension.toLowerCase() : '';

  const isExternalLink =
    resource.isExternal &&
    (resource.resourceType === ResourceType.podcast ||
      resource.resourceType === ResourceType.webSharing) &&
    !!extension;
  if (!extension || isExternalLink) return true; // to support old resources without extension
  if (!extension.startsWith('.')) {
    extension = `.${extension}`;
  }
  return validateFileExtension(accept, extension.toLowerCase());
}

export function getAcceptResourceTypes(accept) {
  if (accept === '*') {
    return {
      acceptResourceTypes: Object.values(ResourceType),
      acceptResourceTypeNames: Object.values(ResourceTypeNames),
    };
  }
  const acceptResourceTypes = [];
  const acceptResourceTypeNames = [];
  accept.split(',').forEach((e) => {
    if (
      e.includes('image') ||
      e.includes('jpg') ||
      e.includes('jpeg') ||
      e.includes('png') ||
      e.includes('gif') ||
      e.includes('webp') ||
      e.includes('svg') ||
      e.includes('apng')
    ) {
      acceptResourceTypes.push(ResourceType.image);
      acceptResourceTypeNames.push(ResourceTypeNames.image);
    } else if (
      e.includes('video') ||
      e.includes('avi') ||
      e.includes('mp4') ||
      e.includes('mov') ||
      e.includes('webm') ||
      e.includes('mpg') ||
      e.includes('mpeg')
    ) {
      acceptResourceTypes.push(ResourceType.video);
      acceptResourceTypeNames.push(ResourceTypeNames.video);
    } else if (e.includes('pdf')) {
      acceptResourceTypes.push(ResourceType.pdf);
      acceptResourceTypeNames.push(ResourceTypeNames.pdf);
    } else if (e.includes('ppt')) {
      acceptResourceTypes.push(ResourceType.powerpoint);
      acceptResourceTypeNames.push(ResourceTypeNames.powerpoint);
    } else if (e.includes('doc')) {
      acceptResourceTypes.push(ResourceType.word);
      acceptResourceTypeNames.push(ResourceTypeNames.word);
    } else if (e.includes('xls')) {
      acceptResourceTypes.push(ResourceType.excel);
      acceptResourceTypeNames.push(ResourceTypeNames.excel);
    } else if (e.includes('key')) {
      acceptResourceTypes.push(ResourceType.keynote);
      acceptResourceTypeNames.push(ResourceTypeNames.keynote);
    } else if (e.includes('woff') || e.includes('otf') || e.includes('ttf')) {
      acceptResourceTypes.push(ResourceType.font);
      acceptResourceTypeNames.push(ResourceType.font);
    } else if (
      e.includes('audio') ||
      e.includes('mp3') ||
      e.includes('mpeg3') ||
      e.includes('wav')
    ) {
      acceptResourceTypes.push(ResourceType.audio);
      acceptResourceTypeNames.push(ResourceType.audio);
    } else if (
      e.includes('dwg') ||
      e.includes('stp') ||
      e.includes('igs') ||
      e.includes('step') ||
      e.includes('stl') ||
      e.includes('jt') ||
      e.includes('sat')
    ) {
      acceptResourceTypes.push(ResourceType.cad);
      acceptResourceTypeNames.push(ResourceType.cad);
    } else if (e.includes('zip')) {
      acceptResourceTypes.push(ResourceType.zip);
      acceptResourceTypeNames.push(ResourceTypeNames.zip);
    } else {
      acceptResourceTypes.push(ResourceType.podcast);
      acceptResourceTypeNames.push(ResourceTypeNames.podcast);
    }
  });
  return {
    acceptResourceTypes: _.uniq(acceptResourceTypes),
    acceptResourceTypeNames: _.uniq(acceptResourceTypeNames),
  };
}

export function getResourceFileType(file) {
  let resourceType = null;
  const fileType = file.type || file.typeFile || '';
  const fileName = file.name ? file.name.toLowerCase() : '';
  // psd file on Mac with type is image/vnd.adobe.photoshop
  if (fileType.startsWith('image') && fileType.indexOf('image/vnd.adobe.photoshop') < 0) {
    resourceType = ResourceType.image;
  } else if (fileType.startsWith('video')) {
    resourceType = ResourceType.video;
  } else if (fileType.startsWith('audio')) {
    resourceType = ResourceType.audio;
  } else if (fileType.startsWith('application/pdf')) {
    resourceType = ResourceType.pdf;
  } else if (
    fileType.indexOf('msword') >= 0 ||
    fileType.indexOf('officedocument.wordprocessingml') >= 0
  ) {
    resourceType = ResourceType.word;
  } else if (
    fileType.indexOf('vnd.ms-excel') >= 0 ||
    fileType.indexOf('officedocument.spreadsheetml') >= 0
  ) {
    resourceType = ResourceType.excel;
  } else if (
    fileType.indexOf('powerpoint') >= 0 ||
    fileType.indexOf('officedocument.presentationml') >= 0
  ) {
    resourceType = ResourceType.powerpoint;
  } else if (
    fileType.indexOf('application/font-woff') >= 0 ||
    fileName.indexOf('.ttf') >= 0 ||
    fileName.indexOf('.otf') >= 0 ||
    fileName.indexOf('.woff') >= 0
  ) {
    resourceType = ResourceType.font;
  } else if (fileName.indexOf('.key') >= 0) {
    resourceType = ResourceType.keynote;
  } else if (
    fileType.indexOf('application/x-zip-compressed') >= 0 ||
    fileType.indexOf('application/zip') >= 0
  ) {
    resourceType = ResourceType.zip;
  } else if (
    fileName.indexOf('.dwg') >= 0 ||
    fileName.indexOf('.stp') >= 0 ||
    fileName.indexOf('.igs') >= 0 ||
    fileName.indexOf('.step') >= 0 ||
    fileName.indexOf('.stl') >= 0 ||
    fileName.indexOf('.jt') >= 0 ||
    fileName.indexOf('.sat') >= 0
  ) {
    resourceType = ResourceType.cad;
  } else if (fileType.indexOf('application/postscript') >= 0) {
    if (fileName.indexOf('.eps') >= 0) {
      resourceType = ResourceType.eps;
    } else if (fileName.indexOf('.ai') >= 0) {
      resourceType = ResourceType.ai;
    }
  } else if (fileName.indexOf('.indd') >= 0) {
    resourceType = ResourceType.indd;
  } else if (fileName.indexOf('.psd') >= 0) {
    resourceType = ResourceType.psd;
  } else if (fileName.indexOf('.dcm') >= 0) {
    resourceType = ResourceType.dcm;
  }
  return resourceType;
}

export function isOneOfResourceTypes(resourceType, types) {
  return types.includes(resourceType);
}

export const getPlayer = memoizeOne((src) => {
  if (!src) {
    return { type: '', isVideo: false };
  }
  // if (src.indexOf('sharepoint.com') !== -1) {
  //   return { type: 'onedrive_media', isVideo: true };
  // }
  if (src.indexOf('google-drive') !== -1) {
    return { type: 'googledrive_media', isVideo: true };
  }
  if (src.indexOf('1drv.com') !== -1) {
    return { type: 'onedrive_media', isVideo: true, externalType: 'personal' };
  }
  if (src.indexOf('ext_rec_token') !== -1) {
    return { type: 'external_recording', isVideo: true };
  }
  if (src.indexOf('streaming.media.azure.net') !== -1) {
    return { type: 'amp', isVideo: true };
  }
  if (src.startsWith('https://miro.com/')) {
    return { type: 'miro', isVideo: false };
  }
  if (src.includes('menti.com') || src.includes('mentimeter.com')) {
    return { type: 'mentimeter', isVideo: false };
  }
  if (src.includes('streaming.mediakind.com')) {
    return { type: 'mediakind', isVideo: true };
  }
  if (src.includes('/app/iloq')) {
    return { type: 'iloq', isVideo: false };
  }
  const type = ReactPlayer.getPlayer(src);
  return { type, isVideo: !!type };
});

export function isVideoResource(src) {
  return getPlayer(src)?.isVideo === true;
}

export function getExtensionFromFileName(fileName) {
  if (!fileName) return '';
  const lowerSrc = fileName.toLowerCase();
  const patt = /(?:\.([^.]+))?$/g;
  const res = patt.exec(lowerSrc);
  return res?.[1] || '';
}

export function checkingSkipTranscode(fileName) {
  const extension = getExtensionFromFileName(fileName);
  return extension === 'webm';
}

export function getExtensionFromUrl(url, extension) {
  if (extension) return extension;

  if (!url || !isString(url)) return null;
  if (url.indexOf('?sis_ext=') !== -1) {
    return url.substring(url.lastIndexOf('=') + 1);
  }

  const urlPath = getPathFromUrl(decodeURIComponent(url));

  const fileName = urlPath.substring(urlPath.lastIndexOf('/') + 1);

  return fileName.split('.').pop().trim();
}

export function getCssClassByResourceType(type, fileName) {
  let cssClass = 'icon-unsupported-file';
  switch (type) {
    case ResourceType.video:
      cssClass = 'icon-video';
      break;
    case ResourceType.image:
      cssClass = 'icon-picture';
      break;
    case ResourceType.pdf:
      cssClass = 'icon-pdf';
      break;
    case ResourceType.powerpoint:
      cssClass = 'icon-powerpoint';
      break;
    case ResourceType.word:
      cssClass = 'icon-word';
      break;
    case ResourceType.excel:
      cssClass = 'icon-excel';
      break;
    case ResourceType.podcast:
      cssClass = 'icon-resource-link';
      break;
    case ResourceType.audio:
      cssClass = 'icon-icon-mp3';
      break;
    case ResourceType.cad: {
      const fileType = fileName && getExtensionFromFileName(fileName);
      cssClass = `icon-icon-${fileType || 'cad'}`;
      break;
    }
    case ResourceType.keynote:
      cssClass = 'icon-keynote';
      break;
    case ResourceType.zip:
      cssClass = 'icon-zip';
      break;
    case ResourceType.vaam:
      cssClass = 'icon-video-message-2';
      break;
    case ResourceType.externalLink:
    case ResourceType.webSharing:
      cssClass = 'icon-resource-link';
      break;
    default: {
      const fileType = fileName && getExtensionFromFileName(fileName);
      if (fileType === 'avi') {
        cssClass = 'icon-video';
      } else {
        cssClass = 'icon-unsupported-file';
      }
      break;
    }
  }
  return cssClass;
}

export function getExternalUrlInfo(url, isWebSharing) {
  if (!url) return null;
  if (isWebSharing) {
    return { src: url, resourceType: ResourceType.webSharing, isExternal: true };
  }

  const lowerSrc = url.toLowerCase();
  const srcWithoutParam = lowerSrc.split('?')[0];

  if (isVideoResource(lowerSrc) || isVideoResource(srcWithoutParam)) {
    return { src: url, resourceType: ResourceType.video, isExternal: true };
  }

  const extension = lowerSrc.split('.').pop();
  const extension1 = srcWithoutParam.split('.').pop();

  let resourceType;
  if (
    extension === 'jpg' ||
    extension === 'jpeg' ||
    extension === 'png' ||
    extension1 === 'jpg' ||
    extension1 === 'jpeg' ||
    extension1 === 'png'
  ) {
    resourceType = ResourceType.image;
  } else if (extension === 'pdf' || extension1 === 'pdf') {
    resourceType = ResourceType.pdf;
  } else {
    resourceType = ResourceType.podcast; // url
  }

  return { src: url, resourceType, isExternal: true };
}

export const getMaterials = (folder) => {
  if (!folder || !folder.spaceResources || folder.spaceResources.length === 0) {
    return [];
  }
  return folder.spaceResources.map((item) => {
    const shouldConvertToPDF =
      (item.state !== null &&
        item.state !== undefined &&
        item.state !== ResourceState.Converted &&
        item.state !== ResourceState.Converting &&
        item.state !== ResourceState.Deleted &&
        isOneOfResourceTypes(item.type, ConvertToPDFResourceTypes) &&
        !isIntegrationResource(item.src)) ||
      shouldConvertIntegrationResourceToPDF(item);
    const name =
      item.resourceName || item.displayName || (item.name !== '' ? item.name : 'Video Message');
    return {
      ...item,
      spaceId: folder.spaceId,
      directoryName: folder.name,
      directoryId: folder.id,
      date: moment.utc(item.created),
      shouldConvertToPDF,
      name: name,
    };
  });
};

export const getMaterialsByDirectory = (directory) => {
  if (!directory) return [];
  const materials = getMaterials(directory);
  if (directory.isExternal) {
    return materials;
  }
  const sortedMaterials = _.orderBy(
    materials,
    ['order', 'name', 'date', 'directoryName'],
    ['asc', 'asc', 'desc', 'asc']
  );
  return sortedMaterials;
};

export const resourcesOfFolder = (folder) => {
  const files = getMaterialsByDirectory(folder);
  const folders = folder?.subFolders || [];
  const data = [...folders, ...files];
  return data;
};

export const getExternalToolType = (url) => {
  if (!url) return null;
  // const lst = ExternalTools || [];
  const lst = [];
  for (let i = 0; i < lst.length; i += 1) {
    const etType = lst[i];
    const domains = etType.domains || [];
    for (let j = 0; j < domains.length; j += 1) {
      const domain = domains[j];
      if (url.includes(domain)) {
        return etType.type;
      }
    }
  }
  return 'an URL';
};

export function formatBytes(bytes, decimals = 0) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export async function downloadFile(file, isPortal) {
  if (file.isRoi) {
    await downloadRoiFile(file);
  } else if (file.isLms) {
    await downloadCertificateFile(file);
  } else if (isIntegrationResource(file.src)) {
    await downloadIntegrationResource(file, isPortal);
  } else {
    // console.log('### 1310 downloadFile:', file);
    const { resourceId, name, spaceId } = file;
    let downloadFileName = name;

    let finalUrl;
    if (resourceId > 0) {
      // need to fetch the url for downloading
      const materialId = file.id;
      let resp;
      if (materialId > 0) {
        resp = await getMaterialResource(spaceId, materialId, isPortal);
      } else {
        resp = await getResourceForViewer(resourceId, isPortal);
      }
      // console.log('### 1310 downloadFile 2:', resp);
      if (resp) {
        if (resp.resourceType === ResourceType.video || resp.resourceType === ResourceType.cad) {
          if (isIntegrationResource(resp.orgSrc)) {
            await downloadIntegrationResource({ src: resp.orgSrc, name: name }, isPortal);
            return;
          }
          finalUrl = resp.orgSrc;
        } else {
          finalUrl = resp.url;
        }
      }
    }

    let extension = getExtensionFromFileName(name);
    if (extension === '') {
      extension = file.extension || `${getExtensionFromUrl(finalUrl)}`;
      downloadFileName = `${downloadFileName}.${extension}`;
    }

    if (finalUrl) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = downloadFileName;
      link.href = `${finalUrl}${finalUrl.includes('?') ? '&' : '?'}download=1`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function getResourceTypeDisplayName(type) {
  const typeName = Object.getOwnPropertyNames(ResourceType).find((p) => ResourceType[p] === type);
  if (typeName) {
    try {
      return ResourceTypeDisplayNames[typeName];
    } catch (error) {
      console.log(error);
    }
  }
  return ResourceTypeDisplayNames.other;
}

export const getFilteredDirectory = memoizeOne((directories, includesExtraFolders = false) => {
  if (!directories || directories.length === 0) {
    return [];
  }
  const newDirectories = directories.slice();
  if (!includesExtraFolders) {
    return newDirectories.filter((d) => d.directoryType !== DirectoryType.Extra);
  }

  return newDirectories;
});

export const getAllItems = memoizeOne((directories) => {
  if (!directories || directories.length === 0) {
    return [];
  }
  const items = [];
  directories.forEach((d) => {
    items.push(d);
    d.spaceResources.forEach((m) => {
      Object.assign(m, { folderId: d.id });
      items.push(m);
    });
  });
  return items;
});

export function getResourceExtension(resource) {
  if (!resource) return '';

  let url = '';

  if (resource.resourceType === ResourceType.video && !resource.isExternal) {
    url = resource.src || resource.orgSrc;
  } else {
    url = resource.url;
  }

  return getExtensionFromUrl(url);
}

export const getVideoResourceData = (resource) => {
  const { state, orgSrc } = resource;
  const isTranscodingFailed = state === ResourceState.TranscodingFailed;

  if (!(resource.resourceType === ResourceType.video || resource.type === ResourceType.video)) {
    return {
      isTranscodingFailed,
      canView: true,
    };
  }

  const extension =
    resource.extension ||
    getExtensionFromFileName(resource.resourceName) ||
    getExtensionFromUrl(resource.url, resource.extension);

  const url = resource.url || resource.src;

  const isAzureMedia = url?.indexOf('streaming.media.azure.net') !== -1;
  const isSpecificResources = ['avi', 'mpg', 'mpeg'].includes(extension);

  if (isSpecificResources) {
    if (isTranscodingFailed || isAzureMedia) {
      return {
        canView: false,
        videoURL: '',
        isTranscodingFailed: true,
      };
    } else {
      return {
        canView: true,
        videoURL: url,
        isTranscodingFailed: false,
      };
    }
  } else {
    return {
      canView: true,
      videoURL: isTranscodingFailed || isAzureMedia ? orgSrc : url,
    };
  }
};

export function canViewResource(resource) {
  const type =
    resource.resourceType !== undefined && resource.resourceType !== null
      ? resource.resourceType
      : resource.type;
  const state = resource.resourceState || resource.state;
  const videoCanView = getVideoResourceData(resource)?.canView;

  const viewNotSupported =
    (state !== ResourceState.Converted &&
      (type === ResourceType.cad || type === ResourceType.keynote)) ||
    type === ResourceType.zip ||
    type === ResourceType.font ||
    type === ResourceType.unknown ||
    type === ResourceType.eps ||
    type === ResourceType.ai ||
    type === ResourceType.indd ||
    type === ResourceType.psd ||
    type === ResourceType.dcm ||
    (type === ResourceType.video && !videoCanView);

  if (
    type === ResourceType.unknown &&
    resource.src &&
    resource.src?.indexOf('sis_ext=html') !== -1
  ) {
    return true;
  }
  return !viewNotSupported;
}

export function isExternalLinkNotVideo(file, isResource) {
  if (!file) {
    return false;
  }
  if (file.type === ResourceType.vaam) {
    return false;
  }
  if (isResource) {
    return file.isExternal && file.resourceType !== ResourceType.video;
  }
  return file.isExternal && file.type !== ResourceType.video;
}

export function getResourceIndicatorType(resource) {
  if (resource.isLms || resource.isRoi) {
    return null;
  }
  let details = getIntegrationResourceDetails(resource.src);
  // console.log('details', details);
  if (!details) {
    details = getIntegrationResourceDetails(resource.orgSrc);
  }
  if (resource.type === ResourceType.vaam) {
    return { indicatorCss: 'icon-video-message' };
  }
  if (details) {
    return details;
  }
  if (resource.isGlobal) {
    return { indicatorCss: 'icon-globe' };
  }
  if (!resource.isExternal) {
    return { indicatorCss: 'icon-upload' };
  }
  return null;
}

export function getFolderIndicatorType(resource) {
  const externalSettingsProvider = resource?.externalFolderSettings?.provider;
  const details = getIntegrationFolderDetails(externalSettingsProvider);

  if (details) {
    return details;
  }
  if (externalSettingsProvider === SERVICE_PROVIDER.LOCAL_MACHINE) {
    return { indicatorCss: 'icon-upload' };
  }

  if (externalSettingsProvider === SERVICE_PROVIDER.GLOBAL_RESOURCES) {
    return { indicatorCss: 'icon-globe' };
  }

  return null;
}

export const parseYoutubePlaylistLink = memoizeOne((url) => {
  let src = url;
  const playerVars = {
    disablekb: 1,
    showinfo: 0,
  };

  try {
    const MATCH_PLAYLIST = /[?&](?:list|channel)=([a-zA-Z0-9_-]+)/;
    if (MATCH_PLAYLIST.test(src)) {
      const [playlistParam, playlistId] = src.match(MATCH_PLAYLIST);
      playerVars.listType = 'playlist';
      playerVars.list = playlistId.replace(/^UC/, 'UU');
      const index = src.indexOf(playlistParam);
      if (index >= 0) {
        src = src.slice(0, index);
      }
    }
  } catch (error) {
    console.log('error', error);
  }

  return { src, config: { youtube: { playerVars } } };
});

export function getYoutubeVideoId(linkYoutube) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = linkYoutube.match(regExp);
  return match && match[7] && match[7].length === 11 ? match[7] : null;
}

export function getThumbnailYoutubeLink(linkYoutube) {
  if (!linkYoutube) return null;
  const youtubeId = getYoutubeVideoId(linkYoutube);
  // console.log('### getThumbnailYoutubeLink youtubeId', youtubeId);
  if (youtubeId) {
    const thumbnailUrl = `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`; // or 0.jpg
    return thumbnailUrl;
  }
  return null;
}

export function getVimeoVideoId(linkVimeo) {
  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const match = linkVimeo.match(regExp);
  return match && match[5] && match[5].length > 0 ? match[5] : null;
}

export async function getThumbnailVimeoLink(linkVimeo) {
  if (!linkVimeo) return null;
  try {
    // e.g. https://vimeo.com/783455878
    // const videoId = getVimeoVideoId(linkVimeo);
    // const vimeoUrlParam = encodeURIComponent(`https://vimeo.com/${videoId}`);
    let urlSrc = linkVimeo;
    if (!urlSrc.startsWith('http')) {
      urlSrc = `https://${urlSrc}`;
    }
    const resp = await fetch(`https://vimeo.com/api/oembed.json?url=${urlSrc}`);
    // console.log('### getThumbnailVimeoLink resp', resp);
    if (resp.ok) {
      const data = await resp.json();
      const thumbnail = data.thumbnail_url;
      return thumbnail;
    }
  } catch (err) {
    console.log('getThumbnailVimeoLink error', err);
  }
  return null;
}

export function getDailymotionVideoId(linkDailymotion) {
  const regExp =
    /(?:https?:\/\/)?(?:www\.)?dai\.?ly(motion)?(?:\.com)?\/?.*(?:video|embed)?(?:.*v=|v\/|\/)([a-z0-9]+)/;
  const match = linkDailymotion.match(regExp);
  return match && match[2] && match[2].length > 0 ? match[2] : null;
}

export function getThumbnailDailymotionLink(linkDailymotion) {
  if (!linkDailymotion) return null;
  // e.g. https://dai.ly/x8n23xx
  const videoId = getDailymotionVideoId(linkDailymotion);
  // console.log('### getThumbnailDailymotionLink videoId', videoId);
  if (videoId) {
    const thumbnailUrl = `https://www.dailymotion.com/thumbnail/video/${videoId}`;
    return thumbnailUrl;
  }
  return null;
}

export async function getVideoThumbnail(video) {
  if (!video) return null;
  if (video.resourceType !== ResourceType.video) return null;
  if (video.thumbnail === '__unavailable__') return null;
  if (!video.isExternal) return video.thumbnail;
  let thumbnail;
  const url = video.url;
  // url = getHttpsUrl(url);
  const player = getPlayer(url);
  // console.log('### getVideoThumbnail player', player);
  if (!player.isVideo) return thumbnail;
  if (player.type === 'youtube') {
    thumbnail = getThumbnailYoutubeLink(url);
  } else if (player.type === 'vimeo') {
    thumbnail = await getThumbnailVimeoLink(url);
  } else if (player.type === 'dailymotion') {
    thumbnail = getThumbnailDailymotionLink(url);
  }
  return thumbnail;
}

export function getFolderPath(folder) {
  if (!folder || !folder.parentFolderId) {
    return null;
  }
  const paths = [];
  let currentFolder = folder;
  let maxDepth = 100;
  while (currentFolder.parentFolder) {
    maxDepth -= 1;
    if (maxDepth <= 0) {
      break;
    }
    paths.splice(0, 0, currentFolder.parentFolder.id);
    currentFolder = currentFolder.parentFolder;
  }
  return paths;
}

export function getFolderPathName(folder) {
  if (!folder || !folder.parentFolderId) {
    return null;
  }
  const paths = [];
  let currentFolder = folder;
  let maxDepth = 100;
  while (currentFolder.parentFolder) {
    maxDepth -= 1;
    if (maxDepth <= 0) {
      break;
    }
    paths.splice(0, 0, currentFolder.parentFolder.name);
    currentFolder = currentFolder.parentFolder;
  }
  return paths.join('/');
}

export function getFileExtension(filename) {
  const match = filename.match(/(?:\.([^.]+))?$/);
  return match ? match[1] : undefined;
}

export function fileNameWithoutExtension(fileName) {
  return fileName.substring(0, fileName.lastIndexOf('.'));
}

export function sanitizeFileName(fileName) {
  if (!fileName) return fileName;
  let newFileName = fileName.toLowerCase();
  const ext = getFileExtension(newFileName);
  const name = fileNameWithoutExtension(newFileName);
  const sanitized = name.replace(/[^a-z0-9]/gi, '_');
  newFileName = `${sanitized}.${ext}`;
  return newFileName;
}

export const getFolderIdFromExternalId = (id) => {
  if (!id?.includes) return id;
  if (id.includes('OneDrive:') || id.includes('GoogleDrive:')) {
    return id.split(':')[1];
  }
  return id;
};

export const getUrlMediaKind = (url, token, isMobile, isIOS) => {
  if (!isMobile) {
    return url;
  }
  const manifestProxy = getMediaKindProxyUrl();
  let urlFormat = url;
  if (urlFormat.indexOf('format=mpd-time-cmaf') >= 0) {
    urlFormat = urlFormat.replace('format=mpd-time-cmaf', 'format=m3u8-cmaf');
    if (urlFormat.indexOf('encryption=cenc') >= 0) {
      urlFormat = urlFormat.replace('encryption=cenc', 'encryption=cbc');
    }
  }
  if (isIOS) {
    return `${manifestProxy}?playbackUrl=${urlFormat}&token=Bearer%3d${token}`;
  } else {
    return urlFormat;
  }
};

export const getMediaKindConfig = (src, laUrl, token, isMobile, isIOS) => {
  if (isMobile && isIOS) {
    return {
      src: getUrlMediaKind(src, token, isMobile, isIOS),
      config: {
        mediakind: {
          type: 'hls',
          licenseKey: process.env.REACT_APP_MK_LICENSE_KEY,
        },
      },
    };
  }
  const url =
    laUrl || 'https://ottapp-appgw-amp.prodc.mkio.tv3cloud.com/drm/clear-key?ownerUid=azuki';
  return {
    src: getUrlMediaKind(src, token, isMobile, isIOS),
    config: {
      mediakind: {
        type: isMobile ? 'hls' : 'dash',
        licenseKey: process.env.REACT_APP_MK_LICENSE_KEY,
        drm: {
          clearkey: {
            LA_URL: isMobile ? 'HLS_AES' : url,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          },
        },
      },
    },
  };
};

export function replaceBlobUrl(url) {
  if (!url) return url;

  const blobDomain = getBlobDomain();
  if (url.indexOf(blobDomain) !== -1) {
    return url.replace(blobDomain, `${window.location.origin}/files`);
  }
  return url;
}
