import UploaderLabel from './UploaderLabel';

interface UploaderLabelProps {
  label?: string;
  tooltip?: string | React.ReactElement;
  element?: string | React.ReactNode;
  htmlFor?: string;
  tooltipClasses?: object;
}

const UploaderLabelTs = UploaderLabel;
export default UploaderLabelTs as React.FC<UploaderLabelProps>;
