import _forEach from 'lodash/forEach';
import { ResourceType } from '../../app/appConstants';
import { checkingSkipTranscode, getResourceFileType } from '../../commons/ResourceUtils';
import { validateInput } from '../DndFileUploader3/utils';
import { newGuid } from '../../commons/utils';

export type ErrorFile = {
  type: string;
  message?: string | undefined;
};

export type FileType = File & {
  feature?: number;
  resourceType?: number | null | undefined;
  fileName?: string;
  fileDescription?: string;
  isGlobal?: boolean;
  validationResult?: ErrorFile | null | undefined;
  uniqueIdentifier?: string;
  files?: FileType[];
  webkitRelativePath?: string;
  path?: string;
  skipTranscode?: boolean;
};

export type InvalidFileType = {
  name?: string;
  fileName?: string;
  file?: File;
  isValid?: boolean;
  id?: string;
  validationResult?: ErrorFile | null | undefined;
};

export const sizeFolderValidation = (data: FileList | File[]) => {
  const files = Array.from(data);
  let valid = false;
  // check total files number > 2000
  if (files.length > 2000) {
    valid = false;
    return 'maximumFiles';
  }
  // check total files size < 50GB
  const totalFilesSize = files.reduce((acc: number, file: File) => acc + file.size, 0);
  if (totalFilesSize > 50 * 1024 * 1024 * 1024) {
    valid = false;
    return 'size';
  }
  return valid;
};

export const getVisibleFiles = (data: FileType[] | FileList) => {
  const validFiles: FileType[] | FileList = [];
  _forEach(data, (file: FileType) => {
    const newFile = file;

    if (!/(^|\/)\.[^/.]/g.test(file.name)) {
      validFiles.push(newFile);
    }
  });
  return validFiles;
};

export const validateFiles = (data: FileType[] | FileList, isGlobal = false) => {
  const validFiles: FileType[] | FileList = [];
  const invalidFiles: InvalidFileType[] = [];
  _forEach(data, (file: FileType) => {
    const result = validateInput('file', file);
    const resourceType = getResourceFileType(file);
    console.log('file: ', file);
    const newFile = file;
    newFile.resourceType = resourceType;
    newFile.fileName = file.name;
    newFile.fileDescription = '';
    newFile.isGlobal = isGlobal;
    if (resourceType === ResourceType.video) {
      newFile.skipTranscode = checkingSkipTranscode(file.fileName);
    }
    if (!result) {
      if (!/(^|\/)\.[^/.]/g.test(file.name)) {
        validFiles.push(newFile);
      }
    } else {
      const invalidFile = {
        name: file.name,
        fileName: file.name,
        file: file,
        isValid: false,
        id: newGuid(),
        validationResult: result,
      };

      invalidFiles.push(invalidFile);
    }
  });
  return {
    validFiles,
    invalidFiles,
  };
};

interface FolderType {
  name?: string;
  isFolder?: boolean;
  filesAndFolders?: FolderType[];
  resourceId?: string;
  resourceIds?: string[];
}

interface FileData {
  path: string;
  resourceId: string;
}

export function buildFolderStructure(files: FileData[]) {
  const folders: FolderType = {
    name: files[0].path.split('/')[0] || 'folder',
    isFolder: true,
    filesAndFolders: [],
  };
  // build tree
  files.forEach((file: FileData) => {
    const paths = file.path.split('/').slice(1, -1);
    let subF: FolderType[] | undefined = folders.filesAndFolders || [];
    if (paths.length === 0) {
      subF.push({ resourceId: file.resourceId, isFolder: false });
    } else {
      paths.forEach((path: string) => {
        const findFolder = subF?.find((folder: FolderType) => folder.name === path);
        if (!findFolder) {
          subF?.push({
            name: path,
            isFolder: true,
            filesAndFolders: [],
          });
        }
        subF = subF?.find((folder: FolderType) => folder.name === path)?.filesAndFolders;
      });
      subF.push({ resourceId: file.resourceId, isFolder: false });
    }
  });

  return folders;
}

export const typeFolderErrors = {
  size: 'The folder exceeds the maximum folder size 50 GB.',
  folderNumber: 'Upload 1 folder at the time.',
  common: 'Upload failed.',
  unsupported: 'The format is not supported. Please try another folder.',
  maximumFiles: 'The folder contains more than 2000 files, exceeding the limit of 2000 files.',
};

export const getFolderName = (files: FileType[]) => {
  if (!files.length) return '';
  const file = Array.from(files)[0];

  let folderName = file.webkitRelativePath?.split('/')[0] || 'folder';
  if (file?.path?.split('/')[0]) {
    folderName = file?.path?.split('/')[0];
  }
  return folderName;
};
