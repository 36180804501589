import { useEffect } from 'react';
import eventBus, { EVENT_BUS } from '../commons/EventBus';

// eslint-disable-next-line react/prop-types
const UploadResourcesDialogHandleEvents = ({ setIsShow }) => {
  useEffect(() => {
    const unsubscribe = eventBus.subscribe(EVENT_BUS.ToggleUploadResourcePopup, setIsShow);
    return () => {
      unsubscribe();
    };
  }, [setIsShow]);

  return null;
};

export default UploadResourcesDialogHandleEvents;
