import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs } from '@mui/material';
import './ColumnsTab.scss';

const ColumnsTab = ({ columns, selectedColumn, setSelectedColumn }) => {
  const tabs = Array.from(Array(columns).keys());
  const [tab, setTab] = useState(selectedColumn || 0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setSelectedColumn(newValue);
  };

  useEffect(() => {
    if (tab !== selectedColumn) {
      setTab(selectedColumn);
    }
  }, [selectedColumn, tab]);

  return (
    <>
      {columns > 1 && (
        <Tabs
          value={tab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs for cms content tab"
          className="cms-editing-component-panel-number-tabs cms-columns-number-tabs"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {tabs.map((t) => (
            <Tab
              key={t}
              label={t + 1}
              value={t}
              aria-controls={`cms-content-tab-${t}`}
              className="cms-editing-component-panel-number-tab"
            />
          ))}
        </Tabs>
      )}
    </>
  );
};

ColumnsTab.propTypes = {
  columns: PropTypes.number,
  selectedColumn: PropTypes.number,
  setSelectedColumn: PropTypes.func,
};

export default ColumnsTab;
