import React, { useState, MouseEvent } from 'react';
import { Popover, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import i18n from '../i18n';
import './FilterButton.scss';
import OfficialButton from '../components/OfficialButtons';

const options = [{ label: 'Exclude internal users', id: '0' }];

type FilterButtonProps = {
  onChange: (checked: boolean) => void;
};

const FilterButtonDropdown = ({ onChange }: FilterButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const countFilesSelected = checkedValues?.length;
  const open = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleOnClear = (): void => {
    setCheckedValues([]);
    setAnchorEl(null);
    if (onChange) {
      setTimeout(() => {
        onChange(false);
      });
    }
  };

  const handleOnChangeFilter = (checkedName: string): string[] => {
    const isChecked = checkedValues?.includes(checkedName);
    const newTypes = isChecked
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...checkedValues, checkedName];
    setCheckedValues(newTypes);
    if (onChange) {
      setTimeout(() => {
        onChange(!isChecked);
      });
    }
    return newTypes;
  };

  const handleOnClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  function renderPopover() {
    if (!open) {
      return null;
    }

    return (
      <Popover
        open
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{
          marginTop: '4px',
        }}
        className="interaction-popup"
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div className="exclude-filter">
          <div className="exclude-filter-header">
            <div>
              <strong>{i18n.t('Filter by')}</strong>
              <div className="filtered">{i18n.t(`${checkedValues.length} selected`)}</div>
            </div>
            <OfficialButton
              label="clear"
              onClick={handleOnClear}
              variant="rectangle-secondary"
              className="clear-btn"
            />
          </div>
          <div className="exclude-filter-body">
            <FormGroup>
              {options.map((item) => (
                <FormControlLabel
                  key={item.id}
                  className="checkbox-label"
                  control={
                    <Checkbox
                      className="checkbox-item"
                      color="primary"
                      checked={checkedValues?.includes(item.id)}
                      onChange={() => handleOnChangeFilter(item.id)}
                      name={item.id}
                    />
                  }
                  labelPlacement="start"
                  label={<div>{item.label}</div>}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </Popover>
    );
  }

  return (
    <>
      <Button
        className={`button-filter ${open ? 'active' : ''}`}
        variant="outlined"
        onClick={handleOnClick}
        startIcon={!countFilesSelected && <i className="icon-filter2" />}
      >
        {!!countFilesSelected && <span className="tag-filled">{countFilesSelected}</span>}

        {i18n.t('Filter')}
      </Button>
      {renderPopover()}
    </>
  );
};

export default React.memo(FilterButtonDropdown);
