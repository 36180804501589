import React from 'react';
import Loading from '../components/Loading';

const GlobalResourcesLoading = () => (
  <div className="loading-box">
    <Loading message="Loading files..." className="no-margin" />
    <p className="loading-desc">Just a moment</p>
  </div>
);

export default GlobalResourcesLoading;
