import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import { ApiResult } from '../types/common';
import { PrivacySpace } from '../../app/appConstants';

interface QueryProps<TApiData, TProjectedData> {
  queryKey: string;
  startDate?: Date;
  endDate?: Date;
  fetcher: SpaceAnalyticsRequest<ApiResult<TApiData>>;
  params?: Record<string, unknown>;
  enabled?: boolean;
  select?: (data: ApiResult<TApiData>) => TProjectedData;
  refetchOnMount?: boolean | 'always';
}

export interface SpaceAnalyticsQueryParams extends Record<string, unknown> {
  spaceId?: string;
  startDate?: string;
  endDate?: string;
}

type SpaceAnalyticsRequest<T> = (params: SpaceAnalyticsQueryParams) => Promise<T>;

const useSpaceAnalyticsQuery = <TApiData, TProjectedData>({
  fetcher,
  params,
  queryKey,
  enabled,
  select,
  refetchOnMount,
}: QueryProps<TApiData, TProjectedData>) => {
  const { space, reportEndDate, dateRange } = useSpaceAnalyticsContext();

  const queryStartDate = dateRange?.startDate || space?.created;
  const queryEndDate = dateRange?.endDate || moment(reportEndDate).toISOString();

  return useQuery({
    queryKey: [queryKey, space?.id, queryStartDate, reportEndDate, params],
    queryFn: () =>
      fetcher({
        ...params,
        spaceId: space?.id,
        isPublic: space?.spacePrivacy === PrivacySpace.PUBLIC,
        startDate: queryStartDate ? moment(queryStartDate).toISOString() : undefined,
        endDate: queryEndDate ? moment(reportEndDate).toISOString() : undefined,
      }),
    enabled,
    select,
    retry: 3,
    retryDelay: () => 5000,
    refetchOnMount: refetchOnMount,
  });
};

export default useSpaceAnalyticsQuery;
