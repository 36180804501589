/* global google */

import React from 'react';
import PropTypes from 'prop-types';
import { appendScript } from '../../../commons/utils';

function useGoogleLogin(props) {
  const { clientId, scope, uxMode, redirectUri, onSuccess, onError, onCancelled } = props;
  const [loaded, setLoaded] = React.useState(false);
  const client = React.useRef(null);

  React.useEffect(() => {
    async function initialize() {
      await appendScript('https://accounts.google.com/gsi/client');
      const params = {
        client_id: clientId,
        scope,
        ux_mode: uxMode ?? 'redirect',
        redirect_uri: redirectUri ?? `${window.location.origin}/oauth/callback`,
        state: 'google-drive',
        select_account: true,
      };
      if (!window.google) return;

      client.current = google.accounts.oauth2.initCodeClient({
        ...params,
        callback: async (res) => {
          console.log('res', res);
          if (res.error !== undefined) {
            onError(res);
          } else {
            onSuccess(res);
          }
        },
        error_callback: (data) => {
          if (onCancelled) {
            onCancelled(data);
          }
        },
      });
      setLoaded(true);
    }

    initialize();
  }, [clientId, onError, onSuccess, redirectUri, scope, uxMode, onCancelled]);

  function signIn() {
    if (client.current) {
      client.current.requestCode();
    }
  }

  return { loaded, signIn };
}

useGoogleLogin.propTypes = {
  clientId: PropTypes.string,
  scope: PropTypes.string,
  uxMode: PropTypes.string,
  redirectUri: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onCancelled: PropTypes.func,
};

useGoogleLogin.defaultProps = {
  redirectUri: undefined,
  onSuccess: () => {},
  onError: () => {},
};

export default useGoogleLogin;
