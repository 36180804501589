import memoizeOne from 'memoize-one';
import i18n from '../../i18n';
import { DefaultMaxFiles } from './utils';
import { uploadPublicResource } from '../../commons/PublicUploadService';
import { uploadResource } from '../../commons/CommonServices';

export function getNewErrorsAndWarnings(errors, warnings, validationResult, name) {
  const result = validationResult;
  // delete old.
  let newErrors = null;
  if (errors) {
    newErrors = { ...errors };
    delete newErrors[name];
  }
  let newWarnings = null;
  if (warnings) {
    newWarnings = { ...warnings };
    delete newWarnings[name];
  }

  let isValid = true;
  if (result) {
    const { canSubmit } = result;
    if (canSubmit) {
      newWarnings = { ...newWarnings, [name]: result };
    } else {
      isValid = false;
      newErrors = { ...newErrors, [name]: result };
    }
  }

  if (name === 'url') {
    // reset file's errors and warnings
    if (newErrors) {
      delete newErrors.file;
      delete newErrors.name;
      delete newErrors.files;
    }
    if (newWarnings) {
      delete newWarnings.file;
      delete newWarnings.name;
      delete newWarnings.files;
    }
  }
  if (name === 'file') {
    // reset url's errors and warnings
    if (newErrors) {
      delete newErrors.url;
      delete newErrors.name;
      delete newErrors.files;
    }
    if (newWarnings) {
      delete newWarnings.url;
      delete newWarnings.name;
      delete newWarnings.files;
    }
  }
  if (name === 'files') {
    // reset url's errors and warnings
    if (newErrors) {
      delete newErrors.url;
      delete newErrors.name;
      delete newErrors.file;
    }
    if (newWarnings) {
      delete newWarnings.url;
      delete newWarnings.name;
      delete newWarnings.file;
    }
  }

  if (newErrors && Object.keys(newErrors).length === 0) newErrors = null;
  if (newWarnings && Object.keys(newWarnings).length === 0) newWarnings = null;

  return { isValid, newErrors, newWarnings };
}

export function getErrorMessage(errors, property, multiple, maxFiles = null) {
  if (errors && errors[property]) {
    if (errors[property].type === 'required') {
      return i18n.t('This field is required');
    }
    if (errors[property].type === 'pattern') {
      return i18n.t('This is not a valid link. Please check again.');
    }
    if (errors[property].type === 'sameType') {
      return i18n.t('Please upload with the same file type');
    }
    if (errors[property].type === 'unsupported' || errors[property].type === 'unaccepted') {
      return i18n.t('The file format is not supported. Please try another file.');
    }
    if (errors[property].type === 'maxFiles') {
      return i18n.t(
        `Upload maximum ${multiple ? maxFiles || DefaultMaxFiles : 1} files at a time.`
      );
    }
    if (errors[property].type === 'maxFileSize') {
      const msg = errors[property].message || i18n.t('The file exceeds the maximum file size');
      return msg;
    }
    if (errors[property].type === 'signature') {
      const msg =
        errors[property].message ||
        i18n.t(
          'The file format has been changed to an unsupported file type. This can happen if the file has been downloaded from a messaging, or web service even if the title has not changed. Try to convert the file to a supported format and upload it again.'
        );
      return msg;
    }
    if (errors[property].type === 'common') {
      const msg = errors[property].message || i18n.t('Upload failed.');
      return msg;
    }
  }
  return null;
}

export async function createUploader(
  file,
  handleOnUploadResourceError,
  handleOnFileProgress,
  handleOnFileSuccess,
  handleOnFileError,
  data,
  uploadContext
) {
  const { spaceId, isPublicResource, oldResourceId, spacePrivacy, folderId } = data;
  let uploader;
  const finalOldResourceId = !oldResourceId ? null : oldResourceId;

  // start uploading
  if (isPublicResource) {
    uploader = await uploadPublicResource(file, handleOnUploadResourceError, finalOldResourceId);
  } else {
    uploader = await uploadResource(
      spaceId,
      file,
      handleOnUploadResourceError,
      finalOldResourceId,
      spacePrivacy,
      folderId,
      uploadContext
    );
  }

  const sessionId = uploader.opts.sessionId; // make sure have sessionId here.

  uploader.on('fileProgress', (f) => {
    handleOnFileProgress(f, sessionId);
  });

  uploader.on('fileSuccess', (f, message) => {
    handleOnFileSuccess(f, message, sessionId, file);
  });

  uploader.on('fileError', (f, message) => {
    handleOnFileError(f, message, sessionId);
  });

  return uploader;
}

export const getCombinedResources = memoizeOne((resources, invalidResources) => {
  return [...resources, ...invalidResources].sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  );
});
