import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { range } from '../../commons/utils';
import './MaterialsBreadcrumbs.scss';

const MaterialsBreadcrumbs = (props) => {
  if (props.data === null || props.data.path === null || props.data.pathName === null) {
    return (
      <Breadcrumbs className="materials-breadcrumbs">
        <Typography className="materials-breadcrumbs-item" color="textPrimary">
          Resources
        </Typography>
      </Breadcrumbs>
    );
  }

  const { pathName, path } = props.data;
  const paths = path.split('/');
  const pathNames = pathName.split('/');
  const pathsRange = range({ length: paths.length });

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      className="materials-breadcrumbs"
    >
      {pathsRange.map((rangeItem) => {
        if (rangeItem === pathsRange.length - 1) {
          return (
            <Typography key={rangeItem} color="textPrimary" className="materials-breadcrumbs-item">
              {pathNames[rangeItem]}
            </Typography>
          );
        }

        return (
          <Link
            variant="body2"
            className="materials-breadcrumbs-item materials-breadcrumbs-item-link"
            style={{ cursor: 'pointer' }}
            key={rangeItem}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              props.onClick(paths[rangeItem]);
            }}
          >
            {pathNames[rangeItem]}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

MaterialsBreadcrumbs.propTypes = {
  data: PropTypes.shape({
    path: PropTypes.string,
    pathName: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

MaterialsBreadcrumbs.defaultProps = {
  onClick: () => {},
};

export default MaterialsBreadcrumbs;
