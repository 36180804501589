import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceMaterialsBasicInfo } from '../services/SpaceAnalyticsService';
import { MaterialBasicInfo } from '../types/resources';

const useAllSpaceResourcesQuery = (endDate: string, spaceId?: string, cmsMaterialIds?: number[]) =>
  useQuery({
    queryKey: [CacheKeys.fetchAllSpaceResources, spaceId, endDate, cmsMaterialIds?.length],
    queryFn: () => {
      if (!spaceId || !cmsMaterialIds) {
        return [];
      }
      return fetchSpaceMaterialsBasicInfo(spaceId, cmsMaterialIds).then(
        (resp) => resp?.materials as MaterialBasicInfo[]
      );
    },
    enabled: !!spaceId && !!cmsMaterialIds,
    retry: 3,
    retryDelay: 5000,
  });

export default useAllSpaceResourcesQuery;
