import React from 'react';
import { getAvatarLabel } from '../../commons/avatarUtils';

export function getChannelName(participants, participantId) {
  let name = '';
  if (participants) {
    const list = participants.filter((p) => p.id !== participantId);
    list.forEach((item, index) => {
      if (index > 0) {
        name += ', ';
      }
      name += `${item.firstName || ''} ${item.lastName || ''}`;
    });
  }
  return name;
}

export function getSendToParticipantId(participants, participantId) {
  let id = null;
  if (participants) {
    const participant = participants.find((p) => p.userInfoId !== participantId);
    id = participant.userInfoId;
  }
  return id;
}

export function getHorizontalAvatars(participants, participantId) {
  const participantFilter = participants.filter((p) => p.id !== participantId);

  return (
    <div className="horizontal-avatars">
      {participantFilter.map((item, index) => (
        <div className="avatar-container" key={index}>
          <div className="avatar">
            <span>{getAvatarLabel(item)}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export function getVerticalAvatars(participants, participantId) {
  const participantFilter = participants.filter((p) => p.id !== participantId);
  return (
    <div className="vertical-avatars">
      {participantFilter.map((item, index) => (
        <div
          key={index}
          className="avatar-container"
          style={{ marginTop: index > 0 ? 15 : 0, marginLeft: index > 0 ? -28 : 0 }}
        >
          <div className="avatar">
            <span>{getAvatarLabel(item)}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
