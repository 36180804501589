import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import memoizeOne from 'memoize-one';
import { useMaterialsContext } from '../spaces/Materials/MaterialsContextProvider';
import { DirectoryType } from '../app/appConstants';
import i18n from '../i18n';
import './ShareVaamLocation.scss';
import { getFolderPathName } from '../commons/ResourceUtils';

const getRootFolder = memoizeOne((directories) => {
  return directories?.find((directory) => directory.directoryType === DirectoryType.Extra);
});

const getRootFolderPathInfo = memoizeOne((rootDirectory) => {
  if (!rootDirectory) return null;
  return {
    id: rootDirectory.id,
    name: rootDirectory.name,
    parentId: null,
    path: rootDirectory.id,
    pathName: 'Resources',
  };
});

const ShareVaamLocation = ({ folderId }) => {
  const [locationText, setLocationText] = useState('');
  const { directories, flatDirectories } = useMaterialsContext();

  const rootDirectory = useRef(getRootFolder(directories));
  const rootFolderPathInfo = useRef(getRootFolderPathInfo(rootDirectory.current));

  const getPathName = useCallback(() => {
    const directory = flatDirectories?.find((x) => x.id === folderId);
    console.log('directory:  ', directory);
    if (!directory || !rootFolderPathInfo.current) {
      setLocationText('');
      return;
    }
    if (directory.path === null && directory.parentFolderId === null) {
      setLocationText(
        `/${rootFolderPathInfo.current.pathName}${
          directory.id !== rootFolderPathInfo.current.id ? `/${directory.name}` : ''
        }`
      );
      return;
    }
    const path = getFolderPathName(directory);
    const pathName = path ? `/${path}` : '';

    setLocationText(`${rootFolderPathInfo.current.pathName}${pathName}/${directory.name}`);
  }, [flatDirectories, folderId]);

  useEffect(() => {
    rootDirectory.current = getRootFolder(directories);
    rootFolderPathInfo.current = getRootFolderPathInfo(rootDirectory.current);
    getPathName();
  }, [directories, flatDirectories, getPathName]);

  return (
    <div className="video-message-location">
      <Typography className="location-title ">{i18n.t('Location')}</Typography>
      <Typography className="location-path">{locationText}</Typography>
    </div>
  );
};

ShareVaamLocation.propTypes = {
  folderId: PropTypes.number,
};

export default ShareVaamLocation;
