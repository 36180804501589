import memoizeOne from 'memoize-one';
import { createFilterOptions } from '@mui/material';

import _uniqBy from 'lodash/uniqBy';
import { getMaterialsByDirectory } from '../../commons/ResourceUtils';
import { DirectoryType } from '../../app/appConstants';
import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';

const descendingComparator = memoizeOne((a, b, orderBy) => {
  // console.log('### descendingComparator', orderBy);
  let valueA = a[orderBy];
  let valueB = b[orderBy];

  if (valueB !== null && valueB !== undefined && (valueA === undefined || valueA === null))
    return -1;
  if ((valueB === undefined || valueB === null) && valueA !== null && valueA !== undefined)
    return 1;

  // if (orderBy === 'lastModified') {
  //   valueA = parseDateTimeStringFromServer(valueA);
  //   valueB = parseDateTimeStringFromServer(valueB);
  // }

  if (orderBy === 'name') {
    valueA = valueA?.toLowerCase();
    valueB = valueB?.toLowerCase();
  }

  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;

  return 0;
});

export const getComparator = memoizeOne((order, orderBy) => {
  return order === 'desc'
    ? (a, b) => {
        const t1 = descendingComparator(a, b, orderBy);
        if (t1 === 0) {
          if (orderBy === 'name') {
            return descendingComparator(a, b, 'lastModified');
          } else if (orderBy === 'noItems') {
            return -descendingComparator(a, b, 'name');
          } else if (orderBy === 'lastModified') {
            return -descendingComparator(a, b, 'name');
          }
        }
        return t1;
      }
    : (a, b) => {
        const t1 = -descendingComparator(a, b, orderBy);
        if (t1 === 0) {
          if (orderBy === 'name') {
            return descendingComparator(a, b, 'lastModified');
          } else if (orderBy === 'noItems') {
            return -descendingComparator(a, b, 'name');
          } else if (orderBy === 'lastModified') {
            return -descendingComparator(a, b, 'name');
          }
        }
        return t1;
      };
});

export const stableSort = memoizeOne((array, comparator) => {
  // console.log('### stableSort', array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // index
  });
  return stabilizedThis.map((el) => el[0]);
});

export const getAvailableItems = memoizeOne((flatDirectories, directory) => {
  if (!flatDirectories || !directory) return [];
  const directoryId = directory.id;
  const materials = [];
  const folders = [];

  const oneDriveFolders = [];
  const googleDriveFolders = [];

  const isRootFolder = directory.directoryType === DirectoryType.Extra;

  flatDirectories.forEach((element) => {
    if (
      isRootFolder || // root folder
      (element.paths?.includes(directoryId) && !element.parentFolder?.isExternal) || // sub folder
      element.id === directoryId // current folder
    ) {
      if (!element.isExternal) {
        const materialsMapping = getMaterialsByDirectory(element);
        materialsMapping?.forEach((m) => {
          materials.push({ ...m, folderId: element.id });
        });
      } else {
        const externalFolder = {
          spaceFolderId: element.id,
          spaceId: element.spaceId,
          ...element.externalFolderSettings,
        };
        if (element.externalFolderSettings.provider === SERVICE_PROVIDER.ONEDRIVE) {
          oneDriveFolders.push(externalFolder);
        } else if (element.externalFolderSettings.provider === SERVICE_PROVIDER.GOOGLE_DRIVE) {
          googleDriveFolders.push(externalFolder);
        }
      }

      // not push current folder
      if (element.id !== directoryId && !element.isHidden && !element.parentFolder?.isExternal) {
        folders.push({ ...element, isFolder: true });
      }
    }
  });
  return {
    internalsData: [...folders, ...materials],
    externalsData: {
      oneDriveFolders: _uniqBy(oneDriveFolders, 'folderId'),
      googleDriveFolders: _uniqBy(googleDriveFolders, 'folderId'),
    },
  };
});

const filter = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.name,
});

export const getFilterListByText = memoizeOne((list, keyword) => {
  if (!keyword || list.length === 0) {
    return [];
  }

  return filter(list, { inputValue: keyword })
    .sort((a, b) => {
      if (a.isFolder === b.isFolder) {
        return 0;
      }
      if (a.isFolder === true) {
        return -1;
      }
      return 1;
    })
    .map((item) => {
      let paths = ['Resources'];
      let parentFolderId = item.parentFolderId;
      const folder = item.folderId ? list.find((x) => x.id === item.folderId) : item;
      if (folder) {
        if (folder.path) {
          const segments = folder.path
            .split('/')
            .map((segment) => list.find((x) => x.id === +segment)?.name);
          paths = paths.concat(segments);
        }

        if (item.folderId && folder.directoryType !== DirectoryType.Extra) {
          paths.push(folder.name);
          parentFolderId = folder.id;
        }
      }

      return { ...item, pathName: paths.join('/'), parentFolderId };
    });
});

export const getSubFoldersId = memoizeOne((flatDirectories, directoriesId) => {
  const subFolderIds = [];
  flatDirectories.forEach((element) => {
    if (directoriesId.includes(element.parentFolderId)) {
      subFolderIds.push(element.id);
    }
  });
  return subFolderIds;
});
