import React from 'react';
import { Typography, Box } from '@mui/material';
import './SpaceAnalyticsInfoBox.scss';
import i18n from '../i18n';

type SpaceAnalyticsInfoBoxProps = {
  icon?: string | React.ReactElement;
  title: string;
  description: string;
  className?: string;
  downloadButton?: React.ReactElement;
  shareSpaceButton?: React.ReactElement;
};

function SpaceAnalyticsInfoBox({
  icon,
  title,
  description,
  className,
  downloadButton,
  shareSpaceButton,
}: SpaceAnalyticsInfoBoxProps) {
  const isElement = React.isValidElement(icon);

  function renderIcon() {
    if (isElement) {
      return icon;
    }
    const iconClassName = icon?.toString();
    return <span className={iconClassName}></span>;
  }

  return (
    <div className="info-box">
      <Box className="box-title">
        <Box className={`box ${className}`}>{renderIcon()}</Box>
        <Box className="box-info" justifyContent="center">
          <Box display="flex" alignItems="center" className="box-info-top">
            <Typography className="title">{i18n.t(title)}</Typography>
            <Box display="flex" alignItems="right">
              <Box className="share-space-btn">{shareSpaceButton}</Box>
              <Box className="download-btn">{downloadButton}</Box>
            </Box>
          </Box>
          {description && <span>{description}</span>}
        </Box>
      </Box>
    </div>
  );
}

export default SpaceAnalyticsInfoBox;
