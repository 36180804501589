import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import ConfirmationDialog from '../components/ConfirmationDialog';
import i18n from '../i18n';

let resolve;
let containerElement;
let rootEl;

const icon = (
  <span className="icon-correct">
    <span className="path1"></span>
    <span className="path2"></span>
  </span>
);

class SuccessMessageDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleClickGoToChat = this.handleClickGoToChat.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount SuccessMessageDialog');
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (containerElement) {
      rootEl.unmount();
    }
    if (resolve) resolve(retVal);
  }

  static show({ message, showGoToChat }) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(<SuccessMessageDialog message={message} showGoToChat={showGoToChat} />);
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClickGoToChat() {
    this.setState({ isOpen: false }, () => {
      SuccessMessageDialog.destroy({ showChat: true });
    });
  }

  handleConfirm() {
    this.setState({ isOpen: false }, () => {
      SuccessMessageDialog.destroy(true);
    });
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    return (
      <ConfirmationDialog
        onClickOK={this.handleConfirm}
        onClickCancel={this.handleClickGoToChat}
        okLabel="ok"
        cancelLabel={this.props.showGoToChat ? 'go to chat' : ''}
        icon={icon}
      >
        <span style={{ display: 'flex', flexDirection: 'column' }} className="message">
          <b style={{ textTransform: 'uppercase', paddingBottom: 15 }}>{i18n.t('Awesome!')}</b>
          <span>{this.props.message}</span>
        </span>
      </ConfirmationDialog>
    );
  }
}

SuccessMessageDialog.propTypes = {
  message: PropTypes.string,
  showGoToChat: PropTypes.bool,
};

SuccessMessageDialog.defaultProps = {
  showGoToChat: true,
};

export default SuccessMessageDialog;
