import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import './AssessmentsWidget.scss';
import BackgroundSpace from '../../components/BackgroundSpace';
import OfficialButton from '../../components/OfficialButtons';
import i18n from '../../i18n';
import useSpaceAssessmentsQuery from '../../assessments/queries/useSpaceAssessmentsQuery';
import StatusAssessmentTag, { getStatus } from '../../assessments/components/StatusAssessmentTag';
import Loading from '../../components/Loading';
import eventBus, { EVENT_BUS } from '../../commons/EventBus';
import queryCache, { CacheKeys } from '../../app/queryCache';
import spaceUser from '../../spaces/spaceUser';
import { useAssessmentContext } from '../../assessments/components/AssessmentContextProvider';

function AssessmentsWidget(props) {
  const { isMobile, widgetName, isEdit, isTemplate, isPortal } = props;
  const isResult = isTemplate || isEdit;
  const assessmentIds = useMemo(() => {
    return props.assessments?.map((assessment) => assessment.assessmentId) || [];
  }, [props.assessments]);

  const { spaceAssessments: assessmentsData, isLoading } = useSpaceAssessmentsQuery(
    assessmentIds,
    isPortal
  );

  const { openAssessment } = useAssessmentContext();

  const handleUserAssessmentCompleted = useCallback(
    (details) => {
      const { assessmentId, triggeredByUserInfoId } = details;
      queryCache.setQueryData(
        [CacheKeys.fetchSpaceAssessments, assessmentIds, isPortal],
        (oldData) => {
          const newData = [...oldData];
          return newData.map((item) => {
            if (
              item.assessmentId === assessmentId &&
              triggeredByUserInfoId === spaceUser.getUserInfoId()
            ) {
              const isReplace = details.score > item.percentageScore || item.state === 'NotStarted';
              const percentageScore = isReplace ? details.score : item.percentageScore;
              const id = isReplace ? details.userAssessmentId : item.id;
              return {
                ...item,
                percentageScore,
                id,
                attempts: item.attempts + 1,
                isPassed: details.isPassed,
                isCompleted: true,
              };
            }
            return item;
          });
        }
      );
    },
    [assessmentIds, isPortal]
  );

  const handleUserCredentialCompleted = useCallback(
    (details) => {
      const { assessmentId, userInfoId, userCredentialId } = details;
      queryCache.setQueryData(
        [CacheKeys.fetchSpaceAssessments, assessmentIds, isPortal],
        (oldData) => {
          const newData = [...oldData];
          return newData.map((item) => {
            if (item.assessmentId === assessmentId && userInfoId === spaceUser.getUserInfoId()) {
              return {
                ...item,
                userCredentialId: userCredentialId,
              };
            }
            return item;
          });
        }
      );
    },
    [assessmentIds, isPortal]
  );

  useEffect(() => {
    const unsubscribe = eventBus.subscribe(
      EVENT_BUS.UserAssessmentCompleted,
      handleUserAssessmentCompleted
    );
    const unsubscribeUserCredentialCompleted = eventBus.subscribe(
      EVENT_BUS.UserCredentialCompleted,
      handleUserCredentialCompleted
    );
    return () => {
      unsubscribe();
      unsubscribeUserCredentialCompleted();
    };
  }, [handleUserAssessmentCompleted, handleUserCredentialCompleted]);

  const assessments = useMemo(() => {
    const data = [];
    props.assessments?.forEach((item) => {
      const assessment = assessmentsData?.find((i) => i.assessmentId === item.assessmentId);
      if (assessment) {
        data.push(assessment);
      }
    });
    return data;
  }, [assessmentsData, props.assessments]);

  function openTryAssessment(assessment) {
    console.log('### openTryAssessment', assessment);
    openAssessment({
      id: assessment.assessmentId,
      type: 'trial',
      isMobile: isMobile,
    });
  }
  function openViewResult(assessment) {
    console.log('### openViewResult', assessment);
    openAssessment({
      id: assessment.assessmentId,
      userAssessmentId: assessment.id,
      type: 'result',
    });
  }
  async function runTest(assessment) {
    openAssessment({
      id: assessment.assessmentId,
      userAssessmentId: assessment.id,
      type: 'start',
    });
  }

  function renderTag(assessment) {
    return <StatusAssessmentTag assessment={assessment} isResult={isResult} />;
  }
  function renderInfoAssessment(assessment) {
    let description;
    const state = getStatus(assessment, isResult);
    const scoreToPass = !assessment.percentageScoreToPass
      ? 'No required score to pass'
      : `To pass, you need ${assessment.percentageScoreToPass * 100}%`;

    const score = assessment.percentageScore
      ? `${Math.round(assessment.percentageScore * 100)}%`
      : '0%';

    switch (state) {
      case 'NotStarted':
        description = 'You haven’t started this yet';
        break;
      // case 'NotSubmitted':
      //   description = 'You have not submitted the assessment.';
      //   break;
      case 'Passed':
        description = `You scored ${score}. ${scoreToPass}.`;
        break;
      case 'Failed':
        description = `You scored ${score}. ${scoreToPass}.`;
        break;
      default:
        description = 'In spaces, result will be available here.';
        break;
    }
    return <div className="item-info">{description}</div>;
  }
  function renderButtons(assessment) {
    const state = getStatus(assessment, isResult);
    const reachToAttempt = assessment.maxNoOfAttempts
      ? assessment.attempts >= assessment.maxNoOfAttempts
      : false;

    let buttons;

    switch (state) {
      case 'NotStarted':
        buttons = (
          <OfficialButton
            onClick={() => runTest(assessment)}
            variant="regular-primary"
            label={i18n.t('Start')}
          />
        );
        break;
      // case 'NotSubmitted':
      //   return (
      //     <OfficialButton
      //       onClick={() => runTest(assessment)}
      //       variant="regular-primary"
      //       label={i18n.t('try again')}
      //     />
      //   );
      case 'Passed':
        buttons = (
          <OfficialButton
            onClick={() => openViewResult(assessment)}
            variant="regular-secondary"
            label={i18n.t('View result')}
          />
        );
        break;
      case 'Failed':
        buttons = (
          <div className="group-buttons">
            {!reachToAttempt && (
              <OfficialButton
                onClick={() => runTest(assessment)}
                variant="regular-primary"
                label={i18n.t('Try again')}
              />
            )}
            <OfficialButton
              onClick={() => openViewResult(assessment)}
              variant="regular-secondary"
              label={i18n.t('View result')}
            />
          </div>
        );
        break;
      default:
        return (
          <OfficialButton
            onClick={() => openTryAssessment(assessment)}
            variant="regular-secondary"
            label={i18n.t('Run trial')}
          />
        );
    }
    return (
      <>
        {buttons}
        {reachToAttempt && state === 'failed' && (
          <div className="text-gray">{`You reached maximum ${assessment.maxNoOfAttempts} attempts`}</div>
        )}
      </>
    );
  }

  function renderContent() {
    if (isLoading) {
      return (
        <div className="loading-box">
          <Loading />
        </div>
      );
    }
    if (!assessments || assessments.length === 0) {
      return <div className="text-center"> {i18n.t('No assessments yet.')}</div>;
    }

    return (
      <div className="cms-assessments-widget-items">
        {assessments.map((assessment) => (
          <div key={assessment.assessmentId} className="cms-assessments-widget-item">
            <div className="left-section">
              <BackgroundSpace
                alt="Space"
                className="customer-background"
                src={assessment.thumbnailResourceUrl}
                title={assessment.name}
                height={72}
              />
              <div className="info-section">
                <div className="item-name">{assessment.assessmentName}</div>
                {renderTag(assessment)}
                {renderInfoAssessment(assessment)}
              </div>
            </div>
            <div className="right-section">{renderButtons(assessment)}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={`cms-assessments-widget ${isMobile ? 'cms-assessments-widget-mobile' : ''}`}>
      <div className={`positionRelative`}>
        <div className="cms-assessments-widget position0">
          <div className="cms-assessments-widget-header">{widgetName}</div>
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

AssessmentsWidget.propTypes = {
  isMobile: PropTypes.bool,
  isPortal: PropTypes.bool,
  isTemplate: PropTypes.bool,
  widgetName: PropTypes.string,
  isEdit: PropTypes.bool,
  assessments: PropTypes.arrayOf(Object),
};

export default AssessmentsWidget;
