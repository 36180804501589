import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import './TablePagination.scss';
import { useResourcesViewContext } from '../components/ResourcesView/ResourcesViewContextProvider';

function TablePagination(props) {
  const {
    info,
    // page,
    rowCount,
    // rowsPerPage,
    rowsPerPageOptions,
    // handleChangePage,
    // handleChangeRowsPerPage,
  } = props;

  const { page, pageSize, setPage, setPageSize } = useResourcesViewContext();

  if (rowCount <= pageSize) {
    // have only 1 page, dont show the pagination
    return null;
  }

  const handleChangePage = (event, newPage) => {
    // setSearchItemSelectedList([]);
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log('### changed page size', event.target);
    const newRowsPerPage = parseInt(event.target.value);
    setPageSize(newRowsPerPage);
    setPage(0);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className="table-pagination"
    >
      <Pagination
        page={page + 1}
        siblingCount={0}
        count={Math.ceil(rowCount / pageSize)}
        onChange={handleChangePage}
        variant="outlined"
        shape="rounded"
      />
      <Box display="flex" alignItems="center" gap={3}>
        <span className="info">{info}</span>
        <Select
          variant="outlined"
          name="rowsPerPage"
          className="select-rows-per-page"
          value={pageSize}
          onChange={handleChangeRowsPerPage}
          MenuProps={{
            classes: { paper: 'select-menu select-menu-top' },
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
        >
          {rowsPerPageOptions.map((op, idx) => (
            <MenuItem key={idx} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
}

TablePagination.propTypes = {
  info: PropTypes.string,
  // page: PropTypes.number,
  rowCount: PropTypes.number,
  // rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  // handleChangePage: PropTypes.func,
  // handleChangeRowsPerPage: PropTypes.func,
};

TablePagination.defaultProps = {
  rowsPerPageOptions: [10, 25, 50],
};

export default TablePagination;
