import React from 'react';
import PropTypes from 'prop-types';
import './SavingBlocker.scss';

function SavingBlocker(props) {
  const { message } = props;
  return (
    <div className="saving-blocker-container">
      <div className="bg" />
      <div className="message">
        <i className="icon-spinner" />
        <p>{message}</p>
      </div>
    </div>
  );
}

SavingBlocker.propTypes = {
  message: PropTypes.string,
};

export default SavingBlocker;
