import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import ResourceThumbnail from '../../components/ResourceThumbnail';
import i18n from '../../i18n';
import { getOneDriveResourceId } from './onedriveService';
import './OneDriveResourcePreviewPane.scss';
import PreviewResources from '../components/PreviewResource';
import { SERVICE_PROVIDER } from '../integrationConstants';

const OneDriveResourcePreviewPane = (props) => {
  const {
    id,
    name,
    resourceType,
    lastModified,
    description,
    src,
    driveId,
    // views,
    // totalSpaces,
    // totalUsage,
    isAdmin,
    context,
    extension,
    isExternal,
    thumbnail,
  } = props;
  const isPortal = context?.type === 'portal';

  const { data: resourceId } = useQuery({
    queryKey: [CacheKeys.getOneDriveResourceId, driveId, id],
    queryFn: async () => {
      const resp = await getOneDriveResourceId(id);
      return resp?.resourceId;
    },
    enabled: true,
  });

  function renderViewer() {
    return (
      <ResourceThumbnail
        resourceId={id}
        // driveId={driveId}
        isPortal={isPortal}
        resourceType={resourceType}
        thumbnail={thumbnail}
        extension={extension}
        src={src}
        isExternal={isExternal}
        provider={SERVICE_PROVIDER.ONEDRIVE}
        inView
      />
    );
  }

  if (!id) {
    return (
      <div className="no-selected-file">
        {i18n.t('Select a file or folder to view the details')}
      </div>
    );
  }
  return (
    <Box className="onedrive-resource-preview-pane">
      <div className="preview-pane-title text-truncate">
        <span title={name} className="bold">
          {name}
        </span>
      </div>
      <div className="previewer">{renderViewer()}</div>
      <PreviewResources
        type={resourceType}
        src={src}
        modified={lastModified}
        resourceId={resourceId}
        description={isAdmin && isPortal ? description : ''}
      />

      {/* <Grid item xs={5}>
          <strong className="field-name">Total spaces:</strong>
        </Grid>
        <Grid item xs={7}>
          <div>{totalSpaces}</div>
        </Grid>
        <Grid item xs={5}>
          <strong className="field-name">Total usage:</strong>
        </Grid>
        <Grid item xs={7}>
          <div>{totalUsage}</div>
        </Grid>
        <Grid item xs={5}>
          <strong className="field-name">Total views:</strong>
        </Grid>
        <Grid item xs={7}>
          <div>{views}</div>
        </Grid> */}
    </Box>
  );
};

OneDriveResourcePreviewPane.propTypes = {
  id: PropTypes.string,
  driveId: PropTypes.string,
  name: PropTypes.string,
  resourceType: PropTypes.number,
  lastModified: PropTypes.string,
  description: PropTypes.string,
  src: PropTypes.string,
  isAdmin: PropTypes.bool,
  context: PropTypes.instanceOf(Object),
  extension: PropTypes.string,
  isExternal: PropTypes.bool,
  thumbnail: PropTypes.string,
  // views: PropTypes.number,
  // totalSpaces: PropTypes.number,
  // totalUsage: PropTypes.number,
};

OneDriveResourcePreviewPane.defaultProps = {
  views: 0,
  totalSpaces: 0,
  totalUsage: 0,
  isExternal: false,
};

export default OneDriveResourcePreviewPane;
