import React from 'react';
import { Checkbox } from '@mui/material';
import './style.scss';
import { UserCheckboxListProps, UserOption } from './type';
import UserCheckBoxItem from './UserCheckBoxItem';

function UserCheckboxList(props: UserCheckboxListProps) {
  console.log('props.users', props.users);
  const { selectedUsers, users } = props;
  const [selectedAll, setSelectedAll] = React.useState<boolean>(
    users.length === selectedUsers.length
  );

  function handleChange(user: UserOption, selected: boolean) {
    const newSelectedUsers = selected
      ? [...props.selectedUsers, user]
      : props.selectedUsers.filter((selectedUser) => selectedUser.id !== user.id);
    props.onChange(newSelectedUsers);
    // update corresponding selectedAll state base on selectedUsers
    setSelectedAll(newSelectedUsers.length === users.length);
  }

  function onSelectedAllChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedAll(event.target.checked);
    props.onChange(event.target.checked ? props.users : []);
  }

  return (
    <>
      <div className="user-checkbox-list-header">
        <p>
          {selectedUsers.length}/{users.length} selected
        </p>
        <Checkbox color="primary" checked={selectedAll} onChange={onSelectedAllChanged} />
      </div>
      <div className="user-checkbox-list-body">
        {users.map((user) => (
          <UserCheckBoxItem
            key={user.email}
            user={user}
            selected={selectedUsers.some((selectedUser) => selectedUser.id === user.id)}
            onChange={handleChange}
          />
        ))}
      </div>
    </>
  );
}

export default UserCheckboxList;
