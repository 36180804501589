import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { getUsersByAssessmentId } from '../services/SpaceAnalyticsService';

const useAssessmentDetailsUsersQuery = (
  spaceId: string | undefined,
  assessmentId: string,
  endDate: string,
  excludeUserIds: number[],
  enabled: boolean
) => {
  const getAssessmentUserDetails = useQuery({
    queryKey: [CacheKeys.spaceAnalytics_FetchUsersByAssessmentById, spaceId, assessmentId, endDate],
    queryFn: async () => {
      if (!spaceId || !assessmentId) {
        return [];
      }
      const resp = await getUsersByAssessmentId(assessmentId, endDate, excludeUserIds);
      return resp;
    },

    retry: 3,
    retryDelay: () => 5000,
    enabled: enabled,
  });

  const isFetching = getAssessmentUserDetails.isFetching;
  const data = getAssessmentUserDetails.data ? getAssessmentUserDetails.data?.items : [];

  return { data, isFetching };
};

export default useAssessmentDetailsUsersQuery;
