import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { copyTextToClipboard, sendNotification } from '../commons/utils';
import './CopyShareLink.scss';
import OfficialButton from './OfficialButtons';
import i18n from '../i18n';
import { shareSpace } from '../homepage/spaceService';
import spaceUser from '../spaces/spaceUser';
import { useShareSpaceCode } from './CopyResourceShareLink';

const CopyShareLink = ({ shareableLink, onShare, label, spaceId, isPortal }) => {
  const id = spaceId || spaceUser.getSpaceId();
  const { isLoading } = useShareSpaceCode();

  async function onClick() {
    copyTextToClipboard(shareableLink);
    sendNotification(i18n.t('Link copied!'), { duration: 3000 });
    if (onShare) {
      onShare();
    }
    // track share event
    const sharedCode = spaceUser.getShareSpaceCode();
    await shareSpace(id, sharedCode, true, isPortal);
  }

  return (
    <div className="copy-link">
      <TextField
        InputProps={{
          readOnly: true,
        }}
        className="generic-url-text"
        color="primary"
        variant="outlined"
        value={shareableLink}
      />
      <OfficialButton
        disabled={isLoading}
        label={label}
        variant="regular-secondary"
        className="btn-copy-link"
        type="submit"
        onClick={onClick}
      />
    </div>
  );
};

CopyShareLink.propTypes = {
  shareableLink: PropTypes.string,
  onShare: PropTypes.func,
  label: PropTypes.string,
  spaceId: PropTypes.string,
  isPortal: PropTypes.bool,
};

CopyShareLink.defaultProps = {
  label: i18n.t('COPY'),
};

export default CopyShareLink;
