import RuntimeApi from '../commons/RuntimeApi';
import Api from '../commons/api';
import queryCache, { CacheKeys } from '../app/queryCache';
import spaceUser from './spaceUser';
import authService from '../components/api-authorization/AuthorizeService';
import currentOrganization from '../commons/CurrentOrganization';
import { getCoreApi, getCoreRuntimeApi, getUrlParameter } from '../commons/utils';
import { getDeviceId } from './useSpaceAcessInfoQuery';

export async function getAndStoreRealIp() {
  try {
    if (sessionStorage.getItem('realIp')) {
      return;
    }
    const resp = await fetch(`https://www.cloudflare.com/cdn-cgi/trace`);
    if (!resp.ok) {
      return;
    }
    const text = await resp.text();
    const regex = /ip=([^\n]+)/;
    const match = regex.exec(text);
    const realIp = match ? match[1] : null;
    if (realIp) {
      sessionStorage.setItem('realIp', realIp);
    }
  } catch (error) {
    console.error(error);
  }
}

export async function fetchSpaceAcessInfo(spaceUrlSlug) {
  await getAndStoreRealIp();
  const rs = await RuntimeApi.get(`${getCoreRuntimeApi()}/runtime/spaces/${spaceUrlSlug}/access`);
  return rs;
}

export function signUpSpaceUser(spaceId, data, isViewOnly = false) {
  if (isViewOnly) {
    return RuntimeApi.get(`${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/join`, data);
  }
  return RuntimeApi.post(`${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/join`, data);
}

export async function getPublicSpaceAccessToken(spaceId, visitorId) {
  const resp = await RuntimeApi.post(
    `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/access/public-token`,
    {
      visitorId,
    }
  );
  return resp;
}

export async function getSpaceAccessToken(
  organizationId,
  spaceId,
  code,
  checksum = null,
  firstName = null,
  lastName = null
) {
  const shareSpaceCode = getUrlParameter('c');
  // console.log('shareSpaceCode', shareSpaceCode);
  let resp = null;
  if (!code) {
    resp = await RuntimeApi.get(
      `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/access/anonymous-token`,
      {
        shareSpaceCode: shareSpaceCode ?? '',
      }
    );
  } else {
    const isAuthenticated = await authService.isAuthenticated();
    const currentOrganizationId = currentOrganization?.getOrganizationId();

    const params = {
      code,
      pin: spaceUser.getPin(),
      checksum: checksum || spaceUser.getChecksum(),
      shareSpaceCode: shareSpaceCode ?? '',
      device: getDeviceId(),
    };
    if (firstName && firstName !== 'null') {
      params.firstName = firstName;
    }
    if (lastName && lastName !== 'null') {
      params.lastName = lastName;
    }

    if (isAuthenticated && organizationId === currentOrganizationId) {
      resp = await Api.get(`${getCoreApi()}/spaces/${spaceId}/access/token`, params);
    } else {
      resp = await RuntimeApi.get(
        `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/access/token`,
        params
      );
    }
  }
  return resp;
}

export function createSpaceUserSession(spaceId, data) {
  // return a space session id;
  return RuntimeApi.post(`${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/users/session`, data);
}

export function fetchSpaceInvitation(spaceId, shouldCreateHiddenUser = false) {
  return Api.get(
    `${getCoreApi()}/spaces/${spaceId}/invitation/fetch?shouldCreateHiddenUser=${shouldCreateHiddenUser}`
  );
}

export function createSpaceInvitation(spaceId, role) {
  if (!!spaceUser.getSpaceId() && spaceUser.getSpaceId() !== spaceId) {
    queryCache.removeQueries({
      queryKey: [CacheKeys.getSpaceAccessToken, spaceUser.getSpaceId(), spaceUser.getInvc()],
      exact: true,
    });
    spaceUser.reset();
  }
  return Api.post(`${getCoreApi()}/spaces/${spaceId}/invitation`, { role });
}

export function createSpaceAccessibility(spaceId, shouldCreateHiddenUser = false) {
  return Api.get(
    `${getCoreApi()}/spaces/${spaceId}/accessibility?shouldCreateHiddenUser=${shouldCreateHiddenUser}`
  );
}

export function createSpaceInvitationByCode(code) {
  return Api.post(`${getCoreApi()}/spaces/invitation`, { code });
}

export async function joinSpace(spaceId) {
  try {
    if (sessionStorage.getItem('joinedSpace') === spaceId) {
      return true;
    }
    sessionStorage.setItem('joinedSpace', spaceId);
    await RuntimeApi.post(`${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/users/join`, {});
  } catch (e) {
    console.error(e);
  }
  return true;
}

export function getJoinedSpaceUsers(spaceId) {
  return RuntimeApi.get(`${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/users/joined`);
}
