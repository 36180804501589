import React from 'react';
import { getUrlParameter } from '../../commons/utils';
import i18n from '../../i18n';
import authService from './AuthorizeService';
import { getReturnUrl } from './AuthServices';

function LogoutCallback() {
  React.useEffect(() => {
    async function handleLogoutCallback() {
      await authService.clearData();
      if (getUrlParameter('autoClose') === '1') {
        window.close();
        return;
      }
      window.location = getReturnUrl();
    }

    handleLogoutCallback();
  }, []);

  return <div className="authorization-container">{i18n.t('Processing logout')}</div>;
}

export default LogoutCallback;
