import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import i18n from '../../i18n';

export interface IUploadCompletedProps {
  onDelete: () => void;
  folderName: string;
  uploadedFiles?: number;
  totalFiles: number;
}

export function UploadCompleted(props: IUploadCompletedProps) {
  const { folderName, uploadedFiles, totalFiles, onDelete } = props;

  return (
    <div className="uploader-completed">
      <div className="uploaded-folder">
        <div className="folder-name">
          <span className="icon-folder"></span>
          <span className="folder-name-text">{folderName}</span>
        </div>
        <IconButton
          className="delete-icon-button"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onDelete();
          }}
        >
          <DeleteIcon className="delete-icon" />
        </IconButton>
      </div>
      {totalFiles ? (
        <div className="text">
          {uploadedFiles} {i18n.t('of')} {totalFiles} {i18n.t('items uploaded successfully.')}
        </div>
      ) : null}
    </div>
  );
}
