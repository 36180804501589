/* eslint no-bitwise: 0 */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CustomSigalRHttpClient from '../commons/CustomSigalRHttpClient';
import spaceUser from './spaceUser';
import { getLMSRuntimeApi } from '../commons/utils';
import eventBus, { EVENT_BUS } from '../commons/EventBus';
import queryCache, { CacheKeys } from '../app/queryCache';
import { SERVICE_PROVIDER } from '../integrations/integrationConstants';

const signalR = require('@microsoft/signalr');

function bindConnectionMessage(connection, spaceId) {
  // Listen messages
  connection.on('UserAssessmentCompleted', (message) => {
    eventBus.publish(EVENT_BUS.UserAssessmentCompleted, message);
  });
  connection.on('UserCredentialCompleted', (message) => {
    eventBus.publish(EVENT_BUS.UserCredentialCompleted, message);
    queryCache.removeQueries({
      queryKey: [
        CacheKeys.getDriveFolderInfo,
        null,
        'my-certificates',
        spaceId,
        SERVICE_PROVIDER.LMS,
      ],
      exact: true,
    });
  });
}

function createConnection() {
  const url = `${getLMSRuntimeApi()}/space`;
  return new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => {
        return spaceUser.getAccessToken();
      },
      httpClient: new CustomSigalRHttpClient(),
    })
    .withAutomaticReconnect()
    .build();
}

class SpaceLMSSignalRHandler extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      connectionHub: null,
    };
    this.connection = null;
    this.shouldStopConnection = false;
  }

  async componentDidMount() {
    if (this.shouldStopConnection) {
      this.cleanUpConnection();
      this.shouldStopConnection = false;
      return;
    }
    this.connection = createConnection();

    this.connection
      .start()
      .then(() => {
        this.setState({ connectionHub: this.connection });
        if (this.props.onConnected) {
          this.props.onConnected(this.connection);
        }
        bindConnectionMessage(this.connection, this.props.spaceId);
      })
      .catch((error) => {
        this.shouldStopConnection = true;
        console.log(`SignalRHandler Fail: ${error.message}`);
      });
  }

  async cleanUpConnection() {
    if (
      (this.connection && this.connection?.state === 'Connecting') ||
      this.connection?.state === 'Reconnecting'
    ) {
      this.shouldStopConnection = true;
      return;
    }
    if (!this.connection) return;

    this.connection.off();
    await this.connection.stop();
    this.connection = null;
  }

  componentWillUnmount() {
    this.cleanUpConnection();
  }

  render() {
    return null;
  }
}

SpaceLMSSignalRHandler.propTypes = {
  onConnected: PropTypes.func,
  spaceId: PropTypes.string,
};

export default SpaceLMSSignalRHandler;
