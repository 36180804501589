import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getPlayer } from '../../commons/ResourceUtils';
import { openWindow } from '../../commons/utils';
import UrlResourcePreviewDialog from '../../resources/UrlResourcePreviewDialog ';
import FormHelper from './FormHelper';
import { getErrorMessage } from './DndFileUploaderUtils';
import FilenameInput from './FilenameInput';
import UploaderLabel from '../DndFolderUploader/UploaderLabel';

const ExternalUrlInput = ({
  url,
  fileName,
  multiple,
  errors,
  warnings,
  canPreview,
  canEditNameSameStep,
  urlInputPlaceHolder,
  fileNameInputPlaceHolder,
  disableUrlInput,
  fileNameInputLabel,
  urlInputLabel,
  urlInputInfo,
  cropImageElement,
  handleInputChange,
  renderWarningMessage,
}) => {
  const showCropperButtonForUnsplashImages = url && url.includes('images.unsplash');

  const handleClearInputButtonClick = () => {
    handleInputChange({ target: { name: 'url', value: '' } });
  };

  const handleInputBlur = (event) => {
    const { value } = event.target;
    if (value !== url) {
      handleInputChange(event);
    }
  };

  const renderErrorMessage = (field) => {
    return getErrorMessage(errors, field, multiple);
  };

  return (
    <>
      <div className={`dnd-file-uploader-control ${disableUrlInput ? 'disabled' : ''}`}>
        <UploaderLabel
          htmlFor="external-url-input"
          label={urlInputLabel}
          tooltip={urlInputInfo}
          tooltipClasses={{ tooltip: 'url-input-tooltip' }}
          element={showCropperButtonForUnsplashImages && cropImageElement}
        />
        <TextField
          id="external-url-input"
          // maxLength={200}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (canPreview && url?.length > 0 && !errors?.url) {
              const { type, isVideo } = getPlayer(url);
              if (!isVideo && type !== 'miro' && type !== 'mentimeter') {
                openWindow(url, '_blank');
                return;
              }
              UrlResourcePreviewDialog.show(url);
            }
          }}
          name="url"
          value={url}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          placeholder={urlInputPlaceHolder}
          autoComplete="off"
          className={`dnd-file-uploader-control-input ${
            canPreview && url?.length > 0 && !errors?.url ? 'text-underline' : ''
          } ${warnings?.url ? 'red-border' : ''}`}
          variant="outlined"
          InputProps={{
            endAdornment: url?.length > 0 && (
              <InputAdornment position="end">
                <div className="action-buttons">
                  <IconButton
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      handleClearInputButtonClick();
                    }}
                  >
                    <DeleteIcon className="delete-icon" />
                  </IconButton>
                </div>
              </InputAdornment>
            ),
          }}
          error={!!errors?.url}
          helperText={renderErrorMessage('url')}
          onFocus={() => {
            console.log('This is a trick to show a tooltip for this TextField!');
          }}
          disabled={disableUrlInput}
        />
        {/* </LightTooltip> */}
        <FormHelper show={!!warnings?.url} helperText={renderWarningMessage('url')} />
      </div>
      {canEditNameSameStep && (
        <div className={`dnd-file-uploader-control ${disableUrlInput ? 'disabled' : ''}`}>
          <FilenameInput
            value={fileName}
            multiple={multiple}
            disabled={disableUrlInput || !url?.length}
            errors={errors}
            fileNameInputPlaceHolder={fileNameInputPlaceHolder}
            fileNameInputLabel={fileNameInputLabel}
            handleInputChange={handleInputChange}
          />
        </div>
      )}
    </>
  );
};

ExternalUrlInput.propTypes = {
  url: PropTypes.string,
  fileName: PropTypes.string,
  multiple: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
  warnings: PropTypes.instanceOf(Object),
  canPreview: PropTypes.bool,
  canEditNameSameStep: PropTypes.bool,
  urlInputPlaceHolder: PropTypes.string,
  fileNameInputPlaceHolder: PropTypes.string,
  disableUrlInput: PropTypes.bool,
  fileNameInputLabel: PropTypes.string,
  urlInputLabel: PropTypes.string,
  urlInputInfo: PropTypes.node,
  cropImageElement: PropTypes.element,
  handleInputChange: PropTypes.func,
  renderWarningMessage: PropTypes.func,
};

export default ExternalUrlInput;
