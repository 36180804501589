import React from 'react';
import moment from 'moment';
import { ISpaceUser } from '../../types/User';
import Loading from '../../components/Loading';
import { formatDateTime, parseDateTimeStringFromServer } from '../../commons/DateTimeUtils';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
// import i18n from '../../i18n';
// import LightTooltip from '../../components/LightTooltip';
import Table from '../components/Table';
import Icons from '../../components/Icons';
import useAssessmentsByUserQuery from '../queries/useAssessmentsByUserQuery';
import StatusAssessmentTag from '../../assessments/components/StatusAssessmentTag';
import { IUserAssessment } from '../AssessmentTable/types';

const emptyState = {
  icon: <Icons className="icon-assessments" />,
};

type AssessmentsByUserProps = {
  userInfo: ISpaceUser;
};

function AssessmentsByUser(props: AssessmentsByUserProps) {
  const { userInfo } = props;
  const { space, reportEndDate } = useSpaceAnalyticsContext();
  const spaceId = space?.id;
  const endDate = moment(reportEndDate).toDate().toISOString();

  const { data, isFetching } = useAssessmentsByUserQuery(
    spaceId,
    userInfo.id,
    endDate,
    !!spaceId && !!userInfo.id
  );

  const countPassedAssessments = data?.filter(
    (assessment: IUserAssessment) => assessment.isPassed
  )?.length;

  function renderTable() {
    return (
      <Table
        columns={[
          {
            title: 'Name',
            field: 'assessmentName',
            cellStyle: {
              width: '30%',
            },
            render: (rowData: IUserAssessment) => {
              return rowData.assessmentName;
            },
          },
          {
            title: 'Status',
            field: 'isPassed',
            cellStyle: {
              width: '25%',
            },
            render: (rowData: IUserAssessment) => {
              const assessment = { ...rowData, isCompleted: true };
              return <StatusAssessmentTag assessment={assessment} />;
            },
          },
          {
            title: 'Score',
            field: 'percentageScore',
            cellStyle: {
              width: '25%',
            },
            render: (rowData: IUserAssessment) => {
              return rowData?.percentageScore ? (
                `${Math.round(rowData.percentageScore * 100)}%`
              ) : (
                // <LightTooltip title={i18n.t('The user hasn’t taken the assessment yet.')}>
                <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
                // </LightTooltip>
              );
            },
          },
          {
            title: 'Submitted Date',
            field: 'completedOn',
            cellStyle: {
              width: '20%',
            },
            render: (rowData: IUserAssessment) => {
              return rowData ? (
                formatDateTime(
                  parseDateTimeStringFromServer(rowData.completedOn)?.local(),
                  'MMM DD, yyyy'
                )
              ) : (
                // <LightTooltip title={i18n.t('The user hasn’t taken the assessment yet.')}>
                <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
                // </LightTooltip>
              );
            },
          },
        ]}
        data={data}
        emptyState={emptyState}
      />
    );
  }

  if (isFetching) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <div className="tab-content-container meetings-by-user-container">
      <p className="short-description">
        {userInfo.fullName} has passed <b>{countPassedAssessments} </b>
        {countPassedAssessments <= 1 ? 'assessment' : 'assessments'}
      </p>
      {renderTable()}
    </div>
  );
}

export default AssessmentsByUser;
