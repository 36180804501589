import React from 'react';
import PropTypes from 'prop-types';
import { SERVICE_PROVIDER } from './integrationConstants';
import OneDriveResourceViewer from './onedrive/OneDriveResourceViewer';
import { getIntegrationResourceDetails } from './integrationResourceUtils';
import GoogleDriveResourceViewer from './googleDrive/GoogleDriveResourceViewer';

const IntegrationResourceViewer = (props) => {
  const {
    resourceId,
    materialId,
    src,
    name,
    type,
    externalViewerToken,
    publicUrl,
    autoPlay,
    showDownloadView,
    showInfoView,
    isPortal,
    extension,
    onClose,
  } = props;
  const { provider } = getIntegrationResourceDetails(src);

  function renderViewer() {
    switch (provider) {
      case SERVICE_PROVIDER.ONEDRIVE:
        return (
          <OneDriveResourceViewer
            resourceId={resourceId}
            materialId={materialId}
            src={src}
            type={type}
            name={name}
            externalViewerToken={externalViewerToken}
            publicUrl={publicUrl}
            autoPlay={autoPlay}
            showDownloadView={showDownloadView}
            showInfoView={showInfoView}
            isPortal={isPortal}
            onDownloaded={props.onDownloaded}
            extension={extension}
            fileSize={props.fileSize}
            onClose={onClose}
          />
        );
      case SERVICE_PROVIDER.GOOGLE_DRIVE:
        return (
          <GoogleDriveResourceViewer
            resourceId={resourceId}
            materialId={materialId}
            src={src}
            type={type}
            name={name}
            externalViewerToken={externalViewerToken}
            autoPlay={autoPlay}
            showDownloadView={showDownloadView}
            showInfoView={showInfoView}
            isPortal={isPortal}
            publicUrl={publicUrl}
            onDownloaded={props.onDownloaded}
            extension={extension}
            fileSize={props.fileSize}
            onClose={onClose}
          />
        );
      default:
        return <div>Coming soon</div>;
    }
  }

  return renderViewer();
};

IntegrationResourceViewer.propTypes = {
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  materialId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  type: PropTypes.number,
  externalViewerToken: PropTypes.string,
  name: PropTypes.string,
  publicUrl: PropTypes.string,
  autoPlay: PropTypes.bool,
  showDownloadView: PropTypes.bool,
  showInfoView: PropTypes.bool,
  isPortal: PropTypes.bool,
  onDownloaded: PropTypes.func,
  extension: PropTypes.string,
  fileSize: PropTypes.string,
  onClose: PropTypes.func,
};

IntegrationResourceViewer.defaultProps = {
  showDownloadView: false,
  showInfoView: false,
};

export default IntegrationResourceViewer;
