/* eslint react-hooks/exhaustive-deps: 0 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import { isIOS, isMobile } from 'react-device-detect';
import { getHttpsUrl } from '../commons/utils';
import { getPlayer, parseYoutubePlaylistLink, getMediaKindConfig } from '../commons/ResourceUtils';
import VideoPlayer from './VideoPlayer';
import MiroPlayer from './MiroPlayer';
import ResourceExternalUrlViewer from './ResourceExternalUrlViewer';
import MentimeterPlayer from './MentimeterPlayer';
import { createResourceInteraction } from '../commons/CommonServices';
import {
  ResourceInteractionType,
  ResourceTypeNames,
  SpaceInteractionType,
  VideoResourceViewedBatchTime,
} from '../app/appConstants';
import eventBus, { EVENT_BUS } from '../commons/EventBus';

class ExternalResourceViewer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      videoIndexer: props.videoIndexer,
    };
    const { type } = getPlayer(props.src);
    this.src = getHttpsUrl(props.src);
    console.log('### url', props.src, this.src);

    this.externalPlayerConfig = {};
    if (type === 'youtube') {
      const result = parseYoutubePlaylistLink(this.src);
      console.log('### parsed youtube playlist', result, props.videoIndexer);
      this.src = result.src;
      this.externalPlayerConfig = result.config;
    } else if (type === 'external_recording') {
      this.externalPlayerConfig = props.externalPlayerConfig;
    } else if (type === 'file') {
      this.externalPlayerConfig = { attributes: { className: 'vjs-tech' } };
    } else if (type === 'mediakind') {
      const { src, config } = getMediaKindConfig(
        this.src,
        props.laUrl,
        props.token,
        isMobile,
        isIOS
      );
      this.src = src;
      this.externalPlayerConfig = config;
    }

    this.playerRef = React.createRef();
    this.handleOnVideoIndexed = this.handleOnVideoIndexed.bind(this);
    this.handleOnLoaded = this.handleOnLoaded.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnChangeDebounced = _debounce(
      (isPlaying, currentTime) => this.handleOnChange({ isPlaying, currentTime }),
      300
    );
    this.reloadReactPlayer = this.reloadReactPlayer.bind(this);
    this.handleSentViewedEventToInsight = this.handleSentViewedEventToInsight.bind(this);
    this.isRecordingFile = props.isRecordingFile;
    this.handleOnVideoPlayed = this.handleOnVideoPlayed.bind(this);
    this.getCurrentState = this.getCurrentState.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.seekTo = this.seekTo.bind(this);
  }

  handleOnVideoIndexed(detail) {
    console.log('onVideoIndexed', detail);
    const { id, state, accessToken } = detail;
    if (this.state.videoIndexer.videoId !== id) {
      return;
    }
    this.setState({
      videoIndexer: { ...this.state.videoIndexer, state, accessToken },
    });
  }

  handleOnLoaded() {
    // const { type } = getPlayer(this.props.src);
    // this.props.onLoaded(type);
    this.props.onLoaded();
  }

  handleOnVideoPlayed() {
    if (this.props.resourceId && this.props.materialId) {
      createResourceInteraction(this.props.resourceId, {
        materialId: this.props.materialId,
        interactionType: ResourceInteractionType.Click,
        data: JSON.stringify({
          resourceType: ResourceTypeNames.video,
        }),
      });
    }
  }

  handleOnChange(data) {
    console.log('### ExternalResourceViewer handleOnChange', data);
    if (this.props.onChange) {
      this.props.onChange(data);
    }
    eventBus.publish(EVENT_BUS.VideoStateChanged, data);
  }

  getCurrentState() {
    const { type } = getPlayer(this.props.src);
    if (type) {
      return this.playerRef.current?.getCurrentState();
    }
    return null;
  }

  play() {
    const { type } = getPlayer(this.props.src);
    if (type) {
      console.log('### play');
      this.playerRef.current?.play();
    }
  }

  seekTo(time, type = null) {
    const player = getPlayer(this.props.src);
    if (player?.type) {
      console.log('### seekTo', time);
      this.playerRef.current?.seekTo(time, type);
    }
  }

  handleSentViewedEventToInsight(totalTime) {
    // console.log(
    //   '### 1210 handleSentViewedEventToInsight:',
    //   this.props.resourceId,
    //   this.props.materialId
    // );
    if (this.props.resourceId > 0) {
      eventBus.publish(EVENT_BUS.SpaceAnalyticsEvents, SpaceInteractionType.VIEW_RESOURCE, {
        resourceId: this.props.resourceId,
        materialId: this.props.materialId,
        isCms: false,
        duration: Math.round(totalTime),
        resourceType: this.isRecordingFile ? 'MeetingRecorded' : ResourceTypeNames.video,
      });
    }
  }

  pause() {
    const { type } = getPlayer(this.props.src);
    if (type) {
      // console.log('### pause', type, this.playerRef.current);
      this.playerRef.current?.pause();
    }
  }

  reloadReactPlayer(nextState) {
    this.playerRef?.current?.player?.current?.play();
    this.playerRef?.current?.player?.current?.seekTo(nextState.currentTime, 'seconds');
    this.props.onReload(nextState);
  }

  render() {
    let player;
    const { autoPlay, src, controls, style, externalTool } = this.props;
    const { type, isVideo } = getPlayer(this.props.src);

    if (!isVideo) {
      if (type === 'miro') {
        return <MiroPlayer key={src} src={src} externalTool={externalTool} />;
      }
      if (type === 'mentimeter') {
        return <MentimeterPlayer key={src} src={src} externalTool={externalTool} />;
      }
      player = (
        <ResourceExternalUrlViewer
          key={src}
          // ref={this.playerRef}
          title="resource-external-url"
          src={this.src}
          externalTool={externalTool}
        />
      );
    } else {
      console.log('### VideoPlayer rendering', this.src, this.externalPlayerConfig);
      player = (
        <VideoPlayer
          ref={this.playerRef}
          autoPlay={autoPlay}
          url={this.src}
          materialId={this.props.materialId}
          controls={controls}
          onChange={this.handleOnChangeDebounced}
          onLoaded={this.handleOnLoaded}
          onReady={() => this.props.onReady()}
          externalPlayerConfig={this.externalPlayerConfig}
          onPlayed={this.handleOnVideoPlayed}
          volume={this.props.volume}
          onReload={this.reloadReactPlayer}
          onUnmount={(isPlaying, currentTime) => this.handleOnChange({ isPlaying, currentTime })}
          sentViewedEventToInsight={this.handleSentViewedEventToInsight}
          sentToInsightEventBatchTimeInSeconds={VideoResourceViewedBatchTime}
        />
      );
    }

    return (
      <div style={style} className={`video-container ${controls ? '' : 'disabled'}`}>
        {player}
      </div>
    );
  }
}

ExternalResourceViewer.propTypes = {
  src: PropTypes.string,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  materialId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  videoIndexer: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object),
  token: PropTypes.string,
  onLoaded: PropTypes.func,
  onChange: PropTypes.func,
  onReady: PropTypes.func,
  externalPlayerConfig: PropTypes.instanceOf(Object),
  externalTool: PropTypes.bool,
  volume: PropTypes.number,
  onReload: PropTypes.func,
  isRecordingFile: PropTypes.bool,
  laUrl: PropTypes.string,
  // resourceInteractionSessionId: PropTypes.string,
  // isPortal: PropTypes.bool,
};

ExternalResourceViewer.defaultProps = {
  controls: true,
  autoPlay: false,
  style: {},
  token: null,
  videoIndexer: null,
  onLoaded: () => console.log('onLoaded'),
  onReady: () => console.log('onReady'),
  onChange: null,
  volume: 1,
  isPortal: false,
};

export default ExternalResourceViewer;
