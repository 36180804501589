import Api from '../commons/api';
import RuntimeApi from '../commons/RuntimeApi';
import { getDMSApi, getDMSRuntimeApi } from '../commons/utils';

export function fetchDealTemplates(isPortal = true) {
  if (isPortal) {
    return Api.get(`${getDMSApi()}/deal-templates`);
  }
  return RuntimeApi.get(`${getDMSRuntimeApi()}/space/deal-templates`);
}

export function createDeal(dealTemplateId, spaceId, formValues) {
  return RuntimeApi.post(
    `${getDMSRuntimeApi()}/space/deal-templates/${dealTemplateId}/spaces/${spaceId}/deals`,
    formValues
  );
}

export function fetchDeals(dealTemplateId, spaceId, isPortal = false) {
  if (isPortal) {
    return Api.get(`${getDMSApi()}/deal-templates/${dealTemplateId}/spaces/${spaceId}/deals`);
  }
  return RuntimeApi.get(
    `${getDMSRuntimeApi()}/space/deal-templates/${dealTemplateId}/spaces/${spaceId}/deals`
  );
}

export function fetchDealById(dealTemplateId, spaceId, dealId) {
  return RuntimeApi.get(
    `${getDMSRuntimeApi()}/space/deal-templates/${dealTemplateId}/spaces/${spaceId}/deals/${dealId}`
  );
}

export function updateDeal(dealTemplateId, spaceId, dealId, fieldValues) {
  return RuntimeApi.put(
    `${getDMSRuntimeApi()}/space/deal-templates/${dealTemplateId}/spaces/${spaceId}/deals/${dealId}`,
    fieldValues
  );
}

export function deleteFieldValue(dealTemplateId, spaceId, dealId, fieldId) {
  return RuntimeApi.delete(
    `${getDMSRuntimeApi()}/space/deal-templates/${dealTemplateId}/spaces/${spaceId}/deals/${dealId}/fields/${fieldId}/value`
  );
}

export function deleteDeals(dealTemplateId, spaceId, ids) {
  const body = { ids };
  return RuntimeApi.put(
    `${getDMSRuntimeApi()}/space/deal-templates/${dealTemplateId}/spaces/${spaceId}/deals/delete`,
    body
  );
}

export function deleteDeal(dealTemplateId, spaceId, dealId) {
  return deleteDeals(dealTemplateId, spaceId, [dealId]);
}

export function fetchDeletedDeals(dealTemplateId, spaceId, dealIds) {
  const ids = dealIds.join(',');
  return RuntimeApi.get(
    `${getDMSRuntimeApi()}/space/deal-templates/${dealTemplateId}/spaces/${spaceId}/deleted-deals?ids=${ids}`
  );
}
