import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, TextField } from '@mui/material';
import { getErrorMessage } from './DndFileUploaderUtils';

const FilenameInput = ({
  value,
  errors,
  multiple,
  maxFiles,
  fileNameInputLabel,
  fileNameInputPlaceHolder,
  disabled,
  handleInputChange,
}) => {
  const handleInputBlur = (event) => {
    if (event?.target?.value !== value) {
      handleInputChange(event);
    }
  };
  return (
    <>
      <InputLabel htmlFor="file-name-input" className="dnd-file-uploader-control-label">
        {fileNameInputLabel}
      </InputLabel>
      <TextField
        id="file-name-input"
        name="name"
        value={value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onClick={(event) => event.stopPropagation()}
        placeholder={fileNameInputPlaceHolder}
        disabled={disabled}
        autoComplete="off"
        className="dnd-file-uploader-control-input"
        variant="outlined"
        error={!!errors?.name}
        helperText={getErrorMessage(errors, 'name', multiple, maxFiles)}
      />
    </>
  );
};

FilenameInput.propTypes = {
  value: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  maxFiles: PropTypes.number,
  errors: PropTypes.instanceOf(Object),
  fileNameInputPlaceHolder: PropTypes.string,
  fileNameInputLabel: PropTypes.string,
  handleInputChange: PropTypes.func,
};

FilenameInput.defaultProps = {
  disabled: false,
};

export default FilenameInput;
