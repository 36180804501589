import _cloneDeep from 'lodash/cloneDeep';
import Api from '../commons/api';
import { deleteResourcesPropertyInTheme } from '../cms/cmsHelpers';
import { getCoreApi } from '../commons/utils';
// import { sleep } from '../commons/utils';
// import MockApi from '../commons/mockApi';

export async function createSpace(data) {
  const payload = _cloneDeep(data);
  if (data?.customTheme) {
    payload.customTheme = JSON.stringify(data.customTheme);
  }
  const resp = await Api.post(`${getCoreApi()}/spaces`, payload);
  return resp;
}

export async function createSpaceFromTemplate(templateId, data) {
  const resp = await Api.post(`${getCoreApi()}/templates/${templateId}/spaces`, data);
  return resp;
}

export function updateSpace(spaceId, data) {
  return Api.put(`${getCoreApi()}/spaces/${spaceId}`, data);
}

export function getSpaceById(spaceId) {
  return Api.get(`${getCoreApi()}/spaces/${spaceId}`);
}

export function getUnsplashPhotos(keyword) {
  return Api.get(`${getCoreApi()}/photos/unsplash?keywords=${keyword}`);
}

export function requestCheckExistingUrl(slug, shouldReturnSuggestedUrls = true) {
  if (!slug) {
    return false;
  }
  const url = `/spaces/slugs/exists?urlSlug=${slug}&shouldReturnSuggestedUrls=${shouldReturnSuggestedUrls}`;
  return Api.get(`${getCoreApi()}${url}`);
}

export function updateCustomThemeSpace(spaceId, data, pageId = null) {
  if (data?.customTheme?.items) {
    // delete resources property
    let newCustomTheme = _cloneDeep(data.customTheme);
    deleteResourcesPropertyInTheme(newCustomTheme);
    newCustomTheme = JSON.stringify(newCustomTheme);
    Object.assign(data, { customTheme: newCustomTheme });
  }
  if (pageId) {
    return Api.put(`${getCoreApi()}/spaces/${spaceId}/pages/${pageId}/custom-theme`, data);
  }
  return Api.put(`${getCoreApi()}/spaces/${spaceId}/custom-theme`, data);
}
