import React from 'react';
import { createRoot } from 'react-dom/client';

import { ThemeProvider } from '@mui/system';
import { QueryClientProvider } from '@tanstack/react-query';
import { IResource, SelectedFileType, SelectedFolderType } from './context/Interfaces';
import UploadResourcesDialog from './UploadResourcesDialog';
import { defaultTheme } from '../theme';
import FeatureFlagsContextProvider from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import currentOrganization from '../commons/CurrentOrganization';
import AppProvider from '../components/AppProvider';

import queryCache from '../app/queryCache';

let containerElement: Element | null = null;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let rootEl: any = null;

function useUploadGlobalResources() {
  let resolver: (resources: Array<IResource>) => void;
  const organizationId = currentOrganization.getOrganizationId();

  React.useEffect(() => {
    return () => {
      if (containerElement && rootEl) {
        rootEl.unmount();
        containerElement = null;
      }
    };
  }, []);

  function closeUploadResourceDialog(newResources: Array<IResource>) {
    if (containerElement) {
      rootEl.unmount();

      containerElement = null;
      if (resolver) {
        resolver(newResources);
      }
    }
  }

  async function showUploadResourceDialog(
    selectedFolder: SelectedFolderType | undefined,
    selectedFile: SelectedFileType | undefined
  ): Promise<Array<IResource>> {
    const promise = new Promise<Array<IResource>>((resolve) => {
      resolver = resolve;
      if (!containerElement) {
        containerElement = document.createElement('div');
        rootEl = createRoot(containerElement);
        rootEl.render(
          <ThemeProvider theme={defaultTheme}>
            <QueryClientProvider client={queryCache}>
              <AppProvider isPortal>
                <FeatureFlagsContextProvider organizationId={organizationId}>
                  <UploadResourcesDialog
                    onClose={closeUploadResourceDialog}
                    selectedFolder={selectedFolder}
                    selectedFile={selectedFile}
                  />
                </FeatureFlagsContextProvider>
              </AppProvider>
            </QueryClientProvider>
          </ThemeProvider>
        );
      }
    });
    return promise;
  }

  return { showUploadResourceDialog };
}

export default useUploadGlobalResources;
