import React from 'react';
import PropTypes from 'prop-types';
import { getAppUrl } from '../commons/utils';

const CadViewer = ({ urn, token, triggerResourceConversion, isHost }) => {
  const src = `/cad/viewer.html?urn=${urn}`;
  const ref = React.useRef();
  const appUrl = getAppUrl();

  React.useEffect(() => {
    const onMessageReceived = (event) => {
      if (event.origin !== appUrl || !ref.current) {
        return;
      }
      switch (event.data.type) {
        case 'ready': {
          ref.current.contentWindow.postMessage(
            { message: 'init', data: { token, isHost } },
            appUrl
          );
          // window.removeEventListener('message', onMessageReceived, false);
          break;
        }
        case 'convert': {
          triggerResourceConversion();
          // window.removeEventListener('message', onMessageReceived, false);
          break;
        }
        default:
          break;
      }
    };
    window.addEventListener('message', onMessageReceived);
    return () => window.removeEventListener('message', onMessageReceived, false);
  }, [appUrl, token, triggerResourceConversion, isHost]);

  return (
    <iframe
      ref={ref}
      title="resource-external-url"
      src={src}
      width="100%"
      height="100%"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
      style={{ backgroundColor: '#fff' }}
    ></iframe>
  );
};

CadViewer.propTypes = {
  isHost: PropTypes.bool,
  urn: PropTypes.string,
  token: PropTypes.string,
  triggerResourceConversion: PropTypes.func,
};

export default CadViewer;
