import React from 'react';
import PropTypes from 'prop-types';
import { ResourceInteractionType, ResourceType } from '../app/appConstants';
import { createResourceInteraction } from '../commons/CommonServices';
import { useMaterialsContext } from '../spaces/Materials/MaterialsContextProvider';

function ResourceViewerSlider(props) {
  const { children, selectedId, onChange, isPortal } = props;
  const { getResourceIdAndMaterialIdExternalFile } = useMaterialsContext();
  const selectedFolderMaterialsStr = sessionStorage.getItem('selectedFolderMaterials');
  const selectedFolderMaterials = selectedFolderMaterialsStr
    ? JSON.parse(selectedFolderMaterialsStr)
    : undefined;
  const allItems = selectedFolderMaterials?.materials?.filter((x) => !x.isFolder) || [];
  const spaceFolderId = selectedFolderMaterials?.spaceFolderId;

  const index = allItems?.findIndex((m) => {
    if (m.externalId) {
      return m.externalId === selectedId;
    } else if (m.resourceId) {
      return m.resourceId === selectedId;
    } else if (m.id) {
      return m.id === selectedId;
    }
    return false;
  });
  // console.log('### ResourceViewerSlider index: ', index);
  const [currentIndex, setCurrentIndex] = React.useState(index || 0);

  async function handleOnChangeMaterial(material) {
    let tmpMaterial = { ...material };
    if (!material.resourceId && spaceFolderId !== -1 && getResourceIdAndMaterialIdExternalFile) {
      tmpMaterial = await getResourceIdAndMaterialIdExternalFile(material, spaceFolderId);
    }
    onChange(tmpMaterial);
    if (
      tmpMaterial.type === ResourceType.video ||
      tmpMaterial.type === ResourceType.vaam ||
      tmpMaterial.type === ResourceType.audio ||
      isPortal
    ) {
      return;
    }
    createResourceInteraction(tmpMaterial.resourceId, {
      materialId: tmpMaterial.id,
      interactionType: ResourceInteractionType.Click,
    });
  }

  function handleOnClickNext() {
    const canNext = allItems && currentIndex < allItems.length - 1;
    if (canNext) {
      const nextIndex = currentIndex + 1;
      const nextMaterial = allItems[nextIndex];
      if (nextMaterial) {
        handleOnChangeMaterial(nextMaterial);
      }
      setCurrentIndex(nextIndex);
    }
  }

  function handleOnClickBack() {
    if (currentIndex > 0) {
      const previousIndex = currentIndex - 1;
      const nextMaterial = allItems[previousIndex];
      if (nextMaterial) {
        handleOnChangeMaterial(nextMaterial);
      }
      setCurrentIndex(previousIndex);
    }
  }

  if (!allItems || allItems.length === 0) {
    return children;
  }

  return (
    <>
      {currentIndex > 0 ? (
        <div className="back-button" onClick={handleOnClickBack}>
          <i className="icon-chevron-left" />
        </div>
      ) : null}
      {children}
      {currentIndex < allItems.length - 1 ? (
        <div className="next-button" onClick={handleOnClickNext}>
          <i className="icon-chevron-right" />
        </div>
      ) : null}
    </>
  );
}

ResourceViewerSlider.propTypes = {
  children: PropTypes.node,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  isPortal: PropTypes.bool,
};

export default ResourceViewerSlider;
