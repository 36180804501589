import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { downloadFile } from '../../commons/ResourceUtils';
import { sendNotification } from '../../commons/utils';
import ConfirmService from '../../components/ConfirmService';
import i18n from '../../i18n';
import { removeRuntimeMaterial } from '../../resources/ResourceServices';
import spaceUser from '../spaceUser';
import { useMaterialsContext } from './MaterialsContextProvider';
import { Item } from '../../components/Material/ActionMenu';
import { ResourceState, ResourceType } from '../../app/appConstants';
import LightTooltip from '../../components/LightTooltip';
import CopyResourceShareLink from '../../components/CopyResourceShareLink';
import { useAppContext } from '../../components/AppProvider';
import DownloadFileItem from '../../components/Material/DownloadFileItem';
import { deleteRoiReport } from '../../components/ROICalculation/service';
import eventBus, { EVENT_BUS } from '../../commons/EventBus';
import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';

const paperProps = {
  style: {
    minWidth: 200,
  },
};

const FileActionMenu = (props) => {
  const {
    spaceId,
    folderId,
    isExternalFolder,
    file,
    onClose,
    anchorEl,
    onOpenForm,
    onResourceDownload,
    onResourceView,
    onClickShareLinkFile,
    onRetryConversion,
    handleMoveResources,
    onConvertToPDFClick,
    handleToggleCanDownload,
  } = props;

  const isHost = spaceUser.isHost();
  const { isPortal } = useAppContext();
  const { deleteMaterial, getResourceIdAndMaterialIdExternalFile } = useMaterialsContext();

  if (!anchorEl) return false;

  const isViewOnly = spaceUser.isViewOnly();
  const isBlockedByFirewall = !!sessionStorage.getItem('isBlockedByFirewall');
  const isUserCreatedFile = file.createdByUserInfoId === spaceUser.getUserInfoId();

  async function handleDeleteButtonClick() {
    if (!file || folderId <= 0) return false;
    const isConfirmed = await ConfirmService.show(
      `${i18n.t('DELETE FILE')}`,
      <>
        <b>This can’t be undone</b>
        <br />
        <span>Are you sure you want to delete this file?</span>
      </>,
      i18n.t('Yes'),
      i18n.t('Cancel')
    );
    if (!isConfirmed) {
      onClose();
      return false;
    }

    try {
      if (file.isRoi && file.documentId) {
        await deleteRoiReport(file.documentId);
        eventBus.publish(EVENT_BUS.ExternalFilesDeleted, {
          provider: SERVICE_PROVIDER.ROI,
          folderId: file.directoryId,
          documentIds: [file.documentId],
        });
      } else {
        await removeRuntimeMaterial(spaceId, folderId, file.id);
        deleteMaterial(folderId, file.id);
      }

      onClose();
      sendNotification(i18n.t('Deleted successfully'), { type: 'success' });
      return true;
    } catch (error) {
      onClose();
      sendNotification(error.message, { type: 'error' });
    }

    return false;
  }
  async function getInfoExternalFile() {
    let fileData = file;
    if (isExternalFolder) {
      fileData = await getResourceIdAndMaterialIdExternalFile(file, props.spaceFolderId);
    }
    return fileData;
  }

  async function handleShareLinkButtonClick() {
    const fileData = await getInfoExternalFile();
    if (onClickShareLinkFile) {
      onClickShareLinkFile(fileData.id);
    }
    onClose();
  }

  async function handleDownloadButtonClick() {
    await downloadFile(file, isPortal);

    if (onResourceDownload) {
      onResourceDownload(file);
    }
    onClose();
  }

  function handleEditButtonClick() {
    onOpenForm('file', file.id, folderId);
    onClose(false);
  }

  const handleReConvertClick = () => {
    onRetryConversion(spaceId, folderId, file.id);
    onClose();
  };

  async function handleViewButtonClick() {
    if (!file) return;
    const fileData = await getInfoExternalFile();
    if (onResourceView && typeof onResourceView === 'function') {
      onResourceView(fileData);
    }
    onClose();
  }

  function handleMoveButtonClick() {
    handleMoveResources(file);
    onClose(false);
  }

  function handleConvertToPDFClick() {
    onConvertToPDFClick(file);
    onClose();
  }

  const failedFile = [ResourceState.ConversionFailed].includes(file?.state);

  const isShowDownloadButton =
    !!file && !failedFile && !file.isExternal && file.type !== ResourceType.podcast;
  const isDownloadable = !failedFile && file?.isDownloadable;
  let isShowDeleteButton =
    (isHost || isUserCreatedFile) && !file.isPresentationFile && !isExternalFolder;
  if (file.isRoi) {
    isShowDeleteButton = true;
  } else if (file.isLms) {
    isShowDeleteButton = false;
  }
  let isShowEditButton = !failedFile && (isHost || isUserCreatedFile) && !isExternalFolder;
  const isShowViewButton = !failedFile;
  let isShowCopyLinkButton =
    !failedFile &&
    ((handleShareLinkButtonClick && !isViewOnly) || isBlockedByFirewall) &&
    !isExternalFolder;
  const isShowRetryButton = failedFile && (isHost || isUserCreatedFile);
  let isMoveable = isHost && !file.isPresentationFile && handleMoveResources && !isExternalFolder;
  let isShowSettingButton = !!file && isHost && !file.isExternal && !isExternalFolder;
  const isShowConvertToPDFButton =
    !isPortal && file.shouldConvertToPDF && onConvertToPDFClick && isHost;
  if (file.isRoi) {
    isShowEditButton = false;
    isShowCopyLinkButton = false;
    isMoveable = false;
    isShowSettingButton = false;
  } else if (file.lms) {
    isShowEditButton = false;
    isShowCopyLinkButton = false;
    isMoveable = false;
    isShowSettingButton = false;
    isShowDeleteButton = false;
  }

  console.log('isShowDeleteButton', isShowDeleteButton);

  return (
    <Menu
      className="add-material-action-menu"
      PaperProps={paperProps}
      anchorEl={anchorEl}
      open
      keepMounted={false}
      onClose={onClose}
      classes={{ paper: 'select-menu' }}
      style={{ width: 240, maxWidth: '100%' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {isShowViewButton && (
        <Item onClick={handleViewButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-show displayFlex justifyContentCenter width24" />
            <span>{i18n.t('View')}</span>
          </section>
        </Item>
      )}

      {isShowCopyLinkButton && (
        <Item onClick={handleShareLinkButtonClick}>
          <CopyResourceShareLink>
            <section className="displayFlex row-direction alignItemsCenter gap8">
              <LinkIcon fontSize="small" />
              <span>{i18n.t('Copy Link')}</span>
            </section>
          </CopyResourceShareLink>
        </Item>
      )}

      {isShowDownloadButton && (
        <LightTooltip
          title={i18n.t('It is not possible to download this resource')}
          placement="bottom-start"
          disableHoverListener={isDownloadable}
          disableFocusListener={isDownloadable}
        >
          <span>
            <Item onClick={handleDownloadButtonClick} isDisabled={!isDownloadable}>
              <section className="displayFlex row-direction alignItemsCenter gap8">
                <span className="icon-download displayFlex justifyContentCenter width24" />
                <span>{i18n.t('Download')}</span>
              </section>
            </Item>
          </span>
        </LightTooltip>
      )}
      {isMoveable && (
        <Item onClick={handleMoveButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-move displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Move')}</span>
          </section>
        </Item>
      )}

      {isShowEditButton && (
        <Item onClick={handleEditButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-update displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Rename')}</span>
          </section>
        </Item>
      )}
      {isShowSettingButton && (
        <DownloadFileItem checked={isDownloadable} file={file} onChange={handleToggleCanDownload} />
      )}

      {/* {onSignButtonClick && (
        <Item onClick={handleSignButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-sign displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Sign')}</span>
          </section>
        </Item>
      )} */}

      {isShowRetryButton && (
        <Item onClick={handleReConvertClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-convert displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Retry')}</span>
          </section>
        </Item>
      )}
      {isShowConvertToPDFButton && (
        <LightTooltip title={i18n.t('Convert file to PDF')} placement="bottom-start">
          <div>
            <Item onClick={handleConvertToPDFClick}>
              <section className="displayFlex row-direction alignItemsCenter gap8">
                <span className="icon-convert displayFlex justifyContentCenter width24" />
                <span>{i18n.t('Convert')}</span>
              </section>
            </Item>
          </div>
        </LightTooltip>
      )}

      {isShowDeleteButton && (
        <Item onClick={handleDeleteButtonClick}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-delete displayFlex justifyContentCenter width24" />
            <span>{i18n.t('Delete')}</span>
          </section>
        </Item>
      )}
    </Menu>
  );
};

FileActionMenu.propTypes = {
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  spaceId: PropTypes.string,
  file: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  anchorEl: PropTypes.instanceOf(Object),
  onOpenForm: PropTypes.func,
  onResourceView: PropTypes.func,
  onResourceDownload: PropTypes.func,
  onClickShareLinkFile: PropTypes.func,
  onRetryConversion: PropTypes.func,
  handleMoveResources: PropTypes.func,
  onConvertToPDFClick: PropTypes.func,
  isExternalFolder: PropTypes.bool,
  spaceFolderId: PropTypes.number,
  handleToggleCanDownload: PropTypes.func,
};

export default FileActionMenu;
