import React from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { TextField } from '@mui/material';
import i18n from '../i18n';
import ResponsiveDialog from '../components/ResponsiveDialog';
import { GLOBAL_RESOURCE_TYPE } from './utils';
import './ResourceFormDialog.scss';
import OfficialButton from '../components/OfficialButtons';

const ResourceFormDialog = (props) => {
  const { onClose, selectedResource, onChange } = props;
  const isFolder = selectedResource?.type === GLOBAL_RESOURCE_TYPE.FOLDER;
  const isFile = selectedResource?.type === GLOBAL_RESOURCE_TYPE.RESOURCE;

  function renderTitle() {
    let title = 'New folder';
    if (isFolder) title = 'Rename folder';
    if (isFile) title = 'Rename resource';

    return title;
  }

  const handleInputChange = useDebouncedCallback((event) => {
    const { name, value } = event.target;
    onChange(name, value);
  }, 100);

  return (
    <ResponsiveDialog className="white resource-form-dialog" onClose={onClose}>
      <div className="content">
        <span className="title">{i18n.t(renderTitle())}</span>
        <div className="form">
          <label className="label">
            {i18n.t(selectedResource && isFile ? 'Resource name' : 'Folder name')}
          </label>
          <TextField
            autoFocus
            autoComplete="off"
            fullWidth
            className="input"
            variant="outlined"
            placeholder=""
            defaultValue={selectedResource?.name ?? ''}
            name="name"
            onChange={handleInputChange}
          />
        </div>
        <div className="submit-button">
          <OfficialButton
            onClick={props.onSubmit}
            label={selectedResource?.id || isFile ? i18n.t('save') : i18n.t('create')}
            variant="large-primary"
            isProcessing={props.isProcessing}
            disabled={!selectedResource?.name}
          />
        </div>
      </div>
    </ResponsiveDialog>
  );
};

ResourceFormDialog.propTypes = {
  selectedResource: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  isProcessing: PropTypes.bool,
};

export default ResourceFormDialog;
