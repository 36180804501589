/* eslint no-bitwise: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import CustomSigalRHttpClient from '../commons/CustomSigalRHttpClient';
import { getNotificationRuntimeApi } from '../commons/utils';
import queryCache, { CacheKeys, clearCache } from '../app/queryCache';
import { notificationsContext } from '../settings/notifications/constant';

const signalR = require('@microsoft/signalr');

function userSpaceNotificationSummaryUpdated(message) {
  console.log('UserSpaceNotificationSummaryUpdated', message);
  queryCache.setQueryData(
    [CacheKeys.getNotificationsBadgesBySpaceId, message.spaceId],
    (oldData) => {
      let newData = _cloneDeep(oldData);
      if (!newData?.items) {
        newData = {
          items: [],
        };
      }
      let foundNotification = newData.items.find((item) => item.context === message.context);

      // Create base object for new notification by context
      if (!foundNotification) {
        foundNotification = {
          context: message.context,
          totalBadges: 0,
          details: {},
        };
        newData.items.push(foundNotification);
      }

      foundNotification.totalBadges = message.totalBadgeCount;

      switch (message.context) {
        case notificationsContext.Chats:
          foundNotification.details.newPrivateMessages = [];
          message.newMessages.forEach((item) => {
            if (item.isPublicChat) {
              foundNotification.details.newPublicMessages = item;
            } else {
              foundNotification.details.newPrivateMessages.push(item);
            }
          });
          break;
        case notificationsContext.Participants:
          foundNotification.details.firstJoinedSpaceUsers = message.firstJoinedSpaceUsers;
          foundNotification.details.requestSpaceAccessIds = message.requestSpaceAccessIds;
          break;
        case notificationsContext.Todos:
          foundNotification.details.newChecklistActivityIds = message.newChecklistActivityIds;
          break;
        case notificationsContext.Resources:
          foundNotification.details.newFolders = message.newFolders;
          foundNotification.details.newMaterials = message.newMaterials;
          foundNotification.details.totalSharedBadges = message.totalSharedBadges;
          foundNotification.details.totalMeetingBadges = message.totalMeetingBadges;
          break;
        default:
          break;
      }
      return newData;
    }
  );
  if (message.requestSpaceAccessIds && message.spaceId) {
    clearCache([CacheKeys.fetchRequestsAccess, message.spaceId], { exact: true });
  }
}

function createConnection(accessToken) {
  console.log('accessToken: ', accessToken);
  return new signalR.HubConnectionBuilder()
    .withUrl(getNotificationRuntimeApi(), {
      accessTokenFactory: () => accessToken,
      httpClient: new CustomSigalRHttpClient(),
    })
    .withAutomaticReconnect()
    .build();
}

class SpaceNotificationSignalRHandler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.connection = null;
    this.shouldStopConnection = false;
  }

  componentDidMount() {
    if (this.shouldStopConnection) {
      this.cleanUpConnection();
      this.shouldStopConnection = false;
      return;
    }
    this.connection = createConnection(this.props.accessToken);

    this.connection
      .start()
      .then(() => {
        console.log('SpaceNotificationSignalRHandler Connected');
      })
      .catch((error) => {
        this.shouldStopConnection = true;
        console.log(`SpaceNotificationSignalRHandler Fail: ${error.message}`);
      });
    this.connection.on('UserSpaceNotificationSummaryUpdated', (message) => {
      userSpaceNotificationSummaryUpdated(message);
    });
  }
  async cleanUpConnection() {
    if (
      (this.connection && this.connection?.state === 'Connecting') ||
      this.connection?.state === 'Reconnecting'
    ) {
      this.shouldStopConnection = true;
      return;
    }
    if (!this.connection) return;

    this.connection.off();
    await this.connection.stop();
    this.connection = null;
  }

  componentWillUnmount() {
    this.cleanUpConnection();
  }

  render() {
    return null;
  }
}

SpaceNotificationSignalRHandler.propTypes = {
  accessToken: PropTypes.string,
};

export default SpaceNotificationSignalRHandler;
