import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n';

class ErrorMessage extends PureComponent {
  render() {
    const message = !this.props.message
      ? i18n.t('Sorry. The page you are looking for does not exist')
      : this.props.message;
    return (
      <div className="page-not-found" style={this.props.style}>
        <div className="unauthorized-div animated shake">
          <div>{message}</div>
          <br />
          {this.props.renderActions && this.props.renderActions()}
        </div>
      </div>
    );
  }
}

ErrorMessage.propTypes = {
  style: PropTypes.instanceOf(Object),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object), PropTypes.node]),
  renderActions: PropTypes.func,
};

ErrorMessage.defaultProps = {
  style: {},
  message: null,
  renderActions: null,
};

export default ErrorMessage;
