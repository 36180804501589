import React, { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react';
import { getDomain, popupWindow, sendNotification, setCookie } from '../../commons/utils';
import i18n from '../../i18n';
import ConnectButton from '../components/ConnectButton';
import ConnectFailedDialog from '../ConnectFailedDialog';
import DisconnectDialog from '../DisconnectDialog';
import { SERVICE_PROVIDER } from '../integrationConstants';
import { useIntegrationContext } from '../IntegrationContextProvider';
import { removeIntegrationConnection } from '../integrationService';
import { getOneDriveAuthUrl } from '../integrationUtils';
import OneDriveSelectRootFoldersDialog from './OneDriveSelectRootFoldersDialog';
import { saveDefaultRootFolders } from './onedriveService';
import { createRootFolderModels } from './utils';
import { ConnectButtonBaseProps } from '../components/ConnectButton/types';
import currentOrganization from '../../commons/CurrentOrganization';

const OneDriveConnectButton = forwardRef((props: ConnectButtonBaseProps, ref: Ref<unknown>) => {
  const { isProcessing, serviceProvider, onConnectSuccess } = props;
  const { addConnection } = useIntegrationContext();
  const [showDisconnectDialog, setShowDisconnectDialog] = useState(false);
  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const [duplicatedOrgs, setDuplicatedOrgs] = useState<string | undefined>(undefined);
  const folderDialogResolver = useRef<(value: boolean) => void>();

  async function connectOneDrive() {
    setCookie(
      'tmpSubDomain',
      currentOrganization.getSubDomain(),
      0.5,
      getDomain(window.location.origin) ?? ''
    );
    popupWindow(getOneDriveAuthUrl(), i18n.t('OneDrive'), 700, 700);
    window.CallParentfunc = async function (isAuthen, token) {
      if (isAuthen) {
        await addConnection({
          accessCode: token,
          serviceProvider: SERVICE_PROVIDER.ONEDRIVE,
          onBeforeSuccessDialog: async () => {
            setShowFolderDialog(true);
            return new Promise((res) => {
              folderDialogResolver.current = res;
            });
          },
          onFailure: (resp: { reason: string; data: { additionalMessage: string } }) => {
            if (resp.reason === 'DuplicatedEmail') {
              console.log('DuplicatedEmail in org: ', resp.data.additionalMessage);
              setDuplicatedOrgs(resp.data.additionalMessage);
            }
          },
        });
      }
    };
  }

  function handleOnClickDisconnect() {
    setShowDisconnectDialog(true);
  }

  useImperativeHandle(ref, () => ({
    connectOneDrive,
  }));

  const handleOnFolderSubmit = async (selectedFolders: unknown[]) => {
    const payload = createRootFolderModels(selectedFolders);
    await saveDefaultRootFolders(payload);
    if (folderDialogResolver.current) {
      folderDialogResolver.current(true);
    }
    setShowFolderDialog(false);
    sendNotification(
      i18n.t('You can continue using SPCE in the meantime'),
      { duration: 5000, type: 'processing' },
      i18n.t('Connecting OneDrive folders might take a while')
    );
    if (onConnectSuccess) {
      await onConnectSuccess();
    }
  };

  async function handleOnFolderDialogClose() {
    await removeIntegrationConnection(SERVICE_PROVIDER.ONEDRIVE);
    if (folderDialogResolver.current) {
      folderDialogResolver.current(false);
    }
    setShowFolderDialog(false);
  }

  function contactUs() {
    const recipient = 'support@spce.com';
    const subject = 'Disconnect OneDrive Support';
    const message = `I would like support with disconnecting the OneDrive integration.`;
    window.open(`mailto:${recipient}?subject=${subject}&body=${message}`);
  }

  function supportChangeFolders() {
    const recipient = 'support@spce.com';
    const subject = 'Change default OneDrive folders Support';
    const message = `I would like support with chaging the OneDrive default shared folders.`;
    window.open(`mailto:${recipient}?subject=${subject}&body=${message}`);
  }

  return (
    <>
      <ConnectButton
        serviceProvider={serviceProvider}
        isProcessing={isProcessing}
        onClickConnect={connectOneDrive}
        onClickDisconnect={handleOnClickDisconnect}
      />
      {showFolderDialog && (
        <OneDriveSelectRootFoldersDialog
          isOpen={showFolderDialog}
          onClose={handleOnFolderDialogClose}
          onRequestSupport={supportChangeFolders}
          onSubmit={handleOnFolderSubmit}
        />
      )}
      {duplicatedOrgs && (
        <ConnectFailedDialog
          failureMessage={duplicatedOrgs}
          serviceProvider={SERVICE_PROVIDER.ONEDRIVE}
          showCloseIcon={false}
          isOpen={duplicatedOrgs !== undefined}
          onClose={() => setDuplicatedOrgs(undefined)}
          onSubmit={() => setDuplicatedOrgs(undefined)}
        />
      )}

      <DisconnectDialog
        isOpen={showDisconnectDialog}
        serviceProvider={serviceProvider.type}
        onSubmit={contactUs}
        onClose={() => setShowDisconnectDialog(false)}
      />
    </>
  );
});

export default OneDriveConnectButton;
