import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel, Checkbox } from '@mui/material';
import i18n from '../i18n';
import './VaamParticipantSelect.scss';
import UserAvatar from '../components/UserAvatar';
import CheckboxAll from '../components/CheckboxAll';

const VaamParticipantSelect = (props) => {
  const { participants, onSelectParticipants, isOnError } = props;
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    if (onSelectParticipants) onSelectParticipants(participants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  function handleOnSelectionChanged(selectedUsers) {
    setSelectedCount(selectedUsers.filter((x) => x.enabled)?.length);
  }

  function handleCheckAll(event) {
    let selectedUsers = [...participants];
    selectedUsers = selectedUsers.map((item) => ({
      ...item,
      enabled: event.target.checked,
    }));
    handleOnSelectionChanged(selectedUsers);
    onSelectParticipants(selectedUsers);
  }

  function handleOnSelectParticipants(event) {
    let selectedUsers = [...participants];

    selectedUsers = selectedUsers.map((item) => {
      if (item.id !== Number(event.target.value)) {
        return item;
      }
      return {
        ...item,
        enabled: event.target.checked,
      };
    });
    handleOnSelectionChanged(selectedUsers);
    onSelectParticipants(selectedUsers);
  }

  function renderParticipantListBody() {
    return (
      <div className="participant-list">
        {participants?.length > 0 && (
          <div className="participant-list-header">
            <Typography className="list-title">{i18n.t('User List')}</Typography>
            <Typography className="list-subtitle">
              {i18n.t('Select users that you want to share the video with.')}
            </Typography>
          </div>
        )}
        {isOnError && (
          <Typography color="error" variant="subtitle2">
            {i18n.t('Select users to share the video message in private chat.')}
          </Typography>
        )}
        {participants?.length > 1 && (
          <div className="select-all">
            <Typography>{`${selectedCount}/${participants.length} selected`}</Typography>
            <CheckboxAll listCheck={participants} onChange={handleCheckAll} />
          </div>
        )}
        <div className="participant-list-container">
          {participants?.map((item) => {
            return (
              <div className="participant-list-item" key={item.id}>
                <FormControlLabel
                  className="form-checkbox full-width"
                  value={item.id}
                  name={item.name}
                  checked={item.enabled}
                  onChange={handleOnSelectParticipants}
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox color="primary" />}
                  label={
                    <div className="participant-item-label">
                      <UserAvatar fullName={item.fullName} email={item.email} />
                      <div className="info">
                        <div className="info-title">
                          <span className="name">{item.fullName}</span>
                        </div>
                        <span className="description text-truncate">{item.email}</span>
                      </div>
                    </div>
                  }
                  labelPlacement="start"
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return <div className="vaam-participant-select">{renderParticipantListBody()}</div>;
};

VaamParticipantSelect.propTypes = {
  onSelectParticipants: PropTypes.func,
  participants: PropTypes.instanceOf(Array),
  isOnError: PropTypes.bool,
};

export default VaamParticipantSelect;
