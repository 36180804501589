import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import _map from 'lodash/map';
import queryCache, { CacheKeys } from '../app/queryCache';
import { getNotificationsBadgesBySpaceId } from '../settings/notifications/service';
import spaceUser from './spaceUser';

const useSpaceNotifications = (spaceId) => {
  const getSpaceNotificationsQuery = useQuery({
    queryKey: [CacheKeys.getNotificationsBadgesBySpaceId, spaceId],
    queryFn: async () => {
      const resp = await getNotificationsBadgesBySpaceId(spaceId);
      return resp;
    },

    retry: 0,
    enabled: spaceUser.hasLoggedIn() && !spaceUser.isVisitor(),
  });

  const newNotifications = useMemo(() => {
    if (getSpaceNotificationsQuery.data?.items?.length > 0) {
      const notifications = {};
      getSpaceNotificationsQuery.data.items.map((item) => {
        notifications[item.context] = {
          total: item.totalBadges,
          details: item.details,
        };
        return item;
      });
      return notifications;
    }
    return {};
  }, [getSpaceNotificationsQuery.data]);

  const setNotifications = useCallback(
    (data) => {
      queryCache.setQueryData([CacheKeys.getNotificationsBadgesBySpaceId, spaceId], () => {
        const newData = { items: [] };
        _map(data, (item, key) => {
          newData.items.push({ context: key, totalBadges: item.total, details: item.details });
          return item;
        });
        return newData;
      });
    },
    [spaceId]
  );

  return { newNotifications, setNotifications };
};

export default useSpaceNotifications;
