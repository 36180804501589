import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import useMobileQuery from '../commons/useMobileQuery';
import ShareSpacePopover from '../homepage/ShareSpacePopover';
import OfficialButton from './OfficialButtons';
import i18n from '../i18n';

const ShareLinkButton = (props) => {
  const { className, space } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useMobileQuery();
  const [anchorEl, setAnchorEl] = useState(null);
  const openSharePopup = window.data?.openSharePopup || false;

  const onClick = (event) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (openSharePopup) {
      const btnShare = document.getElementsByClassName('btn-share-link');
      if (btnShare.length > 0) {
        btnShare[0].click();
      }
    }
  }, [openSharePopup]);

  const onClosePopup = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={`share-space-link ${className}`}>
        {isMobile ? (
          <IconButton className="btn button-icon" onClick={onClick}>
            <LinkIcon />
          </IconButton>
        ) : (
          <OfficialButton
            onClick={onClick}
            variant="regular-primary"
            label={i18n.t('Share')}
            toolTipMessage="Share your space"
            placement="bottom-start"
            className={`btn btn-share-link${isOpen ? ' opened' : ''}`}
          />
        )}
      </div>
      <ShareSpacePopover
        anchorEl={anchorEl}
        isMobile={isMobile}
        space={space}
        onClose={onClosePopup}
      />
    </>
  );
};

ShareLinkButton.propTypes = {
  className: PropTypes.string,
  space: PropTypes.instanceOf(Object),
};

ShareLinkButton.defaultProps = {
  className: '',
  isRuntime: false,
};

export default ShareLinkButton;
