import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ActionMenu from '../components/Material/ActionMenu';
import useUploadGlobalResources from './useUploadGlobalResources';
import { IResource, SelectedFolderType } from './context/Interfaces';
import useGlobalResourcesForm from './useGlobalResourcesForm';
import OfficialButton from '../components/OfficialButtons';
import useUploadGlobalFolder from './useUploadGlobalFolder';
import currentOrganization from '../commons/CurrentOrganization';

type AddButtonProps = {
  selectedFolder?: SelectedFolderType;
  onResourceFolderAdded: () => void;
  onResourceUploaded: (resources: Array<IResource>) => void;
  onUploadFolder: (resources: Array<IResource>) => void;
  addFileDisabled: boolean;
  disabled: boolean;
  variant?:
    | 'regular-primary'
    | 'regular-secondary'
    | 'regular-green'
    | 'regular-red'
    | 'regular-dark-grey'
    | 'rectangle-primary'
    | 'rectangle-secondary'
    | 'rectangle-green'
    | 'rectangle-grey'
    | 'rectangle-dark-grey'
    | 'large-primary'
    | 'large-secondary'
    | 'small-primary'
    | 'small-secondary'
    | 'small-green'
    | 'small-grey';
};

function AddButton(props: AddButtonProps) {
  const {
    selectedFolder,
    onResourceFolderAdded,
    onResourceUploaded,
    onUploadFolder,
    addFileDisabled,
    disabled,
  } = props;
  const enableGlobalResources = currentOrganization?.isEnterprise();
  const [addAnchorEl, setAddAnchorEl] = React.useState<EventTarget | null>(null);
  const { showUploadResourceDialog } = useUploadGlobalResources();
  const { showGlobalResourcesForm } = useGlobalResourcesForm();
  const { showUploadFolderDialog } = useUploadGlobalFolder();
  const mVariant = props.variant || 'regular-primary';

  function closeMenu() {
    setAddAnchorEl(null);
  }

  async function handleOnNewFolderButtonClick() {
    // console.log('### AddButton handleOnNewFolderButtonClick', selectedFolder);
    const result = await showGlobalResourcesForm(selectedFolder);
    if (result && onResourceFolderAdded) {
      onResourceFolderAdded();
    }
    setAddAnchorEl(null);
  }

  async function handleOnNewFileButtonClick() {
    // console.log('### AddButton handleOnNewFileButtonClick', selectedFolder);
    const newResources = await showUploadResourceDialog(selectedFolder, undefined);
    setAddAnchorEl(null);
    if (newResources.length > 0 && onResourceUploaded) {
      onResourceUploaded(newResources);
    }
  }
  async function handleOnUploadFolderButtonClick() {
    const newResources = await showUploadFolderDialog(selectedFolder);
    setAddAnchorEl(null);
    if (onUploadFolder) {
      onUploadFolder(newResources);
    }
  }

  function renderActionMenu() {
    if (!addAnchorEl) {
      return null;
    }

    return (
      <ActionMenu
        anchorEl={addAnchorEl}
        onClose={closeMenu}
        onNewFolderButtonClick={handleOnNewFolderButtonClick}
        onNewFileButtonClick={addFileDisabled ? null : handleOnNewFileButtonClick}
        onUploadFolder={handleOnUploadFolderButtonClick}
      />
    );
  }

  return (
    <div className="add-button-container">
      <OfficialButton
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore:next-line
        icon={<AddIcon className="addicon" />}
        disabled={!enableGlobalResources || disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore:next-line
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAddAnchorEl(e.target);
        }}
        variant={mVariant}
        label="Add"
      />
      {renderActionMenu()}
    </div>
  );
}

export default React.memo(AddButton);
