import _filter from 'lodash/filter';
import { SelectedItemProps } from '../context/Interfaces';
import { GLOBAL_RESOURCE_TYPE } from '../utils';

export function getSelectedResourceIds(selectedResources: Array<SelectedItemProps>) {
  const resourceIds = _filter(
    selectedResources,
    (item) => item.type === GLOBAL_RESOURCE_TYPE.RESOURCE
  ).map((item) => item.resourceId);
  console.log('resourceIds', resourceIds);
  const directoryIds = _filter(
    selectedResources,
    (item) => item.type === GLOBAL_RESOURCE_TYPE.FOLDER
  ).map((item) => item.id);
  return { resourceIds, directoryIds };
}
