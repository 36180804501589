import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import { fetchPathsDirectory } from './globalResourcesService';

const useNavigationsQuery = (provider, parentId, driveId) => {
  const query = useQuery({
    queryKey: [CacheKeys.getFolderNavigations, provider, parentId, driveId],
    queryFn: async () => {
      const resp = await fetchPathsDirectory(provider, parentId, driveId);
      if (!resp) {
        throw new Error('Failed to fetch navigations');
      }
      return resp;
    },
    enabled: false,
    retry: 1,
    retryDelay: () => 3000,
  });

  return query.refetch;
};

export default useNavigationsQuery;
