import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import i18n from '../i18n';
import './Banner.scss';

const Banner = ({ icon, message, className, closeElement, rightButton }) => {
  const [openBanner, setOpenBanner] = useState(true);

  function handleOnClickDismiss() {
    setOpenBanner(false);
  }

  if (!openBanner) {
    return null;
  }

  function renderRightButton() {
    if (rightButton) {
      return rightButton;
    }
    if (closeElement) {
      return (
        <IconButton size="small" onClick={handleOnClickDismiss}>
          {closeElement}
        </IconButton>
      );
    } else {
      return (
        <Button size="small" className="dismiss-button" onClick={handleOnClickDismiss}>
          {i18n.t('Dismiss')}
        </Button>
      );
    }
  }

  return (
    <div className={`banner ${className}`}>
      <div className="banner-left">
        {icon || <InfoIcon className="icon info-icon" />}
        <p className="description">{message}</p>
      </div>
      <div className="banner-right">{renderRightButton()}</div>
    </div>
  );
};

Banner.propTypes = {
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  closeElement: PropTypes.element,
  icon: PropTypes.element,
  rightButton: PropTypes.element,
};

Banner.defaultProps = {
  message: '',
  className: 'banner-warning',
};

export default Banner;
