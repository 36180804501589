import { useCallback, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import PropTypes from 'prop-types';
import { useAppContext } from '../components/AppProvider';
import { CacheKeys, clearCache } from '../app/queryCache';
import { useUnmountEffect } from '../commons/CustomHooks';

const SpacesEventsHandler = (props) => {
  const { connection } = props;
  const { userInfo } = useAppContext();

  const handleParticipantAddedEvent = useCallback(
    (message) => {
      const { userInfoId } = message;
      if (userInfoId === userInfo?.id) {
        clearCache([CacheKeys.fetchSpacesList], false);
      }
    },
    [userInfo?.id]
  );

  const handleParticipantAddedEventDebounced = useDebouncedCallback((message) => {
    handleParticipantAddedEvent(message);
  }, 5000);

  useEffect(() => {
    function refetchSpaceList() {
      clearCache([CacheKeys.fetchSpacesList], false);
    }

    if (connection) {
      connection.on('SpaceUserAddedEvent', handleParticipantAddedEventDebounced);
      connection.on('SpaceStateChanged', refetchSpaceList);
    }
    return () => {
      if (connection) {
        connection.off('SpaceUserAddedEvent', handleParticipantAddedEventDebounced);
        connection.off('SpaceStateChanged', refetchSpaceList);
      }
    };
  }, [connection, handleParticipantAddedEventDebounced]);

  useUnmountEffect(() => {
    if (handleParticipantAddedEventDebounced.isPending) {
      handleParticipantAddedEventDebounced.cancel();
    }
  });

  return null;
};

SpacesEventsHandler.propTypes = {
  connection: PropTypes.instanceOf(Object),
};

export default SpacesEventsHandler;
