import * as React from 'react';
import i18n from '../../i18n';
import GlobalResourcesSelectDialog from '../../globalResources/GlobalResourcesSelectDialog';
import { FeatureFlagsType } from '../../app/appConstants';
import { useFeatureFlagsContext } from '../../commons/FeatureFlags/FeatureFlagsContextProvider';

type IGlobalResourceButtonProps = {
  multiple?: boolean;
  accept?: string;
  isPortal?: boolean;
  isAdmin?: boolean;
  selectType?: string;
  addFolder: (folder: object) => void;
};

export function GlobalResourceButton(props: IGlobalResourceButtonProps) {
  const { multiple, accept, isPortal, isAdmin, selectType, addFolder } = props;

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [canUploadFolderFromOneDrive, canUploadFolderFromGoogleDrive] = checkEnabledFeatures([
    FeatureFlagsType.SPACE_UPLOAD_FOLDER_ONEDRIVE,
    FeatureFlagsType.SPACE_UPLOAD_FOLDER_GOOGLEDRIVE,
  ]);

  const handleToPickGlobalResources = async () => {
    const hideIntegration = !canUploadFolderFromOneDrive && !canUploadFolderFromGoogleDrive;
    const { selectedFolder } = await GlobalResourcesSelectDialog.show(
      multiple,
      accept,
      isPortal,
      false,
      null,
      hideIntegration,
      isAdmin,
      selectType,
      canUploadFolderFromOneDrive,
      canUploadFolderFromGoogleDrive
    );
    addFolder(selectedFolder);
  };

  return (
    <a
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        handleToPickGlobalResources();
      }}
    >
      {i18n.t('add from global resources')}
    </a>
  );
}
