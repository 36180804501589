import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import BlockUI from '../../components/BlockUI';
import './SubscriptionPopup.scss';
import i18n from '../../i18n';
import { defaultTheme } from '../../theme';
import SubscriptionInfo from './SubscriptionInfo';
// import { getTagLabel } from './functions';

// const PaymentForm = React.lazy(() => import('./PaymentForm'));

export default function SubscriptionPopup(props) {
  const { open, onClose, type, message, subscriptionRequire, currentSubscription } = props;
  // const [step, setStep] = React.useState(1);

  function handleOnClickSubscribe() {
    console.log('handleOnClickSubscribe');
    onClose();
  }

  // function handleOnClickBack() {
  //   setStep(1);
  // }

  // function renderBillingForm() {
  //   const tagLabel = getTagLabel(subscriptionRequire);
  //   return (
  //     <div className="subscription-info billing-form-container">
  //       <div className="body">
  //         <div className="header">
  //           <span className={`subscription-tag ${tagLabel}`}>{tagLabel}</span>
  //           <h1 className="title">{i18n.t("Let's move up to the next level")}</h1>
  //         </div>
  //         <p className="description">
  //           {i18n.t('A small step for main, but one giant leap for your customer relations')}
  //         </p>
  //         {/* <Suspense fallback={<BlockUI display />}>
  //           <PaymentForm onClickBack={handleOnClickBack} onClose={onClose} />
  //         </Suspense> */}
  //       </div>
  //       <div className="message">
  //         <p>{i18n.t('Finalize payment and proceed with your new subscription')}</p>
  //       </div>
  //     </div>
  //   );
  // }

  function renderContent() {
    // if (step === 1) {
    return (
      <SubscriptionInfo
        type={type}
        message={message}
        onClick={handleOnClickSubscribe}
        currentSubscription={currentSubscription}
        subscriptionRequire={subscriptionRequire}
      />
    );
    // }

    // return renderBillingForm();
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="subscription-dialog"
      >
        <IconButton onClick={onClose} className="close-button" size="small">
          <CloseRoundedIcon color="primary" />
        </IconButton>
        <DialogContent className="dialog-content">{renderContent()}</DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

SubscriptionPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  message: PropTypes.string,
  currentSubscription: PropTypes.number,
  subscriptionRequire: PropTypes.number,
};

SubscriptionPopup.defaultProps = {
  description: i18n.t(
    'But this is not the end of the galaxy and your upgrade is a single click away.'
  ),
};
