import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { getResourceTypeDisplayName } from '../../commons/ResourceUtils';
import { formatDateTime } from '../../commons/DateTimeUtils';
import LightTooltip from '../../components/LightTooltip';
import i18n from '../../i18n';
import GlobalResourceUsageButton from '../../components/ResourceUsage/GlobalResourceUsageButton';
import './PreviewResource.scss';

const PreviewResources = (props) => {
  const {
    isFolder,
    type,
    modified,
    resourceId,
    description,
    totalSpaces,
    totalUsage,
    views,
    isShowExtend,
  } = props;

  return (
    <div className="preview-details">
      <div className="preview-row">
        <div className="field-name bold">{i18n.t('Type')}</div>
        <div>{isFolder ? 'Folder' : getResourceTypeDisplayName(type)}</div>
      </div>

      {modified && (
        <div className="preview-row">
          <div className="field-name bold">{i18n.t('Modified')}</div>
          <div>{formatDateTime(modified, 'MMM DD, YYYY')}</div>
        </div>
      )}
      {resourceId && (
        <div className="preview-row">
          <div className="field-name bold nowrap">{i18n.t('Resource ID')}</div>
          <LightTooltip
            title={i18n.t('This is the internal ID of this resource in SP_CE.')}
            classes={{ tooltip: 'small-size-tooltip' }}
          >
            <div>{resourceId}</div>
          </LightTooltip>
        </div>
      )}
      {description && (
        <div className="preview-column">
          <div className="field-name bold">{i18n.t('Description')}</div>
          <div>{description}</div>
        </div>
      )}
      {isShowExtend && (
        <>
          <div className="divider-container">
            <Divider />
          </div>
          <div className="preview-row">
            <div className="field-name bold">{i18n.t('Total spaces')}:</div>
            <div style={{ display: 'flex', flex: 1 }}>
              <div>{totalSpaces}</div>
              <div style={{ marginLeft: '50%' }}>
                <GlobalResourceUsageButton resourceId={resourceId} />
              </div>
            </div>
          </div>
          <div className="preview-row">
            <span className="field-name bold">{i18n.t('Total usage')}:</span>
            <div>{totalUsage}</div>
          </div>
          <div className="preview-row">
            <span className="field-name bold">{i18n.t('Total views')}:</span>
            <div>{views}</div>
          </div>
        </>
      )}
    </div>
  );
};
PreviewResources.propTypes = {
  isFolder: PropTypes.bool,
  type: PropTypes.number,
  modified: PropTypes.string,
  resourceId: PropTypes.number,
  description: PropTypes.string,
  isShowExtend: PropTypes.bool,
  totalSpaces: PropTypes.number,
  totalUsage: PropTypes.number,
  views: PropTypes.number,
};

PreviewResources.propDefault = {
  isFolder: false,
  isShowExtend: false,
};

export default PreviewResources;
