import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import currentOrganization from '../../commons/CurrentOrganization';
import SpaceDesignMode from './SpaceDesignMode';

function SpaceDesignerContainer(props) {
  const navigate = useNavigate();
  const { id } = useParams(); // Space Id
  const isAdmin = currentOrganization.isAdmin();
  let shouldCreateHiddenUser = isAdmin;
  const isTemplate = navigate?.state?.isTemplate === true;

  console.log('SpaceDesignerContainer: ', id, props);
  if (isTemplate) {
    shouldCreateHiddenUser = false;
  }

  return <SpaceDesignMode spaceId={id} shouldCreateHiddenUser={shouldCreateHiddenUser} />;
}

export default SpaceDesignerContainer;
