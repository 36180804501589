export const SPACE_FEATURES = {
  PARTICIPANTS: 'Participants',
  RESOURCES: 'Resources',
  CHAT: 'Chat',
  FILE_UPLOAD: 'FileUpload',
};

export const featuresDefault = [
  { id: SPACE_FEATURES.PARTICIPANTS, enabled: true },
  { id: SPACE_FEATURES.RESOURCES, enabled: true },
  { id: SPACE_FEATURES.CHAT, enabled: true },
  { id: SPACE_FEATURES.FILE_UPLOAD, enabled: true },
];

const getSpaceFeatures = (spaceFeatures) => {
  let features = [];
  const featuresMap = {};

  if (spaceFeatures?.length) {
    features = spaceFeatures;
  } else {
    features = featuresDefault;
  }

  features.forEach((feature) => {
    featuresMap[feature.id] = feature.enabled;
  });

  return featuresMap;
};

export default getSpaceFeatures;
