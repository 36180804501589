// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useCallback, useEffect } from 'react';
import OfficialButton from '../components/OfficialButtons';
import i18n from '../i18n';
import { useGlobalResourcesContext } from '../globalResources/context/GlobalResourcesContextProvider';
import { IFolder } from '../globalResources/context/Interfaces';
import MaterialsMoveDialog from '../components/Material/MaterialsMoveDialog';
import LoadingMessage from '../components/LoadingMessage';
import ShareVaamLocation from './ShareVaamLocation';
import { useMaterialsContext } from '../spaces/Materials/MaterialsContextProvider';
import useSpaceMaterialsQuery from '../spaces/Materials/useSpaceMaterialsQuery';

interface ShareVaamInResourcePanelProps {
  spaceId: string;
  selectedFileIds: number[];
  onShareSuccess: () => void;
  // etc
}

const ShareVaamInResourcePanel: FC<ShareVaamInResourcePanelProps> = ({
  spaceId,
  selectedFileIds,
  onShareSuccess,
}) => {
  const [openMoveResourcesDialog, setOpenMoveResourcesDialog] = React.useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = React.useState<IFolder | null>(null);
  const { setSelectedResources } = useGlobalResourcesContext();
  const getMaterialsQuery = useSpaceMaterialsQuery(spaceId, false, true);

  const { flatDirectories } = useMaterialsContext();

  useEffect(() => {
    const rootFolder = flatDirectories?.find((item: { name: string }) => item.name === 'Extra');
    if (!rootFolder) return;

    setSelectedFolder(rootFolder);
  }, [flatDirectories, setSelectedFolder, selectedFileIds, setSelectedResources]);

  const handleCloseShowMoveDialog = useCallback(() => {
    setOpenMoveResourcesDialog(false);
  }, []);

  const onClickMove = useCallback(
    (locationId: number) => {
      const folderId = locationId ?? selectedFolder?.id;
      setSelectedFolder(flatDirectories?.find((item: { name: string }) => item.id === locationId));
      if (onShareSuccess) {
        onShareSuccess(folderId);
      }
    },
    [flatDirectories, onShareSuccess, selectedFolder?.id]
  );
  const onClickChangeLocation = useCallback(() => {
    setOpenMoveResourcesDialog(true);
  }, []);

  const renderDialog = useCallback(() => {
    const currentFolderId = selectedFolder?.id;
    if (!openMoveResourcesDialog || !currentFolderId) {
      return null;
    }
    if (getMaterialsQuery.isLoading) {
      return (
        <div className="loading-container">
          <LoadingMessage>{i18n.t('Loading...')}</LoadingMessage>
        </div>
      );
    }

    return (
      <MaterialsMoveDialog
        spaceId={spaceId}
        onClose={handleCloseShowMoveDialog}
        selectedFileIds={selectedFileIds}
        isVideoMessageSharing
        onVideoMessageShared={onClickMove}
      />
    );
  }, [
    getMaterialsQuery.isLoading,
    handleCloseShowMoveDialog,
    onClickMove,
    openMoveResourcesDialog,
    spaceId,
    selectedFileIds,
    selectedFolder?.id,
  ]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '24px',
        paddingRight: '24px',
      }}
    >
      <ShareVaamLocation folderId={selectedFolder?.id} />
      {renderDialog()}
      <OfficialButton
        label={i18n.t('Change')}
        onClick={onClickChangeLocation}
        style={{ marginTop: '20px' }}
        variant="regular-secondary"
      />
    </div>
  );
};

export default ShareVaamInResourcePanel;
