import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './style.scss';
import SortSelectPopover from './SortSelectPopover';

function SortSelect(props) {
  const { options, onChange } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [orderBy, setOrderBy] = useState(props.orderBy);
  const [order, setOrder] = useState(props.order);

  // console.log('### 235 SortSelectPopover:', options);

  const displayValue = React.useMemo(() => {
    const option = options?.find((item) => item.value === orderBy);
    return option ? option.name : '';
  }, [options, orderBy]);

  const handleOrderByChange = useCallback(
    (event) => {
      const checked = event.target.value;
      setOrderBy(checked);
      if (onChange) {
        const value = { orderBy: checked, order };
        // console.log('### 235 handleOrderByChange:', value);
        onChange(value);
      }
      setAnchorEl(null);
    },
    [onChange, order]
  );

  const handleOrderChange = useCallback(
    (event) => {
      const checked = event.target.value;
      setOrder(checked);
      if (onChange) {
        const value = { orderBy, order: checked };
        // console.log('### 235 handleOrderChange:', value);
        onChange(value);
      }
      setAnchorEl(null);
    },
    [onChange, orderBy]
  );

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div className="sort-select">
      <div className={`button ${anchorEl && 'active'}`} onClick={handleClick}>
        <span className="label">{displayValue}</span>
        {anchorEl ? <ArrowDropUpIcon className="icon" /> : <ArrowDropDownIcon className="icon" />}
      </div>
      <SortSelectPopover
        anchorEl={anchorEl}
        options={props.options}
        order={order}
        orderBy={orderBy}
        onClose={handleClose}
        onOrderByChange={handleOrderByChange}
        onOrderChange={handleOrderChange}
      />
    </div>
  );
}

SortSelect.propTypes = {
  options: PropTypes.instanceOf(Array),
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onChange: PropTypes.func,
};

SortSelect.defaultProps = {};

export default React.memo(SortSelect);
