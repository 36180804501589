import Resumable from 'resumablejs';
import { UploadContextTypes } from '../app/appConstants';
import Api from './api';
import currentOrganization from '../commons/CurrentOrganization';
import RuntimeApi from './RuntimeApi';
// import { getResourceFileType } from './ResourceUtils';
import spaceUser from '../spaces/spaceUser';
import {
  getResourceApi,
  getIdentityApi,
  getResourceRuntimeApi,
  getInsightsAnalyticRuntimeApi,
} from './utils';
import appInfo from '../app/appInfo';
import { getResourceFileType, sanitizeFileName } from './ResourceUtils';
// import { addMaterial } from '../resources/ResourceServices';

export async function uploadResource(
  spaceId,
  file,
  onError = null,
  oldResourceId = null,
  spacePrivacy = null,
  directoryId = null,
  uploadContext = UploadContextTypes.Space
) {
  if (!file) return null;
  const isPortal = appInfo.isPortal();
  const resumableHeaders = { 'X-CSRF': '1' };
  if (isPortal) {
    const organization = currentOrganization.getData();
    if (organization) {
      Object.assign(resumableHeaders, { organizationId: organization.id });
    }
  } else {
    const token = spaceUser.getAccessToken();
    Object.assign(resumableHeaders, { authorization: `Bearer ${token}` });
  }

  const chunkSize = 1 * 1024 * 1024;
  const fileName = file.fileName || file.name || 'noname'; // original file name
  const sanitizedFileName = sanitizeFileName(fileName);
  const newFile = file;
  newFile.fileName = sanitizedFileName; // to avoid firewall issue

  const r = new Resumable({
    chunkSize,
    forceChunkSize: true,
    simultaneousUploads: 1,
    uploadMethod: 'PUT',
    testChunks: false,
    maxChunkRetries: 3,
    chunkRetryInterval: 1000,
    throttleProgressCallbacks: 1,
    fileParameterName: 'file',
    chunkNumberParameterName: 'chunkNumber',
    chunkSizeParameterName: 'chunkSize',
    currentChunkSizeParameterName: 'chunkSize',
    fileNameParameterName: 'fileName',
    totalSizeParameterName: 'totalSize',
    headers: resumableHeaders,
  });

  let isCreateResource = true;
  if (oldResourceId) {
    // extra parameters
    r.opts.query = { ...r.opts.query, oldResourceId };
    isCreateResource = false;
  }

  if (directoryId > 0) {
    r.opts.query = { ...r.opts.query, directoryId };
  }

  r.addFile(file);

  let sessionId;
  try {
    let resourceType = file.resourceType;
    if (!resourceType) {
      resourceType = getResourceFileType(file);
    }

    const data = {
      chunkSize,
      totalSize: file.size,
      fileName: sanitizedFileName,
      fileCategoryType: file.fileCategoryType || 0,
      feature: file.feature,
      isCreateResource,
      spaceId,
      spacePrivacy,
      resourceType,
      resourceName: fileName,
      resourceDescription: file.fileDescription || fileName,
      isGlobal: file.isGlobal,
      uploadContext: uploadContext,
    };
    if (file.skipTranscode !== undefined && file.skipTranscode !== null) {
      data.skipTranscode = file.skipTranscode;
    }
    if (file.isOriginalFormat !== undefined && file.isOriginalFormat !== null) {
      data.isOriginalFormat = file.isOriginalFormat;
    }

    const promise = isPortal
      ? Api.post(`${getResourceApi()}/api/upload/sessions`, data)
      : RuntimeApi.post(`${getResourceRuntimeApi()}/api/runtime/upload/sessions`, data);

    const resp = await promise;

    // console.log('### create an upload session: ', resp);
    sessionId = resp.sessionId;
    r.opts.sessionId = sessionId;
    const targetUrl = !isPortal
      ? `${getResourceRuntimeApi()}/api/runtime/upload/sessions/${sessionId}/files`
      : `${getResourceApi()}/api/upload/sessions/${sessionId}/files`;
    r.opts.target = targetUrl;
    r.upload();
  } catch (error) {
    console.log('ERROR', error);
    if (onError && typeof onError === 'function') {
      onError(error, file, sessionId);
    }
  }

  return r;
}

export async function uploadSpaceMaterial(spaceId, file) {
  // console.log('uploadBigFile', userId);
  const isPortal = appInfo.isPortal();
  const resumableHeaders = { 'X-CSRF': '1' };
  if (isPortal) {
    const organization = currentOrganization.getData();
    if (organization) {
      Object.assign(resumableHeaders, { organizationId: organization.id });
    }
  } else {
    const token = spaceUser.getAccessToken();
    Object.assign(resumableHeaders, { authorization: `Bearer ${token}` });
  }

  const fileName = file.fileName || file.name || 'noname'; // original file name
  const sanitizedFileName = sanitizeFileName(fileName);
  const newFile = file;
  newFile.fileName = sanitizedFileName; // to avoid firewall issue

  const r = new Resumable({
    chunkSize: 1 * 1024 * 1024,
    forceChunkSize: true,
    simultaneousUploads: 1,
    uploadMethod: 'PUT',
    testChunks: false,
    maxChunkRetries: 3,
    chunkRetryInterval: 1000,
    throttleProgressCallbacks: 1,
    fileParameterName: 'file',
    chunkNumberParameterName: 'chunkNumber',
    chunkSizeParameterName: 'chunkSize',
    currentChunkSizeParameterName: 'chunkSize',
    fileNameParameterName: 'fileName',
    totalSizeParameterName: 'totalSize',
    headers: resumableHeaders,
  });

  // console.log('uploadBigFile', file);
  r.on('fileAdded', (f) => {
    console.log('fileAdded', f);
    let resourceType = f.file?.resourceType;
    if (f.resourceType === undefined) {
      resourceType = getResourceFileType(f.file);
    }
    const data = {
      chunkSize: r.opts.chunkSize,
      totalSize: f.size,
      fileName: sanitizedFileName,
      fileCategoryType: f.file?.fileCategoryType || 0,
      feature: f.file?.feature !== null ? f.file?.feature : null,
      isCreateResource: true,
      spaceId: spaceId,
      resourceType,
      resourceName: fileName,
      resourceDescription: f.file?.fileDescription || fileName,
    };
    if (f.file.skipTranscode !== undefined) {
      data.skipTranscode = f.file.skipTranscode;
    }
    if (f.file.isOriginalFormat !== undefined && f.file.isOriginalFormat !== null) {
      data.isOriginalFormat = f.file.isOriginalFormat;
    }

    const promise = isPortal
      ? Api.post(`${getResourceApi()}/api/upload/sessions`, data)
      : RuntimeApi.post(`${getResourceRuntimeApi()}/api/runtime/upload/sessions`, data);

    promise
      .then((resp) => {
        // console.log('uploadBigFile', resp, r);
        const sessionId = resp.sessionId;
        r.opts.sessionId = sessionId;
        const targetUrl = !isPortal
          ? `${getResourceRuntimeApi()}/api/runtime/upload/sessions/${sessionId}/files`
          : `${getResourceApi()}/api/upload/sessions/${sessionId}/files`;
        r.opts.target = targetUrl;
        r.upload();
      })
      .catch((error) => {
        console.log('ERROR', error);
        r.fire('fileError', error);
      });
  });
  r.addFile(newFile);
  return r;
}

export function addResource(data) {
  return Api.post(`${getResourceApi()}/api/resources`, data);
}

export function updateResource(resourceId, data) {
  return Api.put(`${getResourceApi()}/api/resources/${resourceId}`, data);
}

export async function sendLogSelectOrganization(data) {
  return Api.post(`${getIdentityApi()}/api/log/select-organization`, data);
}

export function createResourceInteraction(resourceId, data, isPortal = false) {
  if (isPortal) {
    return Api.post(`${getResourceApi()}/api/resources/${resourceId}/interactions`, data);
  }
  return RuntimeApi.get(
    `${getResourceRuntimeApi()}/api/runtime/resources/${resourceId}/interactions`,
    data
  );
}

export function sendBrowserEvents(spaceId, sessionId, events) {
  return RuntimeApi.post(
    `${getInsightsAnalyticRuntimeApi()}/api/runtime/spaces/${spaceId}/interactions`,
    {
      sessionId,
      events,
    }
  ).catch((error) => console.error(error));
}

export function shareMaterialTrack(spaceId, materialId, data) {
  return RuntimeApi.get(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/materials/${materialId}/share`,
    data
  );
}
