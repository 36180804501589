import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CopyResourceShareLink from '../../components/CopyResourceShareLink';
import { useCommentContext } from './context/CommentContextProvider';
import spaceUser from '../spaceUser';
import { shareMaterialTrack } from '../../commons/CommonServices';
import { copyTextToClipboard, sendNotification } from '../../commons/utils';
import { useSpaceContext } from '../SpaceContext';
import useURLSpace from '../../homepage/useURLSpace';
import I18n from '../../i18n';
import { IComment } from '../../types/comments';
import Loading from '../../components/Loading';

type Props = {
  handleCloseMenu: () => void;
  handleOnClickEdit: (comment?: IComment) => void;
  handleOnClickDelete: (comment?: IComment) => void;
  handleOnClickReply: (comment?: IComment) => void;
  handleOnClickLike: (comment?: IComment) => void;
  spaceId: string;
  isMyself: boolean;
  isHost: boolean;
  isMobile?: boolean;
  liked?: boolean;
};

const ActionMenu = ({
  handleCloseMenu,
  spaceId,
  handleOnClickEdit,
  handleOnClickDelete,
  handleOnClickLike,
  handleOnClickReply,
  isMyself,
  isHost,
  isMobile,
  liked,
}: Props) => {
  const { materialId, canEdit, isLoading, comment, menuAnchorEl } = useCommentContext();
  const { space } = useSpaceContext();
  const { getShareLinkSpace } = useURLSpace(space);

  if (!menuAnchorEl) return null;

  const showDeleteButton = isHost || isMyself;

  async function handleOnClickShareLink() {
    const shareLink = getShareLinkSpace({ srId: materialId });

    if (shareLink) {
      await copyTextToClipboard(shareLink);
      sendNotification(I18n.t('Link copied!'));
      handleCloseMenu();
      const sharedCode = spaceUser.getShareSpaceCode();
      shareMaterialTrack(spaceId, materialId, {
        code: sharedCode,
        isClickButton: true,
      });
    }
  }

  function onClickLike() {
    handleOnClickLike(comment);
    handleCloseMenu();
  }

  function onClickReply() {
    handleOnClickReply(comment);
    handleCloseMenu();
  }

  if (isLoading) {
    return (
      <Menu
        id="simple-menu"
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleCloseMenu}
      >
        <MenuItem>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <Loading />
          </section>
        </MenuItem>
      </Menu>
    );
  }

  return (
    <Menu
      id="simple-menu"
      className="comments-action-menu"
      anchorEl={menuAnchorEl}
      open={!!menuAnchorEl}
      onClose={handleCloseMenu}
      classes={{ paper: 'select-menu' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {materialId && (
        <CopyResourceShareLink>
          <MenuItem onClick={handleOnClickShareLink}>
            <section className="displayFlex row-direction alignItemsCenter gap8">
              <LinkIcon fontSize="small" style={{ width: '24px' }} />
              {I18n.t('Copy link')}
            </section>
          </MenuItem>
        </CopyResourceShareLink>
      )}
      {isMyself && canEdit && (
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            handleOnClickEdit(comment);
          }}
        >
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-update displayFlex justifyContentCenter width24" />
            {I18n.t('Edit')}
          </section>
        </MenuItem>
      )}
      {showDeleteButton && (
        <MenuItem onClick={() => handleOnClickDelete(comment)}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-delete displayFlex justifyContentCenter width24" />
            {I18n.t('Delete')}
          </section>
        </MenuItem>
      )}
      {isMobile && (
        <MenuItem onClick={onClickLike}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            {liked ? (
              <span className="icon-minus-liked displayFlex justifyContentCenter width24"></span>
            ) : (
              <FavoriteIcon />
            )}
            {I18n.t('Like')}
          </section>
        </MenuItem>
      )}
      {isMobile && (
        <MenuItem onClick={onClickReply}>
          <section className="displayFlex row-direction alignItemsCenter gap8">
            <span className="icon-share displayFlex justifyContentCenter width24"></span>
            {I18n.t('Reply')}
          </section>
        </MenuItem>
      )}
    </Menu>
  );
};

export default ActionMenu;
