import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';
import { messageTemplates } from './constants';

export function getNotificationContext(context, messageTemplateId, messageTemplateData) {
  switch (messageTemplateId) {
    case messageTemplates.AddNewOneDriveResourceVersion:
    case messageTemplates.DeleteOneDriveResource:
      return 'OneDrive';
    case messageTemplates.AddNewGoogleDriveResourceVersion:
    case messageTemplates.DeleteGoogleDriveResource:
      return 'GoogleDrive';
    case messageTemplates.ExternalInitProcessCompleted:
      if (messageTemplateData?.provider === SERVICE_PROVIDER.ONEDRIVE) {
        return 'OneDrive';
      } else if (messageTemplateData?.provider === SERVICE_PROVIDER.GOOGLE_DRIVE) {
        return 'GoogleDrive';
      }
      return context;
    default:
      return context;
  }
}
