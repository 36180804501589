import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getResourceApi, getResourceRuntimeApi } from '../commons/utils';
import CustomSigalRHttpClient from '../commons/CustomSigalRHttpClient';
import spaceUser from '../spaces/spaceUser';
import { CacheKeys, clearCache } from '../app/queryCache';
import eventBus, { EVENT_BUS } from '../commons/EventBus';

const signalR = require('@microsoft/signalr');

const onDirectoryCreated = (message) => {
  eventBus.publish(EVENT_BUS.DirectoryAdded, message);
};

const onDirectoryUpdated = (message) => {
  eventBus.publish(EVENT_BUS.DirectoryUpdated, message);
};

const onDirectoryUploaded = (message) => {
  eventBus.publish(EVENT_BUS.DirectoryUploaded, message);
};

const onDirectoryMoved = (message) => {
  eventBus.publish(EVENT_BUS.DirectoryMoved, message);
};
const onDirectoryRemoved = (message) => {
  eventBus.publish(EVENT_BUS.DirectoryDeleted, message);
};

const onShareFolderUpdated = (message) => {
  eventBus.publish(EVENT_BUS.SharedFolderUpdated, message);
};

const onMaterialCreated = (message) => {
  eventBus.publish(EVENT_BUS.MaterialAdded, message);
};

const onMaterialUpdated = (message) => {
  eventBus.publish(EVENT_BUS.MaterialUpdated, message);
};

const onMaterialMoved = (message) => {
  eventBus.publish(EVENT_BUS.MaterialMoved, message);
};

const onMaterialRemoved = (message) => {
  eventBus.publish(EVENT_BUS.MaterialDeleted, message);
};

const onVideoIndexed = (message) => {
  console.log('onVideoIndexed', message);
  eventBus.publish(EVENT_BUS.VideoIndexed, message);
};

const onConversionFinished = (message) => {
  console.log('onConversionFinished', message);
  clearCache([CacheKeys.getResourceForViewer, message.id], false);
  eventBus.publish(EVENT_BUS.ConversionFinished, message);
};

const onTranscodingFinished = (message) => {
  eventBus.publish(EVENT_BUS.TranscodingFinished, message);
};

const onConversionStarted = (message) => {
  eventBus.publish(EVENT_BUS.ConversionStarted, message);
};

const onConversionFailed = (message) => {
  console.log('onConversionFailed', message);
  clearCache([CacheKeys.getResourceForViewer, message.id], false);
  eventBus.publish(EVENT_BUS.ConversionFailed, message);
};

const onResourceFolderDeleted = (message) => {
  eventBus.publish(EVENT_BUS.ResourceFolderDeleted, message);
};

const onResourceFolderAdded = (message) => {
  eventBus.publish(EVENT_BUS.ResourceFolderAdded, message);
};

const onResourceFolderUpdated = (message) => {
  eventBus.publish(EVENT_BUS.ResourceFolderUpdated, message);
};

const onResourceAddedToFolder = (message) => {
  eventBus.publish(EVENT_BUS.ResourceAddedToFolder, message);
};

const onResourceDeleted = (message) => {
  eventBus.publish(EVENT_BUS.ResourceDeleted, message);
};

const onResourceUpdated = (message) => {
  eventBus.publish(EVENT_BUS.ResourceUpdated, message);
};

const onResourcesUpdatedFromTemplate = (message) => {
  eventBus.publish(EVENT_BUS.MaterialsUpdatedFromTemplates, message);
};

const onMaterialsCopied = (message) => {
  eventBus.publish(EVENT_BUS.MaterialsCopied, message);
};

function bindResourceConnectionMessage(connection) {
  // Listen messages for directory
  connection.on('DirectoryCreated', onDirectoryCreated);
  connection.on('DirectoryUpdated', onDirectoryUpdated);
  connection.on('DirectoryMoved', onDirectoryMoved);
  connection.on('DirectoryRemoved', onDirectoryRemoved);
  connection.on('ShareFolderUpdated', onShareFolderUpdated);
  connection.on('DirectoryUploaded', onDirectoryUploaded);

  // Listen messages for material
  connection.on('MaterialCreated', onMaterialCreated);
  connection.on('MaterialUpdated', onMaterialUpdated);
  connection.on('MaterialRemoved', onMaterialRemoved);
  connection.on('MaterialMoved', onMaterialMoved);

  // Listen messages for file converting
  connection.on('ConversionStarted', onConversionStarted);
  connection.on('ConversionFinished', onConversionFinished);
  connection.on('ConversionFailed', onConversionFailed);
  connection.on('TranscodingFinished', onTranscodingFinished);

  // Listen messages for resource folder
  connection.on('ResourceFolderDeleted', onResourceFolderDeleted);
  connection.on('ResourceFolderAdded', onResourceFolderAdded);
  connection.on('ResourceFolderUpdated', onResourceFolderUpdated);
  connection.on('ResourceAddedToFolder', onResourceAddedToFolder);
  connection.on('ResourceDeleted', onResourceDeleted);
  connection.on('ResourceUpdated', onResourceUpdated);

  // Listen messages for global templates
  connection.on('ResourcesUpdatedFromTemplate', onResourcesUpdatedFromTemplate);

  // Listen messages for video
  connection.on('VideoIndexed', onVideoIndexed);

  // Listen messages for copying and pasting resources in cms blocks
  connection.on('MaterialsCopied', onMaterialsCopied);
}

function createResourceConnection(isPortal) {
  const url = isPortal ? `${getResourceApi()}/portal` : `${getResourceRuntimeApi()}/runtime`;
  return new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => {
        let token;
        if (!isPortal) {
          token = spaceUser.getAccessToken();
        }
        return token;
      },
      httpClient: new CustomSigalRHttpClient(),
    })
    .withAutomaticReconnect()
    .build();
}

// function createPollingConnection(isPortal, onConnected) {
//   const connectionId = 'long-polling';
//   const url = `${
//     isPortal ? getResourceApi() : getResourceRuntimeApi()
//   }/api/runtime/pollings/spaceresource`;
//   const connection = new LongPollingConnection(connectionId, url, {
//     headers: {
//       Authorization: `Bearer ${spaceUser.getAccessToken()}`,
//     },
//   });
//   bindResourceConnectionMessage(connection);

//   connection.start();
//   onConnected(connection);
// }

class ResourceSignalRHandler extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      connectionHub: null,
    };
    this.connection = null;
    this.shouldStopConnection = false;
  }

  async componentDidMount() {
    const { isPortal } = this.props;
    // createPollingConnection(isPortal, this.handleConnected);
    if (this.shouldStopConnection) {
      this.cleanUpConnection();
      this.shouldStopConnection = false;
      return;
    }
    this.connection = createResourceConnection(isPortal);

    this.connection
      .start()
      .then(() => {
        this.setState({ connectionHub: this.connection });
        if (this.props.onConnected) {
          this.props.onConnected(this.connection);
        }
        bindResourceConnectionMessage(this.connection);
      })
      .catch((error) => {
        this.shouldStopConnection = true;
        console.log(`SignalRHandler Fail: ${error.message}`);
      });
  }

  async cleanUpConnection() {
    if (
      (this.connection && this.connection?.state === 'Connecting') ||
      this.connection?.state === 'Reconnecting'
    ) {
      this.shouldStopConnection = true;
      return;
    }
    if (!this.connection) return;

    this.connection.off();
    await this.connection.stop();
    this.connection = null;
  }

  componentWillUnmount() {
    this.cleanUpConnection();
  }

  render() {
    return null;
  }
}

ResourceSignalRHandler.propTypes = {
  isPortal: PropTypes.bool,
  onConnected: PropTypes.func,
};

ResourceSignalRHandler.defaultProps = {
  isPortal: true,
  spaceId: null,
  onConnected: () => null,
};

export default ResourceSignalRHandler;
