import initVaamUtils from '@vaam/sdk/utils';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';

const useVaamThumbnailUrlQuery = (vaamId, retry = 3) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const retries = useRef(retry);
  const [isLoading, setIsLoading] = useState(true);
  const query = useQuery({
    queryKey: [CacheKeys.getVaamThumbnailUrls, vaamId],
    queryFn: async () => {
      const isTestEnv = process.env.REACT_APP_ENV !== 'prod';
      const vaamUtils = initVaamUtils(process.env.REACT_APP_VAAM_KEY, isTestEnv);
      const resp = await vaamUtils.getThumbnailUrls(vaamId);
      return resp;
    },
    retry: 3,
    retryDelay: () => 5000,
    enabled: !!vaamId,
  });

  useEffect(() => {
    let timeout;
    async function fetchThumbnailUrl() {
      if (query.data === undefined) {
        return;
      }

      const url = query.data?.jpg || query.data?.gif;
      if (url) {
        setThumbnailUrl(url);
        setIsLoading(false);
      } else {
        // retry if thumbnail is not yet generated by vaam
        if (retries.current === 0) {
          setIsLoading(false);
          return;
        }
        timeout = setTimeout(() => {
          query.refetch();
          retries.current -= 1;
        }, 5000);
      }
    }

    fetchThumbnailUrl();

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data]);

  return { isLoading, thumbnailUrl };
};

export default useVaamThumbnailUrlQuery;
