import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceAssessmentsSummary } from '../services/SpaceAnalyticsService';

const useSpaceAssessmentSummaryQuery = (
  spaceId: string | undefined,
  enabled: boolean,
  endDate: string,
  excludeUserIds: number[]
) => {
  const spaceAssessmentsSummaryQuery = useQuery({
    queryKey: [
      CacheKeys.spaceAnalytics_FetchSpaceAssessmentSummary,
      spaceId,
      endDate,
      excludeUserIds,
    ],
    queryFn: async () => {
      if (!spaceId) {
        return [];
      }
      const resp = await fetchSpaceAssessmentsSummary(excludeUserIds, endDate);
      return resp;
    },

    retry: 1,
    retryDelay: () => 5000,
    enabled: enabled,
  });

  const isFetching: boolean = spaceAssessmentsSummaryQuery.isFetching;

  const assessmentsSummary = spaceAssessmentsSummaryQuery.data?.assessments || [];

  return { assessmentsSummary, isFetching };
};

export default useSpaceAssessmentSummaryQuery;
