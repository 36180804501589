import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import './CannotOpenLinkIframe.scss';

const CannotOpenLinkIframe = (props) => {
  const { icon, title, description, className } = props;
  return (
    <Box className={`message-not-open-link-root ${className}`}>
      <span className="message-not-open-link-icon">{icon}</span>
      <b>{title}</b>
      <div className="message-not-open-link-textGray">{description}</div>
    </Box>
  );
};

CannotOpenLinkIframe.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  className: PropTypes.string,
};

export default CannotOpenLinkIframe;
