import { isEqual } from 'lodash';
import { Alert, AlertProps, AlertTitle, Snackbar } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import i18n from '../../i18n';
import '../../components/NotificationMessages.scss';
import { sendNotification } from '../../commons/utils';
import useInterval from '../../commons/useInterval';
import { fetchDownloadRequestIds } from '../ResourceServices';
import eventBus, { EVENT_BUS } from '../../commons/EventBus';
import useSessionStorage from '../../commons/useSessionStorage';
import ConfirmDialog from '../../components/ConfirmDialog';

export function AlertMessage(props: Readonly<AlertProps>) {
  return (
    <Alert elevation={6} variant="filled" severity={props.severity}>
      {props.children}
    </Alert>
  );
}

type MessageDetailsType = {
  url: string;
  requestId: string;
  status: string;
};

type DownloadResourceHandlerProps = {
  isPortal: boolean;
};

const DownloadStatus = {
  Processing: 'Processing',
  Failed: 'Failed',
  Completed: 'Completed',
};

type CustomEvent = MessageEvent & { detail: string };

const handleDownloadURl = (details: MessageDetailsType) => {
  const { url } = details;
  const link = document.createElement('a');
  const urlDecode = decodeURI(url);
  const fileName = urlDecode.substring(urlDecode.lastIndexOf('/') + 1, urlDecode.lastIndexOf('?'));
  link.target = '_blank';
  link.download = fileName;
  link.href = url;
  link.style.display = 'none';
  document.body.appendChild(link);

  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};

const DownloadResourceHandler = (props: DownloadResourceHandlerProps) => {
  const { isPortal } = props;
  const [downloadRequestIds, setDownloadRequestIds] = useSessionStorage('downloadRequestIds', []);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const handleOnClose = async () => {
    setIsOpen(false);
    setIsConfirming(true);
    const isConfirmed = await ConfirmDialog.show(
      i18n.t('Cancel Download'),
      <span>
        <b>{i18n.t('This can’t be undone.')}</b>
        <br />
        <span>{i18n.t('Are you sure you want to cancel the download?')}</span>
      </span>,
      i18n.t('yes'),
      i18n.t('no')
    );
    if (isConfirmed) {
      setDownloadRequestIds([]);
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    setIsConfirming(false);
  };

  const fetchDownloadUrl = useCallback(
    async (requestIds: []) => {
      if (!requestIds?.length) {
        return;
      }

      let newRequestIds = [...requestIds];

      const resp = await fetchDownloadRequestIds(newRequestIds, isPortal);

      if (resp?.items?.length) {
        resp.items.forEach(async (item: MessageDetailsType) => {
          if (item.status === DownloadStatus.Failed) {
            sendNotification(i18n.t('Sorry, something went wrong. Please try again!'), {
              type: 'error',
            });
          } else if (item.status === DownloadStatus.Completed) {
            handleDownloadURl(item);
          }

          if (item.status !== DownloadStatus.Processing) {
            newRequestIds = newRequestIds.filter((id) => !isEqual(id, item.requestId));
          }
        });
      }

      setDownloadRequestIds(newRequestIds);
    },
    [isPortal, setDownloadRequestIds]
  );

  useEffect(() => {
    setIsOpen(!!downloadRequestIds?.length);
  }, [downloadRequestIds?.length]);

  const syncDownloadByRequestIds = useCallback(async () => {
    if (isConfirming) {
      return;
    }
    if (downloadRequestIds?.length !== 0) {
      await fetchDownloadUrl(downloadRequestIds);
    }
  }, [downloadRequestIds, fetchDownloadUrl, isConfirming]);

  useInterval(syncDownloadByRequestIds, 5000);

  useEffect(() => {
    function handleEventDownloadMessageId(message: CustomEvent) {
      setDownloadRequestIds((oldData: string[]) => {
        if (!oldData) {
          return [message];
        }
        const isExist = oldData.some((id: string) => isEqual(id, message));
        if (isExist) {
          return oldData;
        }
        return [...oldData, message];
      });
    }
    const unsubscribeDownLoadEvent = eventBus.subscribe(
      EVENT_BUS.DownloadResourcesEvent,
      handleEventDownloadMessageId
    );

    return () => {
      unsubscribeDownLoadEvent();
    };
  }, [setDownloadRequestIds]);

  return (
    <Snackbar
      open={isOpen}
      className="notification-mes"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div>
        <Alert
          onClose={handleOnClose}
          severity="success"
          icon={<span className="icon-spinner"></span>}
        >
          <AlertTitle>{i18n.t('You can continue using SPCE while waiting')}</AlertTitle>
          {i18n.t('Preparing your download...Please keep this tab open')}
        </Alert>
      </div>
    </Snackbar>
  );
};

export default DownloadResourceHandler;
