import React from 'react';
import { ISpaceUser } from '../../../types/User';
import UserAvatar from '../../../components/UserAvatar';
import './style.scss';
import { OrganizationUserRole, SpaceUserRole } from '../../../app/appConstants';
import { getTimeActive } from '../../../spaces/SpaceUsers/functions';
import { isOrgRole } from '../../../commons/utils';

type SpaceUserAvatarProps = {
  userInfo: ISpaceUser;
  className?: string;
  showActiveStatus?: boolean;
};

function SpaceUserAvatar({ userInfo, className, showActiveStatus }: SpaceUserAvatarProps) {
  function renderRole() {
    if (!userInfo.isOrganizationUser) {
      return null;
    }
    let role = 'User';
    if (isOrgRole(userInfo.orgRole, OrganizationUserRole.Partner)) {
      role = 'Partner';
    } else {
      switch (userInfo.role) {
        case SpaceUserRole.Host:
          role = 'Host';
          break;
        case SpaceUserRole.Participant:
          role = 'User';
          break;
        default:
          break;
      }
    }
    return <span className="role-tag">{role}</span>;
  }

  const activeStatus = getTimeActive(userInfo.lastActive, userInfo.isOnline, false);

  function renderActiveStatus() {
    if (userInfo.isOnline) {
      return ` • ${activeStatus}`;
    } else {
      return ` • Last active ${activeStatus}`;
    }
  }

  return (
    <div className={`space-user-avatar ${className}`}>
      <UserAvatar
        fullName={userInfo.fullName}
        email={userInfo.email}
        isVisitor={userInfo.isVisitor}
      />
      <div className="username-container">
        <div className="username">
          <span className="full-name">{userInfo.fullName}</span>
          {renderRole()}
        </div>
        {userInfo.email ? (
          <div className="email">
            <span>{userInfo.email}</span>
            {showActiveStatus && activeStatus && <span>{renderActiveStatus()}</span>}
          </div>
        ) : (
          <div className="email">{activeStatus && <span>Last active {activeStatus}</span>}</div>
        )}
      </div>
    </div>
  );
}

export default SpaceUserAvatar;
