import memoizeOne from 'memoize-one';
import { getPlayer } from '../../commons/ResourceUtils';
import { isValidUrl } from '../../commons/ValidationUtils';
import { checkUREmbeddable } from '../../resources/ResourceServices';

const getFullURl = memoizeOne((webUrl) => {
  if (!webUrl.match(/^https?:\/\//i)) {
    return `https://${webUrl}`;
  } else {
    return webUrl;
  }
});

export const requestCheckEmbedableUrl = async (value) => {
  const url = getFullURl(value);
  const isValid = isValidUrl(url);

  if (!isValid) {
    return false;
  }
  if (value?.includes('spce.com')) {
    return true;
  }
  const { type } = getPlayer(value);
  if (type) {
    return true;
  }
  try {
    const resp = await checkUREmbeddable(url);
    return resp?.embeddable;
  } catch (error) {
    console.error(error);
    return false;
  }
};
