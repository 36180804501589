import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { CacheKeys } from '../app/queryCache';
import currentOrganization from '../commons/CurrentOrganization';
import { fetchBasicSpacesList } from './services';
import { useLocalStorage } from '../commons/CustomHooks';
import { SpaceState } from '../app/appConstants';

function usePartnerRedirectToSpace() {
  const navigate = useNavigate();

  const [redirectedToSpace, setRedirectedToSpace] = useLocalStorage('redirectedToSpace', false);
  const enabled = currentOrganization.isPartner() && !redirectedToSpace;

  const query = useQuery({
    queryKey: [CacheKeys.fetchBasicSpacesList],
    queryFn: async () => {
      const resp = await fetchBasicSpacesList();
      return resp;
    },
    ...{
      enabled,
    },
  });

  const redirectToSpace = useMemo(() => {
    if (query?.data?.items?.length) {
      const openSpaces = query.data.items.filter((space) => space.state === SpaceState.Open);
      if (openSpaces?.length === 1) {
        return openSpaces[0].urlSlug;
      }
    }
    return null;
  }, [query.data]);

  useEffect(() => {
    if (redirectToSpace && enabled) {
      setRedirectedToSpace(true);
      navigate(`/s/${redirectToSpace}`);
    }
  }, [redirectToSpace, navigate, enabled, setRedirectedToSpace]);

  return {
    isCheckingPartnerRedirect: query.isLoading,
  };
}

export default usePartnerRedirectToSpace;
