const signalR = require('@microsoft/signalr');

class CustomSigalRHttpClient extends signalR.DefaultHttpClient {
  send(request) {
    request.headers = { ...request.headers, 'X-CSRF': '1' };
    return super.send(request);
  }
}

export default CustomSigalRHttpClient;
