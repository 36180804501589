import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
// import { CMS_COMPONENT_STYLE } from '../cmsConstants';
import i18n from '../../i18n';
import '../commons/Column.scss';

function WidgetColumnPicker(props) {
  // const { size, onChange } = props;

  // const getNumberOfColumn = () => {
  //   let numOfColumn = 0;
  //   switch (size) {
  //     case CMS_COMPONENT_STYLE.SIZE.SMALL:
  //       numOfColumn = 6;
  //       break;
  //     case CMS_COMPONENT_STYLE.SIZE.MEDIUM:
  //     case CMS_COMPONENT_STYLE.SIZE.LARGE:
  //       numOfColumn = 4;
  //       break;
  //     default:
  //       break;
  //   }
  //   return numOfColumn;
  // };

  // const renderColumnList = () => {
  //   const columnList = [];
  //   const numberOfColumn = getNumberOfColumn();
  //   let initialColumnCount = 1;
  //   while (initialColumnCount <= numberOfColumn) {
  //     columnList.push(initialColumnCount);
  //     initialColumnCount += 1;
  //   }
  //   return columnList;
  // };

  const handleClick = (value) => {
    if (props.onChange) props.onChange(value);
  };

  // const isLarge = CMS_COMPONENT_STYLE.SIZE.LARGE === size;
  // if (isLarge) return null;

  return (
    <Grid className="cms-column-picker">
      <div className="label">{i18n.t('Number of widgets')}</div>
      <div className="grid-container">
        {[1, 2, 3, 4].map((value) => (
          <div
            key={value}
            className={`item ${value === props.value ? 'active' : ''}`}
            onClick={() => handleClick(value)}
          >
            {value}
          </div>
        ))}
      </div>
    </Grid>
  );
}

WidgetColumnPicker.propTypes = {
  // size: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default WidgetColumnPicker;
