import React from 'react';
import { Grid } from '@mui/material';
import useSpaceAnalyticsQuery from '../hooks/useSpaceAnalyticsQuery';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceAnalyticsSummary } from '../services/SpaceAnalyticsService';
import Loading from '../../components/Loading';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import EngagementTable from '../EngagementTable';
import ParticipantTrafficTable from '../ParticipantTrafficTable';
import ResourceTable from '../ResourceWidget/ResourceTable';
import ChatInfoTable from '../ChatInfoTable';
import useAvailableSpaceResources from '../hooks/useAvailableSpaceResources';
import { Feature } from '../../types/types';
import AssessmentTable from '../AssessmentTable';

function SpaceAnalyticsSummary() {
  const { space, excludeUserIds, visibleUsers, isFetchingUsers, isEnabledAssessments } =
    useSpaceAnalyticsContext();
  const spaceId = space?.id;

  const { availableMaterialIds, isLoading: isFetchingResources } = useAvailableSpaceResources();
  const spaceFeatures: Feature[] = space?.features || [];
  const isChatEnabled = spaceFeatures.some((feature) => feature.id === 'Chat' && feature.enabled);

  const { data, isLoading } = useSpaceAnalyticsQuery({
    fetcher: fetchSpaceAnalyticsSummary,
    params: {
      excludedUserInfoIds: excludeUserIds,
      users: visibleUsers?.map((x) => ({
        userInfoId: x.userInfoId,
        orgRole: x.orgRole,
        spaceRole: x.role,
      })),
      availableMaterialIds: availableMaterialIds,
    },
    queryKey: CacheKeys.fetchSpaceAnalyticsSummary,
    select: (resp) => resp.value,
    enabled: !!spaceId && !isFetchingUsers && !!availableMaterialIds,
  });

  function renderContent() {
    if (isFetchingResources || isFetchingUsers) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }

    return (
      <Grid container spacing={3}>
        {data?.engagement && (
          <Grid item lg={data?.traffic ? 6 : 12} xs={12} sm={12}>
            <EngagementTable engagementSummary={data?.engagement} />
          </Grid>
        )}
        {data?.traffic && (
          <Grid item lg={data?.engagement ? 6 : 12} md={12} xs={12} sm={12}>
            <ParticipantTrafficTable trafficSummary={data?.traffic} />
          </Grid>
        )}
        {data?.resources && (
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <ResourceTable
              resourcesSummary={data?.resources}
              resourceMetricsByResources={data?.resourceMetricsByResources}
              resourceMetricsByParticipants={data?.resourceMetricsByParticipants}
            />
          </Grid>
        )}

        {isChatEnabled && (
          <Grid item lg={isEnabledAssessments ? 6 : 12} md={12} xs={12} sm={12}>
            <ChatInfoTable />
          </Grid>
        )}
        {isEnabledAssessments && (
          <Grid item lg={isChatEnabled ? 6 : 12} md={12} xs={12} sm={12}>
            <AssessmentTable />
          </Grid>
        )}
      </Grid>
    );
  }

  if (isLoading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return renderContent();
}

export default SpaceAnalyticsSummary;
