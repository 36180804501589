import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import memoizeOne from 'memoize-one';
import { useNavigate } from 'react-router-dom';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import { sendNotification } from '../commons/utils';
import i18n from '../i18n';
import UserAvatar from '../components/UserAvatar';
import CustomizedMenu from '../components/CustomizedMenu/CustomizedMenu';
import './LoginMenu.scss';
// import { SubscriptionType, showPopup as showSubscriptionPopup } from '../commons/Subscription';
import currentOrganization from '../commons/CurrentOrganization';
import Icons from './Icons';
import authService from './api-authorization/AuthorizeService';
import { Ids } from '../commons/pendoTaggings';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../app/appConstants';
import { useAppContext } from './AppProvider';

const getItems = memoizeOne((onClickItem, isMobile, enabledCompanyAnalytics, isAdmin) => {
  const isEnterprise = currentOrganization.isEnterprise();

  const menuList = [
    // {
    //   id: 7,
    //   name: i18n.t('Space Overview'),
    //   onClick: onClickItem,
    //   isShow: false,
    //   icon: <Icons className="icon-homepage" />,
    // },
    // {
    //   id: 8,
    //   name: i18n.t('Activity Overview'),
    //   onClick: onClickItem,
    //   isShow: false,
    //   icon: <Icons className="icon-dashboard" />,
    // },
    {
      id: 2,
      name: i18n.t('Company Analytics'),
      onClick: onClickItem,
      disabled: false,
      isShow: isAdmin && enabledCompanyAnalytics && !isMobile,
      isLocked: !isEnterprise,
      // showDivider: true,
      icon: <Icons className="icon-insight" />,
      dataId: Ids.CompanyAnalytics,
    },
    // {
    //   id: 6,
    //   name: 'Participant Feedback',
    //   onClick: onClickItem,
    //   disabled: false,
    //   isShow: false,
    //   isLocked: isPremium,
    //   icon: <Icons className="icon-feedback" />,
    // },
    {
      id: 1,
      name: i18n.t('Settings'),
      onClick: onClickItem,
      isShow: !isMobile,
      icon: <Icons className="icon-settings" />,
      dataId: Ids.Settings,
    },
    // {
    //   id: 9,
    //   name: i18n.t('User Management'),
    //   onClick: onClickItem,
    //   isLocked: isFreemium,
    //   isShow:
    //     (isFreemium && !isMobile) ||
    //     (isPremium && isAdmin && !isMobile) ||
    //     (isEnterprise && isAdmin && !isMobile),
    //   icon: <Icons className="icon-participants" />,
    //   dataId: Ids.UserManagement,
    //   showDivider: isMobile,
    // },
    {
      id: 10,
      name: 'Global Resources',
      onClick: onClickItem,
      disabled: false,
      isShow: isEnterprise && isAdmin && !isMobile,
      icon: <Icons className="icon-folder-arrow-up" />,
      dataId: Ids.GlobalResources,
    },
    // {
    //   id: 4,
    //   name: i18n.t('Support'),
    //   onClick: onClickItem,
    //   isShow: false,
    //   icon: <Icons className="icon-support" />,
    // },
    {
      id: 5,
      name: i18n.t('Logout'),
      onClick: onClickItem,
      isShow: true,
      icon: <Icons className="icon-logout" />,
      dataId: Ids.Logout,
    },
  ];
  return menuList.filter((item) => item.isShow);
});

function LoginMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isAuthenticated, userInfo, isMobile } = useAppContext();
  const navigate = useNavigate();
  const userOrganization = currentOrganization.getData();
  // const tagLabel = getTagLabel(currentSubscription);
  const { checkEnabledFeatures } = useFeatureFlagsContext();

  const handleClickMenuIcon = (event) => {
    console.log('handleClickMenuIcon: ', event);
    setAnchorEl(event.target);
  };

  // const handleOnClickUpgrade = React.useCallback(() => {
  //   // const { subscriptionRequire, subscriptionFeatureType } =
  //   //   getSubscriptionRequireAndFeatureTypes(currentSubscription);

  //   setAnchorEl(null);
  //   // showSubscriptionPopup(subscriptionFeatureType, '', currentSubscription, subscriptionRequire);
  // }, [currentSubscription]);

  // const switchOrganization = React.useCallback(() => {
  //   currentOrganization.clearData();
  //   location.href = '/';
  // }, []);

  const onClickItem = React.useCallback(
    async (item) => {
      if (item.isLocked) {
        // handleOnClickUpgrade();
        setAnchorEl(null);
        return;
      }
      try {
        switch (item.id) {
          case 1:
            navigate('/profile/settings');
            break;
          case 2:
            navigate('/company-analytics');
            break;
          // case 3:
          //   if (item.isLocked) {
          //     console.log('Locked');
          //   } else {
          //     navigateTo('/profile/settings/integrations');
          //   }
          //   break;
          case 4:
            navigate('/profile/support');
            break;
          case 5:
            authService.signOut();
            navigate(ApplicationPaths.LogOut);
            break;
          case 6:
            navigate('/participant-feedback');
            break;
          case 7:
            navigate('/');
            break;
          case 9:
            navigate('/profile/settings/user-management');
            break;
          case 10:
            navigate('/profile/settings/global-resources');
            break;
          // case 11:
          //   switchOrganization();
          //   break;
          default:
            break;
        }
      } catch (e) {
        sendNotification(e.message, { type: 'error' });
      }
      setAnchorEl(null);
    },
    [navigate]
  );

  function renderHeader() {
    return (
      <div className="login-menu-header">
        <p
          className="capitalize bold text-truncate title"
          title={userOrganization?.organizationName}
        >
          {userOrganization?.organizationName}
        </p>
        <p className="description capitalize name">{userInfo?.fullName}</p>
        <p className="description">{userInfo?.contactEmail}</p>
        {isMobile && (
          <IconButton
            aria-label="close"
            className="close-button"
            color="primary"
            onClick={() => setAnchorEl(null)}
          >
            <CloseIcon className="close-icon" />
          </IconButton>
        )}
      </div>
    );
  }

  function renderMenu() {
    const [enabledCompanyAnalytics] = checkEnabledFeatures([
      FeatureFlagsType.SETTINGS_COMPANY_ANALYTICS,
    ]);
    const isAdmin = currentOrganization.isAdmin();

    const items = getItems(onClickItem, isMobile, enabledCompanyAnalytics, isAdmin);

    return (
      <CustomizedMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        title={i18n.t('Invite participants')}
        items={items}
        isItemChecked={() => true}
        renderHeader={renderHeader}
        className={`login-menu ${isMobile ? 'login-menu-mobile' : ''}`}
      />
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <IconButton
        className="login-menu-button"
        aria-label="Login"
        data-id={Ids.AvatarButton}
        onClick={handleClickMenuIcon}
      >
        <UserAvatar fullName={userInfo?.fullName} email={userInfo?.contactEmail} variant="black" />
      </IconButton>
      {renderMenu()}
    </>
  );
}

export default LoginMenu;
