// HTML viewer component

import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import Loading from '../../Loading';

const HTMLViewer = (props) => {
  const { src } = props;
  const [htmlContent, setHtmlContent] = useState(null);

  const fetchHtmlContentQuery = useQuery({
    queryKey: ['fetchHtmlContentQuery', src],
    queryFn: async () => {
      const response = await fetch(src);
      const data = await response.text();
      return data;
    },
    enabled: !!src,
    retry: false,
  });

  useEffect(() => {
    if (fetchHtmlContentQuery.data) {
      setHtmlContent(fetchHtmlContentQuery.data);
    }
  }, [fetchHtmlContentQuery.data]);

  if (!htmlContent) {
    return <Loading className="loading" />;
  }

  return (
    <iframe
      title="htmlViewer"
      srcDoc={htmlContent}
      width="100%"
      height="100%"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
      style={{ backgroundColor: '#fff' }}
    />
  );
};

HTMLViewer.propTypes = {
  src: PropTypes.string,
};

export default HTMLViewer;
