import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import './style.scss';
import SpaceAnalyticsInfoBox from '../SpaceAnalyticsInfoBox';
import SpaceActivityBox from '../SpaceActivityBox';
import useSpaceAnalyticsQuery from '../hooks/useSpaceAnalyticsQuery';
import { CacheKeys } from '../../app/queryCache';
import { fetchEngagementSummary } from '../services/SpaceAnalyticsService';
import Chart, { ChartDefinition } from '../components/Chart';
import Loading from '../../components/Loading';
import EngagementDetailsTable from './EngagementDetailsTable';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import { EngagementSummary } from '../types/engagement';
import { SpaceActiveTypes } from '../../homepage/spaceConstants';

type EngagementTableProps = {
  engagementSummary?: EngagementSummary;
};

function EngagementTable(props: EngagementTableProps) {
  const { engagementSummary } = props;
  const { space, excludeUserIds, visibleUsers } = useSpaceAnalyticsContext();
  const spaceId = space?.id;
  const { data, isLoading } = useSpaceAnalyticsQuery({
    fetcher: fetchEngagementSummary,
    params: {
      excludedUserInfoIds: excludeUserIds,
      users: visibleUsers?.map((x) => ({
        userInfoId: x.userInfoId,
        orgRole: x.orgRole,
        spaceRole: x.role,
      })),
    },
    queryKey: CacheKeys.fetchEngagementSummary,
    select: (resp) => resp.value,
    enabled: !engagementSummary && !!spaceId && !!visibleUsers && visibleUsers.length > 0,
  });

  const engagementData = engagementSummary || data;

  const defineAxis = useCallback(() => {
    if (!engagementData?.data) {
      return { left: undefined, right: undefined };
    }
    const flatData = engagementData.data.map((x) => x.data);
    const maxCount = Math.max(...flatData.map((x) => Math.max(x.actions)));
    return {
      left: {
        maxValue: maxCount,
        isDuration: false,
      },
      right: undefined,
    };
  }, [engagementData?.data]);

  const chartData = React.useMemo(() => engagementData?.data || [], [engagementData?.data]);

  const chartDefinitions = React.useMemo<ChartDefinition[]>(
    () => [
      {
        type: 'line',
        label: 'Actions',
        data: chartData?.map((x) => x.data.actions),
        color: '#4783ff',
        axisBinding: 'left',
      },
    ],
    [chartData]
  );

  const toolTipLabels = React.useMemo<string[]>(
    () => chartData?.map((x) => x.label) || [],
    [chartData]
  );

  const renderChart = () => {
    if (!chartData || chartData.length === 0) {
      return null;
    }

    const axis = defineAxis();

    return (
      <Chart
        chartDefinitions={chartDefinitions}
        leftAxesDefinition={axis.left}
        rightAxesDefinition={axis.right}
        tooltipLabels={toolTipLabels}
        chartLabels={engagementData?.labels || []}
      />
    );
  };

  const renderTotalActions = () => {
    if (isLoading) {
      return null;
    }

    return (
      <Box className="participant-active-box">
        <SpaceActivityBox
          activeType={engagementData?.activeType || SpaceActiveTypes.Today}
          toolTip="Total number of actions in the space, e.g. user traffic, resource interaction and chat messages."
          totalActions={engagementData?.totalActions || 0}
        />
      </Box>
    );
  };

  function renderContent() {
    if (isLoading) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }

    return (
      <>
        {renderTotalActions()}
        <Box className="chart-area">{renderChart()}</Box>
        {engagementData?.details && engagementData?.details?.length > 0 && (
          <EngagementDetailsTable engagementDetails={engagementData?.details} />
        )}
      </>
    );
  }

  return (
    <Box className="widget-box engagement-table">
      <SpaceAnalyticsInfoBox
        icon="icon-touch"
        title="Engagement for the past 30 days"
        description="Explore what users have done in the space for the past 30 days."
        className="engagement"
      />
      {renderContent()}
    </Box>
  );
}

export default EngagementTable;
