import React, { useState } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { Lock as LockIcon } from '@mui/icons-material';

import ButtonEnhanced from '../components/ButtonEnhanced';
import showSubscriptionPopup from '../commons/Subscription/SubscriptionPopup';
import i18n from '../i18n';
import LightTooltip from '../components/LightTooltip';
import currentOrganization from '../commons/CurrentOrganization';
import { getSubscriptionRequireAndFeatureTypes } from '../commons/Subscription/functions';

const listItems = memoizeOne((isMobile, isFreemium, isAdminRole) => {
  const listButtonMobile = [
    {
      name: 'Spaces',
      isShow: true,
    },
  ];
  const listButtonDeskTop = [
    {
      name: 'Spaces',
      isShow: true,
    },
    {
      name: 'Templates',
      isShow: isAdminRole,
      isLocked: isFreemium,
      disabled: true,
    },
  ];

  const listButtons = isMobile ? listButtonMobile : listButtonDeskTop;
  return listButtons.filter((item) => item.isShow);
});

function GroupButtons(props) {
  const { isMobile, handleChangeTab, selectedTab } = props;
  const [activeButton, setActiveButton] = useState(selectedTab);

  const isFreemium = currentOrganization.isFreemium();
  const isAdminRole = currentOrganization.isAdmin();

  const listButtons = listItems(isMobile, isFreemium, isAdminRole);

  const handleOnClickUpgrade = () => {
    const currentSubscription = currentOrganization.getSubscriptionType();
    const { subscriptionRequire, subscriptionFeatureType } =
      getSubscriptionRequireAndFeatureTypes(currentSubscription);
    showSubscriptionPopup(subscriptionFeatureType, '', currentSubscription, subscriptionRequire);
  };

  const handleOnClick = (item) => {
    const value = item.name;
    if (item.isLocked) {
      handleOnClickUpgrade();
      return;
    }

    setActiveButton(value);
    handleChangeTab(value);
  };

  return (
    <div className="group-buttons">
      {listButtons.map((item, index) => (
        <ButtonEnhanced
          key={index}
          data-id={item.id}
          className={`btn ${activeButton === item.name ? 'active' : ''}`}
          onClick={() => handleOnClick(item)}
        >
          <>
            {i18n.t(item.name)}
            {item.isLocked && (
              <LightTooltip title="Locked feature" placement="bottom-start">
                <LockIcon className="template-lock" />
              </LightTooltip>
            )}
          </>
        </ButtonEnhanced>
      ))}
    </div>
  );
}

GroupButtons.propTypes = {
  selectedTab: PropTypes.string,
  isMobile: PropTypes.bool,
  handleChangeTab: PropTypes.func,
};

export default GroupButtons;
