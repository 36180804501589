import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

function CheckboxAll(props) {
  const { listCheck, onChange, value } = props;

  const handleChange = (e) => {
    onChange(e);
  };

  const renderContent = () => {
    if (!listCheck) return '';
    const itemsChecked = listCheck?.filter((item) => item.enabled).length;
    const indeterminate = itemsChecked && itemsChecked !== listCheck.length;
    return (
      <Checkbox
        className="form-checkbox"
        checked={itemsChecked === listCheck.length}
        color="primary"
        value={value}
        indeterminate={indeterminate}
        onChange={handleChange}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    );
  };

  return <>{renderContent()}</>;
}

CheckboxAll.propTypes = {
  listCheck: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default CheckboxAll;
