import RuntimeApi from '../../commons/RuntimeApi';
import { getInsightsAnalyticRuntimeApi } from '../../commons/utils';

export async function triggerPanelOpenedEvent(spaceId: string, type: string) {
  const data = { PanelType: type };
  const rs = await RuntimeApi.post(
    `${getInsightsAnalyticRuntimeApi()}/api/runtime/spaces/${spaceId}/panel-opened`,
    data
  );
  return rs;
}
