import React from 'react';
import moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';
import OfficialButtons from '../../components/OfficialButtons';
import i18n from '../../i18n';
import { downloadUserTrafficParticipants } from '../services/SpaceAnalyticsService';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';

interface DownloadTrafficSummaryButtonProps {
  dataId: string;
}

const DownloadTrafficSummaryButton: React.FC<DownloadTrafficSummaryButtonProps> = (props) => {
  const { dataId } = props;
  const { space, reportEndDate, isPublic, visibleUsers, dateRange } = useSpaceAnalyticsContext();
  const spaceId = space?.id;

  const startDate = moment(dateRange?.startDate || space?.created)
    .toDate()
    .toISOString();
  const endDate = moment(dateRange?.endDate || reportEndDate)
    .toDate()
    .toISOString();

  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  async function handleOnClickDownload() {
    if (isDownloading) {
      return;
    }
    setIsDownloading(true);
    try {
      // const userInfoIds = users?.map((participant) => participant.userInfoId, participant.);
      const resp = await downloadUserTrafficParticipants(
        startDate,
        endDate,
        visibleUsers,
        isPublic,
        spaceId
      );

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(resp);
      link.download = 'Users-traffic';
      link.target = '_blank';
      link.style.display = 'none';
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      }, 500);
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  }

  return (
    <OfficialButtons
      isProcessing={isDownloading}
      dataId={dataId}
      label={i18n.t('Download')}
      icon={<GetAppIcon />}
      onClick={handleOnClickDownload}
      variant="rectangle-secondary"
    />
  );
};

export default DownloadTrafficSummaryButton;
