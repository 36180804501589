import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import ImageViewer from './ImageViewer';
import { CacheKeys } from '../app/queryCache';

const VimeoThumbnail = (props) => {
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  const fetchVimeoVideoThumbnailQuery = useQuery({
    queryKey: [CacheKeys.getVimeoVideoThumbnailUrl, props.videoUrl],
    queryFn: async () => {
      let thumbnailUrlTemp = '';

      try {
        const vimeoUrlParam = encodeURIComponent(props.videoUrl);

        const resp = await fetch(`https://vimeo.com/api/oembed.json?url=${vimeoUrlParam}`);

        if (resp.ok) {
          const data = await resp.json();
          thumbnailUrlTemp = data.thumbnail_url;
        }
      } catch (error) {
        console.error(error);
      }
      return thumbnailUrlTemp;
    },

    retry: 3,
    retryDelay: () => 5000,
  });

  useEffect(() => {
    const queryResponse = fetchVimeoVideoThumbnailQuery.data;
    if (!queryResponse) {
      return;
    }

    setThumbnailUrl(queryResponse);
  }, [fetchVimeoVideoThumbnailQuery.data]);

  return <ImageViewer src={thumbnailUrl} className={props.className} />;
};

VimeoThumbnail.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
};

VimeoThumbnail.defaultProps = {
  className: '',
};

export default VimeoThumbnail;
