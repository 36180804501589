import { useQuery } from '@tanstack/react-query';

import { getIntegrationConnections } from './integrationService';

import { CacheKeys } from '../app/queryCache';
import { useAppContext } from '../components/AppProvider';
import spaceUser from '../spaces/spaceUser';

const useIntegrationConnectionsQuery = (enabled = true) => {
  const { isPortal, userInfo } = useAppContext();
  // refer to this card to understand why we need to use organizationId as a key for refreshing query
  // https://favro.com/organization/d6a4cf6830e8d0917ed8accf/07fbd0cc370e9b647027631c?card=SPC-5070
  let organizationId = null;
  if (isPortal) {
    organizationId = userInfo?.organizationId;
  } else {
    organizationId = spaceUser?.getOrganizationId();
  }

  return useQuery({
    queryKey: [CacheKeys.getIntegrationConnections, organizationId, isPortal],
    queryFn: async () => {
      const resp = await getIntegrationConnections(isPortal);
      return resp || [];
    },
    retry: 3,
    retryDelay: () => 5000,
    enabled: enabled && !!organizationId,
  });
};

export default useIntegrationConnectionsQuery;
