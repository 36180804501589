import React, { createContext, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import Loading from '../../components/Loading';
import useFeatureFlagsQuery from './useFeatureFlagsQuery';

export const FeatureFlagsContext = createContext();
export const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);
export const getFeatureFlagsContext = (featureFlags) => ({ featureFlags });

function FeatureFlagsContextProvider(props) {
  const { children, organizationId } = props;
  const { featureFlags, isLoading } = useFeatureFlagsQuery(organizationId);

  function renderLoading() {
    if (!isLoading) {
      return null;
    }
    return (
      <div className="loading-box">
        <Loading />
      </div>
    );
  }

  const context = getFeatureFlagsContext(featureFlags);
  const checkEnabledFeatures = useCallback(
    (features) => {
      return features.map((f) => {
        return featureFlags.includes(f);
      });
    },
    [featureFlags]
  );
  context.checkEnabledFeatures = checkEnabledFeatures;

  return (
    <FeatureFlagsContext.Provider value={context}>
      {isLoading ? renderLoading() : children}
    </FeatureFlagsContext.Provider>
  );
}

FeatureFlagsContextProvider.propTypes = {
  children: PropTypes.element,
  organizationId: PropTypes.string,
};

export default FeatureFlagsContextProvider;
