import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DefaultLogo from '../images/spce-logo.png';
import './AppHeader.scss';
import LoginMenu from '../components/LoginMenu';
import NotificationButton from '../components/Notification/NotificationButton';

const AppHeader = ({
  className,
  children,
  hideLoginMenu,
  hideNotification,
  hideLogo,
  logoImage,
  position = 'static',
  color = 'transparent',
}) => {
  return (
    <div className={`spce-portal-header ${className}`}>
      <AppBar position={position} color={color}>
        <Toolbar>
          {!hideLogo && (
            <Link to="/">
              <img src={logoImage || DefaultLogo} alt="SPCE logo" className="logo" />
            </Link>
          )}
          {children && <div className="content">{children}</div>}
          <div className="content-right">
            {!hideNotification && <NotificationButton />}
            {!hideLoginMenu && <LoginMenu />}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

AppHeader.propTypes = {
  className: PropTypes.string,
  position: PropTypes.string,
  color: PropTypes.string,
  logoImage: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.element]),
  hideLogo: PropTypes.bool,
  hideLoginMenu: PropTypes.bool,
  hideNotification: PropTypes.bool,
};

export default AppHeader;
