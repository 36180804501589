import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n';
import './DownloadResourceView.scss';
import ButtonEnhanced from '../components/ButtonEnhanced';
import Loading from '../components/Loading';

const ResourceInfoView = (props) => {
  const { isLoading, name, onClose, fileSize } = props;

  function handleOnClick() {
    if (onClose) onClose();
  }

  function renderBody() {
    return (
      <div className="text-content">
        <span className="text-content-message">
          {i18n.t('It is not possible to view this file type in the browser.')}
        </span>
        <div className="info">
          <span className="name text-truncate">{name}</span>
          {fileSize && (
            <>
              <div className="dot">
                <span></span>
              </div>
              <span className="size">{fileSize}</span>
            </>
          )}
        </div>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="resource-info-view-container">
      <div className="content">
        <div className="icon">
          <span className="icon-no-preview">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
        </div>
        {renderBody()}
      </div>
      <div className="buttons">
        <ButtonEnhanced
          disabled={isLoading}
          onClick={handleOnClick}
          className="large-button button"
          size="large"
          fullWidth
          color="secondary"
          // isProcessing={isProcessing}
        >
          {i18n.t('ok')}
        </ButtonEnhanced>
      </div>
    </div>
  );
};

ResourceInfoView.propTypes = {
  onClose: PropTypes.func,
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  fileSize: PropTypes.string,
};

export default ResourceInfoView;
