import React, { useCallback, useImperativeHandle, useRef } from 'react';
import memoizeOne from 'memoize-one';
import NoDaTaBox from '../../../components/NoDataBox';
import { IResource, RowData } from '../../../globalResources/context/Interfaces';
import {
  GLOBAL_PROVIDER_TYPE,
  GLOBAL_RESOURCES_INFO,
  GLOBAL_RESOURCE_TYPE,
} from '../../../globalResources/utils';
import i18n from '../../../i18n';
import useGoogleDriveInfiniteQuery from '../common/useGoogleDriveInfiniteQuery';
import { formatGoogleDriveResources, mapGoogleDriveItemToResource } from '../common/utils';
import { searchGoogleDriveItem } from '../googleDriveService';
import './style.scss';
import ExternalStorageNoConnectionBox from '../../components/ExternalStorageNoConnectionBox';
import { SERVICE_PROVIDER } from '../../integrationConstants';
import { GRSelectType } from '../../../globalResources/GlobalResourcesPicker';
import { useIntegrationContext } from '../../IntegrationContextProvider';
import GoogleDriveConnectButton from '../GoogleDriveConnectButton';
import { getAcceptResourceTypes } from '../../../commons/ResourceUtils';
import GlobalResourcesLoading from '../../../globalResources/GlobalResourcesLoading';
import ResourcesDisplayView from '../../../globalResources/ResourcesDisplayView/ResourcesDisplayView';
import { useFeatureFlagsContext } from '../../../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../../../app/appConstants';
import { useResourcesViewContext } from '../../../components/ResourcesView/ResourcesViewContextProvider';
import ResourcesViewControls from '../../../components/ResourcesView/ResourcesViewControls';
import { useGlobalResourcesContext } from '../../../globalResources/context/GlobalResourcesContextProvider';
import { useBreadcrumbContext } from '../../../globalResources/context/BreadcrumbsContextProvider';

const pageSize = 20;

type GoogleDriveTableProps = {
  isGlobalResourcesEnabled: boolean;
  isGoogleDriveEnabled: boolean;
  previewItemInPopup: (resource: RowData | null) => void;
  multiple?: boolean;
  selectType?: (typeof GRSelectType)[keyof typeof GRSelectType];
  otherTypeDisabled?: boolean;
  accept?: string;
  isPortal: boolean;
};

const getFormatGlobalResourcesData = memoizeOne((accept, items) => {
  let formattedData = null;
  if (!items?.length) {
    return [];
  }
  if (accept) {
    const { acceptResourceTypes } = getAcceptResourceTypes(accept);
    formattedData = formatGoogleDriveResources(items, acceptResourceTypes);
  } else {
    formattedData = formatGoogleDriveResources(items, null);
  }
  return formattedData;
});

function GoogleDriveTable(props: GoogleDriveTableProps, ref: React.Ref<unknown> | undefined) {
  const {
    isGlobalResourcesEnabled,
    isGoogleDriveEnabled,
    previewItemInPopup,
    multiple = false,
    selectType,
    otherTypeDisabled = false,
    accept,
    isPortal,
  } = props;
  console.log('isGoogleDriveEnabled: ', isGoogleDriveEnabled);

  const { resources, setResources } = useGlobalResourcesContext();

  const {
    currentBreadcrumbItem: selectedFolder,
    goToBreadcrumbItem,
    goToRootItem,
  } = useBreadcrumbContext();

  const [isSearching, setIsSearching] = React.useState(false);

  const { order, orderBy, searchText, setSearchText } = useResourcesViewContext();

  const { getProviderData } = useIntegrationContext();

  const providerData = getProviderData(SERVICE_PROVIDER.GOOGLE_DRIVE);
  const connectButtonRef = useRef<{ handleOnClickConnect: () => void }>();

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledMaterialSlider] = checkEnabledFeatures([FeatureFlagsType.MATERIAL_SLIDER]);

  useImperativeHandle(ref, () => ({
    navigate() {},
  }));

  const { googleDriveInfiniteQuery } = useGoogleDriveInfiniteQuery(
    selectedFolder,
    selectedFolder?.driveId,
    true,
    pageSize,
    order,
    orderBy,
    isGlobalResourcesEnabled && isGoogleDriveEnabled,
    () => {
      if (connectButtonRef.current?.handleOnClickConnect) {
        connectButtonRef.current.handleOnClickConnect();
      }
    }
  );

  const {
    data,
    isLoading: isLoadingResources,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = googleDriveInfiniteQuery;

  React.useEffect(() => {
    if (data) {
      console.log('### googleDriveInfiniteQuery', data);
      const resourcesData = [...data.pages].reduce(
        (m, { children }) => m.concat(children?.items || []),
        [] as IResource[]
      );

      let formattedData = null;
      if (accept) {
        const { acceptResourceTypes } = getAcceptResourceTypes(accept);
        formattedData = formatGoogleDriveResources(resourcesData, acceptResourceTypes);
      } else {
        formattedData = formatGoogleDriveResources(resourcesData, null);
      }
      setResources(formattedData);

      if (isEnabledMaterialSlider) {
        const viewableMaterials = formattedData
          ?.filter((m) => m.type !== GLOBAL_RESOURCE_TYPE.FOLDER)
          ?.map((m) => mapGoogleDriveItemToResource(m));
        sessionStorage.setItem(
          'selectedFolderMaterials',
          JSON.stringify({
            folderId: -1,
            materials: viewableMaterials,
          })
        );
      }
    }
  }, [data, accept, isEnabledMaterialSlider, setResources]);

  // useUnmountEffect(() => {
  //   queryCache.removeQueries([CacheKeys.getGoogleDriveResources], { exact: false });
  //   sessionStorage.removeItem('gr-currentGoogleDriveFolderId');
  // });

  function handleOnLoadMore() {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }

  async function searchResources(value: string) {
    if (!data) {
      return;
    }
    const searchValue = value.toLowerCase().trim();
    goToRootItem();
    if (searchValue) {
      setSearchText(searchValue);
    } else {
      setSearchText('');
    }
    let results = [];
    if (searchValue?.length) {
      setIsSearching(true);
      const dataSearched = await searchGoogleDriveItem(searchValue);
      results = getFormatGlobalResourcesData(accept, dataSearched);
      setIsSearching(false);
    } else {
      // const resourcesData = [...data.pages].reduce(
      //   (m, { children }) => m.concat(children.items),
      //   [] as IResource[]
      // );
      const resourcesData: IResource[] = [];

      results = getFormatGlobalResourcesData(accept, resourcesData);
    }
    setResources(results);
  }

  const handleFolderSelected = useCallback(
    (item: RowData) => {
      setSearchText('');
      const folder = {
        id: item.id,
        name: item.name,
        parentId: item.parentId,
        driveId: item.driveId,
        provider: GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE,
      };
      goToBreadcrumbItem(folder);
    },
    [setSearchText, goToBreadcrumbItem]
  );

  const handleOnClickRow = useCallback(
    (rowData: RowData) => {
      if (rowData.type === GLOBAL_RESOURCE_TYPE.FOLDER) {
        handleFolderSelected(rowData);
      }
    },
    [handleFolderSelected]
  );

  const handleResourceDoubleClicked = useCallback(
    (rowData: RowData) => {
      if (rowData.type === GLOBAL_RESOURCE_TYPE.FOLDER) {
        handleFolderSelected(rowData);
      } else if (previewItemInPopup) {
        previewItemInPopup(mapGoogleDriveItemToResource(rowData));
      }
    },
    [handleFolderSelected, previewItemInPopup]
  );

  const isLoading = isLoadingResources || resources === null;

  function renderHeader() {
    const enabled = isGlobalResourcesEnabled && isGoogleDriveEnabled;
    if (!enabled || (!isLoading && resources?.length === 0 && !searchText)) {
      return null;
    }
    const info = GLOBAL_RESOURCES_INFO.GoogleDrive;
    return (
      <div className="header">
        <section className="info-section">
          <span className="text">{info.readonly}</span>
        </section>
        <div className="actions-section">
          <ResourcesViewControls
            searchPlaceholder={i18n.t('Search file name')}
            searchText={searchText}
            onSearchChange={searchResources}
          />
        </div>
      </div>
    );
  }

  function renderNotFound() {
    if (searchText) {
      return (
        <NoDaTaBox
          icon={<span className="icon-resources" />}
          title={i18n.t('No files or folders found')}
          description={i18n.t('Choose another keyword and try again.')}
        />
      );
    }

    return (
      <NoDaTaBox
        icon={<span className="icon-resources" />}
        title={i18n.t('No files or folders to display')}
      />
    );
  }

  async function handleOnConnectSuccess() {
    await refetch();
  }

  function renderConnectButton() {
    return (
      <ExternalStorageNoConnectionBox
        disabled={isGoogleDriveEnabled}
        serviceProvider={SERVICE_PROVIDER.GOOGLE_DRIVE}
        connectButton={
          <GoogleDriveConnectButton
            serviceProvider={providerData}
            ref={connectButtonRef}
            onConnectSuccess={handleOnConnectSuccess}
          />
        }
      />
    );
  }

  function renderTable() {
    if (!isGoogleDriveEnabled) {
      return <div style={{ height: '27%' }} />; // a trick to push the connect button to the center
    }

    if (isLoading || isSearching) {
      return <GlobalResourcesLoading />;
    }
    const noData = !isLoading && resources?.length === 0;
    if (noData) {
      return (
        <div className="global-resources-table global-resources-table-no-data">
          {renderNotFound()}
        </div>
      );
    }
    return (
      <ResourcesDisplayView
        key={'google-drive-table'}
        hasMore={hasNextPage}
        resources={resources as RowData[]}
        onLoadMore={handleOnLoadMore}
        isLoading={isLoading}
        onClickRow={handleOnClickRow}
        onDoubleClickRow={handleResourceDoubleClicked}
        multiple={multiple}
        selectType={selectType}
        otherTypeDisabled={otherTypeDisabled}
        disableSortingColumns={['itemsCount', 'lastModified']}
        rowCount={0}
        isPortal={isPortal}
      />
    );
  }

  if (!isGlobalResourcesEnabled) {
    return (
      <div className="global-resources-table global-resources-table-no-data">
        <NoDaTaBox
          icon={<span className="icon-resources" />}
          title={i18n.t('Enable Global Resources')}
          description={i18n.t(
            'Use global resources for your organization to simply organizing all content in one place'
          )}
        />
      </div>
    );
  }

  return (
    <div className="google-drive-table">
      {renderHeader()}
      {renderTable()}
      {renderConnectButton()}
      {/** Need to always keep this connect button, the re-connect flow use the connectButtonRef
       * Please check re-connect flow if you want to change UI above such as show or hide this button
       */}
    </div>
  );
}

// const GoogleDriveTable = (props: GoogleDriveTableProps) => {
//   return (
//     <GlobalResourceTableContextProvider>
//       <GoogleDriveTableBase {...props} />
//     </GlobalResourceTableContextProvider>
//   );
// };

export default React.forwardRef(GoogleDriveTable);
