/* eslint-disable */
import moment from 'moment';
import { filterAvailableResources } from '../ResourceWidget/utils';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import useAllSpaceResourcesQuery from '../queries/useAllSpaceResourcesQuery';
import useCmsMaterialsQuery from '../queries/useCmsMaterialsQuery';

const useAvailableSpaceResources = () => {
  const { space, reportEndDate } = useSpaceAnalyticsContext();
  const endDate = moment(reportEndDate).toDate().toISOString();
  const { data: cmsMaterials } = useCmsMaterialsQuery();
  const cmsMaterialIds = cmsMaterials
    ? cmsMaterials.map((x: { materialId: any }) => x.materialId)
    : undefined;
  const { data: allMaterials, isFetching } = useAllSpaceResourcesQuery(
    endDate,
    space?.id,
    cmsMaterialIds
  );

  if (isFetching) {
    return {
      cmsMaterials,
      availableMaterials: undefined,
      availableMaterialIds: undefined,
      isLoading: isFetching,
    };
  }

  const availableMaterials = filterAvailableResources(allMaterials, cmsMaterialIds || []);

  return {
    cmsMaterials: cmsMaterials ?? [],
    availableMaterials: availableMaterials ?? [],
    availableMaterialIds: availableMaterials?.map((x) => x.materialId) ?? [],
    isLoading: isFetching,
  };
};

export default useAvailableSpaceResources;
