/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { isString } from 'lodash';
// import i18n from '../../i18n';
import ButtonEnhanced from '../../components/ButtonEnhanced';
import { ResourceInteractionType, ResourceType } from '../../app/appConstants';
import { CMS_COMPONENT_STYLE } from '../cmsConstants';
import '../cmsStyle.scss';
import './CardPreview.scss';
import CMSDefaultImage from '../../images/cms/default-preview.jpeg';
import { checkAvailableFonts, checkAdditionalStyles } from '../commons/FontSelect/utils';
import { createResourceInteraction } from '../../commons/CommonServices';
import {
  getTextLineHeight,
  getTextPositionCssClasses,
  getHeadingFontSize,
  handleLinkNavigation,
  isSharingPageOrBlockInSpace,
} from '../cmsUtils';
import { getDisplayText } from '../../commons/utils';
import { isIntegrationResourceDeleted } from '../../integrations/integrationResourceUtils';
import ImagePlaceholder from '../../components/ImagePlaceholder';
import { useSpaceContext } from '../../spaces/SpaceContext';
import DescriptionPreview from './DescriptionPreview';
import { parseDateTimeStringFromServer } from '../../commons/DateTimeUtils';
import {
  getImageRatio,
  getPaddingByTextPositionAndImageRatio,
  isMultipleAlignment,
} from './designer/functions';
import PdfViewer from '../../components/PdfViewer';
import { getExtensionFromUrl } from '../../commons/ResourceUtils';
import TiffViewer from '../../components/TiffViewer/TiffViewer';
import { useAppContext } from '../../components/AppProvider';
import useMyFontListQuery from '../commons/FontSelect/MyFontListQuery';
// import ImagePlaceholderSrc from '../../images/image-placeholder.png';

const defaultBackgroundImage = CMSDefaultImage;

function CardPreview(props) {
  const {
    index,
    heading,
    headingLink,
    description,
    buttonLabel,
    secondaryButtonLabel,
    isMobile,
    columns,
    isRowActive,
    isColumnActive,
    descriptionLinks,
    resources,
    shouldDisplay,
    onClick,
  } = props;
  const { isTablet, isDesktop } = useAppContext();

  const textPosition = props.textPosition;
  const sizeCssClass = `column-${props.columns}`;
  const boxShadowBorderCssClass =
    props.isEdit &&
    !props.isPreviewMode &&
    props.columns === 1 &&
    (props.size === CMS_COMPONENT_STYLE.SIZE.FULLWIDTH ||
      props.size === CMS_COMPONENT_STYLE.SIZE.BANNER)
      ? 'box-shadow-border'
      : '';

  const { scrollToBlockId } = useSpaceContext();
  const { myFonts } = useMyFontListQuery(true, true);

  const [backgroundImage, setBackgroundImage] = React.useState(null); // can be image or pdf

  console.log('### CardPreview props: image', backgroundImage, props.backgroundImageUrl);
  React.useEffect(() => {
    const croppedResource = resources?.[props.backgroundImageUrlCropped];
    const originResource = resources?.[props.backgroundImageUrl];

    if (resources && (croppedResource || originResource)) {
      // console.log('### CardPreview useEffect originResource', originResource);
      // console.log('### CardPreview useEffect croppedResource', croppedResource);
      if (croppedResource) {
        let displayResource = croppedResource;
        const lastModified = parseDateTimeStringFromServer(originResource?.lastModified)?.local();
        const created = parseDateTimeStringFromServer(croppedResource.created)?.local();
        if (lastModified > created) {
          displayResource = originResource;
        }
        setBackgroundImage(displayResource);
      } else {
        setBackgroundImage(originResource);
      }
    } else {
      if (isString(props.backgroundImageUrl)) {
        setBackgroundImage({
          url: props.backgroundImageUrl,
          resourceType: ResourceType.image,
        });
      } else {
        setBackgroundImage(null);
      }
    }
  }, [resources, props.backgroundImageUrl, props.backgroundImageUrlCropped]);

  function handleResourceInteraction(linkResourceId, linkMaterialId) {
    // console.log('### 105 handleResourceInteraction:', linkResourceId, linkMaterialId);
    if (linkResourceId && linkMaterialId && !props.isPortal) {
      createResourceInteraction(linkResourceId, {
        materialId: linkMaterialId,
        interactionType: ResourceInteractionType.Click,
        data: JSON.stringify({ isCms: true }),
      });
    }
  }

  function handleNavigation(link) {
    // console.log('### 105 handleNavigation:', link);

    const paramIds = isSharingPageOrBlockInSpace(link, props.spaceId, props.urlSlug);
    if (paramIds) {
      scrollToBlockId(paramIds, props.isEnabledCMSPages);
    } else {
      handleLinkNavigation(link, props.spaceId, props.urlSlug, props.isPortal);
    }
  }

  function handleLinkClick(fieldName) {
    const linkResourceId = props[`${fieldName}ResourceId`];
    const linkMaterialId = props[`${fieldName}MaterialId`];
    handleResourceInteraction(linkResourceId, linkMaterialId);

    const link = props[`${fieldName}`];
    handleNavigation(link);
  }

  const handleOnClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (onClick) {
        onClick(index);
      }
    },
    [index, onClick]
  );

  const getFont = (fontFamily) => {
    return checkAvailableFonts(fontFamily, myFonts);
  };

  function renderInfo() {
    const marginClass =
      textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM ? 'marginTop32' : '';
    let justifyContentClass;
    let maxWidthClass;
    let paddingLRClass;
    let descriptionPaddingLRClass;

    let textColor = 'primaryColor';
    let positionAbsoluteClass;
    let textPositionClass;
    const textSize = props.textSize || 'large';
    let textAlign = props.textAlign || 'center';

    if (textAlign === 'center') {
      descriptionPaddingLRClass = 'paddingLR32';
    }
    // console.log('props.size: ', props.size, textPosition);

    if (
      textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.LEFT ||
      textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT
    ) {
      justifyContentClass = 'justifyContentCenter';
      maxWidthClass = isMobile ? 'marginTop32' : 'maxWidth50p';
      paddingLRClass = '';
    } else if (
      textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM ||
      textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.TOP
    ) {
      if (props.columns === 1 && props.size === CMS_COMPONENT_STYLE.SIZE.FULLWIDTH) {
        paddingLRClass = isMobile ? 'paddingLR16' : 'maxWidth1920';
      }
    } else if (
      textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE ||
      isMultipleAlignment(textPosition)
    ) {
      // Image and Text Overlay
      positionAbsoluteClass = 'positionAbsolute';
      textColor = 'secondaryColor';
      descriptionPaddingLRClass = '';
      paddingLRClass = 'paddingLR16';

      const { positionClass, textAlignClass } = getTextPositionCssClasses(textPosition, isMobile);
      textAlign = textAlignClass;
      textPositionClass =
        positionClass === CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE ? 'middleCenter' : positionClass;
    }

    let headingFontSize = props.headingFontSize;
    const headingFontSizeValue = props.headingFontSize?.replace('px', '');

    headingFontSize = getHeadingFontSize(headingFontSizeValue, isMobile);

    const headingStyle = {
      color: props.headingColor,
      fontFamily: getFont(props.headingFontFamily),
      fontSize: headingFontSize,
      lineHeight: 1.25,
      ...checkAdditionalStyles(props.headingFontFamily),
    };
    const descriptionStyle = {
      color: props.descriptionColor,
      fontFamily: getFont(props.descriptionFontFamily),
      fontSize: props.descriptionFontSize,
      lineHeight: getTextLineHeight(props.descriptionFontSize),
      ...checkAdditionalStyles(props.descriptionFontFamily),
    };
    const buttonLabelStyle = {
      color: props.buttonLabelColor,
      fontFamily: getFont(props.buttonLabelFontFamily),
      fontSize: props.buttonLabelFontSize,
      backgroundColor: props.buttonBackgroundColor,
      ...checkAdditionalStyles(props.buttonLabelFontFamily),
    };
    const secondaryButtonLabelStyle = {
      color: props.secondaryButtonLabelColor,
      fontFamily: getFont(props.secondaryButtonLabelFontFamily),
      fontSize: props.secondaryButtonLabelFontSize,
      backgroundColor: props.secondaryButtonBackgroundColor,
      ...checkAdditionalStyles(props.secondaryButtonLabelFontFamily),
    };

    const { htmlAttributes: headingAttributes } = getDisplayText(heading);
    const { displayText: displayButtonLabel } = getDisplayText(buttonLabel);
    const { displayText: secondaryDisplayButtonLabel } = getDisplayText(secondaryButtonLabel);

    return (
      <div
        className={`cms-card-preview-info-wrapper ${positionAbsoluteClass || ''} ${
          maxWidthClass || ''
        }`}
      >
        <div
          className={`cms-card-preview-info ${textAlign || ''} ${marginClass || ''} ${
            textColor || ''
          } ${justifyContentClass || ''} ${positionAbsoluteClass || ''} ${
            textPositionClass || ''
          } ${sizeCssClass || ''} ${paddingLRClass || ''}`}
        >
          {heading && !headingLink && (
            <span
              className={`cms-card-preview-title ${textSize} ${textAlign}`}
              style={headingStyle}
              {...headingAttributes}
            />
          )}
          {heading && headingLink && (
            <span
              className={`cms-card-preview-title ${textSize} ${textAlign}`}
              style={headingStyle}
            >
              <Link
                href={headingLink}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleLinkClick('headingLink');
                }}
                style={headingStyle}
                {...headingAttributes}
              />
            </span>
          )}
          {description && (
            <DescriptionPreview
              isPortal={props.isPortal}
              textAlignClass={textAlign}
              descriptionPaddingLRClass={descriptionPaddingLRClass}
              descriptionStyle={descriptionStyle}
              description={description}
              descriptionLinks={descriptionLinks}
              handleNavigation={handleNavigation}
              handleResourceInteraction={handleResourceInteraction}
              className="cms-card-preview-description"
            />
          )}
          <div className={`cms-card-preview-buttons ${textAlign}`}>
            {buttonLabel && (
              <ButtonEnhanced
                className="btn-cms"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleLinkClick('buttonLink');
                }}
                style={buttonLabelStyle}
              >
                <span dangerouslySetInnerHTML={{ __html: displayButtonLabel }} />
              </ButtonEnhanced>
            )}
            {secondaryButtonLabel && (
              <ButtonEnhanced
                className="btn-cms"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleLinkClick('secondaryButtonLink');
                }}
                style={secondaryButtonLabelStyle}
              >
                <span dangerouslySetInnerHTML={{ __html: secondaryDisplayButtonLabel }} />
              </ButtonEnhanced>
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderPdfViewer(marginClass, maxWidthClass, paddingClass, imageRatioClass) {
    return (
      <div
        className={`cms-card-preview-image-wrapper ${marginClass || ''} ${maxWidthClass || ''} ${
          paddingClass || ''
        }`}
      >
        <div className={`cms-card-preview-image ${imageRatioClass || ''}`}>
          <PdfViewer
            resourceId={props.backgroundImageUrlResourceId}
            materialId={props.backgroundImageUrlMaterialId}
            src={backgroundImage.url}
            preventEvents
            isCms
          />
        </div>
      </div>
    );
  }

  function renderImageSrc(
    marginClass,
    maxWidthClass,
    paddingClass,
    imageRatioClass,
    backgroundColor
  ) {
    return (
      <div
        className={`cms-card-preview-image-wrapper ${marginClass || ''} ${maxWidthClass || ''} ${
          paddingClass || ''
        }`}
      >
        <div className={`cms-card-preview-image ${imageRatioClass || ''}`}>
          <div
            className={`cms-card-preview-image-overlay ${boxShadowBorderCssClass}`}
            style={{ backgroundColor }}
          />
          {shouldDisplay && (
            <>
              {isIntegrationResourceDeleted(props.imageSrc) ? (
                <ImagePlaceholder />
              ) : (
                <img src={props.imageSrc} alt={heading} />
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  function renderImage() {
    const { imageRatio, imageRatioClass } = getImageRatio(
      columns,
      textPosition,
      props.imageRatio,
      isMobile,
      props.size,
      isTablet,
      isDesktop
    );

    // const imageRatio = props.imageRatio || '16:9';
    // let imageRatioClass = `ratio${imageRatio.split(':').join('')}`;

    const marginClass = textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.TOP ? 'marginTop32' : '';

    const { maxWidthClass, paddingClass } = getPaddingByTextPositionAndImageRatio(
      textPosition,
      imageRatio,
      isMobile,
      isTablet,
      isDesktop
    );

    // TODO: For OneDrive resources, use the <ImagePlaceholder /> component when a resource is deleted

    if (backgroundImage?.resourceType === ResourceType.pdf) {
      return renderPdfViewer(marginClass, maxWidthClass, paddingClass, imageRatioClass);
    }

    const backgroundColor = `rgba(0, 0, 0, ${props.opacity})`;

    if (props.imageSrc) {
      return renderImageSrc(
        marginClass,
        maxWidthClass,
        paddingClass,
        imageRatioClass,
        backgroundColor
      );
    }

    const mExtension = getExtensionFromUrl(backgroundImage?.url, backgroundImage?.extension);
    const isTiff = mExtension === 'tiff' || mExtension === 'tif';
    console.log('### 412 CardPreview: ', backgroundImage);

    return (
      <div
        className={`cms-card-preview-image-wrapper ${marginClass || ''} ${maxWidthClass || ''} ${
          paddingClass || ''
        }`}
      >
        <div className={`cms-card-preview-image ${imageRatioClass || ''}`}>
          {shouldDisplay && (
            <>
              {!backgroundImage && (
                <>
                  <div
                    className={`cms-card-preview-image-overlay ${boxShadowBorderCssClass}`}
                    style={{ backgroundColor }}
                  />
                  <img src={defaultBackgroundImage} alt={heading} />
                </>
              )}
              {backgroundImage && (
                <>
                  {isIntegrationResourceDeleted(backgroundImage.url) ? (
                    <>
                      <div
                        className={`cms-card-preview-image-overlay ${boxShadowBorderCssClass}`}
                        style={{ backgroundColor }}
                      />
                      <ImagePlaceholder />
                    </>
                  ) : (
                    <>
                      {backgroundImage.resourceType === ResourceType.image && (
                        <>
                          <div
                            className={`cms-card-preview-image-overlay ${boxShadowBorderCssClass}`}
                            style={{ backgroundColor }}
                          />
                          {!isTiff && <img src={backgroundImage.url} alt={heading} />}
                          {isTiff && (
                            <TiffViewer
                              src={backgroundImage.url}
                              alt={heading}
                              className="cms-card-image"
                              renderAsImgTag
                              resourceId={backgroundImage.resourceId}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  function renderCardPreview() {
    // if (isFetching) {
    //   return (
    //     <div className={`cms-card-preview column-direction ${sizeCssClass}`}>
    //       <Loading />
    //     </div>
    //   );
    // }

    let active;
    if (isColumnActive) {
      active = `active`;
    } else if (isRowActive) {
      active = 'allowHover';
    }

    const cssDirection = isMobile ? 'column-direction' : 'row-direction';
    const gapClass = isMobile ? '' : 'gap32';
    const mobileModeStyle = isMobile ? 'mobile-mode' : '';

    // console.log('props.activeColumn', props.activeColumn);
    if (textPosition) {
      if (textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.TOP) {
        // Image and Text
        return (
          <div
            className={`cms-card-preview ${mobileModeStyle} column-direction ${sizeCssClass} ${active}`}
            onClick={handleOnClick}
          >
            {renderInfo()}
            {renderImage()}
          </div>
        );
      } else if (textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM) {
        // Image and Text
        return (
          <div
            className={`cms-card-preview ${mobileModeStyle} column-direction ${sizeCssClass} ${active}`}
            onClick={handleOnClick}
          >
            {renderImage()}
            {renderInfo()}
          </div>
        );
      } else if (
        textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE ||
        isMultipleAlignment(textPosition)
      ) {
        // Image and Text Overlay
        return (
          <div
            className={`cms-card-preview ${mobileModeStyle} column-direction alignItemsCenter justifyContentCenter positionRelative ${sizeCssClass} ${active}`}
            onClick={handleOnClick}
          >
            {renderImage()}
            {renderInfo()}
          </div>
        );
      } else if (textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.LEFT) {
        // Hero
        const cssDirectionOrder = isMobile ? 'column-direction-reverse' : '';
        return (
          <div
            className={`cms-card-preview ${mobileModeStyle} ${cssDirection} ${sizeCssClass} ${cssDirectionOrder} ${gapClass}`}
          >
            {renderInfo()}
            {renderImage()}
          </div>
        );
      } else if (textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT) {
        // Hero
        return (
          <div
            className={`cms-card-preview ${mobileModeStyle} ${cssDirection} ${sizeCssClass} ${gapClass}`}
          >
            {renderImage()}
            {renderInfo()}
          </div>
        );
      }
    }
    // Text only
    return (
      <div
        className={`cms-card-preview ${mobileModeStyle} column-direction ${sizeCssClass} ${active}`}
        onClick={handleOnClick}
      >
        {renderInfo()}
      </div>
    );
  }

  return renderCardPreview();
}

CardPreview.propTypes = {
  index: PropTypes.number,
  heading: PropTypes.string,
  headingLink: PropTypes.string,
  descriptionLinks: PropTypes.arrayOf(Object),
  description: PropTypes.string,
  textAlign: PropTypes.string,
  textSize: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonLink: PropTypes.string,
  backgroundImageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundImageUrlCropped: PropTypes.number,
  backgroundImageUrlResourceId: PropTypes.number,
  backgroundImageUrlMaterialId: PropTypes.number,
  imageSrc: PropTypes.string,
  isMobile: PropTypes.bool,
  textPosition: function (props, propName) {
    // textPosition only be used/valid if backgroundImageUrl is provided.
    if (!props.backgroundImageUrl && !!props[propName]) {
      return new Error(`Please provide a backgroundImageUrl property!`);
    }
    return null;
  },
  imageRatio: function (props, propName) {
    // imageRatio only be used/valid if backgroundImageUrl is provided.
    if (!props.backgroundImageUrl && !!props[propName]) {
      return new Error(`Please provide a backgroundImageUrl property!`);
    }
    return null;
  },
  opacity: PropTypes.number,
  columns: PropTypes.number,
  size: PropTypes.string,
  isPortal: PropTypes.bool,
  headingColor: PropTypes.string,
  headingFontFamily: PropTypes.string,
  headingFontSize: PropTypes.string,
  descriptionColor: PropTypes.string,
  descriptionFontFamily: PropTypes.string,
  descriptionFontSize: PropTypes.string,
  buttonLabelColor: PropTypes.string,
  buttonLabelFontFamily: PropTypes.string,
  buttonLabelFontSize: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  secondaryButtonLabelColor: PropTypes.string,
  secondaryButtonLabelFontFamily: PropTypes.string,
  secondaryButtonLabelFontSize: PropTypes.string,
  secondaryButtonBackgroundColor: PropTypes.string,
  spaceId: PropTypes.string,
  urlSlug: PropTypes.string,
  onClick: PropTypes.func,
  isRowActive: PropTypes.bool,
  isColumnActive: PropTypes.bool,
  resources: PropTypes.instanceOf(Object),
  shouldDisplay: PropTypes.bool,
  isEdit: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  isEnabledCMSPages: PropTypes.bool,
};

CardPreview.defaultProps = {
  isEdit: false,
  isPreviewMode: false,
};

export default CardPreview;
