import RuntimeApi from '../commons/RuntimeApi';
import {
  getCoreApi,
  getIntegrationApi,
  getResourceApi,
  getResourceRuntimeApi,
} from '../commons/utils';
import Api from '../commons/api';
import { GLOBAL_PROVIDER_TYPE, getMolnlyckeBffApi } from './utils';

export async function getRuntimeGlobalResources(
  directoryId,
  limit = 10,
  offset = 0,
  keywords = '',
  sortBy = ''
) {
  let url = `${getResourceRuntimeApi()}/api/runtime/global-resources/directories?includesChildren=true&limit=${limit}&offset=${offset}`;
  if (directoryId) {
    url = `${getResourceRuntimeApi()}/api/runtime/global-resources/directories/${directoryId}?includesChildren=true&limit=${limit}&offset=${offset}`;
  }
  if (keywords) {
    url += `&keywords=${keywords}`;
  }
  if (sortBy) {
    url += `&order=${sortBy}`;
  }
  return RuntimeApi.get(url);
}

export async function getVeevaToken(productName, isPortal) {
  if (isPortal) {
    return Api.get(`${getMolnlyckeBffApi()}/token?productName=${productName}`);
  }

  return RuntimeApi.get(`${getMolnlyckeBffApi()}/runtime/token?productName=${productName}`);
}

export async function checkVeevaToken(token, isPortal) {
  if (isPortal) {
    return Api.get(`${getMolnlyckeBffApi()}/healthcheck?token=${token}`);
  }
  return RuntimeApi.get(`${getMolnlyckeBffApi()}/runtime/healthcheck?token=${token}`);
}

export async function createGlobalResourcesDirectory(data) {
  return Api.post(`${getResourceApi()}/api/global-resources/directories`, data);
}

export async function getGlobalResourcesMngt(
  directoryId,
  limit = 10,
  offset = 0,
  keywords = '',
  sortBy = ''
) {
  let url = `${getResourceApi()}/api/global-resources/directories?includesChildren=true&limit=${limit}&offset=${offset}`;
  if (directoryId) {
    url = `${getResourceApi()}/api/global-resources/directories/${directoryId}?includesChildren=true&limit=${limit}&offset=${offset}`;
  }
  if (keywords) {
    url += `&keywords=${keywords}`;
  }
  if (sortBy) {
    url += `&order=${sortBy}`;
  }
  return Api.get(url);
}

export function toggleOrganizationGlobalResources(organizationId, data) {
  return Api.put(`${getCoreApi()}/organizations/${organizationId}/features`, data);
}

export function addResourcesToFolder(directoryId, data) {
  return Api.post(`${getResourceApi()}/api/global-resources/directories/${directoryId}/add`, data);
}

export function deleteDirectory(directoryId) {
  return Api.delete(`${getResourceApi()}/api/global-resources/directories/${directoryId}`);
}

export function updateDirectory(directoryId, data) {
  return Api.put(`${getResourceApi()}/api/global-resources/directories/${directoryId}`, data);
}

export function downloadGlobalDirectory(directoryId) {
  return Api.get(`${getResourceApi()}/api/global-resources/directories/${directoryId}/download`);
}

export function searchGlobalResources(keyword) {
  return Api.get(`${getResourceApi()}/api/global-resources/search?keywords=${keyword}`);
}

export function downloadBulk(directoryIds, resourceIds) {
  let parameterString = '?';
  if (directoryIds.length > 0) {
    parameterString += `&directoryIds=${directoryIds.join(',')}`;
  }
  if (resourceIds.length > 0) {
    parameterString += `&resourceIds=${resourceIds.join(',')}`;
  }

  return Api.get(`${getResourceApi()}/api/global-resources/bulk-download${parameterString}`);
}

export function moveBulk(directoryIds, resourceIds, toFolderId) {
  return Api.post(`${getResourceApi()}/api/global-resources/bulk-move`, {
    directoryIds,
    resourceIds,
    toFolderId,
  });
}

export function deleteBulk(directoryIds, resourceIds) {
  return Api.delete(`${getResourceApi()}/api/global-resources/bulk-delete`, {
    directoryIds,
    resourceIds,
  });
}

export function enableBrandingFile(directoryId, resourceId) {
  return Api.put(
    `${getResourceApi()}/api/global-resources/directories/${directoryId}/resources/${resourceId}/enable-branding`,
    {}
  );
}

export async function fetchPathsDirectory(type, folderId, driveId = '') {
  let paths;
  try {
    if (type === GLOBAL_PROVIDER_TYPE.SPCE) {
      const resp = await Api.get(
        `${getResourceApi()}/api/global-resources/directories/${folderId}/navigations`
      );
      paths = resp.items;
    } else if (type === GLOBAL_PROVIDER_TYPE.ONE_DRIVE) {
      const resp = await Api.get(
        `${getIntegrationApi()}/api/onedrive/${driveId}/items/${folderId}/navigations`
      );
      return resp.items;
    } else if (type === GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE) {
      const resp = await Api.get(
        `${getIntegrationApi()}/api/google-drive/${folderId}/navigations${driveId ? `?driveId=${driveId}` : ''}`
      );
      paths = resp.items;
    }
  } catch (error) {
    console.error(error);
    paths = [];
  }
  return paths;
}

export async function fetchStatsResource(folderId, id) {
  return Api.get(
    `${getResourceApi()}/api/global-resources/directories/${folderId}/resources/${id}/stats`
  );
}
