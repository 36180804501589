import React from 'react';
import PropTypes from 'prop-types';
import RefreshIcon from '@mui/icons-material/Refresh';
import LightTooltip from './LightTooltip';
import './RefreshExternalResourceButton.scss';

const RefreshExternalResourceButton = (props) => {
  const { onClick, className } = props;

  return (
    <div className={`refresh-btn-container ${className}`}>
      <LightTooltip title={'Refresh'} placement="top">
        <RefreshIcon onClick={onClick} className="refresh-icon" />
      </LightTooltip>
    </div>
  );
};

RefreshExternalResourceButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default RefreshExternalResourceButton;
