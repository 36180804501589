import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { IconButton, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './SettingsMenu.scss';

import i18n from '../i18n';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../app/appConstants';
import currentOrganization from '../commons/CurrentOrganization';
import { useSettingsContext } from './SettingsProvider';

function SettingsMenu(props) {
  const { pathname } = props;
  const { handleDrawerToggle, isMobile } = useSettingsContext();
  const navigate = useNavigate();

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [enabledTags, isEnabledAssessments] = checkEnabledFeatures([
    FeatureFlagsType.TAGS,
    FeatureFlagsType.ASSESSMENTS,
  ]);

  const isPartnerRole = currentOrganization.isPartner();
  function isActiveLink(type) {
    const list = ['profile'];
    return !list.includes(type);
  }

  let sideNavMenus = [
    {
      name: 'Profile',
      path: '/profile/settings',
      plan: '',
      index: 0,
      disabled: isActiveLink('profile'),
      isShow: true,
      icon: 'icon-users',
      category: 'For you',
      dataId: 'profile-user-link',
    },
    {
      name: 'Notifications',
      path: '/profile/settings/notifications',
      plan: '',
      index: 1,
      disabled: false,
      isShow: true,
      icon: 'icon-notify',
      divider: !isPartnerRole,
      dataId: 'notification-setting-link',
    },
    {
      name: 'User Management',
      path: '/profile/settings/user-management',
      plan: '',
      index: 4,
      disabled: false,
      isLocked: currentOrganization.isFreemium(),
      isShow: currentOrganization.isAdmin(),
      icon: 'icon-users',
      category: 'For the organization',
      dataId: 'user-management-setting-link',
    },
    {
      name: 'Space Settings',
      path: '/profile/settings/design',
      plan: '',
      index: 6,
      disabled: false,
      isLocked: currentOrganization.isFreemium(),
      isShow: currentOrganization.isAdmin(),
      icon: 'icon-design',
      dataId: 'space-design-setting-link',
    },
    {
      name: 'Global Resources',
      path: '/profile/settings/global-resources',
      plan: '',
      index: 7,
      disabled: false,
      isLocked: currentOrganization.isFreemium(),
      isShow: currentOrganization.isAdmin(),
      icon: 'icon-folder-arrow-up',
      dataId: 'global-resource-setting-link',
    },
    {
      name: 'Tags',
      path: '/profile/settings/tags',
      plan: '',
      index: 8,
      disabled: false,
      isLocked: !enabledTags,
      isShow: enabledTags && currentOrganization.isAdmin(),
      icon: 'icon-tag',
      dataId: 'tags-setting-link',
    },
    {
      name: 'Assessments',
      path: '/profile/settings/assessments',
      plan: '',
      index: 9,
      disabled: !isEnabledAssessments,
      isShow: currentOrganization.isAdmin() && isEnabledAssessments,
      icon: 'icon-assessments',
      dataId: 'assessment-link',
    },
  ];

  sideNavMenus = sideNavMenus.filter((item) => item.isShow);

  function handleNavBtnClick(item) {
    navigate(item.path);
  }

  return (
    <div className="profile-left">
      <div className="side-nav-header">
        <Typography className="title">{i18n.t('Settings')}</Typography>
        {isMobile && (
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
      {/* menus */}
      <div className="user-menus user-limit-content styled-scrollbar">
        <List className="list-links">
          {sideNavMenus.map((item, index) => (
            <div key={item?.index}>
              {item.category && <Typography className="category-title">{item.category}</Typography>}
              <div
                key={index}
                className={`nav-link
                  ${item.disabled ? 'disabled-link' : ''}
                  ${pathname === item.path ? 'active-nav-link' : ''}`}
                // activeclassname="active-nav-link"
                // exact="true"
                data-id={item.dataId}
                onClick={() => handleNavBtnClick(item)}
              >
                <ListItem className="user-menu" button>
                  {item.icon && (
                    <ListItemIcon>
                      <span className={item.icon} />
                    </ListItemIcon>
                  )}
                  <div className="user-menu-items">{item.name}</div>
                </ListItem>
              </div>
              {item.divider && <div className="divider-class" />}
            </div>
          ))}
        </List>
      </div>
    </div>
  );
}

SettingsMenu.propTypes = {
  pathname: PropTypes.string,
};

export default SettingsMenu;
