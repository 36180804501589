import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createSpaceUserSession } from './spaceAccessServices';
import localUserInfo from '../commons/LocalUserInfo';
import useSessionStorage from '../commons/useSessionStorage';
import spaceUser from './spaceUser';

const CreateSpaceUserSessionHandler = (props) => {
  const { spaceId } = props;

  const [spaceUserSessionId, setSpaceUserSessionId] = useSessionStorage('spaceUserSessionId', '');
  // console.log('### 1412 CreateSpaceUserSessionHandler:', spaceId, spaceUserSessionId);

  useEffect(() => {
    async function process() {
      if (!spaceId || !!spaceUserSessionId) return;
      const localInfo = localUserInfo.getUserInfo();
      const spaceAccessSession = spaceUser.getSpaceAccessSession();
      // console.log('### 1412 localInfo:', localInfo, spaceAccessSession);
      const email = localInfo?.email || spaceAccessSession?.email;
      const firstName = localInfo?.firstName || spaceAccessSession?.firstName;
      const lastName = localInfo?.lastName || spaceAccessSession?.lastName;
      if (email) {
        const data = { email, firstName, lastName };
        const newSessionId = await createSpaceUserSession(spaceId, data);
        if (newSessionId) {
          setSpaceUserSessionId(newSessionId);
        }
      }
    }
    process();
  }, [spaceId, spaceUserSessionId, setSpaceUserSessionId]);

  return null;
};

CreateSpaceUserSessionHandler.propTypes = {
  spaceId: PropTypes.string,
};

export default CreateSpaceUserSessionHandler;
