import { get, getFontId } from './utils';

const LIST_BASE_URL = 'https://www.googleapis.com/webfonts/v1/webfonts';
const API_KEY = process.env.REACT_APP_GOOGLE_FONT_API_KEY;

/**
 * Fetch the list of all available fonts from the Google Fonts API
 */
export default async function getGoogleFonts() {
  const url = new URL(LIST_BASE_URL);

  url.searchParams.append('sort', 'popularity');
  url.searchParams.append('key', API_KEY);

  const response = await get(url.href);

  const json = JSON.parse(response);

  let originalFonts = json.items;

  // Google font
  originalFonts = originalFonts.map((fontOriginal) => {
    const { family, subsets, ...others } = fontOriginal;
    return {
      ...others,
      family,
      id: getFontId(family),
      scripts: subsets,
      name: family,
    };
  });
  return originalFonts;
}
