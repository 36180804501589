import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import ListItems from './ListItems';
import './Notifications.scss';

import { fetchNotifications } from '../../settings/notifications/service';
import Loading from '../Loading';

const NotificationUpdates = (props) => {
  const { onClickNotification, onClickReadAll, currentUserInfo } = props;
  const [notifications, setNotifications] = useState(null);

  const fetchData = ({ pageParam = 1 }) => {
    return fetchNotifications(pageParam);
  };

  const getNextPageParam = (lastPage) => {
    if (lastPage.hasMore) {
      return lastPage.currentPage + 1;
    }
    return undefined;
  };

  const notificationsQuery = useInfiniteQuery({
    queryKey: [CacheKeys.fetchUserNotifications],
    queryFn: fetchData,
    ...{
      getNextPageParam,
      retry: 2,
      refetchOnMount: 'always',
    },
  });

  // useUnmountEffect(() => {
  //   // clearCache([CacheKeys.fetchUserNotifications]);
  //   queryCache.removeQueries({ queryKey: [CacheKeys.fetchUserNotifications], exact: true });
  // });

  useEffect(() => {
    if (notificationsQuery?.data) {
      const { pages } = notificationsQuery.data || [];
      console.log('### pages', pages);
      const listSpace = pages.reduce((m, page) => {
        const { items } = page;
        if (!items) {
          console.error('items is undefined', { pages });
          return m;
        }
        return m.concat(items);
      }, []);

      setNotifications(listSpace);
    }
  }, [notificationsQuery?.data]);

  if (notifications === null || notificationsQuery.isLoading) {
    return (
      <div className="loading-box">
        <Loading />
      </div>
    );
  }

  async function handleOnClickItem(item) {
    await onClickNotification(item, CacheKeys.fetchUserNotifications, 'newUpdates');
  }

  function handleOnClickReadAll() {
    onClickReadAll(CacheKeys.fetchUserNotifications, 'newUpdates');
  }

  return (
    <ListItems
      type="notifications"
      list={notifications}
      onClickNotification={handleOnClickItem}
      onClickReadAll={handleOnClickReadAll}
      currentUserInfo={currentUserInfo}
      notificationsQuery={notificationsQuery}
    />
  );
};

NotificationUpdates.propTypes = {
  onClickNotification: PropTypes.func,
  onClickReadAll: PropTypes.func,
  currentUserInfo: PropTypes.instanceOf(Object),
};

export default NotificationUpdates;
