import React from 'react';
import { useQuery } from '@tanstack/react-query';

import PropTypes from 'prop-types';
import { getUrlParameter } from '../commons/utils';
import { fetchShareSpaceCode } from '../homepage/spaceService';
import spaceUser from '../spaces/spaceUser';
import Loading from './Loading';
import { CacheKeys } from '../app/queryCache';

export const useShareSpaceCode = () => {
  const spaceId = spaceUser.getSpaceId();
  return useQuery({
    queryKey: [CacheKeys.shareSpaceCode, spaceId],
    queryFn: async () => {
      const shareCode = getUrlParameter('c');
      const newShareCode = await fetchShareSpaceCode(spaceId, shareCode);
      spaceUser.setShareSpaceCode(newShareCode);
      return newShareCode;
    },
    config: {
      enabled: !!spaceId,
    },
  });
};

const CopyResourceShareLink = (props) => {
  const { isLoading } = useShareSpaceCode();
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        cursor: `${isLoading ? 'not-allowed' : 'inherit'}`,
      }}
    >
      {props.children}
      {isLoading && <Loading fontSize={10} style={{ margin: 'right' }} />}
    </div>
  );
};

CopyResourceShareLink.propTypes = {
  children: PropTypes.node,
};

export default CopyResourceShareLink;
