import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { Column } from '@material-table/core';
import { ISpaceUser } from '../../types/User';
import Loading from '../../components/Loading';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import Table from '../components/Table';
import useSpaceResourcesByUserQuery, {
  UserInteractionResource,
} from '../queries/useSpaceResourceByUserQuery';
import LinearProgressBar from '../components/LinearProgress';
import { getPercent, getStringTime } from '../common';
import {
  MaterialBasicInfo,
  tagViewTimeSupportedList,
  typeViewTimeNotSupportedList,
} from '../types/resources';
import { ResourceType, PrivacySpace } from '../../app/appConstants';
import LightTooltip from '../../components/LightTooltip';
import ResourceItemByType from '../components/ResourceItemByType';
import { sortSpaceAnalyticMetrics } from '../components/Table/utils';
import useAvailableSpaceResources from '../hooks/useAvailableSpaceResources';

type ResourcesByUserProps = {
  userInfo: ISpaceUser;
};

type ResourceByUserInfo = UserInteractionResource & MaterialBasicInfo;

function ResourcesByUser(props: ResourcesByUserProps) {
  const { userInfo } = props;
  const { space, reportEndDate } = useSpaceAnalyticsContext();
  const startDate = moment(space?.created).toDate().toISOString();
  const endDate = moment(reportEndDate).toDate().toISOString();
  const spaceId = space?.id;
  const { cmsMaterials, availableMaterials } = useAvailableSpaceResources();
  const participantFilter = availableMaterials?.filter((p) =>
    typeViewTimeNotSupportedList.find(
      ([boolValue, numValue]) => boolValue === p?.isCmsFile && numValue === p?.resourceType
    )
  );

  const notSupportedViewMaterialIds = participantFilter
    ? participantFilter.map((x) => x.materialId)
    : [];

  const { resourcesInteractions, isFetching } = useSpaceResourcesByUserQuery(
    spaceId,
    userInfo.id,
    notSupportedViewMaterialIds,
    startDate,
    endDate,
    space?.spacePrivacy === PrivacySpace.PUBLIC,
    !!spaceId && !!userInfo.id && !!startDate && !!endDate
  );

  const canTrackByPage = useCallback(
    (materialId: number) => {
      const resourceFound = availableMaterials?.find(
        (resource: MaterialBasicInfo) => resource.materialId === materialId
      );
      return resourceFound && resourceFound.resourceType === ResourceType.pdf;
    },
    [availableMaterials]
  );

  const data =
    resourcesInteractions
      ?.map((x) => {
        const resourceInfo = availableMaterials?.find((r) => r.materialId === x.materialId);
        const isTrackedPage = canTrackByPage(x.materialId);
        const cms = cmsMaterials?.find(
          (m: { materialId: unknown }) => m.materialId === x.materialId
        );
        return {
          ...x,
          ...(resourceInfo || { resourceName: '' }),
          tag: cms?.tag,
          shares: resourceInfo?.isCmsFile ? -1 : x.shares || 0,
          downloads: resourceInfo?.isCmsFile ? -1 : x.downloads || 0,
          totalPages: isTrackedPage ? x.totalPages : -1,
          isViewNotSupported: resourceInfo?.isViewNotSupported,
        };
      })
      .sort((a, b) => a.resourceName.localeCompare(b.resourceName)) || [];

  const renderResourceInfo = useCallback(
    (materialId: number, tag?: string) => {
      const resourceFound = availableMaterials?.find(
        (resource: MaterialBasicInfo) => resource.materialId === materialId
      );
      return <ResourceItemByType material={resourceFound} tag={tag} className="mw-170" />;
    },
    [availableMaterials]
  );

  const columns = useMemo<Column<ResourceByUserInfo>[]>(
    () => [
      {
        title: 'Name',
        field: 'resourceId',
        render: (rowData) => {
          return renderResourceInfo(rowData.materialId, rowData.tag);
        },
      },
      {
        title: 'Clicks',
        field: 'clicks',
        sorting: true,
        defaultSort: 'desc',
        customSort: (a, b) => sortSpaceAnalyticMetrics(a.clicks, b.clicks),
        render: (rowData: ResourceByUserInfo) => {
          const { clicks } = rowData;
          // if (isViewNotSupported || (isCmsFile && resourceType === ResourceType.pdf)) {
          //   return (
          //     <LightTooltip title={'It is not possible to view this resource in a browser.'}>
          //       <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
          //     </LightTooltip>
          //   );
          // }
          return clicks;
        },
      },
      {
        title: 'Share',
        field: 'shares',
        customSort: (a, b) => sortSpaceAnalyticMetrics(a.shares, b.shares),
        render: (rowData) => {
          if (rowData.isCmsFile) {
            return (
              <LightTooltip title={'This resource cannot be shared.'}>
                <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
              </LightTooltip>
            );
          }
          return rowData.shares;
        },
      },
      {
        title: 'Downloads',
        field: 'downloads',
        customSort: (a, b) => sortSpaceAnalyticMetrics(a.downloads, b.downloads),
        render: (rowData) => {
          if (rowData.isCmsFile || rowData.resourceType === ResourceType.vaam) {
            return (
              <LightTooltip title={'This resource cannot be downloaded.'}>
                <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
              </LightTooltip>
            );
          }
          return rowData.downloads;
        },
      },
      {
        title: 'Page read',
        align: 'right',
        field: 'totalPages',
        customSort: (a, b) => sortSpaceAnalyticMetrics(a.totalPages ?? 0, b.totalPages ?? 0),
        render: (rowData) => {
          if (canTrackByPage(rowData.materialId)) {
            return (
              <Box display="flex" alignItems="center" className="page-read-progress">
                <LinearProgressBar
                  color="#4783FF"
                  backgroundColor="#DBE1E3"
                  value={getPercent(rowData.pageRead || 0, rowData.totalPages || 0)}
                />
                <span>
                  {rowData.pageRead || 0}/{rowData.totalPages || 0}
                </span>
              </Box>
            );
          }
          return (
            <LightTooltip title="This resource cannot be tracked page by page.">
              <Typography align="right">N/A</Typography>
            </LightTooltip>
          );
        },
      },
      {
        title: 'View time',
        field: 'viewTimeInSeconds',
        align: 'right',
        cellStyle: {
          width: '20%',
        },
        // customSort: (a, b) => sortSpaceAnalyticMetrics(a.viewTimeInSeconds, b.viewTimeInSeconds),
        render: (rowData) => {
          const { viewTimeInSeconds, isViewNotSupported } = rowData;
          const cmsViewSupported = tagViewTimeSupportedList.find(
            ([boolValue, stringValue]) =>
              boolValue === rowData.isCmsFile && stringValue === rowData.tag
          );

          if (isViewNotSupported && !cmsViewSupported) {
            return (
              <LightTooltip title={'This resource cannot be measured by view time.'}>
                <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
              </LightTooltip>
            );
          } else if (viewTimeInSeconds === 0) {
            return <Typography>{'0s'}</Typography>;
          }
          return <Typography>{getStringTime(viewTimeInSeconds)}</Typography>;
        },
      },
    ],
    [canTrackByPage, renderResourceInfo]
  );

  function renderTable() {
    return <Table columns={columns} data={data} emptyState={{ icon: <FolderIcon /> }} />;
  }

  if (isFetching) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <div className="resources-by-user-container">
      {data?.length ? (
        <p className="short-description">
          {space?.spacePrivacy === PrivacySpace.PUBLIC ? 'The user' : userInfo.fullName} has
          interacted with <b>{data?.length}</b> {data?.length === 1 ? 'resource' : 'resources'}.
        </p>
      ) : (
        <p className="short-description">
          {space?.spacePrivacy === PrivacySpace.PUBLIC ? 'The user' : userInfo.fullName} has not
          interacted with any resources.
        </p>
      )}
      {renderTable()}
    </div>
  );
}

export default ResourcesByUser;
