import { Divider, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';

import React, { PureComponent } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import ResponsiveDialog from '../components/ResponsiveDialog';
import i18n from '../i18n';
import './GlobalResourcesSelectDialog.scss';
import { GRSelectType } from './GlobalResourcesPicker';
import { moveBulk } from './globalResourcesService';
import { sendNotification } from '../commons/utils';
import queryCache, { CacheKeys, clearCache } from '../app/queryCache';
import OfficialButton from '../components/OfficialButtons';
import './MoveGlobalResourcesDialog.scss';
import AppProvider from '../components/AppProvider';
import { getTheme } from '../theme';
import GlobalResourcesContextProvider from './context/GlobalResourcesContextProvider';
import AddButton from './AddButton';
import CurrentOrganization from '../commons/CurrentOrganization';
import FeatureFlagsContextProvider from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import GlobalResourcesSpceTable from './GlobalResourcesSpceTable';
import ResourcesViewContextProvider from '../components/ResourcesView/ResourcesViewContextProvider';
import { ResourceSources } from '../resources/types';
import GlobalResourcesBreadcrumbs from './GlobalResourcesBreadcrumbs';
import { BreadcrumbContextConsumer } from './context/BreadcrumbsContextProvider';
import { GLOBAL_PROVIDER_TYPE } from './utils';

let resolve;
let containerElement;
let rootEl;

class MoveGlobalResourcesDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    // Events binding
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleOnClickMove = this.handleOnClickMove.bind(this);
    this.handleOnAddedOrUpdatedResource = this.handleOnAddedOrUpdatedResource.bind(this);
    this.tabRef = React.createRef();
    this.handleClickOnBreadcrumbItem = this.handleClickOnBreadcrumbItem.bind(this);
  }

  componentWillUnmount() {
    setTimeout(() => {
      rootEl.unmount();
      containerElement = null;
    }, 0);
    resolve = null;
  }

  handleOnAddedOrUpdatedResource() {
    const selectedFolder = this.props.currentBreadcrumbItem;
    clearCache([CacheKeys.getGlobalResourcesMngt, selectedFolder.id], false);
  }

  handleOnClose(isSuccess = false) {
    this.setState({ isOpen: false }, () => {
      MoveGlobalResourcesDialog.destroy({
        isSuccess,
      });
    });
  }

  async handleOnClickMove() {
    const selectedFolder = this.props.currentBreadcrumbItem;

    const { directoryIds, resourceIds, currentFolderParentId, messagePrefix, currentFolderId } =
      this.props;
    console.log('### handleOnClickMove selectedFolder', selectedFolder, currentFolderId);
    if (this.state.isSaving) {
      return;
    }
    if (selectedFolder) {
      this.setState({ isSaving: true });
      const resp = await moveBulk(directoryIds, resourceIds, selectedFolder.id);
      if (
        resp?.directoryResult?.failedItems?.length > 0 ||
        resp?.materialResult?.failedItems?.length > 0
      ) {
        this.setState({ isSaving: false });

        sendNotification(
          i18n.t('Sorry, something went wrong. Please try again!', { type: 'error' })
        );
      } else {
        clearCache([CacheKeys.getGlobalResourcesMngt], false);
        queryCache.removeQueries({
          queryKey: [CacheKeys.getGlobalResourcesMngt, currentFolderParentId],
          exact: false,
        });
        console.log('### handleOnClickMove selectedFolder 1', selectedFolder);
        const timeout = setTimeout(() => {
          let folderName = selectedFolder.name;
          if (folderName === '_root') {
            folderName = 'Global Resources';
          }

          sendNotification(`${messagePrefix} moved to "${folderName}"`, {
            type: 'success',
          });
          this.setState({ isSaving: false });
          clearTimeout(timeout);
          this.handleOnClose(true);
        }, 1000);
      }
    }
  }

  static destroy(result = false) {
    // console.log('### destroy', containerElement, resolve);
    if (containerElement) {
      try {
        rootEl.unmount();
      } catch (e) {
        console.error(e);
      }
    }
    if (resolve) {
      resolve(result);
    }
  }

  static show({
    currentFolderId,
    currentFolderParentId,
    resourceIds,
    directoryIds,
    messagePrefix,
  }) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    const organizationId = CurrentOrganization.getOrganizationId();
    rootEl.render(
      <QueryClientProvider client={queryCache}>
        <ThemeProvider theme={getTheme()}>
          <AppProvider isPortal>
            <FeatureFlagsContextProvider organizationId={organizationId}>
              <GlobalResourcesContextProvider
                key="move-resources-dialog"
                provider={GLOBAL_PROVIDER_TYPE.SPCE}
                useStateOnly
              >
                <BreadcrumbContextConsumer>
                  {({ currentBreadcrumbItem }) => (
                    <MoveGlobalResourcesDialog
                      currentBreadcrumbItem={currentBreadcrumbItem}
                      directoryIds={directoryIds}
                      currentFolderId={currentFolderId}
                      currentFolderParentId={currentFolderParentId}
                      resourceIds={resourceIds}
                      messagePrefix={messagePrefix}
                    />
                  )}
                </BreadcrumbContextConsumer>
              </GlobalResourcesContextProvider>
            </FeatureFlagsContextProvider>
          </AppProvider>
        </ThemeProvider>
      </QueryClientProvider>
    );

    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClickOnBreadcrumbItem(breadcrumb, previousBreadcrumb) {
    console.log('### handleClickOnBreadcrumbItem breadcrumb:', breadcrumb);
    if (this.tabRef.current?.navigate) {
      this.tabRef.current.navigate(breadcrumb, previousBreadcrumb);
    }
  }

  render() {
    const { isOpen, isSaving } = this.state;
    const { directoryIds, currentFolderId, messagePrefix } = this.props;
    const selectedFolder = this.props.currentBreadcrumbItem;

    const disabledButton = !selectedFolder || isSaving || selectedFolder.id === currentFolderId;

    if (!isOpen) {
      return null;
    }

    return (
      <ResponsiveDialog
        modal
        maxWidth="lg"
        className={`white confirm-dialog global-resources-dialog move-resources-dialog`}
        showCloseIcon
        onClose={this.handleOnClose}
      >
        <div className="content-container">
          <div className="dialog-header">
            <section className="left">
              <span className="dialog-title">
                Move <span className="dialog-title-folder-name">{messagePrefix}</span> to...
              </span>
              <GlobalResourcesBreadcrumbs
                className="breadcrumbs"
                selectedTab={GLOBAL_PROVIDER_TYPE.SPCE}
                onClickItem={this.handleClickOnBreadcrumbItem}
              />
            </section>
            <section className="right">
              <AddButton
                onResourceFolderAdded={this.handleOnAddedOrUpdatedResource}
                addFileDisabled
                selectedFolder={selectedFolder}
                disabled={isSaving}
                variant="rectangle-primary"
              />
            </section>
          </div>
          <Divider className="header-divider marginBottom16" />
          <ResourcesViewContextProvider
            key="move-resources-dialog"
            isPicker
            canSelectResource={false}
            source={ResourceSources.Global}
          >
            <div className="content">
              <div className="main-content">
                <GlobalResourcesSpceTable
                  ref={this.tabRef}
                  isGlobalResourcesEnabled
                  isPortal
                  selectType={GRSelectType.Folder}
                  disabledFolderIds={directoryIds}
                  otherTypeDisabled
                  isMovingResources
                  // onClickFolder={handleOnClickFolder}
                />
              </div>
            </div>
          </ResourcesViewContextProvider>
          <Divider className="footer-divider" />
          <div className="footer-actions">
            <OfficialButton
              onClick={this.handleOnClickMove}
              disabled={disabledButton}
              variant="large-primary"
              label={isSaving ? i18n.t('Moving...') : i18n.t('Move here')}
            />
          </div>
        </div>
      </ResponsiveDialog>
    );
  }
}

MoveGlobalResourcesDialog.propTypes = {
  currentBreadcrumbItem: PropTypes.instanceOf(Object),
  currentFolderId: PropTypes.number.isRequired,
  currentFolderParentId: PropTypes.number,
  resourceIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  directoryIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  messagePrefix: PropTypes.string,
};

MoveGlobalResourcesDialog.defaultProps = {
  title: null,
  currentFolderParentId: null,
  directoryIds: [],
};

export default MoveGlobalResourcesDialog;
