import React, { useEffect, useMemo, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import _cloneDeep from 'lodash/cloneDeep';
import i18n from '../../i18n';
import {
  getPendingRequestsToSpace,
  acceptRequestAccessToSpace,
  declineRequestAccessToSpace,
  addOrUpdateSpaceUser,
} from './spaceUsersServices';
import { sendNotification } from '../../commons/utils';
import queryCache, { CacheKeys, clearCache } from '../../app/queryCache';
import Loading from '../../components/Loading';
import { useMountEffect } from '../../commons/CustomHooks';
import OfficialButton from '../../components/OfficialButtons';
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import { getFilteredItems, getSortParticipants } from './functions';
import ParticipantInfoItem from './ParticipantInfoItem';
import FilterBox from '../../components/FilterBox';
import { updateNotificationRead } from '../../settings/notifications/service';
import NoDaTaBox from '../../components/NoDataBox';

const headCells = [
  { id: 'fullName', disablePadding: false, label: 'Name', width: '70%' },
  {
    id: null,
    align: 'center',
    disablePadding: false,
    label: 'Action',
    disableSorting: true,
    width: '30%',
  },
];

const ParticipantRow = (props) => {
  const { participant } = props;

  const [processing, setProcessing] = useState(false);
  const [processingAccept, setProcessingAccept] = useState(false);

  const handleClickOnDeny = async () => {
    setProcessing(true);
    await props.onClickOnDeny(participant.id);
    setProcessing(false);
  };

  const handleAcceptParticipant = async () => {
    setProcessingAccept(true);

    await props.onClickAccept(participant);
    setProcessingAccept(false);
  };
  return (
    <TableRow hover tabIndex={-1} key={participant.id}>
      <TableCell>
        <ParticipantInfoItem participant={participant} />
      </TableCell>
      <TableCell align="center" style={{ paddingLeft: 0 }}>
        <div className="action-buttons">
          <OfficialButton
            onClick={handleClickOnDeny}
            disabled={props.disabledActions || processing}
            label={processing ? i18n.t('processing') : i18n.t('Deny')}
            variant="small-rectangle-dark-grey"
          />
          <OfficialButton
            onClick={handleAcceptParticipant}
            disabled={props.disabledActions || processingAccept}
            label={processingAccept ? i18n.t('processing') : i18n.t('Accept')}
            variant="small-rectangle-green"
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

const RequestAccessList = (props) => {
  const { spaceId, canSearch, newNotifications, setNotifications } = props;
  const [participants, setParticipants] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [sortData, setSortData] = useState({ order: 'desc', orderBy: 'fullName' });
  const items = getFilteredItems(participants, filterText);
  const dataSorted = useMemo(
    () => getSortParticipants(items, sortData.order, sortData.orderBy),
    [sortData, items]
  );

  const getPendingRequestsToSpaceQuery = useQuery({
    queryKey: [CacheKeys.fetchRequestsAccess, spaceId],
    queryFn: async () => {
      return getPendingRequestsToSpace(spaceId);
    },

    retry: 1,
    retryDelay: () => 5000,
    refetchOnMount: 'always',
  });

  async function handleUpdateRequestAccessBadges() {
    if (newNotifications?.Participants?.details?.requestSpaceAccessIds?.length) {
      let ids = newNotifications?.Participants?.details?.requestSpaceAccessIds;
      ids = ids.map((id) => id.toString());
      const data = {
        readItems: {
          requestSpaceAccessIds: ids,
        },
        context: 'Participants',
      };
      const result = await updateNotificationRead(spaceId, data);
      if (result) {
        const newNotify = _cloneDeep(newNotifications);
        if (newNotify.Participants?.details?.requestSpaceAccessIds?.length) {
          newNotify.Participants.details.requestSpaceAccessIds = [];
          setNotifications(newNotify);
        }
      }
    }
  }

  useMountEffect(() => {
    handleUpdateRequestAccessBadges();
  });

  useEffect(() => {
    if (getPendingRequestsToSpaceQuery.data) {
      const mappingData = getPendingRequestsToSpaceQuery.data.items.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
      }));
      setParticipants(mappingData);
    }
  }, [getPendingRequestsToSpaceQuery?.data]);

  const handleChangeQuery = (id) => {
    queryCache.setQueryData([CacheKeys.fetchRequestsAccess, spaceId], (oldData) => {
      if (!oldData) {
        return null;
      }
      const newData = _cloneDeep(oldData);
      if (newData.items) {
        newData.items = newData.items.filter((item) => item.id !== id);
      }

      return newData;
    });
    clearCache([CacheKeys.fetchRequestAccessById, spaceId, id]);
  };

  const handleClickOnDeny = async (id) => {
    try {
      await declineRequestAccessToSpace(spaceId, id);
      handleChangeQuery(id);
    } catch (error) {
      sendNotification(error.message, { type: 'error' });
    }
  };

  const handleOnClickAccept = async (participant) => {
    const payload = {
      firstName: participant.firstName,
      lastName: participant.lastName,
      email: participant.email,
      phone: '',
      role: 1,
      requestId: participant.id,
    };
    try {
      await acceptRequestAccessToSpace(spaceId, participant.id);
      const result = await addOrUpdateSpaceUser(spaceId, payload);

      if (result) {
        handleChangeQuery(participant.id);
        sendNotification(i18n.t('User will receive an email to access the space.'), {
          type: 'success',
        });
      }
    } catch (error) {
      sendNotification(error.message, { type: 'error' });
    }
  };

  function handleOnChangeSort(order, orderBy) {
    setSortData({ order, orderBy });
  }

  function handleOnChangeText(value) {
    setFilterText(value);
  }

  async function handleOnClearText() {
    setFilterText('');
  }

  function renderContent() {
    if (getPendingRequestsToSpaceQuery.isLoading) {
      return (
        <div className="loading-box">
          <Loading />
        </div>
      );
    }

    if (!participants.length) {
      return <NoDaTaBox icon={<i className="icon-users" />} title={i18n.t('No requests yet')} />;
    }

    if (items.length === 0 && filterText.length > 0) {
      return (
        <div className="no-data">
          <div>{i18n.t('No users found')}</div>
          <div className="text-gray">{i18n.t('Choose another keyword and try again')}</div>
        </div>
      );
    }

    return (
      <EnhancedTable
        headCells={headCells}
        defaultSort={sortData}
        className="participants-table"
        onChangeSort={handleOnChangeSort}
      >
        {dataSorted.map((participant) => {
          return (
            <ParticipantRow
              key={participant.id}
              participant={participant}
              onClickAccept={handleOnClickAccept}
              onClickOnDeny={handleClickOnDeny}
              disabledActions={props.disabledActions}
            />
          );
        })}
      </EnhancedTable>
    );
  }
  return (
    <div className="participant-list-content participant-request-list">
      <div className="participant-list-filter">
        {canSearch && participants.length > 0 && (
          <FilterBox
            variant="light"
            placeholder={i18n.t('Search for users or email')}
            value={filterText}
            onChange={handleOnChangeText}
            onCancel={handleOnClearText}
          />
        )}
      </div>
      {renderContent()}
    </div>
  );
};

RequestAccessList.propTypes = {
  spaceId: PropTypes.string,
  disabledActions: PropTypes.bool,
  canSearch: PropTypes.bool,
  newNotifications: PropTypes.instanceOf(Object),
  setNotifications: PropTypes.func,
};

ParticipantRow.propTypes = {
  participant: PropTypes.instanceOf(Object),
  onClickAccept: PropTypes.func,
  onClickOnDeny: PropTypes.func,
  disabledActions: PropTypes.bool,
};

export default RequestAccessList;
