import { CommentState } from '../../app/appConstants';
import Api from '../../commons/RuntimeApi';
import { getCoreRuntimeApi } from '../../commons/utils';

export async function fetchComments(channelId) {
  try {
    const resp = await Api.get(
      `${getCoreRuntimeApi()}/runtime/channels/${channelId}/comments?order=Created:ASC&limit=100`
    );
    return resp.items;
  } catch (ex) {
    console.log('ERROR fetchComments', ex);
    return [];
  }
}

export async function fetchCommentsWithOffset(spaceId, channelId, offset) {
  try {
    const resp = await Api.get(
      `${getCoreRuntimeApi()}/runtime/channels/${channelId}/comments?order=Created:DESC&limit=20&offset=${offset}`
    );
    if (resp?.items?.length) {
      const materialIds = [];
      resp.items.forEach((comment) => {
        if (
          comment.state === CommentState.Active &&
          comment.materialId &&
          !materialIds.includes(comment.materialId)
        ) {
          materialIds.push(comment.materialId);
        }
        if (
          comment.parentComment?.state === CommentState.Active &&
          comment.parentComment?.materialId &&
          !materialIds.includes(comment.parentComment.materialId)
        ) {
          materialIds.push(comment.parentComment.materialId);
        }
      });

      resp.materialIds = materialIds;
    }
    return resp;
  } catch (ex) {
    console.log('ERROR fetchComments', ex);
    return { items: [] };
  }
}

export async function addComment(channelId, data) {
  try {
    const resp = await Api.post(
      `${getCoreRuntimeApi()}/runtime/channels/${channelId}/comments`,
      data
    );
    return resp;
  } catch (ex) {
    console.log('ERROR addComment', ex);
    return null;
  }
}

export async function updateComment(channelId, id, data) {
  try {
    const resp = await Api.put(
      `${getCoreRuntimeApi()}/runtime/channels/${channelId}/comments/${id}`,
      data
    );
    return resp;
  } catch (ex) {
    console.log('ERROR updateComment', ex);
    return null;
  }
}

export async function deleteComment(channelId, id) {
  try {
    const resp = await Api.delete(
      `${getCoreRuntimeApi()}/runtime/channels/${channelId}/comments/${id}`
    );
    return resp;
  } catch (ex) {
    console.log('ERROR deleteComment ', ex);
    return null;
  }
}

export async function fetchChannel(spaceId, channelType) {
  try {
    const url = `/runtime/spaces/${spaceId}/channels?channelType=${channelType}`;
    const resp = await Api.get(`${getCoreRuntimeApi()}${url}`);
    return resp;
  } catch (ex) {
    console.log('ERROR fetchChannel ', ex);
    return null;
  }
}

export async function addChannel(spaceId, data) {
  try {
    const url = `/runtime/spaces/${spaceId}/channels`;
    const resp = await Api.post(`${getCoreRuntimeApi()}${url}`, data);
    return resp;
  } catch (ex) {
    console.log('ERROR addChannel ', ex);
    return null;
  }
}

export async function joinChannel(spaceId, connectionId, channelId, channelType) {
  try {
    const url = `/runtime/spaces/${spaceId}/channels/join-channel`;
    const resp = await Api.post(`${getCoreRuntimeApi()}${url}`, {
      connectionId,
      channelId,
      channelType,
    });
    return resp;
  } catch (ex) {
    console.log('ERROR joinChannel ', ex);
    return null;
  }
}

export async function leaveChannel(spaceId, connectionId, channelId, channelType) {
  // console.log('connectionId', connectionId, channelId);
  const url = `/runtime/spaces/${spaceId}/channels/leave-channel`;
  try {
    const resp = await Api.post(`${getCoreRuntimeApi()}${url}`, {
      connectionId,
      channelId,
      channelType,
    });
    return resp;
  } catch (ex) {
    console.log('ERROR joinChannel ', ex);
    return null;
  }
}

export async function addInteraction(channelId, data) {
  try {
    const resp = await Api.post(
      `${getCoreRuntimeApi()}/runtime/channels/${channelId}/comments/interactions`,
      data
    );
    return resp;
  } catch (ex) {
    console.log('ERROR addInteraction', ex);
    return null;
  }
}

export async function deleteInteraction(channelId, commentInteractionId, data) {
  try {
    const resp = await Api.delete(
      `${getCoreRuntimeApi()}/runtime/channels/${channelId}/comments/interactions/${commentInteractionId}`,
      data
    );
    return resp;
  } catch (ex) {
    console.log('ERROR addInteraction', ex);
    return null;
  }
}

export async function getChannelByParticipants(spaceId, participants) {
  try {
    let filterString = '';
    if (participants && participants.length > 0) {
      participants.forEach((tagId) => {
        if (filterString) {
          filterString += '&';
        }
        filterString += `participants=${tagId}`;
      });
    }
    const url = `/runtime/spaces/${spaceId}/channels/get-channel-by-participants?${filterString}`;
    const resp = await Api.get(`${getCoreRuntimeApi()}${url}`);
    return resp;
  } catch (ex) {
    console.log('ERROR getChannelByParticipants ', ex);
    return null;
  }
}

export async function fetchAllChannels(spaceId, channelType) {
  try {
    const url = `/runtime/spaces/${spaceId}/channels/all?channelType=${channelType}&limit=1000`;
    const resp = await Api.get(`${getCoreRuntimeApi()}${url}`);
    return resp.items;
  } catch (ex) {
    console.log('ERROR fetchAllChannels ', ex);
    return [];
  }
}

export async function getChannelById(spaceId, channelId) {
  try {
    const url = `/runtime/spaces/${spaceId}/channels/${channelId}`;
    const resp = await Api.get(`${getCoreRuntimeApi()}${url}`);
    return resp;
  } catch (ex) {
    console.log('ERROR getChannelById ', ex);
    return null;
  }
}

export async function addExternalChannel(spaceId, data) {
  try {
    const url = `/runtime/spaces/${spaceId}/external-channel`;
    const resp = await Api.post(`${getCoreRuntimeApi()}${url}`, data || { spaceId: spaceId });
    return resp;
  } catch (ex) {
    console.log('ERROR addExternalChannel ', ex);
    return null;
  }
}

export function getLastComment(channelId) {
  return Api.get(`${getCoreRuntimeApi()}/runtime/channels/${channelId}/comments/last`).catch(
    (ex) => {
      console.log(ex);
      return null;
    }
  );
}
