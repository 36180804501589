import { Box } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CacheKeys } from '../../app/queryCache';
import Loading from '../../components/Loading';
import DataSummaryBox from '../DataSummaryBox';
import SpaceAnalyticsInfoBox from '../SpaceAnalyticsInfoBox';
import Chart, { ChartDefinition } from '../components/Chart';
import useSpaceAnalyticsQuery from '../hooks/useSpaceAnalyticsQuery';
import { fetchResourcesSummary } from '../services/SpaceAnalyticsService';
import { AllResourceMetricsResponse, ResourceMetrics, ResourcesSummary } from '../types/resources';
import ResourceMetricsContainer from './ResourceMetricsContainer';
import useAvailableSpaceResources from '../hooks/useAvailableSpaceResources';
import NoSpaceAnalyticsData from '../components/NoSpaceAnalyticsData';
import i18n from '../../i18n';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import { Ids } from '../../commons/pendoTaggings';
import { SidebarType } from '../../app/appConstants';

const generateSummary = (totalResources?: number, metrics?: ResourceMetrics) => {
  const dataSummaryList = [
    {
      id: 'resources',
      label: 'Resources',
      value: totalResources || 0,
      toolTip: 'Total number of resources that users have interacted with.',
    },
    {
      id: 'clicks',
      label: 'Clicks',
      value: metrics?.clicks || 0,
      toolTip: 'Total number of resource clicks in the space.',
    },
    {
      id: 'viewTime',
      label: 'View time',
      value: metrics?.viewTimeInSeconds || 0,
    },
    {
      id: 'shares',
      label: 'Shares',
      value: metrics?.shares || 0,
      toolTip: 'Total number of times resources have been shared.',
    },
    {
      id: 'downloads ',
      label: 'Downloads ',
      value: metrics?.downloads || 0,
      toolTip: 'Total number of times resources have been downloaded.',
    },
  ];

  return dataSummaryList;
};

type ResourceTableProps = {
  resourcesSummary?: ResourcesSummary;
  resourceMetricsByResources?: AllResourceMetricsResponse;
  resourceMetricsByParticipants?: AllResourceMetricsResponse;
};

const ResourceTable = (props: ResourceTableProps) => {
  const { resourcesSummary, resourceMetricsByResources, resourceMetricsByParticipants } = props;
  const navigate = useNavigate();
  const { space, isPublic, excludeUserIds, visibleUsers } = useSpaceAnalyticsContext();

  const { availableMaterialIds, isLoading: isAvailableResourceLoading } =
    useAvailableSpaceResources();

  const { data, isLoading } = useSpaceAnalyticsQuery({
    fetcher: fetchResourcesSummary,
    queryKey: CacheKeys.spaceAnalytics_FetchResourcesSummary,
    params: {
      availableMaterialIds,
      excludedUserInfoIds: excludeUserIds,
      isPublic,
      userInfoIds: visibleUsers?.map((x) => x.userInfoId),
    },
    select: (resp) => resp.value,
    enabled: !resourcesSummary && !isAvailableResourceLoading,
  });

  const resourcesData = resourcesSummary || data;

  const defineAxis = useCallback(() => {
    if (!resourcesData?.data) {
      return { left: undefined, right: undefined };
    }

    const flatData = resourcesData.data.map((x) => x.data);
    const maxCount = Math.max(...flatData.map((x) => Math.max(x.clicks, x.downloads, x.shares)));
    const maxDuration = Math.max(...flatData.map((x) => x.viewTimeInSeconds));
    return {
      left: {
        maxValue: maxDuration,
        isDuration: true,
      },
      right: {
        maxValue: maxCount,
        isDuration: false,
      },
    };
  }, [resourcesData?.data]);

  const chartData = useMemo(() => resourcesData?.data || [], [resourcesData?.data]);
  const tooltipLabels = useMemo<string[]>(() => chartData.map((x) => x.label), [chartData]);

  const chartDefinitions = useMemo<ChartDefinition[]>(
    () => [
      {
        type: 'bar',
        label: 'View time',
        data: chartData?.map((x) => x.data.viewTimeInSeconds) || [],
        color: '#B8F5DB',
        axisBinding: 'left',
        isDuration: true,
        order: 4,
      },
      {
        type: 'line',
        label: 'Clicks',
        data: chartData?.map((x) => x.data.clicks),
        color: '#4783FF',
        axisBinding: 'right',
        order: 3,
      },
      {
        type: 'line',
        label: 'Shares',
        data: chartData?.map((x) => x.data.shares),
        color: '#AD2DE1',
        axisBinding: 'right',
        order: 2,
      },
      {
        type: 'line',
        label: 'Downloads',
        data: chartData?.map((x) => x.data.downloads),
        color: '#1FDA8B',
        axisBinding: 'right',
        order: 1,
      },
    ],
    [chartData]
  );

  const renderChart = useCallback(() => {
    const axis = defineAxis();
    return (
      <Chart
        chartDefinitions={chartDefinitions}
        leftAxesDefinition={axis.left}
        rightAxesDefinition={axis.right}
        tooltipLabels={tooltipLabels}
        chartLabels={resourcesData?.labels || []}
      />
    );
  }, [chartDefinitions, resourcesData?.labels, defineAxis, tooltipLabels]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }

    if (!availableMaterialIds || availableMaterialIds.length === 0) {
      return (
        <NoSpaceAnalyticsData
          icon={<FolderIcon />}
          description={i18n.t('Add more files to see who clicks, shares, and downloads them.')}
          button={{
            label: i18n.t('Add Resource'),
            onClick: () =>
              navigate(`/s/${space?.id}`, { state: { openSidebar: SidebarType.Material } }),
            dataId: Ids.resourceTableAddResourceButton,
          }}
        />
      );
    }

    return (
      <>
        <DataSummaryBox
          dataSummaryList={generateSummary(
            resourceMetricsByResources?.data?.length,
            resourcesData?.summary
          )}
        />
        {renderChart()}
        <ResourceMetricsContainer
          resourceMetricsByResources={resourceMetricsByResources}
          resourceMetricsByParticipants={resourceMetricsByParticipants}
        />
      </>
    );
  };

  return (
    <Box className="widget-box resource-table">
      <SpaceAnalyticsInfoBox
        icon="icon-folder"
        title="Resources"
        description={
          availableMaterialIds && availableMaterialIds.length > 0
            ? 'Find out who has viewed, shared, and downloaded resources in the space.'
            : ''
        }
        className="resource"
      />
      {renderContent()}
    </Box>
  );
};

ResourceTable.defaultProps = {
  gird: 12,
};

export default ResourceTable;
