import { Indicator } from '../../globalResources/context/Interfaces';
import ResourceGridItem from './index';

export interface ResourceGridItemProps {
  resourceId: number | string;
  hasChanges?: boolean;
  lastModified: string;
  created?: string;
  isExternal?: boolean;
  resourceName: string;
  thumbnail?: string;
  state?: number;
  src?: string;
  extension?: string;
  resourceType?: number;
  selected?: boolean;
  multiple?: boolean;
  isMobile?: boolean;
  subscriptionLock?: boolean;
  isViewOnly?: boolean;
  isFolder?: boolean;
  isNewFile?: boolean;
  isDisabled?: boolean;
  isProcessing?: boolean;
  isConvertingFile: boolean;
  dimmed?: boolean;
  indicator?: Indicator;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDoubleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickMoreButton?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showActionMenu?: boolean;
  disabledMoreButton?: boolean;
  draggable?: boolean;
  isDragging?: boolean;
  showCheckbox?: boolean;
  labelId?: string;
  disableCheckbox?: boolean;
  numberFileOfFolder: () => void;
  isPortal: boolean;
  driveId?: string;
  location?: string;
  disabledRowMessage?: string;
  provider?: string;
}

const ResourceGridItemTs = ResourceGridItem;
export default ResourceGridItemTs as React.FC<ResourceGridItemProps>;
