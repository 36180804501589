import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import { getMaterialResource } from '../resources/ResourceServices';
import spaceUser from './spaceUser';
import ErrorDialog from '../components/ErrorDialog';
import i18n from '../i18n';
import ConfirmService from '../components/ConfirmService';
import {
  ConvertToPDFResourceTypes,
  Provider,
  ResourceInteractionType,
  ResourceState,
  ResourceTypeNames,
  SpaceInteractionType,
  SidebarType,
} from '../app/appConstants';
import { isOneOfResourceTypes } from '../commons/ResourceUtils';
import VideoMessageDialog from '../vaam/VideoMessageDialog';
import BlockUI from '../components/BlockUI';
import { useSpaceContext } from './SpaceContext';
import { createResourceInteraction } from '../commons/CommonServices';
import useBrowserUtils from '../commons/useBrowserUtils';
import eventBus, { EVENT_BUS } from '../commons/EventBus';

const ResourceShareLink = (props) => {
  const { materialId, spaceId, isPublicSpace, onOpenResource, channelId } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { space, theme, setSidebar } = useSpaceContext();
  const { removeURLParams } = useBrowserUtils();

  const fetchMaterialQuery = useQuery({
    queryKey: [CacheKeys.getMaterialResource, materialId],
    queryFn: async () => {
      const resp = await getMaterialResource(spaceId, materialId);
      return resp;
    },

    retry: (failureCount, error) => {
      if (
        failureCount === 1 ||
        error.message.indexOf('you need higher access to view this resource')
      ) {
        return false;
      }
      return true;
    },
    enabled:
      (!!spaceId && !!spaceUser.hasLoggedIn()) || (!spaceUser.hasLoggedIn() && isPublicSpace),
  });

  useEffect(() => {
    // console.log('### fetchMaterialQuery', fetchMaterialQuery.isError);
    if (fetchMaterialQuery.isError) {
      ErrorDialog.show(
        i18n.t('YOU HAVE NO ACCESS'),
        i18n.t(
          'Uh-oh, you need higher access to view this resource. Please contact host to get access.'
        ),
        () => {
          removeURLParams(['srId', 'channelId']);
        }
      );
    }
  }, [fetchMaterialQuery.isError, removeURLParams]);
  const material = fetchMaterialQuery.data;
  const isSuccess = fetchMaterialQuery.isSuccess;

  useEffect(() => {
    function handleSentViewedEventToInsight(totalTime) {
      if (totalTime > 0) {
        eventBus.publish(EVENT_BUS.SpaceAnalyticsEvents, SpaceInteractionType.VIEW_RESOURCE, {
          resourceId: material.resourceId,
          materialId: materialId,
          isCms: false,
          duration: Math.round(totalTime),
          resourceType: ResourceTypeNames.video,
          IsVideoMessageViewed: true,
          channelId: channelId ?? 0,
        });
      }
    }

    const confirmDialog = async () => {
      await ConfirmService.show(
        i18n.t('RESOURCE DELETED'),
        <Box display="flex" flexDirection="column">
          <span>{i18n.t('This resource has been deleted.')}</span>
          <span>{i18n.t('Please contact the host if you need to access it.')}</span>
        </Box>,
        i18n.t('Ok'),
        null,
        true
      );
      if (materialId) {
        removeURLParams(['srId', 'channelId']);
        setSidebar(null);
      }
    };
    if (!material) {
      if (!!materialId && !!spaceId && isSuccess) {
        confirmDialog();
      }
      return;
    }
    const externalDocumentSettings = material.externalDocumentSettings;
    if (!externalDocumentSettings) {
      const selectingMaterial = {
        ...material,
        shouldConvertToPDF:
          material.resourceState !== ResourceState.Converted &&
          isOneOfResourceTypes(material.resourceType, ConvertToPDFResourceTypes),
      };
      onOpenResource(selectingMaterial);
    } else {
      const { documentId, provider } = externalDocumentSettings;
      if (provider === Provider.Vaam) {
        VideoMessageDialog.show(
          spaceId,
          documentId,
          material.resourceName === '' ? 'Video Message' : material.resourceName,
          material.resourceDescription,
          material.createdByUserInfoId,
          material.createdDate,
          theme?.logoImageSrc,
          navigate,
          location,
          handleSentViewedEventToInsight,
          () => {
            createResourceInteraction(material.resourceId, {
              interactionType: ResourceInteractionType.Click,
              documentId,
              CommentDescription: material.resourceDescription,
              CommentTitle: material.resourceName,
              IsVideoMessageViewed: true,
              SpaceName: space.name,
              ResourceCreatedByUserInfoId: material.createdByUserInfoId,
              materialId,
              ChannelId: channelId ?? 0,
              data: JSON.stringify({
                resourceType: 'VaamRecordingVideo',
              }),
            });
          },
          () => {
            setSidebar(null);
          }
        );
      }
    }
    if (channelId) {
      setSidebar(SidebarType.Chat);
    }
    removeURLParams(['srId', 'channelId']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    material,
    isSuccess,
    materialId,
    onOpenResource,
    spaceId,
    space.name,
    channelId,
    theme?.logoImageSrc,
  ]);

  if (fetchMaterialQuery.isLoading) {
    return <BlockUI display />;
  }
  return null;
};

ResourceShareLink.propTypes = {
  materialId: PropTypes.number,
  spaceId: PropTypes.string,
  isPublicSpace: PropTypes.bool,
  onOpenResource: PropTypes.func,
  channelId: PropTypes.string,
};

export default ResourceShareLink;
