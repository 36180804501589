import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { uuidv4 } from '../commons/utils';
import RefreshExternalResourceButton from './RefreshExternalResourceButton';
import useWaypoint from '../commons/useWaypoint';
import BlockUI from './BlockUI';

const ResourceExternalUrlViewer = (props) => {
  const { title, src, externalTool } = props;
  console.log('external', externalTool);
  const [loaded, setLoaded] = useState(false);
  const { visible, onEnter } = useWaypoint();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  // Assign random key to iframe
  const [key, setKey] = useState(uuidv4());

  function reload() {
    setKey(uuidv4());
  }
  // this will run when the document is fully loaded

  return (
    <Waypoint onEnter={onEnter} fireOnRapidScroll={false} debug={false} bottomOffset={'-100px'}>
      <div
        className="iframe-player-container"
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        {externalTool && (
          <RefreshExternalResourceButton className={'resource-refresh-button'} onClick={reload} />
        )}
        {visible && (
          <>
            {!loaded && (
              <BlockUI display backgroundColor="#fff" iconColor="#222" backgroundOpacity={1} />
            )}
            <iframe
              key={key}
              src={src}
              title={title}
              id="iframe"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              onLoad={() => setTimeout(() => setLoaded(true), 2000)}
              style={loaded ? { backgroundColor: '#fff' } : { backgroundColor: '#fff', height: 0 }}
            ></iframe>
          </>
        )}
      </div>
    </Waypoint>
  );
};

ResourceExternalUrlViewer.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  externalTool: PropTypes.bool,
};

export default ResourceExternalUrlViewer;
