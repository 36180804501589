import { ResourceType } from '../../app/appConstants';

export const isThumbnailSupported = (type) => {
  return (
    type === ResourceType.pdf ||
    type === ResourceType.word ||
    type === ResourceType.excel ||
    type === ResourceType.powerpoint ||
    type === ResourceType.cad ||
    type === ResourceType.keynote ||
    type === ResourceType.video ||
    type === ResourceType.image
  );
};

export const isGoodToRender = (thumbnail) => {
  if (!thumbnail) return false;
  return true;
};
