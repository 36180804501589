import React, { Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import spaceUser from '../spaceUser';
import Loading from '../../components/Loading';
import useSpaceAssessmentQuery from '../../assessments/queries/useSpaceAssessmentQuery';
import DeletedAssessmentDialog from './DeletedAssessmentDialog';
import queryCache, { CacheKeys } from '../../app/queryCache';

const Assessment = React.lazy(() => import('../../assessments/settings/Assessment'));

function AssessmentSpace(props) {
  const { id, userAssessmentId, spaceHostEmail, type, onClose, openAssessment } = props;

  const { data, isLoading, refetch } = useSpaceAssessmentQuery(id, type, userAssessmentId);

  const canTryAgain = data?.maxNoOfAttempts > data?.attempts || !data?.maxNoOfAttempts;

  const userTest = useMemo(() => {
    return {
      fullName: spaceUser?.getFullName(),
      email: spaceUser?.getEmail(),
    };
  }, []);

  function onTryAgain() {
    openAssessment({
      id: id,
      type: 'start',
    });
    refetch();
  }

  function onViewCertificate(userCredentialId) {
    console.log('View certificate, userCredentialId:', userCredentialId);
  }

  function clearAssessment() {
    queryCache.removeQueries({
      queryKey: [CacheKeys.fetchSpaceAssessment, userAssessmentId, id, type],
      exact: true,
    });
  }

  function renderContent() {
    if (!id) {
      return null;
    }
    if (isLoading) {
      return (
        <div className="loading-box">
          <Loading />
        </div>
      );
    }
    if (!data) {
      return (
        <div className="not-found-box">
          <DeletedAssessmentDialog spaceHostEmail={spaceHostEmail} onClose={onClose} />
        </div>
      );
    }
    return (
      <Suspense fallback={<Loading />}>
        <Assessment
          key={data.id}
          assessment={data}
          userTest={userTest}
          type={type}
          onTryAgain={canTryAgain ? onTryAgain : null}
          onViewCertificate={onViewCertificate}
          onClickBackButton={clearAssessment}
        />
      </Suspense>
    );
  }

  return renderContent();
}

AssessmentSpace.propTypes = {
  id: PropTypes.string.isRequired,
  spaceHostEmail: PropTypes.string,
  type: PropTypes.string,
  userAssessmentId: PropTypes.string,
  onClose: PropTypes.func,
};

export default AssessmentSpace;
