import RuntimeApi from '../../commons/RuntimeApi';
import Api from '../../commons/api';
import { getIntegrationApi, getIntegrationRuntimeApi } from '../../commons/utils';
import { ONEDRIVE_THUMBNAIL_SIZE, mapOneDriveSortBy } from './utils';

export async function getOneDriveResources(
  driveId,
  itemId,
  includeChildrenItemsCount,
  pageSize,
  skipToken,
  orderBy,
  order = 'asc'
) {
  let url = `${getIntegrationApi()}/api/onedrive?includeChildrenItemsCount=${includeChildrenItemsCount}`;
  if (driveId && itemId) {
    url = `${getIntegrationApi()}/api/onedrive/${driveId}/items/${itemId}/children?includeChildrenItemsCount=${includeChildrenItemsCount}`;
    if (pageSize) {
      url = `${url}&pageSize=${pageSize}`;
      if (skipToken) {
        url = `${url}&skipToken=${skipToken}`;
      }
    }
  }
  if (orderBy) {
    url += `&orderBy=${mapOneDriveSortBy(orderBy)}&order=${order}`;
  }

  return Api.get(url);
}

export function getOneDriveRootFolders() {
  const url = `${getIntegrationApi()}/api/onedrive/available`;
  return Api.get(url);
}

export function getOneDriveSubFolders(driveId, itemId) {
  const url = `${getIntegrationApi()}/api/onedrive/${driveId}/items/${itemId}/available`;
  return Api.get(url);
}

export async function saveDefaultRootFolders(selectedFolders) {
  return Api.post(`${getIntegrationApi()}/api/onedrive`, { selectedFolders });
}

export async function getOneDriveThumbnail(driveId, itemId, size, isPortal = false) {
  const gettingSize = size || ONEDRIVE_THUMBNAIL_SIZE.Medium;
  if (isPortal) {
    const url = `${getIntegrationApi()}/api/onedrive/${driveId}/items/${itemId}/thumbnail?size=${gettingSize}`;
    return Api.get(url);
  }
  const url = `${getIntegrationRuntimeApi()}/api/runtime/onedrive/${driveId}/items/${itemId}/thumbnail?size=${gettingSize}`;
  return RuntimeApi.get(url);
}

export async function searchOneDriveItem(keyword) {
  const url = `${getIntegrationApi()}/api/onedrive/search?keyword=${keyword}`;
  return Api.get(url);
}

export async function getOneDriveResourceId(id) {
  return Api.get(`${getIntegrationApi()}/api/onedrive/${id}/resource-id`)
    .then((resp) => resp)
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function searchRuntimeOneDriveItem(data, keyword) {
  const url = `/api/runtime/onedrive/search?keyword=${keyword}&pageSize=200&page=1`;
  const folders = data.map((item) => ({
    webUrl: decodeURI(item.webUrl),
    folderId: item.folderId,
  }));
  return RuntimeApi.post(`${getIntegrationRuntimeApi()}${url}`, {
    folders,
  });
}

export async function getDriveInfo(driveId, itemId) {
  const url = `${getIntegrationRuntimeApi()}/api/runtime/onedrive/${driveId}/items/${itemId}`;
  return RuntimeApi.get(url);
}
