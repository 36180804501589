import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, IconButton, Autocomplete } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './FontSizeSelect.scss';

const FontSizeValues = [
  { id: 9, value: '9' },
  { id: 10, value: '10' },
  { id: 11, value: '11' },
  { id: 12, value: '12' },
  { id: 13, value: '13' },
  { id: 14, value: '14' },
  { id: 15, value: '15' },
  { id: 16, value: '16' },
  { id: 18, value: '18' },
  { id: 24, value: '24' },
  { id: 28, value: '28' },
  { id: 36, value: '36' },
  { id: 48, value: '48' },
  { id: 60, value: '60' },
  { id: 64, value: '64' },
  { id: 72, value: '72' },
  { id: 85, value: '85' },
  { id: 96, value: '96' },
];

const FontSizeSelect = (props) => {
  const { defaultValue, onChange, label, name } = props;
  const fontSize = defaultValue?.replace('px', '');
  const [value, setValue] = useState(fontSize);
  const [showOptions, setShowOptions] = useState(false);

  // React.useEffect(() => {
  //   // console.log('### FontSizeSelect useEffect: ', defaultValue);
  //   const finalValue = defaultValue?.replace('px', '');
  //   setValue(finalValue);
  // }, [defaultValue]);

  function handleOnChange(event, newValue) {
    if (newValue) {
      setValue(newValue);
    }
    if (onChange) {
      onChange(`${newValue.value || newValue}px`);
    }
  }

  function handleClickShowOptions() {
    setShowOptions(!showOptions);
  }

  function handleOpenOptions() {
    setShowOptions(true);
  }
  function handleCloseOptions() {
    setShowOptions(false);
  }

  return (
    <div className="font-size-select">
      <label className="label">{label}</label>
      <Autocomplete
        open={showOptions}
        onOpen={handleOpenOptions}
        onClose={handleCloseOptions}
        options={FontSizeValues}
        onChange={handleOnChange}
        value={value}
        freeSolo
        getOptionLabel={(option) => (option.value ? option.value : option)}
        isOptionEqualToValue={(option, val) => {
          return option.value === (val.value ? val.value : val);
        }}
        className="font-size-select-autocomplete"
        renderOption={(optionProps, option) => {
          return option?.value ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div style={{ color: '#000' }} className="option-item" {...optionProps}>
              {option?.value}
            </div>
          ) : null;
        }}
        disableClearable
        handleHomeEndKeys
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            name={name} // must have the name & autocomplete=new-password to disable autocomplete and autofill
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    size="small"
                    onClick={handleClickShowOptions}
                    className="icon-button"
                  >
                    {showOptions ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

FontSizeSelect.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default FontSizeSelect;
