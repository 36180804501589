import useFlatDirectories from '../../../components/Material/useFlatDirectories';
import { SERVICE_PROVIDER } from '../../../integrations/integrationConstants';
import {
  createRuntimeDirectory,
  removeRuntimeDirectory,
} from '../../../resources/ResourceServices';
import useSpaceMaterialsQuery from '../../../spaces/Materials/useSpaceMaterialsQuery';
import spaceUser from '../../../spaces/spaceUser';

function useSpaceAssessments(space) {
  const spaceId = space?.id;
  const getMaterialsQuery = useSpaceMaterialsQuery(spaceId, true, true, false);
  const { flatDirectories, setFlatDirectories } = useFlatDirectories(
    getMaterialsQuery,
    spaceUser,
    true
  );

  async function createAssessmentDirectory() {
    // find LMS folder
    const lmsFolder = flatDirectories?.find(
      (folder) => folder.externalFolderSettings?.provider === SERVICE_PROVIDER.LMS
    );
    if (!lmsFolder) {
      // create corresponding LMS folder in the space
      const folderData = {
        name: 'Certificates',
        parentFolderId: null,
        externalFolder: {
          provider: SERVICE_PROVIDER.LMS,
          folderId: 'my-certificates',
        },
      };
      const respFolder = await createRuntimeDirectory(spaceId, folderData);
      if (respFolder) {
        // add LMS folder to flatDirectories
        const newFlatDirectories = flatDirectories.concat(respFolder);
        setFlatDirectories(newFlatDirectories);
      }
    }
  }

  async function removeAssessmentDirectory() {
    // find LMS folder
    const lmsFolders = flatDirectories?.filter(
      (folder) => folder.externalFolderSettings?.provider === SERVICE_PROVIDER.LMS
    );
    if (lmsFolders.length > 0) {
      lmsFolders.forEach(async (lmsFolder) => {
        // remove corresponding LMS folder in the space
        await removeRuntimeDirectory(spaceId, lmsFolder.id);
      });

      // remove LMS folders from flatDirectories
      const newFlatDirectories = flatDirectories.filter(
        (folder) => folder.externalFolderSettings?.provider !== SERVICE_PROVIDER.LMS
      );
      setFlatDirectories(newFlatDirectories);
    }
  }

  return { createAssessmentDirectory, removeAssessmentDirectory };
}

export default useSpaceAssessments;
