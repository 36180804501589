import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSpaceContext } from '../SpaceContext';
import { getUrlParameter } from '../../commons/utils';
import { SidebarType } from '../../app/appConstants';
import { contextNotificationsType } from '../../components/Notification/constants';

const SpaceSidebarHandler = () => {
  const channelId = getUrlParameter('channelId', null, true);

  const { setSidebar, notiContext, setSelectedTargetChannel } = useSpaceContext();
  const navigate = useNavigate();
  const location = useLocation();

  let openSidebar;
  if (notiContext) {
    openSidebar = contextNotificationsType[notiContext];
  } else {
    openSidebar = location?.state?.openSidebar || false;
    // console.log('### 276', openSidebar, todoId, history.location?.state);
  }

  // Open sidebar when click notification bell button in the same space or space overview
  useEffect(() => {
    // console.log('### 276 useEffect', openSidebar);
    if (openSidebar) {
      setSidebar(openSidebar);
      navigate(`${location.pathname}${location.search}`, {
        state: {
          ...location.state,
          openSidebar: false,
          selectedTargetChannel: null,
        },
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSidebar]);

  useEffect(() => {
    if (channelId) {
      setSidebar(SidebarType.Chat);
      setSelectedTargetChannel(channelId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  return null;
};

export default SpaceSidebarHandler;
