/* eslint no-useless-escape: 0 */
import { getDomain } from './utils';

export function isNumber(input) {
  if (input === '' || input === null || input === undefined || isNaN(input)) return false;
  if (!isNaN(input)) {
    return true;
  }
  const regex = /[^0-9]/g;
  return input.match(regex) === null;
}

export const regexNumber = /^[0-9]+$/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(email) {
  return emailRegex.test(email);
}

const domainRegex = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/;

export function isValidDomain(website) {
  const isValid = domainRegex.test(website);
  if (isValid) {
    return true;
  }
  const domain = getDomain(website);
  if (!domain) {
    return false;
  }
  return domainRegex.test(domain);
}

const urlRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export function isValidUrl(url) {
  if (!url) {
    return false;
  }
  const res = url.match(urlRegex);
  if (res == null) return false;
  return true;
}

const urlSlugRegex = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;

export function isValidUrlSlugRegex(url) {
  if (!url) {
    return false;
  }
  const res = url.match(urlSlugRegex);
  if (res == null) return false;
  return true;
}
