import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchUserTrafficDetails } from '../services/SpaceAnalyticsService';

const useParticipantTrafficDetail = (
  spaceId: string | undefined,
  startDate: string,
  endDate: string,
  participantId: number,
  isPublic: boolean | undefined
) => {
  const getUserTrafficIndividualParticipants = useQuery({
    queryKey: [
      CacheKeys.fetchUserTrafficDetails,
      spaceId,
      startDate,
      endDate,
      participantId,
      isPublic,
    ],
    queryFn: async () => {
      if (!spaceId) {
        return [];
      }
      const resp = await fetchUserTrafficDetails(
        spaceId,
        startDate,
        endDate,
        participantId,
        isPublic
      );
      return resp;
    },

    retry: 3,
    retryDelay: () => 5000,
    enabled: !!participantId,
  });
  const participantTrafficDetail =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getUserTrafficIndividualParticipants.data?.value || [];
  const isFetching = getUserTrafficIndividualParticipants.isFetching;
  return { participantTrafficDetail, isFetching };
};

export default useParticipantTrafficDetail;
