import memoizeOne from 'memoize-one';

import { searchRuntimeOneDriveItem } from '../../integrations/onedrive/onedriveService';
import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';
import { generateSrcResource } from '../../integrations/integrationResourceUtils';

const findParentFolder = memoizeOne((folders, folder) => {
  const parentFolder = folders.find((item) => {
    if (item.folderId === folder.parentId) {
      return item;
    } else {
      return folder.webUrl.includes(item.webUrl);
    }
  });
  return parentFolder;
});

const mappingOneDriveData = (data, searchingInFolders) => {
  if (!data) return [];
  const newData = [...data];

  return newData.map((item) => {
    const parentFolder = findParentFolder(searchingInFolders, item);
    const newItem = {
      ...item,
      type: item.resourceType,
      provider: SERVICE_PROVIDER.ONEDRIVE,
      spaceId: parentFolder?.spaceId,
      spaceFolderId: parentFolder?.spaceFolderId,
    };
    if (item.isFolder) {
      const externalId = `OneDrive:${item.id}`;
      newItem.externalFolderSettings = {
        provider: SERVICE_PROVIDER.ONEDRIVE,
        folderId: item.id,
        driveId: item.driveId,
        externalId: externalId,
      };
      newItem.spaceResources = [];
      newItem.externalId = externalId;
      newItem.id = externalId;
      newItem.spaceFolderId = parentFolder?.spaceFolderId;
      newItem.isSearchData = true;
      newItem.parentFolderId = `OneDrive:${item.parentId}`;
      newItem.isExternal = true;
      newItem.directSubFolderCount = item.itemsCount;
    } else {
      newItem.src = generateSrcResource(newItem);
      newItem.id = 0;
      newItem.externalId = item.id;
      newItem.directoryId = parentFolder?.spaceFolderId;
    }
    return newItem;
  });
};
const useSearchOneDriveFoldersQuery = (oneDriveFolders, keyword, enabled) => {
  const oneDriveFolderIds = oneDriveFolders.map((folder) => folder.folderId).join(',');
  const oneDriveQuery = {
    queryKey: ['oneDriveSearch', oneDriveFolderIds, keyword],
    queryFn: async () => {
      const resp = await searchRuntimeOneDriveItem(oneDriveFolders, keyword);
      return mappingOneDriveData(resp.items, oneDriveFolders);
    },
    enabled: !!oneDriveFolders.length && !!keyword && enabled,
    retry: 0,
    retryDelay: 5000,
  };

  return oneDriveQuery;
};

export default useSearchOneDriveFoldersQuery;
