import { useState, useEffect, useCallback } from 'react';
import useArrayState from '../../commons/useArrayState';
import { sendNotification } from '../../commons/utils';
import { ResourceState } from '../../app/appConstants';
import { convertResource } from '../../resources/ResourceServices';

const useFlatDirectories = (getMaterialsQuery, currentUser, isPortal = false) => {
  const [flatDirectories, setFlatDirectories] = useState(null);
  const {
    items: processedResources,
    addItem: addProcessedResource,
    removeItems: removeProcessedResources,
  } = useArrayState();
  const {
    items: processingResources,
    addItem: addProcessingResource,
    removeItem: removeProcessingResource,
  } = useArrayState([]);

  // const isHost = currentUser?.data?.spaceAccessSession?.role === SpaceUserRoleNames.Host;

  // const userInfoId = currentUser?.data?.spaceAccessSession?.userInfoId;
  const spaceId = currentUser?.data?.spaceAccessSession?.spaceId;

  useEffect(() => {
    const data = getMaterialsQuery?.data;
    if (data) {
      const folders = data.spaceDirectories || [];

      setFlatDirectories(
        folders.filter((folder) => {
          if (folder.isHidden === true) {
            return false;
          }
          return true;
        })
      );
    }
  }, [currentUser, getMaterialsQuery?.data, isPortal]);

  useEffect(() => {
    if (processedResources.length === 0) {
      return;
    }
    console.log('### processedResources', processedResources);
    setFlatDirectories((prevState) => {
      if (!prevState) return [];
      const newState = prevState.slice(); // folder list

      processedResources.forEach((processedResource) => {
        for (let index = 0; index < newState.length; index += 1) {
          const folder = newState[index];
          const foundMaterialIndex = folder.spaceResources.findIndex(
            (m) => m.resourceId === processedResource.id
          );
          if (foundMaterialIndex !== -1) {
            const newMaterial = {
              ...folder.spaceResources[foundMaterialIndex],
              isTranscoded: true,
              state: processedResource.state,
              thumbnail: processedResource.thumbnail,
            };
            folder.spaceResources[foundMaterialIndex] = newMaterial;
          }
        }
      });

      removeProcessedResources(processedResources);

      return newState;
    });
  }, [processedResources, removeProcessedResources]);

  const onMaterialUpdated = useCallback((detail) => {
    console.log('### onMaterialUpdated: ', detail);
    if (!detail) return;
    const {
      resourceId,
      name,
      description,
      src,
      directoryId,
      resourceName,
      displayName,
      isDownloadable,
      lastModified,
    } = detail;

    setFlatDirectories((prevState) => {
      if (!prevState) return [];
      const newState = prevState.slice();
      const foundFolderIndex = newState.findIndex((folder) => folder.id === directoryId);
      if (foundFolderIndex === -1) {
        return prevState;
      }
      const folder = { ...newState[foundFolderIndex] };
      const foundMaterialIndex = folder.spaceResources.findIndex(
        (m) => m.resourceId === resourceId
      );
      if (foundMaterialIndex !== -1) {
        const foundMaterial = folder.spaceResources[foundMaterialIndex];
        if (name && name !== foundMaterial.name) {
          foundMaterial.name = name;
        }
        if (displayName && displayName !== foundMaterial.displayName) {
          foundMaterial.displayName = displayName;
        }
        if (description && description !== foundMaterial.description) {
          foundMaterial.description = description;
        }
        if (src && src !== foundMaterial.src) {
          foundMaterial.src = src;
        }
        if (resourceName && resourceName !== foundMaterial.resourceName) {
          foundMaterial.resourceName = resourceName;
        }
        if (isDownloadable !== undefined && isDownloadable !== foundMaterial.isDownloadable) {
          foundMaterial.isDownloadable = isDownloadable;
        }
        foundMaterial.lastModified = lastModified;
      } else {
        // console.log('### should clear cache', getMaterialsQuery);
        // Refetch to get materials when changed AL of materials.
        getMaterialsQuery?.refetch();
      }
      folder.lastModified = lastModified;
      newState[foundFolderIndex] = folder;
      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMaterialDeleted = useCallback((detail) => {
    if (!detail) return;
    const { directoryId, materialId } = detail;
    setFlatDirectories((prevState) => {
      if (!prevState) return [];
      const newState = prevState.slice();
      const foundFolderIndex = newState.findIndex((folder) => folder.id === directoryId);
      if (foundFolderIndex === -1) {
        return prevState;
      }
      const foundFolder = { ...newState[foundFolderIndex] };
      foundFolder.spaceResources = foundFolder.spaceResources.filter(
        (item) => item.id !== materialId
      );
      if (newState[foundFolderIndex].spaceResources.length === foundFolder.spaceResources.length) {
        return prevState;
      }
      newState[foundFolderIndex] = foundFolder;
      return newState;
    });
  }, []);

  const onConversionStarted = useCallback(
    (detail) => {
      const { resourceId } = detail;
      console.log('onConversionStarted: ', detail);
      addProcessedResource({ id: resourceId, state: ResourceState.Converting });
      addProcessingResource(resourceId);
    },
    [addProcessedResource, addProcessingResource]
  );

  const onConversionFinished = useCallback(
    (detail) => {
      console.log('onConversionFinished: ', detail);
      addProcessedResource(detail);
      removeProcessingResource(detail.id);
    },
    [addProcessedResource, removeProcessingResource]
  );

  const onConversionFailed = useCallback(
    (detail) => {
      console.log('### onConversionFailed: ', detail);
      addProcessedResource(detail);
      removeProcessingResource(detail.id);
    },
    [addProcessedResource, removeProcessingResource]
  );

  const onTranscodingFinished = useCallback(
    (detail) => {
      console.log('### onTranscodingFinished: ', detail);

      const { id, state, thumbnail } = detail;
      addProcessedResource({ id, state, thumbnail });
    },
    [addProcessedResource]
  );

  const onMaterialAdded = useCallback((detail) => {
    console.log('### onMaterialAdded: ', detail);
    if (detail) {
      const {
        id: materialId,
        createdByUserInfoId,
        spaceDirectoryId: folderId,
        created,
        order,
        resource,
        isTranscoded,
        isDownloadable,
        resourceName,
        fileSize,
      } = detail;

      const {
        name,
        description,
        type,
        src,
        originalSrc: orgSrc,
        id: resourceId,
        state,
        displayName,
        thumbnail,
      } = resource;
      setFlatDirectories((prevState) => {
        if (!prevState) return [];
        const index = prevState.findIndex((d) => d.id === folderId);
        if (index < 0) {
          return prevState;
        }
        const clonedDirectories = prevState.slice();
        const foundFolder = { ...clonedDirectories[index] };
        const addedMaterial = {
          id: materialId,
          resourceId,
          name,
          displayName,
          resourceName,
          description,
          src,
          orgSrc,
          type,
          created,
          createdByUserInfoId,
          order,
          isTranscoded,
          isNew: true,
          state,
          extension: resource.extension,
          isExternal: resource.isExternal,
          isGlobal: resource.isGlobal,
          isDownloadable: isDownloadable || true,
          fileSize,
          externalDocumentSettings: resource?.externalDocumentSettings,
          thumbnail,
        };
        if (!foundFolder.spaceResources) {
          foundFolder.spaceResources = [addedMaterial];
        } else {
          const materialIndex = foundFolder.spaceResources.findIndex((m) => m.id === materialId);
          if (materialIndex < 0) {
            addedMaterial.order = -(foundFolder.spaceResources.length + 1); // a trick to be on top.
            foundFolder.spaceResources.push(addedMaterial);
            // setScrollToTop(true);
          }
        }
        foundFolder.lastModified = created;
        clonedDirectories[index] = foundFolder;
        return clonedDirectories;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMaterialMoved = useCallback((detail) => {
    console.log('@@@@onMaterialMoved: ', detail);
    if (detail) {
      const { materialId, resourceId, directoryId, newDirectoryId, lastModified } = detail;
      setFlatDirectories((prevState) => {
        if (!prevState) return [];
        const fromFolderIndex = prevState.findIndex((d) => d.id === directoryId);
        const newFolderIndex = prevState.findIndex((d) => d.id === newDirectoryId);
        if (fromFolderIndex < 0 || newFolderIndex < 0) {
          return prevState;
        }
        const clonedDirectories = prevState.slice();
        const foundFromFolder = clonedDirectories[fromFolderIndex];
        const foundToFolder = clonedDirectories[newFolderIndex];
        const foundMaterialIndex = foundFromFolder.spaceResources.findIndex(
          (m) => m.resourceId === resourceId
        );
        if (foundMaterialIndex !== -1) {
          const addedMaterial = foundFromFolder.spaceResources[foundMaterialIndex];
          addedMaterial.lastModified = lastModified;
          if (!foundToFolder.spaceResources) {
            foundToFolder.spaceResources = [addedMaterial];
          } else {
            const materialIndex = foundToFolder.spaceResources.findIndex(
              (m) => m.id === materialId
            );
            if (materialIndex < 0) {
              addedMaterial.order = -(foundToFolder.spaceResources.length + 1); // a trick to be on top.
              foundToFolder.spaceResources.push(addedMaterial);
            }
          }
          foundToFolder.lastModified = lastModified;
          clonedDirectories[newFolderIndex] = foundToFolder;

          foundFromFolder.spaceResources = foundFromFolder.spaceResources.filter(
            (item) => item.id !== materialId
          );
          clonedDirectories[fromFolderIndex] = foundFromFolder;

          return clonedDirectories;
        }
        return prevState;
      });
    }
  }, []);

  const onFolderAdded = useCallback((detail) => {
    console.log('onFolderAdded: ', detail);
    if (detail) {
      setFlatDirectories((prevData) => {
        const newData = prevData ? prevData.slice() : [];
        newData.push(detail);
        const parentFolderId = detail.parentFolderId;
        const parentFolderIndex = newData.findIndex((item) => item.id === parentFolderId);
        if (parentFolderIndex !== -1) {
          newData[parentFolderIndex] = { ...newData[parentFolderIndex], subFolders: [] };
        }
        return newData;
      });
    }
  }, []);

  const onDirectoryMoved = useCallback(
    (detail) => {
      console.log('@@@@onDirectoryMoved: ', detail);
      if (detail?.spaceId === spaceId) {
        getMaterialsQuery.refetch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [spaceId]
  );

  const updateExternalFolder = useCallback((externalFolder) => {
    if (externalFolder) {
      setFlatDirectories((prevData) => {
        const newData = prevData?.slice() || [];
        const { externalFolderId, name, lastModified, externalFolderSettings } = externalFolder;
        const foundFolders = newData.filter(
          (item) =>
            item.externalFolderSettings != null &&
            item.externalFolderSettings.folderId === externalFolderId
        );
        if (foundFolders.length === 0) {
          return newData;
        }

        foundFolders.forEach((folder) => {
          const newExternalFolderSettings = {
            ...folder.externalFolderSettings,
            webUrl: externalFolderSettings.webUrl,
          };

          Object.assign(folder, {
            name,
            lastModified,
            externalFolderSettings: newExternalFolderSettings,
          });
        });
        return newData;
      });
    }
  }, []);

  const onFolderUpdated = useCallback((detail) => {
    if (detail) {
      console.log('### onFolderUpdated: ', detail);
      setFlatDirectories((prevData) => {
        const newData = prevData?.slice() || [];
        const { name, description, lastModified } = detail;
        const indexFolder = newData.findIndex((item) => item.id === detail.id);
        if (indexFolder !== -1) {
          Object.assign(newData[indexFolder], {
            name,
            description,
            lastModified,
          });
        } else {
          // Refetch to get materials and sub folders for change access level of folder
          getMaterialsQuery.refetch();
        }
        return newData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFolderDeleted = useCallback((detail) => {
    console.log('onFolderDeleted: ', detail);
    if (detail) {
      setFlatDirectories((prevData) => {
        const newData = prevData?.slice() || [];
        const folderIndex = newData.findIndex(
          (folder) => folder.id === detail.directoryId || folder.id === detail.id
        );
        if (folderIndex !== -1) {
          // Reset subFolder for parent folder
          const parentFolderId = newData[folderIndex].parentFolderId;
          if (parentFolderId !== null) {
            const parentFolderIndex = newData.findIndex((item) => item.id === parentFolderId);
            if (parentFolderIndex !== -1) {
              newData[parentFolderIndex] = { ...newData[parentFolderIndex], subFolders: [] };
            }
          }
          newData.splice(folderIndex, 1);
        }
        return newData;
      });
    }
  }, []);

  const onConvertToPDF = useCallback(
    async (convertingMaterial) => {
      if (convertingMaterial?.resourceId) {
        try {
          await convertResource(
            convertingMaterial.spaceId,
            convertingMaterial.directoryId,
            convertingMaterial.id,
            isPortal
          );
          setFlatDirectories((preFlatDirectories) => {
            const newFlatDirectories = preFlatDirectories.slice();
            const foundFolderIndex = newFlatDirectories.findIndex(
              (folder) => folder.id === convertingMaterial.directoryId
            );
            if (foundFolderIndex === -1) return preFlatDirectories;
            const foundFolder = { ...newFlatDirectories[foundFolderIndex] };

            const foundMaterial = foundFolder.spaceResources.find(
              (m) => m.id === convertingMaterial.id
            );
            if (!foundMaterial) return preFlatDirectories;

            foundMaterial.isTranscoded = false;
            foundMaterial.state = ResourceState.Converting;
            newFlatDirectories[foundFolderIndex] = foundFolder;
            return newFlatDirectories;
          });
        } catch (error) {
          console.log('handleConvertToPDFButtonClick', error);
          sendNotification(error.message, { type: 'error' });
        }
      }
    },
    [isPortal]
  );

  const onReConversion = useCallback(
    async (id, directoryId, materialId) => {
      if (materialId) {
        try {
          await convertResource(id, directoryId, materialId, isPortal);
          setFlatDirectories((preFlatDirectories) => {
            const newFlatDirectories = preFlatDirectories.slice();
            const foundFolderIndex = newFlatDirectories.findIndex(
              (folder) => folder.id === directoryId
            );
            if (foundFolderIndex === -1) return preFlatDirectories;
            const foundFolder = { ...newFlatDirectories[foundFolderIndex] };

            const foundMaterial = foundFolder.spaceResources.find((m) => m.id === materialId);
            if (!foundMaterial) return preFlatDirectories;

            foundMaterial.isTranscoded = false;
            foundMaterial.state = ResourceState.Converting;
            newFlatDirectories[foundFolderIndex] = foundFolder;
            return newFlatDirectories;
          });
        } catch (error) {
          console.log('handleConvertToPDFButtonClick', error);
          sendNotification(error.message, { type: 'error' });
        }
      }
    },
    [isPortal]
  );

  return {
    flatDirectories,
    onMaterialUpdated,
    onMaterialDeleted,
    onConversionFinished,
    onTranscodingFinished,
    onMaterialAdded,
    onFolderAdded,
    onFolderUpdated,
    onFolderDeleted,
    onConvertToPDF,
    onReConversion,
    onConversionStarted,
    onConversionFailed,
    processingResources,
    onMaterialMoved,
    onDirectoryMoved,
    setFlatDirectories,
    updateExternalFolder,
  };
};

export default useFlatDirectories;
