import React from 'react';
import FolderIcon from '@mui/icons-material/Folder';

import ResponsiveDialog from '../../components/ResponsiveDialog';

import Tabs, { useTabs } from '../../components/Tabs';
import i18n from '../../i18n';
import ResourceById from './ResourceById';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import { MaterialBasicInfo, ResourceMetricsDataItem } from '../types/resources';
import NoSpaceAnalyticsData from '../components/NoSpaceAnalyticsData';
import './style.scss';
import SpaceTag from '../../components/SpaceTag/SpaceTag';
import ResourceItemByType from '../components/ResourceItemByType';
import { ResourceType } from '../../app/appConstants';
import { isIntegrationResource } from '../../integrations/integrationResourceUtils';

export const SpaceResourceDetailsTab = {
  VIEW: 0,
  SHARES: 1,
  DOWNLOADS: 2,
};

const getContextTab = (
  activeTab: number,
  resource: ResourceMetricsDataItem<MaterialBasicInfo> | undefined
) => {
  let countNumber = 0;
  let nameContext = '';
  if (resource) {
    switch (activeTab) {
      case SpaceResourceDetailsTab.VIEW:
        countNumber = resource?.clicks;
        nameContext = 'clicked';
        break;
      case SpaceResourceDetailsTab.SHARES:
        countNumber = resource?.shares;
        nameContext = 'shared';
        break;
      default:
        countNumber = resource?.downloads;
        nameContext = 'downloaded';
        break;
    }
  }
  return {
    countNumber,
    nameContext,
  };
};

type SpaceResourceDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  defaultTab: (typeof SpaceResourceDetailsTab)[keyof typeof SpaceResourceDetailsTab];
};

function SpaceResourceDetailsModal(props: SpaceResourceDetailsModalProps) {
  const { open, onClose, defaultTab } = props;
  const { selectedResource } = useSpaceAnalyticsContext();
  const shouldShowClicks =
    selectedResource?.isExternal ||
    !selectedResource?.isViewNotSupported ||
    (selectedResource?.isViewNotSupported && selectedResource?.isExternal);
  const { tabs, changeTab, activeTab } = useTabs(
    [
      shouldShowClicks ? i18n.t('Clicks') : '',
      i18n.t('Shares'),
      selectedResource?.resourceType !== ResourceType.vaam && !selectedResource?.isExternal
        ? i18n.t('Downloads')
        : '',
    ],
    defaultTab
  );
  const { countNumber, nameContext } = getContextTab(activeTab, selectedResource);
  const shoulShowTabs =
    !selectedResource?.isCmsFile && !isIntegrationResource(selectedResource?.src);

  function handleOnChangeTab(value: string) {
    changeTab(value);
  }

  function renderHeader() {
    return (
      <div className={`header ${!shoulShowTabs ? 'bottom-line' : ''}`}>
        <div className="resource-name-wrapper">
          <ResourceItemByType material={selectedResource} />
          {selectedResource?.tag && <SpaceTag colorId={15} name={selectedResource?.tag} />}
        </div>
        {shoulShowTabs && <Tabs tabs={tabs} onChange={handleOnChangeTab} variant="tabs-level-2" />}
      </div>
    );
  }

  function renderActiveTab() {
    if (
      countNumber === 0 &&
      !selectedResource?.isCmsFile &&
      selectedResource?.resourceType !== ResourceType.pdf
    ) {
      return (
        <div className="no-data-container">
          <NoSpaceAnalyticsData icon={<FolderIcon />} message={i18n.t('No data to show')} />
        </div>
      );
    }
    return <ResourceById displayWith={activeTab} />;
  }

  function renderMessage() {
    if (countNumber === 0) {
      return (
        <p className="short-description">
          The file has been {nameContext} <b>0</b> times.
        </p>
      );
    }
    return (
      <p className={`short-description ${!shoulShowTabs ? 'pt-0' : ''}`}>
        This resource has been {nameContext} <b>{countNumber}</b> time{countNumber === 1 ? '' : 's'}
        .
      </p>
    );
  }

  function renderContent() {
    return (
      <div className="tab-content">
        {renderMessage()}
        {renderActiveTab()}
      </div>
    );
  }

  if (!open) {
    return null;
  }

  return (
    <ResponsiveDialog
      modal
      maxWidth="lg"
      className="white confirm-dialog space-users-details-modal resource-details-modal"
      onClose={onClose}
    >
      {renderHeader()}
      {renderContent()}
    </ResponsiveDialog>
  );
}

export default React.memo(SpaceResourceDetailsModal);
