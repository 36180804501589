import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import queryCache, { CacheKeys, clearCache } from '../app/queryCache';
import spaceUser from '../spaces/spaceUser';
import { useAppContext } from '../components/AppProvider';

const DeletedUserEventHandler = (props) => {
  const { connection, spaceUserInfoId } = props;
  const { userInfo } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();
  const isCurrentUserDeleted = useRef(false);

  // console.log('### DeletedUserEventHandler', userInfo, spaceUserInfoId);

  useEffect(() => {
    function clearCacheInSpacePage(spaceId) {
      clearCache([CacheKeys.fetchSpacesList], false);
      clearCache([CacheKeys.fetchSpaceTemplates], false);
      clearCache([CacheKeys.getSpaceAccessToken, spaceId]);
      clearCache([CacheKeys.fetchSpaceAcessInfo], false);
      queryCache.removeQueries({
        queryKey: [CacheKeys.fetchSpacesList],
        exact: false,
      });
      if (userInfo?.orgRole === 'Partner') {
        queryCache.removeQueries({ queryKey: [CacheKeys.fetchBasicSpacesList], exact: true });
        localStorage.removeItem('redirectedToSpace');
      }
      navigate('/');
    }
    async function handleParticipantDeletedEvent(message) {
      console.log('### DeletedUserEventHandler SpaceUserDeletedEvent', message);
      if (isCurrentUserDeleted.current === true) {
        return;
      }
      queryCache.removeQueries({ queryKey: [CacheKeys.fetchSpaceUsers], exact: false });
      const { userInfoId, spaceId } = message;
      if (userInfoId === userInfo?.id) {
        clearCacheInSpacePage(spaceId);
        spaceUser.reset();
      } else if (userInfoId === spaceUserInfoId) {
        // runtime
        queryCache.removeQueries({
          queryKey: [CacheKeys.getSpaceAccessToken, spaceUser.getSpaceId(), spaceUser.getInvc()],
          exact: true,
        });
        spaceUser.reset();
        navigate({
          replace: true,
          path: location.pathname,
          state: { ...location.state, spaceUserHasBeenDeleted: true },
        });
      }
    }

    if (connection) {
      connection.on('SpaceUserDeletedEvent', handleParticipantDeletedEvent);
    }
    return () => {
      if (connection) {
        connection.off('SpaceUserDeletedEvent', handleParticipantDeletedEvent);
      }
    };
  }, [connection, navigate, userInfo, spaceUserInfoId, location]);

  return null;
};

DeletedUserEventHandler.propTypes = {
  connection: PropTypes.instanceOf(Object),
  spaceUserInfoId: PropTypes.number,
};

export default DeletedUserEventHandler;
