import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import authService from '../components/api-authorization/AuthorizeService';
import Loading from './Loading';
import useMobileQuery from '../commons/useMobileQuery';
import DownloadResourceHandler from '../resources/Download/DownloadResourceHandler';
import SignalRHandler from '../app/SignalRHandler';
import NotificationSignalRHandler from '../app/NotificationSignalRHandler';
import { AppContext, useAppContext } from '../commons/AppContext';

const getAppContext = memoizeOne(
  (isAuthenticated, userInfo, isPortal, integrationSignalRConnection, portalHubConnection) => {
    return {
      isAuthenticated,
      userInfo,
      isPortal,
      integrationSignalRConnection,
      portalHubConnection,
    };
  }
);

export { AppContext, useAppContext };

/*
  NOTE: Be careful when adding changes in this component because if this component re-render -> SpaceContainer will be re-rendered -> users in meeting will be kicked out
*/
const AppProvider = ({ children, isPortal, isRouter, isSpace = false }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [integrationSignalRConnection] = useState(null);
  const [portalHubConnection, setPortalHubConnection] = useState(null);
  const { isMobile, isMobilePortrait, isMobileLandscape, isTablet, isDesktop } = useMobileQuery();
  const shouldPopulateAuthStates = isPortal || isSpace;

  const getIsAuthenticatedQuery = useQuery({
    queryKey: [CacheKeys.getIsAuthenticated],
    queryFn: async () => {
      const authenticated = await authService.isAuthenticated(true);
      return authenticated;
    },
    retry: 3,
    retryDelay: () => 5000,
    enabled: !!shouldPopulateAuthStates,
  });

  const getUserInfoQuery = useQuery({
    queryKey: [CacheKeys.getUserInfo],
    queryFn: async () => {
      const resp = await authService.getUserInfo(true);
      return resp;
    },
    retry: 3,
    retryDelay: () => 5000,
    enabled: !!isAuthenticated,
  });

  useEffect(() => {
    const authenticated = getIsAuthenticatedQuery.data;
    if (authenticated !== undefined) {
      if (!authenticated) {
        if (isPortal) {
          authService.signIn();
        }
      }
      setIsAuthenticated(authenticated);
    }
  }, [getIsAuthenticatedQuery.data, isPortal]);

  useEffect(() => {
    const userInfoResp = getUserInfoQuery.data;
    if (userInfoResp !== undefined) {
      if (userInfoResp) {
        setUserInfo(userInfoResp);
        setIsLoading(false);
      }
    }
  }, [getUserInfoQuery]);

  // const handleOnIntegrationSignalRConnected = React.useCallback((connection) => {
  //   if (connection) {
  //     setIntegrationSignalRConnection(connection);
  //   }
  // }, []);

  function handlePortalHubConnected(connection) {
    console.log('connection: portalHubConnection', connection);
    if (connection) {
      setPortalHubConnection(connection);
    }
  }
  console.log('userInfo: ', userInfo);
  const context = getAppContext(
    isAuthenticated,
    userInfo,
    isPortal,
    integrationSignalRConnection,
    portalHubConnection
  );
  context.isMobile = isMobile;
  context.isMobilePortrait = isMobilePortrait;
  context.isMobileLandscape = isMobileLandscape;
  context.isTablet = isTablet;
  context.isDesktop = isDesktop;

  context.updateUserInfo = useCallback(
    (data) => {
      if (data) {
        setUserInfo({
          ...userInfo,
          firstName: data.firstName,
          fullName: data.fullName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
        });
      }
    },
    [userInfo]
  );

  function renderChildren() {
    if (isPortal && isLoading) {
      return (
        <div className="authorization-container">
          <Loading />
        </div>
      );
    }
    return children;
  }

  return (
    <AppContext.Provider value={context}>
      {renderChildren()}
      <DownloadResourceHandler isPortal={isPortal} />
      {isAuthenticated && isRouter && userInfo?.organizationId && (
        <>
          <SignalRHandler
            organizationId={userInfo?.organizationId}
            onConnected={handlePortalHubConnected}
          />
          <NotificationSignalRHandler />
        </>
      )}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  isPortal: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
  isSpace: PropTypes.bool,
  isRouter: PropTypes.bool,
};

export default AppProvider;
