import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import Box from '@mui/material/Box';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// import ArrowButton from '../ArrowButton/ArrowButton';
import './TiffViewer.scss';
import Loading from '../Loading';
import useTiffFile from './useTiffFile';
// import ImagePlaceholder from '../ImagePlaceholder';

// ref: https://github.com/harundogdu/react-tiff/blob/master/src/index.js
const TiffViewer = (props) => {
  const { src, className, alt, renderAsImgTag, resourceId } = props;

  // states
  const [imgSrc, setImgSrc] = React.useState('');
  // const [page, setPage] = React.useState(0);

  // refs
  // const canvasRef = React.useRef(null);

  const { pages, tiffQuery } = useTiffFile(src, resourceId);

  useEffect(() => {
    if (pages?.length > 0) {
      const pageCanvas = pages[0];
      const base64PNGUrl = pageCanvas.toDataURL(); // PNG is the default; or "image/jpeg"
      setImgSrc(base64PNGUrl);
    }
  }, [pages]);

  // useEffect(() => {
  //   if (canvasRef.current && pages.length > 0) {
  //     canvasRef.current.innerHTML = '';
  //     canvasRef.current.appendChild(pages[page]);
  //   }
  // }, [page, pages]);

  // const handlePreviousClick = () => {
  //   if (page > 0) {
  //     setPage(page - 1);
  //   }
  // };

  // const handleNextClick = () => {
  //   if (page < pages.length - 1) {
  //     setPage(page + 1);
  //   }
  // };

  // function renderContent() {
  //   if (tiffQuery.isFetching) {
  //     return <Loading />;
  //   }
  //   return (
  //     <>
  //       <div className="viewer" ref={canvasRef} />
  //       {pages?.length > 1 && (
  //         <div className="buttons">
  //           <ArrowButton icon={<KeyboardArrowLeft />} onClick={handlePreviousClick} />
  //           <ArrowButton icon={<KeyboardArrowRight />} onClick={handleNextClick} />
  //         </div>
  //       )}
  //     </>
  //   );
  // }

  // return (
  //   <div className={`tiff-viewer ${className}`}>
  //     <div className="container">{renderContent()}</div>
  //   </div>
  // );

  if (tiffQuery.isFetching) {
    if (renderAsImgTag) {
      return <Loading className="tiff-viewer-loading" />;
    }
    return (
      <div className={`tiff-viewer ${className}`}>
        <Loading />
      </div>
    );
  }

  if (renderAsImgTag) {
    return <img src={imgSrc} alt={alt} className={className} />;
  }

  return <div className={className} style={{ backgroundImage: `url(${imgSrc})` }} />;
};

TiffViewer.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  renderAsImgTag: PropTypes.bool,
  resourceId: PropTypes.number,
};

TiffViewer.defaultProps = {
  renderAsImgTag: false,
};

export default TiffViewer;
