import Api from '../../commons/api';
import { clearCache, CacheKeys } from '../../app/queryCache';
import {
  getCoreRuntimeApi,
  getNotificationApi,
  getNotificationRuntimeApi,
} from '../../commons/utils';
import RuntimeApi from '../../commons/RuntimeApi';

export function fetchNotificationSetting() {
  return Api.get(`${getNotificationApi()}/users/settings`);
}

export function updateNotificationSetting(data) {
  return Api.put(`${getNotificationApi()}/users/settings`, data);
}

export function updateNotificationRules(data) {
  return Api.put(`${getNotificationApi()}/users/settings/rules`, data);
}

export function fetchNotifications(page = 1, pageSize = 20) {
  return Api.get(
    `${getNotificationApi()}/users/notifications/new-updates?page=${page}&pageSize=${pageSize}`
  );
}

export function requestAccessSpace(spaceId, payload) {
  return RuntimeApi.post(
    `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/request-access`,
    payload
  );
}

export function fetchUserNewMessages(page = 1, pageSize = 10) {
  return Api.get(
    `${getNotificationApi()}/users/notifications/new-messages?page=${page}&pageSize=${pageSize}`
  );
}

export async function updateNotificationsSeen(data) {
  const resp = await Api.put(`${getNotificationApi()}/users/notifications/read`, data);
  clearCache([CacheKeys.fetchNotificationsBadges], { exact: true });
  clearCache([CacheKeys.fetchUserNotifications], { exact: true });
  clearCache([CacheKeys.fetchUserNewMessages], { exact: true });
  return resp;
}

export function updateNotificationsSeenAll(data) {
  return Api.put(`${getNotificationApi()}/users/notifications/new-updates/read-all`, data);
}

export function updateMessagesSeenAll(data) {
  return Api.put(`${getNotificationApi()}/users/notifications/new-messages/read-all`, data);
}

export function getNotificationsBadges() {
  return Api.get(`${getNotificationApi()}/users/notifications/badges`);
}

export function updateNotificationsBadges(data) {
  return Api.put(`${getNotificationApi()}/users/notifications/badges/seen`, data);
}

// export function getNotificationsBadgesBySpaceId(spaceId) {
//   return Api.get(`${getNotificationApi()}/users/notifications/badges/spaces/${spaceId}`);
// }

export function getNotificationsBadgesBySpaceId(spaceId) {
  return RuntimeApi.get(
    `${getNotificationRuntimeApi()}/users/notifications/badges/spaces/${spaceId}`
  );
}

export function updateNotificationsBadgesBySpaceId(spaceId, data) {
  return RuntimeApi.put(
    `${getNotificationRuntimeApi()}/users/notifications/badges/spaces/${spaceId}/seen`,
    data
  );
}

export function updateNotificationRead(spaceId, data) {
  return RuntimeApi.put(
    `${getNotificationRuntimeApi()}/users/notifications/spaces/${spaceId}/details/read`,
    data
  );
}

export function fetchNotificationSettingForParticipants() {
  return Api.get(`${getNotificationApi()}/my-participants/settings`);
}

export function updateNotificationSettingForParticipants(data) {
  return Api.put(`${getNotificationApi()}/my-participants/settings/rules`, data);
}

export function fetchNotificationSettingOfParticipants(spaceId) {
  return RuntimeApi.get(
    `${getNotificationRuntimeApi()}/users/notifications/spaces/${spaceId}/settings`
  );
}

export function updateNotificationSettingOfParticipants(spaceId, data) {
  return RuntimeApi.put(
    `${getNotificationRuntimeApi()}/users/notifications/spaces/${spaceId}/settings/rules`,
    data
  );
}

export function fetchSpaceNotificationSettings(spaceId) {
  return Api.get(`${getNotificationApi()}/my-spaces/${spaceId}/settings`);
}
