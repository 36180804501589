import React, { useCallback, useMemo } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { getComparator, stableSort } from '../function';
import { useMultipleSelectResourcesContext } from '../MultipleSelectResourcesContextProvider';
import { useSpaceContext } from '../../../spaces/SpaceContext';
import { FeatureFlagsType, ResourceState, ResourceType } from '../../../app/appConstants';
import { canViewIntegrationResource } from '../../../integrations/integrationResourceUtils';
import { useFeatureFlagsContext } from '../../../commons/FeatureFlags/FeatureFlagsContextProvider';
import { isExternalLinkNotVideo, resourcesOfFolder } from '../../../commons/ResourceUtils';
import { useResourcesViewContext } from '../../ResourcesView/ResourcesViewContextProvider';
import ResourcesListView from '../ResourcesListView/ResourcesListView';
import ResourcesGridView from '../ResourcesGridView/ResourcesGridView';
import { ViewModeType } from '../../../resources/resourceConstants';
import { useMaterialsContext } from '../../../spaces/Materials/MaterialsContextProvider';

const getResources = memoizeOne((data, order, orderBy) => {
  const isFolder = data.isFolder || data.spaceResources;

  return stableSort(isFolder ? resourcesOfFolder(data) : data, getComparator(order, orderBy));
});

const ResourcesDisplayView = (props) => {
  const {
    data,
    isMobile,
    isPortal,
    selectedFile,
    loadMoreButton,
    spaceFolderId,
    onClickFile,
    onClickFolder,
  } = props;

  const {
    selectedSmartFiles,
    selectedSmartFolders,
    selectedFolderIds,
    selectedFileIds,
    handleOnSelectAll,
    handleOnChangeCheckBoxFile,
    handleOnChangeCheckBoxFolder,
    resetSelected,
  } = useMultipleSelectResourcesContext();
  const { newNotifications } = useSpaceContext();
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledMaterialSlider] = checkEnabledFeatures([FeatureFlagsType.MATERIAL_SLIDER]);
  const { viewMode, order, orderBy, setSortData } = useResourcesViewContext();
  const { updateExternalFolder } = useMaterialsContext();

  const newFiles = useMemo(
    () => newNotifications?.Resources?.details?.newMaterials || [],
    [newNotifications?.Resources?.details?.newMaterials]
  );
  const resources = getResources(data, order, orderBy);
  // const resources = useMemo(() => {
  //   console.log('data: ', data);

  //   const sortedItems = stableSort(
  //     data.isFolder ? resourcesOfFolder(data) : data,
  //     getComparator(order, orderBy)
  //   );

  //   return sortedItems;
  // }, [data, order, orderBy]);

  const selected = useMemo(() => {
    const smartFileIds = selectedSmartFiles?.map((file) => file.id) || [];
    const smartFolderIds = selectedSmartFolders?.map((folder) => folder.directoryId) || [];
    const res = [...selectedFolderIds, ...selectedFileIds, ...smartFolderIds, ...smartFileIds];
    return res;
  }, [selectedFileIds, selectedFolderIds, selectedSmartFiles, selectedSmartFolders]);

  const handleSelectAllClick = useCallback(
    (event) => {
      const folders = resources.filter((i) => i.isFolder);
      const files = resources.filter((i) => !i.isFolder);
      if (event.target.checked) {
        handleOnSelectAll(folders, files);
      } else {
        resetSelected();
      }
    },
    [handleOnSelectAll, resetSelected, resources]
  );

  const setSliderMaterials = useCallback(() => {
    if (isEnabledMaterialSlider) {
      const viewableMaterials = resources?.filter(
        (m) =>
          m.state !== ResourceState.Transcoding &&
          m.state !== ResourceState.Converting &&
          m.state !== ResourceState.TranscodingFailed &&
          m.state !== ResourceState.ConversionFailed &&
          !isExternalLinkNotVideo(m)
      );

      sessionStorage.setItem(
        'selectedFolderMaterials',
        JSON.stringify({
          spaceFolderId: spaceFolderId,
          materials: viewableMaterials,
        })
      );
    }
  }, [isEnabledMaterialSlider, resources, spaceFolderId]);

  const handleSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setSortData({ orderBy: property, order: isAsc ? 'desc' : 'asc' });
      setSliderMaterials();
    },
    [order, orderBy, setSliderMaterials, setSortData]
  );

  const handleOnClick = useCallback(
    (event, resource) => {
      if (resource.isFolder) {
        onClickFolder(resource);
      } else {
        setSliderMaterials();
        onClickFile(resource);
      }
    },
    [onClickFile, onClickFolder, setSliderMaterials]
  );

  const handleClickCheckbox = useCallback(
    (checked, resource) => {
      if (!resource.isFolder) {
        handleOnChangeCheckBoxFile(checked, resource);
      } else {
        handleOnChangeCheckBoxFolder(checked, resource);
      }
    },
    [handleOnChangeCheckBoxFile, handleOnChangeCheckBoxFolder]
  );

  const onDriveInfoUpdated = React.useCallback(
    (folder) => {
      updateExternalFolder(folder);
    },
    [updateExternalFolder]
  );

  const canDragDropFile = useCallback(
    (resource) => {
      if (resource.isFolder || !props.canDragDrop) {
        return false;
      }
      let shouldConvertToPDF;
      if (props.isWebinar) {
        shouldConvertToPDF = resource.shouldConvertToPDF;
      } else {
        shouldConvertToPDF =
          resource.state !== ResourceState.Converted &&
          (resource.type === ResourceType.cad || resource.type === ResourceType.keynote);
      }
      // Exception for AVI file from OneDrive (temporarily)
      const viewIsNotSupported = !canViewIntegrationResource(resource.src);
      const canDragFile =
        resource.type !== ResourceType.zip &&
        resource.type !== ResourceType.font &&
        resource.type !== ResourceType.eps &&
        resource.type !== ResourceType.ai &&
        resource.type !== ResourceType.indd &&
        resource.type !== ResourceType.psd &&
        resource.type !== ResourceType.dcm &&
        resource.type !== ResourceType.vaam;
      return !shouldConvertToPDF && !viewIsNotSupported && canDragFile;
    },
    [props.canDragDrop, props.isWebinar]
  );

  if (!resources.length) {
    return null;
  }

  if (viewMode === ViewModeType.LIST) {
    return (
      <ResourcesListView
        resources={resources}
        isMobile={isMobile}
        handleSelectAllClick={handleSelectAllClick}
        selected={selected}
        order={order}
        orderBy={orderBy}
        handleSort={handleSort}
        canDragDropFile={canDragDropFile}
        selectedFile={selectedFile}
        newFiles={newFiles}
        loadMoreButton={loadMoreButton}
        isWebinar={props.isWebinar}
        showActionMenu={props.showActionMenu}
        disabledMoreButton={props.disabledMoreButton}
        processingResources={props.processingResources}
        newResourceId={props.newResourceId}
        isViewOnly={props.isViewOnly}
        onClickMoreButton={props.onClickMoreButton}
        onClick={handleOnClick}
        onClickCheckbox={handleClickCheckbox}
        isPortal={isPortal}
        onDriveInfoUpdated={onDriveInfoUpdated}
      />
    );
  }

  return (
    <ResourcesGridView
      resources={resources}
      isMobile={isMobile}
      handleSelectAllClick={handleSelectAllClick}
      selected={selected}
      order={order}
      orderBy={orderBy}
      handleSort={handleSort}
      canDragDropFile={canDragDropFile}
      selectedFile={selectedFile}
      newFiles={newFiles}
      loadMoreButton={loadMoreButton}
      isWebinar={props.isWebinar}
      showActionMenu={props.showActionMenu}
      disabledMoreButton={props.disabledMoreButton}
      processingResources={props.processingResources}
      newResourceId={props.newResourceId}
      isViewOnly={props.isViewOnly}
      onClickMoreButton={props.onClickMoreButton}
      onClick={handleOnClick}
      onClickCheckbox={handleClickCheckbox}
      isPortal={isPortal}
      onDriveInfoUpdated={onDriveInfoUpdated}
    />
  );
};

ResourcesDisplayView.propTypes = {
  data: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.instanceOf(Object)]),
  onClickFolder: PropTypes.func,
  onClickFile: PropTypes.func,
  onClickMoreButton: PropTypes.func,
  showActionMenu: PropTypes.bool,
  disabledMoreButton: PropTypes.bool,
  processingResources: PropTypes.instanceOf(Array),
  isWebinar: PropTypes.bool,
  canDragDrop: PropTypes.bool,
  newResourceId: PropTypes.number,
  isMobile: PropTypes.bool,
  isPortal: PropTypes.bool,
  selectedFile: PropTypes.instanceOf(Object),
  loadMoreButton: PropTypes.instanceOf(Object),
  isViewOnly: PropTypes.bool,
  spaceFolderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ResourcesDisplayView.defaultProps = {
  onClickFolder: () => {},
  onClickFile: () => {},
  onClickMoreButton: () => {},
  showActionMenu: true,
  disabledMoreButton: false,
  isViewOnly: false,
};

export default ResourcesDisplayView;
