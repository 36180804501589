import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import DndFileUploader from '../../components/DndFileUploader3/DndFileUploader';
import { DefaultResourceAccept, UploadingFileState } from '../../app/appConstants';
import i18n from '../../i18n';
import { isValidUrl } from '../../commons/ValidationUtils';
import { getResourceFileType, validateFile } from '../../commons/ResourceUtils';
import './FileForm.scss';
import authService from '../../components/api-authorization/AuthorizeService';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import { DefaultUploaderTooltip } from '../../components/DndFolderUploader/UploaderLabel';
import OfficialButton from '../../components/OfficialButtons';

const DefaultMaxFiles = 15;

function validateInput(name, value, props) {
  if (!name) return null;
  if (name === 'url') {
    const text = value?.trim();
    if (!text) return null;
    const isValid = isValidUrl(text);
    if (!isValid) {
      return { type: 'pattern' };
    }

    // const lowerCaseText = text.toLowerCase();
    // const isVideo = isVideoResource(lowerCaseText);
    // if (!isVideo) {
    //   return { type: 'incompatible', canSubmit: true };
    // }
  }
  if (name === 'name') {
    if (!value || !value.trim()) {
      return { type: 'required' };
    }
  }
  if (name === 'file') {
    const file = value;
    const resourceType = getResourceFileType(file);
    if (resourceType === null) {
      return { type: 'unsupported' };
    }

    const accept = props?.accept || DefaultResourceAccept;
    const isValid = validateFile(accept, file);
    if (!isValid) {
      return { type: 'unaccepted' };
    }

    // const isPPT = resourceType === ResourceType.powerpoint;
    const defaultMaxFileSize = 5000; // 5gb
    const maxFileSizeMsg = i18n.t('The file exceeds the maximum file size 5 GB.');
    // if (isPPT) {
    //   defaultMaxFileSize = 50; // 50mb
    //   maxFileSizeMsg = i18n.t('Sorry, PPT file size must be less than 50MB.');
    // }
    const maxFileSize = props?.maxFileSize || defaultMaxFileSize;
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > maxFileSize) {
      return { type: 'maxFileSize', message: maxFileSizeMsg };
    }
  }

  if (name === 'files') {
    // used for checking the number of picked files
    const files = value;
    const maxFiles = props?.multiple ? props?.maxFiles || DefaultMaxFiles : 1;
    if (files.length > maxFiles) {
      return { type: 'maxFiles' };
    }
  }
  return null;
}

function renderWarningMessage(warnings, property) {
  if (warnings && warnings[property]) {
    if (warnings[property].type === 'incompatible') {
      return i18n.t(
        "Please note that this website doesn't support iframe which means it can't be dragged and dropped into a meeting for everyone to see. Users can still view it individually."
      );
    }
  }
  return null;
}

const FileForm = forwardRef((props, ref) => {
  const {
    file,
    onSave,
    processing,
    isGlobalResourcesEnabled,
    spacePrivacy,
    isPortal,
    isAdmin,
    isPartner,
  } = props;
  const [selectedResources, setSelectedResources] = useState([]);
  const [uploaderErrors, setUploaderErrors] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasChangesForm, setHasChangesForm] = useState(false);
  const [showNoResourceErrorMessage, setShowNoResourceErrorMessage] = useState(false);

  const uploaderRef = React.useRef();
  const hasNoResource = !selectedResources?.length;

  React.useEffect(() => {
    async function loadAuthenticated() {
      const authenticated = await authService.isAuthenticated();
      setIsAuthenticated(authenticated);
    }
    loadAuthenticated();
  }, []);

  function handleResourceChange(newResourceList) {
    setSelectedResources(newResourceList);
    if (newResourceList?.length > 0) {
      setShowNoResourceErrorMessage(false);
    }
  }

  function handleOnChangeName() {
    setHasChangesForm(true);
  }

  function handleUploaderError(newErrors) {
    setUploaderErrors(newErrors);
  }

  async function handleOnSave() {
    const showErrMsg = hasNoResource;
    setShowNoResourceErrorMessage(showErrMsg);
    if (!!uploaderErrors || hasNoResource) {
      return false;
    }
    uploaderRef.current?.onReset();

    const result = await onSave(selectedResources);
    setSelectedResources([]);
    return result;
  }

  function handleUploadingStateChange(newState) {
    let uploading = false;
    if (newState <= UploadingFileState.Started) {
      uploading = true;
      setHasChangesForm(true);
      setShowNoResourceErrorMessage(false);
    }
    setIsUploading(uploading);
  }

  async function handleCloseForm() {
    let isCloseForm = true;
    if (hasChangesForm) {
      const { isSaved, discard } = await UnsavedChangesDialog.show(
        i18n.t('UNSAVED CHANGES'),
        <>
          {i18n.t(`You have made changes.`)}
          <br />
          {i18n.t(`Would you like to save?`)}
        </>
      );

      isCloseForm = discard;
      if (isSaved) {
        const result = await handleOnSave();
        if (result) {
          isCloseForm = true;
        } else {
          isCloseForm = false;
        }
      }
    }
    return isCloseForm;
  }

  React.useImperativeHandle(ref, () => ({
    handleCloseForm,
  }));

  const errMsg =
    hasNoResource && uploaderRef.current?.state?.invalidResources?.length > 0
      ? i18n.t('Cannot add files. Please try again.')
      : i18n.t('Enter a website link or upload a file to proceed.');
  // console.log('### FileForm render', uploaderRef.current?.state);
  // console.log('### FileForm render', isUploading, uploaderErrors, selectedResources);

  return (
    <>
      <section className="styled-scrollbar">
        <section className="middle-section displayFlex column-direction gap16">
          {showNoResourceErrorMessage && <span className="no-resource-msg">{errMsg}</span>}
          <div className="uploader-area">
            <DndFileUploader
              ref={uploaderRef}
              accept={isPortal ? DefaultResourceAccept : '*'}
              spacePrivacy={spacePrivacy}
              spaceId={props.spaceId}
              fileNameInputLabel={i18n.t('Show as')}
              fileInputLabel="Add file"
              fileInputInfo={DefaultUploaderTooltip}
              isPortal={isPortal}
              resourceId={file?.resourceId}
              onChange={handleResourceChange}
              onChangeName={handleOnChangeName}
              onError={handleUploaderError}
              validationMethod={validateInput}
              renderWarningMessageMethod={renderWarningMessage}
              multiple
              canPreview
              isGlobalResourcesEnabled={isGlobalResourcesEnabled && isAuthenticated && !isPartner}
              overwrittenResourceName={file?.resourceName || file?.displayName}
              onUploadingStateChange={handleUploadingStateChange}
              isAdmin={isAdmin} // isAdmin from props = isHost || isAdmin (in MaterialsContextProvider)
              disabledUploadExternalFiles
            />
          </div>
        </section>
      </section>
      <div className="buttons">
        <OfficialButton
          fullWidth
          variant="regular-green"
          label={file?.resourceId ? i18n.t('save') : i18n.t('Add')}
          onClick={handleOnSave}
          disabled={isUploading}
          isProcessing={processing}
        />
      </div>
    </>
  );
});

FileForm.propTypes = {
  spaceId: PropTypes.string,
  file: PropTypes.instanceOf(Object),
  processing: PropTypes.bool,
  onSave: PropTypes.func,
  isGlobalResourcesEnabled: PropTypes.bool,
  spacePrivacy: PropTypes.string,
  isPortal: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isPartner: PropTypes.bool,
};

FileForm.defaultProps = {
  isGlobalResourcesEnabled: false,
  isAdmin: false,
  isPartner: false,
};

export default FileForm;
