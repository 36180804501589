/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { useMaterialsContext } from '../../spaces/Materials/MaterialsContextProvider';
import i18n from '../../i18n';
import ErrorMessage from '../../components/ErrorMessage';
import LightTooltip from '../../components/LightTooltip';
import { openWindow, sleep } from '../../commons/utils';
import { useThemeSettingsContext } from './context/ThemeSettingsContextProvider';
import ResourcePreviewDialog, {
  getResourcePreviewContext,
} from '../../resources/ResourcePreviewDialog';
import './CmsWidget.scss';
import './ResourceWidget.scss';
import '../cmsStyle.scss';
import { createResourceInteraction } from '../../commons/CommonServices';
import {
  DirectoryType,
  FeatureFlagsType,
  ResourceInteractionType,
  ResourceType,
  SidebarType,
} from '../../app/appConstants';
import { useSpaceContext } from '../../spaces/SpaceContext';
import spaceUser from '../../spaces/spaceUser';
import { getFilteredDirectory, isExternalLinkNotVideo } from '../../commons/ResourceUtils';
import useExtraDirectories from '../../commons/useExtraDirectories';
import MultipleSelectResourcesContextProvider, {
  useMultipleSelectResourcesContext,
} from '../../components/Material/MultipleSelectResourcesContextProvider';
import SelectResourcesActions from '../../spaces/Materials/SelectResourcesActions';
import AddResourceButton from '../../spaces/Materials/AddResourceButton';
import { useMountEffect, useUnmountEffect } from '../../commons/CustomHooks';
import { useFeatureFlagsContext } from '../../commons/FeatureFlags/FeatureFlagsContextProvider';
import usePinFolder from '../../commons/Resources/usePinFolder';
import ActionButtons from '../../commons/Resources/ActionButtons';
import useNotifications from '../../commons/Resources/useNotifications';
import useShareLink from '../../commons/Resources/useShareLink';
import useDownloadResource from '../../resources/Download/useDownloadResource';
import ResourcesDisplayView from '../../components/Material/ResourcesDisplayView/ResourcesDisplayView';
// import { getFilterListByText } from '../../components/Material/function';
import OfficialButton from '../../components/OfficialButtons';
import ExternalDirectory from '../../spaces/Materials/ExternalDirectory';
import eventBus, { EVENT_BUS } from '../../commons/EventBus';
import Loading from '../../components/Loading';
import ResourcesViewControls from '../../components/ResourcesView/ResourcesViewControls';
import ResourcesViewContextProvider from '../../components/ResourcesView/ResourcesViewContextProvider';
import { ResourceSources } from '../../resources/types';
import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';
import useSpaceMaterialsQuery from '../../spaces/Materials/useSpaceMaterialsQuery';

function ResourceWidget(props) {
  const { spaceId, isPortal, isEdit, settings } = props;
  const settingsDirectoryId = settings?.directoryId;
  const folderSettings = settings?.folderSettings;
  const { isFileUploadEnabled, resourceHubConnection } = useSpaceContext();
  const [updateAnchorEl, setUpdateAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(() => null);
  const [selectedFolderForActionMenu, setSelectedFolderForActionMenu] = useState(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const selectActionsRef = useRef();
  const {
    directories,
    flatDirectories,
    onReConversion,
    processingResources,
    openForm,
    handleOpenForm,
    handleCloseForm,
    setFlatDirectories,
    isFetchingMaterials,
  } = useMaterialsContext();
  const hasLoggedIn = spaceUser.hasLoggedIn();
  useSpaceMaterialsQuery(spaceId, isPortal, true, hasLoggedIn);
  const [viewingMaterial, setViewingMaterial] = useState(null);

  const [selectedFolder, setSelectedFolder] = useState(() => {
    if (folderSettings) {
      const { externalFolderSettings, spaceFolderId, externalId, parentFolderId } = folderSettings;
      if (
        flatDirectories &&
        spaceFolderId &&
        flatDirectories.findIndex((item) => item.id === spaceFolderId) === -1
      ) {
        return null;
      }
      return {
        id: settingsDirectoryId,
        spaceId: spaceId,
        spaceFolderId: spaceFolderId,
        isExternal: true,
        externalId: externalId,
        externalFolderSettings: externalFolderSettings,
        spaceResources: [],
        subFolders: [],
        parentFolderId: parentFolderId,
      };
    }
    return null;
  });

  const { rootShareDirectory, getSpaceExtraDirectoriesQuery } = useExtraDirectories(
    flatDirectories,
    spaceId,
    isPortal
  );

  const {
    isSelectedResourceIds,
    selectedFileIds,
    selectedFolderIds,
    selectedSmartFolders,
    selectedSmartFiles,
    handleOnSelectAll,
    handleOnChangeCheckBoxFolder,
    resetSelected,
    canRemoveResources,
    canMoveResources,
    canShowDownloadButton,
    isCheckedAll,
    handleOnChangeCheckBoxFile,
  } = useMultipleSelectResourcesContext();

  const { space, isPublicSpace, setSidebar, isPreview } = useSpaceContext();

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledMaterialSlider] = checkEnabledFeatures([FeatureFlagsType.MATERIAL_SLIDER]);
  const { handleClickDownloadDirectory, handleClickDownloadBulkResources } = useDownloadResource();

  const { handleShareLinkFileClick, handleShareLinkFolderClick } = useShareLink(space);

  const isHost = spaceUser.isHost();

  const { updateBlock, blocks } = useThemeSettingsContext();
  const selectedFolderId = selectedFolder?.id;

  const { isPinned, handlePinButtonClick, handleUnPinButtonClick, getPinTooltip, getUnpinTooltip } =
    usePinFolder(
      settingsDirectoryId,
      updateBlock,
      blocks,
      props.rowId,
      props.id,
      selectedFolder ?? {}
    );

  const directoriesRef = useRef();
  const [newMaterialId, setNewMaterialId] = useState(null);
  const [newFolderId, setNewFolderId] = useState(null);

  const rootFolder = rootShareDirectory;

  const renderPinFolderRef = useRef(false);
  const settingsDirectory = useMemo(() => {
    return flatDirectories?.find((item) => item.id === settingsDirectoryId);
  }, [flatDirectories, settingsDirectoryId]);

  const isRootFolder =
    !selectedFolder || (selectedFolder && selectedFolder.directoryType === DirectoryType.Extra);

  useEffect(() => {
    setNewMaterialId(null);
    setNewFolderId(null);
  }, [selectedFolderId]);

  useUnmountEffect(() => {
    if (openForm) {
      handleCloseForm();
    }
  });

  useEffect(() => {
    if (settingsDirectory && !renderPinFolderRef.current) {
      renderPinFolderRef.current = true;
      setSelectedFolder(settingsDirectory);
    }
  }, [settingsDirectory]);

  useEffect(() => {
    setSelectedFolder((prevState) => {
      if (!prevState) return prevState;
      // in case: add new resource to a folder, that folder should be re-rendered.
      const updatedFolder = flatDirectories?.find((f) => f.id === prevState.id);
      if (updatedFolder) {
        return updatedFolder;
      }
      return prevState;
    });
  }, [flatDirectories]);

  const { updateTotalNotifications, updateFolderNotifications, updateResourceNotifications } =
    useNotifications(spaceId);

  useMountEffect(() => {
    updateTotalNotifications();
  });

  function updateResourceInteraction(material, interactionType) {
    if (material) {
      createResourceInteraction(material.resourceId, {
        materialId: material.id,
        interactionType: interactionType || ResourceInteractionType.Click,
      });
    }
  }

  const handleDirectoryClick = useCallback(
    (folder) => {
      setSelectedFolder(folder);
      if (isPinned && folder.id !== settingsDirectoryId) {
        setShowBackButton(true);
      }
      resetSelected();
      updateFolderNotifications(folder);
      // setSearchText(null);
    },
    [isPinned, settingsDirectoryId, resetSelected, updateFolderNotifications]
  );

  const handleMoreButtonClick = useCallback((event, selectingItem) => {
    setUpdateAnchorEl(event.target);

    const isFolder = !!selectingItem.spaceResources;
    if (isFolder) {
      setSelectedFolderForActionMenu(selectingItem);
      setSelectedFile(null);
    } else {
      setSelectedFile(selectingItem);
      setSelectedFolderForActionMenu(null);
    }
  }, []);

  function handleNewFileButtonClick() {
    setSidebar(SidebarType.Material);
    handleOpenForm('file', null, null, selectedFolderId);
  }

  async function handleNewFolderButtonClick() {
    setSidebar(SidebarType.Material);
    // console.log('selectedFolder', selectedFolder);
    handleOpenForm('folder', null, null, selectedFolderId);
  }

  async function handleUploadFolderButtonClick() {
    setSidebar(SidebarType.Material);
    handleOpenForm('uploadFolder', null, null, selectedFolderId);
  }

  function handleGoBackDirectory() {
    setSelectedFolder((prevState) => {
      if (prevState.parentFolderId === null) {
        return null;
      }
      const parentFolder = flatDirectories.find((item) => item.id === prevState.parentFolderId);
      if (parentFolder?.id === settingsDirectoryId) {
        setShowBackButton(false);
      }
      return parentFolder;
    });
    resetSelected();
    // setSearchText(null);
  }

  function renderLeftHeader() {
    // console.log('selectedFolder', props.widgetName, selectedFolder);
    let title = 'Resources';
    if (props.widgetName) {
      title = props.widgetName;
    }
    if (selectedFolder?.name) {
      title = selectedFolder.name;
    }
    const isShowBackButton = !isPinned || (isPinned && showBackButton);
    const onClick = (event) => {
      event.stopPropagation();
      setSelectedFile(null);
      handleGoBackDirectory();
    };
    const selectedItems =
      (selectedFileIds?.length ?? 0) +
      (selectedFolderIds?.length ?? 0) +
      (selectedSmartFolders?.length ?? 0) +
      (selectedSmartFiles?.length ?? 0);
    return (
      <div className="left-section alignItemsCenter">
        {isShowBackButton && !!selectedFolder && (
          <ArrowBackIosIcon
            fontSize="small"
            style={{ marginRight: 8, cursor: 'pointer' }}
            onClick={onClick}
          />
        )}

        <div className="cms-widget-title">
          {title}
          {isSelectedResourceIds && (
            <div className="cms-widget-info">
              {selectedItems} {i18n.t('selected')}
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderAddResourceButton() {
    const isViewOnly = isPreview || props.isMobile;

    if (!isFileUploadEnabled) {
      return null;
    }
    if (isViewOnly || selectedFolder?.isExternal || isSelectedResourceIds) {
      return null;
    }
    return (
      <div>
        <AddResourceButton
          tooltip={isEdit ? i18n.t('Go back to the space to add files or folders.') : ''}
          onNewFileButtonClick={handleNewFileButtonClick}
          onNewFolderButtonClick={handleNewFolderButtonClick}
          onUploadFolderButtonClick={isHost ? handleUploadFolderButtonClick : null}
          disabled={isEdit}
          variant="rectangle-green"
        />
      </div>
    );
  }

  function renderHeader() {
    const canPin = props.canPin && selectedFolder && !isSelectedResourceIds;
    const isPinnedFolder =
      isPinned && settingsDirectoryId !== null && settingsDirectoryId !== undefined;

    const pinTooltip = getPinTooltip();
    const unpinTooltip = getUnpinTooltip(isPinnedFolder);

    // const items = totalResources === 1 ? 'item,' : 'items,';
    // const showSummary = !selectedFolder;

    return (
      <div className="cms-widget-header">
        <div className="left-section column-direction">{renderLeftHeader()}</div>
        <div className="right-section">
          {renderAddResourceButton()}
          {canPin && (
            <>
              {isPinnedFolder && (
                <LightTooltip title={unpinTooltip} placement="bottom-start">
                  <IconButton onClick={handleUnPinButtonClick}>
                    <span className="icon-pin-off" />
                  </IconButton>
                </LightTooltip>
              )}
              {!isPinned && (
                <LightTooltip title={pinTooltip} placement="bottom-start">
                  <IconButton onClick={handlePinButtonClick}>
                    <span className="icon-pin" />
                  </IconButton>
                </LightTooltip>
              )}
            </>
          )}
          {isSelectedResourceIds && (
            <OfficialButton
              variant="rectangle-secondary"
              label={i18n.t('Cancel')}
              onClick={resetSelected}
            />
          )}
        </div>
      </div>
    );
  }

  function handleOpenFormAction(form) {
    // console.log('### handleOpenFormAction', form, selectedFolderForActionMenu);
    setSidebar(SidebarType.Material);
    resetSelected();
    handleOpenForm(
      form,
      selectedFolderForActionMenu.id,
      null,
      selectedFolderForActionMenu.parentFolderId
    );
  }

  function handleOpenFileFormAction(form) {
    // console.log('### handleOpenFileFormAction', form, selectedFile);
    setSidebar(SidebarType.Material);
    resetSelected();
    const folderId = selectedFile.directoryId;
    handleOpenForm(form, selectedFile.id, folderId, null);
  }

  function handleCloseFileActionMenu(isResetFile = true) {
    // console.log('### handleCloseFileActionMenu isResetFile', isResetFile);
    setUpdateAnchorEl(null);
    if (isResetFile) {
      setSelectedFile(null);
    }
  }

  const handleResourceView = useCallback(
    (clickingMaterial) => {
      if (!clickingMaterial) return;
      console.log('### handleResourceView', clickingMaterial);
      updateResourceNotifications(clickingMaterial);
      if (isExternalLinkNotVideo(clickingMaterial)) {
        openWindow(clickingMaterial.src, '_blank');
      } else {
        setViewingMaterial(clickingMaterial);
      }
      if (
        clickingMaterial.type === ResourceType.video ||
        clickingMaterial.type === ResourceType.vaam ||
        clickingMaterial.type === ResourceType.audio
      ) {
        return;
      }
      // send request to update resource interaction analytics
      updateResourceInteraction(clickingMaterial);
    },
    [updateResourceNotifications]
  );

  async function handleResourceDownload() {
    updateResourceInteraction(selectedFile, ResourceInteractionType.Downloaded);
  }

  function handleCloseFolderActionMenu(isResetFolder = true) {
    setUpdateAnchorEl(null);
    if (isResetFolder) {
      setSelectedFolderForActionMenu(null);
    }
  }

  function handleOnClickSelectAll(isSelectedAll) {
    let files = [];
    let subFolders = [];
    if (isSelectedAll) {
      if (!selectedFolder) {
        const mainFolders = getFilteredDirectory(directories);
        files = rootFolder.spaceResources || [];
        subFolders = mainFolders;
      } else {
        subFolders = selectedFolder.subFolders || [];
        files = selectedFolder.spaceResources || [];
      }
    }
    handleOnSelectAll(subFolders, files);
  }

  function handleMoveResources(data) {
    if (data.resourceId) {
      handleOnChangeCheckBoxFile(true, data);
    } else {
      handleOnChangeCheckBoxFolder(true, data);
    }
    setTimeout(() => {
      selectActionsRef.current.showMoveDialog();
    }, 200);
  }

  async function handleClickSearchInput() {
    setSidebar(SidebarType.Material);
    handleOpenForm(null, null, null, selectedFolderId); // go to the folder where the search is performed
    await sleep(0.2);
    eventBus.publish(EVENT_BUS.AutoFocusSearch);
  }

  function handleToggleCanDownload(canDownload) {
    setSelectedFile({ ...selectedFile, isDownloadable: canDownload });
  }
  function renderActionButtons() {
    if (!selectedFile && !selectedFolderForActionMenu) {
      return null;
    }

    return (
      <ActionButtons
        currentUserInfoId={spaceUser.getUserInfoId()}
        spaceId={spaceId}
        flatDirectories={flatDirectories}
        selectedFile={selectedFile}
        selectedFolderForActionMenu={selectedFolderForActionMenu}
        isHost={isHost}
        updateAnchorEl={updateAnchorEl}
        handleOpenFileFormAction={handleOpenFileFormAction}
        handleCloseFileActionMenu={handleCloseFileActionMenu}
        handleResourceDownload={handleResourceDownload}
        handleResourceView={handleResourceView}
        handleShareLinkFileClick={handleShareLinkFileClick}
        handleOpenFormAction={handleOpenFormAction}
        handleCloseFolderActionMenu={handleCloseFolderActionMenu}
        handleShareLinkFolderClick={handleShareLinkFolderClick}
        handleDownloadFolder={handleClickDownloadDirectory}
        handleRetryConversion={onReConversion}
        handleMoveResources={!props.isMobile ? handleMoveResources : null}
        handleToggleCanDownload={handleToggleCanDownload}
      />
    );
  }

  function renderFolder(renderingFolder) {
    if (!hasLoggedIn) {
      return null;
    }
    if (renderingFolder.isExternal === true) {
      return (
        <ExternalDirectory
          key={`${props.id}_${renderingFolder.id}`}
          isPortal={isPortal}
          setFlatDirectories={setFlatDirectories}
          directory={renderingFolder}
          selectedFile={selectedFile}
          handleMoreButtonClick={handleMoreButtonClick}
          handleDirectoryClick={handleDirectoryClick}
          handleMaterialClick={handleResourceView}
          disabledMoreButton={isEdit}
          isMobile={props.isMobile}
        />
      );
    }

    return (
      <ResourcesDisplayView
        isMobile={props.isMobile}
        data={renderingFolder}
        newFolderId={newFolderId}
        newMaterialId={newMaterialId}
        isWebinar={false}
        disabledMoreButton={isEdit}
        onClickFolder={handleDirectoryClick}
        onClickFile={handleResourceView}
        onClickMoreButton={handleMoreButtonClick}
        processingResources={processingResources}
        isPortal={isPortal}
      />
    );
  }

  function renderDisabledSearchMessage() {
    if (isEdit) {
      return 'Go back to space to search';
    }

    const folder = isRootFolder && rootFolder ? rootFolder : selectedFolder;
    const isInsideGoogleDriveFolder =
      folder && folder.externalFolderSettings?.provider === SERVICE_PROVIDER.GOOGLE_DRIVE;

    if (isInsideGoogleDriveFolder) {
      return i18n.t('It is not supported to search in Google Drive folders.');
    }

    return null;
  }

  function renderItems() {
    // console.log('### 503 resWidget: ', rootFolder, selectedFolder);
    const folder = isRootFolder && rootFolder ? rootFolder : selectedFolder;
    if (isRootFolder && rootFolder) {
      const mainFolders = getFilteredDirectory(directories);
      folder.subFolders = mainFolders;
    }
    const noData = folder?.subFolders?.length === 0 && folder?.spaceResources?.length === 0;
    const isFetchingExternalResources = folder?.isExternal && !folder?.fetchedExternalResources;
    if (
      !folder ||
      (!folder?.isExternal && noData) ||
      (folder?.isExternal && noData && !isFetchingExternalResources)
    ) {
      return <ErrorMessage message={i18n.t('No materials available.')} />;
    }

    return (
      <>
        {!isFetchingExternalResources && (
          <ResourcesViewControls
            autoFocusSearchInput={false}
            disabledSearchMessage={renderDisabledSearchMessage()}
            onClickSearchInput={handleClickSearchInput}
            canChangeView={!props.isMobile}
          />
        )}

        {renderFolder(folder)}
      </>
    );
  }

  function renderBody() {
    if (isFetchingMaterials || getSpaceExtraDirectoriesQuery.isLoading) {
      return <Loading />;
    }

    return (
      <section className="cms-widget-items styled-scrollbar">
        {renderActionButtons()}
        <div ref={directoriesRef}></div>
        {renderItems()}
      </section>
    );
  }

  function renderSelectResourcesActions() {
    if (!isSelectedResourceIds) return null;
    const isSmartSubFolder = selectedFolder?.isExternal && selectedFolder?.externalId;

    return (
      <SelectResourcesActions
        ref={selectActionsRef}
        variant="white"
        canRemoveResources={canRemoveResources && !isSmartSubFolder}
        isPortal={isPortal}
        resetSelected={resetSelected}
        spaceId={spaceId}
        selectedFolderIds={selectedFolderIds}
        selectedFileIds={selectedFileIds}
        selectedSmartFolders={selectedSmartFolders}
        selectedSmartFiles={selectedSmartFiles}
        onSelectAll={handleOnClickSelectAll}
        defaultCheckValue={isCheckedAll(selectedFolder, rootFolder, directories)}
        canMoveResources={canMoveResources && !isSmartSubFolder}
        isPublicSpace={isPublicSpace}
        canShowDownloadButton={canShowDownloadButton}
        onDownload={handleClickDownloadBulkResources}
        parentId={selectedFolder?.id}
      />
    );
  }

  function renderResourcePreviewDialog() {
    if (viewingMaterial) {
      return (
        <ResourcePreviewDialog
          context={getResourcePreviewContext('space')}
          material={viewingMaterial}
          onClose={() => setViewingMaterial(null)}
          resourceHubConnection={resourceHubConnection}
          enableSlider={isEnabledMaterialSlider}
          spaceName={space?.name}
        />
      );
    }
    return null;
  }

  return (
    <div className="cms-widget">
      <div className="positionRelative paddingB-ratio32">
        <div className="cms-resource-widget positionAbsolute position0">
          {renderHeader()}
          {renderSelectResourcesActions()}
          {renderBody()}
          {renderResourcePreviewDialog()}
        </div>
      </div>
    </div>
  );
}

ResourceWidget.propTypes = {
  settings: PropTypes.instanceOf(Object), // contains directoryId should be hightlighted,
  spaceId: PropTypes.string,
  id: PropTypes.string,
  rowId: PropTypes.string,
  canPin: PropTypes.bool,
  isMobile: PropTypes.bool,
  isPortal: PropTypes.bool,
  widgetName: PropTypes.string,
  isEdit: PropTypes.bool,
};

ResourceWidget.defaultProps = {
  canPin: false,
  isMobile: false,
  isPortal: false,
};

const ResourceWidgetContainer = (props) => {
  return (
    <MultipleSelectResourcesContextProvider>
      <ResourcesViewContextProvider
        source={ResourceSources.Space}
        id={`space-widget-${props.id}`}
        userInfoId={spaceUser?.getUserInfoId()}
        isMobileMode={props.isMobile}
      >
        <ResourceWidget {...props} />
      </ResourcesViewContextProvider>
    </MultipleSelectResourcesContextProvider>
  );
};

ResourceWidgetContainer.propTypes = {
  spaceId: PropTypes.string,
  id: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default ResourceWidgetContainer;
