import React, { ReactNode, createContext, useContext } from 'react';
import memoizeOne from 'memoize-one';
import moment, { Moment } from 'moment';
import _debounce from 'lodash/debounce';
import { newGuid } from '../commons/utils';
import { useUnmountEffect } from '../commons/CustomHooks';
import { ResourceType, SpaceInteractionType } from '../app/appConstants';
import eventBus, { EVENT_BUS } from '../commons/EventBus';
import useGetResourceForViewerQuery from './getResourceForViewerQuery';

interface ResourceInteractionContextProps {
  resourceInteractionSessionId?: string;
  setResourceInteractionSessionId: (sessionId: string) => void;
}

const ResourceInteractionContext = createContext<ResourceInteractionContextProps>({
  resourceInteractionSessionId: undefined,
  setResourceInteractionSessionId: () => {},
});

const getResourceInteractionContext = memoizeOne(
  (resourceInteractionSessionId, setResourceInteractionSessionId) => {
    return {
      resourceInteractionSessionId,
      setResourceInteractionSessionId,
    };
  }
);

const consideredInactiveDuration = 60;

export const ResourceInteractionContextProvider = ({
  spaceId,
  children,
  resourceId,
  materialId,
  isPortal,
  isViewable,
  isViewFromGlobalResource,
}: {
  children: ReactNode;
  spaceId?: string;
  resourceId?: number;
  materialId?: number;
  isPortal?: boolean;
  isViewable?: boolean;
  isViewFromGlobalResource?: boolean;
}): JSX.Element => {
  const resourceType = React.useRef<(typeof ResourceType)[keyof typeof ResourceType] | null>(null);
  const [resourceInteractionSessionId, setResourceInteractionSessionId] = React.useState<
    string | undefined
  >(newGuid());
  const startTime = React.useRef<Moment | null>(null);
  const endTime = React.useRef<Moment | null>(null);

  const getResourceForViewerResp = useGetResourceForViewerQuery(
    spaceId,
    resourceId,
    materialId,
    isPortal,
    !!resourceId || !!materialId,
    isViewFromGlobalResource
  );

  React.useEffect(() => {
    if (getResourceForViewerResp.data) {
      resourceType.current = getResourceForViewerResp.data.resourceType;
    }
  }, [getResourceForViewerResp.data]);

  const sendAnalytics = React.useCallback(() => {
    if (
      !isViewable || // if not viewable, don't send analytics
      resourceType.current === ResourceType.pdf || // pdf has its own analytics tracking mechanism
      resourceType.current === ResourceType.video || // video has its own analytics tracking mechanism
      resourceType.current === ResourceType.audio || // audio has its own analytics tracking mechanism
      resourceType.current === ResourceType.vaam // vaam has its own analytics tracking mechanism
    ) {
      return;
    }
    endTime.current = moment();
    const durationInSeconds = endTime.current.diff(startTime.current, 'seconds');
    console.log('@@actualDuration', durationInSeconds);
    if (durationInSeconds < consideredInactiveDuration && !!resourceId && !!materialId) {
      eventBus.publish(EVENT_BUS.SpaceAnalyticsEvents, SpaceInteractionType.VIEW_RESOURCE, {
        resourceId,
        materialId,
        duration: durationInSeconds,
      });
    }
    startTime.current = moment();
  }, [isViewable, materialId, resourceId]);

  React.useEffect(() => {
    const onMouseOver = _debounce(() => {
      console.log('mouseOver');
      sendAnalytics();
    }, 3000);
    startTime.current = moment();

    let viewerContainer: HTMLElement | null = null;
    setTimeout(() => {
      viewerContainer = document.getElementById('resource-preview-container');
      if (viewerContainer) {
        viewerContainer.addEventListener('mouseover', onMouseOver, true);
      }
    }, 1000);

    return () => {
      if (viewerContainer) {
        viewerContainer.removeEventListener('mouseover', onMouseOver, true);
      }
    };
  }, [sendAnalytics]);

  useUnmountEffect(() => {
    sendAnalytics();
  });

  const contextValue = getResourceInteractionContext(
    resourceInteractionSessionId,
    setResourceInteractionSessionId
  );

  return (
    <ResourceInteractionContext.Provider value={contextValue}>
      <>{children}</>
    </ResourceInteractionContext.Provider>
  );
};

export const useResourceInteractionContext = () => useContext(ResourceInteractionContext);
