import React from 'react';
import Icons from '../../components/Icons';
import { SERVICE_PROVIDER } from '../integrationConstants';
import './ExternalStorageNoConnectionBox.scss';

type ExternalStorageNoConnectionBoxProps = {
  serviceProvider: (typeof SERVICE_PROVIDER)[keyof typeof SERVICE_PROVIDER];
  connectButton?: JSX.Element;
  disabled?: boolean;
};
const populateBoxData = (
  serviceProvider: (typeof SERVICE_PROVIDER)[keyof typeof SERVICE_PROVIDER]
) => {
  switch (serviceProvider) {
    case SERVICE_PROVIDER.ONEDRIVE:
      return {
        icon: 'icon-onedrive',
        text: 'OneDrive',
        hash: 'ONEDRIVE',
      };
    case SERVICE_PROVIDER.GOOGLE_DRIVE:
      return {
        icon: 'icon-googledrive',
        text: 'GoogleDrive',
        hash: 'GOOGLE_DRIVE',
      };
    default:
      return null;
  }
};
const ExternalStorageNoConnectionBox = (props: ExternalStorageNoConnectionBoxProps) => {
  const { serviceProvider, connectButton, disabled } = props;
  const boxData = populateBoxData(serviceProvider);
  if (!boxData) {
    return null;
  }
  return (
    <div className={`ex-storage-noconnect-info ${disabled ? 'disabled' : ''}`}>
      <div className="ex-storage-logo">
        <Icons name={boxData.icon} className={boxData.icon} />
      </div>
      <p className="primary-text">Manage global resources in {boxData.text}</p>
      <p className="secondary-text">
        Setup this integration to easily access {boxData.text} files directly from within a space.
        <br />
        Changes made in {boxData.text} will be updated in the space automatically.
      </p>
      {connectButton}
    </div>
  );
};

export default ExternalStorageNoConnectionBox;
