import React from 'react';
import PropTypes from 'prop-types';

const ImageViewer = (props) => {
  const { src, className } = props;
  return (
    <div className={`image-viewer ${className}`} style={{ backgroundImage: `url(${src})` }}></div>
  );
};

ImageViewer.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
};

ImageViewer.defaultProps = {
  className: '',
};

export default ImageViewer;
