import i18n from '../i18n';
import {
  CAROUSEL_ALIGNMENT_OPTION,
  CONTENT_COMPONENT,
  LAYOUT_COMPONENT,
  TEXT_ALIGNMENT_OPTION,
  CMS_COMPONENT_SIZE_OPTION,
  CMS_COMPONENT_STYLE,
} from './cmsConstants';

export const VIDEO_LAYOUT_OPTION = [
  { value: CMS_COMPONENT_STYLE.VIDEO_LAYOUT.OVERLAY, name: 'Overlay video' },
  { value: CMS_COMPONENT_STYLE.VIDEO_LAYOUT.UNDER, name: 'Under video' },
  { value: CMS_COMPONENT_STYLE.VIDEO_LAYOUT.BESIDE, name: 'Beside video' },
];

export const VIDEO_LAYOUT_FORM_COMPONENT = {
  OVERLAY: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.COLUMN,
      settings: {
        options: [1, 2, 3],
        max: 3,
        min: 1,
      },
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.SIZE,
      settings: {
        label: i18n.t('Size'),
        options: CMS_COMPONENT_SIZE_OPTION,
      },
    },
    {
      id: 4,
      component: LAYOUT_COMPONENT.VIDEO_LAYOUT,
      settings: {
        label: i18n.t('Text layout'),
        options: VIDEO_LAYOUT_OPTION,
      },
    },
    {
      id: 5,
      component: LAYOUT_COMPONENT.SELECT_COLUMN,
    },
    {
      id: 6,
      component: LAYOUT_COMPONENT.SHOW_HEADING,
    },
    {
      id: 7,
      component: LAYOUT_COMPONENT.SHOW_DESCRIPTION,
    },
    {
      id: 8,
      component: LAYOUT_COMPONENT.AUTOPLAY,
    },
    {
      id: 9,
      component: LAYOUT_COMPONENT.TEXT_POSITION,
      settings: {
        options: CAROUSEL_ALIGNMENT_OPTION,
      },
    },
    {
      id: 10,
      component: LAYOUT_COMPONENT.OVERLAY_OPACITY,
      settings: {
        options: [0.3, 0.2, 0.1, 0],
      },
    },
  ],
  UNDER: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.COLUMN,
      settings: {
        options: [1, 2, 3],
        max: 3,
        min: 1,
      },
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.SIZE,
      settings: {
        label: i18n.t('Size'),
        options: CMS_COMPONENT_SIZE_OPTION,
      },
    },
    {
      id: 4,
      component: LAYOUT_COMPONENT.VIDEO_LAYOUT,
      settings: {
        label: i18n.t('Text layout'),
        options: VIDEO_LAYOUT_OPTION,
      },
    },
    {
      id: 5,
      component: LAYOUT_COMPONENT.SELECT_COLUMN,
    },
    {
      id: 6,
      component: LAYOUT_COMPONENT.SHOW_HEADING,
    },
    {
      id: 7,
      component: LAYOUT_COMPONENT.SHOW_DESCRIPTION,
    },
    {
      id: 8,
      component: LAYOUT_COMPONENT.SHOW_BUTTON,
    },
    {
      id: 9,
      component: LAYOUT_COMPONENT.SHOW_SECONDARY_BUTTON,
    },
    {
      id: 10,
      component: LAYOUT_COMPONENT.AUTOPLAY,
    },
    {
      id: 11,
      component: LAYOUT_COMPONENT.ALIGNMENT,
      settings: {
        label: i18n.t('Text alignment'),
        options: TEXT_ALIGNMENT_OPTION,
      },
    },
  ],
  BESIDE: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.VIDEO_LAYOUT,
      settings: {
        label: i18n.t('Text layout'),
        options: VIDEO_LAYOUT_OPTION,
      },
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.SHOW_HEADING,
    },
    {
      id: 4,
      component: LAYOUT_COMPONENT.SHOW_DESCRIPTION,
    },
    {
      id: 5,
      component: LAYOUT_COMPONENT.SHOW_BUTTON,
    },
    {
      id: 6,
      component: LAYOUT_COMPONENT.SHOW_SECONDARY_BUTTON,
    },
    {
      id: 7,
      component: LAYOUT_COMPONENT.AUTOPLAY,
    },
    {
      id: 8,
      component: LAYOUT_COMPONENT.VIDEO_ALIGMENT,
      settings: {
        label: i18n.t('Video aligment'),
        options: ['left', 'right'],
      },
    },
  ],
};

export const VIDEO_CONTENT_FORM_COMPONENT = {
  OVERLAY: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.VIDEO_UPLOAD,
    },
    {
      id: 3,
      component: CONTENT_COMPONENT.IMAGE_UPLOAD,
    },
    {
      id: 4,
      component: CONTENT_COMPONENT.HEADING,
    },
    {
      id: 5,
      component: CONTENT_COMPONENT.DESCRIPTION,
    },
  ],
  UNDER: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.VIDEO_UPLOAD,
    },
    {
      id: 3,
      component: CONTENT_COMPONENT.IMAGE_UPLOAD,
    },
    {
      id: 4,
      component: CONTENT_COMPONENT.HEADING,
    },
    {
      id: 5,
      component: CONTENT_COMPONENT.DESCRIPTION,
    },
    {
      id: 6,
      component: CONTENT_COMPONENT.BUTTON_LABEL,
    },
    {
      id: 7,
      component: CONTENT_COMPONENT.BUTTON_LINK,
    },
    {
      id: 8,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LABEL,
    },
    {
      id: 9,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LINK,
    },
  ],
  BESIDE: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.VIDEO_UPLOAD,
    },
    {
      id: 3,
      component: CONTENT_COMPONENT.IMAGE_UPLOAD,
    },
    {
      id: 4,
      component: CONTENT_COMPONENT.HEADING,
    },
    {
      id: 5,
      component: CONTENT_COMPONENT.DESCRIPTION,
    },
    {
      id: 6,
      component: CONTENT_COMPONENT.BUTTON_LABEL,
    },
    {
      id: 7,
      component: CONTENT_COMPONENT.BUTTON_LINK,
    },
    {
      id: 8,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LABEL,
    },
    {
      id: 9,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LINK,
    },
  ],
};
