import React from 'react';
import LockIcon from '@mui/icons-material/Lock';

import ResponsiveDialog from '../../components/ResponsiveDialog';
import i18n from '../../i18n';

function NoAccessDialog() {
  return (
    <ResponsiveDialog
      showCloseIcon={false}
      modal
      maxWidth="lg"
      className="white new-popup closed-space-dialog"
    >
      <div className="content" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="icon">
          <LockIcon className="lock-icon" />
        </div>
        <h1>{i18n.t('NO ACCESS')}</h1>
        <div className="message">
          {i18n.t('This is an internal link to the space designer.')}
          <br />
          {i18n.t('Ask the host to share the space via the “SHARE” button instead.')}
        </div>
      </div>
    </ResponsiveDialog>
  );
}

NoAccessDialog.propTypes = {};

export default NoAccessDialog;
