/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { forEach } from 'lodash';
import Chart, { Easing } from 'chart.js';
import memoizeOne from 'memoize-one';
import { ChartPluginEvent, ChartPluginInstance } from './types';

type ImageElement = {
  top: number;
  bottom: number;
  left: number;
  right: number;
  onClick?: () => void;
};

type ImageElementOptions = {
  imageElements: ImageElement[];
  enabled: boolean;
};

const roundRect = function (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  r: number
) {
  if (w < 2 * r) r = w / 2;
  if (h < 2 * r) r = h / 2;
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.arcTo(x + w, y, x + w, y + h, r);
  ctx.arcTo(x + w, y + h, x, y + h, r);
  ctx.arcTo(x, y + h, x, y, r);
  ctx.arcTo(x, y, x + w, y, r);
  ctx.closePath();
  return ctx;
};

function drawBoxWithImage(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  image: HTMLImageElement
) {
  ctx.save();
  const padding = 4;
  const cornerRadius = 4;

  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 2;
  ctx.shadowBlur = 8;
  ctx.shadowColor = 'rgba(0, 0, 0, 0.15)';

  ctx.fillStyle = 'white';
  roundRect(ctx, x, y, width, height, cornerRadius);
  ctx.fill();

  const ratioImage = image.height / image.width;
  const imgWidth = Math.min(width - 2 * padding, image.width);
  const imgHeight = ratioImage * imgWidth;
  const imgX = x + padding + (width - 2 * padding - imgWidth) / 2;
  const imgY = y + padding + (height - 2 * padding - imgHeight) / 2;

  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;
  ctx.shadowBlur = 0;
  ctx.drawImage(image, imgX, imgY, imgWidth, imgHeight);
}

type LogoImageDraw = {
  x: number;
  y: number;
  image: HTMLImageElement;
};

export const avatarPoint = {
  id: 'avatarPoint',
  beforeInit(chart: Chart & ChartPluginInstance, options: ImageElementOptions) {
    options = { ...options, imageElements: [] };
  },
  beforeDatasetDraw(chart: Chart & ChartPluginInstance, arg: Easing, options: ImageElementOptions) {
    if (!options?.enabled) {
      return;
    }
    options.imageElements = [];
    const { ctx, chartArea } = chart;
    const { right, top } = chartArea;
    if (!ctx) {
      return;
    }
    const meta = chart.getDatasetMeta(0);
    const dataChart = chart.data.datasets?.[0].data;
    const avatarPoints = chart.data.avatarPoints;

    // const logos = drawImageLogo(meta.data, dataChart, avatarPoints, right, top);
    forEach(meta.data, async (point, index) => {
      const { x, y } = point._model;
      const dataPoint = dataChart?.[index];
      let xValue = x;
      let yValue = y;

      if (x + 48 > right) {
        xValue = x - 48;
      }

      if (yValue > top + 48) {
        yValue = y - 48;
      }

      if (dataPoint && avatarPoints?.[index]) {
        // Set box shadow
        const image = avatarPoints?.[index];
        if (image) {
          drawBoxWithImage(ctx, xValue, yValue, 48, 48, image.img);
          options.imageElements.push({
            left: xValue,
            top: yValue,
            right: xValue + 48,
            bottom: yValue + 48,
            onClick: image.onClick,
          });
        }
      }
    });
    ctx.restore();
  },
  afterEvent: (
    chart: Chart & ChartPluginInstance,
    event: Event & ChartPluginEvent,
    options: ImageElementOptions
  ) => {
    if (event.type === 'click' && chart.config.type === 'scatter') {
      const { x, y } = event;
      forEach(options.imageElements, (imageElement) => {
        const { left, right, bottom, top } = imageElement;
        if (x > left && x < right && y > top && y < bottom) {
          if (imageElement.onClick) {
            imageElement.onClick();
          }
        }
      });
    }
  },
};
