import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InvalidFileType } from './utils';
import i18n from '../../i18n';
import { errorMessageByType } from '../DndFileUploader3/utils';

export interface IUploadFailureResourcesProps {
  files: InvalidFileType[];
}

export function UploadFailureResources(props: IUploadFailureResourcesProps) {
  const { files } = props;

  const renderErrorFile = (file: InvalidFileType, index: number) => {
    const errorMessage = file.validationResult?.type
      ? errorMessageByType(file.validationResult?.type)
      : '';
    return (
      <div className="upload-error" key={index}>
        <div className="error-message-filename">{file.name}</div>
        <div className="error-message">{errorMessage}</div>
      </div>
    );
  };
  return (
    <Accordion className="upload-errors-container">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="upload-errors-header"
      >
        {i18n.t('Items not uploaded ')} {`(${props.files.length})`}
      </AccordionSummary>
      <AccordionDetails className="upload-errors-body">
        {files.map((file: InvalidFileType, index: number) => renderErrorFile(file, index))}
      </AccordionDetails>
    </Accordion>
  );
}
