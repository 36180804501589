import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../i18n';
import HeaderUser from './components/HeaderUser';

function getTitle(type) {
  switch (type) {
    case 'deals':
      return i18n.t('Deals');
    default:
      return '';
  }
}

const SettingsPage = (props) => {
  const { handleDrawerToggle, isOpen, type } = props;

  return (
    <div className={`settings-page ${type}`}>
      <HeaderUser onClick={handleDrawerToggle} isOpen={isOpen} title={getTitle(type)} />
    </div>
  );
};

SettingsPage.propTypes = {
  handleDrawerToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  type: PropTypes.string,
};

export default SettingsPage;
