import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import { formatDateTime, parseDateTimeStringFromServer } from '../../commons/DateTimeUtils';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import SpaceUserAvatar from '../components/SpaceUserAvatar';
import { ISpaceUser } from '../../types/User';
import { VideoChatMessageDetailsResponse } from '../ChatInfoTable/types';
import Table from '../components/Table';
import { Tag } from '../../components/Tags';
import i18n from '../../i18n';
import SpaceModalHeader from '../components/SpaceModalHeader';
import './style.scss';
import { CacheKeys } from '../../app/queryCache';
import { getVideoMessageDetailByIds } from '../services/SpaceAnalyticsService';
import useSpaceAnalyticsQuery from '../hooks/useSpaceAnalyticsQuery';
import Icons from '../../components/Icons';
import { getActivityDateTimeString } from '../../commons/utils';

const emptyState = {
  icon: <Icons className="icon-chat-new" />,
};

type VideoMessageByIdProps = {
  messageId: number;
  title: string;
};

export type VideoMessageDetails = ISpaceUser &
  VideoChatMessageDetailsResponse & {
    totalView?: number;
  };
function VideoMessageById(props: VideoMessageByIdProps) {
  const { space, users, excludeUserIds } = useSpaceAnalyticsContext();
  const [videoMessageDetail, setVideoMessageDetail] = useState<VideoMessageDetails[]>([]);
  const [totalViewCount, setTotalViewCount] = useState<number>(0);
  const spaceId = space?.id;

  const { data: videoMessageData, isLoading } = useSpaceAnalyticsQuery({
    fetcher: getVideoMessageDetailByIds,
    queryKey: CacheKeys.spaceAnalytics_FetchSpaceVideoMessageById,
    params: {
      chatMessageId: props.messageId,
      excludedUserInfoIds: excludeUserIds,
    },
    enabled: !!spaceId && !!props.messageId,
    select: (resp) => resp.value,
  });

  useEffect(() => {
    if (users?.length > 0 && videoMessageData !== undefined && videoMessageData?.data?.length > 0) {
      const updatedMessages = users
        .map((user: ISpaceUser) => {
          const foundVideoMessage = videoMessageData?.data?.find(
            (videoMessage) => videoMessage.userInfoId === user.userInfoId
          );

          if (foundVideoMessage) {
            return {
              ...user,
              ...foundVideoMessage,
            };
          }

          return null;
        })
        .filter(Boolean);

      const validUpdatedMessages = updatedMessages.filter(
        (message) => message !== null
      ) as VideoMessageDetails[];

      const totalView = validUpdatedMessages.reduce((total, item) => {
        return total + (item.viewCount ?? 0);
      }, 0);
      setTotalViewCount(totalView);

      setVideoMessageDetail(validUpdatedMessages);
    }
  }, [users, videoMessageData]);

  function renderTable() {
    if (isLoading) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }

    const columns = [
      {
        title: 'Name',
        field: 'name',
        cellStyle: {
          width: '70%',
        },
        render: (rowData: VideoMessageDetails) => {
          const userInfo: ISpaceUser = {
            id: rowData.userInfoId,
            userInfoId: rowData.userInfoId,
            firstName: rowData.firstName,
            orgRole: rowData.orgRole,
            lastName: rowData.lastName,
            email: rowData.email,
            fullName: rowData.fullName,
            isOrganizationUser: rowData.isOrganizationUser,
            role: rowData.role,
          };
          return <SpaceUserAvatar userInfo={userInfo} />;
        },
      },
      {
        title: 'Clicks',
        field: 'createdDate',
        cellStyle: {
          width: '10%',
        },
        render: (rowData: VideoMessageDetails) => {
          return rowData.viewCount;
        },
      },
      {
        title: 'Last Clicked',
        field: 'lastViewed',
        cellStyle: {
          width: '10%',
        },
        render: (rowData: VideoMessageDetails) => {
          const time = parseDateTimeStringFromServer(rowData.lastViewed);
          const timeInfo = time ? getActivityDateTimeString(time.toDate()) : '';
          return timeInfo || <Tag className="space-tag">{i18n.t('Not joined')}</Tag>;
        },
      },
    ];

    return <Table columns={columns} data={videoMessageDetail} emptyState={emptyState} />;
  }

  return (
    <div className="video-message-info-container">
      <SpaceModalHeader
        title={props.title}
        icon="icon-video-message"
        description={formatDateTime(Date.now(), 'MMM DD, yyyy')}
        className="video-message-info-header"
      />
      <p className="short-description">
        The video message has <b>{totalViewCount}</b> {totalViewCount <= 1 ? 'click' : 'clicks'}.
      </p>
      {renderTable()}
    </div>
  );
}

export default VideoMessageById;
