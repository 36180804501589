import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import {
  getOneDriveRootFolders,
  getOneDriveSubFolders,
  // saveDefaultRootFolders,
} from './onedriveService';
import SelectRootFoldersDialog from '../components/SelectRootFoldersDialog';

const OneDriveSelectRootFoldersDialog = (props) => {
  const { onClose, onSubmit, onRequestSupport, isOpen } = props;
  const [folders, setFolders] = useState([]);

  const fetchRootFoldersQuery = useQuery({
    queryKey: 'fetchRootFolders',
    queryFn: async () => {
      const resp = await getOneDriveRootFolders();
      return resp;
    },
    enabled: isOpen,
  });

  useEffect(() => {
    if (fetchRootFoldersQuery.data) {
      setFolders(fetchRootFoldersQuery.data.items);
    }
  }, [fetchRootFoldersQuery]);

  async function handleExpandItem(item) {
    try {
      const subFolders = await getOneDriveSubFolders(item.driveId, item.id);
      return subFolders?.items;
    } catch (error) {
      console.log('getOneDriveSubFolders ERROR', error);
      return [];
    }
  }

  return (
    <SelectRootFoldersDialog
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={fetchRootFoldersQuery.isLoading}
      isOpen={isOpen}
      onRequestSupport={onRequestSupport}
      onExpandItem={handleExpandItem}
      rootFolders={folders}
    />
  );
};

OneDriveSelectRootFoldersDialog.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onRequestSupport: PropTypes.func,
};

export default OneDriveSelectRootFoldersDialog;
