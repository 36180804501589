import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { createRoot } from 'react-dom/client';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import ButtonEnhanced from '../../../components/ButtonEnhanced';
import i18n from '../../../i18n';
import ImagePlaceholder from '../../../components/ImagePlaceholder';
import './DriveDeletedNotificationDialog.scss';

let resolve;
let containerElement;
let rootEl;

class DriveDeletedNotificationDialog extends Component {
  static show(driveName) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(<DriveDeletedNotificationDialog driveName={driveName} />);
    return new Promise((res) => {
      resolve = res;
    });
  }

  static destroy(result = false) {
    if (containerElement) {
      try {
        setTimeout(() => {
          rootEl.unmount();
          containerElement = null;
        }, 0);
      } catch (e) {
        console.error(e);
      }
    }
    if (resolve) {
      resolve(result);
    }
  }

  render() {
    const { driveName } = this.props;
    return (
      <Dialog open className="global-resources-deleted-dialog" hideBackdrop={false}>
        <DialogTitle>
          <div className="dialog-title-text">File has been deleted</div>
          <IconButton
            className="close-button"
            onClick={() => DriveDeletedNotificationDialog.destroy()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="content">
          <div>
            The {driveName} file has been deleted, we've added this placeholder image in spaces
            where this file was used until you have the time to replace it with a new file.
          </div>
          <div className="image-placeholder-wrapper">
            <ImagePlaceholder />
          </div>
        </DialogContent>
        <DialogActions className="actions">
          <ButtonEnhanced className="btn" onClick={() => DriveDeletedNotificationDialog.destroy()}>
            {i18n.t('Ok')}
          </ButtonEnhanced>
        </DialogActions>
      </Dialog>
    );
  }
}

DriveDeletedNotificationDialog.propTypes = {
  driveName: PropTypes.string,
};
export default DriveDeletedNotificationDialog;
