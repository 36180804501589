import React, { memo, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { Column } from '@material-table/core';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import useSpaceResourcesByIdQuery, {
  InteractionResource,
} from '../queries/useSpaceResourceByIdQuery';
import SpaceUserAvatar from '../components/SpaceUserAvatar';
import { SpaceResourceDetailsTab } from '.';
import Table from '../components/Table';
import { ISpaceUser } from '../../types/User';
import LinearProgressBar from '../components/LinearProgress';
import { getPercent, getStringTime } from '../common';
import { ResourceType, PrivacySpace } from '../../app/appConstants';
import { parseDateTimeStringFromServer, timeFromNowByLocal } from '../../commons/DateTimeUtils';
import Loading from '../../components/Loading';
import LightTooltip from '../../components/LightTooltip';
import useAvailableSpaceResources from '../hooks/useAvailableSpaceResources';
import { tagViewTimeSupportedList } from '../types/resources';

const resourcesPageView: number[] = [ResourceType.pdf, ResourceType.word, ResourceType.powerpoint];
export interface IResourceByIdProps {
  displayWith: (typeof SpaceResourceDetailsTab)[keyof typeof SpaceResourceDetailsTab];
}

function ResourceById(props: IResourceByIdProps) {
  const { space, reportEndDate, selectedResource, isPublic, visibleUsers, excludeUserIds } =
    useSpaceAnalyticsContext();
  const { displayWith } = props;
  const spaceId = space?.id;
  const startCreateSpace = space?.created;
  const startDate = startCreateSpace ? moment(startCreateSpace).toISOString() : undefined;
  const endDate = moment(reportEndDate).toISOString();
  const { availableMaterials } = useAvailableSpaceResources();

  const { resourceInteractions, isFetching } = useSpaceResourcesByIdQuery(
    spaceId,
    selectedResource?.materialId || 0,
    startDate,
    endDate,
    space?.spacePrivacy === PrivacySpace.PUBLIC,
    excludeUserIds,
    visibleUsers.map((x) => x.userInfoId),
    !!spaceId && !!selectedResource?.materialId
  );

  const renderUserAvatar = useCallback((user?: ISpaceUser) => {
    if (user) {
      return <SpaceUserAvatar userInfo={user} />;
    }
    return null;
  }, []);

  const renderTimeForNow = (value: string) => {
    if (value) {
      const localDate = parseDateTimeStringFromServer(value).local();
      return timeFromNowByLocal(localDate, 'spce-en-detail');
    } else {
      return 'N/A';
    }
  };

  const hiddenView =
    selectedResource?.resourceType && resourcesPageView.includes(selectedResource.resourceType);

  const field: keyof InteractionResource = useMemo(() => {
    if (displayWith === SpaceResourceDetailsTab.SHARES) {
      return 'shares';
    } else if (displayWith === SpaceResourceDetailsTab.DOWNLOADS) {
      return 'downloads';
    } else {
      if (selectedResource?.isCmsFile && selectedResource?.resourceType === ResourceType.pdf) {
        return 'viewTimeInSeconds';
      }
      return 'clicks';
    }
  }, [displayWith, selectedResource?.isCmsFile, selectedResource?.resourceType]);

  const columns = useMemo<Column<InteractionResource & ISpaceUser>[]>(() => {
    const nameColumnHeader = isPublic === true ? 'Visitor' : 'Name';
    if (displayWith === SpaceResourceDetailsTab.SHARES) {
      return [
        {
          title: nameColumnHeader,
          field: 'firstName',
          render: (rowData) => {
            return renderUserAvatar(rowData);
          },
        },
        {
          title: 'Shares',
          defaultSort: 'desc',
          field: 'shares',
        },
        {
          title: 'Last shared',
          field: 'lastShare',
          render: (rowData: InteractionResource) => {
            return renderTimeForNow(rowData.lastShare);
          },
        },
      ];
    } else if (displayWith === SpaceResourceDetailsTab.DOWNLOADS) {
      return [
        {
          title: nameColumnHeader,
          field: 'firstName',

          render: (rowData) => {
            return renderUserAvatar(rowData);
          },
        },
        {
          title: 'Downloads',
          defaultSort: 'desc',
          field: 'downloads',
        },
        {
          title: 'Last Downloaded',
          field: 'lastDownload',
          render: (rowData) => {
            return renderTimeForNow(rowData.lastDownload);
          },
        },
      ];
    } else {
      const isViewNotSupported =
        availableMaterials?.find((x) => x.materialId === selectedResource?.materialId)
          ?.isViewNotSupported || false;
      return [
        {
          title: nameColumnHeader,
          field: 'firstName',
          cellStyle: {
            width: '30%',
          },
          render: (rowData) => {
            return renderUserAvatar(rowData);
          },
        },
        {
          title: 'Clicks',
          field: 'clicks',
          defaultSort: 'desc',
        },
        {
          title: 'Last clicked',
          field: 'lastClick',
          render: (rowData: InteractionResource) => {
            return renderTimeForNow(rowData.lastClick);
          },
        },
        {
          title: 'Page read',
          hidden: !hiddenView,
          align: 'right',
          field: 'pageRead',
          render: ({ pageRead, totalPages }: InteractionResource) => {
            if (selectedResource?.resourceType !== ResourceType.pdf) {
              return (
                <LightTooltip title="This resource cannot be tracked page by page.">
                  <Typography align="right">N/A</Typography>
                </LightTooltip>
              );
            }
            return (
              <Box display="flex" alignItems="center" className="page-read-progress">
                <LinearProgressBar
                  color="#4783FF"
                  backgroundColor="#DBE1E3"
                  value={getPercent(pageRead, totalPages)}
                />
                <span>{`${pageRead}/${totalPages}`}</span>
              </Box>
            );
          },
        },
        {
          title: 'View time',
          field: 'viewTimeInSeconds',
          align: 'right',
          cellStyle: {
            width: '20%',
          },
          // customSort: (a, b) => sortSpaceAnalyticMetrics(a.viewTimeInSeconds, b.viewTimeInSeconds),
          render: ({ viewTimeInSeconds }) => {
            const cmsViewSupported = tagViewTimeSupportedList.find(
              ([boolValue, stringValue]) =>
                boolValue === selectedResource?.isCmsFile && stringValue === selectedResource?.tag
            );

            if (isViewNotSupported && !cmsViewSupported) {
              return (
                <LightTooltip title={'This resource cannot be measured by view time.'}>
                  <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
                </LightTooltip>
              );
            } else if (viewTimeInSeconds === 0) {
              return <Typography>{'0s'}</Typography>;
            }
            return <Typography>{getStringTime(viewTimeInSeconds)}</Typography>;
          },
        },
      ];
    }
  }, [isPublic, displayWith, renderUserAvatar, availableMaterials, hiddenView, selectedResource]);

  function renderTable() {
    const data =
      resourceInteractions
        ?.filter((item: InteractionResource) => !!item[field])
        .map((x) => {
          const user = visibleUsers.find((y) => y.userInfoId === x.userInfoId);
          return {
            ...x,
            ...(user || { fullName: '' }),
          };
        })
        .sort((a, b) => a.fullName.localeCompare(b.fullName)) || [];

    return <Table columns={columns} data={data} />;
  }

  if (isFetching) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return renderTable();
}

export default memo(ResourceById);
