import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import './PasswordSettingPage.scss';
import i18n from '../i18n';
import { resetPassword } from '../settings/SettingsServices';
import Logo from '../images/spce-logo.png';
import { sendNotification, getUrlParameter } from '../commons/utils';
import PasswordField from '../components/PasswordField';
import InsightContent from './InsightContent';
import OfficialButton from '../components/OfficialButtons';

function ResetPassword() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const passwordRef = React.useRef(null);
  const confirmPasswordRef = React.useRef(null);
  const code = getUrlParameter('code', '');
  const userId = getUrlParameter('userid', '');
  const navigate = useNavigate();

  const handleOnChangePassword = (name, value) => {
    setNewPassword(value);
  };

  const handleOnChangeConfirmPassword = (name, value) => {
    setConfirmPassword(value);
  };

  const validateConfirmPassword = () => {
    let error = null;
    if (confirmPassword !== newPassword) {
      error = i18n.t('Please make sure your passwords match');
    }
    return error;
  };

  const validateData = () => {
    const isPasswordValid = passwordRef.current.isValid();
    const isConfirmPasswordValid = confirmPasswordRef.current.isValid();
    return isPasswordValid && isConfirmPasswordValid;
  };

  const saveData = async () => {
    if (isSubmitting || !validateData()) {
      return;
    }
    setIsSubmitting(true);
    try {
      const resp = await resetPassword(code, userId, newPassword);
      setIsSubmitting(false);
      if (resp.succeeded) {
        setSuccessMessage('Password changed successfully. Returning to Login...');
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else {
        sendNotification(resp.errors[0]?.description, { type: 'error' });
      }
    } catch (e) {
      sendNotification('Email does not exist', { type: 'error' });
    }
    setIsSubmitting(false);
  };

  const handleOnClickSave = () => {
    saveData();
  };

  return (
    <Grid container className="password-container">
      <Grid item xs={12} md={6} sm={6} className="left-column">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="password-box">
          <form className="form ">
            <p className="title">{i18n.t('Reset Password')}</p>
            <label className="label">{i18n.t('New password')}</label>
            <PasswordField
              name="password"
              onChange={handleOnChangePassword}
              ref={passwordRef}
              placeholder={i18n.t('Enter your new password')}
            />
            <div style={{ margin: 20 }} />
            <label className="label">{i18n.t('Confirm new password')}</label>
            <PasswordField
              name="confirmPassword"
              onChange={handleOnChangeConfirmPassword}
              ref={confirmPasswordRef}
              validate={validateConfirmPassword}
              placeholder={i18n.t('Re-enter new password')}
            />
            {!successMessage ? (
              <p className="description">
                {i18n.t('Your password has to be at least 8 characters')}
              </p>
            ) : (
              <p className="success-message">{successMessage}</p>
            )}
            <div className="button-container">
              <OfficialButton
                label={i18n.t('Save')}
                onClick={handleOnClickSave}
                isProcessing={isSubmitting}
                variant="regular-green"
              />
            </div>
          </form>
        </div>
      </Grid>
      <InsightContent />
    </Grid>
  );
}

export default ResetPassword;
