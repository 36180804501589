import React from 'react';
import queryCache, { CacheKeys, clearCache } from '../../app/queryCache';
import { sendNotification } from '../../commons/utils';
import ButtonEnhanced from '../../components/ButtonEnhanced';
import i18n from '../../i18n';
import { useGlobalResourcesContext } from '../context/GlobalResourcesContextProvider';
import { deleteBulk } from '../globalResourcesService';
import { getSelectedResourceIds } from './utils';
import { IFolder } from '../context/Interfaces';
import ConfirmDialog from '../../components/ConfirmDialog';

interface DeleteButtonProps {
  onDeleteSuccess: () => void;
  selectedFolder: IFolder | null;
}

function DeleteButton(props: DeleteButtonProps) {
  const { onDeleteSuccess, selectedFolder } = props;
  const { selectedResources } = useGlobalResourcesContext();
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  function onClickDelete() {
    if (isSaving) {
      return;
    }
    setOpenConfirmDialog(true);
  }

  function handleOnClickCancel() {
    setOpenConfirmDialog(false);
  }

  async function handleOnClickOk() {
    console.log('selectedResources', selectedResources);
    const { resourceIds, directoryIds } = getSelectedResourceIds(selectedResources);
    setIsSaving(true);

    const resp = await deleteBulk(directoryIds, resourceIds);
    if (
      resp?.directoryResult?.failedItems?.length > 0 ||
      resp?.resourceResult?.failedItems?.length > 0
    ) {
      setIsSaving(false);
      sendNotification(i18n.t('Sorry, something went wrong. Please try again!'), { type: 'error' });
    } else {
      clearCache([CacheKeys.getGlobalResourcesMngt], false);
      const currentFolderParentId = selectedFolder?.parentId;
      queryCache.removeQueries({
        queryKey: [CacheKeys.getGlobalResourcesMngt, currentFolderParentId],
        exact: false,
      });
      // queryCache.removeQueries([CacheKeys.getGlobalResourcesMngt, selectedFolder?.id], {
      //   exact: false,
      // });
      const timeout = setTimeout(() => {
        sendNotification(i18n.t('Successfully deleted'), {
          type: 'success',
        });
        setIsSaving(false);
        setOpenConfirmDialog(false);
        clearTimeout(timeout);
      }, 1000);
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
    }
  }

  function renderConfirmDialog() {
    if (!openConfirmDialog) {
      return null;
    }
    const confirmMessage = (
      <div className="delete-resource-message">
        <p>
          <b>{i18n.t("This can't be undone.")}</b>
        </p>
        <p>
          {selectedResources.length > 0
            ? i18n.t('Are you sure you want to delete these resources?')
            : i18n.t('Are you sure you want to delete this resource?')}
        </p>
        <br />
        <p>
          {selectedResources.length > 0
            ? i18n.t(
                'The spaces where these resources are already being used will not be affected.'
              )
            : i18n.t('The spaces where this resource is already being used will not be affected.')}
        </p>
      </div>
    );
    return (
      <ConfirmDialog
        title={i18n.t('DELETE RESOURCE')}
        message={confirmMessage}
        okLabel={isSaving ? i18n.t('Deleting...') : i18n.t('Yes')}
        cancelLabel={i18n.t('Cancel')}
        onClickOk={handleOnClickOk}
        onClickCancel={handleOnClickCancel}
      />
    );
  }

  return (
    <>
      <ButtonEnhanced
        onClick={onClickDelete}
        className="button"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore:next-line
        endIcon={<span className="icon icon-delete" />}
      >
        {i18n.t('Delete')}
      </ButtonEnhanced>
      {renderConfirmDialog()}
    </>
  );
}

export default React.memo(DeleteButton);
