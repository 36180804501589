// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ENGAGEMENT_EVENT_TYPES: { [key: string]: any } = {
  SPACE_SHARED: 'Shared a space',
  PANEL_OPENED: 'Opened a panel',
  MEETING_PARTICIPANT_JOINED: 'Participated in a meeting',
  MEETING_RECORDING_WATCHED: 'Clicked on a meeting recording',
  SPACE_LEFT: 'Was active in the space',
  RESOURCE_VIEWED: 'Viewed a resource in the space design',
  VIDEO_RESOURCE_VIEWED: 'Viewed a video in the space design',
  RESOURCE_CLICKED: 'Clicked on a resource',
  RESOURCE_ADDED: 'Added a resource',
  RESOURCE_SHARED: 'Shared a resource',
  RESOURCE_DOWNLOADED: 'Downloaded a resource',
  RESOURCE_RENAMED: 'Renamed a resource',
  RESOURCE_DELETED: 'Deleted a resource',
  CHAT_SENT: 'Sent a chat message',
  CHAT_LIKED: 'Liked a message',
  CHAT_VIDEO_MESSAGE_WATCHED: 'Clicked on a video message',
  TODO_ADDED: 'Added a todo',
  TODO_UPDATED: 'Updated a todo',
  TODO_PINNED: 'Pinned a todo on the timeline',
  TODO_UNPINNED: 'Unpinned a todo on the timeline',
  TODO_DELETED: 'Deleted a todo',
};

export const PANEL_OPENED_EVENTS = {
  Meetings: 'Opened the meeting timeline',
  Participants: 'Opened the user panel',
  Marterial: 'Opened the resource panel',
  Todo: 'Opened the to-do panel',
  Chat: 'Opened the chat panel',
};
