import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PDF from '@spce/pdfreader';
import _debounce from 'lodash/debounce';
import { Waypoint } from 'react-waypoint';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import { getAppUrl } from '../commons/utils';
import eventBus, { EVENT_BUS } from '../commons/EventBus';
import {
  ResourceInteractionType,
  ResourceTypeNames,
  SpaceInteractionType,
} from '../app/appConstants';
import useOnScreen from '../commons/useOnScreen';
import { createResourceInteraction } from '../commons/CommonServices';

const viewer = {
  host: getAppUrl(),
  path: '/pdf/web/viewer.html',
};

const maxReadTimePerPage = 120;

const PdfViewer = React.forwardRef((props, ref) => {
  const { src, preview, spaceId, resourceId, materialId, preventEvents, isCms, downloadable } =
    props;
  const [shouldDisplayPdf, setShouldDisplayPdf] = useState(false);
  const isOnScreenRef = useRef(false);
  const viewingMonitorRef = useRef(null);
  const numberOfPagesRead = React.useRef(1);
  const totalPages = React.useRef(0);
  const startTime = React.useRef(null);
  const endTime = React.useRef(null);
  const isSendingAnalytics = useRef(false);

  const sendAnalytics = useDebouncedCallback(() => {
    endTime.current = moment();
    const durationInSeconds = endTime.current.diff(startTime.current, 'seconds');
    console.log('@@actualDuration', durationInSeconds);
    if (durationInSeconds < maxReadTimePerPage && !!resourceId && !!materialId) {
      eventBus.publish(EVENT_BUS.SpaceAnalyticsEvents, SpaceInteractionType.VIEW_RESOURCE, {
        resourceId,
        materialId,
        pagesRead: numberOfPagesRead.current,
        totalPages: totalPages.current,
        isCms: isCms,
        duration: durationInSeconds,
        resourceType: ResourceTypeNames.pdf,
      });
    }
    startTime.current = moment();
    isSendingAnalytics.current = false;
  }, 1000);

  const onViewingMonitorChanged = useCallback(
    (isOnscreen) => {
      if (!shouldDisplayPdf) {
        return;
      }
      if (isOnscreen) {
        startTime.current = moment();
      } else {
        sendAnalytics();
      }
      isOnScreenRef.current = isOnscreen;
      console.log('isOnscreen', isOnscreen);
    },
    [sendAnalytics, shouldDisplayPdf]
  );

  useOnScreen(viewingMonitorRef, onViewingMonitorChanged, 500);

  useEffect(() => {
    const onBeforeunLoad = () => {
      sendAnalytics.flush();
    };

    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (isOnScreenRef.current) {
          startTime.current = moment();
        }
      } else {
        if (isOnScreenRef.current) {
          sendAnalytics();
        }
      }
    };

    document.addEventListener('beforeunload', onBeforeunLoad);
    document.addEventListener('visibilitychange', onVisibilityChange, true);
    return () => {
      document.addEventListener('beforeunload', onBeforeunLoad);
      document.removeEventListener('visibilitychange', onVisibilityChange, true);
    };
  }, [sendAnalytics]);

  if (!src) {
    return null;
  }

  const onLoaded = (pages) => {
    totalPages.current = pages;
    if (isOnScreenRef.current) {
      startTime.current = moment();
    }
    if (props.onLoaded) {
      props.onLoaded(pages);
    }
  };

  const debounceOnPageChanged = _debounce(() => {
    if (isOnScreenRef.current && !isSendingAnalytics.current) {
      isSendingAnalytics.current = true;
      sendAnalytics();
    }
  }, 2000);

  const onPageChanged = (pages) => {
    if (pages > numberOfPagesRead.current) {
      numberOfPagesRead.current = pages;
    }
    debounceOnPageChanged();
    if (props.onPageChanged) {
      props.onPageChanged(pages);
    }
  };

  const onDocumentDownloaded = () => {
    if (resourceId && materialId) {
      createResourceInteraction(resourceId, {
        materialId: materialId,
        interactionType: ResourceInteractionType.Downloaded,
      });
    }
  };

  const onScroll = _debounce(() => {
    if (isOnScreenRef.current && !isSendingAnalytics.current) {
      isSendingAnalytics.current = true;
      sendAnalytics();
    }
  }, 2000);

  return (
    <>
      <Waypoint
        onEnter={() => setShouldDisplayPdf(true)}
        fireOnRapidScroll={false}
        debug={false}
        bottomOffset={-100}
      >
        <div className="waypoint-holder" />
      </Waypoint>
      {shouldDisplayPdf && (
        <>
          <PDF
            key={resourceId || materialId}
            ref={ref}
            viewer={viewer}
            src={src}
            downloadable={downloadable}
            popup={false}
            onPageChanged={onPageChanged}
            onDocumentDownloaded={onDocumentDownloaded}
            onLoaded={onLoaded}
            preview={preview}
            materialId={materialId}
            spaceId={spaceId}
            preventEvents={preventEvents}
            onScroll={onScroll}
          />
        </>
      )}
      <div className="waypoint-viewing-monitor" ref={viewingMonitorRef} />
    </>
  );
});

PdfViewer.propTypes = {
  src: PropTypes.string,
  spaceId: PropTypes.string,
  resourceId: PropTypes.number,
  materialId: PropTypes.number,
  downloadable: PropTypes.bool,
  preview: PropTypes.bool,
  preventEvents: PropTypes.bool,
  isCms: PropTypes.bool,
  onLoaded: PropTypes.func,
  onPageChanged: PropTypes.func,
};

PdfViewer.defaultProps = {
  downloadable: false,
  preview: false,
  preventEvents: false,
  onPageChanged: () => null,
  onLoaded: () => null,
  isCms: false,
};

PdfViewer.displayName = 'PdfViewer';

export default PdfViewer;
