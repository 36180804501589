import React from 'react';

function EmptyThemeSettings() {
  return (
    <div className="empty-theme-settings">
      <div className="icon">
        <i className="icon icon-design-1" />
      </div>
      <span>No design to show</span>
      <span className="add-a-block">Add a block to get started.</span>
    </div>
  );
}

export default EmptyThemeSettings;
