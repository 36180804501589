export default {
  generic_error_message: 'Could not process your request, please contact administration',
  error_message: 'Sorry, something went wrong. Please try again!',
  'Welcome to React': 'Welcome to React and react-i18next',
  NotStarted: 'Not Started',
  Started: 'Started',
  Finished: 'Finished',
  no_result_message: "We're sorry we couldn't find anything to match your search",
  joinMeetingDescription:
    'Welcome to this meeting in Meeting Maker. By entering you agree to our terms and conditions in accordance with the GDPR, which you will find <1>here</1>.',
};
