/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import GlobalResourcesTabs from './GlobalResourcesTabs';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { SERVICE_PROVIDER } from '../integrations/integrationConstants';
import { getResourcePreviewContext } from '../resources/ResourcePreviewDialog';
import GlobalResourcesOneDriveTable from '../integrations/onedrive/GlobalResourcesOneDriveTable';
import OneDriveResourcePreviewPane from '../integrations/onedrive/OneDriveResourcePreviewPane';
import GlobalResourcePreviewPane from './GlobalResourcePreviewPane';
import i18n from '../i18n';
import currentOrganization from '../commons/CurrentOrganization';
import GoogleDriveTable from '../integrations/googleDrive/GoogleDriveTable/GoogleDriveTable';
import GoogleDrivePreview from '../integrations/googleDrive/common/GoogleDrivePreview';
import { FeatureFlagsType } from '../app/appConstants';
import ResourcesViewContextProvider from '../components/ResourcesView/ResourcesViewContextProvider';
import GlobalResourcesSpceTable from './GlobalResourcesSpceTable';
import { GlobalResourcesContextConsumer } from './context/GlobalResourcesContextProvider';
import { GLOBAL_PROVIDER_TYPE, getSource } from './utils';
import GlobalResourcesBreadcrumbs from './GlobalResourcesBreadcrumbs';

export const GRSelectType = {
  File: 'file',
  Folder: 'folder',
  All: 'all',
};

const GlobalResourcesPicker = (props) => {
  const {
    multiple,
    selectType,
    otherTypeDisabled,
    previewDisabled,
    isMovingResources,
    disabledFolderIds,
    hideIntegration,
    accept,
    isPortal,
    isAdmin,
    canUploadFolderFromOneDrive,
    canUploadFolderFromGoogleDrive,
    disabledUploadExternal,
    selectedTab,
    onChangeTab,
  } = props;

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [idFlagOneDrive, idFlagGoogleDrive] = checkEnabledFeatures([
    FeatureFlagsType.INTEGRATIONS_ONEDRIVE,
    FeatureFlagsType.INTEGRATIONS_GOOGLEDRIVE,
  ]);
  const isOneDriveEnabled = currentOrganization.hasConnection(SERVICE_PROVIDER.ONEDRIVE);
  const isGoogleDriveEnabled = currentOrganization.hasConnection(SERVICE_PROVIDER.GOOGLE_DRIVE);
  const tabRef = useRef();
  const isGlobalResourcesEnabled = true;

  function handleClickOnBreadcrumbItem(breadcrumb, previousBreadcrumb) {
    console.log('### handleClickOnBreadcrumbItem breadcrumb:', breadcrumb);
    if (tabRef.current?.navigate) {
      tabRef.current.navigate(breadcrumb, previousBreadcrumb);
    }
  }

  function renderPreviewPane(selectedResource) {
    if (!selectedResource) {
      return (
        <div className="no-selected-file">
          {i18n.t('Select a file or folder to view the details')}
        </div>
      );
    }

    if (selectedTab === GLOBAL_PROVIDER_TYPE.ONE_DRIVE) {
      return (
        <OneDriveResourcePreviewPane
          {...selectedResource}
          isAdmin={isAdmin}
          context={getResourcePreviewContext('space')}
        />
      );
    }

    if (selectedTab === GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE) {
      return (
        <GoogleDrivePreview
          key={selectedResource.id}
          {...selectedResource}
          isAdmin={isAdmin}
          context={getResourcePreviewContext('space')}
        />
      );
    }
    return (
      <GlobalResourcePreviewPane
        {...selectedResource}
        key={selectedResource?.id}
        type={selectedResource?.resourceType}
        modified={selectedResource?.lastModified}
        isAdmin={isAdmin}
        context={getResourcePreviewContext('space')}
      />
    );
  }

  function renderBreadcrumb() {
    return (
      <div className="dialog-header">
        <GlobalResourcesBreadcrumbs
          className={isMovingResources ? 'breadcrumbs' : 'gr-select-dialog-title'}
          selectedTab={selectedTab}
          onClickItem={handleClickOnBreadcrumbItem}
        />
      </div>
    );
  }

  function renderContent() {
    switch (selectedTab) {
      case GLOBAL_PROVIDER_TYPE.SPCE:
        return (
          <GlobalResourcesSpceTable
            ref={tabRef}
            isGlobalResourcesEnabled={isGlobalResourcesEnabled}
            isPortal={isPortal}
            multiple={multiple}
            selectType={selectType}
            accept={accept}
            disabledFolderIds={disabledFolderIds}
            otherTypeDisabled={otherTypeDisabled}
            isMovingResources={isMovingResources}
          />
        );
      case GLOBAL_PROVIDER_TYPE.ONE_DRIVE:
        return (
          <GlobalResourcesOneDriveTable
            ref={tabRef}
            isGlobalResourcesEnabled
            isOneDriveEnabled={isOneDriveEnabled}
            multiple={multiple}
            selectType={props.selectType}
            otherTypeDisabled={props.otherTypeDisabled}
            accept={accept}
            isPortal={isPortal}
          />
        );
      case GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE:
        return (
          <GoogleDriveTable
            ref={tabRef}
            isUserView
            isGlobalResourcesEnabled
            isGoogleDriveEnabled={isGoogleDriveEnabled}
            multiple={multiple}
            selectType={props.selectType}
            otherTypeDisabled={props.otherTypeDisabled}
            accept={accept}
            isPortal={isPortal}
          />
        );
      default:
        break;
    }

    return null;
  }

  let showOneDrive = false;
  let showGoogleDrive = false;
  if (selectType === GRSelectType.Folder) {
    showOneDrive = isOneDriveEnabled && idFlagOneDrive && canUploadFolderFromOneDrive;
    showGoogleDrive = isGoogleDriveEnabled && idFlagGoogleDrive && canUploadFolderFromGoogleDrive;
  } else {
    showOneDrive = isOneDriveEnabled && idFlagOneDrive;
    showGoogleDrive = isGoogleDriveEnabled && idFlagGoogleDrive;
  }

  return (
    <>
      <div className="content">
        <div className="main-content">
          <ResourcesViewContextProvider
            key={selectedTab}
            isPicker
            canSelectResource={!isMovingResources}
            id={isMovingResources ? null : 'global-resources'}
            userInfoId={currentOrganization.getUserInfoId()}
            source={getSource(selectedTab)}
          >
            {renderBreadcrumb()}
            <GlobalResourcesTabs
              selectedTab={selectedTab}
              onChangeTab={onChangeTab}
              disabledTabs={disabledUploadExternal}
              showOneDrive={showOneDrive}
              showGoogleDrive={showGoogleDrive}
              hideIntegration={hideIntegration}
            />
            {renderContent()}
          </ResourcesViewContextProvider>
        </div>
        {!previewDisabled && (
          <GlobalResourcesContextConsumer>
            {({ previewResource }) => (
              <div className="content-element preview-pane">
                {renderPreviewPane(previewResource)}
              </div>
            )}
          </GlobalResourcesContextConsumer>
        )}
      </div>
    </>
  );
};

GlobalResourcesPicker.propTypes = {
  multiple: PropTypes.bool,
  selectType: PropTypes.string.isRequired,
  otherTypeDisabled: PropTypes.bool,
  previewDisabled: PropTypes.bool,
  isMovingResources: PropTypes.bool,
  disabledFolderIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  hideIntegration: PropTypes.bool,
  accept: PropTypes.string,
  startupFolder: PropTypes.instanceOf(Object),
  isPortal: PropTypes.bool,
  isAdmin: PropTypes.bool,
  canUploadFolderFromOneDrive: PropTypes.bool,
  canUploadFolderFromGoogleDrive: PropTypes.bool,
  disabledUploadExternal: PropTypes.bool,
  breadcrumbsRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  selectedTab: PropTypes.string,
  onChangeTab: PropTypes.func,
};

GlobalResourcesPicker.defaultProps = {
  multiple: false,
  otherTypeDisabled: false,
  previewDisabled: false,
  isMovingResources: false,
  disabledFolderIds: [],
  isPortal: false,
  isAdmin: false,
  canUploadFolderFromOneDrive: false,
  canUploadFolderFromGoogleDrive: false,
  disabledUploadExternal: false,
};

export default React.memo(GlobalResourcesPicker);
