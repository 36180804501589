import React from 'react';
import { AlertTitle } from '@mui/material';
import './style.scss';
import ButtonEnhanced from '../ButtonEnhanced';

type WarningMessageProps = {
  title: string;
  description: string;
  actionButton?: JSX.Element;
  buttonLabel?: string;
  onClickButton?: () => void;
};

function WarningMessage(props: WarningMessageProps) {
  const { title, description, buttonLabel, onClickButton, actionButton } = props;
  return (
    <div className="warning-custom-message-popup">
      <div className="warning-custom-message-popup-content">
        {title && <AlertTitle>{title}</AlertTitle>}

        {description && <span>{description}</span>}
      </div>
      {actionButton}
      {!actionButton && buttonLabel && (
        <div className="warning-custom-message-popup-actions">
          <ButtonEnhanced className="regular-rounded-button" onClick={onClickButton}>
            {buttonLabel}
          </ButtonEnhanced>
        </div>
      )}
    </div>
  );
}

export default WarningMessage;
