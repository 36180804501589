import React, { PureComponent } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/system';
import { Checkbox, FormControlLabel } from '@mui/material';

import ConfirmationDialog from './ConfirmationDialog';
import { defaultTheme } from '../theme';
import i18n from '../i18n';

let resolve;
let containerElement;
let rootEl;

class ConfirmService extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleHistoryStateChanged = this.handleHistoryStateChanged.bind(this);
    this.dontAskAgain = false;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleHistoryStateChanged);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount ConfirmService');
    window.removeEventListener('popstate', this.handleHistoryStateChanged);
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (rootEl) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 0);
    }
    if (resolve) resolve(retVal);
  }

  static show(
    title,
    message,
    okLabel = 'yes',
    cancelLabel = 'no',
    hideCancelButton = false,
    icon = null,
    showCloseIcon = true,
    className = '',
    onClickCancel = null,
    dontAskAgain = false,
    dataIds = null
  ) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);

    rootEl.render(
      <ThemeProvider theme={defaultTheme}>
        <ConfirmService
          title={title}
          message={message}
          okLabel={okLabel}
          cancelLabel={cancelLabel}
          hideCancelButton={hideCancelButton}
          icon={icon}
          showCloseIcon={showCloseIcon}
          className={className}
          onClickCancel={onClickCancel}
          dontAskAgain={dontAskAgain}
          dataIds={dataIds}
        />
      </ThemeProvider>
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose() {
    if (this.props.dontAskAgain) {
      this.setState({ isOpen: false }, () => {
        ConfirmService.destroy({
          isConfirmed: false,
          dontAskAgain: this.dontAskAgain,
        });
      });
    } else {
      this.setState({ isOpen: false }, () => {
        ConfirmService.destroy();
      });
    }
  }

  handleCancel() {
    if (this.props.onClickCancel) {
      this.props.onClickCancel();
    }
    this.handleClose();
  }

  handleHistoryStateChanged() {
    this.setState({ isOpen: false }, () => {
      ConfirmService.destroy();
    });
  }

  handleConfirm() {
    if (this.props.dontAskAgain) {
      this.setState({ isOpen: false }, () => {
        ConfirmService.destroy({
          isConfirmed: true,
          dontAskAgain: this.dontAskAgain,
        });
      });
    } else {
      this.setState({ isOpen: false }, () => {
        ConfirmService.destroy(true);
      });
    }
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }
    return (
      <ConfirmationDialog
        title={this.props.title}
        onClickOK={this.handleConfirm}
        onClickCancel={this.handleCancel}
        onClickCloseButton={this.handleClose}
        okLabel={this.props.okLabel}
        cancelLabel={this.props.hideCancelButton ? null : this.props.cancelLabel}
        icon={this.props.icon}
        showCloseIcon={this.props.showCloseIcon}
        className={this.props.className}
        dataIds={this.props.dataIds}
      >
        <div className="message">{this.props.message}</div>
        {this.props.dontAskAgain && (
          <div style={{ textAlign: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    this.dontAskAgain = event.target.checked;
                  }}
                  name="checklist"
                />
              }
              label={i18n.t("Don't ask me again")}
            />
          </div>
        )}
      </ConfirmationDialog>
    );
  }
}

ConfirmService.propTypes = {
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  showCloseIcon: PropTypes.bool,
  className: PropTypes.string,
  onClickCancel: PropTypes.func,
  dontAskAgain: PropTypes.bool,
  dataIds: PropTypes.instanceOf(Object),
};

ConfirmService.defaultProps = {
  icon: null,
  showCloseIcon: true,
  dontAskAgain: false,
};

export default ConfirmService;
