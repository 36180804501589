import WebFont from 'webfontloader';
import memoizeOne from 'memoize-one';
import _unionBy from 'lodash/union';
import _difference from 'lodash/difference';
import _compact from 'lodash/compact';
import { removeFontNameExtension } from './utils';
import { FontVariantsDictionary } from '../../../app/appConstants';

export function getFontActivated() {
  const getLinks = document.querySelectorAll('link[href*="https://fonts.googleapis.com"]');
  // console.log('### 113 getFontActivated:', getLinks);
  let fonts = [];
  getLinks.forEach((item) => {
    const fontsItem = decodeURI(item.href)
      .replace('https://fonts.googleapis.com/css?family=', '')
      .replaceAll('+', ' ')
      .split('|');

    fonts = fonts.concat(fontsItem);
  });

  return _unionBy(fonts);
}

export function loadMyFonts(fonts) {
  if (!fonts?.length) {
    return;
  }
  fonts.forEach((font) => {
    // CheckFont is activated
    if (!document.getElementById(font.resourceName)) {
      const name = removeFontNameExtension(font.resourceName);
      const markup = [
        "@font-face { font-family: '",
        name,
        "'; font-style: 'normal'; font-weight: 'normal'; src: url('",
        font.url,
        "'); }\n",
      ].join('');

      const style = document.createElement('style');
      style.setAttribute('id', font.resourceName);
      style.setAttribute('type', 'text/css');
      style.innerHTML = markup;
      // document.getElementsByTagName('head')[0].appendChild(style);
      document.head.appendChild(style);
    }
  });
}

export function loadGoogleFonts(fonts) {
  // console.log('### 113 loadGoogleFonts:', fonts);
  const fontsIsActives = getFontActivated();
  // console.log('### 113 fontsIsActives:', fontsIsActives);
  let fontsIsNotActive = _difference(fonts, fontsIsActives);
  fontsIsNotActive = _compact(fontsIsNotActive);
  fontsIsNotActive = _unionBy(fontsIsNotActive);
  // console.log('### 113 fontsIsNotActive: ', fontsIsNotActive);
  if (!fontsIsNotActive?.length) {
    return;
  }
  WebFont.load({
    classes: false,
    google: {
      families: fontsIsNotActive,
    },
  });
}

export const fontsWithVariants = memoizeOne((fonts) => {
  const fontsOptions = [];
  fonts.forEach((font) => {
    if (font.variants.length !== 0) {
      font.variants.forEach((variant) => {
        if (FontVariantsDictionary[variant]) {
          const variantDetails = FontVariantsDictionary[variant].split(':');
          fontsOptions.push({
            id: font.name.concat(variantDetails[0]),
            name: variant === 'regular' ? font.name : font.name.concat(' ', variantDetails[0]),
            fontFamily: font.family,
            variant: font.name.concat(':', variantDetails[1]),
            groupName: font.groupName ? font.groupName : 'All fonts',
            styles:
              variant === 'regular' || variant === 'italic'
                ? { fontStyle: variant }
                : { fontWeight: variant },
          });
        }
      });
    } else {
      fontsOptions.push({
        id: font.name,
        name: font.name,
        fontFamily: font.name,
        groupName: font.groupName ? font.groupName : 'All fonts',
        variant: font.name,
        styles: null,
      });
    }
  });
  return fontsOptions;
});
