import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, MenuItem } from '@mui/material';
import SwitchEnhanced from '../SwitchEnhanced';
import './DownloadFileItem.scss';
import { updateRuntimeMaterial } from '../../resources/ResourceServices';
import { sendNotification } from '../../commons/utils';
import i18n from '../../i18n';

const DownloadFileItem = (props) => {
  const [isDownloadable, setIsDownloadable] = useState(props.checked);
  const [processing, setProcessing] = React.useState(false);

  async function handleOnChangeDownloadFile(event) {
    if (props.file) {
      setProcessing(true);
      const { spaceId, directoryId, id } = props.file;
      const newIsDownloadable = event.target.checked;
      setIsDownloadable(newIsDownloadable);
      const newFile = {
        ...props.file,
        isDownloadable: newIsDownloadable,
      };
      await updateRuntimeMaterial(spaceId, directoryId, id, newFile);
      setProcessing(false);
      sendNotification(i18n.t('Updated successfully'), { type: 'success' });
      props.onChange(newIsDownloadable);
    }
  }

  return (
    <MenuItem className="download-file-item" disabled={processing}>
      {processing ? (
        <i className="icon-spinner displayFlex justifyContentCenter width24" />
      ) : (
        <span className="icon-download-option displayFlex justifyContentCenter width24" />
      )}
      <FormControlLabel
        label="Can download"
        labelPlacement="start"
        control={
          <SwitchEnhanced
            color="primary"
            checked={isDownloadable}
            onChange={handleOnChangeDownloadFile}
          />
        }
      />
    </MenuItem>
  );
};

DownloadFileItem.propTypes = {
  checked: PropTypes.bool,
  file: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
};

export default DownloadFileItem;
