import { useCallback, useEffect, useState } from 'react';
import { SelectedFolderProps } from './context/Interfaces';
import { BreadcrumbsData } from './context/BreadcrumbsContextProvider';
import useMountEffect from '../commons/useMountEffect';
import useNavigationsQuery from './useNavigationsQuery';

function readBreadcrumbsDataFromStorage(storageCacheKey: string): BreadcrumbsData {
  let breadcrumbs: SelectedFolderProps[] = [];
  let selectedFolder: SelectedFolderProps | null = null;
  try {
    const item = window.sessionStorage.getItem(storageCacheKey);
    if (item) {
      breadcrumbs = JSON.parse(item);
      if (breadcrumbs.length > 0) {
        selectedFolder = breadcrumbs[breadcrumbs.length - 1];
        selectedFolder.page = 0;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return { breadcrumbs, selectedFolder };
}

export function getStorageCacheKey(provider: string): string {
  return `gr-breadcrumbs-${provider}`;
}

const usePersistedBreadcrumbs = (provider: string, enabled: boolean) => {
  const storageCacheKey = getStorageCacheKey(provider);

  const [breadcrumbsData, setBreadcrumbsData] = useState<BreadcrumbsData>(() =>
    readBreadcrumbsDataFromStorage(storageCacheKey)
  );

  const breadcrumbs = breadcrumbsData.breadcrumbs;
  const selectedFolder = breadcrumbsData.selectedFolder;
  const parentId = selectedFolder?.parentId;
  const driveId = selectedFolder?.driveId;

  const fetchNavigations = useNavigationsQuery(provider, parentId, driveId);

  const hasBreadcrumbs = breadcrumbs !== null;

  const syncBreadcrumbs = useCallback(async () => {
    if (!parentId || !enabled) {
      return;
    }
    const resp = await fetchNavigations();
    setBreadcrumbsData((prev) => {
      if (prev.selectedFolder?.parentId !== parentId) {
        return prev;
      }
      const data = resp.data ? [...resp.data] : [];
      data.push(prev.selectedFolder);
      return { breadcrumbs: data, selectedFolder: prev.selectedFolder };
    });
  }, [enabled, fetchNavigations, parentId]);

  useMountEffect(() => {
    syncBreadcrumbs();
  });

  useEffect(() => {
    if (hasBreadcrumbs) {
      return;
    }
    syncBreadcrumbs();
  }, [syncBreadcrumbs, hasBreadcrumbs]);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (breadcrumbs === null) {
      return;
    }
    sessionStorage.setItem(storageCacheKey, JSON.stringify(breadcrumbs));
  }, [enabled, breadcrumbs, storageCacheKey]);

  const clearBreadcrumbsData = useCallback(() => {
    setBreadcrumbsData({ breadcrumbs: null, selectedFolder: null });
    sessionStorage.removeItem(storageCacheKey);
  }, [setBreadcrumbsData, storageCacheKey]);

  return { breadcrumbs, selectedFolder, setBreadcrumbsData, clearBreadcrumbsData };
};

export default usePersistedBreadcrumbs;
