import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryCache, { CacheKeys } from '../app/queryCache';
import Loading from '../components/Loading';
import spaceUser from './spaceUser';

const RedirectToNewSpace = ({ urlSlug, clearCache = false }) => {
  const [flag, setFlag] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setFlag(true);
    }, 1000);
  }, []);

  if (!flag) {
    return (
      <div className="app app-runtime">
        <Loading />
      </div>
    );
  }
  if (clearCache) {
    queryCache.removeQueries({
      queryKey: [CacheKeys.getSpaceAccessToken, urlSlug, spaceUser.getInvc()],
      exact: true,
    });
    spaceUser.reset();
  }

  const toURL = `/s/${urlSlug}${location.search}`;
  return <Navigate to={toURL} state={location.state} />;
};

RedirectToNewSpace.propTypes = {
  urlSlug: PropTypes.string,
  clearCache: PropTypes.bool,
};

export default RedirectToNewSpace;
