import React from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import memoizeOne from 'memoize-one';
import { createTheme } from '@mui/material';

export const colors = {
  green: '#00cd7f',
};

const defaultPrimaryColor = '#000000';
const defaultSecondaryColor = '#ffffff';
const primaryLightColor = '#17171a';
const primaryVariant = '#222228';

export const themeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['MediumLLWeb-Regular', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
    body2: {
      fontSize: '15px',
      color: defaultPrimaryColor,
    },
    body1: {
      fontSize: '15px',
      color: defaultPrimaryColor,
    },
    button: {
      fontFamily: ['MediumLLWeb-Bold', 'Helvetica Neue', 'Helvetica', 'Arial'],
      fontSize: '14px',
    },
  },
  palette: {
    primary: {
      main: defaultPrimaryColor,
    },
    secondary: {
      main: defaultSecondaryColor,
    },
    primaryLight: {
      main: primaryLightColor,
    },
    primaryVariant: {
      main: primaryVariant,
    },
    error: {
      main: '#f44a4a',
    },
    action: {
      disabled: 'rgba(255, 255, 255, 0.26)',
    },
    background: {
      default: '#ECEEEF',
      transparent: 'transparent',
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
};

// A custom theme for this app
export const defaultTheme = createTheme(themeOptions);

export const getRuntimeTheme = memoizeOne(() => {
  const runtimeThemeOptions = _cloneDeep(themeOptions);
  runtimeThemeOptions.typography.button.fontFamily = [
    'MediumLLSub-BoldSubset',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
  ];
  // runtimeThemeOptions.typography.body2.color = secondaryColor;
  // runtimeThemeOptions.typography.body2.color = secondaryColor;
  // runtimeThemeOptions.palette.primary.main = secondaryColor;
  // runtimeThemeOptions.palette.secondary.main = primaryColor;
  runtimeThemeOptions.palette.background.default = '#f4f6f8';
  return createTheme(runtimeThemeOptions);
});

export const getTheme = memoizeOne((themeSettings = null) => {
  if (!themeSettings) {
    document.querySelector('body').style.setProperty('--primary-color', defaultPrimaryColor);
    document.querySelector('body').style.setProperty('--secondary-color', defaultSecondaryColor);
    return createTheme(themeOptions);
  }
  const primaryColor = themeSettings.mainColor || defaultPrimaryColor;
  const secondaryColor = themeSettings.secondaryColor || defaultSecondaryColor;
  document.querySelector('body').style.setProperty('--primary-color', primaryColor);
  document.querySelector('body').style.setProperty('--secondary-color', secondaryColor);
  themeOptions.typography.body2.color = primaryColor;
  themeOptions.typography.body2.color = primaryColor;
  themeOptions.palette.primary.main = primaryColor;
  themeOptions.palette.secondary.main = secondaryColor;
  return createTheme(themeOptions);
});

export const getThemeContext = memoizeOne((theme) => {
  return { theme };
});

export default React.createContext({});
