import { OrganizationUserRole, SpaceUserRoleNames } from '../app/appConstants';
import { isOrgRole } from '../commons/utils';

export class SpaceUser {
  constructor() {
    this.data = null;
    const aclvPin = sessionStorage.getItem('aclvPin');
    if (aclvPin) {
      this.aclvPin = aclvPin;
    }
    const data = sessionStorage.getItem('spaceUser');
    if (data) {
      const jsonData = JSON.parse(data);
      this.data = jsonData;
    }
  }

  isHost() {
    return this.data?.spaceAccessSession?.role === SpaceUserRoleNames.Host;
  }

  isSupervisor() {
    return this.data?.spaceAccessSession?.role === SpaceUserRoleNames.Supervisor;
  }

  saveToStorage() {
    sessionStorage.setItem('spaceUser', JSON.stringify(this.data));
  }

  isBetaTesting() {
    return this.data?.spaceAccessSession?.isBetaTesting;
  }

  isViewOnly() {
    return this.viewOnly === 1;
  }

  setViewOnly(viewOnly) {
    if (sessionStorage.getItem('isViewOnly') === '1') {
      this.viewOnly = viewOnly;
      return;
    }
    if (viewOnly === 1) {
      sessionStorage.setItem('isViewOnly', viewOnly);
    }
    this.viewOnly = viewOnly;
  }

  setPin(pin) {
    this.aclvPin = pin;
    sessionStorage.setItem('aclvPin', pin);
  }

  removePin() {
    this.aclvPin = null;
    sessionStorage.removeItem('aclvPin');
  }

  getPin() {
    return this.aclvPin;
  }

  getAccessToken() {
    return this.data?.spaceAccessToken;
  }

  getSpaceAccessSession() {
    return this.data?.spaceAccessSession;
  }

  getSubscriptionTypeId() {
    return this.data?.spaceAccessSession?.subscriptionTypeId;
  }

  getFullName() {
    return this.data?.spaceAccessSession?.fullName;
  }

  getEmail() {
    return this.data?.spaceAccessSession?.email;
  }

  getSpaceId() {
    return this.data?.spaceAccessSession?.spaceId;
  }

  getSlugSpace() {
    return this.data?.spaceAccessSession?.urlSlug;
  }

  getUrlSlugSpace(value) {
    if (value) {
      const url = this.data?.spaceAccessSession?.urlSlug;
      if (url === value || value === this.data?.spaceAccessSession?.spaceId) {
        return value;
      }
    }
    return this.data?.spaceAccessSession?.spaceId;
  }

  getUserInfoId() {
    return this.data?.spaceAccessSession?.userInfoId;
  }

  getParticipantId() {
    return this.data?.spaceAccessSession?.participantId;
  }

  getParticipantInfo() {
    return {
      id: this.data?.spaceAccessSession?.userInfoId,
      displayName: this.getFullName(),
      email: this.getEmail(),
      firstName: this.data?.spaceAccessSession?.firstName,
      lastName: this.data?.spaceAccessSession?.lastName,
    };
  }
  getInvc() {
    return this.data?.invc;
  }

  getChecksum() {
    return this.data?.checksum;
  }

  getSpaceUrl(withCode = false) {
    const id = this.getSlugSpace() ?? this.getSpaceId();
    if (!withCode) {
      return `/s/${id}`;
    }
    return `/s/${id}?invc=${this.getInvc()}`;
  }
  getData() {
    return this.data;
  }

  getOrganizationId() {
    return this.data?.spaceAccessSession?.organizationId;
  }

  login(spaceAccessToken, spaceAccessSession, invc, checksum = null, shareSpaceCode = null) {
    this.data = { spaceAccessSession, spaceAccessToken, invc, checksum, shareSpaceCode };
    sessionStorage.setItem('spaceUser', JSON.stringify(this.data));
  }

  hasLoggedIn() {
    return this.getUserInfoId() > 0;
  }

  isVisitor() {
    return this.data?.spaceAccessSession?.role === 'Visitor';
  }

  isHidden() {
    return this.data?.spaceAccessSession?.isHidden === true;
  }

  getUserInfo() {
    if (!this.data) {
      return null;
    }
    return {
      ...this.data?.spaceAccessSession,
      contactEmail: this.data?.spaceAccessSession.email,
    };
  }

  getShareSpaceCode() {
    return this.data?.shareSpaceCode;
  }

  setShareSpaceCode(code) {
    if (!this.data) {
      return;
    }
    this.data.shareSpaceCode = code;
    sessionStorage.setItem('spaceUser', JSON.stringify(this.data));
  }

  reset() {
    this.data = null;
    this.viewOnly = null;
    sessionStorage.removeItem('spaceUser');
    sessionStorage.removeItem('isViewOnly');
    sessionStorage.removeItem('aclvPin');
    // console.log('spaceUser reset()');
  }

  getRole() {
    return this.data?.spaceAccessSession?.role;
  }

  getOrgRole() {
    return this.data?.spaceAccessSession?.orgRole;
  }

  isPartner() {
    return isOrgRole(this.data?.spaceAccessSession?.orgRole, OrganizationUserRole.Partner);
  }
}

const spaceUser = new SpaceUser();

export default spaceUser;
