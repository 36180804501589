/* eslint-disable */
import { useState, useEffect } from 'react';
import useDebounce from './useDebounce';

export default function useOnScreen(ref, onChange = null, delay = 1000, threshold = [1]) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        // rootMargin: '0px',
        threshold,
        delay,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const isOnScreen = useDebounce(isIntersecting, delay);

  useEffect(() => {
    if (onChange) {
      onChange(isOnScreen);
    }
  }, [isOnScreen]);

  // console.log('isIntersecting', isIntersecting, rs);
  return isOnScreen;
}
