/* eslint react/jsx-props-no-spreading: 0 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import i18n from '../i18n';
import {
  extractNamesFromEmail,
  getUrlParameter,
  removeURLParams,
  sendNotification,
} from '../commons/utils';
import localUserInfo from '../commons/LocalUserInfo';
import { signUpSpaceUser } from './spaceAccessServices';
import spaceUser from './spaceUser';
import RequestAccessDialog from '../components/RequestAccessDialog';
import { TermsAndConditionData } from './TermsAndConditionData';
import { ExceptionTypes, FeatureFlagsType } from '../app/appConstants';
import { FeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import withRouter from '../commons/withRouter';
import OfficialButton from '../components/OfficialButtons';
import './SpaceUserSignup.scss';
import { isValidEmail } from '../commons/ValidationUtils';

class SpaceUserSignup extends PureComponent {
  constructor(props) {
    super(props);
    const userInfo = localUserInfo.getUserInfo();
    this.state = {
      isOpen: true,
      email: userInfo?.email || '',
      firstName: userInfo?.firstName || '',
      lastName: userInfo?.lastName || '',
      customerTC: false,
      spceTC: false,
      errors: '',
    };
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClickEnterSpace = this.handleClickEnterSpace.bind(this);
    this.handleConfirmCustomerTC = this.handleConfirmCustomerTC.bind(this);
    this.handleConfirmSpceTC = this.handleConfirmSpceTC.bind(this);
    this.error = getUrlParameter('error');
  }

  componentDidMount() {
    if (this.error === 'invalid_magic_link') {
      setTimeout(() => {
        sendNotification(i18n.t('This link is already used or expired. Please try again.'), {
          type: 'error',
        });
        removeURLParams(['error']);
      }, 200);
    }
  }

  validateEmail(value) {
    console.log('name', this.errors);
    let error = null;
    const lowerCaseValue = value.toLowerCase();
    if (value?.trim() === '') {
      error = i18n.t('This field is required');
    }
    if (!isValidEmail(lowerCaseValue)) {
      error = i18n.t('Please enter a valid email address.');
    }
    return error;
  }

  validateName(name) {
    console.log('name', this.errors);
    return name?.trim() === '' ? 'This field is required' : '';
  }

  isValid() {
    const { email, firstName, lastName } = this.state;
    const emailError = this.validateEmail(email);
    const firstNameError = this.validateName(firstName);
    const lastNameError = this.validateName(lastName);

    if (emailError || firstNameError || lastNameError) {
      this.setState({
        errors: { email: emailError, firstName: firstNameError, lastName: lastNameError },
      });
    }
    return !emailError && !firstNameError && !lastNameError;
  }

  handleChangeTextField(e) {
    const { name, value } = e.target;

    switch (name) {
      case 'email':
        this.setState(() => {
          if (isValidEmail(value)) {
            const { firstName, lastName } = extractNamesFromEmail(value);
            return {
              email: value,
              firstName,
              lastName,
              errors: (prev) => ({ ...prev.errors, email: this.validateEmail(value) }),
            };
          } else {
            return {
              email: value,
              errors: (prev) => ({ ...prev.errors, email: this.validateEmail(value) }),
            };
          }
        });
        break;
      case 'firstName':
        this.setState({ [name]: value });
        this.setState({
          errors: (prev) => ({ ...prev.errors, firstName: this.validateName(value) }),
        });
        break;
      case 'lastName':
        this.setState({ [name]: value });
        this.setState({
          errors: (prev) => ({ ...prev.errors, lastName: this.validateName(value) }),
        });
        break;
      default:
        break;
    }
  }

  handleCancel() {
    this.setState({ isOpen: false });
  }

  handleConfirmCustomerTC(isChecked) {
    this.setState({ customerTC: isChecked, showError: false });
  }

  handleConfirmSpceTC(isChecked) {
    this.setState({ spceTC: isChecked, showError: false });
  }

  goToSpace(invitation) {
    const url = `/s/${this.props.urlSlug}${location.search || '?'}&invc=${invitation.code}`;
    this.props.navigate(url);
  }

  async handleClickEnterSpace(event) {
    event.preventDefault();
    if (!this.isValid()) {
      return;
    }

    const [enabledTC] = this.context.checkEnabledFeatures([FeatureFlagsType.TC]);

    if (this.props.space?.termsAndConditions?.src) {
      if ((!this.state.customerTC && enabledTC) || !this.state.spceTC) {
        this.setState({ showError: true });
        return;
      }
    } else if (!this.state.spceTC) {
      this.setState({ showError: true });
      return;
    }
    this.setState({ isSavingData: true });

    const { email, firstName, lastName } = this.state;
    const data = {
      email: email.trim(),
      firstName,
      lastName,
    };

    try {
      const { id } = this.props.space;

      localUserInfo.setUserInfo(data);

      const resp = await signUpSpaceUser(id, data, spaceUser.isViewOnly());

      const { invitation } = resp;

      this.goToSpace(invitation);
    } catch (e) {
      console.log('ERROR', e);
      this.setState({ isSavingData: false });

      if (e.error.type === 'SpaceAccessException') {
        this.setState({ showRequestDialog: true });
        await RequestAccessDialog.show(
          'NO ACCESS',
          'This is a private space, the host has to grant you access. ',
          data,
          this.props.space.id
        );
        this.setState({ showRequestDialog: false });
      } else if (e.error.type === 'ValidationException') {
        const errorMessageEmail = e.error.errors.find((error) => error.field === 'Payload.Email');
        this.setState((prev) => ({
          errors: {
            ...prev.errors,
            email: errorMessageEmail.message,
          },
        }));
      } else if (e.error.type === ExceptionTypes.TranslatableException) {
        sendNotification(e.error.message, {
          type: 'error',
        });
      } else {
        sendNotification(i18n.t('Sorry, something went wrong. Please try again!'), {
          type: 'error',
        });
      }
    }
  }

  render() {
    const { isSavingData, isOpen, email, firstName, lastName, showRequestDialog } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <div className={`white join-space-dialog${showRequestDialog ? ' hidden' : ''}`}>
        <form onSubmit={this.handleClickEnterSpace}>
          <div className="mobile-join-space-box">
            <div className="content">
              <div className="message">
                <div className="title" title={this.props.space.name}>
                  {i18n.t(`Welcome to ${this.props.space.name}`)}
                </div>
                <span className="sub-title">
                  {i18n.t('Please provide your name and email address to join.')}
                </span>
              </div>
              <div className="form fullwidth">
                <TextField
                  className="form-input"
                  size="small"
                  autoFocus
                  maxLength={200}
                  name="email"
                  value={email}
                  onChange={this.handleChangeTextField}
                  placeholder={`${i18n.t('Email')} *`}
                  error={!!this.state.errors.email}
                  helperText={this.state.errors.email}
                />
              </div>
              <div className="form">
                <TextField
                  className="form-input"
                  size="small"
                  maxLength={100}
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChangeTextField}
                  placeholder={`${i18n.t('First name')} *`}
                  error={!!this.state.errors.firstName}
                  helperText={this.state.errors.firstName}
                />
                <TextField
                  className="form-input"
                  size="small"
                  maxLength={100}
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChangeTextField}
                  placeholder={`${i18n.t('Last name')} *`}
                  error={!!this.state.errors.lastName}
                  helperText={this.state.errors.lastName}
                />
              </div>
              <div className="terms-and-condition">
                <TermsAndConditionData
                  termsAndConditions={this.props.space?.termsAndConditions}
                  organizationName={this.props.space?.organizationName}
                  handleConfirmCustomerTC={this.handleConfirmCustomerTC}
                  handleConfirmSpceTC={this.handleConfirmSpceTC}
                  showError={this.state.showError}
                />
              </div>

              <div className="button-wrapper">
                <OfficialButton
                  label={i18n.t('Enter space')}
                  disabled={isSavingData}
                  isProcessing={isSavingData}
                  type="submit"
                  fullWidth
                  variant="large-primary"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SpaceUserSignup.propTypes = {
  space: PropTypes.instanceOf(Object),
  urlSlug: PropTypes.string,
  navigate: PropTypes.func,
};

SpaceUserSignup.contextType = FeatureFlagsContext;

export default withRouter(SpaceUserSignup);
