/* eslint react/jsx-props-no-spreading: 0 */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router';
import Button from '@mui/material/Button';
import {
  SpaceState,
  ResourceInteractionType,
  ResourceType,
  FeatureFlagsType,
} from '../app/appConstants';
import queryCache, { clearCache, CacheKeys } from '../app/queryCache';
import useMountEffect from '../commons/useMountEffect';
import spaceUser from './spaceUser';
import Loading from '../components/Loading';
import HomeButton from '../components/HomeButton';
import SpaceFooter from './SpaceFooter';
import { useSpaceContext } from './SpaceContext';
import './style.scss';
import LoginMenu from '../components/LoginMenu';

import ThemePreview from '../cms/ThemePreview';
import MaterialsContextProvider from './Materials/MaterialsContextProvider';
import SpaceUsersContextProvider from './SpaceUsers/SpaceUsersContextProvider';
import ShareLinkButton from '../components/ShareLinkButton';
import i18n from '../i18n';
import { truncateText, getUrlParameter, openWindow, sendNotification } from '../commons/utils';
import NotificationButton from '../components/Notification/NotificationButton';

// import VaamButton from '../components/Vaam/VaamButton';
import Banner from '../components/Banner';
import { isExternalLinkNotVideo } from '../commons/ResourceUtils';
import IntegrationRuntimeContextProvider from './integrations/IntegrationRuntimeContextProvider';
import ClosedSpaceDialog from '../homepage/ClosedSpaceDialog';
import CheckRequestAccessPrivateSpace from './SpaceUsers/CheckRequestAccessPrivateSpace';
import ResourceShareLink from './ResourceShareLink';
import ResourceDeletedHandler from './ResourceDeletedHandler';
import LogoSpace from '../components/LogoSpace';
import CreateSpaceDialog from '../createSpace/CreateSpaceDialog';
import { shareSpace } from '../homepage/spaceService';
import { createResourceInteraction, shareMaterialTrack } from '../commons/CommonServices';
import useBrowserUtils from '../commons/useBrowserUtils';
import HandleAcceptCookie from './HandleAcceptCookie';
import SpaceSidebar from './sidebar/SpaceSidebar';
import ResourcePreviewDialog, {
  getResourcePreviewContext,
} from '../resources/ResourcePreviewDialog';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import CreateSpaceUserSessionHandler from './CreateSpaceUserSessionHandler';
import VaamRecordingBox from '../vaam/VaamRecordingBox';
import eventBus, { EVENT_BUS } from '../commons/EventBus';
import { useForceUpdate } from '../commons/CustomHooks';
import { useAppContext } from '../components/AppProvider';
import SpaceSidebarHandler from './sidebar/SpaceSidebarHandler';
import SpaceLMSSignalRHandler from './SpaceLMSSignalRHandler';
import { getAllAssessments } from '../cms/cmsUtils';

const SpaceContainer = (props) => {
  const { hasSidebar, className, spaceOwner } = props;

  const {
    isHostOfSpace,
    sidebar,
    setSidebar,
    isLoading,
    isFetchingToken,
    isMobile,
    showVaamRecorder,
    setShowVaamRecorder,
    space,
    theme,
    isPublicSpace,
    resourceHubConnection,
    isPrivateSpace,
    isSpaceDeleted,
    isSpaceUserLoggedIn,
    onChangeNavigation,
    pagesBlock,
    currentPage,
  } = useSpaceContext();
  const blocks = currentPage?.blocks;
  const [showAcceptCookie, setShowAcceptCookie] = useState(
    isPublicSpace === true && sessionStorage.getItem('hasConsented') !== 'true'
  );
  const { isAuthenticated } = useAppContext();

  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const spaceId = space?.id;
  const location = useLocation();
  const requestAcceptId = getUrlParameter('req-id', null, true);
  const materialId = getUrlParameter('srId', null, true);
  const channelId = getUrlParameter('channelId', null, true);
  const sharingFolderId = getUrlParameter('drId', null, true);
  const isEditSpace = getUrlParameter('isEdit', null, false);

  const createSpacePopupRef = useRef();
  const { removeURLParams, setURLParams } = useBrowserUtils();
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const attachedMessage = location?.state?.attachedMessage;
  const forceUpdate = useForceUpdate();
  const isHost = spaceUser.isHost();
  const isAssessmentExisted = getAllAssessments(space);

  useMountEffect(() => {
    clearCache([CacheKeys.fetchActiveTemplateFeedback, spaceId]);
  });

  useEffect(() => {
    const unsubscribe = eventBus.subscribe(EVENT_BUS.ReloadSpaceContainer, forceUpdate);
    return () => {
      unsubscribe();
    };
  }, [forceUpdate]);

  useEffect(() => {
    // console.log('### SpaceContainer useEffect');
    async function trackSharedSpaceEvent() {
      const sharedCode = getUrlParameter('c');
      if (sharedCode) {
        await shareSpace(spaceId, sharedCode);
      }
    }

    async function trackSharedMaterialEvent() {
      if (materialId) {
        const sharedCode = spaceUser.getShareSpaceCode();
        if (sharedCode) {
          shareMaterialTrack(spaceId, materialId, {
            code: sharedCode,
            isClickButton: false,
          });
        }
      }
    }
    if (isSpaceUserLoggedIn) {
      if (materialId) {
        trackSharedMaterialEvent();
      } else {
        trackSharedSpaceEvent();
      }
      // Keep it until we have a good approach to introduce this to clients
      // setTimeout(() => {
      //   const shareCode = spaceUser.getShareSpaceCode();
      //   if (shareCode) {
      //     setURLParams({ c: shareCode });
      //   }
      // });
    }
  }, [isSpaceUserLoggedIn, materialId, spaceId, setURLParams]);

  useEffect(() => {
    if (attachedMessage) {
      sendNotification(attachedMessage, { type: 'success' });
    }
  }, [attachedMessage]);

  const isSpaceClosed = space?.state === SpaceState.Closed && !space?.isTemplate;

  useEffect(() => {
    if (!spaceUser.data) {
      return;
    }

    if (isSpaceClosed && !isHost) {
      ClosedSpaceDialog.show(spaceOwner.contactEmail);
    }
  }, [isSpaceClosed, spaceOwner?.contactEmail, isHost]);

  const createSpacePopupRefReady = createSpacePopupRef?.current;

  React.useEffect(() => {
    if (isEditSpace && isHost && spaceId && createSpacePopupRef?.current) {
      createSpacePopupRef?.current?.onOpen(
        {
          id: spaceId,
        },
        'edit'
      );
      removeURLParams(['isEdit']);
    }
  }, [isEditSpace, spaceId, createSpacePopupRefReady, removeURLParams, isHost]);

  useEffect(() => {
    return () => {
      console.log('### 1412 unmount!');
      sessionStorage.removeItem('spaceUserSessionId');
    };
  }, []);

  const oldAccessToken = queryCache.getQueryData([
    CacheKeys.getSpaceAccessToken,
    space.id,
    spaceUser.getInvc(),
  ]);

  const handleOnCloseSidebar = useCallback(() => {
    if (sharingFolderId) {
      removeURLParams(['drId']);
    }
  }, [removeURLParams, sharingFolderId]);

  const handleOpenResource = useCallback(
    (resource) => {
      console.log('view shared resource', resource);
      if (isExternalLinkNotVideo(resource, true)) {
        openWindow(resource.url, '_blank');
      } else {
        setSelectedMaterial(resource);
      }
      if (materialId) {
        if (
          resource?.resourceType !== ResourceType.video &&
          resource?.resourceType !== ResourceType.audio
        ) {
          createResourceInteraction(resource.resourceId, {
            materialId: materialId,
            interactionType: ResourceInteractionType.Click,
          });
        }
      }
    },
    [materialId]
  );

  const isFetchingNewToken = !oldAccessToken && isFetchingToken;
  if (!space || isLoading || isFetchingNewToken) {
    return <Loading />;
  }

  const name = theme?.name;
  const backgroundImageSrc = theme?.backgroundImageSrc;

  const hasCustomizedTheme = !!blocks;

  function onCloseResourceViewer() {
    setSelectedMaterial(null);
    if (materialId) {
      removeURLParams(['srId', 'channelId']);
      setSidebar(null);
    }
  }

  function renderResourceViewer() {
    if (!selectedMaterial) return null;
    const material = { ...selectedMaterial };
    if (materialId) {
      material.id = materialId;
      material.spaceId = spaceId;
    }

    console.log('### renderResourceViewer', material);

    const [isEnabledMaterialSlider] = checkEnabledFeatures([FeatureFlagsType.MATERIAL_SLIDER]);

    return (
      <ResourcePreviewDialog
        context={getResourcePreviewContext('space')}
        material={material}
        onClose={onCloseResourceViewer}
        resourceHubConnection={resourceHubConnection}
        enableSlider={isEnabledMaterialSlider}
        spaceName={space?.name}
      />
    );
  }

  function handleClickEditButton() {
    createSpacePopupRef?.current?.onOpen(
      {
        id: spaceId,
      },
      'edit'
    );
  }

  function renderSpaceContentDialog() {
    if (!sidebar) {
      return null;
    }
    return (
      <div className="space-content-dialog">
        {/* {!isMobile && (
          <div className="close-button" onClick={() => setSidebar(null)}>
            <CloseIcon className="close-icon" />
          </div>
        )} */}
        <div className="space-container">
          <div className="space-container-content">
            {hasSidebar && (
              <SpaceSidebar
                onResourceView={setSelectedMaterial}
                sharingFolderId={sharingFolderId}
                onClose={handleOnCloseSidebar}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderWelcome() {
    let spaceUserFullName = spaceUser.getFullName();
    if (!spaceUserFullName) {
      return null;
    }

    if (isMobile) {
      spaceUserFullName = truncateText(spaceUserFullName);
    }

    return (
      <div className="welcome-messages">
        <Trans i18nKey="joinMeetingWelcome">
          Welcome {spaceUserFullName}, in this <b className="whitespace">SP CE</b> you'll find
          everything you need
        </Trans>
      </div>
    );
  }

  // function handleOnClickedVaamButton() {
  //   setSidebar(null);
  // }

  function renderTheme() {
    const hasLoggedIn = spaceUser.hasLoggedIn() || spaceUser.isSupervisor();
    if ((space.isPrivate || isSpaceClosed) && !hasLoggedIn) {
      return (
        <div className={`space-container ${className}`}>
          <div className="space-container-content">
            <LogoSpace src={theme?.logoImageSrc} />
            <div className="right-content">{props.children}</div>
          </div>
        </div>
      );
    }
    const isBlockedByFirewall = !!sessionStorage.getItem('isBlockedByFirewall');
    const hideShareLink =
      (spaceUser.isViewOnly() && !isBlockedByFirewall) ||
      (isPrivateSpace && spaceUser?.isPartner());
    if (hasCustomizedTheme) {
      return (
        <div className={`space-container cms ${className}`}>
          {renderSpaceContentDialog()}
          <ThemePreview
            key={currentPage?.id}
            blocks={blocks}
            onChangeNavigation={onChangeNavigation}
            pagesBlock={pagesBlock}
            currentPage={currentPage}
            spaceId={spaceId}
            isSpaceClosed={isSpaceClosed}
            isSpaceDeleted={isSpaceDeleted}
            hideShareLink={hideShareLink}
            isMobile={isMobile}
            handleClickEditButton={handleClickEditButton}
          />
        </div>
      );
    }
    return (
      <div className={`space-container ${className}`}>
        {hasSidebar && (
          <SpaceSidebar
            onResourceView={setSelectedMaterial}
            sharingFolderId={sharingFolderId}
            onClose={handleOnCloseSidebar}
          />
        )}
        {isSpaceClosed && spaceUser.isHost() && (
          <Banner
            className="banner-warning"
            message={
              <>
                <b>This space is closed.</b> Users will not be able to join this space until you
                reopen it.
              </>
            }
          />
        )}
        <div
          className={`space-container-content ${isSpaceClosed ? 'paddingTop60' : ''}`}
          style={{ backgroundImage: `url(${backgroundImageSrc})` }}
        >
          <div className="welcome">
            <div>
              {space.isTemplate ? (
                <Button className="space-template-button" disabled>
                  {i18n.t('Template')}
                </Button>
              ) : (
                !hideShareLink && <ShareLinkButton space={space} />
              )}
              <h1>{name}</h1>
              {renderWelcome()}
            </div>
          </div>
          <div className="right-content">
            {isHostOfSpace && (
              <div className="back-button">
                <HomeButton />
                {/* Todo */}
                {/* <VaamButton onClicked={handleOnClickedVaamButton} /> */}
                <NotificationButton />
                <LoginMenu />
              </div>
            )}
            {props.children}
          </div>
        </div>
      </div>
    );
  }

  function renderVaamRecorder() {
    if (!showVaamRecorder) return null;
    return <VaamRecordingBox onClose={() => setShowVaamRecorder(false)} spaceId={spaceId} />;
  }

  function renderCheckRequestAccessState() {
    if (!requestAcceptId || !isPrivateSpace || !spaceUser.hasLoggedIn()) {
      return null;
    }
    console.log('getRequestAccessByIdQuery: init');
    return <CheckRequestAccessPrivateSpace spaceId={spaceId} requestAcceptId={requestAcceptId} />;
  }

  function renderSpaceShareLinks() {
    return (
      <>
        {materialId && (
          <ResourceShareLink
            materialId={materialId}
            spaceId={spaceId}
            isPublicSpace={isPublicSpace}
            onOpenResource={handleOpenResource}
            channelId={channelId}
          />
        )}
        <ResourceDeletedHandler
          materialId={materialId}
          sharingFolderId={sharingFolderId}
          selectedMaterial={selectedMaterial}
          setSelectedMaterial={setSelectedMaterial}
        />
      </>
    );
  }

  return (
    <IntegrationRuntimeContextProvider>
      <MaterialsContextProvider
        spaceId={spaceId}
        isPortal={false}
        viewMaterial={handleOpenResource}
      >
        <SpaceUsersContextProvider spaceId={spaceId}>
          {renderResourceViewer()}
          {props.element}
          {renderTheme()}
          {renderVaamRecorder()}
          {renderCheckRequestAccessState()}
          {renderSpaceShareLinks()}
          {showAcceptCookie && <HandleAcceptCookie setShowAcceptCookie={setShowAcceptCookie} />}
          {!space.isTemplate && !isPublicSpace && isSpaceUserLoggedIn && (
            <CreateSpaceUserSessionHandler spaceId={spaceId} />
          )}
          {isHost && isAuthenticated && <CreateSpaceDialog isInSpace ref={createSpacePopupRef} />}
          <SpaceFooter
            isMobile={isMobile}
            position={isMobile ? 'bottom' : 'left'}
            className={`${className} ${hasCustomizedTheme ? 'cms' : ''}`}
            isPublicSpace={isPublicSpace}
          />
          {isSpaceUserLoggedIn && isAssessmentExisted && (
            <SpaceLMSSignalRHandler spaceId={spaceId} />
          )}
          <SpaceSidebarHandler />
        </SpaceUsersContextProvider>
      </MaterialsContextProvider>
    </IntegrationRuntimeContextProvider>
  );
};

SpaceContainer.propTypes = {
  className: PropTypes.string,
  hasSidebar: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
  element: PropTypes.element,
  spaceOwner: PropTypes.instanceOf(Object),
};

SpaceContainer.defaultProps = {
  className: '',
  hasSidebar: true,
};

export default SpaceContainer;
