import React, { useMemo, useState } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import { getFilteredItems, getSortParticipants } from './functions';
import './ParticipantsTable.scss';
import FilterBox from '../../components/FilterBox';
import i18n from '../../i18n';
import ParticipantRow from './ParticipantRowTable';
import ActionButtons from './ActionButtons';
import NoDaTaBox from '../../components/NoDataBox';

const getHeadCells = memoizeOne((isModerator) => {
  const data = [
    { id: 'fullName', disablePadding: true, label: 'Name', show: true },
    { id: 'lastActive', disablePadding: false, label: 'Last Active', show: true },
    {
      id: null,
      align: 'center',
      disablePadding: true,
      label: '',
      disableSorting: true,
      show: isModerator,
      disableHovering: true,
    },
  ];
  return data.filter((x) => x.show);
});

const ParticipantsTable = (props) => {
  const {
    participants,
    setSelectedParticipant,
    canSearch,
    showHeader,
    onClickItem,
    userInfoId,
    isMobile,
    isPublicSpace,
  } = props;
  const [anchorActionMenuEl, setAnchorActionMenuEl] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [sortData, setSortData] = useState(props.defaultSort);

  const headCells = !isMobile ? getHeadCells(props.isModerator) : null;

  const items = getFilteredItems(participants, filterText);

  const dataSorted = useMemo(
    () => getSortParticipants(items, sortData.order, sortData.orderBy),
    [sortData, items]
  );

  if (!participants) {
    return null;
  }

  function onCloseActionMenu() {
    setAnchorActionMenuEl(null);
    setSelectedParticipant(null);
  }

  function handleMoreButtonClick(event, participant) {
    setAnchorActionMenuEl(event.target);
    setSelectedParticipant(participant);
  }

  const handleOnClickParticipant = (participant) => {
    if (participant.isCurrentParticipant || !onClickItem) {
      return;
    }
    onClickItem(participant);
  };

  function handleOnEdit() {
    if (props.onClickEdit) {
      props.onClickEdit();
      setAnchorActionMenuEl(null);
    }
  }

  function handleOnChangeSort(order, orderBy) {
    setSortData({ order, orderBy });
  }

  function handleOnChangeText(value) {
    setFilterText(value);
  }

  function handleOnClearText() {
    setFilterText('');
  }

  function renderSearchInput() {
    if (!canSearch) {
      return null;
    }

    return (
      <div className="participant-list-filter">
        <FilterBox
          variant="light"
          placeholder={i18n.t('Search for users or email')}
          value={filterText}
          onChange={handleOnChangeText}
          onCancel={handleOnClearText}
        />
      </div>
    );
  }

  function handleOnDelete() {
    if (props.onClickDelete) {
      props.onClickDelete();
      setAnchorActionMenuEl(null);
    }
  }

  function renderMenuAction() {
    return (
      <ActionButtons
        onClickEdit={handleOnEdit}
        onClickChat={props.onClickChat}
        onClickDelete={handleOnDelete}
        isModerator={props.isModerator}
        onClose={onCloseActionMenu}
        anchorEl={anchorActionMenuEl}
      />
    );
  }

  function renderContent() {
    if (items.length === 0 && filterText.length > 0) {
      return (
        <NoDaTaBox
          icon={<span className="icon-person" />}
          title={i18n.t('No users found')}
          description={i18n.t('Choose another keyword and try again')}
        />
      );
    }
    return (
      <>
        <EnhancedTable
          headCells={showHeader ? headCells : null}
          defaultSort={sortData}
          className={`participants-table ${isMobile && 'participants-table-mobile'}`}
          onChangeSort={handleOnChangeSort}
        >
          {dataSorted.map((participant) => {
            const isCurrentParticipant = participant.userInfoId === userInfoId;
            return (
              <ParticipantRow
                isMobile={isMobile}
                isCurrentParticipant={isCurrentParticipant}
                isModerator={props.isModerator}
                key={participant.id}
                participant={participant}
                onClickMore={handleMoreButtonClick}
                onClickItem={handleOnClickParticipant}
                disabledActionButton={props.disabledActionButton}
                canChat={props.canChat}
                isPublicSpace={isPublicSpace}
              />
            );
          })}
        </EnhancedTable>
        {renderMenuAction()}
      </>
    );
  }

  return (
    <>
      {renderSearchInput()}
      {renderContent()}
    </>
  );
};

ParticipantsTable.propTypes = {
  showHeader: PropTypes.bool,
  canSearch: PropTypes.bool,
  defaultSort: PropTypes.instanceOf(Object),
  participants: PropTypes.instanceOf(Array),
  onClickEdit: PropTypes.func,
  onClickChat: PropTypes.func,
  onClickItem: PropTypes.func,
  onClickDelete: PropTypes.func,
  isModerator: PropTypes.bool,
  setSelectedParticipant: PropTypes.func,
  userInfoId: PropTypes.number,
  disabledActionButton: PropTypes.bool,
  isMobile: PropTypes.bool,
  canChat: PropTypes.bool,
  isPublicSpace: PropTypes.bool,
};

ParticipantsTable.defaultProps = {
  showHeader: true,
  canSearch: true,
  defaultSort: { order: 'desc', orderBy: 'lastActive' },
  isMobile: false,
};

export default ParticipantsTable;
