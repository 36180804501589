import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import i18n from '../../i18n';
import OfficialButtons from '../../components/OfficialButtons';
import Icons from '../../components/Icons';

import '../../CompanyAnalytics/components/NoCompanyAnalyticsData.scss';

type NoSpaceAnalyticsDataProps = {
  icon?: React.ReactNode;
  message?: string;
  description?: string;
  button?: {
    label: string;
    onClick: () => void;
    dataId?: string;
  };
};

function NoSpaceAnalyticsData(props: NoSpaceAnalyticsDataProps) {
  return (
    <div className={`no-data-box`}>
      <div className="no-data-box-icon">{props.icon}</div>
      <p className="no-data-box-text">{props.message}</p>
      {props.description && <p className="no-data-box-description">{props.description}</p>}
      {props.button && (
        <OfficialButtons
          icon={<AddIcon />}
          dataId={props.button.dataId}
          label={props.button.label}
          variant="rectangle-dark-grey"
          onClick={props.button.onClick}
          style={{ marginBottom: 15 }}
        />
      )}
    </div>
  );
}

NoSpaceAnalyticsData.defaultProps = {
  message: i18n.t('No data to show'),
  icon: <Icons className="icon-participants" />,
};

export default NoSpaceAnalyticsData;
