import React, { PureComponent } from 'react';
import { Box, Divider, Switch, Typography } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/system';
import i18n from '../../i18n';
import { getTheme } from '../../theme';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import OfficialButton from '../../components/OfficialButtons';
import './ApplyChangesDialog.scss';
import ApplyChangesTable from '../components/ApplyChangesTable';
import queryCache from '../../app/queryCache';
import { Ids } from '../../commons/pendoTaggings';

let resolve;
let containerElement;
let rootEl;

class ApplyChangesDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true, // open / close dialog,
      selected: [],
      error: false,
      isOverridden: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleUpdateSelected = this.handleUpdateSelected.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleOnChangeSwitch = this.handleOnChangeSwitch.bind(this);
    // this.tableRef = React.createRef();
  }

  componentWillUnmount() {
    resolve = null;
    containerElement = null;
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log(this.props.templateRefId, this.props.spaceId, prevProps, prevState);
  // }

  static destroy(res = { isConfirmed: false }) {
    if (containerElement) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 0);
    }
    if (resolve) resolve(res);
  }

  static show(templateRefId, spaceId) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <QueryClientProvider client={queryCache}>
          <ApplyChangesDialog templateRefId={templateRefId} spaceId={spaceId} />
        </QueryClientProvider>
      </ThemeProvider>
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose(res = { isConfirmed: false }) {
    this.setState({ isOpen: false }, () => {
      ApplyChangesDialog.destroy(res);
    });
  }

  handleUpdateSelected(newSelected) {
    let newState = { selected: newSelected };
    if (this.state.error) {
      newState = { ...newState, error: false };
    }
    this.setState(newState);
  }

  handleOnChangeSwitch(event) {
    console.log('### handleOnChangeSwitch:', this.state.isOverridden, event.target.checked);
    this.setState({ isOverridden: event.target.checked });
  }

  handleConfirm() {
    // to apply changes
    if (this.state.selected.length === 0) {
      this.setState({ error: true });
      return;
    }
    const data = {
      spaceIds: this.state.selected,
      isConfirmed: true,
      isOverridden: this.state.isOverridden,
    };
    this.handleClose(data);
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    return (
      <ResponsiveDialog
        modal
        maxWidth="lg"
        className="white apply-changes-dialog"
        // onClose={this.handleClose}
      >
        <div className="body">
          <section className="title">
            <span className="text">{i18n.t('Publish Changes')}</span>
          </section>

          <section className="description">
            <span className="text">
              Changes made in <b>material</b> and <b>template design</b> will apply to all future
              spaces created from this template. Select spaces in the list to update changes made.
            </span>
          </section>

          <section className="table-section">
            <ApplyChangesTable
              spaceTemplateId={this.props.templateRefId}
              handleUpdateSelected={this.handleUpdateSelected}
              error={this.state.error}
              isSelectable
              showLastUpdated
              isOverridden={this.state.isOverridden}
            />
          </section>

          <Divider className="divider" />

          <section className="override-section">
            <Box className="switch-control">
              <Typography className="label">
                {i18n.t('Override all changes made by hosts in selected spaces')}
              </Typography>
              <Switch
                color="primary"
                // name="override-switch"
                checked={this.state.isOverridden}
                onChange={this.handleOnChangeSwitch}
                // className="override-switch"
                inputProps={{
                  'data-id': Ids?.ApplyChangesDialog.override,
                }}
              />
            </Box>
            <span className="info">
              {i18n.t(
                'This option will make the selected spaces identical to the template. The update will override all edits made by hosts of these spaces.'
              )}
            </span>
          </section>
        </div>
        <div className="footer">
          <div className="action-buttons">
            <OfficialButton
              onClick={this.handleClose}
              label={i18n.t('Cancel')}
              variant="large-secondary"
              dataId={Ids.ApplyChangesDialog.cancel}
            />
            <OfficialButton
              onClick={this.handleConfirm}
              label={i18n.t('Confirm')}
              variant="large-primary"
              dataId={Ids.ApplyChangesDialog.confirm}
            />
          </div>
        </div>
      </ResponsiveDialog>
    );
  }
}

ApplyChangesDialog.propTypes = {
  templateRefId: PropTypes.string,
};

export default ApplyChangesDialog;
