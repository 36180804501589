import _memoize from 'lodash/memoize';
import _findIndex from 'lodash/findIndex';
import memoizeOne from 'memoize-one';
import { ResourceState, ResourceType } from '../../app/appConstants';
import { IResource, RowData, SelectedItemProps } from '../context/Interfaces';
import { GRSelectType } from '../GlobalResourcesPicker';
import { GLOBAL_RESOURCE_TYPE } from '../utils';
import { getVideoResourceData, validateResource } from '../../commons/ResourceUtils';

const isItemDisabled = _memoize(
  (
    state: number | undefined,
    resourceType: number | undefined,
    type: (typeof GLOBAL_RESOURCE_TYPE)[keyof typeof GLOBAL_RESOURCE_TYPE],
    selectType: (typeof GRSelectType)[keyof typeof GRSelectType] | undefined,
    otherTypeDisabled: boolean | undefined,
    extension?: string | undefined,
    allowSelectedTypes?: boolean | undefined,
    src?: string | undefined
  ) => {
    const isCadDisabled = false; // enable cad files for testing
    // resourceType === ResourceType.cad && state !== ResourceState.Converted;
    console.log(resourceType);
    let isDisabled = isCadDisabled;
    if (resourceType === ResourceType.video) {
      const videoResourceData = getVideoResourceData({ src, type: resourceType, state, extension });
      isDisabled = !videoResourceData?.canView;
    }
    if (otherTypeDisabled) {
      if (selectType === GRSelectType.Folder && type === GLOBAL_RESOURCE_TYPE.RESOURCE) {
        isDisabled = true;
      }
      if (selectType === GRSelectType.File && type === GLOBAL_RESOURCE_TYPE.FOLDER) {
        isDisabled = true;
      }
    }
    if (!allowSelectedTypes) isDisabled = true;
    return isDisabled;
  },
  (state, resourceType, type, selectType, otherTypeDisabled, extension, allowSelectedTypes, src) =>
    `${state}${resourceType}${type}${selectType}${otherTypeDisabled}${extension}${allowSelectedTypes}${src}`
);

export const getItemCount = (
  resources: IResource[],
  selectType: (typeof GRSelectType)[keyof typeof GRSelectType] | undefined
) => {
  let rowCount = 0;
  let shouldCountResources = [...resources];
  if (selectType === GRSelectType.File) {
    shouldCountResources = shouldCountResources.filter(
      (x) => x.type === GLOBAL_RESOURCE_TYPE.RESOURCE
    );
  } else if (selectType === GRSelectType.Folder) {
    shouldCountResources = shouldCountResources.filter(
      (x) => x.type === GLOBAL_RESOURCE_TYPE.FOLDER
    );
  }
  rowCount = shouldCountResources.length;
  return rowCount;
};

export const getGROrderBy = memoizeOne((orderBy: string, order: string) => {
  const mOrder = order ?? 'asc';
  // const rOrder = mOrder === 'asc' ? 'desc' : 'asc';
  let mOrderBy = '';
  switch (orderBy) {
    case 'name':
      mOrderBy = `name:${mOrder},lastModified:desc`;
      break;
    case 'itemsCount':
      mOrderBy = `itemsCount:${mOrder},name:asc`;
      break;
    case 'lastModified':
      mOrderBy = `lastModified:${mOrder}`;
      break;
    default:
      mOrderBy = `name:${mOrder},lastModified:desc`;
      break;
  }
  return mOrderBy;
});

export const isResourceEqual = (a: SelectedItemProps, b: SelectedItemProps) => {
  // console.log('a', a);
  // console.log('b', b);
  if (a.driveId || b.driveId) {
    return a.id === b.id;
  } else {
    if (a.type === GLOBAL_RESOURCE_TYPE.FOLDER) {
      return a.id === b.id;
    }
    if (a.resourceId && b.resourceId) {
      return a.resourceId === b.resourceId;
    }
    return a.id === b.id;
  }
};

function isFolderDisabled(
  disabledFolderIds: Array<number | string> | undefined,
  id: number | string | undefined
): boolean {
  if (disabledFolderIds && id) {
    return disabledFolderIds.includes(id);
  }

  return false;
}

export const checkDisabledSelectRow = (
  rowData: RowData,
  selectType: string | undefined,
  otherTypeDisabled: boolean | undefined,
  disabledFolderIds: Array<number | string> | undefined,
  accept: string | undefined
) => {
  if (rowData.type === GLOBAL_RESOURCE_TYPE.RESOURCE) {
    const isDisabled = isItemDisabled(
      rowData.state,
      rowData.resourceType,
      rowData.type,
      selectType,
      otherTypeDisabled,
      rowData.extension,
      rowData.allowSelectedType,
      rowData.url
    );
    console.log(
      '2222isDisabled',
      isDisabled,
      rowData.resourceName,
      rowData.url,
      rowData.resourceType,
      rowData.state,
      rowData.extension
    );
    return selectType === GRSelectType.Folder || !validateResource(accept, rowData) || isDisabled;
  } else {
    return isFolderDisabled(disabledFolderIds, rowData.id);
  }
};

export const checkDisabledCheckBox = (
  rowData: RowData,
  selectType: string | undefined,
  hasSelectColumn: boolean,
  processingResources: Array<number> | undefined,
  accept: string | undefined
) => {
  if (!hasSelectColumn) {
    return true;
  }
  if (selectType === GRSelectType.Folder && rowData.type === GLOBAL_RESOURCE_TYPE.RESOURCE) {
    return true;
  }

  if (selectType === GRSelectType.File && rowData.type === GLOBAL_RESOURCE_TYPE.FOLDER) {
    return true;
  }

  if (rowData.type === GLOBAL_RESOURCE_TYPE.RESOURCE && !validateResource(accept, rowData)) {
    return true;
  }

  const disableCheckbox =
    (rowData.type === GLOBAL_RESOURCE_TYPE.RESOURCE &&
      (rowData.state === ResourceState.Transcoding ||
        rowData.state === ResourceState.Converting)) ||
    !rowData.allowSelectedType ||
    (processingResources && rowData.resourceId
      ? processingResources.includes(rowData.resourceId)
      : false);
  return disableCheckbox;
};

export const checkRowSelected = (selectedResources: Array<SelectedItemProps>, rowData: RowData) => {
  const resource: SelectedItemProps = {
    id: rowData.id,
    resourceId: rowData.resourceId,
    type: rowData.type,
  };
  return _findIndex(selectedResources, (item) => isResourceEqual(item, resource)) !== -1;
};
