import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/system';
import { getTheme } from '../theme';
import i18n from '../i18n';
import ResponsiveDialog from '../components/ResponsiveDialog';
import './SpaceEmailVerificationDialog.scss';
import Icons from '../components/Icons';
import { removeURLParams } from '../commons/utils';

let resolve;
let containerElement;
let rootEl;

class SpaceEmailVerificationDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isProcessing: false,
      pin: '',
      error: null,
    };
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount SpaceEmailVerificationDialog');
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (containerElement) {
      rootEl.unmount();
    }
    if (resolve) resolve(retVal);
  }

  static show(email) {
    removeURLParams(['invc']);

    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <SpaceEmailVerificationDialog email={email} />
      </ThemeProvider>
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleCancel() {
    this.setState({ isOpen: false }, () => {
      SpaceEmailVerificationDialog.destroy();
    });
  }

  render() {
    const { isOpen } = this.state;
    if (!isOpen) {
      return null;
    }

    return (
      <ResponsiveDialog
        modal
        maxWidth="lg"
        className="white confirm-dialog space-email-verify-dialog"
        disableBackdropClick
        disableEscapeKeyDown
        showCloseIcon={false}
      >
        <div className="content">
          <div className="icon-box">
            <Icons className="icon-envelop" />
          </div>
          <div className="responsive-dialog-title">{i18n.t('Check your email')}</div>
          <p className="description">
            {i18n.t('A secure link has been sent to')}
            <br />
            <b>{this.props.email}</b>
            <br />
            {i18n.t('Please check your email to verify your account')}
          </p>
        </div>
      </ResponsiveDialog>
    );
  }
}

SpaceEmailVerificationDialog.propTypes = {
  email: PropTypes.string,
};

export default SpaceEmailVerificationDialog;
