export const messageTemplates = {
  JoinedSpace: 'JoinedSpace',
  AddedResource: 'AddedResource',
  AddedFolder: 'AddedFolder',
  SentChatMessage: 'SentChatMessage',
  AssignedHost: 'AssignedHost',
  AddNewResourceVersion: 'AddNewResourceVersion',
  AddNewOneDriveResourceVersion: 'AddNewOneDriveResourceVersion',
  DeleteOneDriveResource: 'DeleteOneDriveResource',
  AddNewGoogleDriveResourceVersion: 'AddNewGoogleDriveResourceVersion',
  DeleteGoogleDriveResource: 'DeleteGoogleDriveResource',
  ExternalInitProcessCompleted: 'ExternalInitProcessCompleted',
  RequestAccessToSpace: 'RequestAccessToSpace',
  VideoMessageViewed: 'VideoMessageViewed',
  SentVideoMessage: 'SentVideoMessage',
  GlobalTemplateUpdated: 'GlobalTemplateUpdated',
  SpaceAccessGranted: 'SpaceAccessGranted',
  DealAssigned: 'DealAssigned',
  DealUpdated: 'DealUpdated',
  DealsDeleted: 'DealsDeleted',
};

export const iconsByNotifyContext = {
  Participants: {
    color: '#1FDA8B',
    icon: 'icon-person',
  },
  Resources: {
    color: '#4783FF',
    icon: 'icon-folder',
  },
  Chats: {
    color: '#F44A4A',
    icon: 'icon-single-chat',
  },
  OneDrive: {
    color: '',
    icon: 'icon-onedrive',
    size: '15px',
  },
  GoogleDrive: {
    color: '',
    icon: 'icon-googledrive',
    size: '15px',
  },
  RequestAccessToSpace: {
    color: '#1FDA8B',
    icon: 'icon-person',
  },
  VideoMessageViewed: {
    color: '#1FDA8B',
    icon: 'icon-person',
  },
  ParticipantInteraction: {
    color: '#F44A4A',
    icon: 'icon-single-chat',
  },
  GlobalSpaceTemplateUpdated: {
    color: '#F44A4A',
    icon: 'icon-design-1',
  },
  Deals: {
    color: '#AD2DE1',
    icon: 'icon-dollar',
  },
};

export const contextNotificationsType = {
  Participants: 'Participants',
  Resources: 'Marterial',
  Chats: 'Chat',
  ParticipantsInteraction: 'ParticipantsInteraction',
  Deals: 'Deals',
};

export const notificationType = {
  Participants: 'Participants',
  Marterial: 'Resources',
  Chat: 'Chats',
  ParticipantsInteraction: 'ParticipantsInteraction',
};

export const resourcesType = {
  AddResource: 'resource',
  AddFolder: 'folder',
};
