import Api from '../commons/api';
import { getCoreApi, newGuid } from '../commons/utils';

export function fetchSpaceThemes(keywords, sortBy) {
  let url = `/api/space-themes?`;
  if (keywords) {
    url += `&keywords=${keywords}`;
  }
  if (sortBy) {
    url += `&order=${sortBy}`;
  }

  return Api.get(url);
}

export function mapTheme(theme) {
  return theme.map((item) => {
    const newItem = { ...item, id: newGuid() };
    if (newItem.items) {
      newItem.items = newItem.items.map((component) => ({ ...component, id: newGuid() }));
    }
    return newItem;
  });
}

export function fetchSpaceThemeById(id) {
  return Api.get(`${getCoreApi()}/space-themes/${id}`);
}

export function addPagesBlock(spaceId) {
  return Api.post(`${getCoreApi()}/spaces/${spaceId}/pagesblock`, {});
}

export function deletePagesBlock(spaceId, pagesBlockId) {
  return Api.delete(`${getCoreApi()}/spaces/${spaceId}/pagesblock/${pagesBlockId}`);
}

export function handleUpdatePagesBlock(spaceId, pagesBlockId, payload) {
  return Api.put(`${getCoreApi()}/spaces/${spaceId}/pagesblock/${pagesBlockId}`, payload);
}

export function updatePageName(spaceId, pagesBlockId, pageId, payload) {
  return Api.put(
    `${getCoreApi()}/spaces/${spaceId}/pagesblock/${pagesBlockId}/pages/${pageId}`,
    payload
  );
}

export function handleAddPage(spaceId, pagesBlockId, payload) {
  return Api.post(`${getCoreApi()}/spaces/${spaceId}/pagesblock/${pagesBlockId}/pages`, payload);
}

export function handleDeletePage(spaceId, pagesBlockId, pageId) {
  return Api.delete(`${getCoreApi()}/spaces/${spaceId}/pagesblock/${pagesBlockId}/pages/${pageId}`);
}

export function rearrangePages(spaceId, pagesBlockId, payload) {
  return Api.put(`${getCoreApi()}/spaces/${spaceId}/pagesblock/${pagesBlockId}/rearrange`, payload);
}
