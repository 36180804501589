import React from 'react';
import PropTypes from 'prop-types';

function ArrowButton(props) {
  const { className, onClick, icon } = props;
  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick();
  };
  return (
    <button className={className} onClick={handleOnClick}>
      {icon}
    </button>
  );
}

ArrowButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.element,
};

export default ArrowButton;
