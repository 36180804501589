import { useEffect } from 'react';
import PropTypes from 'prop-types';

const FeatureFlagsHandler = ({ connection }) => {
  useEffect(() => {
    function handleFeatureFlagsChanged() {
      sessionStorage.removeItem('FeatureFlagsOrg');
    }
    if (connection) {
      connection.on('FeatureFlagsChanged', handleFeatureFlagsChanged);
    }
    return () => {
      if (connection) {
        connection.off('FeatureFlagsChanged', handleFeatureFlagsChanged);
      }
    };
  }, [connection]);
  return null;
};

FeatureFlagsHandler.propTypes = {
  connection: PropTypes.instanceOf(Object),
};

export default FeatureFlagsHandler;
