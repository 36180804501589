// Error page component with logo, message, and button go back to home page

import React from 'react';

import OfficialButton from './OfficialButtons';
import './ErrorPage.scss';
import Logo from '../images/spce-logo.png';
import authService from './api-authorization/AuthorizeService';

const ErrorPage = () => {
  const onClick = () => {
    authService.signOut();
  };
  return (
    <div className="app error-page">
      <div className="logo">
        <img src={Logo} alt="logo" />
      </div>
      <div className="content">
        <div>{'Ooops, if you reached this page or mean you been logged out'}</div>
        <div>{'Please click the button to login'}</div>
        <br />
        <OfficialButton onClick={onClick} variant="regular-green" label="Enter Space" />
      </div>
    </div>
  );
};

export default ErrorPage;
