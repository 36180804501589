import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import { ThemeProvider } from '@mui/system';
import { getTheme } from '../theme';
import i18n from '../i18n';
import ResponsiveDialog from '../components/ResponsiveDialog';
import ButtonEnhanced from '../components/ButtonEnhanced';
import TextFieldEnhanced from '../components/TextFieldEnhanced';
import { getSpaceAccessToken } from './spaceAccessServices';
import spaceUser from './spaceUser';
import './SpaceAccessPinDialog.scss';
import { Ids } from '../commons/pendoTaggings';
import localUserInfo from '../commons/LocalUserInfo';

let resolve;
let containerElement;
let rootEl;

class SpaceAccessPinDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isProcessing: false,
      pin: '',
      error: null,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChangePin = this.handleChangePin.bind(this);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount SpaceAccessPinDialog');
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (containerElement) {
      rootEl.unmount();
    }
    if (resolve) resolve(retVal);
  }

  static show(spaceId, code, organizationId) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <SpaceAccessPinDialog organizationId={organizationId} spaceId={spaceId} code={code} />
      </ThemeProvider>
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleCancel() {
    this.setState({ isOpen: false }, () => {
      SpaceAccessPinDialog.destroy();
    });
  }

  handleChangePin(name, value) {
    this.setState({
      pin: value,
    });
  }

  async handleOnSubmit(event) {
    event.preventDefault();
    try {
      this.setState({ isProcessing: true });
      spaceUser.setPin(this.state.pin.trim());
      const { spaceId, code, organizationId } = this.props;
      const localInfo = localUserInfo.getUserInfo();
      // console.log('### 1312 localInfo:', localInfo);
      const resp = await getSpaceAccessToken(
        organizationId,
        spaceId,
        code,
        null,
        localInfo?.firstName,
        localInfo?.lastName
      );
      this.setState({ isOpen: false }, () => {
        SpaceAccessPinDialog.destroy(resp);
      });
    } catch (e) {
      spaceUser.removePin();
      this.setState({ isProcessing: false, error: e.message });
    }
  }

  render() {
    const { isOpen, pin, error, isProcessing } = this.state;
    if (!isOpen) {
      return null;
    }

    return (
      <ResponsiveDialog
        modal
        maxWidth="lg"
        className="white confirm-dialog space-access-pin-dialog"
        title={i18n.t('Email Verification')}
      >
        <form onSubmit={this.handleOnSubmit}>
          <div className="content">
            <p>
              {i18n.t(
                'This space is private, and a unique pin code has been sent to the email you entered. Enter that pin here to verify your email and gain access.'
              )}
            </p>
            <p>
              {i18n.t(
                'If you have not received your pin in 15 seconds, please check your spam box or contact the host.'
              )}
            </p>
            <div className="pin-field">
              <TextFieldEnhanced
                autoFocus
                variant="outlined"
                placeholder="Enter the code here"
                onChange={this.handleChangePin}
                name="pin"
              />
            </div>
            {error && <span className="error">{error}</span>}
          </div>
          <div className="large-rounded-button">
            <ButtonEnhanced
              type="submit"
              isProcessing={isProcessing}
              variant="contained"
              fullWidth
              data-id={Ids.GoToSpaceButton}
              disabled={pin.length < 6}
            >
              {i18n.t('Go to space')}
            </ButtonEnhanced>
          </div>
        </form>
      </ResponsiveDialog>
    );
  }
}

SpaceAccessPinDialog.propTypes = {
  spaceId: PropTypes.string,
  code: PropTypes.string,
  organizationId: PropTypes.string,
};

export default SpaceAccessPinDialog;
