import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

import { CacheKeys } from '../../app/queryCache';
import { removeURLParams, sendNotification } from '../../commons/utils';
import i18n from '../../i18n';
import { useSpaceContext } from '../SpaceContext';
import { SidebarType } from '../../app/appConstants';
import { getRequestAccessById } from './spaceUsersServices';

const CheckRequestAccessPrivateSpace = (props) => {
  const { spaceId, requestAcceptId } = props;

  const { setSidebar, setCurrentTabInParticipant } = useSpaceContext();

  const getRequestAccessByIdQuery = useQuery({
    queryKey: [CacheKeys.fetchRequestAccessById, spaceId, requestAcceptId],
    queryFn: async () => {
      const resp = await getRequestAccessById(spaceId, requestAcceptId);
      return resp?.requestSpaceAccess;
    },
    retry: 1,
    retryDelay: () => 5000,
    enabled: !!requestAcceptId,
  });

  useEffect(() => {
    if (getRequestAccessByIdQuery.data && requestAcceptId) {
      const state = getRequestAccessByIdQuery.data.state;
      console.log('getRequestAccessByIdQuery: ', requestAcceptId, state);
      if (state === 'Denied') {
        sendNotification(i18n.t('The access request has been denied'), { type: 'error' });
      } else if (state === 'Accepted') {
        sendNotification(i18n.t('User has already joined the space'), { type: 'success' });
      } else {
        setSidebar(SidebarType.Participants);
        setCurrentTabInParticipant(1);
      }
      removeURLParams(['req-id']);
    }
  }, [getRequestAccessByIdQuery.data, requestAcceptId, setCurrentTabInParticipant, setSidebar]);

  return null;
};

CheckRequestAccessPrivateSpace.propTypes = {
  spaceId: PropTypes.string,
  requestAcceptId: PropTypes.string,
};

export default CheckRequestAccessPrivateSpace;
