export const defaultFeatureFlags = {
  items: [
    'magic-link',
    'space-space-analytics',
    'space-vaam',
    'settings-tags',
    'settings-company-analytics',
    'home-spaces-engagement',
    'cms-pages',
    'cms-global-template-update',
    'space-add-host',
  ],
};
