import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uuidv4 } from '../commons/utils';
import RefreshExternalResourceButton from './RefreshExternalResourceButton';

function extractMiroId(url) {
  const patt = /\/app\/board\/(.*)\//g;
  const res = patt.exec(url);
  return res?.[1] || '';
}

const MiroPlayer = (props) => {
  let src = props.src;
  if (!src.includes('live-embed')) {
    const id = extractMiroId(src);
    src = `https://miro.com/app/live-embed/${id}/?moveToViewport=-700,-400,1400,800`;
  }

  const [key, setKey] = useState(uuidv4());
  // Assign random key to iframe
  function reload() {
    setKey(uuidv4());
  }
  // this will run when the document is fully loaded

  return (
    <div
      className="iframe-player-container"
      style={{ width: '100%', height: '100%', position: 'relative' }}
    >
      {props.externalTool && <RefreshExternalResourceButton onClick={reload} />}
      <iframe
        key={key}
        title="resource-external-url"
        id="iframe"
        src={`${src}&embedAutoplay=true`}
        width="100%"
        height={props.height || '100%'}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        style={{ backgroundColor: '#fff' }}
      ></iframe>
    </div>
  );
};

MiroPlayer.propTypes = {
  src: PropTypes.string,
  height: PropTypes.string,
  externalTool: PropTypes.bool,
};

export default MiroPlayer;
