import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import _find from 'lodash/find';
import useIntegrationConnectionsQuery from './useIntegrationConnectionsQuery';
import { serviceProviderList } from './integrationConstants';
import {
  getRemoveConnectionData,
  getSucceedMessage,
  mapProviders,
  showCommonError,
  showConfirmPopup,
  showErrorMessage,
  showSuccessMessage,
} from './integrationUtils';
import Loading from '../components/Loading';
import SavingBlocker from '../components/SavingBlocker';
import i18n from '../i18n';
import currentOrganization from '../commons/CurrentOrganization';
import {
  addIntegrationConnection,
  clearConnectionsCache,
  getIntegrationConnections,
  removeIntegrationConnection,
} from './integrationService';

export const IntegrationContext = React.createContext();
export const useIntegrationContext = () => React.useContext(IntegrationContext);
export const getIntegrationContext = memoizeOne((providers, isLoading) => ({
  providers,
  isLoading,
}));

function IntegrationContextProvider(props) {
  const { children, showLoading } = props;
  const [providers, setProviders] = React.useState(serviceProviderList);
  const [isSaving, setIsSaving] = React.useState(false);
  const fetchConnectionsQuery = useIntegrationConnectionsQuery();
  const { isLoading } = fetchConnectionsQuery;

  React.useEffect(() => {
    const resp = fetchConnectionsQuery.data;
    if (!resp) {
      return;
    }
    setProviders(mapProviders(resp));
    // in case admin add or remove connections, from normal users sides, we should update the latest connections to local storage
    // TODO: should send signal R to update other users (in the same org) if admin has updated integration connections
    currentOrganization.setIntegrationConnections(resp);
  }, [fetchConnectionsQuery.data]);

  async function updateLocalStorageConnections() {
    const connections = await getIntegrationConnections();
    if (connections) {
      currentOrganization.setIntegrationConnections(connections);
    }
  }

  async function addConnection(data) {
    if (isSaving) {
      return;
    }
    try {
      setIsSaving(true);
      const resp = await addIntegrationConnection(data, !!data.onBeforeSuccessDialog);
      setIsSaving(false);
      if (resp.isSuccess) {
        // onBeforeSuccessDialog must be async and returns a boolean value
        let shouldShowSuccessMessage = true;
        if (data.onBeforeSuccessDialog) {
          shouldShowSuccessMessage = await data.onBeforeSuccessDialog(resp);
          clearConnectionsCache();
        }

        if (shouldShowSuccessMessage) {
          const succeedMessage = getSucceedMessage(data.serviceProvider, resp.data);
          if (succeedMessage) {
            await showSuccessMessage(succeedMessage);
          }
        }

        // onAfterSuccessDialog must be async
        if (data.onAfterSuccessDialog) {
          await data.onAfterSuccessDialog(resp);
        }
        // updateLocalStorageConnections();
      } else {
        if (data.onFailure) {
          data.onFailure(resp);
        } else {
          await showErrorMessage(resp.reason, resp.externalErrors);
        }
      }
    } catch (error) {
      setIsSaving(false);
      console.log('result: ', error.message);
      showCommonError();
    }
  }

  async function removeConnection(serviceProvider) {
    const data = getRemoveConnectionData(serviceProvider);
    if (data.needConfirm !== false) {
      const isConfirmed = await showConfirmPopup(
        data.confirmMessage,
        data.title,
        data.okLabel,
        data.cancelLable,
        data.icon
      );
      if (!isConfirmed) {
        return false;
      }
    }

    try {
      if (isSaving) {
        return true;
      }
      setIsSaving(true);
      const resp = await removeIntegrationConnection(serviceProvider);
      if (!resp.isSuccess) {
        showErrorMessage(resp.reason, resp.externalErrors);
      }

      setIsSaving(false);

      if (data.onSuccess) {
        data.onSuccess();
      }
    } catch (error) {
      console.log('result: ', error.message);
      showCommonError();
    }
    return true;
  }

  function hasConnection(serviceProvider) {
    const foundProvider = _find(
      providers,
      (item) => item.type === serviceProvider && item.isActive
    );
    return !!foundProvider;
  }

  const integrationContext = getIntegrationContext(providers, isLoading);

  integrationContext.showBlocker = () => {
    setIsSaving(true);
  };

  integrationContext.hideBlocker = () => {
    setIsSaving(false);
  };

  integrationContext.addConnection = addConnection;
  integrationContext.removeConnection = removeConnection;
  integrationContext.updateLocalStorageConnections = updateLocalStorageConnections;
  integrationContext.hasConnection = hasConnection;
  integrationContext.getProviderData = (serviceProvider) => {
    return _find(providers, (item) => item.type === serviceProvider);
  };

  function renderLoading() {
    return <Loading />;
  }

  return (
    <IntegrationContext.Provider value={integrationContext}>
      {showLoading && isLoading ? renderLoading() : children}
      {isSaving && (
        <SavingBlocker message={i18n.t('Please wait while we set things up for you...')} />
      )}
    </IntegrationContext.Provider>
  );
}

IntegrationContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  showLoading: PropTypes.bool,
};

IntegrationContextProvider.defaultProps = {
  showLoading: true,
};

export default IntegrationContextProvider;
