import React, { useState } from 'react';
import { AppBar, Divider, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import SpaceInfo from '../../../homepage/SpaceInfo';
import NotificationButton from '../../../components/Notification/NotificationButton';
import i18n from '../../../i18n';
import './Header.scss';

import LoginMenu from '../../../components/LoginMenu';
import { Tag } from '../../../components/Tags';
import OfficialButton from '../../../components/OfficialButtons';
import { Ids } from '../../../commons/pendoTaggings';
import LightTooltip from '../../../components/LightTooltip';
import Icons from '../../../components/Icons';
import useMobileQuery from '../../../commons/useMobileQuery';
import spaceUser from '../../../spaces/spaceUser';

function Header(props) {
  const [isPublishing, setIsPublishing] = useState(false);
  const { isXDesktop } = useMobileQuery();
  const isSupervisor = spaceUser.isSupervisor();

  // async function handleSaveButtonClick(event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   if (props.onSaveButtonClick) {
  //     setIsSaving(true);
  //     await props.onSaveButtonClick();
  //     setIsSaving(false);
  //   }
  // }

  async function handlePublishButtonClick(event) {
    event.preventDefault();
    event.stopPropagation();
    if (props.onPublishButtonClick) {
      setIsPublishing(true);
      await props.onPublishButtonClick();
    }
  }

  function handleChangeToDesktopMode() {
    if (props.isMobileMode) {
      props.onChangeMobileMode();
    }
  }

  function handleChangeToMobileMode() {
    if (!props.isMobileMode) {
      props.onChangeMobileMode();
    }
  }

  function renderSpaceInfo() {
    return (
      <SpaceInfo
        spaceName={props.space?.name}
        spaceId={props.space?.id}
        spaceLogo={props.space?.theme?.logoImageSrc}
        className="spacename"
        isPortal
        showInfoSpaceIcon={!props.isPreviewMode}
      />
    );
  }

  if (props.isPreviewMode) {
    return (
      <AppBar position="fixed" className="designer-header-wrapper">
        <div className="designer-header">
          <section className="left-section">
            <div className="space-name-header">{renderSpaceInfo()}</div>
            <div className="message-info message-info-blue">
              {i18n.t(`This view shows how the space design looks like for users. `)}
              {!isSupervisor
                ? i18n.t(`Head back to the space if you want to share it with others.`)
                : ''}
            </div>
          </section>
        </div>
      </AppBar>
    );
  }

  function renderInfoMessage() {
    return (
      <>
        {props.space.isTemplate ? (
          <div className="message-info message-info-purple">
            {i18n.t(`You're editing the template design, changes are saved automatically.`)}
          </div>
        ) : (
          <div className="message-info message-info-blue">
            {i18n.t(`You're editing the space design, changes are saved automatically.`)}
          </div>
        )}
      </>
    );
  }

  return (
    <AppBar position="fixed" className="designer-header-wrapper">
      <div className="designer-header">
        <section className="left-section">
          <div className="space-name-header">
            {!props.hideSpaceName && renderSpaceInfo()}

            {props.space.isTemplate && (
              <>
                <Tag color="#fff" backgroundColor="#AD2DE1" className="template-tag">
                  {i18n.t('Template')}
                </Tag>
              </>
            )}
          </div>
          {isXDesktop && (
            <>
              <div className="template-vertical-divider">
                <Divider className="divider" />
              </div>
              {renderInfoMessage()}
            </>
          )}
        </section>
        <section className="right-section">
          <div className="group-buttons">
            <LightTooltip title="Desktop view">
              <IconButton
                className={`button-icon ${props.isMobileMode ? '' : 'active'}`}
                data-id={Ids.SwitchDesktopMode}
                onClick={handleChangeToDesktopMode}
              >
                {props.isMobileMode ? (
                  <Icons name="icon-desktop-light" />
                ) : (
                  <Icons name="icon-desktop-dark" />
                )}
              </IconButton>
            </LightTooltip>
            <LightTooltip title="Mobile view">
              <IconButton
                data-id={Ids.SwitchMobileMode}
                className={`button-icon ${props.isMobileMode ? 'active' : ''}`}
                onClick={handleChangeToMobileMode}
              >
                <PhoneIcon />
              </IconButton>
            </LightTooltip>
          </div>
          {/* <div className="vertical-divider">
            <Divider className="divider" />
          </div> */}
          <div className="group-buttons">
            {props.space && (
              <>
                <OfficialButton
                  onClick={handlePublishButtonClick}
                  variant="regular-green"
                  isProcessing={isPublishing}
                  dataId={Ids.SaveDesignSpace}
                  label={i18n.t('Done')}
                />
              </>
            )}
          </div>
          <div className="vertical-divider">
            <Divider className="divider" />
          </div>
          <NotificationButton />
          <LoginMenu />
        </section>
      </div>
      {!isXDesktop && <div className="bottom-section">{renderInfoMessage()}</div>}
    </AppBar>
  );
}

Header.propTypes = {
  space: PropTypes.instanceOf(Object),
  hideSpaceName: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  // onSaveButtonClick: PropTypes.func,
  onPublishButtonClick: PropTypes.func,
  // isSidePanelOpened: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  isMobileMode: PropTypes.bool,
  onChangeMobileMode: PropTypes.func,
};

export default Header;
