import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useBrowserUtils = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location?.state;

  const removeURLParams = useCallback(
    (params) => {
      const url = new URL(window.location.href);
      let hasChanged = false;
      params.forEach((param) => {
        if (url.searchParams.has(param)) {
          hasChanged = true;
          url.searchParams.delete(param);
        }
      });
      if (hasChanged) {
        const state = locationState ? { ...locationState } : {};
        navigate(`${url.pathname}${url.search}`, {
          state,
          replace: true,
        });
      }
    },
    [navigate, locationState]
  );

  const setURLParams = useCallback(
    (params) => {
      const url = new URL(window.location.href);
      Object.keys(params).forEach((item) => url.searchParams.set(item, params[item]));
      const state = locationState ? { ...locationState } : {};
      navigate(`${url.pathname}${url.search}`, {
        state,
        replace: true,
      });
    },
    [navigate, locationState]
  );

  return { removeURLParams, setURLParams };
};

export default useBrowserUtils;
