import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';

import ResponsiveDialog from '../components/ResponsiveDialog';
import './VideoMessageDialog.scss';
import useUnmountEffect from '../commons/useUnmountEffect';
import {
  ResourceInteractionContextProvider,
  useResourceInteractionContext,
} from '../resources/ResourceInteractionContextProvider';
import queryCache from '../app/queryCache';
import VideoMessageViewer from './VideoMessageViewer';

let resolve;
let containerElement;
let rootEl;

const VideoMessageDialog = (props) => {
  const {
    spaceId,
    createdByUserInfoId,
    vaamId,
    title,
    description,
    createdDate,
    logoUrl,
    navigate,
    location,
    handleViewMessageViewedTime,
    handleOnClosed,
    handleOnStart,
  } = props;

  const [isOpen, setIsOpen] = useState(true);
  const { resourceInteractionSessionId } = useResourceInteractionContext();
  console.log('####resourceInteractionSessionId', resourceInteractionSessionId);

  useUnmountEffect(() => {
    console.log('componentWillUnmount VideoMessageDialog');
    resolve = null;
    containerElement = null;
  });

  const handleClose = () => {
    setIsOpen(false);
    if (handleOnClosed) handleOnClosed();
    VideoMessageDialog.destroy();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ResponsiveDialog modal maxWidth="lg" className="video-message-dialog" onClose={handleClose}>
      <div className="content">
        <VideoMessageViewer
          spaceId={spaceId}
          vaamId={vaamId}
          title={title}
          description={description}
          createdByUserInfoId={createdByUserInfoId}
          createdDate={createdDate}
          logoUrl={logoUrl}
          navigate={navigate}
          location={location}
          handleViewMessageViewedTime={handleViewMessageViewedTime}
          handleOnStart={handleOnStart}
        />
      </div>
    </ResponsiveDialog>
  );
};

VideoMessageDialog.destroy = (retVal = false) => {
  if (containerElement) {
    rootEl.unmount();
    containerElement = null;
  }
  if (resolve) resolve(retVal);
};

VideoMessageDialog.show = (
  spaceId,
  vaamId,
  title,
  description,
  createdByUserInfoId,
  createdDate,
  logoUrl,
  navigate,
  location,
  handleViewMessageViewedTime,
  handleOnStart,
  handleOnClosed
) => {
  containerElement = document.createElement('div');
  rootEl = createRoot(containerElement);
  rootEl.render(
    <QueryClientProvider client={queryCache}>
      <ResourceInteractionContextProvider>
        <VideoMessageDialog
          spaceId={spaceId}
          vaamId={vaamId}
          title={title}
          description={description}
          createdByUserInfoId={createdByUserInfoId}
          createdDate={createdDate}
          logoUrl={logoUrl}
          navigate={navigate}
          location={location}
          handleViewMessageViewedTime={handleViewMessageViewedTime}
          handleOnStart={handleOnStart}
          handleOnClosed={handleOnClosed}
        />
      </ResourceInteractionContextProvider>
    </QueryClientProvider>
  );
  return new Promise((res) => {
    resolve = res;
  });
};

VideoMessageDialog.propTypes = {
  vaamId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  createdByUserInfoId: PropTypes.number,
  createdDate: PropTypes.string,
  logoUrl: PropTypes.string,
  navigate: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  handleViewMessageViewedTime: PropTypes.func,
  handleOnClosed: PropTypes.func,
  handleOnStart: PropTypes.func,
  spaceId: PropTypes.string,
};

export default VideoMessageDialog;
