import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { MenuEnhancedProps } from '../../components/SelectEnhanced';
import './CmsSelect.scss';

function CmsSelect(props) {
  const { onChange, options, label, visible } = props;

  const handleOnChange = (e) => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <div className={`cms-select ${!visible ? 'cms-select-hidden' : ''} `}>
      {label && <InputLabel className="cms-select-label">{label}</InputLabel>}
      <FormControl className="cms-select-control" variant="outlined">
        <Select
          onChange={handleOnChange}
          value={props.value}
          MenuProps={MenuEnhancedProps}
          classes={{ input: 'cms-select-input' }}
        >
          {options.map(({ value, name, disabled }) => (
            <MenuItem value={value} key={value} disabled={disabled}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

CmsSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  label: PropTypes.string,
  value: PropTypes.string,
  visible: PropTypes.bool,
};

CmsSelect.defaultProps = {
  visible: true,
};

export default CmsSelect;
