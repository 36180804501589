import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import './VideoPlayerReloadButton.scss';

const VideoPlayerReloadButton = (props) => {
  const { player, onReload } = props;
  // const { remoteState } = useVideoPresentationContext();
  const isPlaying = player?.getCurrentState()?.isPlaying;

  const handleOnClick = () => {
    onReload();
  };
  console.log('### VideoPlayerReloadButton', isPlaying);

  if (isPlaying === false) {
    return (
      <IconButton className="play-video-button" onClick={handleOnClick}>
        <PlayArrowIcon />
      </IconButton>
    );
  }
  return null;
};

VideoPlayerReloadButton.propTypes = {
  player: PropTypes.instanceOf(Object),
  onReload: PropTypes.func,
};

export default VideoPlayerReloadButton;
