import queryCache, { CacheKeys } from '../../../app/queryCache';
import { systemFonts } from '../../../app/appConstants';

export function getFontId(fontFamily) {
  return fontFamily.replace(/\s+/g, '-').toLowerCase();
}

export function get(url) {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.overrideMimeType('application/json');
    request.open('GET', url, true);
    request.onreadystatechange = () => {
      // Request has completed
      if (request.readyState === 4) {
        if (request.status !== 200) {
          // On error
          reject(new Error(`Response has status code ${request.status}`));
        } else {
          // On success
          resolve(request.responseText);
        }
      }
    };
    request.send();
  });
}

export function removeFontNameExtension(fontFamily) {
  if (!fontFamily) return fontFamily;
  return fontFamily.replace(/\..+$/, '');
}

export function checkAvailableFonts(font, myFonts) {
  let fontCopy = font;
  if (!fontCopy) return '';
  if (fontCopy.includes(':')) {
    const fontWithVariant = fontCopy.split(':');
    fontCopy = fontWithVariant[0];
  }
  const googleFonts = queryCache.getQueryData([CacheKeys.requestGoogleFontsList]);

  let isGoogleFont = false;
  if (googleFonts && typeof googleFonts.find === 'function') {
    isGoogleFont = googleFonts.find((item) => item.name === fontCopy);
  }

  let isUploadedFont = false;
  if (myFonts && typeof myFonts.find === 'function') {
    isUploadedFont = myFonts.find(
      (item) => removeFontNameExtension(item.resourceName) === fontCopy
    );
  }

  let isSystemFont = false;
  if (systemFonts && typeof systemFonts.find === 'function') {
    isSystemFont = systemFonts.find((item) => item.name === fontCopy);
  }

  if (isGoogleFont || isUploadedFont || isSystemFont) {
    return fontCopy;
  } else {
    return '';
  }
}

export function checkAdditionalStyles(font) {
  let additionalStyles = null;
  if (font?.includes(':')) {
    const fontWithVariant = font.split(':');
    if (fontWithVariant[1] === 'regular' || fontWithVariant[1] === 'italic') {
      additionalStyles = {
        fontStyle: fontWithVariant[1],
      };
    } else {
      additionalStyles = {
        fontWeight: fontWithVariant[1],
      };
    }
  }
  return additionalStyles;
}
