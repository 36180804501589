import React from 'react';
import { Box, Typography } from '@mui/material';

export type ChartTooltipContentProps = {
  title: string;
  body: string[];
  colors: string[];
};

const ChartTooltipContent = ({ title, body, colors }: ChartTooltipContentProps) => {
  return (
    <Box display="flex" flexDirection="column" padding={2} gap={1}>
      {title && <Typography style={{ fontFamily: 'MediumLLWeb-Bold' }}>{title}</Typography>}
      {body.map((text, i) => {
        const color = colors[i];
        return (
          <Box key={i} display="flex" flexDirection="row" gap={1} alignItems="center">
            <span className="icon-minus-solid" style={{ color: color }}></span>
            <Typography>{text}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default ChartTooltipContent;
