import _omitBy from 'lodash/omitBy';

export function linkToSpace(space, query) {
  if (!space) {
    return '';
  }
  const domain = window.location.origin;
  let pathname = `/s/${space.id}`;
  const params = _omitBy(query, (p) => p == null);
  // keep it until we have a good approach to introduce to clients
  // add shared Code to track who shared space
  // const shareCode = spaceUser.getShareSpaceCode();
  // if (shareCode) {
  //   params.c = shareCode;
  // }

  if (space.urlSlug) {
    pathname = `/s/${space.urlSlug}`;
  }
  const numberParams = Object.keys(params).length;
  if (numberParams) {
    pathname = `${pathname}?`;
  }
  const searchParams = new URLSearchParams(params);
  return `${domain}${pathname}${searchParams}`;
}

const useURLSpace = (space) => {
  const getShareLinkSpace = (query) => {
    return linkToSpace(space, query);
  };

  return {
    getShareLinkSpace,
  };
};

export default useURLSpace;
