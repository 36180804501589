import React, { Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from '../../components/AppProvider';
import useAssessmentQuery from '../queries/useAssessmentQuery';
import Loading from '../../components/Loading';
import { useUnmountEffect } from '../../commons/CustomHooks';
import queryCache, { CacheKeys } from '../../app/queryCache';

const Assessment = React.lazy(() => import('./Assessment'));

function TrialAssessment(props) {
  const { id, isMobile } = props;
  const { userInfo } = useAppContext();
  const { data, isLoading } = useAssessmentQuery(id);

  const userTest = useMemo(() => {
    return {
      fullName: userInfo?.fullName,
      email: userInfo?.contactEmail,
    };
  }, [userInfo.fullName, userInfo.contactEmail]);

  useUnmountEffect(() => {
    queryCache.removeQueries({
      queryKey: [CacheKeys.fetchAssessment, id],
      exact: true,
    });
  });

  if (!id) {
    return null;
  }
  if (isLoading) {
    return (
      <div className="loading-box">
        <Loading />
      </div>
    );
  }

  return (
    <Suspense fallback={<Loading />}>
      <Assessment assessment={data} userTest={userTest} isMobile={isMobile} type="trial" />;
    </Suspense>
  );
}

TrialAssessment.propTypes = {
  id: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default TrialAssessment;
