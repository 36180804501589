import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import DndFileUploader from '../../components/DndFileUploader3/DndFileUploader';
import { CONTENT_COMPONENT, CMS_COMPONENT_TYPE } from '../cmsConstants';
import './CmsFileUploader.scss';
import CropperImage from '../../components/CropperImage/CropperImage';
import { useThemeSettingsContext } from '../components/context/ThemeSettingsContextProvider';
import { useSpaceContext } from '../../spaces/SpaceContext';
import OfficialButton from '../../components/OfficialButtons';

function getSizeByRatio(ratio) {
  const values = {
    '16:9': '1920 x 1080px',
    '4:1': '1200 x 300px',
    '3:2': '1200 x 800px',
    '2:3': '800 x 1200px',
    '1:1': '600 x 600px',
    '2:1': '1920 x 960px',
  };
  return values[ratio];
}

function renderWarningMessage(warnings, property) {
  if (warnings && warnings[property]) {
    if (warnings[property].type === 'incompatible') {
      return i18n.t(
        'This video link might not be compatible with our application. Please change or check again to ensure a smooth experience'
      );
    }
  }
  return null;
}

function getUploaderFileDetails(contentType, componentType, ratio) {
  const IMAGE_INPUT_LABELS = {
    [CMS_COMPONENT_TYPE.WIDGET]: i18n.t('Add image'),
    [CMS_COMPONENT_TYPE.CAROUSEL]: i18n.t('Add image'),
    [CMS_COMPONENT_TYPE.VIDEO]: i18n.t('Add thumbnail'),
  };

  const isVideoComponent = componentType === CMS_COMPONENT_TYPE.VIDEO;

  const sizeByRatio = getSizeByRatio(ratio);

  const FILE_DETAILS = {
    [CONTENT_COMPONENT.VIDEO_UPLOAD]: {
      defaultFileAccept: '.mp4, .webm, .mov, .avi',
      fileInputLabel: i18n.t('Add video'),
      urlInputLabel: i18n.t('Video link'),
      urlInputPlaceHolder: i18n.t('Enter link to your video'),
      fileInputInfo: (
        <div className="file-input-info">{'Supported file types: MP4, WEBM, MOV, AVI.'}</div>
      ),
    },
    [CONTENT_COMPONENT.FILE_UPLOAD]: {
      defaultFileAccept: '.jpg, .jpeg, .png, .gif, .webp, .svg, .apng, .tif, .tiff, .pdf',
      fileInputLabel: i18n.t('Add file'),
      urlInputLabel: i18n.t('File link'),
      urlInputPlaceHolder: i18n.t('Enter link'),
      disabledCropper: ['gif', 'tif', 'tiff'],
      helpTextByType: {
        gif: 'It is not possible to crop GIF files because the animation breaks',
      },
      urlInputInfo: (
        <div className="url-input-info">
          {'Support links to PDF and images.'}
          <br />
          {`For the best result, use an image with a ${ratio} aspect ratio (${sizeByRatio}).`}
        </div>
      ),
      fileInputInfo: (
        <div className="file-input-info">
          {'Supported file types: JPG, JPEG, PNG, GIF, WEBP, SVG, APNG, TIF, TIFF, PDF.'}
          <br />
          {`For the best result, use an image with a ${ratio} aspect ratio (${sizeByRatio}).`}
        </div>
      ),
    },
    [CONTENT_COMPONENT.IMAGE_UPLOAD]: {
      defaultFileAccept: '.jpg, .jpeg, .png, .gif, .webp, .svg, .apng, .tif, .tiff',
      fileInputLabel: IMAGE_INPUT_LABELS[componentType],
      urlInputLabel: isVideoComponent ? i18n.t('Thumbnail link') : i18n.t('Image link'),
      urlInputPlaceHolder: i18n.t('Enter link to your image'),
      disabledCropper: ['gif', 'tif', 'tiff'],
      helpTextByType: {
        gif: 'It is not possible to crop GIF files because the animation breaks',
      },
      urlInputInfo: !isVideoComponent && (
        <div className="url-input-info">
          {'Support links to images.'}
          <br />
          {`For the best result, use an image with a ${ratio} aspect ratio (${sizeByRatio}).`}
        </div>
      ),
      fileInputInfo: (
        <div className="file-input-info">
          {'Supported file types: JPG, JPEG, PNG, GIF, WEBP, SVG, APNG, TIF, TIFF.'}
          <br />
          {`For the best result, use an image with a ${ratio} aspect ratio (${sizeByRatio}).`}
        </div>
      ),
    },
  };

  return FILE_DETAILS[contentType];
}

function CmsFileUploader(props) {
  const { contentType, componentType, spaceId, resourceId, ratio, content } = props;
  const { setOpenUnsplashPreview, setWorkingData, savedColors, setSavedColors } =
    useThemeSettingsContext();
  const { isGlobalResourcesEnabled } = useSpaceContext();

  // console.log('### CmsFileUploader', resourceId);

  async function handleResourceChange(newResources) {
    if (props.onChange) {
      const newResource = newResources?.[0];
      await props.onChange(newResource, contentType);
    }
  }

  const contentFile = getUploaderFileDetails(contentType, componentType, ratio);

  const cropperElement = () => {
    if ([CONTENT_COMPONENT.FILE_UPLOAD, CONTENT_COMPONENT.IMAGE_UPLOAD].includes(contentType)) {
      // console.log('### CmsFileUploader content', content);
      return (
        <CropperImage
          spaceId={spaceId}
          resourceId={resourceId}
          onChange={(newResource) => handleResourceChange([newResource])}
          isPortal
          ratio={ratio}
          savedColors={savedColors}
          setSavedColors={setSavedColors}
          defaultBackgroundColor={content.backgroundImageCropped}
          disabledCropper={contentFile.disabledCropper}
          helpTextByType={contentFile?.helpTextByType}
          cropperData={content.cropperData}
        />
      );
    } else {
      return null;
    }
  };

  const unsplashElement = () => {
    if ([CONTENT_COMPONENT.FILE_UPLOAD, CONTENT_COMPONENT.IMAGE_UPLOAD].includes(contentType)) {
      return (
        <OfficialButton
          label={i18n.t('Search Unsplash')}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setWorkingData({ contentType, currentTab: props.currentTab });
            setOpenUnsplashPreview(true);
          }}
          variant="rectangle-primary"
          className="unsplash-button"
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className="cms-file-uploader">
      <div className="uploader-area">
        <DndFileUploader
          accept={contentFile?.defaultFileAccept}
          spaceId={spaceId}
          hideUrlInput={contentFile?.hideUrlInput}
          fileInputLabel={contentFile?.fileInputLabel}
          urlInputLabel={contentFile?.urlInputLabel}
          urlInputPlaceHolder={contentFile?.urlInputPlaceHolder}
          renderWarningMessageMethod={renderWarningMessage}
          onChange={handleResourceChange}
          resourceId={resourceId}
          isPortal
          callOnChangeAfterFetchingResource={false}
          cropImageElement={cropperElement()}
          unsplashElement={unsplashElement()}
          isGlobalResourcesEnabled={isGlobalResourcesEnabled}
          canEdit
          canEditNameSameStep={false}
          folderId={props.folderId}
          fileInputInfo={contentFile?.fileInputInfo}
          urlInputInfo={contentFile?.urlInputInfo}
          isAdmin={props.isAdmin}
        />
      </div>
    </div>
  );
}

CmsFileUploader.propTypes = {
  contentType: PropTypes.string,
  componentType: PropTypes.string,
  onChange: PropTypes.func,
  resourceId: PropTypes.number,
  spaceId: PropTypes.string,
  ratio: PropTypes.string,
  currentTab: PropTypes.number,
  content: PropTypes.instanceOf(Object),
  folderId: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default CmsFileUploader;
