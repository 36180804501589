import { getUrlParameters, newGuid } from '../commons/utils';

export const localStorageKey = 'cms-copied-data';

function processObject(obj, params = {}) {
  if (!obj) return;
  const { fromSpaceId, fromSpaceUrlSlug, toSpaceId, toSpaceUrlSlug } = params;
  const resources = [];
  const newObj = obj;
  const newId = newGuid();
  newObj.id = newId;
  const isSameSpace = fromSpaceId === toSpaceId;

  const properties = Object.keys(obj);
  properties.forEach((property) => {
    const value = obj[property];
    if (property.endsWith('MaterialId')) {
      const prop = property.replace('MaterialId', '');
      const prop2 = `${prop}ResourceId`;
      const resourceId = obj[prop2];
      const newResource = {
        uniqueId: newGuid(),
        fieldName: prop,
        action: 'copy',
        data: { materialId: +value, ...params, resourceId: +resourceId },
      };
      resources.push(newResource);
    }

    if (property.endsWith('Link') && fromSpaceId && toSpaceId && !isSameSpace) {
      const newPattern = toSpaceUrlSlug ? `/s/${toSpaceUrlSlug}` : `/s/${toSpaceId}`;
      const pattern1 = `/s/${fromSpaceId}`;
      const pattern2 = fromSpaceUrlSlug ? `/s/${fromSpaceUrlSlug}` : pattern1;

      const canBeASharedLink = value.includes(pattern2) || value.includes(pattern1);
      if (canBeASharedLink) {
        const { srId, drId } = getUrlParameters(value);
        // cbId: do nothing in this iteration
        if (drId) {
          const newResource = {
            uniqueId: newGuid(),
            fieldName: property,
            action: 'copy',
            data: { directoryId: +drId, ...params },
          };
          resources.push(newResource);
        }
        if (srId) {
          const newResource = {
            uniqueId: newGuid(),
            fieldName: property,
            action: 'copy',
            data: { materialId: +srId, ...params },
          };
          resources.push(newResource);
        }

        // update the shared link based on the destination space
        const newValue = value.replace(pattern2, newPattern).replace(pattern1, newPattern);
        newObj[property] = newValue;
      }
    }

    // directoryId in resourceWidget settings
    if (property === 'settings' && value?.subType === 'resources' && value?.directoryId > 0) {
      const newResource = {
        uniqueId: newGuid(),
        fieldName: property,
        action: 'copy',
        data: { directoryId: +value.directoryId, ...params },
      };
      resources.push(newResource);
    }
  });

  if (resources.length > 0) {
    newObj.resources = resources;
  }
}

function processList(items, params = {}) {
  if (!items?.length) return;
  items.forEach((item) => {
    processObject(item, params);
  });
}

export function processPastingBlock(block, params = {}) {
  if (!block) return;
  // console.log('### 213 processPastingBlock 1:', block);
  // for widget's background
  processObject(block, params);
  if (block.items?.length > 0) {
    block.items.forEach((item) => {
      // for columns / components
      processObject(item, params);
      // for carousel
      processList(item.items, params);
    });
  }
  // console.log('### 213 processPastingBlock 2:', block);
}
