import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import queryCache, { CacheKeys } from '../../app/queryCache';
import { ParticipantRole } from '../../app/appConstants';
import { useSpaceContext } from '../SpaceContext';
import spaceUser from '../spaceUser';
import { deleteSpaceUser } from './spaceUsersServices';
import i18n from '../../i18n';
import { sendNotification } from '../../commons/utils';
import useSpaceUsersQuery from './useSpaceUsersQuery';

const SpaceUsersContext = React.createContext({});
SpaceUsersContext.displayName = 'SpaceUsersContext';

export const useSpaceUsersContext = () => React.useContext(SpaceUsersContext);

const getSpaceUserContext = memoizeOne((participants) => {
  let sortedParticipants = [];
  const isHidden = spaceUser.isHidden();
  const currentUserInfoId = spaceUser.getUserInfoId();

  sortedParticipants = participants.map((item) => ({
    ...item,
    isOnline: !isHidden && item.userInfoId === currentUserInfoId ? true : item.isOnline,
  }));

  sortedParticipants = _.sortBy(sortedParticipants, (item) => !item.isOnline);
  return {
    participants: sortedParticipants,
    numOnlineUsers: sortedParticipants.filter((f) => f.isOnline === true).length,
  };
});

function sortParticipants(items, isSpace) {
  if (isSpace) {
    return _.orderBy(items, ['role', 'lastRole']);
  }

  const owners = _.filter(items, (item) => {
    if (item.participant?.lastRole) {
      return item.participant.lastRole === ParticipantRole.Host;
    } else {
      return item.participant?.role === ParticipantRole.Host;
    }
  });
  const others = _.reject(items, (item) => {
    if (item.participant?.lastRole) {
      return item.participant.lastRole === ParticipantRole.Host;
    } else {
      return item.participant?.role === ParticipantRole.Host;
    }
  });
  const sortedOthers = owners.concat(others);
  return sortedOthers;
}

const canAddParticipant = (isSpace, signalRData) => {
  // if received signalR event when in meeting, check if that participant is added/deleted/updated from meeting or space
  // signalRData.participant is not null -> from meeting
  // signalRData.participant is null -> from space
  if (!isSpace && !signalRData.participant) {
    return false;
  }
  return true;
};

const canDeleteParticipant = (isSpace, signalRData) => {
  // if received signalR event when in meeting, check if that participant is added/deleted/updated from meeting or space
  // signalRData.participant is not null -> from meeting
  // signalRData.participant is null -> from space
  if (isSpace && signalRData.participant) {
    return false;
  }
  return true;
};

const SpaceUsersContextProvider = ({ children, spaceId }) => {
  const [participants, setParticipants] = useState([]);
  const { signalRConnection: spaceHubConnection } = useSpaceContext();

  const isSpace = true;
  const code = spaceUser.getInvc();
  // const isSupervisor = spaceUser.isSupervisor();

  const getSpaceUsersQuery = useSpaceUsersQuery(spaceId, false, false, false);

  const handleSpaceUserLeft = React.useCallback((data) => {
    console.log('handleSpaceUserLeft', data.lastActive);
    const { id, lastActive } = data;
    setParticipants((prevState) => {
      const newParticipants = [...prevState];
      const foundIndex = _.findIndex(newParticipants, (item) => item.id === id);
      if (foundIndex < 0) {
        return prevState;
      }
      newParticipants[foundIndex] = { ...newParticipants[foundIndex], lastActive, isOnline: false };
      return newParticipants;
    });
  }, []);

  const handleSpaceUserJoined = React.useCallback((data) => {
    console.log('handleSpaceUserJoined', data.lastActive);
    const { id, lastActive } = data;
    setParticipants((prevState) => {
      const newParticipants = [...prevState];
      const foundIndex = _.findIndex(newParticipants, (item) => item.id === id);
      if (foundIndex < 0) {
        return prevState;
      }
      newParticipants[foundIndex] = { ...newParticipants[foundIndex], lastActive, isOnline: true };
      return newParticipants;
    });
  }, []);

  const handleOnParticipantAdded = React.useCallback(
    (data) => {
      if (canAddParticipant(isSpace, data)) {
        if (data.isHidden) {
          return;
        }
        setParticipants((prevState) => {
          const newParticipants = [...prevState];
          const foundParticipant = _.find(
            newParticipants,
            (item) => item.userInfoId === data.userInfoId
          );
          if (foundParticipant) {
            return prevState;
          }

          newParticipants.push(data);
          queryCache.setQueryData([CacheKeys.fetchSpaceUsers, spaceId, code], () => {
            return newParticipants;
          });
          // clear cache for participants loaded for space chat, reference to ParticipantsInteractionsPopover
          queryCache.setQueryData([CacheKeys.fetchSpaceUsers, spaceId, null, code], () => {
            return newParticipants;
          });
          return newParticipants;
        });
      }
      queryCache.removeQueries({ queryKey: [CacheKeys.fetchAllSpaceUsers], exact: false });
      queryCache.removeQueries({ queryKey: [CacheKeys.getSpaceUsers, spaceId], exact: true });
    },
    [code, isSpace, spaceId]
  );

  const handleOnParticipantUpdated = React.useCallback(
    (data) => {
      setParticipants((prevState) => {
        const newParticipants = [...prevState];
        const foundIndex = _.findIndex(
          newParticipants,
          (item) => item.userInfoId === data.userInfoId
        );
        if (foundIndex < 0) {
          newParticipants.push(data);
          return newParticipants;
        }
        newParticipants[foundIndex] = { ...data };
        return newParticipants;
      });
      queryCache.removeQueries({ queryKey: [CacheKeys.getSpaceUsers, spaceId], exact: true });
    },
    [spaceId]
  );

  const handleOnParticipantDeleted = React.useCallback(
    (data) => {
      if (canDeleteParticipant(isSpace, data)) {
        console.log('SpaceUserDeletedEvent', data);
        setParticipants((prevState) => {
          let newParticipants = [...prevState];
          const foundIndex = _.findIndex(
            newParticipants,
            (item) => item.userInfoId === data.userInfoId
          );
          if (foundIndex < 0) {
            return prevState;
          }
          newParticipants = _.reject(
            newParticipants,
            (item) => item.userInfoId === data.userInfoId
          );
          return newParticipants;
        });
      }
      queryCache.removeQueries({ queryKey: [CacheKeys.fetchAllSpaceUsers], exact: false });
      queryCache.removeQueries({ queryKey: [CacheKeys.getSpaceUsers, spaceId], exact: true });
    },
    [isSpace, spaceId]
  );

  useEffect(() => {
    if (spaceHubConnection) {
      spaceHubConnection.on('SpaceUserAddedEvent', handleOnParticipantAdded);
      spaceHubConnection.on('SpaceUserUpdatedEvent', handleOnParticipantUpdated);
      spaceHubConnection.on('SpaceUserDeletedEvent', handleOnParticipantDeleted);
      spaceHubConnection.on('SpaceUserLeftEvent', handleSpaceUserLeft);
      spaceHubConnection.on('SpaceUserJoinedEvent', handleSpaceUserJoined);
    }

    return () => {
      if (spaceHubConnection) {
        spaceHubConnection.off('SpaceUserAddedEvent', handleOnParticipantAdded);
        spaceHubConnection.off('SpaceUserUpdatedEvent', handleOnParticipantUpdated);
        spaceHubConnection.off('SpaceUserDeletedEvent', handleOnParticipantDeleted);
        spaceHubConnection.off('SpaceUserLeftEvent', handleSpaceUserLeft);
        spaceHubConnection.off('SpaceUserJoinedEvent', handleSpaceUserJoined);
      }
    };
  }, [
    spaceHubConnection,
    handleOnParticipantAdded,
    handleOnParticipantUpdated,
    handleOnParticipantDeleted,
    handleSpaceUserLeft,
    handleSpaceUserJoined,
  ]);

  useEffect(() => {
    const data = getSpaceUsersQuery.data;
    if (data) {
      setParticipants(sortParticipants(data, isSpace));
    }
  }, [getSpaceUsersQuery.data, isSpace]);

  const context = getSpaceUserContext(participants);
  context.setParticipants = setParticipants;

  context.deleteSpaceUser = React.useCallback(
    async (participantUserInfoId) => {
      await deleteSpaceUser(spaceId, participantUserInfoId);

      sendNotification(i18n.t('Deleted successfully'), { type: 'success' });
      setParticipants((prevParticipants) => {
        // update UI
        let newParticipants = [...prevParticipants];
        newParticipants = newParticipants.filter((p) => p.userInfoId !== participantUserInfoId);
        return newParticipants;
      });
    },
    [spaceId]
  );

  return <SpaceUsersContext.Provider value={context}>{children}</SpaceUsersContext.Provider>;
};

SpaceUsersContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
  spaceId: PropTypes.string,
};

export default SpaceUsersContextProvider;
