import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceUsers } from './spaceUsersServices';

const useSpaceUsersQuery = (spaceId, isPortal = true, enabled = true, refetchOnMount = true) => {
  const getSpaceParticipantsQuery = useQuery({
    queryKey: [CacheKeys.getSpaceUsers, spaceId],
    queryFn: async () => {
      const resp = await fetchSpaceUsers(spaceId, isPortal);
      return resp;
    },
    enabled,
    retry: 3,
    retryDelay: () => 5000,
    refetchOnMount: refetchOnMount ? 'always' : false,
  });

  // const users = getSpaceParticipantsQuery.data;

  return getSpaceParticipantsQuery;
};

export default useSpaceUsersQuery;
