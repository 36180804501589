// import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import TextFieldEnhanced from '../../components/TextFieldEnhanced';

const PageNameInput = (props) => {
  const { defaultValue, onChange } = props;
  const ref = React.useRef(null);

  const handleOnChange = (name, value) => {
    console.log('handleOnChange', ref.current.isValid());
    if (ref.current.isValid()) {
      onChange(name, value);
    }
  };
  return (
    <div className="cms-component-panel-control">
      <label className="cms-component-panel-control-label">{i18n.t('Page Heading')}</label>
      <TextFieldEnhanced
        ref={ref}
        defaultValue={defaultValue}
        required
        autoComplete="off"
        fullWidth
        name="name"
        className="cms-component-panel-control-input"
        InputProps={{
          inputProps: { maxLength: 25 },
        }}
        onChange={handleOnChange}
      />
      {defaultValue.length === 25 && (
        <span className="input-hint">{i18n.t(`You've reached the maximum of 25 characters.`)}</span>
      )}
    </div>
  );
};

PageNameInput.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};
export default PageNameInput;
