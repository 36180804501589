import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import SearchBar from '../../components/SearchBar';
import i18n from '../../i18n';
import useAssessmentsQuery from '../queries/useAssessmentsQuery';
import ListCard from './ListCards';
import './CMSSelectAssessmentPopup.scss';
import OfficialButton from '../../components/OfficialButtons';

const CMSSelectAssessmentPopup = (props) => {
  const { onChange, onClose, excludeIds } = props;
  const [keywords, setKeywords] = useState('');
  const [selectedAssessments, setSelectedAssessments] = useState([]);

  const { assessmentsQuery } = useAssessmentsQuery(keywords, true);

  const handleOnChangeKeyWord = (value) => {
    setKeywords(value);
  };

  const handleOnChangeSelectedAssessment = (assessment) => {
    const index = selectedAssessments.findIndex((item) => item.id === assessment.id);
    if (index === -1) {
      setSelectedAssessments([...selectedAssessments, assessment]);
    } else {
      setSelectedAssessments(selectedAssessments.filter((item) => item.id !== assessment.id));
    }
  };

  const handleOnAdd = () => {
    if (onChange) {
      // Sort selected assessments by lastUpdated
      const sortedSelectedAssessments = selectedAssessments.sort((a, b) => {
        return new Date(b.lastUpdated) - new Date(a.lastUpdated);
      });
      onChange(sortedSelectedAssessments);
    }
    onClose();
    setSelectedAssessments([]);
  };

  const listItems = React.useMemo(() => {
    if (!assessmentsQuery?.data?.pages) return [];
    return assessmentsQuery.data.pages
      .reduce((m, page) => {
        const { items } = page;
        if (!items) {
          console.error('items is undefined', { page });
          return m;
        }
        return m.concat(items);
      }, [])
      .filter((item) => !excludeIds?.includes(item.id));
  }, [assessmentsQuery.data, excludeIds]);

  const handleClearSelectedAssessments = () => {
    setSelectedAssessments((prev) => {
      return prev?.filter((item) => !listItems?.find((listItem) => listItem.id === item.id));
    });
  };

  function renderContent() {
    return (
      <div className="select-assessments-container">
        <div className="select-assessments-header">
          <div>
            <div className="select-assessments-title">
              <div>{i18n.t('Select assessment to add')}</div>
            </div>
            <div className="text-gray">{`${selectedAssessments?.length} selected`}</div>
          </div>

          <SearchBar
            defaultValue={keywords}
            onChange={handleOnChangeKeyWord}
            placeholder="Search assessment's name"
            autoFocus={false}
          />
        </div>

        <div className="select-assessments-body">
          <ListCard
            query={assessmentsQuery}
            listItems={listItems}
            keywords={keywords}
            columns={{ xs: 4, sm: 12, md: 12, xl: 8 }}
            selectedAssessments={selectedAssessments}
            excludeIds={excludeIds}
            onClick={handleOnChangeSelectedAssessment}
          />
        </div>
        <div className="select-assessments-footer">
          <div>
            <OfficialButton
              label={i18n.t('Clear')}
              onClick={handleClearSelectedAssessments}
              variant="regular-secondary"
              disabled={selectedAssessments?.length === 0}
            />
          </div>
          <div className="group-buttons">
            <OfficialButton
              label={i18n.t('Cancel')}
              onClick={onClose}
              variant="regular-secondary"
            />
            <OfficialButton label={i18n.t('Add')} onClick={handleOnAdd} variant="regular-green" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <ResponsiveDialog open maxWidth="xl" className="select-assessments-dialog" onClose={onClose}>
      {renderContent()}
    </ResponsiveDialog>
  );
};

CMSSelectAssessmentPopup.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  excludeIds: PropTypes.instanceOf(Array),
};

CMSSelectAssessmentPopup.displayName = 'CMSSelectAssessmentPopup';

export default CMSSelectAssessmentPopup;
