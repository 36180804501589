import React from 'react';
import PropTypes from 'prop-types';
import { ResourceType } from '../../app/appConstants';
import { getCssClassByResourceType } from '../../commons/ResourceUtils';

const ResourceIcon = (props) => {
  const { type, name, extension, className } = props;

  const iconCssClass =
    type === ResourceType.cad && extension
      ? `icon-icon-${extension.toLowerCase()}`
      : getCssClassByResourceType(type, name);

  return (
    <span className={`${iconCssClass} ${className}`}>
      <span className="path1" />
      <span className="path2" />
      {type === ResourceType.cad && (
        <>
          <span className="path3"></span>
          <span className="path4"></span>
          <span className="path5"></span>
          <span className="path6"></span>
        </>
      )}
    </span>
  );
};

ResourceIcon.propTypes = {
  type: PropTypes.number,
  name: PropTypes.string,
  extension: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(ResourceIcon);
