import React, { useCallback } from 'react';
import OfficialButton from '../../components/OfficialButtons';
import { useGlobalResourcesContext } from '../context/GlobalResourcesContextProvider';
import i18n from '../../i18n';
import { RowData } from '../context/Interfaces';

interface Props {
  resources: Array<RowData>;
}

function SelectAllButton(props: Props) {
  const { resources } = props;
  const { isSelectedAll, addSelectedResources, removedSelectedResources } =
    useGlobalResourcesContext();

  const isSelectAll = isSelectedAll();
  const handleSelectAllClick = useCallback(
    (checked: boolean) => {
      let newSelecteds = resources;
      if (checked) {
        newSelecteds = newSelecteds.filter((x) => !x.isDisabled);
        addSelectedResources(newSelecteds);
        return;
      }
      removedSelectedResources(newSelecteds);
    },
    [addSelectedResources, removedSelectedResources, resources]
  );

  return (
    <OfficialButton
      label={isSelectAll ? i18n.t('Deselect all') : i18n.t('Select all')}
      onClick={() => handleSelectAllClick(!isSelectAll)}
      variant="small-rectangle-secondary"
    />
  );
}

export default SelectAllButton;
