import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import i18n from '../i18n';
import './GlobalResourcesBreadcrumbs.scss';
import { useBreadcrumbContext } from './context/BreadcrumbsContextProvider';

const GlobalResourcesBreadcrumbs = (props) => {
  const { selectedTab } = props;
  const { breadcrumbs, goToBreadcrumbItem } = useBreadcrumbContext();
  const data = breadcrumbs;
  const handleOnClick = (index, clickingItem, lastItem = null) => {
    let item = clickingItem;
    if (index === 0 && (!data?.length || data[0].name !== '_root')) {
      // click on Root
      item = null;
    }
    goToBreadcrumbItem(item);
    props.onClickItem(item, lastItem);
  };

  let newTitle = (
    <div className={props.className}>
      <Breadcrumbs
        aria-label="breadcrumb"
        className="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Typography color="textPrimary" className="global-resources-nav">
          {i18n.t('Global Resources')}
        </Typography>
        {data?.[0]?.id ? (
          <Link
            component="button"
            variant="body2"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleOnClick(0, data?.[0]);
            }}
          >
            {selectedTab}
          </Link>
        ) : (
          <Typography color="textPrimary">{selectedTab}</Typography>
        )}
      </Breadcrumbs>
    </div>
  );

  if (data?.length > 1) {
    const lastItem = data[data.length - 1];
    newTitle = (
      <div className={props.className}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className="global-resources-nav"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Typography color="textPrimary">{i18n.t('Global Resources')}</Typography>
          {data.map((x, index) => {
            return (
              <Link
                key={x.id}
                component="button"
                variant="body2"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  console.log('test: ', x, lastItem);
                  handleOnClick(index, x, lastItem);
                }}
              >
                {index === 0 ? selectedTab : x.name}
              </Link>
            );
          })}
        </Breadcrumbs>
      </div>
    );
  }

  return newTitle;
};

GlobalResourcesBreadcrumbs.propTypes = {
  className: PropTypes.string,
  selectedTab: PropTypes.string.isRequired,
  onClickItem: PropTypes.func,
};

GlobalResourcesBreadcrumbs.defaultProps = {
  selectedTab: 'SPCE',
  className: 'global-resources-breadcrumbs',
  onClickItem: () => {},
};

export default React.memo(GlobalResourcesBreadcrumbs);
