import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import './GroupByUsersTable.scss';
import { ISpaceUser } from '../../types/User';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import { SpaceUserDetailsTab } from '../SpaceUserDetailsModal';
import Table from '../components/Table';
import LinearProgressBar from '../components/LinearProgress';
import { getPercent } from '../common';
import SpaceUserAvatar from '../components/SpaceUserAvatar';
// import LightTooltip from '../../components/LightTooltip';
// import i18n from '../../i18n';
import { AssessmentUserSummary } from './types';
import NoDaTaBox from '../../components/NoDataBox';
import LightTooltip from '../../components/LightTooltip';
import i18n from '../../i18n';

type AssessmentUser = AssessmentUserSummary & ISpaceUser;

type GroupByUsersTableProps = {
  data?: AssessmentUserSummary[];
  totalAssessments: number;
};

function GroupByUsersTable(props: GroupByUsersTableProps) {
  const { data, totalAssessments } = props;
  const { openSpaceUserModal, visibleUsers } = useSpaceAnalyticsContext();

  const assessmentsData = useMemo(() => {
    if (!data || !visibleUsers) {
      return [];
    }
    const usersDetails: AssessmentUser[] = [];
    data.forEach((assessment) => {
      const user: ISpaceUser | undefined = visibleUsers.find(
        (visibleUser) => visibleUser.userInfoId === assessment.userInfo
      );
      if (user) {
        const convertUser = {
          id: user.userInfoId,
          userInfoId: user.userInfoId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          fullName: user.fullName,
          isOrganizationUser: user.isOrganizationUser,
          role: user.role,
          orgRole: user.orgRole,
          isVisitor: user.isVisitor,
        };
        usersDetails.push({
          ...assessment,
          ...convertUser,
        });
      }
      return null;
    });
    return usersDetails;
  }, [visibleUsers, data]);

  function handleOnClickLink(assessment: AssessmentUser) {
    openSpaceUserModal(assessment, SpaceUserDetailsTab.ASSESSMENTS);
  }

  const renderContent = () => {
    if (!data?.length) {
      return <NoDaTaBox icon={<i className="icon-users" />} title="No data to show" />;
    }

    return (
      <Table
        columns={[
          {
            title: 'Name',
            field: 'fullName',
            cellStyle: {
              width: '40%',
            },
            type: 'string',
            sorting: false,
            render: (rowData: AssessmentUser) => {
              return <SpaceUserAvatar userInfo={rowData} />;
            },
          },

          {
            title: 'Assessments taken',
            field: 'noOfAssessmentsCompleted',
            cellStyle: {
              width: '40%',
              fontFamily: 'Roboto',
            },
            defaultSort: 'desc',
            customSort: (a: AssessmentUser, b: AssessmentUser) => {
              const aNum = a.noOfAssessmentsCompleted || 0;
              const bNum = b.noOfAssessmentsCompleted || 0;
              return aNum - bNum;
            },

            render: (rowData: AssessmentUser) => {
              if (!rowData.noOfAssessmentsCompleted) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return (
                  <LightTooltip title={i18n.t("The user hasn't joined the space yet")}>
                    <span style={{ cursor: 'pointer' }}>{'N/A'}</span>
                  </LightTooltip>
                );
              }
              return (
                <Box display="flex" alignItems="center" className="page-read-progress full-width">
                  <LinearProgressBar
                    color="#4783FF"
                    backgroundColor="#DBE1E3"
                    value={getPercent(rowData?.noOfAssessmentsCompleted, totalAssessments)}
                  />
                  <span>{`${rowData?.noOfAssessmentsCompleted}/${totalAssessments}`}</span>
                </Box>
              );
            },
          },
          {
            title: 'Passed rate',
            field: 'avgPercentageScore',
            cellStyle: {
              width: '20%',
              fontFamily: 'Roboto',
            },
            align: 'left',
            type: 'numeric',
            sorting: false,
            render: (rowData: AssessmentUser) => {
              return (
                <span className="btn-link" onClick={() => handleOnClickLink(rowData)}>
                  {`${Math.round(rowData.avgPercentageScore * 100)}%`}
                </span>
              );
            },
          },
        ]}
        data={assessmentsData}
      />
    );
  };

  return (
    <Box className="assessments-table">
      <Box>{renderContent()}</Box>
    </Box>
  );
}

GroupByUsersTable.propTypes = {};

export default GroupByUsersTable;
