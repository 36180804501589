import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';

import { useDebouncedCallback } from 'use-debounce';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import i18n from '../i18n';
import './FilterBox.scss';

/**
 *
 * @param {*} props { placeholder, onChange, onCancel, value }
 * @returns void
 */
const FilterBox = forwardRef((props, ref) => {
  const textInput = React.useRef(null);
  const { placeholder, onChange, onCancel, value, variant, disabled, autoFocus } = props;

  const onChangeText = useDebouncedCallback((event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  }, 1000);

  useEffect(() => {
    textInput.current.value = value || '';
  }, [value]);

  function handleOnCancel() {
    props.onChange('');
    textInput.current.value = '';
    textInput.current.focus();
    onCancel();
  }

  function handleOnClick(e) {
    if (props.onClick) {
      props.onClick(e);
    }
  }

  useImperativeHandle(ref, () => ({
    focus: () => {
      textInput?.current?.focus();
    },
  }));

  return (
    <div className="filter-box" onClick={handleOnClick}>
      <TextField
        autoFocus={autoFocus}
        inputRef={textInput}
        disabled={disabled}
        id="standard-basic"
        fullWidth
        size="small"
        placeholder={placeholder}
        defaultValue={value}
        data-id={props.dataId}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" color="secondary">
              <SearchIcon color="secondary" />
            </InputAdornment>
          ),
          endAdornment: (textInput.current?.value || props.showCloseIcon) && (
            <InputAdornment position="end" onClick={handleOnCancel} style={{ cursor: 'pointer' }}>
              <CloseIcon color="secondary" />
            </InputAdornment>
          ),
        }}
        onChange={onChangeText}
        color="secondary"
        autoComplete="off"
        variant={variant === 'dark' ? 'standard' : 'outlined'}
        className={`filter-box-input-${variant}`}
      />
    </div>
  );
});

FilterBox.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  value: PropTypes.string,
  showCloseIcon: PropTypes.bool,
  variant: PropTypes.string,
  dataId: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onClick: PropTypes.func,
};

FilterBox.defaultProps = {
  placeholder: i18n.t('Search'),
  variant: 'dark',
  autoFocus: false,
};

export default React.memo(FilterBox);
