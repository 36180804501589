import React, { forwardRef, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import { DefaultResourceAccept, UploadingFileState } from '../../app/appConstants';
import DndFolderUpload from '../../components/DndFolderUploader';
import { createDirectory, createRuntimeDirectory } from '../../resources/ResourceServices';
import { buildFolderStructure } from '../../components/DndFolderUploader/utils';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import { sendNotification } from '../../commons/utils';
import { useAppContext } from '../../components/AppProvider';
import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';
import OfficialButton from '../../components/OfficialButtons';

const UploadFolderForm = forwardRef((props, ref) => {
  const uploaderRef = useRef();
  const {
    isGlobalResourcesEnabled,
    spacePrivacy,
    isPortal,
    isAdmin,
    isPartner,
    spaceId,
    parentFolderId,
    onSave,
  } = props;
  const { isAuthenticated } = useAppContext();
  const [isUploading, setIsUploading] = useState(false);
  const [folders, setFolders] = useState({});
  const [hasChangesForm, setHasChangesForm] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleOnSave = async () => {
    // if (!folders || Object.keys(folders).length === 0) {
    //   return;
    // }
    setProcessing(true);

    const payload = {
      ...folders,
      parentFolderId,
    };

    // stringify filesAndFolders property if upload from local machine
    if (!folders.globalFolderId && folders.filesAndFolders) {
      payload.filesAndFolders = JSON.stringify(folders.filesAndFolders);
    }

    if (folders.globalFolderId) {
      payload.externalFolder = {
        provider: SERVICE_PROVIDER.GLOBAL_RESOURCES,
      };
    } else if (folders.filesAndFolders) {
      payload.externalFolder = {
        provider: SERVICE_PROVIDER.LOCAL_MACHINE,
      };
    }

    try {
      if (isPortal) {
        await createDirectory(spaceId, payload);
      } else {
        await createRuntimeDirectory(spaceId, payload);
      }
      setHasChangesForm(false);

      uploaderRef?.current?.resetSessionIds();

      sendNotification(i18n.t('Nice! Your folder has been added!'), { type: 'success' });
      if (onSave) {
        setTimeout(() => {
          onSave();
        }, 200);
      }
    } catch (error) {
      setProcessing(false);
      sendNotification(error.message, { type: 'error' });
    }
  };

  const handleResourceChange = useCallback(async (values) => {
    if (values?.length === 0) {
      setHasChangesForm(false);
      setFolders({});
      return;
    }
    setHasChangesForm(true);
    const data = buildFolderStructure(values);
    setFolders(data);
  }, []);

  function handleUploadingStateChange(newState) {
    let uploading = false;
    if (newState <= UploadingFileState.Started) {
      uploading = true;
    }
    setIsUploading(uploading);
  }

  async function handleCloseForm() {
    let isCloseForm = true;
    if (hasChangesForm) {
      const { isSaved, discard } = await UnsavedChangesDialog.show(
        i18n.t('UNSAVED CHANGES'),
        <>
          {i18n.t(`You have made changes.`)}
          <br />
          {i18n.t(`Would you like to save?`)}
        </>
      );
      isCloseForm = discard;
      if (isSaved) {
        const result = await handleOnSave();
        if (result) {
          isCloseForm = true;
        }
      }
    }
    return isCloseForm;
  }

  const handleSelectFolder = async (folder) => {
    if (!folder) {
      return;
    }
    const folderData = {};
    if (folder.id) {
      folderData.globalFolderId = folder.id;
    }
    if (folder.name) {
      folderData.name = folder.name;
    }
    if (folder.driveId) {
      folderData.externalFolder = folderData.externalFolder || {};
      folderData.externalFolder.driveId = folder.driveId;
    }
    if (folder.provider) {
      folderData.externalFolder = folderData.externalFolder || {};
      folderData.externalFolder.provider = folder.provider;
      if (folder.id) folderData.externalFolder.folderId = folder.id;
      delete folderData.globalFolderId;
    }
    if (folder.sharedDriveId) {
      folderData.externalFolder = folderData.externalFolder || {};
      folderData.externalFolder.sharedDriveId = folder.sharedDriveId;
    }
    // console.log('### 25 handleSelectFolder:', folder, folderData);
    setFolders(folderData);
  };

  React.useImperativeHandle(ref, () => ({
    handleCloseForm,
  }));

  return (
    <>
      <section className="styled-scrollbar">
        <section className="middle-section displayFlex column-direction gap16">
          <div className="uploader-area">
            <DndFolderUpload
              ref={uploaderRef}
              isGlobalResourcesEnabled={isGlobalResourcesEnabled && isAuthenticated && !isPartner}
              accept={DefaultResourceAccept}
              multiple={false}
              spacePrivacy={spacePrivacy}
              isPortal={isPortal}
              isAdmin={isAdmin}
              spaceId={spaceId}
              onChangeResources={handleResourceChange}
              onSelectFolder={handleSelectFolder}
              onUploadingStateChange={handleUploadingStateChange}
            />
          </div>
        </section>
      </section>
      <div className="buttons">
        <OfficialButton
          label={i18n.t('add')}
          fullWidth
          variant="regular-green"
          onClick={handleOnSave}
          isProcessing={processing}
          disabled={isUploading}
        />
      </div>
    </>
  );
});
UploadFolderForm.propTypes = {
  isGlobalResourcesEnabled: PropTypes.bool,
  spacePrivacy: PropTypes.string,
  isPortal: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isPartner: PropTypes.bool,
  // multiple: PropTypes.bool,
  spaceId: PropTypes.string,
  parentFolderId: PropTypes.string,
  onSave: PropTypes.func,
};

export default UploadFolderForm;
