import React, { PureComponent } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/system';
import { QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import { getTheme } from '../../theme';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import './SpacesAffectedDialog.scss';
// import ApplyChangesTable from '../components/ApplyChangesTable';
import queryCache from '../../app/queryCache';
import ApplyChangesTable from '../components/ApplyChangesTable';

let resolve;
let containerElement;
let rootEl;

class SpacesAffectedDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      rowCount: 0,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleUpdateRowCount = this.handleUpdateRowCount.bind(this);
  }

  componentWillUnmount() {
    resolve = null;
    containerElement = null;
  }

  static destroy(res = { isConfirmed: false }) {
    if (containerElement) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 0);
    }
    if (resolve) resolve(res);
  }

  static show(data) {
    const { logId, templateId, templateName, affectedSpacesCount } = data;
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);

    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <QueryClientProvider client={queryCache}>
          <SpacesAffectedDialog
            logId={logId}
            spaceTemplateId={templateId}
            spaceTemplateName={templateName}
            affectedSpacesCount={affectedSpacesCount}
          />
        </QueryClientProvider>
      </ThemeProvider>
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose(res = { isConfirmed: false }) {
    this.setState({ isOpen: false }, () => {
      SpacesAffectedDialog.destroy(res);
    });
  }

  handleUpdateRowCount(count) {
    this.setState({ rowCount: count });
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }
    const spaceTemplateId = this.props.spaceTemplateId;
    const logId = this.props.logId;
    return (
      <ResponsiveDialog
        modal
        maxWidth="lg"
        className="white spaces-affected-dialog"
        onClose={this.handleClose}
      >
        <div className="dialog-header">
          <span className="title">
            {this.state.rowCount} {i18n.t('Spaces')}
          </span>
          <span className="description">
            {i18n.t('Affected by updates in the template ')}
            {this.props.spaceTemplateName}
          </span>
        </div>
        <div className="dialog-body">
          <section className="table-section">
            <ApplyChangesTable
              spaceTemplateId={spaceTemplateId}
              logId={logId}
              handleUpdateRowCount={this.handleUpdateRowCount}
              showVisitSpace
            />
          </section>
        </div>
      </ResponsiveDialog>
    );
  }
}

SpacesAffectedDialog.propTypes = {
  spaceTemplateId: PropTypes.string,
  spaceTemplateName: PropTypes.string,
  logId: PropTypes.number,
  // affectedSpacesCount: PropTypes.number,
};

export default SpacesAffectedDialog;
