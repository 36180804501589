import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import LoadingMessage from '../LoadingMessage';
import { useMaterialsContext } from '../../spaces/Materials/MaterialsContextProvider';
import i18n from '../../i18n';
import {
  getFilteredDirectory,
  getFolderPathName,
  getMaterialsByDirectory,
} from '../../commons/ResourceUtils';
import { useMultipleSelectResourcesContext } from './MultipleSelectResourcesContextProvider';
import useExtraDirectories from '../../commons/useExtraDirectories';
import { DirectoryType } from '../../app/appConstants';
import ErrorMessage from '../ErrorMessage';
import MaterialsBreadcrumbs from './MaterialsBreadcrumbs';
import './MaterialsMoveDialog.scss';
import { sendNotification } from '../../commons/utils';
import { bulkMoveDirectory } from '../../resources/ResourceServices';
import MaterialNewFolderDrawer from './MaterialNewFolderDrawer';
import { useAppContext } from '../AppProvider';
import OfficialButton from '../OfficialButtons';
import MovingTable from './MovingTable/MovingTable';
import spaceUser from '../../spaces/spaceUser';
import FilterBox from '../FilterBox';
import { getAvailableItems, getFilterListByText, getSubFoldersId } from './function';

const getRootFolder = memoizeOne((directories) => {
  let foundRootDirectory = null;
  foundRootDirectory = directories.find(
    (directory) => directory.directoryType === DirectoryType.Extra
  );

  return foundRootDirectory;
});

const getRootFolderPathInfo = memoizeOne((directories) => {
  const foundRootDirectory = getRootFolder(directories);
  let pathInfo = null;

  if (foundRootDirectory) {
    // root directory info for shared files
    pathInfo = {
      id: foundRootDirectory.id,
      name: foundRootDirectory.name,
      parentId: null,
      path: foundRootDirectory.id,
      pathName: 'Resources',
    };
  }

  return pathInfo;
});

const MaterialsMoveDialog = (props) => {
  const [selectedDirectory, setSelectedDirectory] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [breadcrumbData, setBreadcrumbData] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [openNewFolderDrawer, setOpenNewFolderDrawer] = useState(false);
  const { isPortal } = useAppContext();
  const { getMaterialsQuery, directories, flatDirectories, currentUser } = useMaterialsContext();

  const { rootShareDirectory } = useExtraDirectories(flatDirectories, props.spaceId);

  const rootDirectoryPathInfo = useRef(getRootFolderPathInfo(flatDirectories, 0));

  const {
    selectedFileIds,
    selectedFolderIds,
    currentDirectoryId, // parentFolderId, the parent of selected/moving items
    resetSelected,
  } = useMultipleSelectResourcesContext();

  const updateNavigation = useCallback((directory) => {
    const getPath = () => {
      if (directory.directoryType === DirectoryType.Extra) {
        return `${directory.id}`;
      }
      const paths = directory.paths?.join('/') || '';
      return `${rootDirectoryPathInfo.current.path}/${paths ? `${paths}/` : ''}${directory.id}`;
    };

    const getPathName = () => {
      if (directory.path === null && directory.parentFolderId === null) {
        return `${rootDirectoryPathInfo.current.pathName}/${directory.name}`;
      }
      const pathName = getFolderPathName(directory);
      return `${rootDirectoryPathInfo.current.pathName}/${pathName ? `${pathName}/` : ''}${directory.name}`;
    };

    if (!directory) {
      setSelectedDirectory(null);
      setBreadcrumbData(null);
      return;
    }

    const navData = {
      id: directory.id,
      name: directory.name,
      parentId: directory.parentFolderId,
      path: getPath(),
      pathName: getPathName(),
    };

    setSelectedDirectory(directory);
    setBreadcrumbData(navData);
  }, []);

  useEffect(() => {
    const parentFolder = flatDirectories.find((x) => x.id === selectedDirectory?.id);
    if (parentFolder) {
      setSelectedDirectory(parentFolder);
    }
  }, [flatDirectories, selectedDirectory]);
  useEffect(() => {
    if (currentDirectoryId > 0) {
      // the parent of moving items
      const parentFolder = flatDirectories.find((x) => x.id === currentDirectoryId); // can be a normal folder or root folder
      console.log('### useEffect 2:', parentFolder);
      if (parentFolder && parentFolder.directoryType !== DirectoryType.Extra) {
        updateNavigation(parentFolder);
      }
      // Extra folders will be rendered as scenario 2
    }
    // disable eslint dependencies (flatDirectories and updateNavigation) because flatDirectories will be changed after added a new folder.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDirectoryId]);

  async function handleMoveButtonClick() {
    try {
      let toFolderId = selectedDirectory?.id;
      if (!toFolderId) {
        const root = getRootFolder(flatDirectories, 0);
        toFolderId = root.id;
      }
      const data = {
        directoryIds: props.selectedFolderIds,
        materialIds: props.selectedFileIds,
        toFolderId: toFolderId,
      };
      setProcessing(true);
      if (props.isVideoMessageSharing && props.onVideoMessageShared) {
        props.onVideoMessageShared(toFolderId, 0);
        props.onClose();
        return;
      }
      const response = await bulkMoveDirectory(props.spaceId, data, isPortal);
      // console.log('### bulkMoveDirectory response', response);

      const noF1 = response?.directoryResult?.failedItems?.length || 0;
      const noF2 = response?.materialResult?.failedItems?.length || 0;

      if (noF1 === 0 && noF2 === 0) {
        const no1 = response?.directoryResult?.successfulItems?.length || 0;
        const no2 = response?.directoryResult?.subSuccessfulItems?.length || 0;
        const no3 = response?.materialResult?.successfulItems?.length || 0;
        const no4 = response?.materialResult?.subSuccessfulItems?.length || 0;
        const noMovedItems = no1 + no2 + no3 + no4;
        const msgSuccess =
          noMovedItems > 1 ? i18n.t('Items moved successfully') : i18n.t('Item moved successfully');

        sendNotification(msgSuccess, { type: 'success' });
        getMaterialsQuery.refetch();
        resetSelected();
      } else {
        sendNotification(
          'Uh-oh! Something went wrong. Please refresh your browser and give it another try.',
          { type: 'error' }
        );
      }

      setProcessing(false);
      props.onClose();
    } catch (err) {
      console.log('handleMoveButtonClick error', err);
      sendNotification(
        'Uh-oh! Something went wrong. Please refresh your browser and give it another try.',
        { type: 'error' }
      );
    }
  }

  const handleDirectoryClick = (directory) => {
    updateNavigation(directory);
  };

  const handleChangePath = (directoryId) => {
    if (directoryId === 'null') {
      updateNavigation(null);
    } else {
      const foundDirectory = flatDirectories.find((directory) => directory.id === +directoryId);
      if (foundDirectory && foundDirectory.directoryType !== DirectoryType.Extra) {
        updateNavigation(foundDirectory);
      } else {
        updateNavigation(null); // to render as scenario 2, for root Extra folders
      }
    }
  };

  const handleOpenNewFolderDrawer = () => {
    setOpenNewFolderDrawer(true);
  };

  const handleCloseNewFolderDrawer = (addedFolder) => {
    console.log('### handleCloseNewFolderDrawer newly added folder:', addedFolder);
    setOpenNewFolderDrawer(false);
  };

  const handleOnChangeText = (value) => {
    setSearchText(value);
  };

  const getCombinedItems = (resources) => {
    const subFolderIds = getSubFoldersId(flatDirectories, selectedFolderIds);
    return resources.map((x) => {
      const isNotFolder = x.spaceResources === undefined;
      // const isSameFolder = x.id === parentFolderId;
      const isSelf = !isNotFolder && selectedFolderIds?.includes(x.id);
      const isSubFolder = subFolderIds.includes(x.id);
      let tooltip;
      if (isSelf) {
        tooltip = i18n.t('Cannot move the resource into itself');
      } else if (isSubFolder) {
        tooltip = i18n.t('Cannot move the resource into its descendant');
      }
      return {
        ...x,
        name: x.resourceName || x.displayName || (x.name !== '' ? x.name : 'Video Message'),
        noItems: x.spaceResources?.length,
        isDisabled: x.isExternal || isNotFolder || isSelf || isSubFolder,
        tooltip,
      };
    });
  };

  const getResourcesByDirectory = (directory, parentFolderId) => {
    if (!directory) return [];
    const subFolders = directory.subFolders || [];
    const materials = getMaterialsByDirectory(directory);
    const combinedItems = getCombinedItems([...subFolders, ...materials], parentFolderId);
    return combinedItems;
  };

  const getFilterList = (directory) => {
    const { internalsData } = getAvailableItems(flatDirectories, directory);

    const filteredData = getFilterListByText(internalsData, searchText);
    const combinedItems = getCombinedItems(filteredData, currentDirectoryId);

    return combinedItems;
  };

  const renderDirectory = (directory) => {
    let data = getResourcesByDirectory(directory, currentDirectoryId);
    if (searchText?.length > 0) {
      const filteredData = getFilterList(directory);

      if (filteredData.length === 0) {
        return (
          <ErrorMessage
            message={
              <>
                {i18n.t('No folders found.')}
                <br />
                <span className="text-gray">{i18n.t('Choose another keyword and try again.')}</span>
              </>
            }
          />
        );
      } else {
        data = filteredData;
      }
    }
    return (
      <MovingTable
        data={data}
        handleFolderClick={handleDirectoryClick}
        parentFolderId={currentDirectoryId}
        isHost={spaceUser.isHost()}
        variant="white"
        selectedFolderIds={selectedFolderIds}
      />
    );
  };

  const renderHeader = () => {
    const itemsToMoveCount = (selectedFileIds?.length ?? 0) + (selectedFolderIds?.length ?? 0);

    return (
      <div className="materials-header">
        <div className="materials-header-left-section">
          <div className="materials-header-title">
            {props.isVideoMessageSharing
              ? `Save video message to...`
              : `Move ${itemsToMoveCount} item to...`}
          </div>
          {/* <div className="materials-header-info">Resources</div> */}
          <MaterialsBreadcrumbs data={breadcrumbData || null} onClick={handleChangePath} />
        </div>
        <div className="materials-header-right-section">
          <OfficialButton
            icon={<AddIcon />}
            label={i18n.t('Add')}
            onClick={handleOpenNewFolderDrawer}
            variant="rectangle-green"
            className="add-button"
          />

          <IconButton className="close-button" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (getMaterialsQuery.isLoading) {
      return (
        <div className="loading-container">
          <LoadingMessage>{i18n.t('Loading...')}</LoadingMessage>
        </div>
      );
    }

    const rootFolder = rootShareDirectory;

    const mainFolders = getFilteredDirectory(directories);

    if (rootFolder) {
      rootFolder.subFolders = mainFolders;
    }

    const folder = selectedDirectory || rootFolder;

    if (!folder) {
      return null;
    }

    if (folder.subFolders?.length === 0 && folder?.spaceResources?.length === 0) {
      return <ErrorMessage message={i18n.t('No materials available.')} />;
    }

    if (folder) {
      return (
        <>
          <FilterBox
            variant="light"
            placeholder={i18n.t('Search for a folder')}
            value={searchText}
            onChange={handleOnChangeText}
            onCancel={() => setSearchText('')}
          />
          {renderDirectory(folder)}
        </>
      );
    }

    return null;
  };

  // console.log(
  //   'RE-RENDER currentDirectoryId=%i, selectedDirectory?.id=%i',
  //   currentDirectoryIdRef.current,
  //   selectedDirectory?.id,
  //   selectedDirectory
  // );

  return (
    <Dialog open className="materials-move-dialog">
      <DialogTitle>{renderHeader()}</DialogTitle>
      <DialogContent className="styled-scrollbar">{renderContent()}</DialogContent>
      <DialogActions>
        <OfficialButton
          disabled={
            (currentDirectoryId === selectedDirectory?.id && !props.isVideoMessageSharing) ||
            selectedFolderIds?.includes(selectedDirectory?.id)
          }
          onClick={handleMoveButtonClick}
          isProcessing={processing}
          label={props.isVideoMessageSharing ? i18n.t('Save Here') : i18n.t('Move Here')}
          variant="large-primary"
        />
      </DialogActions>

      {openNewFolderDrawer && (
        <MaterialNewFolderDrawer
          spaceId={props.spaceId}
          currentFolder={selectedDirectory}
          currentUser={currentUser}
          onClose={handleCloseNewFolderDrawer}
        />
      )}
    </Dialog>
  );
};

MaterialsMoveDialog.propTypes = {
  spaceId: PropTypes.string,
  onClose: PropTypes.func,
  selectedFileIds: PropTypes.arrayOf(PropTypes.number),
  selectedFolderIds: PropTypes.arrayOf(PropTypes.number),
  isVideoMessageSharing: PropTypes.bool,
  onVideoMessageShared: PropTypes.func,
};

export default MaterialsMoveDialog;
