// AppInsights.js
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { getAppInsightsKey } from '../commons/utils';

export const LogLevels = {
  INFO: SeverityLevel.Information,
  WARN: SeverityLevel.Warning,
  ERROR: SeverityLevel.Error,
  CRITICAL: SeverityLevel.Critical,
};

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getAppInsightsKey(),
    enableAutoRouteTracking: true,
    disableAjaxTracking: true,
    disableFetchTracking: true,
    excludeRequestFromAutoTrackingPatterns: ['/api/insights', '/interactions'],
    extensions: [reactPlugin],
    // extensionConfig: {
    //   [reactPlugin.identifier]: { history: browserHistory },
    // },
    disableCookiesUsage: true,
  },
});
if (process.env.REACT_APP_ENV !== 'local') {
  appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };
