import React from 'react';
import { createRoot } from 'react-dom/client';

import { ThemeProvider } from '@mui/system';
import { IResource, SelectedFolderType } from './context/Interfaces';
import UploadFolderDialog from './UploadFolderFormDialog';
import { defaultTheme } from '../theme';

let containerElement: Element | null = null;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let rootEl: any = null;

function useUploadGlobalFolder() {
  let resolver: (resources: Array<IResource>) => void;

  React.useEffect(() => {
    return () => {
      if (containerElement && rootEl) {
        rootEl?.unmount();
        containerElement = null;
      }
    };
  }, []);

  function closeUploadFolderDialog(newResources: Array<IResource>) {
    if (containerElement && rootEl) {
      rootEl?.unmount();
      containerElement = null;
      if (resolver) {
        resolver(newResources);
      }
    }
  }

  async function showUploadFolderDialog(
    selectedFolder: SelectedFolderType | undefined
  ): Promise<Array<IResource>> {
    const promise = new Promise<Array<IResource>>((resolve) => {
      resolver = resolve;
      if (!containerElement) {
        containerElement = document.createElement('div');
        rootEl = createRoot(containerElement);
        rootEl.render(
          <ThemeProvider theme={defaultTheme}>
            <UploadFolderDialog
              onClose={closeUploadFolderDialog}
              selectedFolderId={selectedFolder?.id}
            />
          </ThemeProvider>
        );
      }
    });
    return promise;
  }

  return { showUploadFolderDialog };
}

export default useUploadGlobalFolder;
