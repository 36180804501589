import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n';

import { requestForCustomSubscription } from '../settings/SettingsServices';
import { sendNotification } from '../commons/utils';
import { SubscriptionType } from '../commons/Subscription';
import ConfirmService from './ConfirmService';
import OfficialButton from './OfficialButtons';

function SubscriptionCheckoutButton(props) {
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);

  const handleOnClickCheckout = async () => {
    if (isSaving) {
      return;
    }

    let subject = '';
    if (props.subscriptionRequire === SubscriptionType.enterprise) {
      subject = 'Request for enterprise';
    }
    if (props.subscriptionRequire === SubscriptionType.premium) {
      subject = 'Request for premium';
    }

    if (props.withConfirmation) {
      const resp = await ConfirmService.show(subject, i18n.t('Are you sure you want to proceed?'));
      if (!resp) {
        return;
      }
    }

    try {
      setIsSaving(true);
      await requestForCustomSubscription(subject);
      setIsSent(true);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      sendNotification(i18n.t('Sorry, something went wrong. Please try again!'), { type: 'error' });
    }
  };

  if (props.component) {
    return (
      <div onClick={handleOnClickCheckout}>
        {isSaving && <p style={{ textAlign: 'center', margin: 7 }}>{i18n.t('Processing...')}</p>}
        {!isSaving && props.component}
      </div>
    );
  }

  function handleOnClickRequestUpgrade() {
    let subject = '';
    if (props.subscriptionRequire === SubscriptionType.enterprise) {
      subject = 'Request for enterprise';
    }
    if (props.subscriptionRequire === SubscriptionType.premium) {
      subject = 'Request for premium';
    }
    subject = encodeURIComponent(`${subject}`);
    const body = 'Hi. I would like to be contacted regarding upgrading my subscription.';
    const link = `${'mailto:sales@spce.com?cc=&subject='}${subject}&body=${body}`;
    window.location.href = link;
  }

  function renderText() {
    if (isSent) {
      return <p>{i18n.t('We will get back to you shortly')}</p>;
    } else {
      if (props.subscriptionRequire === SubscriptionType.super) {
        return null;
      }
      return <p>{i18n.t('')}</p>;
    }
  }

  return (
    <div className="button-container">
      <OfficialButton
        label={i18n.t('Talk to sales')}
        className={`${props.className}`}
        onClick={handleOnClickRequestUpgrade}
        variant="large-primary"
        isProcessing={isSaving}
        disabled={isSent}
      />
      {renderText()}
    </div>
  );
}

SubscriptionCheckoutButton.propTypes = {
  subscriptionRequire: PropTypes.number,
  component: PropTypes.instanceOf(Object),
  withConfirmation: PropTypes.bool,
  className: PropTypes.string,
};

export default SubscriptionCheckoutButton;
