import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUrlParameter } from '../commons/utils';
import useMountEffect from '../commons/useMountEffect';

const Redirectors = () => {
  const returnUrl = getUrlParameter('returnUrl');

  const shouldUseLocationHref = returnUrl && returnUrl.startsWith('http');

  useMountEffect(() => {
    if (shouldUseLocationHref) {
      location.href = returnUrl;
    }
  });

  if (returnUrl.startsWith('/s/')) {
    location.href = location.origin + returnUrl;
  }

  return <Navigate to={returnUrl} />;
};

export default Redirectors;
