import React, { PureComponent, createRef } from 'react';
import { createRoot } from 'react-dom/client';

import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import i18n from '../../i18n';
import OfficialButton from '../OfficialButtons';
import { getTheme } from '../../theme';
import './AddPartnerUsersDialog.scss';
import UserCheckboxList from '../UserCheckboxList';
import { inviteOrganizationUsers } from '../../settings/SettingsServices';
import { OrganizationUserRole } from '../../app/appConstants';

let resolve;
let containerElement;
let rootEl;

class AddPartnerUsersDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      users: props.users,
      selectedUsers: props.users,
      isSavingData: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.dialogRef = createRef();
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleHistoryStateChanged);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleHistoryStateChanged);
  }

  static destroy(data) {
    if (rootEl) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 100);
    }
    if (resolve) {
      resolve(data);
      resolve = null;
    }
  }

  static show({ emailDefault, users }) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    document.body.appendChild(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <AddPartnerUsersDialog emailDefault={emailDefault} users={users} />
      </ThemeProvider>
    );

    return new Promise((res) => {
      resolve = res;
    });
  }

  handleCancel = () => {
    this.props.onClose();
    this.setState({ isOpen: false }, () => AddPartnerUsersDialog.destroy());
  };

  handleUserChange(users) {
    this.setState({ selectedUsers: users });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { selectedUsers } = this.state;
    console.log('selectedUsers', selectedUsers);

    if (selectedUsers.length === 0) {
      return;
    }

    const data = {
      users: selectedUsers.map((user) => ({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        role: OrganizationUserRole.Partner,
        shouldSendInvitation: true,
      })),
    };

    this.setState({ isSavingData: true });
    await inviteOrganizationUsers(data);
    this.setState({ isSavingData: false });
    this.handleCancel();
  }

  render() {
    const { hidePopup } = this.state;

    if (!this.state.isOpen) {
      return null;
    }

    return (
      <div
        className={`white add-partner-users-dialog ${hidePopup ? 'hidden-popup' : ''}`}
        id="add-partner-users-dialog"
      >
        <form onSubmit={this.handleSubmit} ref={this.dialogRef} noValidate>
          <div className="mobile-join-space-box">
            <div className="dialog-content" ref={this.dialogRef}>
              <div className="message">
                <div className="title">{i18n.t(`Add as a partner user`)}</div>
              </div>
              <div className="form-content">
                <div className="form fullwidth">
                  <span className="description">
                    {i18n.t(
                      'The selected external user(s) already have access to this space. Would you like to upgrade them to partner user(s) for full access of the materials? '
                    )}
                  </span>
                </div>
                <div className="form fullwidth user-select-autocomplete-popper">
                  <UserCheckboxList
                    users={this.state.users}
                    selectedUsers={this.state.selectedUsers}
                    onChange={this.handleUserChange}
                  />
                </div>
              </div>
              <div className="button-wrapper" style={{ marginTop: 20 }}>
                <OfficialButton
                  variant="large-secondary"
                  label={i18n.t('Skip For Now')}
                  onClick={this.handleCancel}
                />
                <OfficialButton
                  variant="large-primary"
                  type="submit"
                  label={i18n.t('Add')}
                  isProcessing={this.state.isSavingData}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

AddPartnerUsersDialog.propTypes = {
  users: PropTypes.instanceOf(Array),
  onClose: PropTypes.func,
};

export default AddPartnerUsersDialog;
