import React, { useState, createContext, useEffect } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { useAppContext } from '../components/AppProvider';

const getSettingsContext = memoizeOne((open, isMobile) => {
  return {
    open,
    isMobile,
  };
});

export const SettingsContext = createContext();

export const useSettingsContext = () => React.useContext(SettingsContext);

function SettingsProvider(props) {
  const { children } = props;
  const { isMobilePortrait: isMobile } = useAppContext();

  const [open, setOpen] = useState();

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const context = getSettingsContext(open, isMobile);

  context.handleDrawerToggle = handleDrawerToggle;

  return <SettingsContext.Provider value={context}>{children}</SettingsContext.Provider>;
}

SettingsProvider.propTypes = {
  children: PropTypes.element,
};

export default SettingsProvider;
