import React from 'react';
import { RowData, ResourcesViewProps } from '../context/Interfaces';
import './styles.scss';
import TablePagination from '../TablePagination';
import ScrollingList from '../../components/ScrollingList';
import ResourceItem from '../ResourcesDisplayView/ResourceItem';
import { ViewModeType } from '../../resources/resourceConstants';

function ResourcesGridView(props: ResourcesViewProps) {
  const {
    resources,
    className,
    onClickMore,
    selectType,
    multiple,
    // page,
    // rowsPerPage,
    rowCount,
    // handleChangePage,
    // handleChangeRowsPerPage,
    // order,
    // orderBy,
    // handleRequestSort,
    onClickItem,
    onDoubleClickItem,
    // handleOnDoubleClickRow,
    processingResources,
    onLoadMore,
    hasMore,
    isLoading,
    onClickCheckbox,
    isPortal,
  } = props;

  function renderResource(rowData: RowData, index: number | undefined) {
    return (
      <ResourceItem
        viewMode={ViewModeType.GRID}
        key={rowData.id ?? rowData.resourceId}
        rowData={rowData}
        index={index}
        isSelected={rowData.isSelected ?? false}
        onClickItem={onClickItem}
        onDoubleClickItem={onDoubleClickItem}
        processingResources={processingResources}
        multiple={multiple}
        selectType={selectType}
        onClickMore={onClickMore}
        onClickCheckbox={onClickCheckbox}
        isPortal={isPortal}
        driveId={rowData.driveId}
      />
    );
  }

  function renderGrid() {
    return resources.map((resource: RowData) => renderResource(resource, resource?.index));
  }

  if (onLoadMore) {
    return (
      <div className="grid-view-container">
        <ScrollingList
          className={`resource-grid-view-container styled-scrollbar ${className || ''}`}
          onScrollToBottom={hasMore && !isLoading ? onLoadMore : null}
        >
          {renderGrid()}
        </ScrollingList>
      </div>
    );
  }

  return (
    <div className="grid-view-container">
      <div className={`resource-grid-view-container styled-scrollbar ${className}`}>
        {renderGrid()}
      </div>
      <TablePagination info={`${rowCount} results`} rowCount={rowCount} />
    </div>
  );
}

export default React.memo(ResourcesGridView);
