import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { useQuery } from '@tanstack/react-query';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InfoIcon from '@mui/icons-material/Info';
import { Checkbox, Divider, FormControlLabel, Typography, Alert, Radio } from '@mui/material';
import { CacheKeys } from '../app/queryCache';
import { fetchSpaceUsers } from '../spaces/SpaceUsers/spaceUsersServices';
import spaceUser from '../spaces/spaceUser';
import VaamParticipantSelect from './VaamParticipantSelect';
import './VaamParticipantTabPanel.scss';
import Loading from '../components/Loading';
import ShareVaamInResourcePanel from './ShareVaamInResourcePanel';
import currentOrganization from '../commons/CurrentOrganization';

export const SharingTypes = {
  PRIVATE: 0,
  PUBLIC: 1,
  RESOURCE_PANEL: 2,
};

const getSharingLabel = memoizeOne((type) => {
  switch (type) {
    case SharingTypes.PRIVATE:
      return 'Private Chat';
    case SharingTypes.PUBLIC:
      return 'Public Chat';
    case SharingTypes.RESOURCE_PANEL:
      return 'Resource Panel';
    default:
      return '';
  }
});

const getParticipants = memoizeOne((items, userInfoId, isPublicSpace, enabled = false) => {
  const participants = items
    .filter((item) => item.userInfoId !== userInfoId && !(item.isExternal && isPublicSpace))
    .map((p) => {
      return { ...p, id: p.userInfoId, enabled };
    });
  return participants;
});

const getParticipantsUserDetails = memoizeOne((items) => {
  const userInfoDetails = [];
  items.forEach((item) => {
    if (item.enabled) {
      const userDetails = {
        userInfoId: item.userInfoId,
        email: item.email,
        fullName: item.fullName,
      };

      userInfoDetails.push(userDetails);
    }
  });

  return userInfoDetails;
});

const VaamParticipantTabPanel = (props) => {
  const [tab, setTab] = useState(0);
  const { spaceId, onSelectParticipants, channel, isOnError, enabledChat, isPublicSpace } = props;
  const [participants, setParticipants] = useState([]);
  const code = spaceUser.getInvc();
  const currentUserId = spaceUser?.getUserInfoId();

  const getSpaceUsersQuery = useQuery({
    queryKey: [CacheKeys.fetchSpaceUsers, spaceId, code],
    queryFn: async () => {
      if (!code) {
        return null;
      }
      const resp = await fetchSpaceUsers(spaceId);
      return resp;
    },
    retry: 1,
    retryDelay: () => 5000,
    cacheTime: 0, // temporary remove cached to avoid cached participants, will refactor later
    enabled: enabledChat,
  });

  // TODO: Refactor so that we don't need to fetch and filter all space users for private message (P2P)
  useEffect(() => {
    let data = getSpaceUsersQuery.data;

    if (!data) return;

    props.getParticipantsCount(data.length - 1);

    if (channel?.participants === null || channel?.participants.length === 0) {
      data = getParticipants(data, currentUserId, isPublicSpace);
    } else {
      data = data?.filter(
        (participant) => participant?.userInfoId === channel?.participants[1]?.userInfoId
      );
      data = getParticipants(data, currentUserId, isPublicSpace, true);
    }
    setParticipants(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSpaceUsersQuery.data, channel?.participants]);

  function handleChangeTab(event, value) {
    setTab(value);
    if (props.onChangeSharingType) {
      props.onChangeSharingType(value);
    }
  }

  function handleOnSelectParticipants(items) {
    setParticipants(items);
    onSelectParticipants(getParticipantsUserDetails(items));
  }

  function handleOnSharingSuccess(items) {
    if (props.handleSharingClick) {
      props.handleSharingClick(items);
    }
  }

  function handleChangeNotifyByEmail(event, checked) {
    if (enabledChat && (tab === SharingTypes.PUBLIC || tab === SharingTypes.RESOURCE_PANEL)) {
      const enabledParticipants = participants.map((x) => {
        return { ...x, enabled: checked };
      });
      handleOnSelectParticipants(enabledParticipants);
    }
    if (props.onChangeNotifyByEmail) props.onChangeNotifyByEmail(checked);
  }
  function renderBody() {
    if (!enabledChat || tab === SharingTypes.RESOURCE_PANEL) {
      return (
        <ShareVaamInResourcePanel
          spaceId={spaceId}
          organizationId={currentOrganization.organizationId}
          isPublicSpace={isPublicSpace}
          onShareSuccess={handleOnSharingSuccess}
        />
      );
    }

    if (tab === SharingTypes.PUBLIC) {
      return (
        <Alert icon={<InfoIcon />} severity="info" className="information-alert">
          Your video message will be visible to all users in the space.
        </Alert>
      );
    }

    return (
      <VaamParticipantSelect
        participants={participants}
        spaceId={props.spaceId}
        onSelectParticipants={handleOnSelectParticipants}
        isOnError={isOnError}
      />
    );
  }

  function renderTabLabel(value) {
    const label = getSharingLabel(value, enabledChat);
    if (!enabledChat) {
      return <Typography style={{ fontWeight: 'bold' }}>{label}</Typography>;
    }
    return (
      <FormControlLabel
        value={value}
        control={<Radio value={value} checked={tab === value} color="primary" />}
        label={label}
      />
    );
  }

  if (getSpaceUsersQuery.isLoading) {
    return (
      <div className="loading-box">
        <Loading />
      </div>
    );
  }
  console.log('enabledChat: ', enabledChat);

  return (
    <div className="vaam-participant-tab">
      <div className="vaam-participant-tab-header">
        {enabledChat && (
          <Tabs value={tab} onChange={handleChangeTab} aria-label="simple tabs" className="tabs">
            <Tab
              value={SharingTypes.PRIVATE}
              label={renderTabLabel(SharingTypes.PRIVATE)}
              disableRipple
            />
            )
            <Tab
              value={SharingTypes.PUBLIC}
              label={renderTabLabel(SharingTypes.PUBLIC)}
              disableRipple
            />
            <Tab
              value={SharingTypes.RESOURCE_PANEL}
              label={renderTabLabel(SharingTypes.RESOURCE_PANEL)}
              disableRipple
            />
          </Tabs>
        )}
        {!enabledChat && (
          <div
            style={{
              paddingLeft: '24px',
              paddingRight: '24px',
            }}
          >
            <div>
              <span>Save the video message as a resource in the space.</span>
            </div>
          </div>
        )}
      </div>
      <div className="vaam-participant-tab-body">{renderBody()}</div>
      {enabledChat && (
        <>
          <Divider />
          <FormControlLabel
            onChange={handleChangeNotifyByEmail}
            className="email-notify-checkbox"
            control={<Checkbox color="primary" />}
            label="Notify users about the video message via email"
          />
        </>
      )}
    </div>
  );
};

VaamParticipantTabPanel.propTypes = {
  spaceId: PropTypes.string,
  onSelectParticipants: PropTypes.func,
  channel: PropTypes.instanceOf(Object),
  getParticipantsCount: PropTypes.func,
  enabledChat: PropTypes.bool,
  isOnError: PropTypes.bool,
  onChangeNotifyByEmail: PropTypes.func,
  onChangeSharingType: PropTypes.func,
  handleSharingClick: PropTypes.func,
  isPublicSpace: PropTypes.bool,
};

export default VaamParticipantTabPanel;
