import React from 'react';
import PropTypes from 'prop-types';
import LinkIcon from '@mui/icons-material/Link';
import './WebContentPreview.scss';
import ExternalResourceViewer from '../../components/ExternalResourceViewer';
import i18n from '../../i18n';
import CannotOpenLinkIframe from '../../components/Material/CannotOpenLinkIframe';
import { getPlayer } from '../../commons/ResourceUtils';
import MiroPlayer from '../../components/MiroPlayer';
import MentimeterPlayer from '../../components/MentimeterPlayer';
import Icons from '../../components/Icons';
import TCOCalculationViewer from '../../components/TCOCalculationViewer';

function WebContentPreview(props) {
  const { webUrl, embeddable } = props;

  const playerRef = React.createRef();
  const { type } = getPlayer(webUrl);

  const ratioClass =
    props.isMobile && props.columns === 1 ? 'paddingB-ratio916' : 'paddingB-ratio169';

  function renderInfo() {
    const sizeCssClass = `column-${props.columns}`;
    return (
      <div className="cms-web-content-preview-info">
        <div className={`cms-web-content-preview-content ${sizeCssClass}`}>
          <section className="icon-section">
            <LinkIcon fontSize="small" className="link-icon" />
          </section>
          <section className="info-section">
            <span className={`cms-web-content-preview-title`}>{i18n.t('Website')}</span>
            <span className="cms-web-content-preview-description">
              {i18n.t('Add a website link in the content tab.')}
            </span>
          </section>
        </div>
      </div>
    );
  }

  if (!webUrl) {
    return <div className={`cms-web-content-preview empty ${ratioClass}`}>{renderInfo()}</div>;
  }

  if (!embeddable) {
    return (
      <div className={`cms-web-content-preview empty ${ratioClass}`}>
        <CannotOpenLinkIframe
          className="cms-web-content-preview-info"
          icon={<Icons name="icon-new-info" className="info-icon" />}
          title={i18n.t(`Oops! This site can't be shown`)}
          description={i18n.t(
            `This website doesn't allow us to show it in an iframe element. We recommend adding a button with a link to the website which opens it in a new browser tab.`
          )}
        />
      </div>
    );
  }

  function renderPlayer() {
    let player;
    if (type === 'miro') {
      player = <MiroPlayer height={'810px'} key={webUrl} ref={playerRef} src={webUrl} />;
    } else if (type === 'mentimeter') {
      player = (
        <MentimeterPlayer height={'810px'} key={webUrl} ref={playerRef} src={webUrl} controls />
      );
    } else if (type === 'iloq') {
      player = <TCOCalculationViewer key={webUrl} ref={playerRef} src={webUrl} />;
    } else {
      player = <ExternalResourceViewer key={webUrl} ref={playerRef} src={webUrl} controls />;
    }
    return (
      <div
        className={`cms-web-content-preview ${type !== 'miro' && type !== 'mentimeter' && type !== 'iloq' && ratioClass} ${type === 'iloq' ? 'iloq' : ''}`}
      >
        {player}
      </div>
    );
  }

  return renderPlayer();
}

WebContentPreview.propTypes = {
  webUrl: PropTypes.string,
  columns: PropTypes.number,
  isMobile: PropTypes.bool,
  embeddable: PropTypes.bool,
};

WebContentPreview.defaultProps = {
  embeddable: true,
};

export default WebContentPreview;
