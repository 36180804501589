import React, { useEffect, useState, useCallback } from 'react';
import { Select, MenuItem, FormControl, ListItemIcon, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import './styles.scss';
import { ResourcesViewModeOptions } from '../../../resources/resourceConstants';

function ViewModeSelect(props) {
  const { value, options, onChange } = props;

  const [valueSelect, setValueSelected] = useState(value || 0);

  useEffect(() => {
    if (value !== undefined) {
      const el = options.find((item) => item.field === value);
      if (el) {
        setValueSelected(el.value);
      }
    }
  }, [value, options]);

  const handleChange = useCallback(
    (event) => {
      setValueSelected(event.target.value);
      if (onChange) {
        const el = options.find((item) => item.value === event.target.value);
        console.log('### handleChange', el.field);

        onChange(el.field);
      }
    },
    [onChange, options]
  );

  const handleOnClose = useCallback(() => {
    setTimeout(() => {
      document.activeElement.blur();
    }, 100);
  }, []);

  return (
    <FormControl
      variant="outlined"
      className="select-options view-mode-select"
      disabled={props.disabled}
    >
      <Select
        value={valueSelect}
        onChange={handleChange}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          classes: {
            paper: 'select-menu-icon select-menu',
          },
        }}
        className="view-mode-select__select"
        renderValue={(v) => {
          const item = options.find((el) => el.value === v);
          return <i className={item?.icon} />;
        }}
        onClose={handleOnClose}
      >
        {options.map((item, index) => (
          <MenuItem value={item.value} key={index}>
            <ListItemIcon>
              <i className={item.icon} />
            </ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

ViewModeSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ViewModeSelect.defaultProps = {
  disabled: false,
  options: ResourcesViewModeOptions,
};

export default React.memo(ViewModeSelect);
