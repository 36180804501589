import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import useSearchOneDriveFoldersQuery from './useSearchOneDriveFoldersQuery';

const useSmartFolderSearch = (oneDriveFolders, googleDriveFolders, keyword, enabled = true) => {
  const oneDriveQuery = useSearchOneDriveFoldersQuery(oneDriveFolders, keyword, enabled);
  const queryResults = useQueries({
    queries: [oneDriveQuery],
  });

  const mutateSmartFolderResults = useMemo(() => {
    const results = [];
    queryResults.forEach((result) => {
      if (result.isSuccess && result.data) {
        results.push(...result.data);
      }
    });
    return results;
  }, [queryResults]);

  const isLoading = queryResults.some((result) => result.isLoading);
  const errors = queryResults.map((result) => result.error).filter((error) => error != null);

  return {
    smartFolderResults: mutateSmartFolderResults,
    isLoading,
    errors,
  };
};

export default useSmartFolderSearch;
