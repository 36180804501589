import React from 'react';
import PropTypes from 'prop-types';
import './SidePanel.scss';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function SidePanel(props) {
  return (
    <div className="side-panel">
      {props.onClose && (
        <div className="close-side-panel">
          <IconButton
            aria-label="close"
            size="small"
            className="close-icon"
            onClick={props.onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      {props.children}
    </div>
  );
}

SidePanel.propTypes = {
  children: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
};

export default SidePanel;
