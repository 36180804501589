import React, { PureComponent } from 'react';
import { Popover } from '@mui/material';

import PropTypes from 'prop-types';
import ShareSpaceInvite from './ShareSpaceInvite';
import './ShareSpacePopover.scss';

class ShareSpacePopover extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'left',
    };
    this.transformOrigin = {
      vertical: 'top',
      horizontal: 'left',
    };
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    const { space, anchorEl, isMobile } = this.props;

    if (!anchorEl) {
      return null;
    }

    return (
      <Popover
        open
        anchorOrigin={this.anchorOrigin}
        transformOrigin={this.transformOrigin}
        className="spce-popover share-space-popover"
        anchorEl={anchorEl}
        onClose={this.handleClose}
      >
        <div className="content">
          <div className="tab-content">
            <ShareSpaceInvite space={space} isMobile={isMobile} onShare={this.handleClose} />
          </div>
        </div>
      </Popover>
    );
  }
}

ShareSpacePopover.propTypes = {
  space: PropTypes.instanceOf(Object),
  anchorEl: PropTypes.instanceOf(Object),
  isMobile: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ShareSpacePopover;
