import React, { useState, createContext, useEffect } from 'react';

import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { useAppContext } from '../../components/AppProvider';

const getUserDashboardContext = memoizeOne((open, isMobile) => {
  return {
    open,
    isMobile,
  };
});

export const UserDashboardContext = createContext();

export const useUserDashboardContext = () => React.useContext(UserDashboardContext);

function UserDashboardProvider(props) {
  const { children } = props;
  const { isMobile } = useAppContext();

  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const context = getUserDashboardContext(open, isMobile);

  context.handleDrawerToggle = handleDrawerToggle;
  context.handleCloseSideBar = () => {
    setOpen(false);
  };

  return <UserDashboardContext.Provider value={context}>{children}</UserDashboardContext.Provider>;
}

UserDashboardProvider.propTypes = {
  children: PropTypes.element,
};

export default UserDashboardProvider;
