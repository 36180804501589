import { useState } from 'react';
import { BreadcrumbsData } from './context/BreadcrumbsContextProvider';

const useTransientBreadcrumbs = () => {
  const [breadcrumbsData, setBreadcrumbsData] = useState<BreadcrumbsData>({
    breadcrumbs: [],
    selectedFolder: null,
  });

  const selectedFolder = breadcrumbsData.selectedFolder;
  const breadcrumbs = breadcrumbsData.breadcrumbs;
  const clearBreadcrumbsData = () => setBreadcrumbsData({ breadcrumbs: [], selectedFolder: null });

  return { breadcrumbs, selectedFolder, setBreadcrumbsData, clearBreadcrumbsData };
};

export default useTransientBreadcrumbs;
