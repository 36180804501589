import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import './ContentTab.scss';
import { Tab, Tabs } from '@mui/material';
import ContentForm from './ContentForm';
import { useThemeSettingsContext } from '../context/ThemeSettingsContextProvider';
import TabPanel from './TabPanel';
import { updatePageName } from '../../cmsServices';
import { useSpaceContext } from '../../../spaces/SpaceContext';

function PagesContentTab(props) {
  const { layout, spaceId, isAdmin } = props;
  const { selectedBlock, updateBlock, handleScrollingToBlock } = useThemeSettingsContext();
  const { onChangeNavigation, currentPage } = useSpaceContext();
  const [tab, setTab] = useState(0);
  const pagesBlock = selectedBlock?.items[0]?.pages;
  const selectedPageIndex = pagesBlock?.findIndex((page) => page?.id === currentPage?.id) || 0;
  useEffect(() => {
    setTab(selectedPageIndex);
  }, [selectedPageIndex]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    onChangeNavigation(newValue);
    if (handleScrollingToBlock) {
      handleScrollingToBlock(selectedBlock);
    }
  };

  const handleOnChangeContentForm = (prop, value, forceUpdate = false) => {
    const length = selectedBlock?.items?.[0]?.pages?.length;
    if (!length) return;
    const newBlock = _cloneDeep(selectedBlock);
    if (typeof prop === 'object' && typeof value === 'object') {
      prop.forEach((propItem, index) => {
        newBlock.items[0].pages[tab][propItem] = value[index];
      });
    } else {
      newBlock.items[0].pages[tab][prop] = value;
    }
    if (prop === 'name') {
      const payload = {
        name: value,
      };
      updatePageName(spaceId, newBlock.id, currentPage.id, payload);
    }

    updateBlock(newBlock, forceUpdate);
  };

  function renderDefaultForm() {
    const type = selectedBlock?.items?.[0]?.type;
    const columns = selectedBlock.items?.[0]?.pages?.length || 1;
    const tabs = Array.from(Array(columns).keys());
    const content = selectedBlock.items?.[0]?.pages?.[tab];
    // const { columns, tabs, content, type, handleOnChangeContent } = getDataContent();

    return (
      <>
        {columns > 1 && (
          <Tabs
            value={tab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs for cms content tab"
            className="cms-editing-component-panel-number-tabs"
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            {tabs.map((t) => (
              <Tab
                key={t}
                label={t + 1}
                value={t}
                aria-controls={`cms-content-tab-${t}`}
                className="cms-editing-component-panel-number-tab"
              />
            ))}
          </Tabs>
        )}
        <TabPanel
          key={tab}
          value={tab}
          index={tab}
          className="cms-editing-component-panel-number-tabpanel"
        >
          <ContentForm
            key={content?.id}
            type={type}
            layout={layout}
            content={content}
            onChange={handleOnChangeContentForm}
            spaceId={spaceId}
            currentTab={tab}
            isAdmin={isAdmin}
          />
        </TabPanel>
      </>
    );
  }

  return <div className="cms-editing-component-panel-tabpanel-content">{renderDefaultForm()}</div>;
}

PagesContentTab.propTypes = {
  layout: PropTypes.instanceOf(Object),
  spaceId: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default PagesContentTab;
