import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import memoizeOne from 'memoize-one';
import { GLOBAL_PROVIDER_TYPE } from '../utils';
import { SelectedFolderProps } from './Interfaces';
import useBreadcrumbs from '../useBreadcrumbs';
import { useGlobalResourcesContext } from './GlobalResourcesContextProvider';

export interface BreadcrumbsData {
  breadcrumbs: SelectedFolderProps[] | null;
  selectedFolder: SelectedFolderProps | null;
}

const defaultValue: IBreadcrumbContext = {
  breadcrumbs: [],
  currentBreadcrumbItem: null,
  goToBreadcrumbItem: () => {},
  updateBreadcrumbItem: () => {},
  goToRootItem: () => {},
  setPage: () => {},
  clearBreadcrumbsData: () => {},
};

type IBreadcrumbContext = {
  breadcrumbs: SelectedFolderProps[];
  currentBreadcrumbItem: SelectedFolderProps | null;
  goToBreadcrumbItem: (breadcrumb: SelectedFolderProps | null) => void;
  updateBreadcrumbItem: (data: SelectedFolderProps) => void;
  goToRootItem: () => void;
  setPage: (page: number) => void;
  clearBreadcrumbsData: () => void;
};

type IBreadcrumbsContextProviderProps = {
  children: React.ReactNode;
  type: (typeof GLOBAL_PROVIDER_TYPE)[keyof typeof GLOBAL_PROVIDER_TYPE];
  useStateOnly: boolean;
};

// Define the context
const BreadcrumbContext = createContext<IBreadcrumbContext>(defaultValue);

export const BreadcrumbContextConsumer = BreadcrumbContext.Consumer;
export const useBreadcrumbContext = () => useContext<IBreadcrumbContext>(BreadcrumbContext);

const getBreadcrumbContext = memoizeOne(
  (breadcrumbs, currentBreadcrumbItem): IBreadcrumbContext => ({
    breadcrumbs,
    currentBreadcrumbItem,
    goToBreadcrumbItem: defaultValue.goToBreadcrumbItem,
    goToRootItem: defaultValue.goToRootItem,
    updateBreadcrumbItem: defaultValue.updateBreadcrumbItem,
    setPage: defaultValue.setPage,
    clearBreadcrumbsData: defaultValue.clearBreadcrumbsData,
  })
);

// Define the provider component
export const BreadcrumbsContextProvider = (props: IBreadcrumbsContextProviderProps) => {
  const { children, type, useStateOnly } = props;
  const { breadcrumbs, selectedFolder, setBreadcrumbsData, clearBreadcrumbsData } = useBreadcrumbs(
    type,
    useStateOnly
  );
  const { clearResources } = useGlobalResourcesContext();
  const prevSelectedFolderId = useRef<string | number | null | undefined>(selectedFolder?.id);

  const context: IBreadcrumbContext = getBreadcrumbContext(breadcrumbs, selectedFolder);

  useEffect(() => {
    prevSelectedFolderId.current = selectedFolder?.id;
  }, [selectedFolder?.id]);

  context.goToBreadcrumbItem = useCallback(
    (item: SelectedFolderProps | null) => {
      if (prevSelectedFolderId.current !== item?.id) {
        clearResources();
      }
      setBreadcrumbsData((prev) => {
        const prevSelectedFolder = prev.selectedFolder;
        if (
          prevSelectedFolder?.id === item?.id &&
          prevSelectedFolder?.parentId === item?.parentId &&
          prevSelectedFolder?.name === item?.name
        ) {
          return prev;
        }
        let newBreadcrumbs: SelectedFolderProps[];
        const newSelectedFolder = item
          ? {
              id: item.id,
              name: item.name,
              parentId: item.parentId,
              driveId: item.driveId,
              provider: type,
              page: 0,
            }
          : null;

        if (!newSelectedFolder) {
          newBreadcrumbs = [];
        } else if (
          newSelectedFolder.parentId === null ||
          newSelectedFolder.parentId === undefined
        ) {
          newBreadcrumbs = [newSelectedFolder];
        } else {
          newBreadcrumbs = prev.breadcrumbs ? [...prev.breadcrumbs] : [];
          const indexFolder = newBreadcrumbs.findIndex(
            (b) => b.id === newSelectedFolder.id && b.parentId === newSelectedFolder.parentId
          );

          if (indexFolder > -1) {
            newBreadcrumbs = newBreadcrumbs.slice(0, indexFolder);
            newBreadcrumbs.push(newSelectedFolder);
          } else {
            const indexParent = newBreadcrumbs.findIndex(
              (b) => b.id === newSelectedFolder.parentId
            );

            if (indexParent > -1) {
              newBreadcrumbs = newBreadcrumbs.slice(0, indexParent + 1);
              newBreadcrumbs.push(newSelectedFolder);
            } else {
              return { breadcrumbs: null, selectedFolder: newSelectedFolder };
            }
          }
        }

        return { breadcrumbs: newBreadcrumbs, selectedFolder: newSelectedFolder };
      });
    },
    [clearResources, setBreadcrumbsData, type]
  );

  context.updateBreadcrumbItem = useCallback(
    (data: SelectedFolderProps) => {
      setBreadcrumbsData((prev) => {
        if (prev.selectedFolder === null) {
          return { breadcrumbs: [data], selectedFolder: data };
        }
        if (!prev.breadcrumbs) {
          return prev;
        }
        const index = prev.breadcrumbs.findIndex((item) => item.id === data.id);
        if (index === -1) {
          return prev;
        }
        // check if the data is the same
        const item = prev.breadcrumbs[index];
        if (
          (data.name !== undefined && data.name !== item.name) ||
          (data.parentId !== undefined && data.parentId !== item.parentId) ||
          (data.driveId !== undefined && data.driveId !== item.driveId)
        ) {
          const newBreadcrumbs = [...prev.breadcrumbs];
          newBreadcrumbs[index] = { ...item, ...data };
          return { breadcrumbs: newBreadcrumbs, selectedFolder: prev.selectedFolder };
        }
        return prev;
      });
    },
    [setBreadcrumbsData]
  );

  context.goToRootItem = useCallback(() => {
    setBreadcrumbsData((prev) => {
      if (!prev.breadcrumbs || prev.breadcrumbs.length === 0) {
        return prev;
      }
      const rootItem = prev.breadcrumbs[0];
      rootItem.page = 0;
      return { breadcrumbs: [rootItem], selectedFolder: rootItem };
    });
  }, [setBreadcrumbsData]);

  context.setPage = useCallback(
    (page: number) => {
      setBreadcrumbsData((prev) => {
        if (!prev.selectedFolder) {
          return prev;
        }
        const newSelectedFolder = { ...prev.selectedFolder, page };
        return { breadcrumbs: prev.breadcrumbs, selectedFolder: newSelectedFolder };
      });
    },
    [setBreadcrumbsData]
  );

  context.clearBreadcrumbsData = clearBreadcrumbsData;

  return <BreadcrumbContext.Provider value={context}>{children}</BreadcrumbContext.Provider>;
};
