import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { getSpaceDirectoryByType } from '../../resources/ResourceServices';
import { DirectoryType } from '../../app/appConstants';

function useCmsDirectory(spaceId) {
  const [cmsDirectory, setCmsDirectory] = React.useState(null);

  const getSpaceCMSDirectoryQuery = useQuery({
    queryKey: [CacheKeys.getSpaceCmsDirectory, spaceId],
    queryFn: async () => {
      const resp = await getSpaceDirectoryByType(spaceId, DirectoryType.CMS, true);
      if (!resp) {
        throw new Error('getSpaceCMSDirectoryQuery failed');
      }
      return resp;
    },

    retry: 3,
    retryDelay: (attemptIndex) => 2000 * attemptIndex + 1000,
    enabled: !!spaceId,
  });

  React.useEffect(() => {
    // console.log('getSpaceCMSDirectoryQuery.data', getSpaceCMSDirectoryQuery.data);
    if (!getSpaceCMSDirectoryQuery.data) {
      return;
    }
    setCmsDirectory(getSpaceCMSDirectoryQuery.data);
  }, [getSpaceCMSDirectoryQuery.data]);

  return [cmsDirectory, setCmsDirectory, getSpaceCMSDirectoryQuery.isFetching];
}

useCmsDirectory.propTypes = {
  spaceId: PropTypes.string,
};

export default useCmsDirectory;
