import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import i18n from '../../i18n';
import { copyTextToClipboard, sendNotification } from '../../commons/utils';
import useURLSpace from '../../homepage/useURLSpace';
import { useThemeSettingsContext } from './context/ThemeSettingsContextProvider';

function ShareLinkCmsBlock(props) {
  const { layoutId, pageSlug } = props;

  const { space } = useThemeSettingsContext();

  const params = layoutId ? { cbId: layoutId } : {};

  if (pageSlug) {
    params.pageId = pageSlug;
  }
  const { getShareLinkSpace } = useURLSpace(space);

  const toBeSharedLink = getShareLinkSpace(params);

  async function handleCopyContentBlockLink() {
    console.log('### toBeSharedLink', toBeSharedLink);
    await copyTextToClipboard(toBeSharedLink);
    sendNotification(i18n.t('Link copied!'));
  }

  return (
    <div className="cms-component-panel-control">
      <label className="cms-component-panel-control-label">{i18n.t('Share link')}</label>
      <TextField
        autoComplete="off"
        fullWidth
        className="cms-component-panel-control-input"
        variant="outlined"
        defaultValue={toBeSharedLink}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleCopyContentBlockLink();
                }}
              >
                <LinkIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
      {!!layoutId && <div className="divider"></div>}
    </div>
  );
}

ShareLinkCmsBlock.propTypes = {
  layoutId: PropTypes.string,
  pageSlug: PropTypes.string,
};

export default ShareLinkCmsBlock;
