import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceAssessmentsByUser } from '../services/SpaceAnalyticsService';

const useAssessmentsByUserQuery = (
  spaceId: string | undefined,
  userInfoId: number,
  endDate: string,
  enabled: boolean
) => {
  const getAssessmentsByUser = useQuery({
    queryKey: [CacheKeys.spaceAnalytics_FetchSpaceAssessmentsByUser, spaceId, userInfoId, endDate],
    queryFn: async () => {
      if (!spaceId || !userInfoId) {
        return [];
      }
      const resp = await fetchSpaceAssessmentsByUser(userInfoId, endDate);
      return resp;
    },

    retry: 3,
    retryDelay: () => 5000,
    enabled: enabled,
  });

  const isFetching = getAssessmentsByUser.isFetching;
  const data = getAssessmentsByUser.data?.items || [];

  return { data, isFetching };
};

export default useAssessmentsByUserQuery;
