import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import I18n from '../../i18n';
import { updateComment } from './CommentService';
import { sendNotification, uuidv4 } from '../../commons/utils';
import { uploadResource } from '../../commons/CommonServices';
import { getResourceFileType } from '../../commons/ResourceUtils';
import FilePicker from '../../components/FilePicker';
import Loading from '../../components/Loading';
import { useSpaceContext } from '../SpaceContext';
import spaceUser from '../spaceUser';
import useMiscDirectory from '../../commons/useMiscDirectory';
import { addRuntimeMaterial } from '../../resources/ResourceServices';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import './CommentBox.scss';
import { newDate } from '../../commons/DateTimeUtils';
import { CommentStatus } from '../../app/appConstants';
import CommentText from './CommentText';

let uploader = null;

const CommentBox = forwardRef((props, ref) => {
  const { isMobile } = useSpaceContext();

  const [text, setText] = useState(props.comment?.text || '');
  const [validation, setValidation] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [progress, setProgress] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editingResource, setEditingResource] = useState(null);

  const [hasChanges, setHasChanges] = useState(false);
  const isHost = spaceUser.isHost();
  const [miscDirectory] = useMiscDirectory(props.spaceId);
  const filePickerRef = React.useRef(null);
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (props.comment) {
      setText(props.comment.text);
    } else {
      setText('');
    }
    setEditingResource(
      props.comment?.resourceId
        ? {
            id: props.comment.resourceId,
            name: props.comment.resourceName,
            materialId: props.comment.materialId,
          }
        : null
    );
  }, [props.comment]);

  function validateForm() {
    let flag = true;
    if (!text.trim() && !selectedFile && !editingResource) {
      flag = false;
    }
    if (flag === true && selectedFile) {
      if (getResourceFileType(selectedFile) === null) {
        setValidation(I18n.t('This file type is not supported'));
        flag = false;
      }
    }
    if (flag) {
      setValidation(null);
    }
    return flag;
  }

  const validateFormDebounced = useDebouncedCallback(() => validateForm(), 1000);

  function handleOnChange(event) {
    event.stopPropagation();
    const value = event.target.value;
    setText(value);
    validateFormDebounced();
    if (!value.length && !selectedFile) {
      setHasChanges(false);
    } else {
      setHasChanges(true);
    }
    return false;
  }

  function handleOnClickAttachIcon() {
    if (filePickerRef) {
      filePickerRef.current.openFilePicker();
    }
  }

  function handleOnChangeFile(file) {
    setSelectedFile(file);
    setEditingResource(null);
    validateFormDebounced();
    setHasChanges(true);
  }

  function renderFilePicker() {
    return <FilePicker accept="*" onChange={handleOnChangeFile} ref={filePickerRef} />;
  }

  async function saveComment(resource) {
    let newResource = resource;
    if (props.comment && !resource) {
      newResource = editingResource;
    }
    let data = {
      text: text,
      parentCommentId: props.parentCommentId || null,
      attachFileUrl: '',
      resourceId: newResource?.id,
      resourceName: newResource?.name,
      materialId: newResource?.materialId,
    };
    if (props.comment) {
      // edit
      data.id = props.comment.id;
      const resp = await updateComment(props.channelId, props.comment.id, data);
      setTimeout(() => {
        setIsSaving(false);
        if (!resp) {
          sendNotification(I18n.t('Something went wrong, please try again'), { type: 'error' });
        } else {
          setText('');
          setHasChanges(false);
          if (props.onEdit) {
            props.onEdit(data);
          }
        }
      });
    } else {
      // add
      data = {
        ...data,
        status: CommentStatus.SENDING,
        participant: spaceUser.getParticipantInfo(),
        participantId: props.participantId,
        channelId: props.channelId,
        created: newDate().utc().format(),
        refId: uuidv4(),
        parentComment: props.parentComment ? props.parentComment : null,
        isAdded: true,
      };
      setText('');
      setHasChanges(false);
      if (props.onAdd) {
        props.onAdd(data);
      }
    }
  }

  function handleOnProgressUpdate(f) {
    // console.log('progress', f.progress());
    setProgress(Math.round(f.progress() * 100));
  }

  async function uploadFile() {
    setProgress(0);
    selectedFile.skipTranscode = true;
    uploader = await uploadResource(
      props.spaceId,
      selectedFile,
      null,
      null,
      null,
      miscDirectory?.id
    );

    return new Promise((resolve) => {
      uploader.on('fileProgress', handleOnProgressUpdate);
      uploader.on('fileSuccess', async (f, message) => {
        const messageObj = JSON.parse(message);
        // console.log('messageObj', messageObj);
        messageObj.sessionId = uploader.opts.sessionId;
        const newComment = {
          id: messageObj.resourceId,
          name: messageObj.fileName,
          materialId: messageObj.materialId,
        };
        // add material
        if (messageObj.resourceId > 0 && !!miscDirectory && !messageObj.materialId) {
          const material = {
            resourceId: messageObj.resourceId,
            fileName: messageObj.fileName,
            fileDescription: '',
            resourceType: messageObj.resourceType,
          };
          const resp = await addRuntimeMaterial(props.spaceId, miscDirectory.id, material);
          // console.log('### resp', resp);
          if (resp?.materialId) {
            newComment.materialId = resp.materialId;
          }
        }

        await saveComment(newComment);
        setTimeout(() => {
          setProgress(null);
          setSelectedFile(null);
          console.log('success uploading');
          resolve(true);
        });
      });
      uploader.on('cancel', () => {
        setProgress(null);
        setIsSaving(false);
        resolve(false);
      });
      uploader.on('fileError', (f, message) => {
        const error = JSON.parse(message);
        setProgress(null);
        setIsSaving(false);
        sendNotification(error.message, { type: 'error' });
        resolve(false);
      });
    });
  }

  function handleOnClickCancelUpload() {
    if (uploader) {
      uploader.cancel();
    }
    setIsSaving(false);
    setProgress(null);
  }

  async function handleOnSave() {
    if (isSaving || !validateForm(true)) {
      // setIsSaving(false);
      return;
    }
    // setIsSaving(true);

    if (selectedFile) {
      await uploadFile();
    } else {
      await saveComment();
    }
  }

  function handleOnKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleOnSave();
    }
    return false;
  }

  function handleOnSend() {
    handleOnSave();
  }

  async function handleCloseForm() {
    let isCloseForm = true;
    if (hasChanges) {
      const { isSaved, discard } = await UnsavedChangesDialog.show(
        I18n.t('MESSAGE NOT SENT'),
        <>
          {I18n.t(`You have not sent your messages.`)}
          <br />
          {I18n.t(`Would you like to send?`)}
        </>,
        I18n.t('Send')
      );
      isCloseForm = discard;
      if (isSaved) {
        await handleOnSave();
        return true;
      }
    }
    return isCloseForm;
  }

  React.useImperativeHandle(ref, () => ({
    handleCloseForm,
    focusInput: () => {
      inputRef.current.focus();
    },
  }));

  function renderProgressBar() {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress color="secondary" variant="determinate" value={progress} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="secondary">{`${Math.round(progress)}%`}</Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            color="secondary"
            className="cancel"
            onClick={handleOnClickCancelUpload}
          >
            {I18n.t('Cancel')}
          </Typography>
        </Box>
      </Box>
    );
  }

  function handleOnClickRemoveFile() {
    setSelectedFile(null);
    setEditingResource(null);
    if (!text?.length) {
      setHasChanges(false);
    }
    validateFormDebounced();
  }

  function renderFilePreview() {
    let name = null;
    if (editingResource && editingResource.name) {
      name = editingResource.name;
    } else if (selectedFile) {
      name = selectedFile.name;
    }
    if (!name) {
      return null;
    }
    return (
      <div className="uploaded-file">
        <span className="file-name">{name}</span>
        {!isSaving && (
          <IconButton
            aria-label="more"
            color="primary"
            onClick={handleOnClickRemoveFile}
            size="small"
            className="remove"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  }

  function renderUploadingFile() {
    return (
      <div className="attach-file">
        {progress != null && renderProgressBar()}
        {renderFilePreview()}
      </div>
    );
  }

  // async function handleOnClickVideoMessageIcon() {
  //   setSidebar(null);
  //   setShowVaamRecorder(true);
  //   setVaamTargetChannel(activeChannel);
  //   setIsVaamFromChat(true);
  // }

  function renderSendButton() {
    if (isSaving) {
      return <Loading fontSize={15} />;
    }
    const isValid = text || selectedFile || editingResource;
    // console.log('### renderSendButton', isValid, text, selectedFile);
    return <span className={`icon-send ${!isValid ? 'disabled' : ''}`} onClick={handleOnSend} />;
  }

  function handleCancel() {
    props.onCancel();
    setValidation('');
  }

  function renderEditButtons() {
    return (
      <div className="edit-buttons">
        <span onClick={handleCancel}>
          <CloseIcon />
        </span>
        <span onClick={handleOnSend}>
          <CheckIcon />
        </span>
      </div>
    );
  }

  const replySection = React.useMemo(() => {
    if (!props.parentComment) return null;

    const isMyself = props.participantId === props.parentComment.participant.id;
    return (
      <div className="replying">
        <div className="top-info">
          <span className="icon-share"></span>
          <div className="comment-info">
            <span>
              Replying to <b>{isMyself ? 'Yourself' : props.parentComment.participant.email}</b>
            </span>
          </div>
        </div>
        <IconButton className="close-response" onClick={props.cancelReply}>
          <CloseIcon />
        </IconButton>
        <div className="bottom-info">
          <span>
            <CommentText resource={props.parentComment.resource} text={props.parentComment.text} />
          </span>
        </div>
      </div>
    );
  }, [props.parentComment, props.participantId, props.cancelReply]);

  const canHideUploadFile = !isHost;
  return (
    <div className="comment-box">
      <div className="comment-box-body">
        <div className="input" id="comment-box-input-container">
          {validation && <div className="validation">{validation}</div>}
          {replySection}
          <InputBase
            value={text}
            inputRef={inputRef}
            inputProps={{ 'aria-label': 'naked' }}
            multiline
            placeholder={props.placeholder || I18n.t('Type a message')}
            fullWidth
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onFocus={props.onFocus}
            autoFocus={!isMobile && !props.isChatWiget}
          />
          <div className="action-buttons">
            <div className="left">
              {!canHideUploadFile && (
                <div className="attach">
                  <AttachFileIcon onClick={handleOnClickAttachIcon} />
                </div>
              )}
            </div>
            <div className="right">
              {props.comment ? (
                <div className="edit">{renderEditButtons()}</div>
              ) : (
                <div className="send">{renderSendButton()}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {renderUploadingFile()}
      {renderFilePicker()}
    </div>
  );
});
CommentBox.propTypes = {
  spaceId: PropTypes.string,
  parentCommentId: PropTypes.number,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  comment: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    attachFileUrl: PropTypes.string,
    resourceId: PropTypes.number,
    resourceName: PropTypes.string,
    materialId: PropTypes.number,
  }),
  onCancel: PropTypes.func,
  channelId: PropTypes.number,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  parentComment: PropTypes.instanceOf(Object),
  participantId: PropTypes.number,
  cancelReply: PropTypes.func,
  isChatWiget: PropTypes.bool,
};

CommentBox.defaultProps = {
  vaamEnabled: true,
};

CommentBox.displayName = 'CommentBox';

export default CommentBox;
