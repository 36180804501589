import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import ButtonEnhanced from '../ButtonEnhanced';

const ImageSettings = (props) => {
  return (
    <div>
      <div className="label">{i18n.t('Select image layout')}</div>
      <div className="group-button">
        <ButtonEnhanced
          className="btn-layout"
          startIcon={<span className="icon-image-1"></span>}
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {i18n.t('Original size')}
        </ButtonEnhanced>
      </div>
    </div>
  );
};

ImageSettings.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
export default ImageSettings;
