/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import FilePicker from '../FilePicker';
import './style.scss';
import UploadButton from './UploadButton';
import {
  InvalidFileType,
  getFolderName,
  getVisibleFiles,
  sizeFolderValidation,
  typeFolderErrors,
  validateFiles,
} from './utils';
import { UploadingFileState } from '../../app/appConstants';
import UploadStatus from './UploadStatus';
import UploaderLabelTs from './UploaderLabelTs';
import { UploadCompleted } from './UploadCompleted';
import { UploadFailureResources } from './UploadeFailureResources';
import FormHelper from '../DndFileUploader3/FormHelper';
import FilesUploader, { ImperativeHandleRef } from './FilesUploader';

export interface IProps {
  multiple?: boolean;
  accept?: string;
  isGlobalResourcesEnabled?: boolean;
  spaceId: string;
  spacePrivacy: string;
  isPortal: boolean;
  onChangeResources: (resources: any[]) => void;
  onUploadingStateChange: (state: number) => void;
  variant: string;
  onSelectFolder?: (folder: object) => void;
  isAdmin: boolean;
  isGlobal: boolean;
}

export interface IState {
  files: File[];
  uploadingState: number;
  invalidFiles: InvalidFileType[];
  folders: any;
  totalFiles: number;
  progresses: { [key: string]: number } | null;
  resources: any[];
  sectionIds: string[];
  errorMessages: string;
  progress: number;
}

class DndFolderUpload extends React.PureComponent<IProps, IState> {
  private folderRef: React.RefObject<FilePicker>;

  private filesUploaderRef: React.RefObject<ImperativeHandleRef>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      totalFiles: 0,
      files: [],
      uploadingState: UploadingFileState.NotStarted,
      invalidFiles: [],
      folders: {},
      progresses: {},
      resources: [],
      sectionIds: [],
      errorMessages: '',
      progress: 0,
    };

    this.folderRef = React.createRef();
    this.filesUploaderRef = React.createRef();

    this.pickFolder = this.pickFolder.bind(this);
    this.pickFolderGr = this.pickFolderGr.bind(this);
    this.handleCancelUploadFolder = this.handleCancelUploadFolder.bind(this);
    this.renderUploadContainer = this.renderUploadContainer.bind(this);
    this.handleUploadFolderFailed = this.handleUploadFolderFailed.bind(this);
    this.handleUploaderOnchange = this.handleUploaderOnchange.bind(this);
    this.resetSessionIds = this.resetSessionIds.bind(this);
    this.handleUploadFileFailed = this.handleUploadFileFailed.bind(this);
    this.handleChangeProgress = this.handleChangeProgress.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    if (prevState.uploadingState !== this.state.uploadingState) {
      this.props.onUploadingStateChange(this.state.uploadingState);
    }
  }

  componentWillUnmount(): void {
    // Remove all sessionIds when unmount
    if (this.filesUploaderRef?.current?.getSessionIds().length) {
      this.filesUploaderRef?.current?.cancelSessions();
    }
  }

  handleUploadFolderFailed(type: keyof typeof typeFolderErrors) {
    if (!type) return;

    this.setState({
      uploadingState: UploadingFileState.Failed,
      errorMessages: typeFolderErrors[type],
    });
  }

  handleUploadFileFailed(file: InvalidFileType) {
    if (!file) return;

    this.setState({
      invalidFiles: [...this.state.invalidFiles, file],
    });
  }

  async pickFolder(files: any) {
    // console.log('### pickFolder', files, files);
    // Not select hidden files
    const filterFiles = getVisibleFiles(files);
    const valid = sizeFolderValidation(filterFiles);
    if (valid) {
      this.handleUploadFolderFailed(valid);
      return;
    }
    if (filterFiles.length === 0) {
      return;
    }
    const folderName = getFolderName(filterFiles);
    let folder = {};
    if (folderName) {
      folder = {
        name: folderName,
      };
    }
    if (this.props.onSelectFolder) {
      this.props.onSelectFolder(folder);
    }

    const { validFiles, invalidFiles } = validateFiles(filterFiles, this.props.isGlobal);
    if (validFiles?.length) {
      this.setState({
        files: validFiles,
        totalFiles: filterFiles.length,
        invalidFiles: invalidFiles,
        progress: 0,
        errorMessages: '',
        folders: folder,
        uploadingState: UploadingFileState.Started,
      });
    } else {
      if (invalidFiles) {
        this.setState({
          totalFiles: filterFiles.length,
          invalidFiles: invalidFiles,
          progress: 0,
          errorMessages: '',
          folders: folder,
          uploadingState: UploadingFileState.Completed,
        });
      }
    }
  }

  handleUploaderOnchange(resource: any) {
    const newResources = [...this.state.resources];
    const index = newResources.findIndex((item) => item.sessionId === resource.sessionId);

    if (index > -1) return;
    newResources.push(resource);
    this.setState({
      resources: newResources,
    });
    this.props.onChangeResources(newResources);
  }

  handleChangeProgress(progress: number) {
    if (this.state.progress === progress) {
      return;
    }
    this.setState({ progress });
    if (progress === 100) {
      setTimeout(() => {
        this.setState({
          uploadingState: UploadingFileState.Completed,
        });
      }, 1800);
    } else {
      this.setState({
        uploadingState: UploadingFileState.Started,
      });
    }
  }

  resetForm() {
    this.setState({
      resources: [],
      progress: 0,
      files: [],
      invalidFiles: [],
      totalFiles: 0,
      folders: {},
    });
  }

  resetSessionIds() {
    this.resetForm();
    this.setState({ uploadingState: UploadingFileState.NotStarted });
    this.filesUploaderRef?.current?.resetSessionIds();
  }

  handleCancelUploadFolder() {
    this.filesUploaderRef?.current?.cancelSessions();
    this.resetForm();
    this.setState({ uploadingState: UploadingFileState.Canceled });
    this.props.onChangeResources([]);
  }

  pickFolderGr(folder: any) {
    if (!folder) {
      return;
    }
    this.setState({ folders: folder, uploadingState: UploadingFileState.Completed });
    if (this.props.onSelectFolder) {
      this.props.onSelectFolder(folder);
    }
  }

  renderUploadContainer() {
    const { multiple, accept, isGlobalResourcesEnabled, isAdmin, isPortal } = this.props;
    const { totalFiles, uploadingState, progress, resources } = this.state;

    const uploadError =
      !!this.state.errorMessages && this.state.uploadingState === UploadingFileState.Failed;
    // const totalUploadingFiles = totalFiles - resources.length - invalidFiles?.length;
    const totalUploadedFiles = resources.length;
    // console.log('### 262 FolderUpload: ', totalFiles, resources.length, invalidFiles?.length);

    if (uploadingState === UploadingFileState.Started) {
      return (
        <UploadStatus
          totalFiles={totalFiles}
          totalUploadedFiles={totalUploadedFiles}
          uploadingState={uploadingState}
          progress={progress}
          onCancel={this.handleCancelUploadFolder}
        />
      );
    } else if (uploadingState === UploadingFileState.Completed) {
      return (
        <>
          <UploadCompleted
            folderName={this.state?.folders?.name || 'folder'}
            totalFiles={this.state.totalFiles}
            uploadedFiles={this.state.resources.length}
            onDelete={this.handleCancelUploadFolder}
          />
          {this.state.invalidFiles?.length > 0 ? (
            <UploadFailureResources files={this.state.invalidFiles} />
          ) : null}
        </>
      );
    }
    return (
      <>
        <FilePicker
          accept={accept}
          onChange={this.pickFolder}
          ref={this.folderRef}
          multiple={multiple}
          validatePowerPointFile={false}
          isFolder
        />
        <div className="upload-area">
          <div className="dnd-file-uploader-control">
            <UploaderLabelTs />
            <UploadButton
              multiple={multiple}
              accept={accept}
              addFolder={this.pickFolderGr}
              isAdmin={isAdmin}
              isPortal={isPortal}
              isGlobalResourcesEnabled={isGlobalResourcesEnabled}
              pickFolder={this.pickFolder}
              onOpenSelectFolder={() => {
                this.folderRef.current?.onClick();
              }}
              onError={this.handleUploadFolderFailed}
            />
            <FormHelper show={uploadError} helperText={this.state.errorMessages} />
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className={`upload-container ${this.props.variant || ''}`}>
        {this.renderUploadContainer()}
        {this.state.files.length > 0 && (
          <FilesUploader
            ref={this.filesUploaderRef}
            isPortal={this.props.isPortal}
            files={this.state.files}
            spaceId={this.props.spaceId}
            spacePrivacy={this.props.spacePrivacy}
            onChangeResource={this.handleUploaderOnchange}
            onChangeProgress={this.handleChangeProgress}
            onUploadFailed={this.handleUploadFileFailed}
          />
        )}
      </div>
    );
  }
}

export default DndFolderUpload;
