import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const GlobalDndContext = (props) => {
  // the following line solve the problem only with key property
  return (
    <DndProvider backend={HTML5Backend} key={props.keyList}>
      {props.children}
    </DndProvider>
  );
};

GlobalDndContext.propTypes = { children: PropTypes.node, keyList: PropTypes.string };

export default GlobalDndContext;
