import React from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ParticipantInfoItem from './ParticipantInfoItem';
import { getTimeActive } from './functions';
import LightTooltip from '../../components/LightTooltip';

const ParticipantRow = (props) => {
  const {
    isMobile,
    participant,
    isModerator,
    isCurrentParticipant,
    disabledActionButton,
    isPublicSpace,
  } = props;

  const handleMoreButtonClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    props.onClickMore(event, participant);
  };

  const handleOnClickParticipant = () => {
    if (props.onClickItem && !(participant.isExternal && isPublicSpace)) {
      props.onClickItem(participant);
    }
  };

  const renderAction = () => {
    if (
      !participant.isCurrentParticipant &&
      isModerator &&
      !(participant.isExternal && isPublicSpace)
    ) {
      return (
        <LightTooltip
          title="Go back to the space to add participants."
          disableHoverListener={!props.disabledActionButton}
        >
          <div>
            <IconButton
              className="btn-three-dots"
              onClick={handleMoreButtonClick}
              disabled={disabledActionButton}
            >
              <MoreHorizIcon />
            </IconButton>
          </div>
        </LightTooltip>
      );
    }

    return null;
  };

  return (
    <LightTooltip
      key={participant.id}
      title={'Start a private chat with selected user in the Space'}
      disableHoverListener={
        !props.onClickItem ||
        participant.isCurrentParticipant ||
        props.disabledActionButton ||
        !props.canChat ||
        (participant.isExternal && isPublicSpace)
      }
      placement="right"
      classes={{ tooltip: 'participant-table-row-tooltip' }}
    >
      <TableRow
        hover={!participant.isCurrentParticipant}
        tabIndex={-1}
        key={participant.id}
        onClick={handleOnClickParticipant}
      >
        <TableCell className="participant-cell">
          <ParticipantInfoItem
            participant={participant}
            isPublicSpace={isPublicSpace}
            isCurrentParticipant={isCurrentParticipant}
            isShowLastActive={isMobile}
          />
        </TableCell>
        {!isMobile && (
          <TableCell className="last-modified-cell">
            {getTimeActive(participant?.lastActive, participant.isOnline, false, '')}
          </TableCell>
        )}
        <TableCell align="center" className="action-button">
          {renderAction()}
        </TableCell>
      </TableRow>
    </LightTooltip>
  );
};

ParticipantRow.propTypes = {
  isMobile: PropTypes.bool,
  isCurrentParticipant: PropTypes.bool,
  participant: PropTypes.instanceOf(Object),
  onClickMore: PropTypes.func,
  isModerator: PropTypes.bool,
  onClickItem: PropTypes.func,
  disabledActionButton: PropTypes.bool,
  canChat: PropTypes.bool,
  isPublicSpace: PropTypes.bool,
};

export default ParticipantRow;
