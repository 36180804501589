import Resumable from 'resumablejs';
import { getResourceFileType, sanitizeFileName } from './ResourceUtils';
import currentOrganization from '../commons/CurrentOrganization';
import spaceUser from '../spaces/spaceUser';
import Api from './api';
import RuntimeApi from './RuntimeApi';
import { getResourceApi, getResourceRuntimeApi } from './utils';
import appInfo from '../app/appInfo';

export async function uploadPublicResource(file, onError = null, oldResourceId = null) {
  if (!file) return null;
  const isPortal = appInfo.isPortal();
  const resumableHeaders = { 'X-CSRF': '1' };
  if (isPortal) {
    const organization = currentOrganization.getData();
    if (organization) {
      Object.assign(resumableHeaders, { organizationId: organization.id });
    }
  } else {
    const token = spaceUser.getAccessToken();
    Object.assign(resumableHeaders, { authorization: `Bearer ${token}` });
  }

  const chunkSize = 1 * 1024 * 1024;
  const fileName = file.fileName || file.name || 'noname'; // original file name
  const sanitizedFileName = sanitizeFileName(fileName);
  const newFile = file;
  newFile.fileName = sanitizedFileName; // to avoid firewall issue

  const r = new Resumable({
    chunkSize,
    forceChunkSize: true,
    simultaneousUploads: 1,
    uploadMethod: 'PUT',
    testChunks: false,
    maxChunkRetries: 3,
    chunkRetryInterval: 1000,
    throttleProgressCallbacks: 1,
    fileParameterName: 'file',
    chunkNumberParameterName: 'chunkNumber',
    chunkSizeParameterName: 'chunkSize',
    currentChunkSizeParameterName: 'chunkSize',
    fileNameParameterName: 'fileName',
    totalSizeParameterName: 'totalSize',
    headers: resumableHeaders,
  });

  let isCreateResource = true;
  if (oldResourceId) {
    // extra parameters
    r.opts.query = { oldResourceId };
    isCreateResource = false;
  }

  r.addFile(newFile);

  let sessionId;
  try {
    let resourceType = file.resourceType;
    if (!resourceType) {
      resourceType = getResourceFileType(file);
    }
    const data = {
      chunkSize,
      totalSize: file.size,
      fileName: sanitizedFileName,
      fileCategoryType: file.fileCategoryType || 0,
      feature: file.feature,
      isCreateResource,
      resourceType,
      resourceName: fileName,
      resourceDescription: file.fileDescription || fileName,
      isGlobal: file.isGlobal,
      isPublic: true,
    };
    if (file.skipTranscode !== undefined && file.skipTranscode !== null) {
      data.skipTranscode = file.skipTranscode;
    }
    if (file.isOriginalFormat !== undefined && file.isOriginalFormat !== null) {
      data.isOriginalFormat = file.isOriginalFormat;
    }

    const promise = isPortal
      ? Api.post(`${getResourceApi()}/api/upload/sessions`, data)
      : RuntimeApi.post(`${getResourceRuntimeApi()}/api/runtime/upload/sessions`, data);

    const resp = await promise;

    // console.log('### create an upload session: ', resp);
    sessionId = resp.sessionId;
    r.opts.sessionId = sessionId;
    const targetUrl = !isPortal
      ? `${getResourceRuntimeApi()}/api/runtime/upload/sessions/${sessionId}/files`
      : `${getResourceApi()}/api/upload/sessions/${sessionId}/files`;
    r.opts.target = targetUrl;
    r.upload();
  } catch (error) {
    console.log('### ERROR', error);
    if (onError && typeof onError === 'function') {
      onError(error, file, sessionId);
    }
  }

  return r;
}

export async function createUploadSession(file) {
  const isPortal = appInfo.isPortal();
  const resumableHeaders = { 'X-CSRF': '1' };
  if (isPortal) {
    const organization = currentOrganization.getData();
    if (organization) {
      Object.assign(resumableHeaders, { organizationId: organization?.id });
    }
  } else {
    const token = spaceUser.getAccessToken();
    Object.assign(resumableHeaders, { authorization: `Bearer ${token}` });
  }

  const fileName = file.fileName || file.name || 'noname'; // original file name
  const sanitizedFileName = sanitizeFileName(fileName);
  const newFile = file;
  newFile.fileName = sanitizedFileName; // to avoid firewall issue

  const r = new Resumable({
    chunkSize: 1 * 1024 * 1024,
    forceChunkSize: true,
    simultaneousUploads: 1,
    uploadMethod: 'PUT',
    testChunks: false,
    maxChunkRetries: 3,
    chunkRetryInterval: 1000,
    throttleProgressCallbacks: 1,
    fileParameterName: 'file',
    chunkNumberParameterName: 'chunkNumber',
    chunkSizeParameterName: 'chunkSize',
    currentChunkSizeParameterName: 'chunkSize',
    fileNameParameterName: 'fileName',
    totalSizeParameterName: 'totalSize',
    headers: resumableHeaders,
  });
  r.on('fileAdded', (f) => {
    console.log('fileAdded', f);
    let resourceType = f.file?.resourceType;
    if (f.resourceType === undefined) {
      resourceType = getResourceFileType(f.file);
    }
    const data = {
      chunkSize: r.opts.chunkSize,
      totalSize: f.size,
      fileName: sanitizedFileName,
      fileCategoryType: f.file.fileCategoryType || 0,
      feature: f.file.feature !== null ? f.file.feature : null,
      isCreateResource: true,
      resourceType,
      resourceName: fileName,
      resourceDescription: f.file?.fileDescription || fileName,
      isPublic: true,
    };
    if (f.file.skipTranscode !== undefined) {
      data.skipTranscode = f.file.skipTranscode;
    }
    if (f.file.isOriginalFormat !== undefined && f.file.isOriginalFormat !== null) {
      data.isOriginalFormat = f.file.isOriginalFormat;
    }

    const promise = isPortal
      ? Api.post(`${getResourceApi()}/api/upload/sessions`, data)
      : RuntimeApi.post(`${getResourceRuntimeApi()}/api/runtime/upload/sessions`, data);

    promise
      .then((resp) => {
        // console.log('uploadBigFile', resp, r);
        const sessionId = resp.sessionId;
        r.opts.sessionId = sessionId;
        const targetUrl = !isPortal
          ? `${getResourceRuntimeApi()}/api/runtime/upload/sessions/${sessionId}/files`
          : `${getResourceApi()}/api/upload/sessions/${sessionId}/files`;
        r.opts.target = targetUrl;
        r.upload();
      })
      .catch((error) => {
        console.log('ERROR', error);
        r.fire('fileError', error);
      });
  });
  r.addFile(newFile);
  return r;
}

export async function uploadFile(file, onProgressUpdate = null) {
  const uploader = await createUploadSession(file);
  return new Promise((resolve, reject) => {
    if (onProgressUpdate) {
      uploader.on('fileProgress', onProgressUpdate);
    }
    uploader.on('fileSuccess', async (f, message) => {
      resolve(JSON.parse(message));
    });
    uploader.on('cancel', () => {
      reject('Abort');
    });
    uploader.on('fileError', (error) => {
      reject(error);
    });
  });
}

export default { uploadFile };
