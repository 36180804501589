import React, { useCallback } from 'react';
import memoizeOne from 'memoize-one';
import { IGlobalResourcesContext, IResource, RowData, SelectedItemProps } from './Interfaces';
import { isResourceEqual } from '../ResourcesDisplayView/utils';
import { GLOBAL_PROVIDER_TYPE } from '../utils';
import { BreadcrumbsContextProvider } from './BreadcrumbsContextProvider';

const mapRowDataToSelectedItemProps = (row: RowData): SelectedItemProps => ({
  id: row.id,
  driveId: row.driveId,
  resourceId: row.resourceId,
  type: row.type,
  resourceName: row.resourceName,
  name: row.name,
  parentId: row.parentId,
  provider: row.provider,
  brandingSrc: row.brandingSrc,
  resourceFolderId: row.resourceFolderId,
  src: row.orgSrc || row.url,
  resourceType: row.resourceType,
});

const mapResourceToSelectedItemProps = (row: IResource): SelectedItemProps => ({
  id: row.id,
  driveId: row.driveId,
  resourceId: row.resourceId,
  type: row.type,
  resourceName: row.resourceName,
  name: row.name,
});

const defaultContext = {
  resources: null,
  selectedResources: [],
  previewResource: null,
  setResources: () => {},
  updateResource: () => {},
  getSelectedResources: () => [],
  clearResources: () => {},
  clearSelectedResources: () => {},
  setSelectedResources: () => {},
  toogleSelectedResource: () => {},
  addSelectedResources: () => {},
  removedSelectedResources: () => {},
  isSelectedAll: () => false,
};

export const GlobalResourcesContext = React.createContext<IGlobalResourcesContext>(defaultContext);
GlobalResourcesContext.displayName = 'GlobalResoucesContext';

export const GlobalResourcesContextConsumer = GlobalResourcesContext.Consumer;

export const useGlobalResourcesContext = () =>
  React.useContext<IGlobalResourcesContext>(GlobalResourcesContext);

const getGlobalResourcesContext = memoizeOne(
  (
    resources: Array<IResource> | null,
    selectedResources,
    lastPreviewResource
  ): IGlobalResourcesContext => {
    let previewResource = null;
    if (selectedResources.length > 0 && resources !== null) {
      const lastSelectedResource = selectedResources[selectedResources.length - 1];
      previewResource = resources.find((r) =>
        isResourceEqual(mapResourceToSelectedItemProps(r), lastSelectedResource)
      );
      if (!previewResource) {
        previewResource = lastPreviewResource.current;
      }
    }
    return {
      resources,
      selectedResources,
      previewResource,
      setResources: defaultContext.setResources,
      setSelectedResources: defaultContext.setSelectedResources,
      clearResources: defaultContext.clearResources,
      clearSelectedResources: defaultContext.clearSelectedResources,
      toogleSelectedResource: defaultContext.toogleSelectedResource,
      addSelectedResources: defaultContext.addSelectedResources,
      removedSelectedResources: defaultContext.removedSelectedResources,
      updateResource: defaultContext.updateResource,
      isSelectedAll: defaultContext.isSelectedAll,
    };
  }
);

type GlobalResourcesContextProviderProps = {
  children: React.ReactNode;
  provider: (typeof GLOBAL_PROVIDER_TYPE)[keyof typeof GLOBAL_PROVIDER_TYPE];
  useStateOnly: boolean;
};

export default function GlobalResourcesContextProvider(
  props: GlobalResourcesContextProviderProps
): JSX.Element {
  const { children, provider, useStateOnly } = props;

  const [resources, setResources] = React.useState<Array<IResource> | null>(
    defaultContext.resources
  );
  const [selectedResources, setSelectedResources] = React.useState<Array<SelectedItemProps>>([]);
  const lastPreviewResource = React.useRef<IResource | null>(null);

  const context: IGlobalResourcesContext = getGlobalResourcesContext(
    resources,
    selectedResources,
    lastPreviewResource
  );

  lastPreviewResource.current = context.previewResource;

  context.setResources = setResources;

  context.setSelectedResources = useCallback((rows: Array<RowData>) => {
    const newSelectedResources = rows.map((row) => mapRowDataToSelectedItemProps(row));
    setSelectedResources(newSelectedResources);
  }, []);

  context.toogleSelectedResource = useCallback((row: RowData) => {
    const resource = mapRowDataToSelectedItemProps(row);
    setSelectedResources((prev) => {
      const index = prev.findIndex((item) => isResourceEqual(item, resource));
      if (index > -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return [...prev, resource];
    });
  }, []);

  context.addSelectedResources = useCallback((rows: Array<RowData>) => {
    const newSelectedResources = rows.map((row) => mapRowDataToSelectedItemProps(row));
    setSelectedResources((prev) => {
      const newResources = newSelectedResources.filter(
        (item) => !prev.some((prevItem) => isResourceEqual(prevItem, item))
      );
      return [...prev, ...newResources];
    });
  }, []);

  context.removedSelectedResources = useCallback((rows: Array<RowData>) => {
    const removedResources = rows.map((row) => mapRowDataToSelectedItemProps(row));
    setSelectedResources((prev) =>
      prev.filter(
        (item) => !removedResources.some((removedItem) => !isResourceEqual(item, removedItem))
      )
    );
  }, []);

  context.clearSelectedResources = useCallback(() => {
    lastPreviewResource.current = null;
    setSelectedResources([]);
  }, []);

  context.clearResources = useCallback(() => {
    lastPreviewResource.current = null;
    setSelectedResources([]);
    setResources(null);
  }, []);

  context.isSelectedAll = useCallback(() => {
    const selectables = resources?.filter((resource) => !resource.disabledCheckbox);
    if (selectables?.length === 0) return false;
    let flag = true;
    selectables?.forEach((resource) => {
      if (!selectedResources.some((item) => isResourceEqual(item, resource))) {
        flag = false;
      }
    });
    return flag;
  }, [resources, selectedResources]);

  return (
    <GlobalResourcesContext.Provider value={context}>
      <BreadcrumbsContextProvider type={provider} useStateOnly={useStateOnly}>
        {children}
      </BreadcrumbsContextProvider>
    </GlobalResourcesContext.Provider>
  );
}
