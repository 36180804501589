import React, { useCallback } from 'react';
import { ResourceItemProps } from '../context/Interfaces';
import ResourceListItem from '../../components/ResourceListItem/ResourceListItemTs';
import ResourceGridItem from '../../components/ResourceGridItem/ResourceGridItemTs';
import { ViewModeType } from '../../resources/resourceConstants';
import { GRSelectType } from '../GlobalResourcesPicker';
import { GLOBAL_RESOURCE_TYPE } from '../utils';
import i18n from '../../i18n';

const ResourceItem = ({
  viewMode,
  rowData,
  index,
  isSelected,
  onClickItem,
  onDoubleClickItem,
  processingResources,
  multiple,
  selectType,
  onClickMore,
  onClickCheckbox,
  isPortal,
  driveId,
  columns,
}: ResourceItemProps) => {
  const labelId = `enhanced-table-checkbox-${index}`;

  const isDisabledRow = rowData.isDisabled;
  const disableCheckbox = rowData.disabledCheckbox;

  const isConvertingFile =
    rowData?.resourceId && processingResources
      ? processingResources.includes(rowData.resourceId)
      : false;

  const handleClickCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      // event.preventDefault();
      if (onClickCheckbox) {
        onClickCheckbox(event.target.checked, rowData);
      }
    },
    [onClickCheckbox, rowData]
  );

  const onClickMoreButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      if (onClickMore) {
        onClickMore(event, rowData);
      }
    },
    [onClickMore, rowData]
  );

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      if (onClickItem) onClickItem(event, rowData);
    },
    [onClickItem, rowData]
  );

  const handleOnDoubleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      if (onDoubleClickItem) onDoubleClickItem(event, rowData);
    },
    [onDoubleClickItem, rowData]
  );

  const numberFileOfFolder = useCallback(() => {
    let itemsCount = null;
    let renderItem = null;
    if (rowData.isFolder && rowData.itemsCount !== null && rowData.itemsCount !== undefined) {
      renderItem = rowData.itemsCount <= 1 ? 'item' : 'items';
      itemsCount = rowData.itemsCount || 0;
      if (viewMode === ViewModeType.LIST) {
        return <span className="number-file">{itemsCount}</span>;
      }
      return (
        <span className="number-file">
          <span className="dot"> &bull;</span>
          {itemsCount !== null && itemsCount !== undefined && `${itemsCount} ${renderItem}`}
        </span>
      );
    }
    return null;
  }, [rowData.isFolder, rowData.itemsCount, viewMode]);

  const resourceName = rowData.resourceDisplayName || rowData.resourceName || rowData.name;
  const id = rowData.resourceId || rowData.id;

  let disabledRowMessage = '';
  if (isDisabledRow && selectType === GRSelectType.Folder) {
    disabledRowMessage =
      rowData.type === GLOBAL_RESOURCE_TYPE.RESOURCE
        ? i18n.t('Cannot select this resource because it is not a folder')
        : i18n.t('Cannot move the resource into itself');
  }

  if (viewMode === ViewModeType.LIST) {
    return (
      <ResourceListItem
        key={id}
        resourceId={id}
        labelId={labelId}
        lastModified={rowData.lastModified}
        resourceType={rowData.resourceType}
        isExternal={rowData.isExternal}
        resourceName={resourceName}
        src={rowData.url}
        state={rowData.state}
        extension={rowData.extension}
        selected={isSelected}
        multiple={multiple}
        isFolder={rowData.isFolder}
        isDisabled={isDisabledRow}
        location={rowData.location}
        disabledRowMessage={disabledRowMessage}
        isConvertingFile={isConvertingFile}
        showActionMenu={!!onClickMore}
        showCheckbox={!disableCheckbox}
        columns={columns}
        numberFileOfFolder={numberFileOfFolder}
        onClick={handleOnClick}
        onDoubleClick={handleOnDoubleClick}
        onClickCheckbox={handleClickCheckbox}
        onClickMoreButton={onClickMoreButton}
      />
    );
  }

  return (
    <ResourceGridItem
      key={id}
      resourceId={id}
      labelId={labelId}
      lastModified={rowData.lastModified}
      resourceType={rowData.resourceType}
      isExternal={rowData.isExternal}
      resourceName={resourceName}
      thumbnail={rowData.thumbnail}
      location={rowData.location}
      src={rowData.url}
      state={rowData.state}
      extension={rowData.extension}
      selected={isSelected}
      multiple={multiple}
      isFolder={rowData.isFolder}
      isDisabled={isDisabledRow}
      disabledRowMessage={disabledRowMessage}
      showActionMenu={!!onClickMore}
      showCheckbox={!disableCheckbox}
      numberFileOfFolder={numberFileOfFolder}
      onClick={handleOnClick}
      onDoubleClick={handleOnDoubleClick}
      onClickCheckbox={handleClickCheckbox}
      onClickMoreButton={onClickMoreButton}
      isConvertingFile={isConvertingFile}
      isPortal={isPortal}
      driveId={driveId}
      provider={rowData.provider}
    />
  );
};

export default React.memo(ResourceItem);
