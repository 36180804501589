import _debounce from 'lodash/debounce';
import RuntimeApi from '../commons/RuntimeApi';
import { getResourceRuntimeApi } from '../commons/utils';
import { ResourceResponse } from './types';

let resourceIds: number[] = [];
let resolves: Array<(data: ResourceResponse[]) => void> = [];

const debouncedFetchResources = _debounce(
  async () => {
    const copyOfResourceIds = [...resourceIds];
    const copyOfResolves = [...resolves];
    resolves = [];
    resourceIds = [];
    // console.log('fetch resourceIds', copyOfResourceIds);
    let resources: ResourceResponse[] = [];
    if (copyOfResourceIds.length > 0) {
      try {
        let url = `/api/runtime/resources/srcs?`;
        copyOfResourceIds.forEach((id) => {
          url = `${url}resourceIds=${id}&`;
        });
        url = `${url}f=1`;
        resources = await RuntimeApi.get(`${getResourceRuntimeApi()}${url}`);
      } catch (error) {
        if (error instanceof Error) {
          console.error(`Error when fetching resources: ${error.message}`);
        }
      }
    }
    copyOfResolves.forEach((resolve) => {
      resolve(resources);
    });
  },
  250,
  { maxWait: 3000 }
);

async function accumulateAndFetchResources(ids: number[]): Promise<ResourceResponse[]> {
  return new Promise((resolve) => {
    ids.forEach((id) => {
      if (!id) {
        return;
      }
      if (resourceIds.indexOf(id) < 0) {
        resourceIds.push(id);
      }
    });
    resolves.push(resolve);
    debouncedFetchResources();
    if (resourceIds.length > 50) {
      debouncedFetchResources.flush();
    }
  });
}

export async function fetchResourcesByIdsInRuntime(ids: number[]): Promise<ResourceResponse[]> {
  if (ids.length === 0) {
    return [];
  }
  const resources = await accumulateAndFetchResources(ids);
  return resources.filter((resource: ResourceResponse) => {
    return ids.indexOf(resource.resourceId) >= 0;
  });
}
