/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { Chart, ChartTooltipModel, ChartType, Tooltip } from 'chart.js';
import { ChartPluginEvent, ChartPluginInstance } from './types';

export interface ITickData {
  min: number;
  max: number;
  stepSize: number;
  tickCount: number;
}

interface FractionLimit {
  roundedFrom: number;
  limit: number;
}

interface Limit {
  base: number;
  limits: FractionLimit[];
}

const LIM_BASE10: Limit = {
  base: 10,
  limits: [
    {
      roundedFrom: 1.5,
      limit: 1,
    },
    {
      roundedFrom: 3,
      limit: 2,
    },
    {
      roundedFrom: 7,
      limit: 5,
    },
  ],
};

const LIM_DUR_BASE60: Limit = {
  base: 60,
  limits: [
    {
      roundedFrom: 1.5,
      limit: 1,
    },
    {
      roundedFrom: 3,
      limit: 2,
    },
    {
      roundedFrom: 7,
      limit: 5,
    },
    {
      roundedFrom: 13,
      limit: 10,
    },
    {
      roundedFrom: 20,
      limit: 15,
    },
    {
      roundedFrom: 40,
      limit: 30,
    },
  ],
};

// normal => base 10
// duration as seconds => base 60
const niceNum = (localRange: number, round: boolean, isDuration: boolean) => {
  let lim = LIM_BASE10;
  if (isDuration) {
    lim = LIM_DUR_BASE60;
  }
  const base = lim.base;

  let niceFraction: number; /** nice, rounded fraction */

  const exponent = Math.floor(Math.log(localRange) / Math.log(base));
  const fraction = localRange / base ** exponent;

  if (round) {
    const fracLim = lim.limits.find((x) => fraction < x.roundedFrom);
    if (!fracLim) {
      niceFraction = lim.base;
    } else {
      niceFraction = fracLim.limit;
    }
  } else {
    const fracLim = lim.limits.find((x) => fraction <= x.limit);
    if (!fracLim) {
      niceFraction = lim.base;
    } else {
      niceFraction = fracLim.limit;
    }
  }

  return niceFraction * base ** exponent;
};

const calculateTickCount = (min: number, max: number, tickSpacing: number) =>
  (max - min) / tickSpacing;

export const calculateTickData = (
  min: number,
  max: number,
  maxTickCount = 6,
  isDuration = false
): ITickData => {
  let maxValue = max;
  if (maxValue === 0) {
    maxValue = isDuration ? 360 : 10;
  }
  const range = niceNum(maxValue - min, false, isDuration);
  const tickSpacing = niceNum(range / (maxTickCount - 1), true, isDuration);
  const niceMin = Math.floor(min / tickSpacing) * tickSpacing;
  const niceMax = Math.ceil(maxValue / tickSpacing) * tickSpacing;

  return {
    stepSize: tickSpacing,
    min: niceMin,
    max: niceMax,
    tickCount: calculateTickCount(niceMin, niceMax, tickSpacing),
  };
};

export const adaptTickData = (left: ITickData, right: ITickData) => {
  if (
    left.tickCount === right.tickCount ||
    left.tickCount % right.tickCount === 0 ||
    right.tickCount % left.tickCount === 0
  ) {
    return { left, right };
  }
  const tickCount = Math.max(left.tickCount, right.tickCount);
  return {
    left: {
      ...left,
      max: tickCount * left.stepSize,
      tickCount: tickCount,
    },
    right: {
      ...right,
      max: tickCount * right.stepSize,
      tickCount: tickCount,
    },
  };
};

type VerticalLinePluginOptions = {
  color: string;
  lineWidth: number;
  enabled: boolean;
  x: number;
};

export const verticalLinePlugin = {
  id: 'verticalLine',
  afterInit: (_chart: Chart & ChartPluginInstance, options: VerticalLinePluginOptions) => {
    options = { ...options, x: 0 };
  },
  afterEvent: (
    chart: Chart & ChartPluginInstance,
    event: Event & ChartPluginEvent,
    options: VerticalLinePluginOptions
  ) => {
    if (!options.enabled) {
      return;
    }
    const chartArea = chart.chartArea;
    if (
      event.x >= chartArea.left &&
      event.y >= chartArea.top &&
      event.x <= chartArea.right &&
      event.y <= chartArea.bottom &&
      chart.tooltip._model
    ) {
      options.x = chart.tooltip._model.caretX;
    } else {
      options.x = NaN;
    }
  },
  afterDraw: (
    chart: Chart & ChartPluginInstance,
    _easing: ChartTooltipModel,
    options: VerticalLinePluginOptions
  ) => {
    if (!options.enabled) {
      return;
    }
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    const x = options.x;
    if (!isNaN(x) && ctx) {
      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = options.color;
      ctx.lineWidth = options.lineWidth;
      ctx.moveTo(options.x, chartArea.bottom);
      ctx.lineTo(options.x, chartArea.top);
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    }
  },
};

export const initChartExtensions = () => {
  if (!Chart.Tooltip.positioners.top || typeof Chart.Tooltip.positioners.top !== 'function') {
    Chart.Tooltip.positioners.top = (elements, eventPosition) => {
      if (!elements.length) {
        return { x: 0, y: 0 };
      }

      let chartModel = elements.find((e) => !!e._model.width)?._model;
      if (!chartModel) {
        chartModel = elements.find((e) => !!e._model)._model;
      }
      if (!chartModel) {
        return { x: 0, y: 0 };
      }
      const chartArea = elements[0]._chart.chartArea;
      const canvas = elements[0]._chart.canvas;

      return {
        x: canvas.clientLeft + chartModel.x,
        y: canvas.clientTop + chartArea.top,
      };
    };
  }

  // const verticalLinePlugin = Chart.plugins.getAll().find((x) => x.id === 'verticalLine');
  // if (!verticalLinePlugin) {
  //   Chart.plugins.register({
  //     id: 'verticalLine',
  //     afterInit: (chart, options) => {
  //       options = { ...options, x: 0, y: 0 };
  //     },
  //     afterEvent: (
  //       chart: Chart & ChartPluginInstance,
  //       event: Event & ChartPluginEvent,
  //       options
  //     ) => {
  //       const chartArea = chart.chartArea;
  //       if (
  //         event.x >= chartArea.left &&
  //         event.y >= chartArea.top &&
  //         event.x <= chartArea.right &&
  //         event.y <= chartArea.bottom &&
  //         chart.tooltip._model
  //       ) {
  //         options.x = chart.tooltip._model.caretX;
  //       } else {
  //         options.x = NaN;
  //       }
  //     },
  //     afterDraw: (chart, easing, options) => {
  //       const ctx = chart.ctx;
  //       const chartArea = chart.chartArea;
  //       const x = options.x;
  //       if (!isNaN(x) && ctx) {
  //         ctx.save();
  //         ctx.beginPath();
  //         ctx.strokeStyle = options.color;
  //         ctx.lineWidth = options.lineWidth;
  //         ctx.moveTo(options.x, chartArea.bottom);
  //         ctx.lineTo(options.x, chartArea.top);
  //         ctx.stroke();
  //         ctx.closePath();
  //         ctx.restore();
  //       }
  //     },
  //   });
  // }
};
