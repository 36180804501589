import RuntimeApi from '../../commons/RuntimeApi';

import queryCache, { clearCache, CacheKeys } from '../../app/queryCache';
import appInfo from '../../app/appInfo';
import Api from '../../commons/api';
import { getCoreApi, getCoreRuntimeApi } from '../../commons/utils';

export async function addOrUpdateSpaceUser(spaceId, participant) {
  try {
    let rs;
    if (participant.userInfoId) {
      rs = await RuntimeApi.put(
        `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/users/${participant.userInfoId}`,
        participant
      );
    } else {
      rs = await RuntimeApi.post(
        `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/users`,
        participant
      );
    }
    clearCache([CacheKeys.fetchSpaceUsers, spaceId], false);
    clearCache([CacheKeys.getOrganizationsUsers], false);
    return rs;
  } catch (ex) {
    console.log('ERROR addOrUpdateSpaceUser', ex);
    return {
      error: ex.message,
    };
  }
}

export function getUserInfoById(spaceId, userInfoId) {
  return RuntimeApi.get(`${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/users/${userInfoId}`);
}

export function fetchSpaceUsers(spaceId, isPortal = false) {
  if (isPortal) {
    return Api.get(`${getCoreApi()}/spaces/${spaceId}/users`)
      .then((resp) => resp.users)
      .catch((ex) => {
        console.log('ERROR fetchSpaceUsers', ex);
        return [];
      });
  }
  return RuntimeApi.get(`${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/users`)
    .then((resp) => resp.users)
    .catch((ex) => {
      console.log('ERROR fetchSpaceUsers', ex);
      return [];
    });
}

export const getPendingRequestsToSpace = (spaceId) => {
  return RuntimeApi.get(
    `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/request-access/pending-requests`
  );
};

export const acceptRequestAccessToSpace = (spaceId, requestId) => {
  return RuntimeApi.put(
    `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/request-access/${requestId}/accept`
  );
};

export const declineRequestAccessToSpace = (spaceId, requestId) => {
  return RuntimeApi.put(
    `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/request-access/${requestId}/deny`
  );
};

export const getRequestAccessById = (spaceId, requestId) => {
  const isPortal = appInfo.isPortal();
  if (isPortal) {
    return Api.get(`${getCoreApi()}/spaces/${spaceId}/request-access/${requestId}`);
  }

  return RuntimeApi.get(
    `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/request-access/${requestId}`
  );
};

export async function addSpaceUsers(spaceId, users) {
  return RuntimeApi.post(`${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/users/batch`, {
    spaceId,
    participants: users,
  });
}

export function fetchShareSpaceMessageTemplate(spaceId, isPrivate, spacePrivacy) {
  return RuntimeApi.get(
    `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/share-message-template?isPrivate=${isPrivate}&spacePrivacy=${spacePrivacy}`
  );
}

export async function deleteSpaceUser(spaceId, participantId) {
  const resp = RuntimeApi.delete(
    `${getCoreRuntimeApi()}/runtime/spaces/${spaceId}/users/${participantId}`
  );

  if (resp) {
    const { spaceUserId } = resp;
    queryCache.setQueryData([CacheKeys.fetchParticipants, resp.spaceId], (participants) => {
      if (!participants) {
        return participants;
      }
      return participants.filter((e) => e.id !== spaceUserId).map((e) => e);
    });
    clearCache([CacheKeys.fetchParticipants, spaceId]);
    clearCache([CacheKeys.getParticipantsInParticipantSelectComponent, resp.spaceId]);
  }
  return resp;
}
