import React from 'react';
import PropTypes from 'prop-types';
import useGetCertificateFileQuery from '../queries/useGetCertificateFileQuery';
import PdfViewer from '../../components/PdfViewer';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import i18n from '../../i18n';

const CertificateViewer = (props) => {
  const { userCredentialId } = props;

  const getResourceForViewerResp = useGetCertificateFileQuery(userCredentialId);

  if (getResourceForViewerResp.isFetching) {
    return <Loading className="loading" />;
  }

  const resource = getResourceForViewerResp.data;

  if (!resource?.url) {
    return (
      <ErrorMessage message={i18n.t('Sorry, this resource is not available at the moment!')} />
    );
  }

  return <PdfViewer src={resource.url} downloadable />;
};

CertificateViewer.propTypes = {
  userCredentialId: PropTypes.string.isRequired,
};

CertificateViewer.defaultProps = {};

export default CertificateViewer;
