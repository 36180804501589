import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';
import { TagColors } from '../../settings/tags/constants';
import './SpaceTag.scss';

const onClick = (e) => {
  e.stopPropagation();
};

const SpaceTag = (props) => {
  const { name, className, colorId } = props;
  let styles = {};
  if (colorId) {
    const styleTag = TagColors[colorId];
    styles = {
      backgroundColor: styleTag?.background,
      color: styleTag?.color,
    };
  }
  const renderItem = () => {
    if (props.tooltipContent) {
      return (
        <Tooltip
          title={props.tooltipContent}
          classes={{
            tooltip: 'tooltip-custom-grey',
          }}
        >
          <span className="text">{name}</span>
        </Tooltip>
      );
    }
    return <span className="text">{name}</span>;
  };

  return (
    <div className={`space-tag ${className}`} onClick={onClick} style={styles}>
      {renderItem()}
    </div>
  );
};

SpaceTag.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  colorId: PropTypes.number,
  tooltipContent: PropTypes.element,
};

SpaceTag.displayName = 'SpaceTag';

export default SpaceTag;
