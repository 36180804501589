import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ResponsiveDialog from '../components/ResponsiveDialog';
import DndFileUploader from './../components/DndFileUploader3/DndFileUploader';
import { sendNotification } from '../commons/utils';
import { addResourcesToFolder } from './globalResourcesService';
import i18n from '../i18n';
import './UploadResourcesDialog.scss';
import {
  getExtensionFromFileName,
  getResourceFileType,
  getExternalUrlInfo,
  getResourceExtension,
  validateFile,
} from '../commons/ResourceUtils';
import { addResource, updateResource } from '../commons/CommonServices';
import { isValidUrl } from '../commons/ValidationUtils';
import { ResourceType, UploadingFileState } from '../app/appConstants';
import queryCache, { CacheKeys } from '../app/queryCache';
import { getResourceInfo } from '../resources/ResourceServices';
import OfficialButton from '../components/OfficialButtons';
import { DefaultAccept, DefaultMaxFiles } from '../components/DndFileUploader3/utils';
import UploadResourcesDialogHandleEvents from './UploadResourcesDialogHandleEvents';
import { DefaultUploaderTooltip } from '../components/DndFolderUploader/UploaderLabel';

const UploadResourcesDialog = (props) => {
  const { onClose, selectedFolder, selectedFile } = props;
  const [selectedResources, setSelectedResources] = useState([]); // for picked files
  const [uploaderErrors, setUploaderErrors] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [isShow, setIsShow] = useState(true);
  const dndFileUploaderRef = useRef();

  function handleResourceChange(newResourceList) {
    setSelectedResources(newResourceList);
    setError(null);
  }

  function handleUploaderError(newErrors) {
    setUploaderErrors(newErrors);
    setError(null);
  }

  function validateInput(name, value, innerProps) {
    if (!name) return null;

    const isUploadingNewVersion = !!selectedFile?.resourceId;

    if (name === 'url') {
      if (!value) return null;

      const isValid = isValidUrl(value);
      if (!isValid) {
        return { type: 'pattern' };
      }

      if (isUploadingNewVersion) {
        const urlResource = getExternalUrlInfo(value);
        if (urlResource.resourceType !== selectedFile.resourceType) {
          return { type: 'sameType' };
        }
      }
    }

    if (name === 'name') {
      if (!value || !value.trim()) {
        return { type: 'required' };
      }
    }

    if (name === 'file') {
      const file = value;
      const resourceType = getResourceFileType(file);
      if (resourceType === null) {
        return { type: 'unsupported' };
      }

      if (isUploadingNewVersion) {
        const newVersionExtension = getExtensionFromFileName(file.name);
        const selectFileExtention = getResourceExtension(selectedFile);
        if (newVersionExtension !== selectFileExtention) {
          return { type: 'sameType' };
        }
      }

      const accept = innerProps?.accept || DefaultAccept;
      const isValid = validateFile(accept, file);
      if (!isValid) {
        return { type: 'unaccepted' };
      }

      // const isPPT = resourceType === ResourceType.powerpoint;
      const defaultMaxFileSize = 5000; // 5gb
      const maxFileSizeMsg = i18n.t('The file exceeds the maximum file size 5 GB.');
      // if (isPPT) {
      //   defaultMaxFileSize = 50; // 50mb
      //   maxFileSizeMsg = i18n.t('Sorry, PPT file size must be less than 50MB.');
      // }
      const maxFileSize = innerProps?.maxFileSize || defaultMaxFileSize;
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > maxFileSize) {
        return { type: 'maxFileSize', message: maxFileSizeMsg };
      }
    }

    if (name === 'files') {
      // used for checking the number of picked files
      const files = value;
      const maxFiles = innerProps?.multiple ? innerProps?.maxFiles || DefaultMaxFiles : 1;
      if (files.length > maxFiles) {
        return { type: 'maxFiles' };
      }
    }

    return null;
  }

  async function addAnExternalResource(item) {
    const { fileName, fileDescription, resourceType, src } = item;
    const newResource = {
      fileName: fileName || 'Untitled',
      fileDescription: fileDescription || '',
      src,
      type: resourceType,
      resourceType,
      order: 0,
      isExternal: true,
      isGlobal: true,
    };
    const newId = await addResource(newResource);
    newResource.resourceId = newId;
    return newResource;
  }

  async function handleAddOrUpdateFileButtonClick() {
    if (!selectedFolder) return;
    if (!selectedResources?.length) {
      if (dndFileUploaderRef?.current?.state?.invalidResources?.length) {
        setError('Cannot add files. Please try again.');
      } else {
        setError('Enter a website link or upload a file to proceed.');
      }
      return;
    }
    try {
      setIsProcessing(true);
      dndFileUploaderRef.current.onReset();

      const isUploadingNewVersion = !!selectedFile;
      const externalResources = selectedResources.filter((x) => !x.resourceId);
      const internalResources = selectedResources.filter((x) => !!x.resourceId);
      let finalResources = await Promise.all(
        internalResources.map(async (x) => {
          if (x.resourceType === ResourceType.video) {
            const newResource = await getResourceInfo(x.resourceId, true);
            return { ...x, state: newResource.state };
          }
          return x;
        })
      );
      let addingResources = internalResources.map((x) => {
        return { id: x.resourceId, name: x.name };
      });

      if (externalResources.length > 0) {
        if (isUploadingNewVersion) {
          const oldResourceId = selectedFile.resourceId;
          const selectedResource = externalResources[0];
          await updateResource(oldResourceId, { src: selectedResource.src });
          finalResources.push({ ...selectedResource, resourceId: oldResourceId });
        } else {
          const promises = externalResources.map((x) => addAnExternalResource(x));
          const newlyAddedResources = await Promise.all(promises);
          finalResources = finalResources.concat(newlyAddedResources);
          addingResources = addingResources.concat(
            newlyAddedResources.map((x) => {
              return { id: x.resourceId, name: x.name };
            })
          );
        }
      }

      await addResourcesToFolder(selectedFolder.id, { resources: addingResources });
      if (isUploadingNewVersion) {
        queryCache.removeQueries({
          queryKey: [CacheKeys.getResourceForViewer],
          exact: false,
        });
        queryCache.removeQueries({
          queryKey: [CacheKeys.getResourcesForViewer],
          exact: false,
        });
      }
      onClose(finalResources);

      setSelectedResources([]);
      setIsProcessing(false);
      const successMessage =
        finalResources?.length === 1
          ? 'Nice! Your file has been added!'
          : 'Nice! Your files have been added!';
      sendNotification(successMessage, { type: 'success' });
    } catch (e) {
      sendNotification(e.message, { type: 'error' });
      setIsProcessing(false);
    }
  }

  function handleUploadingStateChange(newState) {
    let uploading = false;
    if (newState <= UploadingFileState.Started) {
      uploading = true;
    }
    setIsUploading(uploading);
  }

  const renderTitle = () => {
    if (selectedFile) {
      return selectedFile.name;
    }
    return selectedFolder.name === '_root' ? 'Global Resource' : selectedFolder.name;
  };

  return (
    <ResponsiveDialog
      className={`white upload-resources-dialog ${isShow ? '' : 'hide'}`}
      onClose={() => onClose([])}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div className="content">
        <div className="header">
          <span className="title">{renderTitle()}</span>
        </div>
        <section className="uploader-container styled-scrollbar">
          <div className="uploader-area">
            {error && <div className="message-warning">{error}</div>}

            <DndFileUploader
              ref={dndFileUploaderRef}
              accept={selectedFile ? `.${getResourceExtension(selectedFile)}` : '*'}
              spaceId="00000000-0000-0000-0000-000000000000"
              // urlInputPlaceHolder={i18n.t('Enter link to your file')}
              fileNameInputLabel={i18n.t('Show as')}
              // resourceId={selectedFile?.resourceId}
              oldResourceId={selectedFile?.resourceId}
              onChange={handleResourceChange}
              onError={handleUploaderError}
              multiple={!selectedFile}
              canPreview
              isGlobal
              isPortal
              isAdmin
              validationMethod={validateInput}
              onUploadingStateChange={handleUploadingStateChange}
              hideUrlInput={selectedFile && !selectedFile.isExternal}
              hideFileInput={selectedFile && selectedFile.isExternal}
              fileInputInfo={DefaultUploaderTooltip}
            />
            {selectedFile && (
              <Alert icon={<InfoIcon />} severity="info" className="information-alert">
                The file will be updated in all spaces where it is being used.
              </Alert>
            )}
          </div>
        </section>
        <div className="submit-button">
          <OfficialButton
            label={selectedFile ? i18n.t('upload') : i18n.t('ADD')}
            disabled={!!uploaderErrors || isUploading}
            isProcessing={isProcessing}
            onClick={handleAddOrUpdateFileButtonClick}
            variant="large-primary"
          />
        </div>
      </div>
      <UploadResourcesDialogHandleEvents setIsShow={setIsShow} />
    </ResponsiveDialog>
  );
};

UploadResourcesDialog.propTypes = {
  selectedFolder: PropTypes.instanceOf(Object),
  selectedFile: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
};

export default UploadResourcesDialog;
